import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { SearchAdvanced, useGetAdvancedSearchProps, DEFAULT_SEARCH_GUARD_LENGTH, } from "@ads/ui";
import { useApi } from "@ads/react-common-module";
import { SelectionTiersFormAdapter } from "./SearchTiersFormAdapter";
import { apiSearchTiers } from "../../..";
export const columns = [
    {
        title: "Code",
        dataIndex: "codeTiers",
        key: "codeTiers",
        render: (_value, item) => {
            return `${item.codeTiers}`;
        },
    },
    {
        title: "Nom Prénom / Raison sociale",
        dataIndex: "nom",
        key: "nom",
        render: (_value, item) => {
            return `${item.nom} ${item.prenom}`;
        },
    },
    {
        title: "Adresse",
        dataIndex: ["adressePrincipale", "adresseComplete"],
        key: "adressePrincipale.adresseComplete",
        render: (_value, item) => {
            var _a;
            return `${(_a = item.adressePrincipale) === null || _a === void 0 ? void 0 : _a.adresseComplete}`;
        },
    },
];
export function SelectionTiers(props) {
    const api = useApi();
    const getKeyFromData = (item) => item.typeTiers + "/" + item.tiersID;
    const transformSearchResultsToSelectOptions = (item) => `${item.nom} ${item.prenom} (${item.codeTiers})`;
    const transformModalFormDataToSearchParam = (formData) => {
        return {
            qui: formData.qui,
            ville_cp: formData.ou,
            typetiers: formData.listTypeTiers.map((typetiers) => typetiers.typeTiersID),
        };
    };
    const transformSelectFormDataToSearchParam = (formData) => {
        return {
            qui: formData.trim(),
            ville_cp: props.selectSearchParamsConstante.ville_cp,
            typetiers: props.selectSearchParamsConstante.typetiers,
        };
    };
    const defaultModalFormData = props.defaultFormData === null ? undefined : props.defaultFormData;
    const searchAdvancedInput = useGetAdvancedSearchProps({
        hookParams: {
            api,
            defaultModalFormData: defaultModalFormData,
            getKeyFromData,
            typeSelection: props.typeSelection,
            modalSearch: apiSearchTiers,
            selectSearch: apiSearchTiers,
            transformModalFormDataToSearchParam,
            transformSelectFormDataToSearchParam,
        },
        tableSearchConfiguration: {
            columns,
            formAdapter: SelectionTiersFormAdapter,
            modalTitleAdapter: "Recherce d'un tiers",
        },
        selectSearchConfiguration: {
            transformSearchResultsToSelectOptions,
            searchGuardLength: DEFAULT_SEARCH_GUARD_LENGTH,
        },
        onSubmit: props.onTiersSelected,
    });
    return (_jsx(_Fragment, { children: _jsx(SearchAdvanced, Object.assign({}, searchAdvancedInput), void 0) }, void 0));
}
