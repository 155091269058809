import styled from "styled-components";

const WrapperBtnPatient = styled("div")`
  grid-area: patient;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  border-top: 1px solid #ededed;

  ${({ theme }) => theme.media.lg} {
    border-top: none;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export default WrapperBtnPatient;
