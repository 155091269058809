import { PrimaryButton } from "@ads/ui";
import { SelectTypeVisiteTypePresta } from "../../SelectTypeVisiteTypePresta/ui/SelectTypeVisiteTypePresta";
import { TypeVisiteTypePrestaList } from "../../TypeVisiteTypePrestaList/ui/TypeVisiteTypePrestaList";
import { ISelectTypeVisiteTypePrestaVisiteHookService } from "../hook/interfaces/ISelectTypeVisiteTypePrestaVisiteHookService";

import styled from "styled-components";

const WrapperSelectButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  margin-bottom: 1.2rem;

  ${({ theme }) => theme.media.md} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 0;
  }

  button {
    margin-top: 1.2rem;
  }

  ${({ theme }) => theme.media.md} {
    button {
      margin-top: 0;
    }
  }
`;

interface SelectTypeVisiteTypePrestaVisiteProps {
  services: ISelectTypeVisiteTypePrestaVisiteHookService;
}

export function SelectTypeVisiteTypePrestaVisite({
  services,
}: SelectTypeVisiteTypePrestaVisiteProps) {
  function calculButtonLibelle() {
    if (
      services.serviceSelectionTypeVisiteTypePresta.autoSearchTypeVisiteService
        .selectService.selectedData.length > 0
    ) {
      const selectedTypeVisite =
        services.serviceSelectionTypeVisiteTypePresta
          .autoSearchTypeVisiteService.selectService.selectedData[0];
      const index =
        services.serviceTypeVisiteTypePrestaList.typesVisites.findIndex(
          (typevisite) =>
            typevisite.sPKTypeVisite === selectedTypeVisite.sPKTypeVisite
        );
      if (index === -1) {
        return "Ajouter";
      } else {
        return "Mettre à jour";
      }
    } else {
      return "Ajouter";
    }
  }

  return (
    <div>
      <WrapperSelectButton>
        <SelectTypeVisiteTypePresta
          services={services.serviceSelectionTypeVisiteTypePresta}
        />
        <PrimaryButton
          buttonStyle="secondary"
          as="button"
          type="button"
          label={calculButtonLibelle()}
          size="S"
          onClick={services.notifyAddTypeVisiteTypePresta}
        />
      </WrapperSelectButton>
      <TypeVisiteTypePrestaList
        services={services.serviceTypeVisiteTypePrestaList}
        isDeletable={true}
      />
    </div>
  );
}
