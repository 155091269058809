import { ISelectTiersVisiteHookInput } from "./interfaces/ISelectTiersVisiteHookInput";
import { ISelectTiersVisiteHookService } from "./interfaces/ISelectTiersVisiteHookService";
import {
  useApiGetTypeTiersByID,
  useDefaultSelectionTiersV3Props,
} from "@ads/module-tiers";
import { useEffect } from "react";
import { IFormSelectionTiersHookInput } from "@ads/module-tiers";

const defaultFormConfig: IFormSelectionTiersHookInput = {
  ouConfig: {
    allowChange: true,
    defaultData: "",
  },
  quiConfig: {
    allowChange: true,
    defaultData: "",
  },
  typesTiersConfig: {
    allowChange: true,
    defaultSelectedData: [],
    visible: true,
    data: [],
    typeSelection: "multiple",
  },
};

export function useSelectTiersVisite({
  defaultTypeTiersID,
  onTiersSelected,
  defaultTiersSelected,
}: ISelectTiersVisiteHookInput): ISelectTiersVisiteHookService {
  const {
    loading,
    fetch,
    data: typeTiers,
  } = useApiGetTypeTiersByID(defaultTypeTiersID);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const service = useDefaultSelectionTiersV3Props({
    onTiersSelected: (tiers) => {
      if (tiers && tiers.length > 0) {
        onTiersSelected(tiers[0]);
      } else {
        onTiersSelected(null);
      }
    },
    formConfig: {
      ...defaultFormConfig,
      typesTiersConfig: {
        ...defaultFormConfig.typesTiersConfig,
        defaultSelectedData: typeTiers ? [typeTiers] : [],
      },
    },
    selectionConfig: {
      defaultSelectedData: defaultTiersSelected ? [defaultTiersSelected] : [],
      typeSelection: "simple",
    },
  });

  useEffect(() => {
    if (typeTiers) {
      service.tableFormService.notifyFormDataChanged({
        data: {
          ou: service.tableFormService.formData?.data.ou
            ? service.tableFormService.formData?.data.ou
            : "",
          qui: service.tableFormService.formData?.data.qui
            ? service.tableFormService.formData?.data.qui
            : "",
          typesTiers: [typeTiers],
        },
        config: service.tableFormService.formData?.config
          ? {
              ...service.tableFormService.formData?.config,
              typesTiersConfig: {
                ...service.tableFormService.formData?.config.typesTiersConfig,
                defaultSelectedData: [typeTiers],
              },
            }
          : {
              ...defaultFormConfig,
              typesTiersConfig: {
                ...defaultFormConfig.typesTiersConfig,
                defaultSelectedData: [typeTiers],
              },
            },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeTiers]);

  return {
    typeTiersLoading: loading,
    serviceSearchSelectTiers: service,
  };
}
