import NavigationAppareil from "modules/appareils/ui/organisms/NavigationAppareil";
import NavigationConsommable from "modules/consommable/component/NavigationConsommable";
import TemplateSaisieVisite from "components/ui/templates/TemplateSaisieVisite";
import { RouteParam } from "interface/core/RouteParam";
import { Route, useParams } from "react-router-dom";
import RouterAppareil from "../modules/appareils/router/RouterAppareil";
import RouterConsommable from "../modules/consommable/router/RouterConsommable";
import { RouterCommande } from "modules/commande/router";
import { NavigationCommande } from "../modules/commande/ui/organisms";
import RouterFicheSuivi from "modules/fiche-suivi/router/RouterFicheSuivi";
import NavigationFicheSuivi from "modules/fiche-suivi/components/navigation/NavigationFicheSuivi";
import NavigationQuestionnaire from "modules/questionnaire/components/NavigationQuestionnaire";
import RouterQuestionnaire from "modules/questionnaire/router/RouterQuestionnaire";
import RouterEvenement from "modules/evenement/router/RouterEvenement";
import NavigationEvenement from "modules/evenement/ui/organisms/NavigationEvenement";
import { RouterBlocNote } from "modules/blocnote/RouterBlocNote";
import ProtectedRoute from "components/core/routes/ProtectedRoute";
import { NavigationBlocNote } from "modules/blocnote/NavigationBlocNote";
import { RouterFinVisite } from "modules/fin-de-visite/RouterFinVisite";
import { NavigationFinVisite } from "modules/fin-de-visite/NavigationFinVisite";
import { VisiteStoreProvider } from "modules/visites/store/visiteStore";
import { FichesSuiviStoreProvider } from "modules/fiche-suivi/components/context/visiteContext";
import { PageModifierVisite } from "pages/feuille-de-route/PageModifierVisite";
import { NavigationSuiviPatient } from "modules/suivi-patient/NavigationSuiviPatient";
import { RouterSuiviPatient } from "modules/suivi-patient/RouterSuiviPatient";
import { MessagerieRouter } from "modules/messagerie/component/router/MessagerieRouter";

/**
 * @desc Router de toute la partie saisie de visite, contient le Template saisie de visite et charge la navigation
 *
 */
export function RouterSaisieDeVisite() {
  const { theme } = useParams<RouteParam>();

  let navigation = <></>;

  switch (theme) {
    case "appareils":
      navigation = <NavigationAppareil />;
      break;
    case "consommables":
      navigation = <NavigationConsommable />;
      break;
    case "commande":
      navigation = <NavigationCommande />;
      break;
    case "questionnaires":
      navigation = <NavigationQuestionnaire />;
      break;
    case "suivi-patient":
      navigation = <NavigationFicheSuivi />;
      break;
    case "evenements":
      navigation = <NavigationEvenement />;
      break;
    case "bloc-notes":
      navigation = <NavigationBlocNote />;
      break;
    case "programmes":
      navigation = <NavigationSuiviPatient />;
      break;
    case "fin-de-visite":
      navigation = <NavigationFinVisite />;
      break;
  }

  return (
    <>
      {/* MODIFICATION DE VISITE */}
      <ProtectedRoute
        exact
        path="/visites/:iPKVisite/modifier"
        component={PageModifierVisite}
      />
      <Route path="/visites/:iPKVisite/:theme/:tabActive?/:ficheSuiviID?">
        <VisiteStoreProvider>
          <FichesSuiviStoreProvider>
            <TemplateSaisieVisite navigation={navigation}>
              <ProtectedRoute path="/visites/:iPKVisite/appareils/">
                <RouterAppareil />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/consommables">
                <RouterConsommable />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/commande">
                <RouterCommande />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/suivi-patient">
                <RouterFicheSuivi />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/questionnaires">
                <RouterQuestionnaire />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/evenements">
                <RouterEvenement />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/bloc-notes">
                <RouterBlocNote />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/fin-de-visite">
                <RouterFinVisite />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/programmes">
                <RouterSuiviPatient />
              </ProtectedRoute>
              <ProtectedRoute path="/visites/:iPKVisite/messages/:messageID?">
                <MessagerieRouter />
              </ProtectedRoute>
            </TemplateSaisieVisite>
          </FichesSuiviStoreProvider>
        </VisiteStoreProvider>
      </Route>
    </>
  );
}
