import styled from "styled-components";

const WrapperMain = styled("main")`
  /* flex-grow: 0; */
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.colors.neutral["110"]};
  height: 100%;
  /* margin-top: 13rem; */
`;

export default WrapperMain;
