var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ProduitDTOAdapter } from "./ProduitDTOAdapter";
//Pas de nouveau attribut entre le modeleDTO et le produitDTO 
//=> pas besoin d'implémenter les fonctions. Celle de la super 
//classe suffise.
var ModeleDTOAdapter = /** @class */ (function (_super) {
    __extends(ModeleDTOAdapter, _super);
    function ModeleDTOAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ModeleDTOAdapter;
}(ProduitDTOAdapter));
export { ModeleDTOAdapter };
