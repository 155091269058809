import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { truncate } from "@ads/react-common-module";
import { Badge, Collapse, Table } from "antd";
import { CustomPanel, SectionTitle } from "../atoms/FichePatientElements";
const AdsTable = styled(Table) `
  padding: 0;
  [class*="ant-table-body"] {
    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.neutral[120]};
      border: 3px solid transparent;
      border-radius: 4px;
    }
  }
`;
const columns = [
    {
        title: "Libelle",
        dataIndex: "libelle",
        key: "libelle",
        width: "70%",
        render: (libelle) => truncate(libelle, 40),
    },
    {
        title: "Quantité",
        dataIndex: "quantite",
        key: "quantite",
        width: "15%",
    },
    {
        title: "Fréquence",
        dataIndex: "frequence",
        key: "frequence",
        width: "15%",
    },
];
export function TableConsommable({ data }) {
    return (_jsx(Collapse, Object.assign({ defaultActiveKey: ["1"], ghost: true, accordion: true, expandIconPosition: "right" }, { children: _jsx(CustomPanel, Object.assign({ header: _jsx(SectionTitle, { children: _jsx(Badge, Object.assign({ color: "#ED5B2E", count: data.length, offset: [15, 5] }, { children: "Consommables install\u00E9s" }), void 0) }, void 0) }, { children: _jsx(AdsTable, { dataSource: data, columns: columns, pagination: false, size: "small" }, void 0) }), "1") }), void 0));
}
