var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EvenementAdapter } from ".";
import { CureAdapter, LieuExerciceMedecinAdapter, PrescriptionAdapter, PrestationAdapter, } from "../..";
var EvenementStandardAdapter = /** @class */ (function (_super) {
    __extends(EvenementStandardAdapter, _super);
    function EvenementStandardAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lieuExerciceMedecinAdapter = new LieuExerciceMedecinAdapter();
        _this.prestationAdapter = new PrestationAdapter();
        _this.prescriptionAdapter = new PrescriptionAdapter();
        _this.cureAdapter = new CureAdapter();
        return _this;
    }
    EvenementStandardAdapter.prototype.toClient = function (src) {
        return __assign(__assign({}, _super.prototype.toClient.call(this, src)), { motif: src.sMotif, tiersOrigine: src.tiersOrigine && this.tiersAdapter.toClient(src.tiersOrigine), lieuExercice: src.lieuExercice &&
                this.lieuExerciceMedecinAdapter.toClient(src.lieuExercice), prestation: src.prestation && this.prestationAdapter.toClient(src.prestation), prescription: src.prescription && this.prescriptionAdapter.toClient(src.prescription), cure: src.cure && this.cureAdapter.toClient(src.cure), precision: src.precision });
    };
    EvenementStandardAdapter.prototype.toClients = function (srcs) {
        var result = [];
        if (srcs) {
            for (var _i = 0, srcs_1 = srcs; _i < srcs_1.length; _i++) {
                var src = srcs_1[_i];
                if (src) {
                    result.push(this.toClient(src));
                }
            }
        }
        return result;
    };
    EvenementStandardAdapter.prototype.toAPI = function (src) {
        return __assign(__assign({}, _super.prototype.toAPI.call(this, src)), { sMotif: src.motif, tiersOrigine: src.tiersOrigine && this.tiersAdapter.toAPI(src.tiersOrigine), lieuExercice: src.lieuExercice &&
                this.lieuExerciceMedecinAdapter.toAPI(src.lieuExercice), prestation: src.prestation && this.prestationAdapter.toAPI(src.prestation), prescription: src.prescription && this.prescriptionAdapter.toAPI(src.prescription), cure: src.cure && this.cureAdapter.toAPI(src.cure), precision: src.precision });
    };
    EvenementStandardAdapter.prototype.toAPIs = function (srcs) {
        var result = [];
        if (srcs) {
            for (var _i = 0, srcs_2 = srcs; _i < srcs_2.length; _i++) {
                var src = srcs_2[_i];
                if (src) {
                    result.push(this.toAPI(src));
                }
            }
        }
        return result;
    };
    return EvenementStandardAdapter;
}(EvenementAdapter));
export { EvenementStandardAdapter };
