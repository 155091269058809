import styled from "styled-components";

const TextAdresse = styled("span")`
  margin-top: 0.7rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: ${({ theme }) => theme.font.type.primaryMedium};
`;

export default TextAdresse;
