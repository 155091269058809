import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";
import { TypePrestationAdapter } from "@ads/isadom-model";
export function apiGetTypePrestationByFamilleID(familleID) {
    const api = getDefaultApiInstance();
    if (api) {
        const adapter = new TypePrestationAdapter();
        return apiGet(api, `/familles/${familleID}/types-presta`, adapter);
    }
    throw new Error("La config d'api par défaut est null");
}
