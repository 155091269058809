import { Loading } from "@ads/ui";
import { Alert } from "antd";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";
import { useSyncFichesSuiviByVisiteIDWithContext } from "../context/useSyncFichesSuiviByVisiteIDWithContext";
import { ParametreEditionForm } from "../parametre-edition/ui/ParametreEditionForm";

export function PageParamEdition() {
  const { iPKVisite } = useParams<RouteParam>();
  const fichesSuivisServices = useSyncFichesSuiviByVisiteIDWithContext(
    +iPKVisite
  );

  if (fichesSuivisServices.loadings.readLoading) {
    return <Loading>Chargement des données en cours</Loading>;
  } else if (
    fichesSuivisServices.data.fichesSuivis &&
    fichesSuivisServices.data.fichesSuivis.length > 0
  ) {
    return (
      <ParametreEditionForm
        data={fichesSuivisServices.data.fichesSuivis[0]}
        loadings={fichesSuivisServices.loadings}
        visiteID={+iPKVisite}
      />
    );
  } else {
    return (
      <Alert
        message="Erreur"
        showIcon
        description={
          <div>
            Une erreur inconnue est survenue pendant le chargement. <br />
            Veuillez contacter l'administrateur
          </div>
        }
        type="error"
      />
    );
  }
}
