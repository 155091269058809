import { apiGet, ApiInstance } from "@ads/react-common-module";
import {
  FicheSuiviModel,
  FicheSuivisAdapter,
  HttpResponseAdapter,
  HttpResponseModel,
} from "@ads/isadom-model";
export function apiGetFicheSuivisByVisiteID(
  api: ApiInstance | null,
  visiteID: number
): Promise<HttpResponseModel<FicheSuiviModel[]>> {
  if (api) {
    const adapter = new HttpResponseAdapter(new FicheSuivisAdapter());
    return apiGet(
      api,
      `/visites/${visiteID}/fiches-suivis`,
      adapter
    ) as Promise<HttpResponseModel<FicheSuiviModel[]>>;
  } else {
    throw new Error("La configuration d'api est null");
  }
}
