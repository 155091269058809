import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  EvenementTelesuiviAdapter,
  EvenementTelesuiviModel,
  TiersModel,
  TypeEvenementModel,
  VisiteAdapter,
} from "@ads/isadom-model";
import { notification } from "antd";
import { apiIsadomErrorStrategy, Breadcrumb, FormWidget, Loading } from "@ads/ui";
import MenuTab from "components/ui/molecules/MenuTab";
import {
  EvenementTelesuiviDetailsForm,
  EvenementTelesuiviInformationsForm,
} from "../../molecules/evenementTelesuivi";
import { EvenementTelesuiviTableauAppareils } from "../../molecules/evenementTelesuivi/EvenementTelesuiviTableauAppareils";
import ContentTab from "components/ui/molecules/ContentTab";
import { useGetAppareilFromAPI } from "modules/evenement/api/apiGetAppareilsByVisiteID";
import { useAddEvenementMutation, useGetVisiteQuery, useUpdateEvenementMutation } from "store/redux/services/nomadApi";
import { RouteParam } from "interface/core/RouteParam";
import useGetEvenement from "modules/evenement/hooks/useGetEvenement";
import getBreadcrumbFormEvenement from "modules/evenement/functions/getBreadcrumbFormEvenement";
import config from "config";
import { Helmet } from "react-helmet";

const InformationsGenerales = styled.div`
  max-width: 80rem;
`;

const Content = styled.div`
  display: block;

  ${({ theme }) => theme.media.md} {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

const tabActive = "irelm-teleobs"

export const EvenementTelesuiviForm: FC = () => {
  const history = useHistory();
  const { iPKVisite: visiteID, evenementID } = useParams<RouteParam>()
  const { data: visiteAPI, isLoading: isLoadingVisite } = useGetVisiteQuery(+visiteID)
  const visiteClient = visiteAPI && new VisiteAdapter().toClient(visiteAPI);
  const { evenement, loading: isLoadingEvenement } = useGetEvenement(evenementID, tabActive, visiteClient)
  const { loading: isLoadingAppareils, appareils } = useGetAppareilFromAPI({ visiteID: visiteClient?.ID });

  // Typeguard
  let evenementTeleSuiviModel: EvenementTelesuiviModel | undefined
  if ((evenement && "estDecision" in evenement) || evenement === undefined) {
    evenementTeleSuiviModel = evenement
  }

  // Breadcrumb
  const breadcrumbsItems = getBreadcrumbFormEvenement(evenementTeleSuiviModel, visiteClient, tabActive)

  // Récupération des appareils teleobs
  const initFormData: EvenementTelesuiviModel = {
    appareilsTelesuivis: appareils || [],
    ID: evenementTeleSuiviModel?.ID || 0,
    evenementVisiteID: evenementTeleSuiviModel?.evenementVisiteID || 0,
    estDecision: evenementTeleSuiviModel?.estDecision || false,
    typeEvenement: evenementTeleSuiviModel?.typeEvenement || ({} as TypeEvenementModel),
    dateDebut: evenementTeleSuiviModel?.dateDebut || "0000-00-00",
    dateFin: evenementTeleSuiviModel?.dateFin || "0000-00-00",
    estAnnule: evenementTeleSuiviModel?.estAnnule || false,
    tiers: evenementTeleSuiviModel?.tiers || ({} as TiersModel),
    informations: evenementTeleSuiviModel?.informations || "",
  };

  const [formData, setFormData] =
    useState<EvenementTelesuiviModel>(initFormData);

  useEffect(() => {
    if (evenementTeleSuiviModel)
      setFormData(evenementTeleSuiviModel)
  }, [evenementTeleSuiviModel])


  useEffect(() => {
    // Si je suis dans le cas d'un évènement existant, je récupère les appareils de l'évènement
    if (evenementTeleSuiviModel && evenementTeleSuiviModel?.appareilsTelesuivis.length > 0) {
      setFormData({
        ...initFormData,
        appareilsTelesuivis: evenementTeleSuiviModel.appareilsTelesuivis,
      });
    } else {
      // Sinon je suis dans le cas d'un ajout d'évènement, j'initialise les appareils avec useGetAppareilFromAPI
      setFormData({ ...initFormData, appareilsTelesuivis: appareils || [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appareils]);

  const handleChange = (formData: EvenementTelesuiviModel) => {
    setFormData(formData);
  };

  const [updateEvenement, { isLoading: isLoadingUpdateEvenement }] = useUpdateEvenementMutation()
  const [addEvenement, { isLoading: isLoadingAddEvenement }] = useAddEvenementMutation()
  const adapter = new EvenementTelesuiviAdapter();

  const handleSave = async () => {
    const evenementApi = adapter.toAPI(formData)
    if (!visiteClient) return

    if (!evenementTeleSuiviModel || !evenementTeleSuiviModel.evenementVisiteID) {
      addEvenement({ body: evenementApi, params: { visiteId: visiteClient.ID } }).unwrap().then(() => {
        notification.success({
          key: `form_success`,
          message: "Confirmation",
          description: "L'évènement a bien été enregistré.",
        });
        history.push(`/visites/${visiteClient?.ID}/evenements/${tabActive}`);
      })
        .catch((error: any) => apiIsadomErrorStrategy(error))
    } else if (evenementTeleSuiviModel.evenementVisiteID) {
      const queryString = formData?.changeStatus ? "?action=CHANGEMENT_STATUS" : ""

      const data = {
        body: evenementApi,
        params: {
          visiteId: visiteClient?.ID,
          evenementVisiteId: evenementTeleSuiviModel.evenementVisiteID,
          queryString
        }
      }

      updateEvenement(data).unwrap().then(() => {
        notification.success({
          key: `form_success`,
          message: "Confirmation",
          description: "L'évènement a bien été mis à jour.",
        });
        history.push(`/visites/${visiteClient?.ID}/evenements/${tabActive}`);
      }).catch((error: any) => apiIsadomErrorStrategy(error))
    }
  };

  // On affiche uniquement le loading en cas d'édition d'un évènement
  if (evenementID !== "nouveau" && (isLoadingVisite || isLoadingAppareils || isLoadingEvenement)) {
    return <Loading>Chargement de l'évènement.</Loading>
  }

  const title = evenement ? `Évènement ${evenement.typeEvenement.libelle}` : "Nouvel Évènement";

  return (
    <>
      <Helmet title={`${title} - NOMAD v${config.version}`} />

      {breadcrumbsItems && <Breadcrumb breadcrumbItems={breadcrumbsItems} size="M" />}
      <FormWidget
        handleCancel={() =>
          history.push(`/visites/${visiteClient?.ID}/evenements/${tabActive}`)
        }
        handleSave={handleSave}
        isLoadingSave={isLoadingUpdateEvenement || isLoadingAddEvenement}
      >
        <MenuTab
          tabs={[
            { id: "informations", label: "Informations générales", url: "#" },
          ]}
          subTabActive={"informations"}
        />
        <ContentTab>
          <InformationsGenerales>
            <Content>
              <EvenementTelesuiviInformationsForm
                evenement={formData}
                onInformationsChange={handleChange}
              />
              {visiteClient && <EvenementTelesuiviDetailsForm
                visiteID={visiteClient.ID}
                onChange={handleChange}
                evenement={formData}
              />}
            </Content>
            {formData?.typeEvenement?.ID === "TELEOBS" && appareils && (
              <EvenementTelesuiviTableauAppareils
                handleChange={handleChange}
                evenement={formData}
                loading={isLoadingAppareils}
              />
            )}
          </InformationsGenerales>
        </ContentTab>
      </FormWidget>
    </>
  );


};
