import styled from "styled-components";
export const Mobile = styled("div") `
  display: block;
  width: 100%;
  ${({ theme }) => theme.media.sm} {
    display: none;
  }
`;
export const Tablette = styled("div") `
  display: none;
  width: 100%;
  ${({ theme }) => theme.media.sm} {
    display: block;
  }
  ${({ theme }) => theme.media.md} {
    display: none;
  }
  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;
export const Desktop = styled("div") `
  display: none;
  width: 100%;
  ${({ theme }) => theme.media.md} {
    display: block;
  }
`;
