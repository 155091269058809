var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
import { ConsommableAdapter, LotAdapter } from ".";
import { LocalisationInterneAdapter } from "../../../localisation/adapter/LocalisationInterneAdapter";
var RetourVisiteAdapter = /** @class */ (function (_super) {
    __extends(RetourVisiteAdapter, _super);
    function RetourVisiteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.consommableAdapter = new ConsommableAdapter();
        _this.lotAdapter = new LotAdapter();
        _this.localisationInterneAdapter = new LocalisationInterneAdapter();
        return _this;
    }
    RetourVisiteAdapter.prototype.toClient = function (src) {
        return {
            ligneRetourID: src.ligneRetourID,
            consommable: this.consommableAdapter.toClient(src.consommable),
            iQuantite: src.iQuantite,
            lot: src.lot ? this.lotAdapter.toClient(src.lot) : undefined,
            localisationRetour: src.localisationRetour
                ? this.localisationInterneAdapter.toClient(src.localisationRetour)
                : undefined,
        };
    };
    RetourVisiteAdapter.prototype.toAPI = function (src) {
        return {
            ligneRetourID: src.ligneRetourID,
            consommable: this.consommableAdapter.toAPI(src.consommable),
            iQuantite: src.iQuantite,
            lot: src.lot ? this.lotAdapter.toAPI(src.lot) : undefined,
            localisationRetour: src.localisationRetour
                ? this.localisationInterneAdapter.toAPI(src.localisationRetour)
                : undefined,
        };
    };
    return RetourVisiteAdapter;
}(Adapter));
export { RetourVisiteAdapter };
