import { adsNotification } from "@ads/ui";
import nomadTheme from "utils/nomad-theme";

export class NotificationManager {
  private static instance: NotificationManager;
  private observer: Map<string, NotificationObserver> = new Map();

  private constructor() {}

  public static getInstance(): NotificationManager {
    if (!NotificationManager.instance) {
      NotificationManager.instance = new NotificationManager();
    }
    return NotificationManager.instance;
  }

  public registerObserver(observer: NotificationObserver) {
    this.observer.set(observer.id, observer);
  }

  public notify(notification: NotificationModel) {
    switch (notification.type) {
      case "error":
        adsNotification.error({
          message: notification.displayedMessage,
          duration: nomadTheme.duration.notification
        });
        break;
    }
  }
}

export interface NotificationModel {
  id: string;
  tag: string;
  type: "alert" | "error" | "info";
  title: string;
  displayedMessage: string;
}

export interface NotificationObserver {
  id: string;
  onNotification(messages: NotificationModel): void;
}

export class AdsException<T> {
  data?: T;
  notification?: NotificationModel;

  constructor({
    data,
    notification
  }: {
    data?: T;
    notification?: NotificationModel;
  }) {
    this.data = data;
    this.notification = notification;
  }
}
