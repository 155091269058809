import { createGlobalStyle } from "styled-components";

import PoppinsRegular from "../fonts/Poppins-Regular.woff2";
import PoppinsMedium from "../fonts/Poppins-Medium.woff2";
import PoppinsBold from "../fonts/Poppins-Bold.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "PoppinsRegular";
    src: url(${PoppinsRegular});
  }
  @font-face {
    font-family: "PoppinsMedium";
    src: url(${PoppinsMedium});
  }
  @font-face {
    font-family: "PoppinsBold", sans-serif;
    src: url(${PoppinsBold});
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;

  }

  html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
  }

 body > iframe {
    display: none !important;
  } 

  body {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "PoppinsRegular", sans-serif !important;
    font-size: 1.4em;
    line-height: 1.428571428571429em !important;
    color: ${({ theme }) => theme.colors.neutral[180]}; 
    background: #e9853a;
  }
  
  legend {
    font-size: 2.0rem;
    padding: 1rem  1.4rem;
    border-radius: 1.3rem;
  }

  input[type=checkbox] {
    appearance: none;
    position: relative;
    background: #FFFFFf;
  }

  input[type=checkbox]:checked::after {
    content: "X";
    position: absolute;
    left: 3px;
    top: 0px;
    font-size: 1.7rem;
    color: #FFFFFf;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* .ant-input-number-handler-wrap {
    display: none;
  } */

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  svg {
    fill: currentColor;
  }

  a{
    color: inherit;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
  }

  .react-datepicker.floating {
    top: -29.6rem;
  }

  .react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::after, .react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::before {
    bottom: -1rem !important;
    transform: rotate(180deg);
  }

  .ui.dropdown .menu>.item.default {
    font-weight: bold;
    color: purple;
  }
  .ui.dropdown>.text {
    width: 100%;
  }

  .loading {
    animation: loading-opacity infinite 1s ease-in-out;
  }

  @keyframes App-logo-float {
    0% {
      transform-origin: center;
      transform: translateY(0) rotate(0);
    }
    50% {
      transform-origin: center;
      transform: translateY(5px) rotate(6deg);
    }
    100% {
      transform-origin: center;
      transform: translateY(0px) rotate(0);
    }
  }

  @keyframes loading-opacity {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  /* --- ANTD --- */
  /* CHECKBOX */
  .ant-checkbox {
    top: 0;
    font-size: 0;
  }
  .ant-checkbox-wrapper {
    align-items: center;
  }

 /* SELECT */
  .ant-select-dropdown {
    border-radius: 1.3rem;
    padding: 1rem 1rem;
    min-width: 10rem !important;

    &.mode-dark {
      background: ${(props) => props.theme.colors.neutral[130]};
    }
    &.mode-light {
      background: ${(props) => props.theme.colors.neutral[110]};
    }
  }
  /* item list */
  .ant-select-item {
    &.mode-dark {
      background-color: ${(props) => props.theme.colors.neutral[110]};
    }
    &.mode-light {
      background-color: ${(props) => props.theme.colors.neutral[130]};
    }
  }
  /* hover item */
  .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
    background: ${(props) => props.theme.colors.neutral[140]};
    border-radius: 6rem;
  }
  /* border item */
  .ant-select-item-option {
    border-radius: 6rem;
  }
  /* last item selected */
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: ${(props) => props.theme.colors.primary[110]};
  }
  /* content */
  .ant-select-item-option-content {
    padding: 0.6em 0.6em;
  }

   /* FIN SELECT */

   /* COLLAPSE */
  .ant-collapse-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  /* dropdown menu from Select FiltreAppareils */
  .customDropDownFilterAppareils{
    .rc-virtual-list-holder-inner{
      max-width: 9em;
      /* max-height: 6rem; */
    }
  }
`;

export default GlobalStyle;
