import { Alert } from "antd";
import { Loading, FormWidget } from "@ads/ui";
import { VisiteModel } from "@ads/isadom-model";
import styled from "styled-components";
import { useFormulaireCommandeAPI } from "modules/commande/hooks/useFormulaireCommandesAPI";
import CommandesHeader from "../organisms/CommandesHeader";
import CommandeHeader from "../organisms/CommandeHeader";
import CommandeListe from "../organisms/CommandeListe";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";

const CommandeContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.xl} {
    flex-direction: row;
  }
`;
interface AppareilDetailsProps {
  visite: VisiteModel;
}

export const CommandeTemplate = ({ visite }: AppareilDetailsProps) => {
  const { saveData, resetData } = useFormulaireCommandeAPI(visite);
  const { commande, isLoadingFetch, isError, isLoadingSave } =
    useFormulaireCommandesStore();

  // console.log("Messages", responseMessages);

  if (isLoadingFetch) {
    return <Loading>Chargement en cours de la commande.</Loading>;
  }

  // if (isError) {
  //   return (
  //     <Alert
  //       showIcon
  //       type="error"
  //       message="Une erreur est survenue."
  //       description="Les informations de la commande n'ont pu être récupérées."
  //     />
  //   );
  // }

  return (
    <>
      <CommandesHeader resetData={resetData} />
      <FormWidget
        handleSave={() => saveData(commande)}
        isLoadingSave={isLoadingSave}
      >
        <CommandeContainer>
          <CommandeHeader />
          <CommandeListe />
        </CommandeContainer>
      </FormWidget>
    </>
  );
};
