var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../..";
import { AdresseAdapter } from "../../localisation";
import { LigneCommandeVisiteAdapter } from ".";
import { EquipeAdapter } from "../../equipe";
import { ModeLivraisonAdapter } from "../../logistique";
var CommandeVisiteAdapter = /** @class */ (function (_super) {
    __extends(CommandeVisiteAdapter, _super);
    function CommandeVisiteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.equipeAdapter = new EquipeAdapter();
        _this.adresseAdapter = new AdresseAdapter();
        _this.ligneCommandeVisiteAdapter = new LigneCommandeVisiteAdapter();
        _this.modeLivraisonAdapter = new ModeLivraisonAdapter();
        return _this;
    }
    CommandeVisiteAdapter.prototype.toClient = function (src) {
        return {
            adresse: src.adresse && this.adresseAdapter.toClient(src.adresse),
            modeLivraison: src.modelivraison &&
                this.modeLivraisonAdapter.toClient(src.modelivraison),
            equipePreparation: src.equipePreparation &&
                this.equipeAdapter.toClient(src.equipePreparation),
            commentaire: src.commentaire,
            lignes: src.tLigneCommande &&
                this.ligneCommandeVisiteAdapter.toClients(src.tLigneCommande),
        };
    };
    CommandeVisiteAdapter.prototype.toAPI = function (src) {
        return {
            adresse: src.adresse && this.adresseAdapter.toAPI(src.adresse),
            modelivraison: src.modeLivraison && this.modeLivraisonAdapter.toAPI(src.modeLivraison),
            equipePreparation: src.equipePreparation &&
                this.equipeAdapter.toAPI(src.equipePreparation),
            commentaire: src.commentaire,
            tLigneCommande: src.lignes && this.ligneCommandeVisiteAdapter.toAPIs(src.lignes),
        };
    };
    return CommandeVisiteAdapter;
}(Adapter));
export { CommandeVisiteAdapter };
