import { IConstanteModel } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { apiPutConstanteListByVisiteID } from "modules/fiche-suivi/api/apiPutConstanteListByVisiteID";

export async function updateConstantesByVisiteID(visiteID: number, constantes: IConstanteModel[] | null):  Promise<IConstanteModel[] | null> {
    if(constantes){
        return await (await apiPutConstanteListByVisiteID(getDefaultApiInstance(), visiteID, constantes)).data
    }else{
        return null
    }
}  