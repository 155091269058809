import { InputV3 } from "@ads/ui";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const FormLibelleAdresse = () => {
  return (
    <Wrapper>
      <label htmlFor="libelle-adresse">Libellé de l'adresse</label>
      <InputV3 type="text" id="libelle-adresse" name="libelle-adresse" />
    </Wrapper>
  );
};

export default FormLibelleAdresse;
