var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiIsadomPost } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";
export function apiSearchModele(api, searchParam) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            //toutes les chaines vides sont remisent à null pour ne pas poluer l'appel back
            Object.keys(searchParam).forEach(function (key, _index) {
                if (!searchParam[key]) {
                    searchParam[key] = undefined;
                }
            }, searchParam);
            //const adapter = new ModeleDTOAdapter();
            const result = yield apiIsadomPost(api, `/produits/recherche`, searchParam, apiIsadomErrorStrategy);
            return result.data;
        }
        return new Promise((_, reject) => {
            reject("La configuration api est null");
        });
    });
}
