import { ModeLivraisonModel } from "@ads/isadom-model";
import { FormItemWrapper, Label, SelectWrapper, SelectV3 } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const HeadersSelectLivraison = () => {
  const { commande, modesLivraison, setModeLivraison } =
    useFormulaireCommandesStore();

  return (
    <Wrapper>
      <FormItemWrapper direction="vertical">
        <Label htmlFor="mode-livraison">Mode de livraison</Label>
        <SelectV3
          disabled={false}
          id="mode-livraison"
          name="mode-livraison"
          onSelect={(value) => {
            const modeLivraison = modesLivraison.find(
              (m) => m.ID === parseInt(value, 10)
            );
            if (modeLivraison) {
              setModeLivraison(modeLivraison);
            }
          }}
          showSearch
          filterOption={(input: string, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={modesLivraison.map((modeLivraison: ModeLivraisonModel) => ({
            value: `${modeLivraison.ID}`,
            label: modeLivraison.libelle
          }))}
          selectMode={"light"}
          placeholder="Sélectionnez un mode de livraison"
          defaultValue={
            commande?.modeLivraison ? `${commande.modeLivraison.ID}` : undefined
          }
          allowClear
        />
      </FormItemWrapper>
    </Wrapper>
  );
};

export default HeadersSelectLivraison;
