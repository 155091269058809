import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { QuestionMolecule } from "./QuestionMolecule";
import { TitleList } from "../atoms";
export function ThemeMolecule(props) {
    const { theme, disableActions, listener } = props;
    const OnQuestionChangeListener = {
        onQuestionChanged: (question) => {
            for (let i = 0; i < theme.questions.length; i++) {
                if (theme.questions[i].questionID === question.questionID) {
                    theme.questions[i] = question;
                    listener.onThemeChanged(theme);
                }
            }
        },
    };
    return (_jsxs(_Fragment, { children: [_jsx(TitleList, { children: theme.libelle }, void 0), theme && theme.questions && (_jsx("ul", { children: theme.questions.map((value) => (_jsxs(_Fragment, { children: [_jsx(QuestionMolecule, { listener: OnQuestionChangeListener, question: value, disableActions: disableActions }, value.questionID), _jsx("br", {}, void 0)] }, void 0))) }, void 0))] }, void 0));
}
