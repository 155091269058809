import { TiersAdapter } from "@ads/isadom-model";
import { apiSearch } from "@ads/react-common-module";
export function apiSearchTiersV2(api, criteria) {
    if (api) {
        const adapter = new TiersAdapter();
        return apiSearch(api, "/tiers/recherche", adapter, criteria);
    }
    else {
        throw new Error("La configuration d'api est null.");
    }
}
