import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { v4 } from "uuid";
import { notification } from "antd";
import styled from "styled-components";
import { InputSearch, ScannerSearch, useListSelection } from "@ads/ui";
import { useSearchBonsLivraisons } from "../../hooks/useSearchBonsLivraisons";
import { apiSearchBonsLivraison, } from "../../api/apiSearchBonsLivraison";
import { ModalBonLivraisonForm } from "./ModalBonLivraisonForm";
const SearchContainer = styled.div `
  min-width: 30rem;
`;
export const BLSelection = ({ visiteID, refreshData }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [bonLivraisonID, setBonLivraisonID] = useState();
    const [livraisonsConsommables, setLivraisonsConsommables] = useState();
    const searchService = useSearchBonsLivraisons({
        apiSearch: apiSearchBonsLivraison,
        transformFormDataToSearchParam: (formData) => {
            setBonLivraisonID(parseInt(formData.trim()));
            return {
                livraisonID: parseInt(formData.trim()),
                visiteID: visiteID,
            };
        },
    });
    const selectService = useListSelection({
        data: [],
        defaultSelectedData: [],
        typeSelection: "simple",
        getKeyFromData: () => 0,
    });
    return (_jsxs(SearchContainer, { children: [_jsx(InputSearch, { buttonProps: {
                    disabled: searchService.isFormDataLoading,
                    loading: searchService.isFormDataLoading,
                }, inputProps: {
                    name: "bl",
                    type: "text",
                    selectMode: "light",
                    placeholder: "Bon de livraison",
                    value: searchService === null || searchService === void 0 ? void 0 : searchService.formData,
                    disabled: searchService.isFormDataLoading,
                }, onSearchResult: (result) => {
                    if (result.length > 0) {
                        setLivraisonsConsommables(result.sort((a, b) => {
                            if (a.consommable.sLibelle < b.consommable.sLibelle) {
                                return -1;
                            }
                            if (a.consommable.sLibelle > b.consommable.sLibelle) {
                                return 1;
                            }
                            return 0;
                        }));
                        setIsModalVisible(true);
                    }
                    else {
                        notification.warning({
                            key: v4(),
                            message: "Aucun bon de livraison ne correspond à votre recherche",
                        });
                    }
                }, widget: ScannerSearch, searchService: searchService, selectService: selectService }, void 0), _jsx(ModalBonLivraisonForm, { livraisons: livraisonsConsommables, bonLivraisonID: bonLivraisonID, isModalVisible: isModalVisible, setIsModalVisible: setIsModalVisible, visiteID: visiteID, refreshData: refreshData }, void 0)] }, void 0));
};
