import { jsx as _jsx } from "react/jsx-runtime";
import { Badge, Collapse, Table } from "antd";
import { truncate } from "@ads/react-common-module";
import { CustomPanel, SectionTitle } from "../atoms/FichePatientElements";
import styled from "styled-components";
const AdsTable = styled(Table) `
  padding: 0;
  [class*="ant-table-body"] {
    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.neutral[120]};
      border: 3px solid transparent;
      border-radius: 4px;
    }
  }
`;
const columns = [
    {
        title: "Libelle",
        dataIndex: "libelle",
        key: "libelle",
        width: "60%",
        render: (libelle) => truncate(libelle, 40),
    },
    {
        title: "Prépondérance",
        dataIndex: "preponderance",
        key: "preponderance",
        width: "15%",
    },
    {
        title: "Date d'installation",
        dataIndex: "dateInstallation",
        key: "dateInstallation",
        width: "25%",
        render: (dateNaissance) => new Intl.DateTimeFormat("fr-FR").format(new Date(dateNaissance)),
    },
];
export function TableAppareil({ data }) {
    return (_jsx(Collapse, Object.assign({ defaultActiveKey: ["1"], ghost: true, accordion: true, expandIconPosition: "right" }, { children: _jsx(CustomPanel, Object.assign({ header: _jsx(SectionTitle, { children: _jsx(Badge, Object.assign({ color: "#ED5B2E", count: data.length, offset: [15, 5] }, { children: "Appareils install\u00E9s" }), void 0) }, void 0) }, { children: _jsx(AdsTable, { dataSource: data, columns: columns, pagination: false, size: "small" }, void 0) }), "1") }), void 0));
}
