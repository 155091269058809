var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { Select as SelectANTD } from "antd";
import { FormItemWrapper, Input, Label, SelectWrapper } from "@ads/ui";
import { useApi } from "@ads/react-common-module";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetAllTypeTiersFromAPI } from "../../api/useGetAllTypeTiersFromAPI";
const WrapperFormItems = styled("div") `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`;
const schema = yup.object().shape({
    typeTiers: yup.array().of(yup.string()),
    qui: yup.string(),
    ou: yup.string(),
});
const FormSelectDestinataires = ({ setFormData, formData, }) => {
    const { handleSubmit, control } = useForm({
        resolver: yupResolver(schema),
    });
    const [typesTiers, setTiers] = useState([]);
    const api = useApi();
    const fetchTypeTiers = () => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield useGetAllTypeTiersFromAPI(api);
        // @ts-ignore
        setTiers(data);
    });
    useEffect(() => {
        fetchTypeTiers();
    }, []);
    const onSubmit = (formData) => {
        if (setFormData) {
            setFormData(formData);
        }
    };
    return (_jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs(WrapperFormItems, { children: [_jsx("div", { children: _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "typeTiers" }, { children: "Type de tiers" }), void 0), _jsx(SelectWrapper, Object.assign({ className: "type-objet", selectMode: "light" }, { children: _jsx(Controller, { control: control, name: "typesObjets", render: ({ field }) => (_jsx(SelectANTD, Object.assign({}, field, { defaultValue: ["INT", "GRD"], placeholder: "Choisissez un type de tiers", showSearch: true, allowClear: true, mode: "multiple", filterOption: (input, option) => {
                                            return (option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0 ||
                                                option.props.value
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0);
                                        }, onChange: (value) => {
                                            if (setFormData) {
                                                return setFormData(Object.assign(Object.assign({}, formData), { typeTiers: value }));
                                            }
                                            return void 0;
                                        } }, { children: typesTiers.map((typeTiers) => (_jsx(SelectANTD.Option, Object.assign({ value: typeTiers.typeTiersID }, { children: typeTiers.libelle }), void 0))) }), void 0)) }, void 0) }), void 0)] }), void 0) }, void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "qui" }, { children: "Qui" }), void 0), _jsx(Input, { id: "qui", name: "qui", type: "text", defaultValue: (formData === null || formData === void 0 ? void 0 : formData.searchValue) || (formData === null || formData === void 0 ? void 0 : formData.qui), onChange: (e) => {
                                const newValue = e.currentTarget.value;
                                setFormData && setFormData(Object.assign(Object.assign({}, formData), { qui: newValue }));
                            }, placeholder: "Nom ou pr\u00E9nom du tiers", selectMode: "light" }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "ou" }, { children: "O\u00F9" }), void 0), _jsx(Input, { id: "o\u00F9", name: "o\u00F9", defaultValue: formData === null || formData === void 0 ? void 0 : formData.où, onChange: (e) => {
                                const newValue = e.currentTarget.value;
                                setFormData && setFormData(Object.assign(Object.assign({}, formData), { où: newValue }));
                            }, type: "text", placeholder: "Adresse du tiers", selectMode: "light" }, void 0)] }), void 0)] }, void 0) }), void 0));
};
export default FormSelectDestinataires;
