import styled from "styled-components";
import { ConfigAppareilSpecTiersVisiteAPI } from "@ads/isadom-model";

const Details = styled.div`
  grid-area: details;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 0 0 1.7rem;

  ${({ theme }) => theme.media.sm} {
    padding: 1.7rem;
  }
  ${({ theme }) => theme.media.lg} {
    padding: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;

const AppareilRef = styled.div`
  font-weight: bold;
  display: block;
  font-size: 1.5rem;
  line-height: 2.1rem;
  ${({ theme }) => theme.media.lg} {
    display: inline-block;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;

const AppareilNom = styled.div`
  font-weight: bold;
  display: block;
  ${({ theme }) => theme.media.lg} {
    display: inline-block;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;

const Separator = styled.div`
  font-weight: bold;
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: inline-block;
    margin: 0 6px;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;

const AppareilAdresse = styled.div`
  font-family: ${({ theme }) => theme.font.type.primaryMedium};

  margin-top: 1rem;
  font-size: 1.3rem;
  line-height: 1.7rem;

  ${({ theme }) => theme.media.md} {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  ${({ theme }) => theme.media.lg} {
    margin-top: 0.6rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

interface PropsDetailItemAppareil {
  configurationProduitSpec: ConfigAppareilSpecTiersVisiteAPI;
}

const DetailAppareilItem = ({
  configurationProduitSpec,
}: PropsDetailItemAppareil) => (
  <Details>
    <div>
      <AppareilNom>{configurationProduitSpec.produit.sLibelle}</AppareilNom>
      <Separator>-</Separator>
      <AppareilRef>
        {configurationProduitSpec.produit.sRefProduitAppareil}
      </AppareilRef>
      <AppareilAdresse>
        {configurationProduitSpec.adresseInstallation.sAdresseComplete}
      </AppareilAdresse>
    </div>
  </Details>
);

export default DetailAppareilItem;
