import {
  natureProduit,
  ProduitRechercheDTOAPI,
  TypeProduitModel,
} from "@ads/isadom-model";
import {
  SelectionConsommable,
  SelectionConsommableProps,
} from "@ads/module-produit";
import { SelectionRetourConsommableProps } from "@ads/module-consommables-visite";
import {
  getDefaultApiInstance,
  ERROR_UNKNOWN,
  BaseError,
} from "@ads/react-common-module";
import { Alert } from "antd";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";
import { useState, useEffect } from "react";
import { apiGetTypeProduitByID } from "api/appareil";
import { Loading } from "@ads/ui";

export function SelectionRetourConsommable(
  props: SelectionRetourConsommableProps
) {
  const [defaultTypeProduit, setDefaultTypeProduit] =
    useState<TypeProduitModel>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiGetTypeProduitByID(getDefaultApiInstance(), "CSO")
      .then((result) => {
        setDefaultTypeProduit(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleConsommableSelected(produits: ProduitRechercheDTOAPI[]) {
    if (produits.length === 1) {
      props.onConsommableSelected(produits);
    } else if (produits.length > 1) {
      throw new BaseError(
        "CSO-LIV-SELECT-CSO",
        "Impossible d'ajouter plusieurs consommables en même temps."
      );
    }
  }

  if (loading && !defaultTypeProduit) {
    return (
      <Loading>
        Chargement du composant pour l'ajout d'un consommable en cours.
      </Loading>
    );
  } else if (!loading && defaultTypeProduit) {


    const selectionConsommableProps: SelectionConsommableProps = {
      config: {
        natureConfig: {
          allowChange: false,
          defaultSelectedData: [natureProduit("C")],
          typeSelection: "simple",
          visible: false,
        },
        typeProduitConfig: {
          allowChange: true,
          defaultSelectedData: [defaultTypeProduit],
          typeSelection: "simple",
          visible: true,
        },
        familleConfig: {
          allowChange: true,
          defaultSelectedData: [],
          typeSelection: "simple",
          visible: true,
        },
        sousFamilleConfig: {
          allowChange: true,
          defaultSelectedData: [],
          typeSelection: "simple",
          visible: true,
        },
        typePrestationConfig: {
          allowChange: true,
          defaultSelectedData: [],
          typeSelection: "simple",
          visible: true,
        },
      },
      onConsommablesSelected: handleConsommableSelected,
    };

    return <SelectionConsommable {...selectionConsommableProps} />;
  } else {
    return (
      <WrapperAlert>
        <Alert
          showIcon
          description={`Erreur lors du chargement du composant d'ajout de consommable (${ERROR_UNKNOWN}).`}
          type="error"
        />
      </WrapperAlert>
    );
  }
}
