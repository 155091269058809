import { adsNotification } from "@ads/ui";
import { QuestionnaireModelStatus, } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiGetAllTypeQuestionnaire } from "../../../api/apiGetAllTypeQuestionnaire";
import { apiPutVisiteQuestionnaire } from "../../../api/apiPutVisiteQuestionnaire";
import { calculateScore } from "../../../features";
export function useQuestionnaireForm(input) {
    /****************************************/
    /**************LOCAL STATE***************/
    /****************************************/
    const history = useHistory();
    const [typeQuestionnairesDisponibles, setTypeQuestionnairesDisponibles] = useState([]);
    const [typeQuestionnaire, setTypeQuestionnaire] = useState();
    const [questionnaire, setQuestionnaire] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    /****************************************/
    /**************INTERNAl FUNCION**********/
    /****************************************/
    const updateScore = (questionnaire) => {
        questionnaire.score = calculateScore(questionnaire);
        setQuestionnaire(Object.assign({}, questionnaire));
    };
    const updateTypeQuestionnaire = (typeQuestionnaire) => {
        setTypeQuestionnaire(typeQuestionnaire);
        const dateToday = new Date().toISOString().slice(0, 10);
        setQuestionnaire({
            typeQuestionnaire: typeQuestionnaire,
            themes: typeQuestionnaire.themes,
            information: "",
            dateSaisie: dateToday,
            scoreMax: typeQuestionnaire.scoreMax,
            dateValidation: "",
            visiteID: 0,
            examenID: 0,
            gereValidation: typeQuestionnaire.besoinValidation,
            nautVisiteID: input.visiteID,
            questionnaireID: 0,
            score: 0,
            status: QuestionnaireModelStatus.NOTHING,
            visProgrammeID: 0,
            tiersQuestionne: {
                tiersID: input.tiersVisiteID,
            },
        });
    };
    const cancelCreateQuestionnaire = () => {
        history.push(`/visites/${input.visiteID}/questionnaires/supplémentaires`);
    };
    const uploadQuestionnaire = () => {
        return new Promise(() => {
            if (questionnaire) {
                console.log("nous avons un questionnaire");
                setIsLoading(true);
                return apiPutVisiteQuestionnaire(getDefaultApiInstance(), input.visiteID, questionnaire)
                    .then(() => {
                    adsNotification.success({ message: "Questionnaire enregistré !" });
                    history.push(`/visites/${input.visiteID}/questionnaires/supplémentaires`);
                })
                    .catch(() => {
                    adsNotification.error({
                        message: "Questionnaire non-enregistré !",
                    });
                })
                    .finally(() => {
                    setIsLoading(false);
                });
            }
            else {
                throw new Error("Function not implemented.");
            }
        });
    };
    const onUpdate = (questionnaire) => {
        questionnaire.score = calculateScore(questionnaire);
        setQuestionnaire(Object.assign({}, questionnaire));
    };
    /****************************************/
    /**************     Effect     **********/
    /****************************************/
    useEffect(() => {
        setIsLoading(true);
        setError(false);
        apiGetAllTypeQuestionnaire()
            .then((result) => {
            setTypeQuestionnairesDisponibles(result);
            setIsLoading(false);
        })
            .catch(() => {
            setIsLoading(false);
            setError(true);
        });
    }, []);
    return {
        questionnaire: questionnaire,
        typeQuestionnaire: typeQuestionnaire,
        typeQuestionnairesDisponibles: typeQuestionnairesDisponibles,
        isLoading: isLoading,
        error: error,
        cancelCreateQuestionnaire: cancelCreateQuestionnaire,
        setTypeQuestionnaire: setTypeQuestionnaire,
        uploadQuestionnaire: uploadQuestionnaire,
        onUpdate: onUpdate,
        updateScore: updateScore,
        updateTypeQuestionnaire: updateTypeQuestionnaire,
    };
}
