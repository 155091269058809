import { useState, useEffect } from "react";
import { useList } from "@ads/ui";
import { useApi } from "@ads/react-common-module";
/**
 * @description
 * Hook pour manipuler la structure liste de ChecklistModel
 *
 * S'initialise à l'aide de la fonction injecter dans IBaseChecklistListHookInput
 *
 * * @date 22/12/2021
 */
export function useBaseChecklistList(input) {
    /****************************************/
    /***************LOCAL VAR****************/
    /****************************************/
    const [initialData, setInitialData] = useState([]);
    const api = useApi();
    useEffect(() => {
        input.getDataFunction(api, input.fetchParams).then((result) => {
            setInitialData(result);
        });
    }, []);
    const hookInput = {
        data: initialData,
        isEquals: input.isEquals,
    };
    const legacyHook = useList(hookInput);
    /***********************************************/
    /**************INTERNAL FUNCTIONS***************/
    /***********************************************/
    /****************************************/
    /**************INHERITANCE***************/
    /****************************************/
    /****************************************/
    /**************LOCAL STATE***************/
    /****************************************/
    /****************************************/
    /*****************EFFECT*****************/
    /****************************************/
    return Object.assign({}, legacyHook);
}
