import { FC, useState } from "react";
import styled from "styled-components";
import { Select } from "antd";
// Assets
import copier from "assets/img/copier.svg";
// Components
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import { GrilleAPI, ReglageAPI, ReglageValeurAPI } from "@ads/isadom-model";

const WrapperSelect = styled("div")`
  position: relative;

  .ant-select {
    width: 100%;
    ${({ theme }) => theme.media.xl} {
      width: auto;
    }
  }

  .ant-select-arrow {
    right: 7.1rem;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 8.4rem;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: ${({ theme }) => theme.colors.neutral[140]};
  }
`;

const IconCopy = styled("div")`
  position: absolute;
  top: 1px;
  right: 1px;
  border-radius: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.9rem;
  height: 4.8rem;
  background-color: ${({ theme }) => theme.colors.neutral[110]};
  cursor: pointer;

  &:after {
    content: "";
    background: url(${copier}) no-repeat;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const { Option } = Select;

type OptionSelect =
  | ""
  | "theoPrecedentToTheo"
  | "theoPrecedentToConstate"
  | "constatPrecedentToConstate";

interface Props {
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
}

/**
 * @desc Select qui permet de copier les données précédemments saisies qui se trouve dans "derniereGrille" dans le state du formulaire, stateFormGrilleReglage
 * @author Johan Petrikovsky
 * @date 2021-10-11
 */

const SelectCopierLesDonnees: FC<Props> = ({
  modesReglagesAppareil,
  setModesReglagesAppareil,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<OptionSelect>("");

  const handleChange = (selectValue: OptionSelect) => {
    setSelectedValue(selectValue);
  };

  const indexGrille = modesReglagesAppareil.grilleActive;

  const grilleSaisi = modesReglagesAppareil.modesSaisis.find(
    (modeSaisi) => modeSaisi.reglageModeID === modesReglagesAppareil.modeActif
  )?.tabGrille[indexGrille];

  const copyGrilleSaisie = { ...grilleSaisi } as GrilleAPI;

  const grillePrecedent = modesReglagesAppareil.modesPrecedents.find(
    (modeSaisi) => modeSaisi.reglageModeID === modesReglagesAppareil.modeActif
  )?.tabGrille[indexGrille];

  // Mise à jour de la grille courante
  const updateGrille = (newGrille: GrilleAPI) => {
    // Je vais mettre à jour mon state modeReglageAppareil
    const newModesReglagesAppareil: ModesReglagesAppareil = {
      ...modesReglagesAppareil,
      modesSaisis: modesReglagesAppareil.modesSaisis.map((modeSaisi) => {
        if (modeSaisi.reglageModeID === modesReglagesAppareil.modeActif) {
          return {
            ...modeSaisi,
            tabGrille: modeSaisi.tabGrille.map((grille, indexCurrentGrille) => {
              if (indexCurrentGrille === indexGrille) {
                return newGrille;
              }
              return grille;
            }),
          };
        }
        return modeSaisi;
      }),
    };
    setModesReglagesAppareil(newModesReglagesAppareil);
  };

  const updateReglageValeurs = (
    typeReglageOrigin: string,
    typeReglageDestination: string
  ): void => {
    // J efface d abord la donnee courante
    const reglages: ReglageAPI[] = copyGrilleSaisie.tabReglage.map(
      (reglage) => {
        // Je pars à la recherche d'un réglage en fonction du type sélectionné (Théorique, constaté... )
        const indexReglage = reglage.tabReglageValeur.findIndex(
          (reglageValeur) =>
            reglageValeur.sTypeReglage === typeReglageDestination
        );
        // Si j'ai une entrée j'efface la valeur
        if (indexReglage !== -1) {
          reglage.tabReglageValeur[indexReglage].sValeur = "";
        }
        // Je retourne mon réglage qui a pu être modifié
        return reglage;
      }
    );
    const grilleVide: GrilleAPI = {
      ...copyGrilleSaisie,
      tabReglage: reglages,
    };

    updateGrille(grilleVide);

    // Je copie ensuite les donnees precendentes
    if (grillePrecedent) {
      grillePrecedent.tabReglage.forEach((reglagePrecedent, indexReglage) => {
        // Si j'ai des valeurs (theo / constate)
        if (reglagePrecedent.tabReglageValeur.length > 0) {
          reglagePrecedent.tabReglageValeur.forEach(
            (reglageValeurPrecedent) => {
              // Je test si c est le type de reglage a copier
              if (reglageValeurPrecedent.sTypeReglage === typeReglageOrigin) {
                // Je récupère le réglage et je le mets à jour vers le nouveau type de réglage
                const copyReglageValeurPrecedent = {
                  ...reglageValeurPrecedent,
                  sTypeReglage: typeReglageDestination,
                }; // (théorique précédent vers theorique)

                // Je parcours les grilles saisies, à la recherche du même réglage, et du même réglage valeur avec les indices précédent
                if (
                  copyGrilleSaisie.tabReglage[indexReglage].tabReglageValeur
                    .length > 0
                ) {
                  // J'ai déjà des réglages valeurs, je vérifie si un réglage de "destination" existe déjà.
                  const indexReglageValeur = copyGrilleSaisie.tabReglage[
                    indexReglage
                  ].tabReglageValeur.findIndex(
                    (reglageValeur) =>
                      reglageValeur.sTypeReglage === typeReglageDestination
                  );
                  // Une concordance a été trouvée
                  if (indexReglageValeur !== -1) {
                    copyGrilleSaisie.tabReglage[indexReglage].tabReglageValeur[
                      indexReglageValeur
                    ] = copyReglageValeurPrecedent;
                  } else {
                    // Il n'y a pas encore de réglage de destination je peux push directement
                    copyGrilleSaisie.tabReglage[
                      indexReglage
                    ].tabReglageValeur.push(copyReglageValeurPrecedent);
                  }
                } else {
                  // Sinon pas de réglage déjà présent, je push mon réglage précédent dans mon réglage saisi
                  copyGrilleSaisie.tabReglage[
                    indexReglage
                  ].tabReglageValeur.push(copyReglageValeurPrecedent);
                }
              } else {
                // Si je ne trouve pas de re
                const index = reglagePrecedent.tabReglageValeur.findIndex(
                  (reglageValeur) =>
                    reglageValeur.sTypeReglage === typeReglageOrigin
                );
                if (index === -1) {
                  // Je copie du vide
                  const reglageVide: ReglageValeurAPI = {
                    reglageID: 0,
                    codeReglageID: "",
                    sLibelleReglage: "",
                    sTypeReglage: "T",
                    sValeur: "",
                    dSaisie: "",
                    bInterpretation: false,
                    iCPNautReglageGrille: 0,
                    sIDRegroupement: "",
                    bModeHoraire: false,
                    bTheoriqueInactif: false,
                    tabReglageValeurHoraire: [],
                  };
                  // je vais chercher dans ma grille saisie si j ai un reglage qui correspond
                  const indexCopy = copyGrilleSaisie.tabReglage[
                    indexReglage
                  ].tabReglageValeur.findIndex(
                    (reglageValeur) =>
                      reglageValeur.sTypeReglage === typeReglageDestination
                  );
                  // Je viens ecraser avec la valeur existante avec la valeur vide
                  if (indexCopy !== -1) {
                    copyGrilleSaisie.tabReglage[indexReglage].tabReglageValeur[
                      indexCopy
                    ] = reglageVide;
                  }
                }
              }
            }
          );
        }
      });

      updateGrille(copyGrilleSaisie);
    }
  };

  const handleClick = () => {
    if (selectedValue === "theoPrecedentToTheo") {
      updateReglageValeurs("T", "T");
    }
    if (selectedValue === "theoPrecedentToConstate") {
      updateReglageValeurs("T", "C");
    }
    if (selectedValue === "constatPrecedentToConstate") {
      updateReglageValeurs("C", "C");
    }
  };

  return (
    <WrapperSelect>
      <Select style={{ width: 330 }} defaultValue="" onChange={handleChange}>
        <Option value="">Copier les données vers...</Option>
        <Option value="theoPrecedentToTheo">
          Théorique précédent vers théorique
        </Option>
        <Option value="theoPrecedentToConstate">
          Théorique précédent vers constaté
        </Option>
        <Option value="constatPrecedentToConstate">
          Constaté précédent vers constaté
        </Option>
      </Select>
      <IconCopy onClick={handleClick} />
    </WrapperSelect>
  );
};

export default SelectCopierLesDonnees;
