import { apiGet } from "@ads/react-common-module";
import { FamilleAdapter } from "@ads/isadom-model";
export const apiGetFamilleByTypeProduit = (api, typeProduitID) => {
    if (api) {
        const adapter = new FamilleAdapter();
        return apiGet(api, `/types-produits/${typeProduitID}/familles`, adapter);
    }
    return new Promise((_, reject) => {
        reject("La configuration api est null");
    });
};
