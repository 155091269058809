import styled from "styled-components";
import ContentReglage from "./ContentReglage";
import { motion } from "framer-motion";
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import { FC } from "react";

const WrapperTable = styled(motion.div)`
  position: relative;
  width: 100%;
  padding: 1.4rem 1rem 0 1rem;
  /* margin-top: -11rem; */

  ${({ theme }) => theme.media.md} {
    padding: 1.4rem 2rem 0 2rem;
  }
`;

interface Props {
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
  shouldRefetch: boolean;
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}
const ModeReglage: FC<Props> = ({
  modesReglagesAppareil,
  setModesReglagesAppareil,
  shouldRefetch,
  setShouldRefetch,
}: Props) => {
  return (
    <WrapperTable
      exit={{ y: 10 }}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <>
        <ContentReglage
          setShouldRefetch={setShouldRefetch}
          modesReglagesAppareil={modesReglagesAppareil}
          shouldRefetch={shouldRefetch}
          setModesReglagesAppareil={setModesReglagesAppareil}
        />
      </>
    </WrapperTable>
  );
};

export default ModeReglage;
