import styled from "styled-components";

const Infos = styled.div`
  grid-area: infos;
  padding-top: 1rem;
  border-top: 1px solid #ededed;

  .infos__date-differente {
    display: none;

    ${({ theme }) => theme.media.xl} {
      display: block;
      margin-bottom: 8px;
      color: ${({ theme }) => theme.colors.danger[100]};
      font-size: 1.6rem;
      .isOpenLeft & {
        display: none !important;
      }
    }
  }

  ${({ theme }) => theme.media.xl} {
    .isOpenLeft & {
      padding: 1rem 0;
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
      border-left: none;
      border-right: none;
    }
  }

  ${({ theme }) => theme.media.lg} {
    padding-bottom: 1rem;

    border-bottom: 1px solid #ededed;
    margin-bottom: 1rem;
  }

  ${({ theme }) => theme.media.xl} {
    margin-bottom: 0rem;
    border-bottom: 0;
    border-left: 1px solid #ededed;
    border-top: 0;
    border-right: 1px solid #ededed;
    padding: 0 4rem;
  }
`;

export default Infos;
