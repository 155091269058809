var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { apiListeConsommablesRetoursVisite } from "../api/apiListeConsommablesRetoursVisite";
import { apiEnregistreConsommablesRetours } from "../api/apiEnregistreConsommablesRetours";
import { apiRaZConsommablesRetoursVisite } from "../api/apiRaZConsommablesRetoursVisite";
import { adsNotification } from "@ads/ui";
import { apiGetConsommableByProduitID } from "../api/apiGetConsommableByProduitID";
import { apiGetTypePrestationByFamilleID } from "../api/apiGetTypePrestationsByFamilleId";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { apiListeConsommablesConfigurationVisite } from "../api/apiListeConsommablesConfigurationVisite";
export const useConsommablesRetoursForm = (visiteID) => {
    const [isLoadingFetch, setIsLoadingFetch] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingReset, setIsLoadingReset] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState([]);
    const fetchData = (visiteID) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoadingFetch(true);
            const [dataRetours, config] = yield Promise.all([
                apiListeConsommablesRetoursVisite(visiteID),
                apiListeConsommablesConfigurationVisite(visiteID),
            ]);
            setData(dataRetours.sort((a, b) => {
                if (a.ligneRetourID < b.ligneRetourID) {
                    return -1;
                }
                if (a.ligneRetourID > b.ligneRetourID) {
                    return 1;
                }
                if (a.consommable.sLibelle < b.consommable.sLibelle) {
                    return -1;
                }
                if (a.consommable.sLibelle > b.consommable.sLibelle) {
                    return 1;
                }
                return 0;
            }));
            setConfig(config);
        }
        catch (err) {
            console.error(err);
            setIsError(true);
        }
        finally {
            setIsLoadingFetch(false);
        }
    });
    const refreshData = (retour) => {
        const retoursData = data.map((r) => r.ligneRetourID === retour.ligneRetourID ? retour : r);
        setData(retoursData.sort((a, b) => {
            if (a.ligneRetourID < b.ligneRetourID) {
                return -1;
            }
            if (a.ligneRetourID > b.ligneRetourID) {
                return 1;
            }
            if (a.consommable.sLibelle < b.consommable.sLibelle) {
                return -1;
            }
            if (a.consommable.sLibelle > b.consommable.sLibelle) {
                return 1;
            }
            return 0;
        }));
    };
    const refreshAllData = (newData) => {
        const retoursData = [];
        data.forEach((r) => {
            retoursData.push(Object.assign(Object.assign({}, r), newData));
        });
        setData(retoursData.sort((a, b) => {
            if (a.ligneRetourID < b.ligneRetourID) {
                return -1;
            }
            if (a.ligneRetourID > b.ligneRetourID) {
                return 1;
            }
            if (a.consommable.sLibelle < b.consommable.sLibelle) {
                return -1;
            }
            if (a.consommable.sLibelle > b.consommable.sLibelle) {
                return 1;
            }
            return 0;
        }));
    };
    const removeData = (ligneRetourID) => {
        const formData = data.filter((r) => r.ligneRetourID !== ligneRetourID);
        setData(formData.sort((a, b) => {
            if (a.ligneRetourID < b.ligneRetourID) {
                return -1;
            }
            if (a.ligneRetourID > b.ligneRetourID) {
                return 1;
            }
            if (a.consommable.sLibelle < b.consommable.sLibelle) {
                return -1;
            }
            if (a.consommable.sLibelle > b.consommable.sLibelle) {
                return 1;
            }
            return 0;
        }));
    };
    const addData = (produit) => __awaiter(void 0, void 0, void 0, function* () {
        const { produitID, libelle: sLibelle, refFournissseurPrincipal: sRefProduit, } = produit;
        const { data: consommables } = yield apiGetConsommableByProduitID(getDefaultApiInstance(), produitID);
        const consommable = consommables[0];
        const prestations = yield apiGetTypePrestationByFamilleID(consommable.familleID);
        const retour = {
            ligneRetourID: -new Date().getTime(),
            consommable: {
                IDProduit: parseInt(produitID, 10),
                sRefProduit,
                sFKTypeProduit: "",
                sCodeNatureProduit: "",
                sLibelle,
                sRefFabricant: "",
                bInactif: false,
                bPrevisionInactif: false,
                dArretCdeInterne: "",
                bArretCommande: false,
                typeProduit: {
                    typeProduitId: consommable.typeProduitID,
                    libelle: consommable.libelleTypeProduit,
                    codeNatureProduit: consommable.nature,
                    suiviTiers: false,
                    suiviIntervention: false,
                    inactif: false,
                },
                famille: {
                    idFamille: consommable.familleID,
                    libelle: consommable.libelleFamille,
                    suiviCompteur: false,
                    suiviReglage: false,
                    observance: false,
                    inactif: false,
                    sousFamille: [],
                    tTypePrestation: prestations,
                },
                iFKCodePriorite: -1,
                sCodePriorite: "",
                bSuiviLot: false,
                bSuiviDatePeremption: false,
                bSuiviNumeroLot: false,
                bSuiviSortie: consommable.suiviLotSortie,
                bKit: false,
                bControleLivraison: false,
                iQuantiteConfig: -1,
                iFrequenceConfig: -1,
                iFrequenceInventaire: -1,
                sFKTypeIntervention: "",
                bInfoLivraisonProduitSubstitution: false,
                bIgnoreControleConformite: false,
                iMultipleCdeCatalogue: -1,
                sTypePeremption: "",
            },
            iQuantite: 0,
            lot: undefined,
            localisationRetour: data[0].localisationRetour,
        };
        const present = data.find((r) => r.consommable.IDProduit === parseInt(produitID, 10));
        if (!present || consommable.suiviLotSortie) {
            setData([retour, ...data]);
        }
        else {
            adsNotification.error({
                message: "Le consommable est déjà présent dans la liste.",
            });
        }
    });
    const saveData = (visiteID, retours) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoadingSave(true);
            const payload = retours.map((r) => r.ligneRetourID < 0 ? Object.assign(Object.assign({}, r), { ligneRetourID: -1 }) : r);
            const { data } = yield apiEnregistreConsommablesRetours(+visiteID, payload);
            adsNotification.success({
                message: "Les données des retours ont été enregistrées.",
            });
            setData(data.sort((a, b) => {
                if (a.ligneRetourID < b.ligneRetourID) {
                    return -1;
                }
                if (a.ligneRetourID > b.ligneRetourID) {
                    return 1;
                }
                if (a.consommable.sLibelle < b.consommable.sLibelle) {
                    return -1;
                }
                if (a.consommable.sLibelle > b.consommable.sLibelle) {
                    return 1;
                }
                return 0;
            }));
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsLoadingSave(false);
        }
    });
    const resetData = (visiteID) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoadingReset(true);
            yield apiRaZConsommablesRetoursVisite(visiteID);
            adsNotification.success({
                message: "Les données des retours ont été réinitialisées.",
            });
            fetchData(visiteID);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsLoadingReset(false);
        }
    });
    const isDataConfig = (retour) => {
        return !!config.find((c) => c.produit.IDProduit === retour.consommable.IDProduit);
    };
    useEffect(() => {
        if (visiteID) {
            fetchData(visiteID);
        }
    }, [visiteID]);
    return {
        data,
        isLoadingFetch,
        refreshData,
        refreshAllData,
        saveData,
        isLoadingSave,
        removeData,
        addData,
        isError,
        resetData,
        isLoadingReset,
        isDataConfig,
    };
};
