import { ProgrammeEducatifModel } from "@ads/isadom-model";
import { LoadingView } from "components-v2/loading/LoadingView";
import { RouteParam } from "interface/core/RouteParam";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { ProgrammeEducatifFormAdapter } from "../common/ProgrammeEducatifFormAdapter";
import { BaseError, ERROR_UNKNOWN, Form } from "@ads/react-common-module";
import useQuery from "hooks/useQuery";
import { useSelectVisiteStore } from "modules/visites/store/visiteStore";
import {
  useGetPEByTiersIDAndIsaID,
  useGetPEByVisiteIdAndNautID
} from "modules/suivi-patient/programme-educatif/features";
import { useFormPE } from "modules/suivi-patient/programme-educatif/hooks/useFormPE";
import {
  useDeletePEByVisiteIDAndID
} from "modules/suivi-patient/programme-educatif/features/useDeletePEByVisiteIDAndID";
import { DataSourceDeleteResult } from "@ads/react-common-module";

export interface ProgrammeEducatifFormVisiteTemplateProps {
  visiteID: number;
}

export function ProgrammeEducatifModifFormVisiteTemplate({
  visiteID
}: ProgrammeEducatifFormVisiteTemplateProps) {
  const { peID, iPKVisite } = useParams<RouteParam>();

  //on récupère la source du PE depuis les query parameters (param source)
  const query = useQuery();
  const source = query.get("source") as "isadom" | "isanaut" | null;
  //Si la source n'est aps trouvé alors on lève une exception
  if (!source) {
    throw new BaseError(
      ERROR_UNKNOWN,
      "Il manque le query parameter source (isadom | isanaut)."
    );
  }

  //on récupère la visite pour récupérer le tiersID
  const { visitesMap } = useSelectVisiteStore();
  const visite = visitesMap.get(+iPKVisite);
  let tiersID = -1;
  //si la souce est isadom et que la visite n'est pas trouvé on lève une exception
  if (source === "isadom" && !visite) {
    throw new BaseError(
      ERROR_UNKNOWN,
      "La visite n'a pas été trouvé impossible de récupérer le tiers correspondant."
    );
  } else if (source === "isadom" && visite) {
    tiersID = visite.tiersVisite.tiersID;
  }

  const fetchService =
    source === "isadom"
      ? useGetPEByTiersIDAndIsaID(tiersID, +peID)
      : useGetPEByVisiteIdAndNautID(visiteID, +peID);

  const deleteService = useDeletePEByVisiteIDAndID({
    visiteID,
    peID: +peID
  }) as DataSourceDeleteResult<
    ProgrammeEducatifModel,
    Record<string, string | number>
  >;

  const services = useFormPE({
    visiteID,
    fetchService,
    deleteService
  });

  function withFetchLoading(): ReactNode {
    return (
      <>
        <LoadingView libelle="du programme éducatif" />
      </>
    );
  }

  return (
    <Form
      services={services}
      formAdapter={ProgrammeEducatifFormAdapter}
      withFetchLoading={withFetchLoading}
      withSaveLoading={null}
      withDeleteLoading={null}
    />
  );
}
