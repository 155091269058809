import { HttpResponseModel, PrescriptionAPI } from "@ads/isadom-model";
import { apiSearchV2, getDefaultApiInstance } from "@ads/react-common-module";
export interface PrescriptionSearchParams {
  dDebut?: string;
  dFin?: string;
  tiersID: number;
  enCours?: string;
  prestationID?: number;
  codePrescriptionID?: string;
  tCodeEtat?: [string];
}

export function apiGetPrescriptionsByTiersID(
  searchParams: PrescriptionSearchParams
): Promise<HttpResponseModel<PrescriptionAPI[]>> {
  const api = getDefaultApiInstance();
  if (api) {
    const prescriptions = apiSearchV2(
      api,
      `prescriptions/recherche`,
      searchParams
    );
    return prescriptions as unknown as Promise<
      HttpResponseModel<PrescriptionAPI[]>
    >;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
