var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { TypePrestationAdapter } from "../../medical/prestation/adapter";
import { SousFamilleAdapter } from "./SousFamilleAdapter";
var FamilleAdapter = /** @class */ (function (_super) {
    __extends(FamilleAdapter, _super);
    function FamilleAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typePrestationAdapter = new TypePrestationAdapter();
        _this.sousFamilleAdapter = new SousFamilleAdapter();
        return _this;
    }
    FamilleAdapter.prototype.toClient = function (src) {
        return {
            idFamille: src.sPKFamille,
            libelle: src.sLibelle,
            suiviCompteur: src.bSuiviCompteur,
            suiviReglage: src.bSuiviReglage,
            observance: src.bObservance,
            inactif: src.bInactif,
            sousFamille: this.sousFamilleAdapter.toClients(src.tSousFamille),
            tTypePrestation: this.typePrestationAdapter.toClients(src.tTypePrestation),
        };
    };
    FamilleAdapter.prototype.toAPI = function (src) {
        return {
            sPKFamille: src.idFamille,
            sLibelle: src.libelle,
            bSuiviCompteur: src.suiviCompteur,
            bSuiviReglage: src.suiviReglage,
            bObservance: src.observance,
            bInactif: src.inactif,
            tSousFamille: src.sousFamille && this.sousFamilleAdapter.toAPIs(src.sousFamille),
            tTypePrestation: this.typePrestationAdapter.toAPIs(src.tTypePrestation),
        };
    };
    return FamilleAdapter;
}(Adapter));
export { FamilleAdapter };
