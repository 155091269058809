import { useApi } from "@ads/react-common-module";
import { apiPutVisiteQuestionnaire } from "../../../api/apiPutVisiteQuestionnaire";
import { useQuestionnaireListAutoFetch } from "./useQuestionnaireListAutoFetch";
export function useQuestionnaireListVisite(visiteID, tiersVisiteID, getQuestionnaireFromApi, add, title) {
    const api = useApi();
    //Valeur par défaut des options de la listview
    const options = {
        title: title,
    };
    const createQuestionnaire = (questionnaire) => {
        questionnaire.tiersQuestionne = {
            tiersID: tiersVisiteID,
        };
        return new Promise((resolve, reject) => {
            apiPutVisiteQuestionnaire(api, visiteID, Object.assign(Object.assign({}, questionnaire), { nautVisiteID: visiteID }))
                .then((questionnaire) => {
                resolve(questionnaire);
            })
                .catch((err) => {
                reject(err);
            });
        });
    };
    const service = useQuestionnaireListAutoFetch({
        dataFetchFunction: getQuestionnaireFromApi,
        fetchParams: visiteID,
        optionsList: options,
        add: add,
        createQuestionnaire: createQuestionnaire,
    });
    return {
        optionsList: service.optionsList,
        questionnaires: service.questionnaires,
        fetchError: service.fetchError,
        questionnairesLoading: service.questionnairesLoading,
        filter: service.filter,
        createQuestionnaire: service.createQuestionnaire,
        createQuestionnaireFromType: service.createQuestionnaireFromType,
        addFiltersOptions: service.addFiltersOptions,
        setBaseQuestionnaires: service.setBaseQuestionnaires,
    };
}
