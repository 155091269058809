import styled from "styled-components";
import { Select, Checkbox } from "antd";
import { ConfigAppareilSpecTiersAPI } from "@ads/isadom-model";

import SelectWrapper from "components/ui/atoms/forms/SelectWrapper";

const { Option } = Select;

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 14.5rem;
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 2rem;
  margin-bottom: 2.5rem;
  width: 100%;
  
  ${({ theme }) => theme.media.lg} {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 4.5rem;
    padding: 0 2rem;
    background: ${({ theme }) => theme.colors.neutral[100]};
    border-radius: 5rem;
    margin-bottom: 0;
  }
`;

const Label = styled("label")`
  margin-bottom: 1rem;
  ${({ theme }) => theme.media.lg} {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[110]};

    margin: 0 2.5rem 0 0;
    padding-right: 1.3rem;
    .item {
      font-size: 1.4rem;
      opacity: 0.65;
    }
  }
`;

const WrapperFilterLeft = styled("span")`
  min-width: 8rem;
`;

const SelectWrapperCustom = styled(SelectWrapper)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({ theme }) => theme.colors.neutral[130]};
    cursor: pointer;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 4.5rem;
    border-radius: 1.3rem;
    min-height: auto;
  }


  ${({ theme }) => theme.media.lg} {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: ${({ theme }) => theme.colors.neutral[100]};
      cursor: pointer;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 4.5rem;
      border-radius: 1.3rem;
      width: 16rem;
      min-height: auto;
    }
  }


  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    position: relative;
    top: 2px;
    line-height: 4.5rem;
    font-family: ${({ theme }) => theme.font.type.primaryMedium};
  }

  /* Focus */
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    box-shadow: 0;
  }
`;

interface FiltresConfigurationAppareilsProps {
  prestation: string[];
  masquerAppareilsAbsents: boolean;
}
interface PropsFilterAppareil {
  listeAppareils: ConfigAppareilSpecTiersAPI[];
  setFiltersAppareils: React.Dispatch<
    React.SetStateAction<FiltresConfigurationAppareilsProps>
  >;
  filtresAppareils: FiltresConfigurationAppareilsProps;
}

const FiltresConfigurationAppareils = ({
  listeAppareils,
  setFiltersAppareils,
  filtresAppareils,
}: PropsFilterAppareil) => {
  const itemsPrestationAppareils = listeAppareils
    .map((appareil) => appareil.typePrestation.sPKTypePrestation)
    .filter(
      (prestation, index, tab) =>
        tab.indexOf(prestation) === index && prestation !== ""
    );

  const handleChange = (value: string) => {
    if (typeof value === "undefined") {
      setFiltersAppareils({ ...filtresAppareils, prestation: [] });
    } else {
      setFiltersAppareils({
        ...filtresAppareils,
        prestation: [value],
      });
    }
  };
  const onChange = (e: any) => {
    if (e.target.checked === true) {
      setFiltersAppareils({
        ...filtresAppareils,
        masquerAppareilsAbsents: true,
      });
    } else {
      setFiltersAppareils({
        ...filtresAppareils,
        masquerAppareilsAbsents: false,
      });
    }
  };

  return (
    <Wrapper>
      <Label>
        <WrapperFilterLeft className="item">
          Filtrer par prestation :
        </WrapperFilterLeft>
        <SelectWrapperCustom className="select-wrapper">
          <Select
            dropdownClassName="customDropDownFilterAppareils"
            showSearch
            optionFilterProp="children"
            onChange={handleChange}
            disabled={itemsPrestationAppareils?.length < 1}
            allowClear
          >
            {itemsPrestationAppareils?.map((prestation) => (
              <Option value={prestation} key={prestation}>
                {prestation}
              </Option>
            ))}
          </Select>
        </SelectWrapperCustom>
      </Label>
      <Checkbox onChange={onChange}>Masquer les appareils absents</Checkbox>
    </Wrapper>
  );
};

export default FiltresConfigurationAppareils;
