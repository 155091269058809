var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { ButtonResetLivraisonsConso } from "../atoms/ButtonResetLivraisonsConso";
import { BLSelection } from "./BLSelection";
const Wrapper = styled.div `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;

  ${({ theme }) => theme.media.lg} {
    gap: 0;
    flex-direction: row;
  } ;
`;
const WrapperTitre = styled.h1 `
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 0 0 1rem;
  font-weight: normal;
  font-size: 2rem;
  line-height: 5rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2.2rem;
  }
`;
const WrapperActions = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    gap: 2rem;
  } ;
`;
export const LivraisonsConsommablesListHeader = ({ visiteID, nbConso, SelectionLivraisonConsommable, resetData, isLoadingReset, addData, refreshData, }) => {
    const onSelect = (produits) => __awaiter(void 0, void 0, void 0, function* () {
        addData(produits[0]);
        // try {
        //   const {
        //     produitID,
        //     libelle: sLibelle,
        //     refFournissseurPrincipal: sRefProduit,
        //   } = produits[0];
        //   const result: any = await apiGetConsommableByProduitID(
        //     getDefaultApiInstance(),
        //     produitID
        //   );
        //   const consommable = result.data[0];
        //   const prestations = await apiGetTypePrestationByFamilleID(
        //     consommable.familleID
        //   );
        //   const livraison = {
        //     ligneLivraisonID: -new Date().getTime(),
        //     consommable: {
        //       IDProduit: parseInt(produitID),
        //       sRefProduit,
        //       sFKTypeProduit: "",
        //       sCodeNatureProduit: "",
        //       sLibelle,
        //       sRefFabricant: "",
        //       bInactif: false,
        //       bPrevisionInactif: false,
        //       dArretCdeInterne: "",
        //       bArretCommande: false,
        //       typeProduit: {
        //         typeProduitId: consommable.typeProduitID,
        //         libelle: consommable.libelleTypeProduit,
        //         codeNatureProduit: consommable.nature,
        //         suiviTiers: false,
        //         suiviIntervention: false,
        //         inactif: false,
        //       },
        //       famille: {
        //         idFamille: consommable.familleID,
        //         libelle: consommable.libelleFamille,
        //         suiviCompteur: false,
        //         suiviReglage: false,
        //         observance: false,
        //         inactif: false,
        //         sousFamille: [],
        //         tTypePrestation: prestations,
        //       },
        //       iFKCodePriorite: -1,
        //       sCodePriorite: "",
        //       bSuiviLot: false,
        //       bSuiviDatePeremption: false,
        //       bSuiviNumeroLot: false,
        //       bSuiviSortie: consommable.suiviLotSortie,
        //       bKit: false,
        //       bControleLivraison: false,
        //       iQuantiteConfig: -1,
        //       iFrequenceConfig: -1,
        //       iFrequenceInventaire: -1,
        //       sFKTypeIntervention: "",
        //       bInfoLivraisonProduitSubstitution: false,
        //       bIgnoreControleConformite: false,
        //       iMultipleCdeCatalogue: -1,
        //       sTypePeremption: "",
        //     },
        //     appareil: undefined,
        //     iQuantite: 0,
        //     lot: undefined,
        //     typePrestation: undefined,
        //   };
        //   const present = livraisonsConsommables.find(
        //     (livraison) => livraison.consommable.IDProduit === parseInt(produitID)
        //   );
        //   if (present) {
        //     if (["FM", "PD"].indexOf(consommable.typeProduitID) !== -1) {
        //       if (configAppareils.length > 1) {
        //         setLivraisonsConsommables([livraison, ...livraisonsConsommables]);
        //       } else {
        //         throw new Error("Aucun appareil pour ce consommable");
        //       }
        //     } else {
        //       if (prestations.length > 1) {
        //         setLivraisonsConsommables([livraison, ...livraisonsConsommables]);
        //       } else {
        //         throw new Error(
        //           "Une livraison existe déjà pour ce consommable (1 seule prestation)"
        //         );
        //       }
        //     }
        //   } else {
        //     setLivraisonsConsommables([livraison, ...livraisonsConsommables]);
        //   }
        // } catch (err) {
        //   adsNotification.error({
        //     message:
        //       err.message ||
        //       "Une erreur est survenue lors de l'enregistrement de la livraison de ce consommable.",
        //   });
        //   console.error(err);
        // }
    });
    return (_jsxs(Wrapper, { children: [_jsxs(WrapperTitre, { children: [nbConso === 0 && `Aucun consommable`, nbConso === 1 && `1 consommable`, nbConso > 1 && `${nbConso} consommables`] }, void 0), _jsxs(WrapperActions, { children: [_jsx(BLSelection, { visiteID: visiteID, refreshData: refreshData }, void 0), _jsx(SelectionLivraisonConsommable, { onConsommableSelected: onSelect }, void 0), nbConso > 0 && (_jsx(ButtonResetLivraisonsConso, { resetData: resetData, isLoadingReset: isLoadingReset, visiteID: visiteID }, void 0))] }, void 0)] }, void 0));
};
