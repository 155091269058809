import styled from "styled-components";

const SelectWrapper = styled("div")`
  /* Placeholder */
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    color: ${(props) => props.theme.colors.neutral[180]};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: ${(props) => props.theme.colors.neutral[110]};
    color: ${(props) => props.theme.colors.neutral[170]};
  }
  .ant-select {
    width: 100% !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 5rem;
    border-radius: 1.3rem;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 5rem;
  }

  /* Centrage input */
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    display: flex;
    align-items: center;
  }
  /* Arrow */
  .ant-select-arrow {
    color: ${(props) => props.theme.colors.neutral[180]};
  }
  /* Focus */
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 2px solid ${(props) => props.theme.colors.neutral[150]};
    box-shadow: 0 0 2px ${(props) => props.theme.colors.neutral[110]};
  }
`;

export default SelectWrapper;
