import { useFormSelectionItem, } from "../../../../common";
import { searchModeles } from "../../../feature/searchModeles";
export function useFormSelectionModeleItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.produitID;
        },
        config: input.config,
        initParams: input.initParams,
        isDataMatchWithFilter(data, filter) {
            if ((filter === null || filter === void 0 ? void 0 : filter.familleID) && data.familleID !== (filter === null || filter === void 0 ? void 0 : filter.familleID)) {
                return false;
            }
            if ((filter === null || filter === void 0 ? void 0 : filter.sousFamilleID) && data.sousFamilleID !== filter.sousFamilleID) {
                return false;
            }
            if ((filter === null || filter === void 0 ? void 0 : filter.typeProduitID) && data.typeProduitID != filter.typeProduitID) {
                return false;
            }
            return true;
        },
        onGetDataRequested: searchModeles,
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
