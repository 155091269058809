import { FormItemWrapper, Label, Loading } from "@ads/ui";
import {
  ISelectionAppareilInput,
  SelectionAppareil,
} from "@ads/module-produit";
import { natureProduit, TypeProduitModel } from "@ads/isadom-model";
import { AppareilRechercheAPI } from "@ads/isadom-model";
import { apiGetTypeProduitByID } from "api/appareil";
import { useEffect, useState } from "react";
import { ERROR_UNKNOWN, getDefaultApiInstance } from "@ads/react-common-module";
import { Alert } from "antd";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";
import { ParamDisponibilite } from "@ads/module-produit/lib/esm/appareil/component/selection/hook/interface/IParamDisponibilite";

export interface IInstallationAppareilProps {
  requestInstallationAppareil(conso: AppareilRechercheAPI): void;
}

export function InstallationAppareil(props: IInstallationAppareilProps) {
  const [defaultTypeProduit, setDefaultTypeProduit] =
    useState<TypeProduitModel>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiGetTypeProduitByID(getDefaultApiInstance(), "APP")
      .then((result) => {
        setDefaultTypeProduit(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading && !defaultTypeProduit) {
    return (
      <Loading>
        Chargement du composant pour l'installation d'un appareil en cours.
      </Loading>
    );
  } else if (!loading && defaultTypeProduit) {
    const config: ISelectionAppareilInput = {
      natureConfig: {
        allowChange: false,
        defaultSelectedData: [natureProduit("M")],
        typeSelection: "simple",
        visible: false,
      },
      typeProduitConfig: {
        allowChange: true,
        defaultSelectedData: [defaultTypeProduit],
        typeSelection: "simple",
        visible: true,
      },
      familleConfig: {
        allowChange: true,
        defaultSelectedData: [],
        typeSelection: "simple",
        visible: true,
      },
      sousFamilleConfig: {
        allowChange: true,
        defaultSelectedData: [],
        typeSelection: "simple",
        visible: true,
      },
      disponibiliteConfig: {
        allowChange: true,
        defaultSelectedData: [ParamDisponibilite.disponible],
        typeSelection: "simple",
        visible: true,
      },
      localisationConfig: {
        allowChange: true,
        defaultSelectedData: [],
        typeSelection: "simple",
        visible: true,
      },
      modeleConfig: {
        allowChange: true,
        defaultSelectedData: [],
        typeSelection: "simple",
        visible: true,
      },
      typelocalisationConfig: {
        allowChange: true,
        defaultSelectedData: [],
        typeSelection: "simple",
        visible: true,
      },
    };

    return (
      <FormItemWrapper direction="vertical">
        <Label htmlFor="">Installer un appareil</Label>
        <SelectionAppareil
          hasScanner={true}
          config={config}
          onMaterielsSelected={(materiels) => {
            materiels[0] && props.requestInstallationAppareil(materiels[0]);
          }}
        />
      </FormItemWrapper>
    );
  } else {
    return (
      <WrapperAlert>
        <Alert
          showIcon
          description={`Erreur lors du chargement du composant d'installation d'appareil (${ERROR_UNKNOWN}).`}
          type="error"
        />
      </WrapperAlert>
    );
  }
}
