import { InputV3 } from "@ads/ui";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const FormNomDeVoie = () => {
  return (
    <Wrapper>
      <label htmlFor="voie-name">Nom de la voie</label>
      <InputV3 type="text" id="voie-name" name="voie-name" />
    </Wrapper>
  );
};

export default FormNomDeVoie;
