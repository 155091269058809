export const CODE_ERROR = {};
export function addErrorCode(code, message) {
    CODE_ERROR[code] = message + " (" + code + ")";
}
const MESSAGE_ERROR_UNKNOWN = "Une erreur inconnue est survenue, veuillez contacter votre administrateur système";
export const ERROR_UNKNOWN = "UNK";
addErrorCode(ERROR_UNKNOWN, MESSAGE_ERROR_UNKNOWN);
//DEV
export const MSG_ERROR_DEV_UNKNOWN = "Une erreur de dev inconnue est survenu, veuillez contacter votre administrateur système.";
export const ERROR_DEV_UNKNOWN = "UNK-DEV";
addErrorCode(ERROR_DEV_UNKNOWN, MSG_ERROR_DEV_UNKNOWN);
//HTTP
export const ERROR_HTTP_UNKNOWN = "HTTP-UNK";
addErrorCode(ERROR_HTTP_UNKNOWN, MESSAGE_ERROR_UNKNOWN);
export const ERROR_HTTP_UNKNOWN_POST = "HTTP-UNK-POST";
addErrorCode(ERROR_HTTP_UNKNOWN_POST, MESSAGE_ERROR_UNKNOWN);
export const ERROR_HTTP_UNKNOWN_GET = "HTTP-UNK-GET";
addErrorCode(ERROR_HTTP_UNKNOWN_GET, MESSAGE_ERROR_UNKNOWN);
export const ERROR_HTTP_UNKNOWN_DELETE = "HTTP-UNK-DELETE";
addErrorCode(ERROR_HTTP_UNKNOWN_DELETE, MESSAGE_ERROR_UNKNOWN);
export const ERROR_HTTP_UNKNOWN_SEARCH = "HTTP-UNK-SEARCH";
addErrorCode(ERROR_HTTP_UNKNOWN_SEARCH, MESSAGE_ERROR_UNKNOWN);
export const ERROR_HTTP_UNKNOWN_PUT = "HTTP-UNK-PUT";
addErrorCode(ERROR_HTTP_UNKNOWN_PUT, MESSAGE_ERROR_UNKNOWN);
export const ERROR_HTTP_API_NULL = "HTTP-CONF";
addErrorCode(ERROR_HTTP_API_NULL, MESSAGE_ERROR_UNKNOWN);
//SEARCH
export const ERROR_APP_SEARCH_LIMIT = "APP-SEARCH-LIMIT";
addErrorCode(ERROR_APP_SEARCH_LIMIT, "La recherche dépasse la limite de 1000 appareils. Veuillez affiner vos critères de recherche.");
