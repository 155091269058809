import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import { Alert } from "antd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormWidget, Loading } from "@ads/ui";
// Hooks
import {
  useDeleteAppareilMutation,
  useGetVisiteQuery
} from "../../../../store/redux/services/nomadApi";
// Interfaces
import { IErrorBackEnd } from "../../../../interface/erreur/api/errorBackEnd";
import { RetraitAppareil } from "../../../../interface/retraitAppareil";
import { RouteParam } from "../../../../interface/core/RouteParam";
// Functions
import errorAdapter from "../../../../utils/errors/errorAdapter";
import formNotification from "../../../../utils/errors/formNotification";
// Components
import MotifsRetrait from "../../molecules/MotifsRetrait";
import LocalisationArriveeRetrait from "../../molecules/LocalisationArriveeRetrait";
import InformationRetrait from "../../molecules/InformationRetrait";
import FormCompteur, {
  FieldsFormCompteur,
  schemaFieldsCompteur
} from "../../molecules/form/FormCompteur";
import WrapperError from "../wrappers/WrapperError";

import { useGetConfigurationAppareilsTiersFromAPI } from "api/appareil";
import {
  ConfigProduitSpecAPI,
  LocalisationInterneAutoriseeAPI
} from "@ads/isadom-model";

const schema = yup.object().shape({
  motif: yup
    .object()
    .shape({ idMotif: yup.number().required(), information: yup.string() }),
  ...schemaFieldsCompteur
});
const FormStyle = styled("form")`
  max-width: 84rem;
  textarea {
    background-color: ${({ theme }) => theme.colors.neutral[120]};
  }
`;

const Title = styled("h2")`
  margin: 0 0 1.2rem 0;
  padding-bottom: 0.8rem;
  font-weight: bold;
  font-size: 1.6rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
`;

const FormWidgetAppareil = styled(FormWidget)`
  &.form-widget-background {
    background-color: transparent;
    box-shadow: none;
  }
`;

type CompteurActuel = {
  iCompteurActuel?: number;
};

export interface FormRetrait extends FieldsFormCompteur {
  motif: {
    idMotif: string;
  };
  information: string;
  localisationRetrait: LocalisationInterneAutoriseeAPI | string;
}

/**
 * Formulaire de retrait d'un appareil
 * @author Johan Bastien
 * @date 2021-09-13
 */
const Retrait = () => {
  const [postRetrait, { error, isSuccess, isLoading }] =
    useDeleteAppareilMutation();

  const history = useHistory();
  const { IDProduit, iPKVisite } = useParams<RouteParam>();
  const { data: visite } = useGetVisiteQuery(+iPKVisite);

  // Localisation de retrait par défaut
  const localisationRetourAppareil =
    visite?.intervenantPrincipal.tLocalisationAutorise.find(
      (localisation: LocalisationInterneAutoriseeAPI) =>
        localisation.bLocalisationRetourDM === true
    );

  const localisationsAutorisees =
    visite?.intervenantPrincipal?.tLocalisationAutorise;

  const localisationsAutoriseesTriees = localisationsAutorisees && [
    ...localisationsAutorisees
  ];

  localisationsAutoriseesTriees &&
    localisationsAutoriseesTriees.sort(function compare(a, b) {
      if (a.sLibelle < b.sLibelle) return -1;
      if (a.sLibelle > b.sLibelle) return 1;
      return 0;
    });

  //Trouver la loc par défault dans les loc autorisées
  const defaultValue = localisationsAutoriseesTriees?.find(
    (loc) =>
      loc.sNature === localisationRetourAppareil?.sNature &&
      loc.localisationId === localisationRetourAppareil.localisationId
  );

  const methods = useForm<FormRetrait>({
    resolver: yupResolver(schema),
    defaultValues: {
      localisationRetrait: JSON.stringify(defaultValue)
    }
  });

  const {
    data,
    loading,
    error: configError
  } = useGetConfigurationAppareilsTiersFromAPI({
    visiteID: iPKVisite
  });

  const configAppareil =
    data &&
    data.tabconfigProduitSpec.find(
      (config: ConfigProduitSpecAPI) => config.produit.IDProduit === +IDProduit
    );

  const onSubmit = async (formData: FormRetrait) => {
    if (configAppareil && formData) {
      let compteurActuel: CompteurActuel = {
        iCompteurActuel: 0
      };

      // Si le champ n'existe pas dans le formulaire ou si le compteur est hs, on ne renvoie rien au back
      if (!formData?.releveCompteur || formData?.compteurHS === true) {
        compteurActuel = {};
      } else if (configAppareil) {
        configAppareil.produit.compteur.iCompteurActuel =
          +formData?.releveCompteur;
      }

      //Enregistrement de l'input dans react hook form
      methods.register("localisationRetrait");

      const locRetraitAppareil: LocalisationInterneAutoriseeAPI = JSON.parse(
        formData.localisationRetrait as unknown as string
      );

      const dataAppareilToSend: RetraitAppareil = {
        appareilId: +IDProduit,
        visiteId: +iPKVisite,
        localisation: {
          localisationId:
            locRetraitAppareil.localisationId ||
            localisationRetourAppareil?.localisationId,
          sNature:
            locRetraitAppareil.sNature || localisationRetourAppareil?.sNature
        },
        motif: {
          iPKMotifRetraitDM: +formData.motif.idMotif
        },
        information: formData.information,
        compteur: {
          bCompteurHS: formData?.compteurHS || false, // Si l'appareil n'a pas de compteur on envoie quand même false au back
          iCompteurActuel: formData?.releveCompteur,
          ...compteurActuel
        }
      };
      postRetrait(dataAppareilToSend);
    }
  };

  useEffect(() => {
    if (error) {
      if ("data" in error) {
        errorAdapter(error.data as IErrorBackEnd)?.forEach((error) =>
          formNotification({ type: "error", error: error.message })
        );
      }
    }
    if (isSuccess) {
      formNotification({ type: "success" });
      const redirectLink = `/visites/${visite?.ID}/appareils/configuration`;
      history.push(redirectLink);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isSuccess, visite?.ID]);

  return (
    <>
      {loading ? (
        <Loading>Chargement en cours du formulaire de retrait...</Loading>
      ) : (
        configAppareil &&
        (visite?.bValide ? (
          <>
            <Title className="retrait">Retrait</Title>
            <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
              <MotifsRetrait />
              <LocalisationArriveeRetrait
                methods={methods}
                localisationsAutoriseesTriees={localisationsAutoriseesTriees}
                defaultValue={JSON.stringify(defaultValue)}
                selectedValue={defaultValue as LocalisationInterneAutoriseeAPI}
              />
              <InformationRetrait appareil={configAppareil.produit} />
              {configAppareil.produit.modele.bSuiviCompteur && (
                <FormCompteur produit={configAppareil.produit} />
              )}
            </FormStyle>
          </>
        ) : (
          <FormWidgetAppareil
            handleSave={methods.handleSubmit(onSubmit)}
            saveLabel="Retirer"
            isLoadingSave={isLoading}
            offsetX={-36}
            offsetY={-100}
          >
            <FormProvider {...methods}>
              <Title className="retrait">Retrait</Title>
              <FormStyle onSubmit={methods.handleSubmit(onSubmit)}>
                <MotifsRetrait />
                <LocalisationArriveeRetrait
                  methods={methods}
                  localisationsAutoriseesTriees={localisationsAutoriseesTriees}
                  defaultValue={JSON.stringify(defaultValue)}
                  selectedValue={
                    defaultValue as LocalisationInterneAutoriseeAPI
                  }
                />
                <InformationRetrait appareil={configAppareil.produit} />
                {configAppareil.produit.modele.bSuiviCompteur && (
                  <FormCompteur produit={configAppareil.produit} />
                )}
              </FormStyle>
            </FormProvider>
          </FormWidgetAppareil>
        ))
      )}
      {configError && (
        <WrapperError>
          <Alert
            message="Error"
            showIcon
            description="Une erreur est survenue pendant le chargement du formulaire de retrait."
            type="error"
          />
        </WrapperError>
      )}
    </>
  );
};

export default Retrait;
