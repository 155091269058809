import { FC, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
// Assets
import filters from "assets/icons/icon-filters-white.svg";
// Interfaces
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
// Components
import InputLibelle from "modules/appareils/ui/atoms/InputLibelle";
import CheckboxTheorique from "modules/appareils/ui/atoms/CheckboxTheorique";
import SelectCopierLesDonnees from "../atoms/SelectCopierLesDonnees";

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.media.xl} {
    flex-direction: row;
    padding: 0 0 1.5rem 0;
  }

  input[type="text"] {
    padding: 1.4rem 1.3rem;
    font-size: 1.4rem;
    border-radius: 1.3rem;
    background: ${({ theme }) => theme.colors.neutral[140]};
    border: none;

    ${({ theme }) => theme.media.xl} {
      min-width: 36.7rem;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.neutral[160]};
    }
  }
`;

const WrapperTitleButton = styled("div")`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const TitleTable = styled("h2")`
  position: relative;
  z-index: 10;
  top: 0.9rem;

  display: inline-block;
  margin: 0;
  padding: 1.8rem 3.8rem 1.2rem;
  font-size: 1.5rem;
  line-height: 2.1rem;
  background: ${({ theme }) => theme.colors.neutral[100]};
  font-weight: bold;
  border-top-left-radius: 1.8rem;
  border-top-right-radius: 1.8rem;

  ${({ theme }) => theme.media.xl} {
    top: 2.3rem;
  }
`;

const ButtonFilter = styled("div")`
  position: relative;
  top: 1.0rem;
  right: 1.2rem;

  width: 6rem;
  height: 5rem;

  border-top-right-radius: 1.3rem;
  border-bottom-right-radius: 1.3rem;
  background: ${({ theme }) => theme.colors.neutral[140]};
  cursor: pointer;

  ${({ theme }) => theme.media.xl} {
    display: none;
  }

  &:after {
    position: relative;
    top: 1.3rem;
    left: 2.5rem;
    content: url(${filters});
  }
`;

const Form = styled(motion.form)`
  order: -1;

  ${({ theme }) => theme.media.xl} {
    height: auto !important; /*  surcharge framermotion */
    order: 1;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 3.8rem;
  }
`;

const WrapperInput = styled("div")`
  margin-bottom: 1.6rem;
  ${({ theme }) => theme.media.xl} {
    max-width: 36.7rem;
    margin-bottom: 0;
  }
`;

const variants = {
  open: { opacity: 1, height: 151, y: 0, zIndex: 0 },
  closed: { opacity: 0, height: 0, y: "100%", zIndex: -1 },
};

interface Props {
  /**
   * Permet de modifier le libellé de la grille correspondante et copier les données
   */
  indexGrille: number;
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
}

const FormGrilleReglage: FC<Props> = ({
  indexGrille,
  modesReglagesAppareil,
  setModesReglagesAppareil,
}: Props) => {
  // Gestion des filtres sur mobile
  const [isFilterOpen, setFilterOpen] = useState(true);
  const handleClick = () => {
    setFilterOpen(!isFilterOpen);
  };

  return (
    <Wrapper>
      <WrapperTitleButton>
        <TitleTable>MODE {modesReglagesAppareil.modeActif}</TitleTable>
        <ButtonFilter onClick={handleClick} />
      </WrapperTitleButton>

      <Form animate={isFilterOpen ? "open" : "closed"} variants={variants}>
        <WrapperInput>
          <InputLibelle
            indexGrille={indexGrille}
            modesReglagesAppareil={modesReglagesAppareil}
            setModesReglagesAppareil={setModesReglagesAppareil}
          />
        </WrapperInput>
        <SelectCopierLesDonnees
          modesReglagesAppareil={modesReglagesAppareil}
          setModesReglagesAppareil={setModesReglagesAppareil}
        />
        <CheckboxTheorique
          modesReglagesAppareil={modesReglagesAppareil}
          setModesReglagesAppareil={setModesReglagesAppareil}
        />
      </Form>
    </Wrapper>
  );
};

export default FormGrilleReglage;
