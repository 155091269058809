import { notification } from "antd";
import axios from "axios";
import { IErrorBackEnd } from "interface/erreur/api/errorBackEnd";
import { useState } from "react";
import config from "../config";
import { PayloaGrilles } from "../interfaces/modes-reglages.interfaces";

/**
 * @desc Fonction de soumission du formulaire des grilles de réglages.
 * @author Johan Petrikovsky
 * @param visiteId
 * @param appareilId
 * @param shouldRefetch booléen définie dans le template appareil. Permet de redéclencer les appels backs et obtenir la donnée fraîche.
 * @param setShouldRefetch fonction définie dans le template appareil
 * @returns
 */
const usePostGrilles = (
  visiteId: string,
  appareilId: string,
  shouldRefetch: boolean,
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>();

  async function postGrilles(payload: PayloaGrilles) {
    setIsLoading(true);
    try {
      await axios.post(
        `${config.apiURL}visites/${visiteId}/appareils/${appareilId}/modes-reglages`,
        payload
      );
      notification.success({
        message: "Succès",
        description: `Le traitement de vos données a été effectué avec succès.`
      });
      setShouldRefetch(!shouldRefetch); // Mise à jour du state pour déclencher un rerendu
    } catch (error: any) {
      if (error.response?.status === 500) {
        const error: IErrorBackEnd = {
          metiers: [
            {
              type: "S",
              code: "",
              message: "Une erreur inatendue est survenue",
              idConcerne: ""
            }
          ]
        };
        setErrors(error);
      } else if (error.response) {
        setErrors(error.response.data);
      } else {
        const error: IErrorBackEnd = {
          metiers: [
            {
              type: "S",
              code: "",
              message: "Une erreur interne est survenue",
              idConcerne: ""
            }
          ]
        };
        setErrors(error);
      }
      // eslint-disable-next-line no-console
      console.error(
        "👨‍🚒 Une erreur est survenue dans useEditObservance.ts -->",
        error
      );

      setErrors(true);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    errors,
    isLoading,
    postGrilles
  };
};

export default usePostGrilles;
