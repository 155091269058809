var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
var ConfigConsoSpecTiersVisiteDTOAdapter = /** @class */ (function (_super) {
    __extends(ConfigConsoSpecTiersVisiteDTOAdapter, _super);
    function ConfigConsoSpecTiersVisiteDTOAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConfigConsoSpecTiersVisiteDTOAdapter.prototype.toClient = function (src) {
        var _a;
        return {
            produitID: src.produit.IDproduit,
            sTypeConfig: src.sTypeConfig,
            configCsoID: src.configCsoID,
            iQuantite: src.iQuantite,
            iFrequence: src.iFrequence,
            dDebut: src.dDebut,
            dFin: src.dFin,
            bPreparation: src.bPreparation,
            bTest: src.bTest,
            sInformation: src.sInformation,
            bAfficherCR: src.bAfficherCR,
            bAfficherOrdo: src.bAfficherOrdo,
            bAfficherGED: src.bAfficherGED,
            configCsoVisiteID: src.configCsoVisiteID,
            bAjoutConfig: src.bAjoutConfig,
            bRetraitConfig: src.bRetraitConfig,
            typePrestationID: ((_a = src.typePrestation) === null || _a === void 0 ? void 0 : _a.sPKTypePrestation) || "",
            dDerniereLivraison: src.dDerniereLivraison,
            iQuantiteDerniereLivraison: src.iQuantiteDerniereLivraison,
        };
    };
    ConfigConsoSpecTiersVisiteDTOAdapter.prototype.toAPI = function (src) {
        return {
            produit: { IDproduit: src.produitID },
            sTypeConfig: src.sTypeConfig,
            configCsoID: src.configCsoID,
            iQuantite: src.iQuantite,
            iFrequence: src.iFrequence,
            dDebut: src.dDebut,
            dFin: src.dFin,
            bPreparation: src.bPreparation,
            bTest: src.bTest,
            sInformation: src.sInformation,
            bAfficherCR: src.bAfficherCR,
            bAfficherOrdo: src.bAfficherOrdo,
            bAfficherGED: src.bAfficherGED,
            configCsoVisiteID: src.configCsoVisiteID,
            bAjoutConfig: src.bAjoutConfig,
            bRetraitConfig: src.bRetraitConfig,
            typePrestation: { sPKTypePrestation: src.typePrestationID || "" },
            dDerniereLivraison: src.dDerniereLivraison,
            iQuantiteDerniereLivraison: src.iQuantiteDerniereLivraison,
        };
    };
    return ConfigConsoSpecTiersVisiteDTOAdapter;
}(Adapter));
export { ConfigConsoSpecTiersVisiteDTOAdapter };
