import styled from "styled-components";
import React from "react";
import logoNomad from "../../assets/img/nomad.svg";
import NavigationMain from "components/ui/molecules/NavigationMain";

export const HEADER_HEIGHT = 7;

const HeaderWrapper = styled.div`
  height: ${HEADER_HEIGHT}rem;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 4rem 5rem 2.5rem;
`;

const HomeLogo = styled.a``;

const ImgLogo = styled.img``;

export default function HeaderV2() {
  return (
    <HeaderWrapper className="header">
      <HomeLogo href="/">
        <ImgLogo src={logoNomad} />
      </HomeLogo>
      <NavigationMain />
    </HeaderWrapper>
  );
}
