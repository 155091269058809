import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Desktop, Mobile, Tablette } from "..";
export function GridLayout(props) {
    let gridTemplateColumnsDesktop = "";
    let gridTemplateColumnsTablette = "";
    let gridTemplateColumnsMobile = "";
    for (let i = 0; i < props.nbColumn; i++) {
        gridTemplateColumnsDesktop += "1fr ";
    }
    for (let i = 0; i < ((props.nbColumn / 3) | 0) * 2; i++) {
        gridTemplateColumnsTablette += "1fr ";
    }
    for (let i = 0; i < ((props.nbColumn / 3) | 0); i++) {
        gridTemplateColumnsMobile += "1fr ";
    }
    return (_jsxs(_Fragment, { children: [_jsx(Desktop, { children: _jsx("div", Object.assign({ style: Object.assign({ display: "grid", gridTemplateColumns: gridTemplateColumnsDesktop }, props) }, { children: props.children }), void 0) }, void 0), _jsx(Tablette, { children: _jsx("div", Object.assign({ style: Object.assign({ display: "grid", gridTemplateColumns: gridTemplateColumnsTablette }, props) }, { children: props.children }), void 0) }, void 0), _jsx(Mobile, { children: _jsx("div", Object.assign({ style: Object.assign({ display: "grid", gridTemplateColumns: gridTemplateColumnsMobile }, props) }, { children: props.children }), void 0) }, void 0)] }, void 0));
}
