export function useDeleteWithCache({ deleteFromCache, deleteFromRemote, criteria, }) {
    const fromRemote = deleteFromRemote(criteria);
    return {
        loading: fromRemote.loading,
        data: fromRemote.data,
        delete(pathParameters) {
            const result = fromRemote.delete(pathParameters);
            result.then((data) => {
                deleteFromCache(pathParameters, data);
            });
            return result;
        },
    };
}
