import { WORKFLOW_HEIGHT } from "components/ui/organisms/Workflow";
import { HEADER_HEIGHT } from "./Header-v2";
import { HeaderTemplate, NAV_HEIGHT } from "./HeaderTemplate";

export interface HeaderSaisieDeVisiteProps {
  iPKVisite: number;
  navigation: JSX.Element;
  displayWorkflow?: boolean;
}

export function HeaderSaisieDeVisite({
  iPKVisite,
  navigation,
  displayWorkflow
}: HeaderSaisieDeVisiteProps) {
  const size = HEADER_HEIGHT + NAV_HEIGHT + WORKFLOW_HEIGHT;
  return (
    <HeaderTemplate
      iPKVisite={iPKVisite}
      navigation={navigation}
      size={size}
      displayWorkflow={displayWorkflow}
    />
  );
}
