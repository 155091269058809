import { FormItemWrapper, Label } from "@ads/ui";
import { Input } from "antd";
import { Controller, useFormContext } from "react-hook-form";

import { AppareilAPI } from "@ads/isadom-model";
import { FormRetrait } from "../organisms/navigation-appareil/Retrait";

interface Props {
  appareil: AppareilAPI;
}

const { TextArea } = Input;

/**
 * Textarea pour gérer les commentaires d'un appareil.
 * @author Johan Petrikovsky
 * @date 2021-09-13
 * @param appareil - objet Appareil
 */
const InformationRetrait = ({ appareil }: Props) => {
  const { control } = useFormContext<FormRetrait>();

  return (
    <FormItemWrapper direction="vertical">
      <Label htmlFor="informations">Informations</Label>
      <Controller
        defaultValue={appareil.sInformation}
        control={control}
        name="information"
        render={({ field }) => (
          <TextArea
            {...field}
            rows={8}
            placeholder="Saisissez des informations complémentaires (facultatif)"
          />
        )}
      />
    </FormItemWrapper>
  );
};

export default InformationRetrait;
