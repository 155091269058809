import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import MenuTab from "components/ui/molecules/MenuTab";
import Fiche from "components/ui/organisms/navigation-appareil/Fiche";
import Intervention from "components/ui/organisms/navigation-appareil/Intervention";
import Retrait from "components/ui/organisms/navigation-appareil/Retrait";
import FormHeaderReglage from "./FormHeaderReglage";
import { RouteParam } from "interface/core/RouteParam";
import { HeaderAppareil } from "modules/appareils/interfaces/appareils.interfaces";
import { AppareilAPI, VisiteAPI } from "@ads/isadom-model";
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import { WidgetWrapper } from "@ads/ui";

const WrapperTab = styled.div`
  /* margin-top: 2.5rem; */
  position: relative;

  ${({ theme }) => theme.media.md} {
    background-color: ${({ theme }) => theme.colors.neutral[130]};
  }
`;

const ContentTab = styled("div")`
  position: relative;
  padding: 2.2rem 1.9rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  box-shadow: 0 8px 1rem rgb(80 70 70 / 25%);
  margin-bottom: 8.6rem;
  ${({ theme }) => theme.media.md} {
    position: static;
    padding: 4rem 6rem;
    margin-bottom: 0;
    box-shadow: 0 4px 1rem rgba(80, 70, 70, 0.25);
  }
`;

const WidgetWrapperReglage = styled(WidgetWrapper)`
  .content-tab {
    margin-bottom: 0;
  }
  &.widget-wrapper {
    padding-bottom: auto;
  }
  &.widget-wrapper-appareil {
    padding-bottom: 0;
  }
`;

interface Props {
  headerAppareil: HeaderAppareil;
  appareil: AppareilAPI | undefined;
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
  visite: VisiteAPI | undefined;
}

const PanelsAppareil = ({
  headerAppareil,
  appareil,
  modesReglagesAppareil,
  setModesReglagesAppareil,
  visite,
}: Props) => {
  const { subTabActive } = useParams<RouteParam>();

  return (
    <WidgetWrapperReglage
      className={
        subTabActive === "reglages"
          ? "widget-wrapper-appareil"
          : "widget-wrapper"
      }
    >
      <WrapperTab className="---wrapper-tab---">
        <MenuTab
          tabs={headerAppareil.tabsConfig}
          subTabActive={subTabActive || "fiche"}
        />
        <ContentTab className="content-tab">
          <motion.div
            exit={{ y: -10 }}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            {subTabActive === "fiche" && <Fiche visite={visite} />}
            {subTabActive === "reglages" && (
              <FormHeaderReglage
                appareil={appareil}
                modesReglagesAppareil={modesReglagesAppareil}
                setModesReglagesAppareil={setModesReglagesAppareil}
                visite={visite}
              />
            )}
            {subTabActive === "intervention" && <Intervention />}
            {subTabActive === "retrait" && <Retrait />}
          </motion.div>
        </ContentTab>
      </WrapperTab>
    </WidgetWrapperReglage>
  );
};

export default PanelsAppareil;
