var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TypeQuestionnaireAdapter, } from "@ads/isadom-model";
import { apiIsadomErrorStrategy } from "@ads/ui";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
export function apiGetAllTypeQuestionnaire() {
    return __awaiter(this, void 0, void 0, function* () {
        const adapter = new TypeQuestionnaireAdapter();
        const result = yield apiGetV2(getDefaultApiInstance(), "/types-questionnaires", apiIsadomErrorStrategy);
        return adapter.toClients(result);
    });
}
