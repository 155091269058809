import { EvenementStandardModel } from "@ads/isadom-model";
import styled from "styled-components";

const Details = styled.div`
  width: 100%;
`;

const Detail = styled.div`
  display: flex;
  margin: 0 0 1rem;
  width: 100%;
`;
const Nom = styled.div`
  margin: 0 1rem 0 0;
  min-width: 100%;
  font-weight: bold;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: 33%;
  }
`;
const Valeur = styled.div`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: 33%;
  }
`;

interface EvenementStandardDetailsProps {
  evenement: EvenementStandardModel;
}

export const EvenementStandardDetails = ({
  evenement,
}: EvenementStandardDetailsProps) => (
  <Details>
    <Detail>
      <Nom>Tiers d'origine</Nom>
      <Valeur>
        {evenement.tiersOrigine
          ? `${evenement.tiersOrigine.prenom} ${evenement.tiersOrigine.nom}`
          : ``}
      </Valeur>
    </Detail>
    <Detail>
      <Nom>Lieu d'exercice</Nom>
      <Valeur>{evenement.lieuExercice ? evenement.lieuExercice.ID : ``}</Valeur>
    </Detail>
    <Detail>
      <Nom>Prestation</Nom>
      <Valeur>
        {evenement.prestation ? evenement.prestation.type.libelle : ``}
      </Valeur>
    </Detail>
    <Detail>
      <Nom>Prescription</Nom>
      <Valeur>
        {evenement.prescription
          ? `${evenement.prescription.codePrescription.ID} du ${evenement.prescription?.dateDebut}`
          : ``}
      </Valeur>
    </Detail>
    <Detail>
      <Nom>Cure</Nom>
      <Valeur>{evenement.cure ? evenement.cure : ``}</Valeur>
    </Detail>
    <Detail>
      <Nom>Précision</Nom>
      <Valeur>{evenement.precision}</Valeur>
    </Detail>
    <Detail>
      <Nom>Motif</Nom>
      <Valeur>{evenement.motif}</Valeur>
    </Detail>
  </Details>
);
