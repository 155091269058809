import {
  EvenementStandardModel,
  EvenementRGPDModel,
  EvenementTelesuiviModel,
  VisiteModel
} from "@ads/isadom-model";

const getBreadcrumbFormEvenement = (
  evenement:
    | EvenementStandardModel
    | EvenementRGPDModel
    | EvenementTelesuiviModel
    | undefined,
  visiteClient: VisiteModel | undefined,
  tabActive: string | undefined
) => {
  if (!visiteClient) return;

  const dateEvenement =
    evenement &&
    new Intl.DateTimeFormat("fr-FR").format(new Date(evenement.dateDebut));

  const labelBreadCrumb = evenement
    ? `${evenement.typeEvenement.libelle} le ${dateEvenement}`
    : `Nouvel évènement`;

  const breadcrumbsItems = [
    {
      item: "Évènements",
      to: `/visites/${visiteClient.ID}/evenements/${tabActive}`
    },
    {
      item: labelBreadCrumb,
      to: ""
    }
  ];

  return breadcrumbsItems;
};

export default getBreadcrumbFormEvenement;
