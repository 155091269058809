var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TypeFormulaireAdapter } from "../../formulaire/adapter";
import { Adapter } from "../../_adapter";
var IConstanteAdapter = /** @class */ (function (_super) {
    __extends(IConstanteAdapter, _super);
    function IConstanteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeFormulaireAdapter = new TypeFormulaireAdapter();
        return _this;
    }
    IConstanteAdapter.prototype.toClient = function (src) {
        return {
            id: src.id,
            libelle: src.sLibelle,
            code: src.sCode,
            dateSaisieValeur: src.dValeur,
            dateSaisieValeurPrecedente: src.dValeurPrecedente,
            duliquer: src.bDuliquer,
            inactif: src.bInactif,
            information: src.sInformation,
            parametrageID: src.parametrageID,
            typesFormulaires: this.typeFormulaireAdapter.toClients(src.typesFormulaires),
            valeur: src.sValeur,
            valeurMax: src.sValeurMax,
            valeurMin: src.sValeurMin,
            valeurPrecedente: src.sValeurPrecedente
        };
    };
    IConstanteAdapter.prototype.toAPI = function (src) {
        return {
            id: src.id,
            sLibelle: src.libelle,
            bDuliquer: src.duliquer,
            bInactif: src.inactif,
            dValeur: src.dateSaisieValeur,
            dValeurPrecedente: src.dateSaisieValeurPrecedente,
            parametrageID: src.parametrageID,
            sCode: src.code,
            sInformation: src.information,
            sValeur: src.valeur,
            sValeurMax: src.valeurMax,
            sValeurMin: src.valeurMin,
            sValeurPrecedente: src.valeurPrecedente,
            typesFormulaires: this.typeFormulaireAdapter.toAPIs(src.typesFormulaires),
        };
    };
    return IConstanteAdapter;
}(Adapter));
export { IConstanteAdapter };
