import { useFormModifierVisite } from "modules/feuille-de-route/components/FormModifVisite/hook/useFormModifierVisite";
import { useParams } from "react-router-dom";
import { RouteParam } from "interface/core/RouteParam";
import { VisiteAdapter, VisiteAPI } from "@ads/isadom-model";
import { Loading } from "@ads/ui";
import { FormModifierVisite } from "modules/feuille-de-route/components/FormModifVisite/ui/FormModifierVisite";
import ErrorAppareil from "modules/appareils/ui/molecules/ErrorAppareil";
import {
  useGetVisiteQuery,
  useUpdateVisiteMutation
} from "store/redux/services/nomadApi";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition
} from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import WrapperMain from "components/ui/organisms/wrappers/WrapperMain";
import WrapperMainContent from "components/ui/organisms/wrappers/WrapperMainContent";
import WrapperApp from "components/ui/organisms/wrappers/WrapperApp";
import { HeaderPageUpsertVisite } from "components-v2/header/HeaderPageUpsertVisite";

export const PageModifierVisite = () => {
  const { iPKVisite } = useParams<RouteParam>();
  const { data: visite, isLoading } = useGetVisiteQuery(+iPKVisite);
  const [updateVisite, { isLoading: isLoadingSave }] =
    useUpdateVisiteMutation();

  if (isLoading && !visite) {
    return (
      <WrapperApp>
        <HeaderPageUpsertVisite />
        <WrapperMain className="wrapper-main">
          <WrapperMainContent
            id="wrapper-main-content"
            className="wrapper-main-content"
          >
            <Loading>Chargement des informations de la visite</Loading>
          </WrapperMainContent>
        </WrapperMain>
      </WrapperApp>
    );
  } else if (visite) {
    return (
      <PageModifierVisiteWHenVisiteIsLoad
        isLoadingSave={isLoadingSave}
        visite={visite}
        updateVisite={updateVisite}
      />
    );
  } else {
    return (
      <WrapperApp>
        <HeaderPageUpsertVisite />
        <WrapperMain className="wrapper-main">
          <WrapperMainContent
            id="wrapper-main-content"
            className="wrapper-main-content"
          >
            <ErrorAppareil />
          </WrapperMainContent>
        </WrapperMain>
      </WrapperApp>
    );
  }
};

function PageModifierVisiteWHenVisiteIsLoad({
  visite,
  updateVisite,
  isLoadingSave
}: {
  visite: VisiteAPI;
  isLoadingSave: boolean;
  updateVisite: MutationTrigger<
    MutationDefinition<
      VisiteAPI,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        Record<string, any>,
        FetchBaseQueryMeta
      >,
      "Visite",
      VisiteAPI,
      "api"
    >
  >;
}) {
  const services = useFormModifierVisite({
    visite: visite ? new VisiteAdapter().toClient(visite) : null,
    postDataFeature: updateVisite
  });

  if (services.serviceInfoVisite) {
    return (
      <WrapperApp>
        <HeaderPageUpsertVisite />
        <WrapperMain className="wrapper-main">
          <WrapperMainContent
            id="wrapper-main-content"
            className="wrapper-main-content"
          >
            <FormModifierVisite
              isLoadingSave={isLoadingSave}
              services={services}
            />
          </WrapperMainContent>
        </WrapperMain>
      </WrapperApp>
    );
  } else {
    return (
      <WrapperApp>
        <HeaderPageUpsertVisite />
        <WrapperMain className="wrapper-main">
          <WrapperMainContent
            id="wrapper-main-content"
            className="wrapper-main-content"
          >
            <ErrorAppareil />
          </WrapperMainContent>
        </WrapperMain>
      </WrapperApp>
    );
  }
}
