var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { apiGetTypePrestationByFamilleID } from "../api/apiGetTypePrestationsByFamilleId";
export const useGetTypesPrestationsByFamilleId = (familleID) => {
    const [typesPrestations, setTypesPrestations] = useState();
    const [typesPrestationsErrors, setTypePrestationsErrors] = useState();
    const [isTypesPrestationsLoading, setIsTypesPrestationsLoading] = useState(false);
    const getTypesPrestations = (familleID) => __awaiter(void 0, void 0, void 0, function* () {
        setIsTypesPrestationsLoading(true);
        if (familleID) {
            yield apiGetTypePrestationByFamilleID(familleID)
                .then((result) => {
                setTypesPrestations(result);
                setTypePrestationsErrors(null);
                setIsTypesPrestationsLoading(false);
            })
                .catch((err) => {
                console.error("👨‍🚒 une erreur est survenue dans useGetTypesPrestationsByFamilleId ", err);
                setTypePrestationsErrors(err);
                setIsTypesPrestationsLoading(false);
            });
        }
    });
    useEffect(() => {
        getTypesPrestations(familleID);
    }, [familleID]);
    return {
        typesPrestations,
        isTypesPrestationsLoading,
        typesPrestationsErrors,
    };
};
