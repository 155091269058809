import { useState, useEffect } from "react";
import {
  IParametreEditionModel} from "@ads/isadom-model";
import { useList,IListHookInput } from "@ads/ui";
import { IBaseParametreEditionListHookInput } from "./interfaces/IBaseParametreEditionListHookInput";
import { IBaseParametreEditionListHookService } from "./interfaces/IBaseParametreEditionListHookService";
import {  useApi } from "@ads/react-common-module";

/**
 * @description
 * Hook pour manipuler la structure liste de IParametreEditionModel
 * 
 * S'initialise à l'aide de la fonction injecter dans IBaseParametreEditionListHookInput
 * 
 * * @date 22/12/2021
 */
export function useBaseParametreEditionList<FetchParams>(
  input: IBaseParametreEditionListHookInput<FetchParams>
): IBaseParametreEditionListHookService {
  /****************************************/
  /***************LOCAL VAR****************/
  /****************************************/
  
  const [initialData, setInitialData] = useState<IParametreEditionModel[]>([]);
  const api = useApi();

  useEffect(() => {
    input.getDataFunction(api, input.fetchParams).then((result) => {
      setInitialData(result);
    });
  }, []);

  useEffect(() => {
    setInitialData(input.data)
  }, [input.data])

  const hookInput: IListHookInput<IParametreEditionModel> = {
    data: initialData,
    isEquals: input.isEquals,
  };

  const legacyHook = useList(hookInput);

  /***********************************************/
  /**************INTERNAL FUNCTIONS***************/
  /***********************************************/


  /****************************************/
  /**************INHERITANCE***************/
  /****************************************/

  /****************************************/
  /**************LOCAL STATE***************/
  /****************************************/

  /****************************************/
  /*****************EFFECT*****************/
  /****************************************/

  return {
    ...legacyHook
  };
}