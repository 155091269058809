import { Select } from "@ads/ui";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.sm} {
    width: 45%;
  }
  ${({ theme }) => theme.media.md} {
    width: 100%;
  }
  ${({ theme }) => theme.media.xxl} {
    width: 45%;
  }
`;

const FormCodePostal = () => {
  return (
    <Wrapper>
      <label htmlFor="cp">CP</label>
      <Select id="cp" name="" selectMode="light" />
    </Wrapper>
  );
};

export default FormCodePostal;
