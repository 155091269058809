var ReponseAdapter = /** @class */ (function () {
    function ReponseAdapter() {
    }
    ReponseAdapter.prototype.toClients = function (srcs) {
        var result = [];
        for (var _i = 0, srcs_1 = srcs; _i < srcs_1.length; _i++) {
            var src = srcs_1[_i];
            result.push(this.toClient(src));
        }
        return result;
    };
    ReponseAdapter.prototype.toAPIs = function (srcs) {
        var result = [];
        for (var _i = 0, srcs_2 = srcs; _i < srcs_2.length; _i++) {
            var src = srcs_2[_i];
            result.push(this.toAPI(src));
        }
        return result;
    };
    ReponseAdapter.prototype.toClient = function (src) {
        return {
            reponseID: src.iPKQuestionnaireDetail,
            information: src.sInformation,
            libelle: src.sLibelle,
            ordre: src.iOrdre,
            score: src.iScore,
            valeur: src.sValeur
        };
    };
    ReponseAdapter.prototype.toAPI = function (src) {
        return {
            iPKQuestionnaireDetail: src.reponseID,
            iOrdre: src.ordre,
            iScore: src.score,
            sInformation: src.information,
            sLibelle: src.libelle,
            sValeur: src.valeur
        };
    };
    return ReponseAdapter;
}());
export { ReponseAdapter };
