import { HttpMessageModel } from "@ads/isadom-model";
import { Divider } from "antd";
import styled from "styled-components";
import { MultiRollingPanelFixedTitle } from "@ads/ui";

export interface VisiteErrorMessagePanelProps {
  messages: HttpMessageModel[] | undefined;
  visiteID: number;
}

const Wrapper = styled.div`
  .is-sticky & {
    height: calc(100vh - 18.6rem);
  }
  width: 100%;
  background: ${(props) => props.theme.colors.neutral[100]};
  background-color: white;
`;

const VisiteErrorContent = styled.div`
  padding: 8rem 2rem 0;
`;

export function VisiteErrorMessagePanel(props: VisiteErrorMessagePanelProps) {
  return (
    <Wrapper>
      <MultiRollingPanelFixedTitle title="Erreur de validation de la visite" />
      <br />
      <VisiteErrorContent>
        {props.messages?.map((data) => {
          return (
            <>
              {data.message}
              <br />
              <Divider />
            </>
          );
        })}
      </VisiteErrorContent>
    </Wrapper>
  );
}
