export function calculateScore(questionnaire) {
    var _a, _b, _c, _d;
    let sumOfQuestionScore = 0;
    let finalScore = 0;
    if (questionnaire.themes) {
        for (const theme of questionnaire.themes) {
            for (const question of theme.questions) {
                sumOfQuestionScore += (_a = question.reponseSaisie) === null || _a === void 0 ? void 0 : _a.score;
            }
        }
    }
    if ((_b = questionnaire.typeQuestionnaire) === null || _b === void 0 ? void 0 : _b.calcul) {
        const calcul = (_d = (_c = questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.typeQuestionnaire) === null || _c === void 0 ? void 0 : _c.calcul) === null || _d === void 0 ? void 0 : _d.toLowerCase().replace("<score>", sumOfQuestionScore.toString());
        // eslint-disable-next-line no-eval
        finalScore = eval(calcul);
    }
    else {
        finalScore = sumOfQuestionScore;
    }
    return finalScore;
}
