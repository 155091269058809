import styled from "styled-components";

const Card = styled("div")`
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);

  ${({ theme }) => theme.media.md} {
    padding: 2rem 3rem;
  }
`;

export default Card;
