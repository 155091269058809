import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Wrapper = styled.div `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  } ;
`;
const WrapperTitre = styled.h1 `
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 0 0 1rem;
  font-weight: normal;
  font-size: 2rem;
  line-height: 5rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2.2rem;
  }
`;
export const HistoriqueConsommationHeader = ({ visiteID, }) => {
    // console.log(visiteID);
    return (_jsx(Wrapper, { children: _jsx(WrapperTitre, { children: "Historique des consommations" }, void 0) }, void 0));
};
