import moment from "moment";
import "moment/locale/fr";

export const getDateFr = (date: string | Date) => {
  const dateFr = moment(date).locale("fr").format("L");
  if (dateFr === "Invalid date") {
    return "Date non renseignée";
  }
  return dateFr;
};

export const getTimeFromDate = (date: string | Date) =>
  moment(date).format("hh:mm");

export const getTimeFromBackTime = (time: string) => time.slice(0, -7);
