import styled from "styled-components";
import HeadersAdresse from "../molecules/HeadersAdresse";
import HeadersSelectLivraison from "../molecules/HeadersSelectLivraison";
import HeadersSelectPreparation from "../molecules/HeadersSelectPreparation";
import HeadersCommentaire from "../molecules/HeadersCommentaire";

const WrapperHeadersCommande = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  padding: 2rem;
  border-radius: 1rem;
  margin-right: 1rem;
  margin-top: 4.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  transition: all 0.2s ease-in-out;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
  }

  ${({ theme }) => theme.media.xl} {
    width: 30%;
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const WrapperSelects = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.md} {
    width: calc(100% / 3);
  }
  ${({ theme }) => theme.media.xl} {
    width: 100%;
  }
`;
const HeadersCommande = () => {
  return (
    <WrapperHeadersCommande>
      <HeadersAdresse />
      <WrapperSelects>
        <HeadersSelectPreparation />
        <HeadersSelectLivraison />
      </WrapperSelects>
      <HeadersCommentaire />
    </WrapperHeadersCommande>
  );
};

export default HeadersCommande;
