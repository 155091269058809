import { useState, useEffect } from "react";
import { Loading } from "@ads/ui";
import {
  EvenementDTOAdapter,
  EvenementDTOModel,
  EvenementTelesuiviDTOModel,
  EvenementTelesuiviDTOAdapter,
} from "@ads/isadom-model";
import { apiGetEvenementsByVisiteIDv2 } from "../../api";
import { EvenementListHeader } from "../organisms/EvenementListHeader";
import { EvenementRGPDList } from "../organisms/evenementRGPD";
import { EvenementStandardList } from "../organisms/evenementStandard";
import { EvenementTelesuiviList } from "../organisms/evenementTelesuivi";
import { useParams } from "react-router-dom";
import { RouteParam } from "interface/core/RouteParam";

export const EvenementsListeTemplate = () => {
  const { iPKVisite: visiteID, tabActive: tabActive } = useParams<RouteParam>()
  const [loading, setLoading] = useState<boolean>(false);
  const [evenements, setEvenements] = useState<
    EvenementDTOModel[] | EvenementTelesuiviDTOModel[]
  >([]);
  const [evenementsFiltrees, setEvenementsFiltrees] = useState<
    EvenementDTOModel[] | EvenementTelesuiviDTOModel[]
  >([]);

  useEffect(() => {
    const getEvts = async () => {
      try {
        let result;
        setLoading(true)
        if (tabActive === "rgpd") {
          const adapter = new EvenementDTOAdapter();
          result = await apiGetEvenementsByVisiteIDv2(+visiteID, adapter, tabActive);
        } else if (tabActive === "irelm-teleobs") {
          const adapter = new EvenementTelesuiviDTOAdapter();
          result = await apiGetEvenementsByVisiteIDv2(+visiteID, adapter, tabActive);
        } else {
          // Évènement non réservé
          const adapter = new EvenementDTOAdapter();
          if (!tabActive) return
          result = await apiGetEvenementsByVisiteIDv2(+visiteID, adapter, tabActive);
        }
        if (result) {
          setEvenements(result);
          setEvenementsFiltrees(result);
        }
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getEvts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiteID, tabActive]);


  return loading ? (
    <Loading>Chargement des évènements.</Loading>
  ) : (
    <div>
      <EvenementListHeader
        visiteID={+visiteID}
        data={evenements as []}
        nbEvenement={evenementsFiltrees.length}
        evenementType={tabActive || ""}
        setListFilters={setEvenementsFiltrees}
      />
      {tabActive === "irelm-teleobs" && (
        <EvenementTelesuiviList
          visiteID={+visiteID}
          type={tabActive}
          evenements={evenementsFiltrees}
        />
      )}
      {tabActive === "non-reserves" && (
        <EvenementStandardList
          visiteID={+visiteID}
          type={tabActive}
          evenements={evenementsFiltrees}
        />
      )}
      {tabActive === "rgpd" && (
        <EvenementRGPDList
          visiteID={+visiteID}
          type={tabActive}
          evenements={evenementsFiltrees}
        />
      )}
    </div>
  );
};
