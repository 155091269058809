var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useBaseChampList } from '../../.generated/list/hook/useBaseChampList';
export function useChampsList(input) {
    const inputBase = {
        getDataFunction: (_api, _params) => __awaiter(this, void 0, void 0, function* () { return input.data; }),
        fetchParams: null,
        data: input.data,
        isEquals: input.isEquals,
    };
    const listControler = useBaseChampList(inputBase);
    const listenerGroup = {
        idListener: "group",
        onBeforeDeleteItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onAfterDeleteItem: function (_item) { },
        onBeforeAddItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onAfterAddItem: function (_item) { },
        onBeforemModifyItem: function (item) {
            return new Promise((resolve, _reject) => {
                resolve(item);
            });
        },
        onAfterModifyItem: function (_item) { },
        onBeforeDeleteAll: function () {
            throw new Error("Function not implemented.");
        },
        onAfterDeleteAll: function () { }
    };
    listControler.registerListener(listenerGroup);
    return {
        data: listControler.data,
        modifyItem: listControler.modifyItem,
        registerListener: listControler.registerListener
    };
}
