var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../..";
var EvenementDTOAdapter = /** @class */ (function (_super) {
    __extends(EvenementDTOAdapter, _super);
    function EvenementDTOAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EvenementDTOAdapter.prototype.toClient = function (src) {
        return {
            ID: src.evenementID,
            evenementVisiteID: src.evenementVisiteID,
            typeEvenementID: src.typeEvenementID,
            libelleTypeEvenement: src.libelleTypeEvenement,
            dateDebut: src.dDebut,
            dateFin: src.dFin,
            estAnnule: src.bAnnule,
            estPeriodeValidite: src.bPeriodeValidite,
            information: src.sInformation,
        };
    };
    EvenementDTOAdapter.prototype.toAPI = function (src) {
        return {
            evenementID: src.ID,
            evenementVisiteID: src.evenementVisiteID,
            typeEvenementID: src.typeEvenementID,
            libelleTypeEvenement: src.libelleTypeEvenement,
            dDebut: src.dateDebut,
            dFin: src.dateFin,
            bAnnule: src.estAnnule,
            bPeriodeValidite: src.estPeriodeValidite,
            sInformation: src.information,
        };
    };
    return EvenementDTOAdapter;
}(Adapter));
export { EvenementDTOAdapter };
