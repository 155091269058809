import styled from "styled-components";
import { CheckboxV3, FormItemWrapper } from "@ads/ui";

import ListCommandItem from "./ListCommandItem";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
import {
  CommandeVisiteModel,
  LigneCommandeVisiteModel
} from "@ads/isadom-model";

const WrapperList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ theme }) => theme.media.xl} {
    width: 70%;
  }
`;

const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1rem;
  padding: 0 1rem 1rem 0;

  .form-item {
    margin-bottom: 0;
  }
`;

const isChecked = (commande: CommandeVisiteModel) => {
  let checked = true;
  const { lignes } = commande;

  lignes.forEach((l) => {
    if (!l.independante) {
      checked = false;
    }
  });

  return checked;
};

const isIndeterminate = (commande: CommandeVisiteModel) => {
  let indeterminate = false;
  let isChecked = false;
  let isNotChecked = false;

  const { lignes } = commande;

  lignes.forEach((l) => {
    if (l.independante) {
      isChecked = true;
    } else if (!l.independante) {
      isNotChecked = true;
    }
  });

  indeterminate = isChecked && isNotChecked;

  return indeterminate;
};

const isAnyLineChecked = (lignes: LigneCommandeVisiteModel[]) => {
  let anyChecked = false;

  lignes.forEach((l) => {
    if (l.independante) {
      anyChecked = true;
    }
  });

  return anyChecked;
};

const ListCommande = () => {
  const { commande, setCommandesIndependantes } = useFormulaireCommandesStore();

  if (!commande) {
    return null;
  }

  return (
    <WrapperList>
      <WrapperCheckbox>
        <FormItemWrapper direction="vertical">
          <CheckboxV3
            id="cmd-independantes"
            checked={isChecked(commande) || isAnyLineChecked(commande.lignes)}
            indeterminate={isIndeterminate(commande)}
            onChange={() => {
              let value = true;

              if (isChecked(commande) || isAnyLineChecked(commande.lignes)) {
                value = false;
              }

              setCommandesIndependantes(value);
            }}
          >
            Commandes indépendantes
          </CheckboxV3>
        </FormItemWrapper>
      </WrapperCheckbox>

      {commande.lignes.map((ligne) => (
        <ListCommandItem
          key={`${ligne.ID}-${ligne.famille?.idFamille}`}
          item={ligne}
        />
      ))}
    </WrapperList>
  );
};

export default ListCommande;
