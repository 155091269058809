// import { EvenementAppareilTelesuiviModel } from "@ads/isadom-model";

import { useState, useEffect } from "react";
import axios from "axios";
import api from "api";
import {
  EvenementAppareilTelesuiviAdapter,
  EvenementAppareilTelesuiviModel,
} from "@ads/isadom-model";

interface GetAppareilsProps {
  visiteID: number | undefined;
}

export const useGetAppareilFromAPI = ({ visiteID }: GetAppareilsProps) => {
  const [appareils, setAppareils] =
    useState<EvenementAppareilTelesuiviModel[]>();
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const evenementAppareilTelesuiviAdapter =
    new EvenementAppareilTelesuiviAdapter();

  const fetchData = () => {
    const url = `/visites/${visiteID}/evenements/appareils`;
    setloading(true);
    api
      .get(url)
      .then(({ data }) => {
        setAppareils(evenementAppareilTelesuiviAdapter.toClients(data.data));
        setError("");
      })
      .catch((error: any) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 500) {
            setError("Erreur interne du serveur.");
          } else {
            setError(error.response?.data || "Erreur inconnue");
          }
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { appareils, error, loading };
};
