import { Label, SelectWrapper } from "@ads/ui";
import { Select } from "antd";
import { Controller, UseFormReturn } from "react-hook-form";
import { v4 } from "uuid";
import { LocalisationInterneAutoriseeAPI } from "@ads/isadom-model";
import { useEffect, useState } from "react";

interface Props {
  name: string;
  titre: string;
  methods: UseFormReturn<any, Record<string, any>>;
  localisationsAutoriseesTriees: LocalisationInterneAutoriseeAPI[];
  placeholder?: string;
  onChange?: () => any;
  defaultValue: string;
  selectedValue?: LocalisationInterneAutoriseeAPI | undefined;
}

const SelectLocalisation = ({
  name,
  titre,
  methods,
  localisationsAutoriseesTriees,
  placeholder,
  defaultValue,
  selectedValue,
}: Props): JSX.Element => {
  const { Option } = Select;

  const [stateSelectedValue, setStateSelectedValue] = useState<string>();

  useEffect(() => {
    setStateSelectedValue(selectedValue?.sLibelle);
  }, [selectedValue]);

  const handleSelect = (value: string) => {
    setStateSelectedValue(value);
  };

  return (
    <>
      <Label htmlFor={titre}>{titre}</Label>
      <SelectWrapper selectMode="light">
        <Controller
          control={methods.control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Select
              id={titre}
              showSearch
              {...field}
              onSelect={(value: any) =>
                handleSelect(value?.toString())
              }
              placeholder={placeholder}
              defaultValue={stateSelectedValue}
              value={stateSelectedValue}
            >
              {localisationsAutoriseesTriees?.map(
                (localisationAutorisee: LocalisationInterneAutoriseeAPI) => {
                  return (
                    <Option
                      key={v4()}
                      value={JSON.stringify(localisationAutorisee)}
                    >
                      {localisationAutorisee.sLibelle}
                    </Option>
                  );
                }
              )}
            </Select>
          )}
        />
      </SelectWrapper>
    </>
  );
};

export default SelectLocalisation;
