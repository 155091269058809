import { ConsommablesConfigurationDetailTemplate } from '@ads/module-consommables-visite'
import { useParams } from 'react-router-dom'

const ContainerConsommableConfigurationDetail = () => {
  const { configConsoID, iPKVisite } = useParams<{
    iPKVisite: string;
    configConsoID: string;
  }>()

  return (
    <ConsommablesConfigurationDetailTemplate
      visiteID={+iPKVisite}
      configConsoID={parseInt(configConsoID || "")}
    />
  )
}

export default ContainerConsommableConfigurationDetail