import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
const ResponsiveReactGridLayout = WidthProvider(Responsive);
export function ResponsiveGridLayout(props) {
    function generateDOM() {
        return props.data.map((e, i) => {
            return (_jsx("div", { children: props.adapter(e) }, i));
        });
    }
    const [layouts, _setLayouts] = React.useState({
        lg: props.generateLayout(props.data, props.cols, "lg"),
        md: props.generateLayout(props.data, props.cols, "md"),
        sm: props.generateLayout(props.data, props.cols, "sm"),
        xs: props.generateLayout(props.data, props.cols, "xs"),
        xxs: props.generateLayout(props.data, props.cols, "xxs")
    });
    function onBreakpointChange(newBreakpoint, newCols) {
        console.log(newBreakpoint, newCols);
        _setLayouts({
            lg: props.generateLayout(props.data, props.cols, "lg"),
            md: props.generateLayout(props.data, props.cols, "md"),
            sm: props.generateLayout(props.data, props.cols, "sm"),
            xs: props.generateLayout(props.data, props.cols, "xs"),
            xxs: props.generateLayout(props.data, props.cols, "xxs")
        });
    }
    React.useEffect(() => {
        _setLayouts({
            lg: props.generateLayout(props.data, props.cols, "lg"),
            md: props.generateLayout(props.data, props.cols, "md"),
            sm: props.generateLayout(props.data, props.cols, "sm"),
            xs: props.generateLayout(props.data, props.cols, "xs"),
            xxs: props.generateLayout(props.data, props.cols, "xxs")
        });
    }, [props.data]);
    const convertCols = Object.assign({}, props.cols);
    return _jsx(ResponsiveReactGridLayout, Object.assign({ rowHeight: props.rowHeight, cols: convertCols, layouts: layouts, isDraggable: false, onBreakpointChange: onBreakpointChange }, { children: generateDOM() }), void 0);
}
