import { TypeVisiteModel } from "@ads/isadom-model";
import { SelectionHookListener } from "@ads/ui";
import { useSelectTypeVisiteTypePrestaByIntervenant } from "../../SelectTypeVisiteTypePresta/hook/useSelectTypeVisiteTypePrestaByIntervenant";
import { useTypeVisiteTypePrestaList } from "../../TypeVisiteTypePrestaList/hook/useTypeVisiteTypePrestaList";
import { ISelectTypeVisiteTypePrestaVisiteHookInput } from "./interfaces/ISelectTypeVisiteTypePrestaVisiteHookInput";
import { ISelectTypeVisiteTypePrestaVisiteHookService } from "./interfaces/ISelectTypeVisiteTypePrestaVisiteHookService";

export function useSelectTypeVisiteTypePrestaVisite({
  intervenantID,
  defaultTypeVisiteList = [],
  readOnliTypesVisitesTypesPresta = [],
}: ISelectTypeVisiteTypePrestaVisiteHookInput): ISelectTypeVisiteTypePrestaVisiteHookService {
  const servicesTypeVisiteTypePresta =
    useSelectTypeVisiteTypePrestaByIntervenant({
      intervenantID: intervenantID,
    });

  servicesTypeVisiteTypePresta.autoSearchTypeVisiteService.selectService.registerListener(
    new (class extends SelectionHookListener<TypeVisiteModel> {
      onAfterDataSelectedChanged(selectedData: TypeVisiteModel[]): void {
        if (selectedData && selectedData.length > 0) {
          const typeVisite = serviceTypeVisiteTypePrestaList.getTypeVisiteByID(
            selectedData[0].sPKTypeVisite
          );
          //si un type de visite est déjà présent dans la liste j'ajoute les types de prestation déjà selectionnés
          if (typeVisite && typeVisite.tTypePrestation) {
            servicesTypeVisiteTypePresta.autoSearchTypePrestaService.selectService.notifyDataSelectedAllWithoutControl(
              typeVisite.tTypePrestation
            );
            //sinon je vide les types de prestations potentiellement selectionnés
          } else {
            servicesTypeVisiteTypePresta.autoSearchTypePrestaService.selectService.notifyDataSelectedReset();
          }
        }
      }
    })("@ads/FormModifierVisite")
  );

  function handleClickAddTypeVisiteTypePresta() {
    serviceTypeVisiteTypePrestaList.notifyTypeVisiteAdded(
      servicesTypeVisiteTypePresta.getTypeVisiteTypePrestaSelected()
    );
  }

  const serviceTypeVisiteTypePrestaList = useTypeVisiteTypePrestaList({
    defaultTypesVisitesTypesPresta: defaultTypeVisiteList,
    readOnliTypesVisitesTypesPresta: readOnliTypesVisitesTypesPresta,
  });

  return {
    serviceSelectionTypeVisiteTypePresta: servicesTypeVisiteTypePresta,
    serviceTypeVisiteTypePrestaList,
    notifyAddTypeVisiteTypePresta: handleClickAddTypeVisiteTypePresta,
  };
}
