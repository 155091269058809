import { useApi } from "@ads/react-common-module";
import { useEffect, useState } from "react";
import { apiSearchTypeQuestionnaire } from "../..";
export function useSelectTypeQuestionnaire(searchparam, delay = 0, minLengthSearch = 2) {
    const [items, setList] = useState([]);
    const [isLoadingItems, setLoading] = useState(false);
    const api = useApi();
    useEffect(() => {
        if (searchparam.searchValue &&
            searchparam.searchValue.length > minLengthSearch) {
            const delayDebounceFn = setTimeout(() => {
                setLoading(true);
                apiSearchTypeQuestionnaire(api, searchparam.searchValue)
                    .then((result) => {
                    setList(result);
                })
                    .catch((err) => console.error(err))
                    .finally(() => setLoading(false));
            }, delay);
            return () => clearTimeout(delayDebounceFn);
        }
        return;
    }, [searchparam, delay, minLengthSearch]);
    return { items, isLoadingItems };
}
