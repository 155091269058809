var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { useState } from "react";
import { ApiIsadomHttpResponseError, BaseError, ERROR_HTTP_API_NULL, ERROR_HTTP_UNKNOWN_POST, ERROR_HTTP_UNKNOWN_PUT, HttpResponseError, } from "../../error";
export function apiPutV3(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            try {
                return (yield api.put(uri, body)).data;
            }
            catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const newError = new HttpResponseError(ERROR_HTTP_UNKNOWN_POST, error.response.status, error.response.data);
                        if (errorStrategy) {
                            errorStrategy(newError);
                        }
                        throw newError;
                    }
                    else {
                        throw new BaseError(ERROR_HTTP_UNKNOWN_POST);
                    }
                }
                else {
                    return error.data;
                }
            }
        }
        else {
            throw new BaseError(ERROR_HTTP_API_NULL);
        }
    });
}
function useApiPostPutCommonV3(api, uri, apiRequestFunction, options) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    function request(resource, pathParameters) {
        return new Promise((resolve, reject) => {
            const body = (options === null || options === void 0 ? void 0 : options.adaptBody)
                ? options.adaptBody(resource)
                : resource;
            setLoading(true);
            let realUri = uri;
            if (pathParameters) {
                for (const key of Object.keys(pathParameters)) {
                    realUri = realUri.replace(`:${key}`, pathParameters[key].toString());
                }
            }
            apiRequestFunction(api, realUri, body, options === null || options === void 0 ? void 0 : options.errorStrategy)
                .then((response) => {
                if (!(options === null || options === void 0 ? void 0 : options.adaptResult)) {
                    setData(response);
                    resolve(response);
                }
                else {
                    setData(options.adaptResult(response));
                    resolve(options.adaptResult(response));
                }
            })
                .catch(reject)
                .finally(() => {
                setLoading(false);
            });
        });
    }
    return {
        data: data,
        request: request,
        loading: loading,
    };
}
export function useApiPostV3(api, uri, options) {
    return useApiPostPutCommonV3(api, uri, apiPostV3, options);
}
export function useApiPutV3(api, uri, options) {
    return useApiPostPutCommonV3(api, uri, apiPutV3, options);
}
export function apiPostV3(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            try {
                return (yield api.post(uri, body)).data;
            }
            catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const newError = new HttpResponseError(ERROR_HTTP_UNKNOWN_POST, error.response.status, error.response.data);
                        if (errorStrategy) {
                            errorStrategy(newError);
                        }
                        throw newError;
                    }
                    else {
                        throw new BaseError(ERROR_HTTP_UNKNOWN_POST);
                    }
                }
                else {
                    return error.data;
                }
            }
        }
        else {
            throw new BaseError(ERROR_HTTP_API_NULL);
        }
    });
}
export function apiIsadomPostV3(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield apiPostV3(api, uri, body);
        }
        catch (error) {
            if (error instanceof HttpResponseError) {
                const newErrorType = new ApiIsadomHttpResponseError(ERROR_HTTP_UNKNOWN_POST, error.status, error.data, error.message);
                if (errorStrategy) {
                    errorStrategy(newErrorType);
                }
                throw newErrorType;
            }
            else {
                throw new BaseError(ERROR_HTTP_UNKNOWN_POST);
            }
        }
    });
}
export function apiIsadomPutV3(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield apiPutV3(api, uri, body);
        }
        catch (error) {
            if (error instanceof HttpResponseError) {
                const newErrorType = new ApiIsadomHttpResponseError(ERROR_HTTP_UNKNOWN_PUT, error.status, error.data, error.message);
                if (errorStrategy) {
                    errorStrategy(newErrorType);
                }
                throw newErrorType;
            }
            else {
                throw new BaseError(ERROR_HTTP_UNKNOWN_PUT);
            }
        }
    });
}
