import { LocalisationModel } from "@ads/isadom-model";
import { useListSelection } from "@ads/ui";
import { ISelectLocalisationHookInput } from "./interfaces/ISelectLocalisationHookInput";
import { ISelectLocalisationHookService } from "./interfaces/ISelectLocalisationHookService";

export function useSelectLocalisation({
  typeSelection = "simple",
  defaultSelectedLocalisation,
  defaultSelectionableLocalisation,
}: ISelectLocalisationHookInput): ISelectLocalisationHookService {
  const services = useListSelection({
    data: defaultSelectionableLocalisation,
    defaultSelectedData: defaultSelectedLocalisation,
    getKeyFromData(localisation: LocalisationModel) {
      return localisation.localisationId;
    },
    typeSelection: typeSelection,
  });

  return {
    ...services,
  };
}
