import { FC, useState } from "react";
import {
  LigneTable,
  TypeReglage,
} from "modules/appareils/interfaces/modes-reglages.interfaces";
import ModalHoraires from "../organisms/ModalHoraires";
import styled from "styled-components";
import BoutonOpenModal from "./BoutonOpenModal";
import { updateReglageAppareil } from "modules/appareils/functions/updateReglageAppareil";
import { ReglageValeurHoraireAPI } from "@ads/isadom-model";

const InputWrapper = styled("div")`
  position: relative;
  .button-modal {
    position: absolute;
    right: 0.8rem;
    top: 50%;
    border: none;
    padding: 0;
    height: auto;
    width: auto;
    transform: translateY(-50%);
    font-size: 2.4rem;
  }
`;

interface Props {
  record: LigneTable;
  typeReglage: TypeReglage;
}

const InputGrille: FC<Props> = ({ record, typeReglage }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    sCode,
    sLibelle,
    indexLigne,
    sValeurMin,
    sValeurMax,
    theoriquePrecedent,
    constatePrecedent,
    mesurePrecedent,
    enPartantPrecedent,
    setModesReglagesAppareil,
    modesReglagesAppareil,
    isModeHoraire,
  } = record;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;

    const newReglageAppareil = updateReglageAppareil(
      modesReglagesAppareil,
      sCode,
      typeReglage,
      sLibelle,
      inputValue
    );

    setModesReglagesAppareil({ ...newReglageAppareil });
  };

  const handleHoraire = (
    value: string,
    reglagesValeursHoraires: ReglageValeurHoraireAPI[]
  ) => {
    const newReglageAppareil = updateReglageAppareil(
      modesReglagesAppareil,
      sCode,
      typeReglage,
      sLibelle,
      value,
      reglagesValeursHoraires
    );

    setModesReglagesAppareil({ ...newReglageAppareil });
  };

  if (!modesReglagesAppareil) {
    // eslint-disable-next-line no-console
    console.error(
      "modesReglagesAppareil IS NOT DEFINED ",
      modesReglagesAppareil
    );
    return <></>;
  }

  const reglagesValeur = modesReglagesAppareil?.modesSaisis
    ? modesReglagesAppareil.modesSaisis
      .find((mode) => mode.reglageModeID === modesReglagesAppareil.modeActif)
      ?.tabGrille[modesReglagesAppareil.grilleActive].tabReglage.find(
        (reglage) => reglage.sCode === sCode
      )?.tabReglageValeur
    : undefined;

  const inputValue = reglagesValeur?.find(
    (reglageValeur) => reglageValeur.sTypeReglage === typeReglage
  )?.sValeur;

  const reglage = reglagesValeur?.find(
    (reglageValeur) => reglageValeur.sTypeReglage === typeReglage
  );

  let className = "ipt-text";

  if (typeReglage === "T" && theoriquePrecedent && inputValue) {
    className = getClassName(
      +sValeurMin,
      +sValeurMax,
      inputValue,
      theoriquePrecedent
    );
  }

  if (typeReglage === "C" && theoriquePrecedent && inputValue) {
    className = getClassName(
      +sValeurMin,
      +sValeurMax,
      inputValue,
      constatePrecedent
    );
  }

  if (typeReglage === "E" && theoriquePrecedent && inputValue) {
    className = getClassName(
      +sValeurMin,
      +sValeurMax,
      inputValue,
      enPartantPrecedent
    );
  }

  if (typeReglage === "M" && theoriquePrecedent && inputValue) {
    className = getClassName(
      +sValeurMin,
      +sValeurMax,
      inputValue,
      mesurePrecedent
    );
  }

  return (
    <>
      <ModalHoraires
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleHoraire={handleHoraire}
        reglagesHoraires={reglage?.tabReglageValeurHoraire}
      />
      <InputWrapper>
        <input
          id={`${sCode}-${indexLigne}-${typeReglage}-${modesReglagesAppareil.grilleActive}`}
          key={`${sCode}-${indexLigne}-${typeReglage}-${modesReglagesAppareil.grilleActive}`}
          className={className || "ipt-text"}
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder=""
          onClick={() => isModeHoraire && setIsModalVisible(true)}
        />
        {isModeHoraire && (
          <>
            <BoutonOpenModal setIsModalVisible={setIsModalVisible} />
          </>
        )}
      </InputWrapper>
    </>
  );
};

export default InputGrille;

function getClassName(
  sValeurMin: number,
  sValeurMax: number,
  valeurCourante: string | number,
  valeurPrecedente: string
): string {
  let className = "ipt-text ";
  if (sValeurMin || sValeurMax) {
    // Si différent des bornes min ou max, danger
    if (+valeurCourante < sValeurMin || +valeurCourante > sValeurMax) {
      return (className = "ipt-text danger");
    }
  }
  // Gestion des couleurs de l'input
  if (valeurCourante && valeurPrecedente) {
    // Si différent de la valeur précédente, warning
    if (valeurCourante !== valeurPrecedente) {
      className += "warning";
      return className;
    }
  }
  return className;
}
