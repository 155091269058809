import { VisiteAdapter } from "@ads/isadom-model";

import { FormulaireCommandeProvider } from "../store";
import { CommandeTemplate } from "../ui/templates";

import ProtectedRoute from "components/core/routes/ProtectedRoute";
import { RouteParam } from "interface/core/RouteParam";
import { Switch, useParams } from "react-router-dom";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";

export const RouterCommande = () => {
  const { iPKVisite } = useParams<RouteParam>();
  const { data: visiteAPI } = useGetVisiteQuery(+iPKVisite);
  const visiteClient = visiteAPI && new VisiteAdapter().toClient(visiteAPI);

  if (!visiteClient) {
    return null;
  }

  return (
    <Switch>
      {/* Page commande */}
      <ProtectedRoute exact path="/visites/:iPKVisite/:tabActive">
        <FormulaireCommandeProvider>
          <CommandeTemplate visite={visiteClient} />
        </FormulaireCommandeProvider>
      </ProtectedRoute>
    </Switch>
  );
};
