var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter, IIAHAdapter } from "../..";
import { FormulaireAdapter } from '../../formulaire/adapter/FormulaireAdapter';
import { ParametreEditionAdapter } from "./ParametreEditionAdapter";
var FicheSuivisAdapter = /** @class */ (function (_super) {
    __extends(FicheSuivisAdapter, _super);
    function FicheSuivisAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formAdapter = new FormulaireAdapter();
        _this.parametreEditionAdapter = new ParametreEditionAdapter();
        _this.iahAdapter = new IIAHAdapter();
        return _this;
    }
    FicheSuivisAdapter.prototype.toClient = function (src) {
        return {
            formulaire: this.formAdapter.toClient(src.formulaire),
            bIntegrationFormulaire: src.bIntegrationFormulaire,
            parametresEditions: this.parametreEditionAdapter.toClients(src.tParametreEdition),
            IAH: this.iahAdapter.toClient(src.IAH),
            IAHPrecedent: this.iahAdapter.toClient(src.IAHPrecedent),
        };
    };
    FicheSuivisAdapter.prototype.toAPI = function (src) {
        return {
            formulaire: this.formAdapter.toAPI(src.formulaire),
            bIntegrationFormulaire: src.bIntegrationFormulaire,
            tParametreEdition: this.parametreEditionAdapter.toAPIs(src.parametresEditions),
            IAH: this.iahAdapter.toAPI(src.IAH),
            IAHPrecedent: this.iahAdapter.toAPI(src.IAHPrecedent),
        };
    };
    return FicheSuivisAdapter;
}(Adapter));
export { FicheSuivisAdapter };
