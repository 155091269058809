var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { QuestionnaireModelBuilder, } from "@ads/isadom-model";
import { useEffect, useState } from "react";
import { deepCopy } from "@ads/react-common-module";
import { useGetAllSurveysTypesFromAPI } from "../../..";
export function useQuestionnaireListAutoFetch({ dataFetchFunction, fetchParams, optionsList, add, createQuestionnaire, }) {
    /****************************************/
    /**************INTERNAl FUNCION**********/
    /****************************************/
    const [baseQuestionnaire, setBaseQuestionnaire] = useState();
    const filter = (typeQuestionnaireID) => {
        //si la donnée a été fetch et qu'aucun filtre n'est à appliqué
        if (typeQuestionnaireID === undefined && baseQuestionnaire) {
            setQuestionnaires(deepCopy(baseQuestionnaire));
            //si la donnée n'a été fetch et qu'aucun filtre n'est à appliqué
        }
        else if (typeQuestionnaireID === undefined && !baseQuestionnaire) {
            setQuestionnaires([]);
            //sinon appliquer le filtre
        }
        else if (baseQuestionnaire) {
            const filteredQuestionnaires = new Array();
            for (const questionnaire of baseQuestionnaire) {
                if (questionnaire.typeQuestionnaire.libelle === typeQuestionnaireID) {
                    filteredQuestionnaires.push(questionnaire);
                }
            }
            setQuestionnaires(filteredQuestionnaires);
        }
        else {
            setQuestionnaires(baseQuestionnaire);
        }
    };
    //TODO FBR regarder le composant de search pour qu'il renvoit l'intégralité du type de questionnaire
    const createQuestionnaireFromtype = (typeQuestionnaireID) => {
        if (allTypeQuestionnaire) {
            for (const typeQuestionnaire of allTypeQuestionnaire) {
                if (typeQuestionnaireID === typeQuestionnaire.typeQuestionnaireID) {
                    const builder = new QuestionnaireModelBuilder();
                    builder.setTypeQuestionnaire(typeQuestionnaire);
                    return createQuestionnaire(builder.build());
                }
            }
        }
        return new Promise((_, reject) => {
            reject("Une erreur est survenue, veuillez essayer à nouveau");
        });
    };
    const createQuestionnaireWrapper = (questionnaire) => __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            createQuestionnaire(questionnaire)
                .then((data) => {
                if (questionnaires) {
                    setQuestionnaires([data, ...questionnaires]);
                }
                else {
                    setQuestionnaires([data]);
                }
                resolve(data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    });
    function addFiltersOptions(typeQuestionnaireLibelles) {
        const set = new Set();
        for (const typeQuestionnaireLibelle of filterTypesQuestionnaires) {
            set.add(typeQuestionnaireLibelle);
        }
        for (const typeQuestionnaireLibelle of typeQuestionnaireLibelles) {
            set.add(typeQuestionnaireLibelle);
        }
        setFilterTypesQuestionnaires(Array.from(set.values()));
    }
    function setBaseQuestionnairesWithoutDoublon(questionnaires) {
        const arrayWithoutDoublon = baseQuestionnaire
            ? baseQuestionnaire
            : [];
        for (const questionnaire of questionnaires) {
            let find = false;
            for (const arrayWithoutDoublonItem of arrayWithoutDoublon) {
                if (questionnaire.questionnaireID !== undefined &&
                    arrayWithoutDoublonItem.questionnaireID ===
                        questionnaire.questionnaireID) {
                    find = true;
                }
                //pour gérer le cas des questionnaires obligatoires
                if (questionnaire.questionnaireID === undefined &&
                    arrayWithoutDoublonItem.questionnaireID === undefined &&
                    arrayWithoutDoublonItem.typeQuestionnaire.typeQuestionnaireID ===
                        questionnaire.typeQuestionnaire.typeQuestionnaireID) {
                    find = true;
                }
            }
            if (!find) {
                arrayWithoutDoublon.push(questionnaire);
            }
        }
        setBaseQuestionnaire(arrayWithoutDoublon);
    }
    /****************************************/
    /**************LOCAL STATE***************/
    /****************************************/
    const [allTypeQuestionnaire, setAllTypeQuestionnaire] = useState();
    const [questionnaires, setQuestionnaires] = useState();
    //Contient la liste des types de questionnaire utilisé pour la combo de filtre
    const [filterTypesQuestionnaires, setFilterTypesQuestionnaires] = useState([]);
    /****************************************/
    /***************LOCAL VAR****************/
    /****************************************/
    const allTypeQuestionnaireResult = useGetAllSurveysTypesFromAPI();
    const initialApiResult = dataFetchFunction(fetchParams);
    //Valeur par défaut des options de la listview
    const mandatoryOptionsList = {
        title: optionsList ? optionsList.title : "Questionnaires",
        filterProps: {
            filterLibelle: "types de questionnaires",
            filterList: filterTypesQuestionnaires,
            onFilterChanged: filter,
        },
    };
    //Si l'option d'ajout est activé
    if (add) {
        mandatoryOptionsList.add = true;
        mandatoryOptionsList.addButtonLabel = "Ajouter un questionnaire";
    }
    /****************************************/
    /*****************EFFECT*****************/
    /****************************************/
    useEffect(() => {
        if (!initialApiResult.isLoading && initialApiResult.data) {
            setBaseQuestionnaire(deepCopy(initialApiResult.data));
        }
    }, [initialApiResult.isLoading]);
    useEffect(() => {
        if (baseQuestionnaire) {
            setQuestionnaires(baseQuestionnaire);
            //MAJ des filtres de la liste en fonction des questionnaires affiché
            const set = new Set();
            for (const questionnaire of baseQuestionnaire) {
                set.add(questionnaire.typeQuestionnaire.libelle);
            }
            setFilterTypesQuestionnaires(Array.from(set.values()));
        }
    }, [baseQuestionnaire]);
    useEffect(() => {
        if (!allTypeQuestionnaireResult.isLoading &&
            allTypeQuestionnaireResult.data) {
            setAllTypeQuestionnaire(allTypeQuestionnaireResult.data);
        }
    }, [allTypeQuestionnaireResult.isLoading]);
    return {
        questionnaires: questionnaires,
        fetchError: initialApiResult.error,
        optionsList: Object.assign(Object.assign({}, optionsList), mandatoryOptionsList),
        questionnairesLoading: initialApiResult.isLoading,
        filter: filter,
        createQuestionnaireFromType: createQuestionnaireFromtype,
        createQuestionnaire: createQuestionnaireWrapper,
        addFiltersOptions: addFiltersOptions,
        setBaseQuestionnaires: setBaseQuestionnairesWithoutDoublon,
    };
}
