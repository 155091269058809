import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Loading, FormWidget } from "@ads/ui";
import { Alert } from "antd";
import { v4 } from "uuid";
import { useConsommablesLivraisonsForm } from "../../hooks/useConsommablesLivraisonsForm";
import { LivraisonsConsommablesListHeader, } from "../organisms/LivraisonsConsommablesListHeader";
import { LigneFormulaireLivraison } from "../organisms/LigneFormulaireLivraison";
const List = styled.div `
  margin: 2.6rem 0 0;
`;
const formatLignesFormulaire = (livraisonsConsommables, config) => {
    let lignesFormulaire = [];
    livraisonsConsommables.forEach((livraison) => {
        const position = lignesFormulaire.findIndex((configConso) => configConso.consommable.IDProduit === livraison.consommable.IDProduit);
        if (position !== -1) {
            // Le consommable existe déjà dans le formulaire, on ajoute la livraison à la ligne
            const ligne = lignesFormulaire[position];
            const savedLivraisons = [];
            const unsavedLivraisons = [];
            [...ligne.livraisons, livraison].forEach((r) => {
                if (r.ligneLivraisonID < -10000) {
                    unsavedLivraisons.push(r);
                }
                else {
                    savedLivraisons.push(r);
                }
            });
            // console.log("unsaved", unsavedRetours);
            // console.log("saved", savedRetours);
            const livraisons = [
                ...savedLivraisons.sort((a, b) => {
                    if (a.ligneLivraisonID < b.ligneLivraisonID) {
                        return 1;
                    }
                    if (a.ligneLivraisonID > b.ligneLivraisonID) {
                        return -1;
                    }
                    return 0;
                }),
                ...unsavedLivraisons.sort((a, b) => {
                    if (a.ligneLivraisonID < b.ligneLivraisonID) {
                        return 1;
                    }
                    if (a.ligneLivraisonID > b.ligneLivraisonID) {
                        return -1;
                    }
                    return 0;
                }),
            ];
            lignesFormulaire[position] = Object.assign(Object.assign({}, ligne), { quantite: ligne.quantite + (livraison.iQuantite || 0), livraisons });
        }
        else {
            // Le consommable n'est pas présent dans les lignes du formulaire, on créée une nouvelle ligne
            lignesFormulaire.push({
                ligneID: v4(),
                consommable: livraison.consommable,
                quantite: livraison.iQuantite || 0,
                livraisons: [livraison],
                isConfig: !!config.find((c) => c.produit.IDProduit === livraison.consommable.IDProduit),
            });
        }
    });
    return lignesFormulaire.sort((a, b) => {
        if (a.consommable.sLibelle < b.consommable.sLibelle) {
            return -1;
        }
        if (a.consommable.sLibelle > b.consommable.sLibelle) {
            return 1;
        }
        return 0;
    });
};
export const ConsommablesLivraisonsTemplate = ({ visiteID, intervenantID, SelectionLivraisonConsommable, }) => {
    const { isError, isLoadingFetch, isLoadingSave, isLoadingReset, data, config, affectations, refreshData, saveData, removeData, addData, addToConfig, resetData, isDataConfig, } = useConsommablesLivraisonsForm(visiteID);
    const handleSaveRetours = () => {
        saveData(visiteID, data);
    };
    if (isLoadingFetch) {
        return (_jsx(Loading, { children: "Chargement en cours des livraisons de consommables." }, void 0));
    }
    if (isError) {
        return (_jsx(Alert, { showIcon: true, type: "error", message: "Une erreur est survenue.", description: "Les livraisons de consommables n'ont pu \u00EAtre r\u00E9cup\u00E9r\u00E9es." }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsx(LivraisonsConsommablesListHeader, { visiteID: visiteID, intervenantID: intervenantID, nbConso: data.length, SelectionLivraisonConsommable: SelectionLivraisonConsommable, resetData: resetData, isLoadingReset: isLoadingReset, addData: addData, refreshData: refreshData }, void 0), data.length > 0 ? (_jsx(FormWidget, Object.assign({ handleSave: handleSaveRetours, isLoadingSave: isLoadingSave }, { children: _jsx(List, Object.assign({ id: "retours-consommables" }, { children: formatLignesFormulaire(data, config).map((ligneFormulaire) => (_jsx(LigneFormulaireLivraison, { affectations: affectations, data: ligneFormulaire, refreshData: refreshData, removeData: removeData, isDataConfig: isDataConfig, livraisons: data, configConsommables: config, addToConfig: addToConfig }, ligneFormulaire.consommable.IDProduit))) }), void 0) }), void 0)) : (_jsx(Alert, { showIcon: true, type: "info", message: "La liste des livraisons est vide." }, void 0))] }, void 0));
    // useEffect(() => {
    //   if (livraisonsListFilters) {
    //     setLivraisonsConsommablesFiltrees(
    //       livraisonsConsommables.filter(
    //         (livraison: LivraisonVisiteModel) =>
    //           livraison.typePrestation &&
    //           livraison.typePrestation.id === livraisonsListFilters.id
    //       )
    //     );
    //   } else {
    //     setLivraisonsConsommablesFiltrees(livraisonsConsommables);
    //   }
    // }, [livraisonsListFilters, livraisonsConsommables]);
    // const handleSaveLivraisons = async () => {
    //   try {
    //     setSaveLoading(true);
    //     // const livraisons = (
    //     await apiEnregistreConsommablesLivraisons(
    //       +visiteID,
    //       livraisonsConsommables.map((livraison) =>
    //         livraison.ligneLivraisonID < 0
    //           ? { ...livraison, ligneLivraisonID: -1 }
    //           : livraison
    //       )
    //     );
    //     // ).data;
    //     // setLivraisonsConsommables(livraisons);
    //     adsNotification.success({
    //       message: "Les livraisons de consommables ont été enregistrées.",
    //     });
    //     history.push(`/visites/${visiteID}/consommables/livraisons`);
    //   } catch (error: any) {
    //     adsNotification.error({
    //       message: "Erreur",
    //       description:
    //         "Les livraisons de consommables n'ont pas pu être enregistrées.",
    //     });
    //   } finally {
    //     setSaveLoading(false);
    //   }
    // };
    // if (loading) {
    //   return (
    //     <Loading>Chargement en cours des livraisons de consommables.</Loading>
    //   );
    // }
    // if (error) {
    //   return (
    //     <Alert
    //       showIcon
    //       type="error"
    //       message="Une erreur est survenue."
    //       description="Les livraisons de consommables n'ont pu être récupérées."
    //     />
    //   );
    // }
    // let lignesFormulaire: LigneFormumlaireLivraisonModel[] = [];
    // const tmp: LivraisonVisiteModel[] = [...livraisonsConsommablesFiltrees];
    // tmp.forEach((livraison) => {
    //   const position: number = lignesFormulaire.findIndex(
    //     (configConso) =>
    //       configConso.consommable.IDProduit === livraison.consommable.IDProduit
    //   );
    //   if (position !== -1) {
    //     // Le consommable existe déjà dans le formulaire, on ajoute la livraison à la ligne
    //     const ligne = lignesFormulaire[position];
    //     lignesFormulaire[position] = {
    //       ...ligne,
    //       quantite: ligne.quantite + (livraison.iQuantite || 0),
    //       livraisons: [...ligne.livraisons, livraison],
    //     };
    //   } else {
    //     // Le consommable n'est pas présent dans les lignes du formulaire, on créée une nouvelle ligne
    //     lignesFormulaire.push({
    //       ligneID: v4(),
    //       consommable: livraison.consommable,
    //       quantite: livraison.iQuantite || 0,
    //       livraisons: [livraison],
    //       isConfig: !!configData.find(
    //         (config) =>
    //           config.produit.IDProduit === livraison.consommable.IDProduit
    //       ),
    //     });
    //   }
    // });
    // lignesFormulaire = lignesFormulaire
    //   .map((ligneFormulaire: LigneFormumlaireLivraisonModel) => {
    //     if (ligneFormulaire.livraisons.length > 1) {
    //       // si tous les lots sont pour la même presta/appareil (discussion SB)
    //       let livraisons = ligneFormulaire.livraisons;
    //       const presta = livraisons.find(
    //         (l) => l.typePrestation !== undefined
    //       )?.typePrestation;
    //       const app = livraisons.find((l) => l.appareil !== undefined)?.appareil;
    //       if (presta) {
    //         livraisons = livraisons.map((l) => ({
    //           ...l,
    //           typePrestation: presta,
    //         }));
    //       }
    //       if (app) {
    //         livraisons = livraisons.map((l) => ({ ...l, appareil: app }));
    //       }
    //       return { ...ligneFormulaire, livraisons };
    //     }
    //     return ligneFormulaire;
    //   })
    //   .sort(
    //     (
    //       a: LigneFormumlaireLivraisonModel,
    //       b: LigneFormumlaireLivraisonModel
    //     ) => {
    //       if (a.isConfig < b.isConfig) {
    //         return -1;
    //       }
    //       if (a.isConfig > b.isConfig) {
    //         return 1;
    //       }
    //       if (a.consommable.sLibelle < b.consommable.sLibelle) {
    //         return -1;
    //       }
    //       if (a.consommable.sLibelle > b.consommable.sLibelle) {
    //         return 1;
    //       }
    //       return 0;
    //     }
    //   );
    // // console.log("livraisons", livraisonsConsommables);
    // // console.log("lignes formulaire", lignesFormulaire);
    // return (
    //   <>
    //     <LivraisonsConsommablesListHeader
    //       visiteID={visiteID}
    //       intervenantID={intervenantID}
    //       nbConso={tmp.length}
    //       setConfigurationListFilters={setLivraisonsListFilters}
    //       SelectionLivraisonConsommable={SelectionLivraisonConsommable}
    //       livraisonsConsommables={livraisonsConsommables}
    //       setLivraisonsConsommables={setLivraisonsConsommables}
    //       configAppareils={appareils}
    //       resetData={resetData}
    //       isLoadingReset={isLoadingReset}
    //     />
    //     {livraisonsConsommables.length > 0 && (
    //       <FormWidget
    //         handleSave={handleSaveLivraisons}
    //         isLoadingSave={saveLoading}
    //       >
    //         <List>
    //           {lignesFormulaire.map(
    //             (ligneFormulaire: LigneFormumlaireLivraisonModel) => (
    //               <LigneFormulaireLivraison
    //                 key={ligneFormulaire.ligneID}
    //                 visiteID={visiteID}
    //                 configAppareils={appareils}
    //                 affectations={affectations}
    //                 data={ligneFormulaire}
    //                 livraisonsConsommables={livraisonsConsommables}
    //                 setLivraisonsConsommables={setLivraisonsConsommables}
    //               />
    //             )
    //           )}
    //         </List>
    //       </FormWidget>
    //     )}
    //   </>
    // );
};
