import { AppareilAPI } from "@ads/isadom-model";
import { HeaderAppareil } from "../interfaces/appareils.interfaces";

const useHeaderAppareil = (
  iPKVisite: string,
  produit: AppareilAPI | undefined,
  IDProduit: string,
  hasReglages: boolean
): HeaderAppareil => {
  const breadcrumbItems = [
    {
      item: "Appareils",
      to: `/visites/${iPKVisite}/appareils/configuration`
    }
  ];
  if (produit) {
    breadcrumbItems.push({
      item: `${produit.sLibelle} - ${produit.sRefProduit}`,
      to: ""
    });
  }

  const tabsConfig = [
    {
      id: "fiche",
      url: `/visites/${iPKVisite}/appareils/configuration/${IDProduit}/fiche`,
      label: "Fiche"
    }
  ];

  if (hasReglages) {
    tabsConfig.push({
      id: "reglages",
      url: `/visites/${iPKVisite}/appareils/configuration/${IDProduit}/reglages`,
      label: "Réglages"
    });
  }

  return {
    breadcrumbItems,
    tabsConfig
  };
};

export default useHeaderAppareil;
