import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";
import { Alert } from "antd";

const ErrorAppareil = () => {
  return (
    <WrapperAlert>
      <Alert
        message="Erreur"
        showIcon
        description={
          <div>
            Une erreur inconnue est survenue pendant le chargement. <br />
            Veuillez contacter l'administrateur
          </div>
        }
        type="error"
      />
    </WrapperAlert>
  );
};

export default ErrorAppareil;
