import { combineReducers, configureStore } from "@reduxjs/toolkit";

import appReducer from "./features/app/app.slice";
import utilisateurReducer from "./features/utilisateur/utilisateur.slice";
import FeuilleDeRouteReducer from "modules/feuille-de-route/store/redux/features/feuille-de-route.slice";
import feuilleDeRouteMiddleware from "modules/feuille-de-route/store/redux/middleware";
import { nomadApi, middleware } from "./services/nomadApi";

import nomadMiddleware from "./middlewares/nomad.middleware";

export const reducer = combineReducers({
  app: appReducer,
  auth: utilisateurReducer,
  tournee: FeuilleDeRouteReducer,
  [nomadApi.reducerPath]: nomadApi.reducer
});

export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(middleware)
      .concat(nomadMiddleware)
      .concat(feuilleDeRouteMiddleware);
  },
  reducer
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
