import {
  HttpResponseAdapter,
  HttpResponseModel,
  TypeEvenementAdapter,
  TypeEvenementModel,
} from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";

export function apiGetTypesEvenementsByVisiteID(
  visiteID: number,
  volet = "STD"
): Promise<HttpResponseModel<TypeEvenementModel[]>> {
  const api = getDefaultApiInstance();

  if (api) {
    const adapter = new HttpResponseAdapter(new TypeEvenementAdapter());
    return apiGet(
      api,
      `visites/${visiteID}/types-evenements?volet=${volet}`,
      adapter
    ) as Promise<HttpResponseModel<TypeEvenementModel[]>>;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
