import { useFormSelectionItem, } from "../../../../common";
import { searchFamilles } from "../../../feature";
export function useFormSelectionFamilleItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.idFamille;
        },
        config: input.config,
        initParams: input.initParams,
        isDataMatchWithFilter(data, filter) {
            if (filter.typeProduitIDs !== null) {
                for (const typeProduitID of filter.typeProduitIDs) {
                    if (typeProduitID == data.idFamille) {
                        return true;
                    }
                }
            }
            return false;
        },
        onGetDataRequested: searchFamilles,
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
