import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Icons, PrimaryButton } from "@ads/ui";
import { EvenementDTOModel } from "@ads/isadom-model";
import { EvenementListFilter } from "./EvenementListFilter";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.lg} {
    gap: 0;
    flex-direction: row;
  }
`;

const WrapperTitre = styled.h1`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 0 0 1rem;
  font-weight: normal;
  font-size: 2rem;
  line-height: 5rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2.2rem;
  }
`;

const WrapperActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;

  ${({ theme }) => theme.media.lg} {
    margin-bottom: 0rem;
    flex-direction: row;
    gap: 2rem;
  }
`;
interface EvenementListProps {
  visiteID: number;
  data: [];
  evenementType: string;
  nbEvenement: number;
  setListFilters: React.Dispatch<React.SetStateAction<EvenementDTOModel[]>>;
}

export const EvenementListHeader = ({
  visiteID,
  data,
  evenementType,
  nbEvenement,
  setListFilters,
}: EvenementListProps) => {
  const history = useHistory();

  const handleClickAdd = () => {
    history.push(`/visites/${visiteID}/evenements/${evenementType}/nouveau`);
  };

  return (
    <Wrapper>
      <WrapperTitre>
        {nbEvenement === 0 && `Aucun évènement`}
        {nbEvenement === 1 && `1 évènement`}
        {nbEvenement > 1 && `${nbEvenement} évènements`}
      </WrapperTitre>

      <WrapperActions>
        <EvenementListFilter
          data={data}
          setEvenementListFilters={setListFilters}
        />
      </WrapperActions>
      <PrimaryButton
        as="button"
        iconPosition="left"
        icon={Icons.AddButton}
        type="button"
        label="Ajouter un évènement"
        buttonStyle="light"
        size="S"
        onClick={handleClickAdd}
      />
    </Wrapper>
  );
};
