import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TypeChampsProps } from "./ChampProps";
import { ChampCheck } from "../../champCheck/ui/ChampCheck";
import { ChampSelect } from "../../champSelect/ui/ChampSelect";
import { ChampTextArea } from "../../champTextArea/ui/ChampTextArea";
import styled from "styled-components";
import { useChampCheck } from "../../champCheck/hook/useChampCheck";
import { useChampSelect } from "../../champSelect/hook/useChampSelect";
import { ChampInt } from "../../champInt/ui/ChampInt";
import { useChampInt } from "../../champInt/hook/useChampInt";
import { ChampInput, useChampInput } from "../../champInput";
const Libelle = styled.h5 `
  margin: 1rem 0 1rem;
  padding: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary[100]};
  ${({ theme }) => theme.media.sm} {
  }
`;
export function Champ(props) {
    // Invisible
    /*if (!props.actif && !props.valeur.valeurs[0] ){
          return <></>
      // Visible non saisissable
      }else if(!props.actif && props.valeur.valeurs[0]){
          //TO DO : Champ en lecture seul
          return <>
          <Libelle>{props.libelle}</Libelle>
          <p></p>
          </>
      }*/
    //Visible saisissable
    return (_jsxs(_Fragment, { children: [_jsx(Libelle, { children: props.data.libelle }, void 0), props.data.typeChamp &&
                props.data.typeChamp.libelle === TypeChampsProps.Liste && (_jsx(ChampSelect, Object.assign({}, useChampSelect({
                data: props.data,
                updateValues: (newValues) => {
                    var _a, _b;
                    if ((_a = props.notifier) === null || _a === void 0 ? void 0 : _a.notifyItemChange) {
                        const dataProps = props.data;
                        const newValueChamp = props.data.valeur;
                        if (!dataProps.isChampMultiple.estChoixMultiple) {
                            newValueChamp.valeurs = [];
                        }
                        newValueChamp.valeurs.push(newValues);
                        return (_b = props.notifier) === null || _b === void 0 ? void 0 : _b.notifyItemChange(Object.assign(Object.assign({}, props.data), { valeur: newValueChamp }));
                    }
                },
            })), void 0)), props.data.typeChamp &&
                props.data.typeChamp.libelle === TypeChampsProps.Coche && (_jsx(ChampCheck, Object.assign({}, useChampCheck({
                data: props.data,
                setValue: (newValue) => {
                    var _a, _b;
                    if ((_a = props.notifier) === null || _a === void 0 ? void 0 : _a.notifyItemChange) {
                        const newValueChamp = props.data.valeur;
                        newValueChamp.valeurs = [];
                        newValueChamp.valeurs.push(newValue);
                        return (_b = props.notifier) === null || _b === void 0 ? void 0 : _b.notifyItemChange(Object.assign(Object.assign({}, props.data), { valeur: newValueChamp }));
                    }
                },
            })), void 0)), props.data.typeChamp &&
                props.data.typeChamp.libelle === TypeChampsProps.Texte && (_jsx(ChampInput, Object.assign({}, useChampInput({
                data: props.data,
                setValue: (newValue) => {
                    var _a, _b;
                    if ((_a = props.notifier) === null || _a === void 0 ? void 0 : _a.notifyItemChange) {
                        const newValueChamp = Object.assign(Object.assign({}, props.data.valeur), { valeurs: [newValue] });
                        return (_b = props.notifier) === null || _b === void 0 ? void 0 : _b.notifyItemChange(Object.assign(Object.assign({}, props.data), { valeur: newValueChamp }));
                    }
                },
            })), void 0)), " ", props.data.typeChamp &&
                props.data.typeChamp.libelle === TypeChampsProps.Long && (_jsx(ChampTextArea, Object.assign({}, useChampInput({
                data: props.data,
                setValue: (newValue) => {
                    var _a, _b;
                    if ((_a = props.notifier) === null || _a === void 0 ? void 0 : _a.notifyItemChange) {
                        const newValueChamp = Object.assign(Object.assign({}, props.data.valeur), { valeurs: [newValue] });
                        return (_b = props.notifier) === null || _b === void 0 ? void 0 : _b.notifyItemChange(Object.assign(Object.assign({}, props.data), { valeur: newValueChamp }));
                    }
                },
            })), void 0)), props.data.typeChamp &&
                props.data.typeChamp.libelle === TypeChampsProps.Number && (_jsx(ChampInt, Object.assign({}, useChampInt({
                data: props.data,
                setValue: (newValue) => {
                    var _a, _b;
                    if ((_a = props.notifier) === null || _a === void 0 ? void 0 : _a.notifyItemChange) {
                        const newValueChamp = Object.assign(Object.assign({}, props.data.valeur), { valeurs: [newValue] });
                        return (_b = props.notifier) === null || _b === void 0 ? void 0 : _b.notifyItemChange(Object.assign(Object.assign({}, props.data), { valeur: newValueChamp }));
                    }
                },
            })), void 0))] }, void 0));
}
