export const errorAdapter = (error) => {
    let errors = [];
    if (error) {
        for (const [key, value] of Object.entries(error)) {
            const arrayError = value;
            if (arrayError.length > 0) {
                const messagesAndIds = arrayError.map((element) => ({
                    message: element.message,
                    id: element.idConcerne,
                }));
                errors = messagesAndIds.map((messageId) => ({
                    sType: key,
                    message: messageId.message,
                    idConcerne: messageId.id,
                }));
            }
        }
    }
    else {
        errors.push({
            sType: "systeme",
            message: "Une erreur inatendue est survenue",
            idConcerne: "0",
        });
    }
    return errors;
};
export default errorAdapter;
