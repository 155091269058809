import {
  HttpResponseAdapter,
  HttpResponseAPI,
  HttpResponseModel,
  IConstanteAdapter,
  IConstanteAPI,
  IConstanteModel,
} from "@ads/isadom-model";
import { ApiInstance, apiIsadomPut } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiPutConstanteRespiratoireListByVisiteID(
  api: ApiInstance | null,
  visiteID: number,
  constantes: IConstanteModel[]
): Promise<HttpResponseModel<IConstanteModel[]>> {
  const constanteAdapter = new IConstanteAdapter();
  const result: HttpResponseAPI<IConstanteAPI[]> = await apiIsadomPut(
    api,
    "/visites/" + visiteID + "/constantes-respiratoires",
    constanteAdapter.toAPIs(constantes),
    apiIsadomErrorStrategy
  );
  const adapter = new HttpResponseAdapter(constanteAdapter);
  return adapter.toClient(result) as HttpResponseModel<IConstanteModel[]>;
}
