import { adsNotification } from "@ads/ui";
import { useBaseChecklistList, } from "../../..";
const TAG = "useChecklistList";
const ID = "@ads/" + TAG;
export function useChecklistList(input) {
    const legacyHook = useBaseChecklistList(input);
    const listener = {
        idListener: ID,
        onBeforeDeleteItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onAfterDeleteItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onBeforeAddItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onAfterAddItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onBeforemModifyItem: function (item) {
            return new Promise((resolve, _reject) => {
                if (input.update === null) {
                    resolve(null);
                }
                else if (item !== null) {
                    input
                        .update(item)
                        .then((result) => {
                        resolve(result);
                    })
                        .catch((err) => {
                        let displayError = "";
                        for (const errorBack of err.metiers) {
                            displayError += errorBack.message + "\n";
                        }
                        adsNotification.error({ message: displayError });
                    });
                }
                else {
                    adsNotification.error({
                        message: "Une erreur inattendu est survenue. Veuillez contacter votre administrateur.",
                    });
                }
            });
        },
        onAfterModifyItem: function (_item) {
        },
        onBeforeDeleteAll: function () {
            throw new Error("Function not implemented.");
        },
        onAfterDeleteAll: function () {
            throw new Error("Function not implemented.");
        },
    };
    legacyHook.registerListener(listener);
    // TO-DO : Ajouter récupération de la liste des types de NR
    // L'idée est de les passer en props pour chaque element de la CL
    // Et faire donc moins d'appelle API
    const result = {
        data: legacyHook.data,
        addItem: legacyHook.addItem,
        deleteAllItems: legacyHook.deleteAllItems,
        deleteItem: legacyHook.deleteItem,
        modifyItem: legacyHook.modifyItem,
        registerListener: legacyHook.registerListener,
        unregisterListener: legacyHook.unregisterListener
    };
    return result;
}
