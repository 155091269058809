import { HttpResponseAdapter, TypeTiersAdapter, } from "@ads/isadom-model";
import { getDefaultApiInstance, useApiGetV2 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";
export function useApiGetTypeTiersByID(id) {
    const adapter = new TypeTiersAdapter();
    const httpAdapter = new HttpResponseAdapter(adapter);
    return useApiGetV2(getDefaultApiInstance(), "/types-tiers/" + id, {
        errorStrategy: apiIsadomErrorStrategy,
        adaptResult(typetiersAPI) {
            return httpAdapter.toClient(typetiersAPI).data;
        },
    });
}
