import { DiagnostiqueEducatifModel } from "@ads/isadom-model";
import { LoadingView } from "components-v2/loading/LoadingView";
import { ReactNode, useEffect } from "react";
import { DiagnosticEducatifFormAdapter } from "./DiagnosticEducatifFormAdapter";
import { ApplicationContext, Form, useForm } from "@ads/react-common-module";
import { useApiGetDiagnosticEducatifByVisiteID } from "../../api/useApiGetDiagnosticEducatifByVisiteID";
import { useApiPutDiagnoticEducatifVisite } from "../../api/useAPIPutDiagnosticEducatifVisite";
import { useApiDeleteDiagnosticEducatifByVisiteIDAndID } from "../../api/useApiDeleteDiagnoticEducatifByVisiteIDAndID";
import { adsNotification, DEFAULT_NOTIFICATION_DURATION } from "@ads/ui";

export interface DiagnostiqueEducatifModelFormVisiteTemplateProps {
  visiteID: number;
}

export function DiagnosticEducatifFormVisiteTemplate({
  visiteID
}: DiagnostiqueEducatifModelFormVisiteTemplateProps) {
  function withFetchLoading(): ReactNode {
    return (
      <>
        <LoadingView libelle="du diagnostic éducatif" />
      </>
    );
  }

  function compareTo(
    initFormData: DiagnostiqueEducatifModel,
    newFormData: DiagnostiqueEducatifModel
  ): number {
    return JSON.stringify(initFormData) === JSON.stringify(newFormData)
      ? 0
      : -1;
  }
  const theme = ApplicationContext.getInstance().getTheme();

  const services = useForm<DiagnostiqueEducatifModel>({
    compareTo: compareTo,
    fetchServices: useApiGetDiagnosticEducatifByVisiteID({
      visiteID: visiteID
    }),
    saveService: {
      ...useApiPutDiagnoticEducatifVisite({ visiteID }),
      onResult() {
        adsNotification.success({
          message: "Programme éducatif sauvegardé avec succès.",
          duration: theme?.duration?.notification
            ? theme.duration.notification
            : DEFAULT_NOTIFICATION_DURATION
        });
      }
    },
    deleteServices: {
      ...useApiDeleteDiagnosticEducatifByVisiteIDAndID({
        visiteID
      }),
      onResult(result) {
        services.notifyFormDataChanged(result);

        adsNotification.success({
          message: "Programme éducatif supprimé avec succès.",
          duration: theme?.duration?.notification
            ? theme.duration.notification
            : DEFAULT_NOTIFICATION_DURATION
        });
      }
    }
  });

  useEffect(() => {
    if (services.fetchServices?.fetch) services.fetchServices.fetch();
  }, []);

  return (
    <Form
      services={services}
      formAdapter={DiagnosticEducatifFormAdapter}
      withSaveLoading={null}
      withFetchLoading={withFetchLoading}
      withDeleteLoading={null}
    />
  );
}
