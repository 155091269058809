import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { IconDeleteItem, IconSeparator } from "@ads/ui";
import styled from "styled-components";
import { toFrDate } from "@ads/react-common-module";
import { Modal } from "antd";
import { useState } from "react";
const Right = styled.div `
  display: flex;
  padding: 2rem;
  position: absolute;
  right: 0.5rem;
  transition: all 0.2s ease-in-out;
  ${({ theme }) => theme.media.md} {
    position: static;
  }
`;
const Card = styled.div `
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  min-height: 6rem;
  padding: 3rem 2rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  cursor: pointer;
  margin-bottom: 2.6rem;
  align-items: start;
  transition: all 0.2s ease-in-out;
  &:last-child {
    margin: 2rem;
  }
  padding: 0 0.6rem;

  // Effet permettant de rendre la flèche orange au hover
  /* &:hover ${Right} svg .a {
    stroke: ${(props) => props.theme.colors.primary[100]};
  } */

  ${({ theme }) => theme.media.md} {
    padding: 0.6rem 2rem;
    flex-direction: row;
    align-items: center;
  }

  ${({ theme }) => theme.media.lg} {
    padding: 0.6rem 0;
  }
`;
const Left = styled.div `
  grid-area: left;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  height: 100%;
  justify-content: flex-start;
  padding: 1.7rem;

  ${({ theme }) => theme.media.sm} {
    padding: 1.7rem 0;
  }
  ${({ theme }) => theme.media.md} {
    width: 12%;
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }

  ${({ theme }) => theme.media.lg} {
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
  }
`;
const TagLabel = styled.span `
  padding: 0.5rem 1.4rem;
  background: ${({ theme }) => theme.colors.neutral[130]};
  border-radius: 1.7rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.7rem;
  max-width: 11rem;
  width: fit-content;
  height: fit-content;
  ${({ theme }) => theme.media.md} {
    max-width: 14rem;
  }
`;
const TextTime = styled.div `
  color: ${({ theme }) => theme.colors.primary[100]};
  font-weight: 700;
  display: block;
  margin: 0.5rem;
  .time-item {
    display: block;
    margin-bottom: 0.4rem;
    white-space: pre;
  }

  ${({ theme }) => theme.media.md} {
    border: 0;
  }
`;
const Center = styled.div `
  display: flex;
  justify-content: flex-start;
  padding: 1.7rem;
  gap: 1rem;

  ${({ theme }) => theme.media.md} {
    width: 80%;
  }

  ${({ theme }) => theme.media.lg} {
    width: 100%;
  }
`;
const ButtonRemove = styled.button `
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  z-index: 10;
  margin: 0;
  padding: 0;
  height: 4.2rem;
  width: 4.2rem;
  line-height: 4.2rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;
export function BlocNoteItemAdapter(props) {
    const [modalVisible, setModalVisible] = useState("");
    const redirection = () => {
        if (props.notifier && props.notifier.notifyItemClick) {
            props.notifier.notifyItemClick(props.data);
        }
    };
    const deleteItem = () => {
        if (props.notifier && props.notifier.notifyItemDeleteClick) {
            props.notifier.notifyItemDeleteClick(props.data);
        }
    };
    return (_jsxs(Card, { children: [_jsx(Left, Object.assign({ onClick: () => {
                    redirection();
                } }, { children: _jsxs(TextTime, { children: ["Du ", toFrDate(props.data.date)] }, void 0) }), void 0), _jsxs(Center, Object.assign({ onClick: () => {
                    redirection();
                } }, { children: [_jsx(TagLabel, { children: props.data.typeNote.libelle }, void 0), " ", props.data.information] }), void 0), _jsxs(Right, Object.assign({ onClick: () => {
                    redirection();
                } }, { children: [props.data.masquer && _jsx(_Fragment, { children: "Note\u00A0Clotur\u00E9e" }, void 0), _jsx(IconSeparator, { iconSize: "2.5rem", iconColor: "#524D72" }, void 0)] }), void 0), props.isDeletable(props.data) && (_jsxs(_Fragment, { children: [_jsx(ButtonRemove, Object.assign({ onClick: () => setModalVisible("deleteObjet") }, { children: _jsx(IconDeleteItem, { iconSize: "3em", iconColor: "#ffffff" }, void 0) }), void 0), _jsx(Modal, Object.assign({ title: "", visible: modalVisible === "deleteObjet", onOk: () => {
                            deleteItem();
                            setModalVisible("");
                        }, onCancel: () => setModalVisible("") }, { children: _jsx("p", { children: "Voulez-vous supprimer la note ?" }, void 0) }), void 0)] }, void 0))] }, void 0));
}
