import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  ButtonRound,
  IconSeparator,
  IconBlocNote,
  IconAppareil,
  IconConsommables,
  IconEvenements,
  IconFicheSuivi,
  IconFinVisite,
  IconQuestionnaire
} from "@ads/ui";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
// Hooks
import useWindowSize from "../../../hooks/useWindowSize";
// Assets
import borderWorkflow from "../../../assets/img/workflow-border.gif";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import nomadTheme from "utils/nomad-theme";
import { RouteParam } from "interface/core/RouteParam";

const WrapperFullWidth = styled("div")`
  width: 110%;
  position: relative;
  ${({ theme }) => theme.media.xxl} {
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: block;
    height: 4px;
    background: url(${borderWorkflow});
  }

  .swiper-slide {
    padding: 0 1rem;
    ${({ theme }) => theme.media.sm} {
      padding: 0 2rem;
    }
    ${({ theme }) => theme.media.md} {
      padding: 0 2rem;
    }
    ${({ theme }) => theme.media.xl} {
      padding: 0 4rem;
    }
    ${({ theme }) => theme.media.xxl} {
      padding: 0 5rem;
    }
  }

  .swiper-container {
    overflow: unset;
  }
`;

const WrapperWorkflow = styled("div")`
  position: relative;
  margin: 0 auto;
  padding: 1rem 6rem;
  ${({ theme }) => theme.media.md} {
    padding: 1rem 6rem;
  }
`;

// La navigation du workflow (les flèches qui permettent d'aller manuellement à droite ou a gauche)
const WrapperNav = styled("div")`
  display: none;

  .button-round {
    cursor: pointer;
    box-shadow: 3px 3px 1.3rem -2px rgb(0 0 0 / 15%);
  }

  .rotate {
    transform: rotate(-180deg);
  }

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  ${({ theme }) => theme.media.md} {
    padding: 0 7rem;
    display: flex;
    z-index: 20;
    position: absolute;
    top: 8rem;
    right: 10%;
    gap: 7px;

    .nav-item {
      margin-right: 7px;
    }
  }
`;

SwiperCore.use([Navigation]);

export const WORKFLOW_HEIGHT = 6;

const MySwiper = styled(Swiper)`
  height: ${WORKFLOW_HEIGHT}rem;
  background-color: transparent;
`;

const SwiperSlideWrapper = styled(NavLink)`
  height: 6rem;
  margin: 0;
  display: inline-flex;
  align-content: center;
  align-self: center;
  align-items: center;
  width: auto;
  text-decoration: none;
  background-color: white;
  border-radius: 1.5rem;
  &:hover .workflow-item-label {
    color: ${({ theme }) => theme.colors.neutral[100]};
    background: ${({ theme }) => theme.colors.primary[100]};
  }

  ${({ theme }) => theme.media.md} {
    width: 22rem;
  }
`;

const WorkflowItemIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 1.5rem;
  padding: 1rem;
  ${({ theme }) => theme.media.md} {
    position: static;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .active & {
    background-color: ${({ theme }) => theme.colors.primary[100]};
    ${({ theme }) => theme.media.md} {
      background-color: ${({ theme }) => theme.colors.neutral[100]};
    }
  }

  .icon {
    width: 70%;
    ${({ theme }) => theme.media.md} {
      width: 100%;
    }
  }
`;

const WorkflowItemLabel = styled.span`
  display: none;
  width: 100%;
  padding: 0 1.1rem;
  height: 100%;
  transition: all 0.2s ease-in-out;
  color: black;
  border-radius: 0 1.5rem 1.5rem 0;
  .active & {
    color: ${({ theme }) => theme.colors.neutral[100]};
    background: ${({ theme }) => theme.colors.primary[100]};
  }

  ${({ theme }) => theme.media.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2.3rem;
  }
`;

interface WorkflowProps {
  iPKVisite: number;
}

const Workflow = ({ iPKVisite }: WorkflowProps) => {
  // theme utilisé pour afficher la classe active sur les élements
  const { theme } = useParams<RouteParam>();

  const { pathname } = useLocation();
  const swiperRef = useRef<any>(null);

  let initialSlide = 0;

  // Permet de gérer le positionnement du workflow en fonction de l'URL.
  // Peut-être amélioré en se basant sur le tableau d'items du workflow.
  if (pathname.indexOf("/appareils") !== -1) {
    initialSlide = 0;
  } else if (pathname.indexOf("/consommables") !== -1) {
    initialSlide = 1;
    swiperRef.current?.swiper.slideTo(3);
  } else if (pathname.indexOf("/questionnaires") !== -1) {
    initialSlide = 2;
  } else if (pathname.indexOf("/evenements") !== -1) {
    initialSlide = 4;
  } else if (pathname.indexOf("/bloc-notes") !== -1) {
    initialSlide = 5;
  } else if (pathname.indexOf("/fiches-suivi") !== -1) {
    initialSlide = 6;
  } else if (pathname.indexOf("/fin-de-visite") !== -1) {
    initialSlide = 7;
  }

  const navPrevButton = useRef<HTMLDivElement>(null);
  const navNextButton = useRef<HTMLDivElement>(null);

  const [windowWidth] = useWindowSize();
  const [slidesPerView, setSlidesPerView] = useState<number>(3);

  // Cet effect permet de gérer le nombre d'items du workflow qui sont apparant à l'écran
  // En fonction de la taille de l'écran.
  useEffect(() => {
    if (windowWidth > parseInt(nomadTheme.breakpoints.xxl)) {
      setSlidesPerView(6);
    } else if (windowWidth > parseInt(nomadTheme.breakpoints.xl)) {
      setSlidesPerView(5);
    } else if (windowWidth > parseInt(nomadTheme.breakpoints.lg)) {
      setSlidesPerView(4);
    } else if (windowWidth == parseInt(nomadTheme.breakpoints.md)) {
      setSlidesPerView(3);
    } else if (windowWidth > parseInt(nomadTheme.breakpoints.md)) {
      setSlidesPerView(3);
    } else if (windowWidth > parseInt(nomadTheme.breakpoints.sm)) {
      setSlidesPerView(4);
    } else {
      setSlidesPerView(3);
    }
  }, [windowWidth]);

  const handleOnInit = (Swiper: SwiperCore): void => {
    if (typeof Swiper.params.navigation !== "boolean") {
      const navigation = Swiper.params.navigation;
      if (navigation) {
        navigation.prevEl = navPrevButton.current;
        navigation.nextEl = navNextButton.current;
      }
    }
  };

  const [windowWith] = useWindowSize();

  // Gestion de la taille de l'icone des items du workflow (avec label et sans label)
  const iconSize =
    windowWith > parseInt(nomadTheme.breakpoints.md) ? "3rem" : "6rem";

  interface WorkflowItemModel {
    label: string;
    icon: JSX.Element;
    link: string;
    slug: string;
  }

  // Les éléments qui composent le workflow. Pour ajouter un nouvel item au workflow,
  // Ajoutez simplement cet item à ce tableau.
  const WorkflowItems: WorkflowItemModel[] = [
    {
      label: "Appareils",
      icon: <IconAppareil iconSize={iconSize} />,
      link: "appareils/configuration",
      slug: "appareils"
    },
    {
      label: "Consommables",
      icon: <IconConsommables iconSize={iconSize} />,
      link: "consommables/configuration",
      slug: "consommables"
    },
    {
      label: "Commandes",
      icon: <IconConsommables iconSize={iconSize} />, // TODO: Commande Icon missing
      link: "commande",
      slug: "commande"
    },
    {
      label: "Questionnaires",
      icon: <IconQuestionnaire iconSize={iconSize} />,
      link: "questionnaires/obligatoires",
      slug: "questionnaires"
    },
    {
      label: "Evenements",
      icon: <IconEvenements iconSize={iconSize} />,
      link: "evenements/irelm-teleobs",
      slug: "evenements"
    },
    {
      label: "Bloc-notes",
      icon: <IconBlocNote iconSize={iconSize} />,
      link: "bloc-notes",
      slug: "bloc-notes"
    },
    {
      label: "Fiches de suivi",
      icon: <IconFicheSuivi iconSize={iconSize} />,
      link: "suivi-patient/constantes",
      slug: "suivi-patient"
    },
    {
      label: "Programmes",
      icon: <IconConsommables iconSize={iconSize} />,
      link: "programmes/programmes-educatifs",
      slug: "programmes"
    },
    {
      label: "Fin de visite",
      icon: <IconFinVisite iconSize={iconSize} />,
      link: "fin-de-visite/observations-internes",
      slug: "fin-de-visite"
    }
  ];

  return (
    <WrapperFullWidth className="workflow workflow-WrapperFullWidth">
      <WrapperWorkflow className="workflow swiper-container">
        <WrapperNav className="workflow-WrapperNav">
          <div ref={navPrevButton}>
            <ButtonRound
              backgroundStyle="primary"
              borderStyle="primary"
              className="rotate"
              size="4rem"
            >
              <IconSeparator iconSize="2.3rem" iconColor="#FFF" />
            </ButtonRound>
          </div>
          <div ref={navNextButton}>
            <ButtonRound
              backgroundStyle="primary"
              borderStyle="primary"
              className=""
              size="4rem"
            >
              <IconSeparator iconSize="2.3rem" iconColor="#FFF" />
            </ButtonRound>
          </div>
        </WrapperNav>
        <MySwiper
          className="workflow"
          initialSlide={initialSlide}
          slidesPerView={slidesPerView}
          onBeforeInit={handleOnInit}
        >
          {WorkflowItems.map((item) => {
            return (
              <SwiperSlide key={`/visites/${iPKVisite}/${item.link}`}>
                <SwiperSlideWrapper
                  to={`/visites/${iPKVisite}/${item.link}`}
                  className={`${theme === item.slug ? "active" : ""}`}
                >
                  <WorkflowItemIcon className="workflow-item-icon">
                    {item.icon}
                  </WorkflowItemIcon>
                  <WorkflowItemLabel className="workflow-item-label">
                    {item.label}
                  </WorkflowItemLabel>
                </SwiperSlideWrapper>
              </SwiperSlide>
            );
          })}
        </MySwiper>
      </WrapperWorkflow>
    </WrapperFullWidth>
  );
};

export default Workflow;
