import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { SelectWrapper, Label, Select } from "@ads/ui";
const Wrapper = styled.div `
  & label {
    white-space: nowrap;
  }

  ${({ theme }) => theme.media.md} {
    display: flex;
    gap: 1rem;
    width: 33%;

    & label {
      width: 8rem;
      line-height: 4.8rem;
    }
  }
`;
export const FormLivraisonVisiteAffectationProduit = ({ livraisons, affectations, refreshData, }) => {
    const onSelect = (value) => {
        const affectationProduit = affectations.find((affectation) => `${affectation.parametreId}` === value);
        const data = Object.assign(Object.assign({}, livraisons[0]), { affectationProduit });
        refreshData(data);
        // if (livraisons.length > 1) {
        //   setLivraisonsConsommables(
        //     livraisonsConsommables.map((l: LivraisonVisiteModel) =>
        //       livraisons.find((ls) => ls.ligneLivraisonID === l.ligneLivraisonID)
        //         ? { ...l, affectationProduit }
        //         : l
        //     )
        //   );
        // } else {
        //   setLivraisonsConsommables(
        //     livraisonsConsommables.map((l: LivraisonVisiteModel) =>
        //       l.ligneLivraisonID === livraisons[0].ligneLivraisonID
        //         ? livraisonConsommable
        //         : l
        //     )
        //   );
        // }
    };
    const options = affectations.map((affectation) => ({
        value: `${affectation.parametreId}`,
        content: affectation.libelle,
    }));
    return (_jsxs(Wrapper, { children: [_jsx(Label, Object.assign({ htmlFor: `affectation-${livraisons[0].ligneLivraisonID}` }, { children: "Affectation :" }), void 0), _jsx(SelectWrapper, Object.assign({ selectMode: "light" }, { children: _jsx(Select, { id: `affectation-${livraisons[0].ligneLivraisonID}`, name: `affectation-${livraisons[0].ligneLivraisonID}`, onSelect: onSelect, showSearch: true, filterOption: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, itemsSelect: options, selectMode: "light", placeholder: "S\u00E9lectionnez une affectation", defaultValue: livraisons[0].affectationProduit &&
                        livraisons[0].affectationProduit.parametreId
                        ? `${livraisons[0].affectationProduit.parametreId}`
                        : undefined }, void 0) }), void 0)] }, void 0));
};
