import {
  ReglageAPI,
  ReglageValeurAPI,
  ReglageValeurHoraireAPI,
} from "@ads/isadom-model";
import {
  ModesReglagesAppareil,
  TypeReglage,
} from "../interfaces/modes-reglages.interfaces";

export function updateReglageAppareil(
  modesReglagesAppareil: ModesReglagesAppareil,
  sCode: string,
  typeReglage: TypeReglage,
  sLibelle: string,
  value: string,
  reglagesValeursHoraires: ReglageValeurHoraireAPI[] = []
) {
  const newReglageAppareil: ModesReglagesAppareil = {
    ...modesReglagesAppareil,
    modesSaisis: modesReglagesAppareil.modesSaisis.map((modeSaisi) => {
      if (modeSaisi.reglageModeID === modesReglagesAppareil.modeActif) {
        return {
          ...modeSaisi,
          tabGrille: modeSaisi.tabGrille.map((grille, indexGrille) => {
            if (indexGrille === modesReglagesAppareil.grilleActive) {
              return {
                ...grille,
                tabReglage: grille.tabReglage.map((reglage) => {
                  if (reglage.sCode === sCode) {
                    // Si j'ai déjà une valeur présente dans mon tableau de réglage, je recherche la valeur et la modifie
                    if (reglage.tabReglageValeur.length > 0) {
                      const isHere = reglage.tabReglageValeur.some(
                        (reglageValeur) =>
                          reglageValeur.sTypeReglage === typeReglage
                      );

                      // Si la valeur est déjà présente (ex: je modifie un théorique existant)
                      if (isHere) {
                        const newReglage = {
                          ...reglage,
                          tabReglageValeur: reglage.tabReglageValeur.map(
                            (reglageValeur) => {
                              if (reglageValeur.sTypeReglage === typeReglage) {
                                if (reglagesValeursHoraires.length > 0) {
                                  return {
                                    ...reglageValeur,
                                    tabReglageValeurHoraire:
                                      reglagesValeursHoraires,
                                    sValeur: value,
                                  };
                                } else {
                                  return {
                                    ...reglageValeur,
                                    sValeur: value,
                                  };
                                }
                              }
                              return reglageValeur;
                            }
                          ),
                        };

                        return newReglage;
                      } else {
                        const newReglageValeur: ReglageValeurAPI = {
                          reglageID: 0,
                          codeReglageID: "",
                          sLibelleReglage: "",
                          sTypeReglage: typeReglage,
                          sValeur: `${value}`,
                          dSaisie: "",
                          bInterpretation: false,
                          iCPNautReglageGrille: 0,
                          sIDRegroupement: "",
                          bModeHoraire:
                            reglagesValeursHoraires.length > 0 ? true : false,
                          bTheoriqueInactif: false,
                          tabReglageValeurHoraire: reglagesValeursHoraires,
                        };

                        const newReglage: ReglageAPI = {
                          ...reglage,
                          tabReglageValeur: [
                            ...reglage.tabReglageValeur,
                            newReglageValeur,
                          ],
                        };
                        return newReglage;
                      }
                    } else {
                      // Sinon la valeur n'est pas présente dnas le tableau, on créé un nouvel objet
                      const newReglage: ReglageAPI = {
                        ...reglage,
                        tabReglageValeur: [
                          {
                            reglageID: 0,
                            codeReglageID: "0",
                            sLibelleReglage: sLibelle,
                            sTypeReglage: typeReglage,
                            sValeur: value,
                            dSaisie: "",
                            bInterpretation: false, // TODO à l'initialisation quelle doit etre la valeur ?
                            iCPNautReglageGrille: 0,
                            sIDRegroupement: "",
                            bModeHoraire:
                              reglagesValeursHoraires.length > 0 ? true : false,
                            bTheoriqueInactif: false, // TODO à l'initialisation quelle doit etre la valeur ?
                            tabReglageValeurHoraire: reglagesValeursHoraires,
                          },
                        ],
                      };

                      return newReglage;
                    }
                  }
                  return reglage;
                }),
              };
            }
            // Retourne la grille non modifiée si ce n'est pas la grille active
            return grille;
          }),
        };
      }
      return modeSaisi;
    }),
  };
  return newReglageAppareil;
}
