import { ProgrammeEducatifModel } from "@ads/isadom-model";
import {
  useDeleteWithCache,
  varUndefinedToNull
} from "@ads/react-common-module";
import { DataSourceDeleteResult } from "@ads/react-common-module/lib/esm/data-source/model/DataSourceDeleteResult";
import { useApiRAZPEList } from "../api";
import { useSelectPEStore } from "../components/context/PEContext";

export interface RaZProgrammeEducatifByVisiteIDHookInput
  extends Record<string, string | number> {
  visiteID: number;
}

export function useRaZPE(criteria?: RaZProgrammeEducatifByVisiteIDHookInput) {
  const { setPEList } = useSelectPEStore();

  return useDeleteWithCache<
    ProgrammeEducatifModel[],
    RaZProgrammeEducatifByVisiteIDHookInput
  >({
    criteria,

    deleteFromCache(_criteria, data) {
      const result = varUndefinedToNull(data);
      setPEList(result);
      return result;
    },
    deleteFromRemote: (criteria) => {
      return useApiRAZPEList(criteria) as unknown as DataSourceDeleteResult<
        ProgrammeEducatifModel[],
        RaZProgrammeEducatifByVisiteIDHookInput
      >;
    }
  });
}
