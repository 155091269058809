import { INavigationItem } from "@ads/ui";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { RouteParam } from "interface/core/RouteParam";
import { useSelectVisiteStore } from "modules/visites/store/visiteStore";
import { useParams } from "react-router-dom";

const CODE_FICHE_SUIVI_AUTH = "ParFicheSuivi";

const NavigationFicheSuivi = () => {
  const { ficheSuiviID, iPKVisite, tabActive } = useParams<RouteParam>();
  const { visitesMap } = useSelectVisiteStore();
  const navItems: Array<INavigationItem> = [
    {
      to: `/visites/${iPKVisite}/suivi-patient/constantes`,
      label: "Constantes",
      active: tabActive === "constantes",
      visible: true
    }
  ];

  const visite = visitesMap.get(+iPKVisite);

  if (visite) {
    let ficheSuiviFound = false;
    for (const feature of visite.fonctionnalite) {
      if (feature.etat !== 0) {
        ficheSuiviFound = true;
        if (feature.id.startsWith(CODE_FICHE_SUIVI_AUTH)) {
          const id = feature.id.split(".")[1];
          navItems.push({
            to: `/visites/${iPKVisite}/suivi-patient/fiches-suivi/${id}`,
            label: feature.libelle,
            active: tabActive === "fiches-suivi" && ficheSuiviID === id,
            visible: true
          });
          navItems.push({
            to: `/visites/${iPKVisite}/suivi-patient/iah/${id}`,
            label: "IAH " + feature.libelle,
            active: tabActive === "iah" && ficheSuiviID === id,
            visible: true
          });
        }
      }
    }
    if (ficheSuiviFound) {
      navItems.push({
        to: `/visites/${iPKVisite}/suivi-patient/param-edition`,
        label: "Paramètres d'éditions",
        active: tabActive === "param-edition",
        visible: true
      });
    }
  }

  return (
    <NavigationPrimary
      navItems={navItems || []}
      isOpen={false}
      isLargeNav={true}
    />
  );
};

export default NavigationFicheSuivi;
