import { useState } from "react";
import { ConfigAppareilSpecTiersVisiteAPI, VisiteAPI } from "@ads/isadom-model";
import {
  IconSeparator,
  DeleteButton,
  LabelNew,
  ButtonRestaurer,
  theme
} from "@ads/ui";

import { notification } from "antd";
import { IErrorBackEnd } from "interface/erreur/api/errorBackEnd";
import { RetraitAppareil } from "interface/retraitAppareil";
import { Dispatch, SetStateAction, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import useApiPutConfigAppSpecTiersVisite from "hooks/useApiPutConfigAppSpecTiersVisite";
import {
  useDeleteAppareilMutation,
  useGetVisiteQuery
} from "store/redux/services/nomadApi";
import styled from "styled-components";
import errorAdapter from "utils/errorAdapter";
import formNotification from "utils/errors/formNotification";
import { RouteParam } from "../../../../interface/core/RouteParam";
import { PropsFilters } from "../templates/TemplateConfiguration";
import AlertsItemAppareil from "./AlertesAppareilItem";
import DetailItemAppareil from "./DetailAppareilItem";
import InfosItemAppareil from "./InfosAppareilItem";

//Wrapper qui sert a positionner le bouton delete hors de la card pour le rendre clicable
const WrapperMain = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2.6rem;
`;

const Appareil = styled(NavLink)<{ disabled: boolean }>`
  display: grid;
  align-items: center;
  grid-template-areas:
    "status alerts"
    "prestations btn"
    "details btn"
    "infos btn";
  min-height: 13.6rem;
  padding: 0 0.6rem;
  margin-bottom: 2.6rem;
  background: ${({ disabled, theme }) =>
    disabled ? theme.colors.neutral[120] : theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  cursor: ${({ disabled }) => (disabled ? `inherit` : `pointer`)};

  &:last-child {
    margin: 0;
  }

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.neutral[150] : theme.colors.neutral[180]};

  ${({ theme }) => theme.media.sm} {
    grid-template-areas:
      "status prestations alerts alerts"
      "details details infos btn";

    grid-template-columns: 15% 35% 40% 10%;
  }

  ${({ theme }) => theme.media.md} {
    grid-template-areas:
      "status status alerts alerts"
      "prestations details infos btn";

    grid-template-columns: 25% 25% 40% 10%;

    padding: 0.6rem 2rem;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-areas: "status prestations details infos alerts btn";
    grid-template-columns: 5% 15% 25% 30% 20% 5%;
    grid-template-rows: auto;
    padding: 0.6rem 0;
  }
`;

const Status = styled.div`
  grid-area: status;
  display: flex;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  height: 100%;

  align-items: center;
  justify-content: flex-start;

  padding: 1.7rem;

  ${({ theme }) => theme.media.sm} {
    padding: 1.7rem;
  }

  ${({ theme }) => theme.media.lg} {
    justify-content: center;
    align-items: center;
    padding: 0;
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;

const AppareilStatus = styled.div`
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.neutral[130]};
  border-radius: 50%;
`;

const Prestations = styled.div`
  grid-area: prestations;
  display: flex;
  height: 100%;
  padding: 1.7rem;

  ${({ theme }) => theme.media.md} {
    padding: 1.5rem 0;
    align-items: flex-start;
  }
  ${({ theme }) => theme.media.lg} {
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`;

const PrestationLabel = styled.span`
  padding: 0.5rem 1.4rem;
  background: ${({ theme }) => theme.colors.neutral[130]};
  border-radius: 1.7rem;
  font-family: ${({ theme }) => theme.font.type.primaryMedium};
  font-size: 1.2rem;
  line-height: 1.7rem;
  max-width: 14rem;
`;

const Btn = styled("button")`
  grid-area: btn;
  display: flex;
  justify-content: flex-end;
  border: none;
  background: none;
  ${({ theme }) => theme.media.lg} {
    justify-content: center;
    align-items: center;
  }
`;

const WrapperPrestationLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperButton = styled(NavLink)`
  display: block;
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  padding: 0.5rem;
  cursor: pointer;
  ${({ theme }) => theme.media.sm} {
    top: -2.2rem;
    right: -2.2rem;
  }
`;

const WrapperButtonNewAppareil = styled.div`
  display: block;
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  padding: 0.5rem;
  cursor: pointer;
  ${({ theme }) => theme.media.sm} {
    top: -2.2rem;
    right: -2.2rem;
  }
`;

interface AppareilItemProps {
  appareilsAbsents: ConfigAppareilSpecTiersVisiteAPI[];
  configurationProduitSpec: ConfigAppareilSpecTiersVisiteAPI;
  visite: VisiteAPI | undefined;
  filtresAppareils: PropsFilters;
  setFiltresAppareils: Dispatch<SetStateAction<PropsFilters>>;
}

const openSuccess = (key: string) => {
  notification.success({
    key,
    message: "Validation du formulaire",
    description: "L'appareil est retiré de la configuration",
    duration: theme.duration.notification
  });
};

const AppareilItem = ({
  configurationProduitSpec,
  appareilsAbsents,
  filtresAppareils,
  setFiltresAppareils
}: AppareilItemProps) => {
  const [appareil, setAppareil] = useState(configurationProduitSpec);
  const [postRetrait, { error, isSuccess }] = useDeleteAppareilMutation();
  const { iPKVisite } = useParams<RouteParam>();
  const { data: visite } = useGetVisiteQuery(+iPKVisite);
  const isAppareilCouldBeRetirer = false;

  const { handleRestaure } = useApiPutConfigAppSpecTiersVisite(
    parseInt(iPKVisite)
  );

  const handleClick = async () => {
    const response = await handleRestaure(configurationProduitSpec);

    if (response?.status === 200) {
      setAppareil(response?.data);
    }
  };

  const handleClickRetrait = () => {
    const dataAppareilToSend: RetraitAppareil = {
      appareilId: configurationProduitSpec.produit.IDProduit,
      visiteId: +iPKVisite,
      localisation: {},
      motif: {
        iPKMotifRetraitDM: 0
      },
      information: "",
      compteur: {}
    };
    postRetrait(dataAppareilToSend);
  };

  useEffect(() => {
    if (isSuccess) {
      // Suppression de l'appareil de la liste des appareils
      setFiltresAppareils({
        ...filtresAppareils,
        idProduit: configurationProduitSpec.produit.IDProduit
      });
      // Notification de succès
      openSuccess("L'appareil est retiré de la configuration");
    }

    if (error) {
      if ("data" in error) {
        errorAdapter(error.data as IErrorBackEnd)?.forEach((error) =>
          formNotification({ type: "error", error: error.message })
        );
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isSuccess, visite?.ID]);

  return (
    <WrapperMain>
      {appareil.bRetrait && <ButtonRestaurer handleClick={handleClick} />}
      {!appareil.bRetrait && !appareil.bAbsent && (
        <WrapperButton
          className="wrapper-button"
          to={`/visites/${iPKVisite}/appareils/configuration/${appareil.produit.IDProduit}/retrait`}
        >
          <DeleteButton />
        </WrapperButton>
      )}
      {/* Retrait d'un appreil qui vient d'être ajouté */}
      {appareil.bAjout && (
        <WrapperButtonNewAppareil onClick={handleClickRetrait}>
          <DeleteButton />
        </WrapperButtonNewAppareil>
      )}
      {appareil.bAjout && <LabelNew>Nouveau</LabelNew>}
      {appareil.bRetrait ? (
        <Appareil
          className="wrapper-appareil"
          disabled={appareil.bRetrait}
          as="div"
        >
          <Status>
            <AppareilStatus />
          </Status>
          <Prestations>
            {appareil.typePrestation.sLibelle && (
              <WrapperPrestationLabel>
                <PrestationLabel>
                  {appareil.typePrestation.sLibelle}
                </PrestationLabel>
              </WrapperPrestationLabel>
            )}
          </Prestations>
          <DetailItemAppareil configurationProduitSpec={appareil} />
          <InfosItemAppareil configurationProduitSpec={appareil} />
          <AlertsItemAppareil
            configurationProduitSpec={appareil}
            appareilsAbsents={appareilsAbsents}
          />
          <Btn>
            <IconSeparator iconSize="2.5rem" iconColor="#524D72" />
          </Btn>
        </Appareil>
      ) : (
        <Appareil
          className="wrapper-appareil"
          disabled={isAppareilCouldBeRetirer}
          to={`/visites/${iPKVisite}/appareils/configuration/${appareil.produit.IDProduit}/fiche`}
        >
          <Status className="status">
            <AppareilStatus />
          </Status>
          <Prestations className="prestations">
            {appareil.typePrestation.sLibelle && (
              <WrapperPrestationLabel>
                <PrestationLabel>
                  {appareil.typePrestation.sLibelle}
                </PrestationLabel>
              </WrapperPrestationLabel>
            )}
          </Prestations>
          <DetailItemAppareil configurationProduitSpec={appareil} />
          <InfosItemAppareil configurationProduitSpec={appareil} />
          <AlertsItemAppareil
            configurationProduitSpec={appareil}
            appareilsAbsents={appareilsAbsents}
          />
          <Btn>
            <IconSeparator iconSize="2.5rem" iconColor="#524D72" />
          </Btn>
        </Appareil>
      )}
    </WrapperMain>
  );
};

export default AppareilItem;
