import { useState, useCallback, useEffect } from "react";
import { ObservanceVisiteModel } from "@ads/isadom-model";
import { ApplicationContext } from "@ads/react-common-module";
import { adsNotification, DEFAULT_NOTIFICATION_DURATION } from "@ads/ui";
import {
  apiGetObservancesByVisiteID,
  apiPutObservancesByVisiteID
} from "api/observances";

const useObservancesForm = (visiteID: number) => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isErrorData, setIsErrorData] = useState<boolean>(false);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [data, setData] = useState<ObservanceVisiteModel[]>([]);

  const theme = ApplicationContext.getInstance()
    .getAppSettings()
    .getData()?.theme;

  const fetchObservances = useCallback(
    async (visiteID: number): Promise<void> => {
      try {
        setIsErrorData(false);
        setIsLoadingData(true);

        const data = await apiGetObservancesByVisiteID(visiteID);

        setData(data);
      } catch (err: any) {
        setIsErrorData(true);
      } finally {
        setIsLoadingData(false);
      }
    },
    []
  );

  const saveObservances = async (
    visiteID: number,
    observances: ObservanceVisiteModel[]
  ): Promise<void> => {
    try {
      setIsLoadingSave(true);

      const { messages } = await apiPutObservancesByVisiteID(
        visiteID,
        observances
      );

      adsNotification.success({
        message: messages[0].message,
        duration: theme?.duration?.notification
          ? theme.duration.notification
          : DEFAULT_NOTIFICATION_DURATION
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setIsLoadingSave(false);
    }
  };

  useEffect(() => {
    if (visiteID) {
      fetchObservances(visiteID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiteID]);

  return { isLoadingData, isErrorData, data, saveObservances, isLoadingSave };
};

export default useObservancesForm;
