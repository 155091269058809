
import { ProgrammeEducatifModel } from "@ads/isadom-model";
import { UseApiGetV3Output } from "@ads/react-common-module";
import { useAPIGetProgrammeEducatifByVisiteID } from "../api";
import { useSelectPEStore } from "../components/context/PEContext";


export function useSyncPEByVisiteIDWithContext(visiteID: number): UseApiGetV3Output<ProgrammeEducatifModel[]> {

  const { setPEList } = useSelectPEStore()
  const service = useAPIGetProgrammeEducatifByVisiteID(visiteID)
  async function fetchWithSync() {
    const result = await service.fetch()
    setPEList(result)
    return result
  }

  return {
    ...service,
    fetch: fetchWithSync
  }

}
