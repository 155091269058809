import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { v4 as uuid } from "uuid";
import { SelectWrapper } from "@ads/ui";
import { Select } from "antd";
import styled from "styled-components";
import { SearchTypeQuestionnaire } from "./SearchTypeQuestionnaire";
const WrapperTitle = styled("div") `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
  button {
    font-family: ${({ theme }) => theme.font.type
    .primaryMedium} !important; // Surcharge style par défaut ANTD
  }
`;
const TitleMain = styled.h1 `
  padding: 0 0 0 1rem;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.8rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2.2rem;
  }
`;
const WrapperFilterButton = styled("div") `
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  align-items: center;
  margin-top: 0.9rem;
  ${({ theme }) => theme.media.lg} {
    margin-top: 0;
  } ;
`;
const SearchTypeQuestionnaireList = styled(SearchTypeQuestionnaire) `
  min-width: 30rem;
`;
export function ListViewHeader(props) {
    return (_jsxs(WrapperTitle, { children: [_jsx(TitleMain, { children: props.title }, void 0), _jsxs(WrapperFilterButton, { children: [props.filterProps && _jsx(Filter, Object.assign({}, props.filterProps), void 0), props.add && (_jsx(SearchTypeQuestionnaireList, { name: "addQuestionnaire", onSelect: props.onSelect }, void 0))] }, void 0)] }, void 0));
}
const { Option } = Select;
const Wrapper = styled("div") `
  display: none;
  ${({ theme }) => theme.media.lg} {
    display: flex;
    align-items: center;
    height: 4.5rem;
    padding: 0 2rem;
    background: ${({ theme }) => theme.colors.neutral[100]};
    border-radius: 5rem;
    opacity: 1;
  } ;
`;
const Label = styled("label") `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;

  .item {
    font-size: 1.4rem;
    opacity: 0.65;
  }
`;
const SelectWrapperCustom = styled(SelectWrapper) `
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 4.5rem;
    border-radius: 1.3rem;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    position: relative;
    top: 2px;
    line-height: 4.5rem;
    font-weight: 500;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 4.5rem;
  }
  // Focus
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    box-shadow: 0;
  }
`;
const Filter = (props) => {
    return (_jsx(_Fragment, { children: props && (_jsx(Wrapper, { children: _jsxs(Label, { children: [_jsxs("span", Object.assign({ style: { minWidth: "20rem" }, className: "item" }, { children: ["Filtrer par ", props.filterLibelle, " :"] }), void 0), _jsx(SelectWrapperCustom, Object.assign({ className: "select-wrapper", selectMode: "light" }, { children: _jsx(Select, Object.assign({ allowClear: true, style: { minWidth: "30rem" }, dropdownClassName: "customDropDown", showSearch: true, optionFilterProp: "children", placeholder: props.filterPlaceholder, onClear: () => props.onFilterChanged && props.onFilterChanged(undefined), onChange: (value, _) => props.onFilterChanged &&
                                value &&
                                props.onFilterChanged(value === null || value === void 0 ? void 0 : value.toString()) }, { children: props &&
                                props.filterList &&
                                props.filterList.map((value) => (_jsx(Option, Object.assign({ value: value }, { children: value }), uuid()))) }), void 0) }), void 0)] }, void 0) }, void 0)) }, void 0));
};
