import axios from "axios";
import styled from "styled-components";
import { useParams } from "react-router";
import { Loading } from "@ads/ui";
import { useHistory } from "react-router-dom";
import config from "config";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";
import { ConfigAppareilSpecTiersVisiteAPI } from "@ads/isadom-model";
import TextHint from "components/ui/atoms/text/TextHint";
import formNotification from "utils/errors/formNotification";
import { useState } from "react";
import { InstallationAppareil } from "components/core/selection/InstallationAppareil";

interface RouteParams {
  iPKVisite: string;
}
const FormItem = styled("div")`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;

  .ant-select {
    min-width: 30rem;
  }
`;

interface AjoutAppareilProps {
  appareilID: number;
  visiteId: number | undefined;
}

interface PropsSelectAppareils {
  hint?: string;
  configurationsProduitSpec: ConfigAppareilSpecTiersVisiteAPI[] | undefined;
}

/**
 * @author Bastien Escourbiac
 * @date 2021-10-20
 * @param hint - Texte informatif à côté du label.
 * @param appareils - Liste des appareils
 * @param multiple - Sélection multiple dans les résultats de recherche.
 */
export default function SelectAppareils({
  hint,
  configurationsProduitSpec,
}: PropsSelectAppareils) {
  const { iPKVisite } = useParams<RouteParams>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isLoadingAddAppareil, setIsLoadingAddAppareil] =
    useState<boolean>(false);

  const history = useHistory();

  const idVisite = iPKVisite || 0;
  // Récupération de la visite en cours pour avoir son adresse
  const {
    data: visite,
    isError: isErrorVisite,
    isLoading,
  } = useGetVisiteQuery(+idVisite);

  if (isErrorVisite || (!visite && !isLoading)) return <></>;

  if (!configurationsProduitSpec) return <></>;

  //Fonction d'ajout d'un appareil
  async function postAjoutAppareil(
    payload: any,
    paramsUrl: AjoutAppareilProps
  ) {
    setIsLoadingAddAppareil(true);
    try {
      const response = await axios.put(
        `${config.apiURL}visites/${paramsUrl.visiteId}/appareils/${paramsUrl.appareilID}/installations`,
        payload
      );
      //Redirection sur la fiche à la soumission du formulaire
      if (response.data) {
        setIsLoadingAddAppareil(false);
        formNotification({ type: "success" });
        history.push(
          `/visites/${paramsUrl.visiteId}/appareils/configuration/${paramsUrl.appareilID}/fiche`
        );
      } else {
        return <Loading>Chargement en cours...</Loading>;
      }
    } catch (error: any) {
      error.response.data?.messages.forEach((err: any) => {
        formNotification({ type: "error", error: err.message });
      });
      setIsLoadingAddAppareil(false);
    }
  }

  const onSelect = async (idApppareil: number) => {
    postAjoutAppareil({}, { visiteId: visite?.ID, appareilID: idApppareil });
  };

  return (
    <>
      {hint && <TextHint>{hint}</TextHint>}
      <FormItem>
        <InstallationAppareil
          requestInstallationAppareil={(materiel) =>
            onSelect(materiel.appareilID)
          }
        />
      </FormItem>
    </>
  );
}
