import {
  BaseParametreEditionList,
  IBaseParametreEditionListProps,
} from ".generated/list";
import {
  SelectionTiersDestinatairePE,
  SelectionTiersDestinatairePEProps,
} from "../../parametre-edition-select";

export interface ParametreEditionProps {
  selectionTiersProps: SelectionTiersDestinatairePEProps;
  listPEProps: IBaseParametreEditionListProps;
}

export function ParametreEdition(props: ParametreEditionProps) {
  return (
    <>
      <SelectionTiersDestinatairePE {...props.selectionTiersProps} />
      <br />
      <BaseParametreEditionList {...props.listPEProps} />
    </>
  );
}
