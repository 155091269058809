import { INavigationItem } from "@ads/ui";
import { FC } from "react";

export interface NavItem extends INavigationItem {
  id?: string;
  component?: React.ReactElement;
}
export interface TemplateSaisieVisiteProps {
  title: string;
  visiteID?: string;
  patientID?: number;
  navItems: Array<NavItem>;
  children: React.ReactNode;
  isLargeNav?: boolean;
}

export const TemplateSaisieVisite: FC<TemplateSaisieVisiteProps> = ({
  children
}: TemplateSaisieVisiteProps): JSX.Element => {
  return <>{children}</>;
};
