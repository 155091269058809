import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { List } from ".";
export function ListFilterTemplate({ service, loadingView, listProps, errorView, listDecorator, }) {
    if (service.fetchLoading || listProps.services.forceDisplayFetchLoading) {
        return _jsx(_Fragment, { children: loadingView }, void 0);
    }
    else if (!service.fetchLoading && service.dataFiltered && service.data) {
        const ListComponent = _jsx(List, Object.assign({ data: service.dataFiltered }, listProps), void 0);
        return (_jsx(_Fragment, { children: listDecorator({
                listView: ListComponent,
                service: service,
                uiService: listProps.services,
            }) }, void 0));
    }
    else {
        return _jsx(_Fragment, { children: errorView }, void 0);
    }
}
