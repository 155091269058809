import styled from "styled-components";
import { SelectionTiersV3 } from "@ads/module-tiers";
import { FormItemWrapper, Label, Loading } from "@ads/ui";
import { ISelectTiersVisiteHookService } from "../hook/interfaces/ISelectTiersVisiteHookService";

export interface SelectTiersVisiteProps {
  label: string;
  services: ISelectTiersVisiteHookService;
}

const SelectWrapper = styled.div`
  .ant-select {
    min-width: 33rem !important;
  }
`;

export function SelectTiersVisite({ services, label }: SelectTiersVisiteProps) {
  if (services.typeTiersLoading) {
    return <Loading>Chargement du composant de selection de tiers.</Loading>;
  } else {
    return (
      <FormItemWrapper direction="horizontal">
        <Label htmlFor="intervenant_visite">{label}</Label>
        <SelectWrapper>
          <SelectionTiersV3 service={services.serviceSearchSelectTiers} />
        </SelectWrapper>
      </FormItemWrapper>
    );
  }
}
