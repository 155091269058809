import { setup } from "axios-cache-adapter";
import config from "config";

const api = setup({
  baseURL: config.apiURL,
  cache: {
    maxAge: 0,
    exclude: {
      methods: ["post", "put", "patch", "delete"],
    },
  },

  withCredentials: false,
});

export default api;
