import {
  ChecklistVisiteAdapter,
  ChecklistVisiteModel,
} from "@ads/isadom-model";
import { apiGet, ApiInstance, apiPut } from "@ads/react-common-module";

export function apiGetChecklistByVisiteID(
  api: ApiInstance | null,
  visiteID: number
): Promise<ChecklistVisiteModel[]> {
  //TODO FBR replace with rest call
  return new Promise((resolve, reject) => {
    if (api) {
      const adapter: ChecklistVisiteAdapter = new ChecklistVisiteAdapter();
      (
        apiGet(api, `/visites/${visiteID}/check-list`, adapter) as Promise<
          ChecklistVisiteModel[]
        >
      )
        .then((result) => {
          result.sort((a: ChecklistVisiteModel, b: ChecklistVisiteModel) => {
            if (a.libelle < b.libelle) {
              return -1;
            } else if (a.libelle > b.libelle) {
              return 1;
            } else {
              return 0;
            }
          });
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject("La configuration api est null");
    }
  });
}

export function apiUpdateChecklistByVisiteID(
  api: ApiInstance | null,
  visiteID: number,
  checklist: ChecklistVisiteModel
): Promise<ChecklistVisiteModel> {
  if (api) {
    const adapter = new ChecklistVisiteAdapter();
    return apiPut(
      api,
      `/visites/${visiteID}/check-list/${checklist.referenceID}`,
      adapter,
      checklist
    ) as Promise<ChecklistVisiteModel>;
  }
  return new Promise((_, reject) => {
    reject("La configuration api est null");
  });
}
