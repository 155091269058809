import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { DatePicker } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/fr_FR";
const ContainerForms = styled.form `
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.lg} {
    justify-content: flex-end;
  }
`;
const DatePickerANTD = styled(DatePicker) `
  width: 12rem;
  background: ${(props) => props.theme.colors.neutral[100]};
`;
export const FormRetourVisiteDLCLot = ({ retour, refreshData, }) => {
    var _a;
    const handleChange = (newDLC) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const lot = {
            iPKProduitDMCLot: ((_a = retour.lot) === null || _a === void 0 ? void 0 : _a.iPKProduitDMCLot) || -1,
            iFKProduit: ((_b = retour.lot) === null || _b === void 0 ? void 0 : _b.iFKProduit) || -1,
            sLot: ((_c = retour.lot) === null || _c === void 0 ? void 0 : _c.sLot) || "",
            dLimiteConso: newDLC || "",
            dLimiteUtilisation: ((_d = retour.lot) === null || _d === void 0 ? void 0 : _d.dLimiteUtilisation) || "",
            iPKEmplacement: ((_e = retour.lot) === null || _e === void 0 ? void 0 : _e.iPKEmplacement) || -1,
            sCodeEmplacement: ((_f = retour.lot) === null || _f === void 0 ? void 0 : _f.sCodeEmplacement) || "",
            iPKStock: ((_g = retour.lot) === null || _g === void 0 ? void 0 : _g.iPKStock) || -1,
            iStockTheorique: ((_h = retour.lot) === null || _h === void 0 ? void 0 : _h.iStockTheorique) || -1,
        };
        const data = Object.assign(Object.assign({}, retour), { lot });
        refreshData(data);
    };
    const uniqueID = `dlc-${retour.ligneRetourID}-${(_a = retour.lot) === null || _a === void 0 ? void 0 : _a.sLot}`;
    return (_jsx(ContainerForms, { children: _jsx(DatePickerANTD, { name: uniqueID, id: uniqueID, value: retour.lot &&
                retour.lot.dLimiteConso !== "0000-00-00" &&
                retour.lot.dLimiteConso !== ""
                ? moment(retour.lot.dLimiteConso)
                : undefined, format: "DD/MM/YYYY", locale: locale, onChange: (value) => {
                handleChange((value === null || value === void 0 ? void 0 : value.format("YYYY-MM-DD")) || "");
            }, placeholder: "DLC" }, void 0) }, void 0));
};
