import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderCard = styled.div`
  display: none;
  grid-template-areas: "status type-evenement dateDebut dateFin information decision dateAccord validation annulation btn";
  grid-template-columns: 5% 12% 9% 10% 31% 7% 9% 6% 6% 5%;
  padding: 0.6rem;
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  transition: transform 0.1s ease-out, box-shadow 0.2s;
  font-weight: bold;
  margin-bottom: 1.6rem;

  &:last-child {
    margin: 0;
  }

  ${({ theme }) => theme.media.lg} {
    padding: 0.6rem 0;
    display: grid;
  }
`;

export const CardItem = styled(Link)`
  display: grid;
  grid-template-areas:
    "type-evenement type-evenement"
    "dateDebut dateDebut"
    "dateFin dateFin"
    "decision dateAccord"
    "validation annulation"
    "information information";
  padding: 1.6rem;
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  cursor: pointer;
  transition: transform 0.1s ease-out, box-shadow 0.2s;

  &:hover {
    transform: scale(1.008);
    box-shadow: 0 0 1rem rgb(80 70 70 / 35%);
    color: ${({ theme }) => theme.colors.neutral[180]};
  }

  &:last-child {
    margin: 0;
  }

  ${({ theme }) => theme.media.md} {
    grid-template-areas:
      "type-evenement type-evenement btn"
      "dateDebut dateFin btn"
      "decision dateAccord btn"
      "validation annulation btn"
      "information information btn";
    grid-template-columns: 45% 45% 10%;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-areas: "status type-evenement dateDebut dateFin information decision dateAccord validation annulation btn";
    grid-template-columns: 5% 12% 9% 10% 31% 7% 9% 6% 6% 5%;
    padding: 0.6rem;
  }

  ${({ theme }) => theme.media.lg} {
    padding: 0.6rem 0;
  }
`;

export const WrapperButton = styled.div`
  display: block;
  position: absolute;
  top: -2.5rem;
  right: -2.2rem;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 99;
  ${({ theme }) => theme.media.sm} {
    top: -2.2rem;
    right: -2.2rem;
  }
`;

export const LabelItem = styled.span`
  display: block;
  font-weight: bold;
  margin-right: 0.4rem;

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

export const Status = styled.div`
  grid-area: status;
  display: none;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  height: 100%;

  align-items: center;
  justify-content: flex-start;

  padding: 1.7rem;

  ${({ theme }) => theme.media.sm} {
    padding: 1.7rem 0;
  }

  ${({ theme }) => theme.media.lg} {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0;
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;

export const EvenementStatus = styled.div`
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.neutral[130]};
  border-radius: 50%;
`;

const BaseElement = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 0.6rem;
`;

export const TypeEvenement = styled(BaseElement)`
  grid-area: type-evenement;
  display: block;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[130]};
  padding-bottom: 0.6rem;
  font-size: 1.2em;

  ${({ theme }) => theme.media.lg} {
    display: flex;
    margin-bottom: 0;
    padding: 0 2rem;
    border-bottom: none;
    font-size: 1em;
  }
`;

export const DateDebut = styled(BaseElement)`
  grid-area: dateDebut;
`;

export const DateFin = styled(BaseElement)`
  grid-area: dateFin;
`;

export const DateAccord = styled(BaseElement)`
  grid-area: dateAccord;
`;

export const Decision = styled(BaseElement)`
  grid-area: decision;
`;

export const Validation = styled(BaseElement)`
  grid-area: validation;
`;

export const Annulation = styled(BaseElement)`
  grid-area: annulation;
`;

export const Information = styled(BaseElement)`
  grid-area: information;
  display: block;

  ${({ theme }) => theme.media.lg} {
    display: flex;
  }
`;

export const Informations = styled.div`
  margin: 0 2rem 0 0;
  padding: 0.6rem;
  margin-bottom: 2.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.neutral[130]};
  border-radius: 0.6rem;
  ${({ theme }) => theme.media.md} {
    width: calc(50% - 1rem);
    margin-bottom: 0;
  }

  & .quill .ql-container.ql-snow {
    border: none;
  }
`;

export const Btn = styled("button")`
  grid-area: btn;
  display: none;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  border-left: 1px solid ${({ theme }) => theme.colors.neutral[120]};

  ${({ theme }) => theme.media.sm} {
    display: flex;
  }
`;
