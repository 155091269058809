// ? Want to know more ? --> https://medium.com/ovrsea/checking-the-type-of-an-object-in-typescript-the-type-guards-24d98d9119b0
import { VisiteAPI } from "@ads/isadom-model";

export default function checkIsVisite(
  toBeDetermined: any
): toBeDetermined is VisiteAPI {
  if ((toBeDetermined as VisiteAPI).Rdv) {
    return true;
  }
  return false;
}
