import { useState } from "react";
import { apiPutVisite } from "../api/apiPutVisite";
import { VisiteModel } from "@ads/isadom-model";
import { setErrorMessageToVisite } from "modules/feuille-de-route/store/redux/features/feuille-de-route.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/redux";
import { adsNotification, DEFAULT_NOTIFICATION_DURATION } from "@ads/ui";
import { ApplicationContext } from "@ads/react-common-module";
import { useHistory } from "react-router-dom";

export const useEnregistreVisite = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const theme = ApplicationContext.getInstance()
    .getAppSettings()
    .getData()?.theme;

  const enregistreVisite = async (
    visite: VisiteModel
  ): Promise<VisiteModel | undefined> => {
    setLoading(true);
    try {
      const result = await apiPutVisite(visite);
      if (result.data) {
        if (result.data.bValide) {
          dispatch(
            setErrorMessageToVisite({
              visiteID: visite.ID,
              messages: []
            })
          );
          adsNotification.success({
            message: "La visite est validée.",
            duration: theme?.duration?.notification
              ? theme.duration.notification
              : DEFAULT_NOTIFICATION_DURATION
          });
          history.push("/feuille-de-route/en-cours");
        } else {
          adsNotification.success({
            message: "Informations enregistrées avec succès",
            duration: theme?.duration?.notification
              ? theme.duration.notification
              : DEFAULT_NOTIFICATION_DURATION
          });
        }
      } else if (!result.data && !result.messages) {
        if (visite.bValide) {
          adsNotification.error({
            message:
              "Une erreur inconnue est survenue. Veuillez contacter un administrateur.",
            duration: theme?.duration?.notification
              ? theme.duration.notification
              : DEFAULT_NOTIFICATION_DURATION,
          });
        }
      } else if (!result.data) {
        if (visite.bValide) {
          adsNotification.error({
            message:
              "Erreur lors de la validation de la visite. Référez vous au panneau de droite pour avoir le détail.",
            duration: theme?.duration?.notification
              ? theme.duration.notification
              : DEFAULT_NOTIFICATION_DURATION
          });
        }
      }
      if (visite.bValide) {
        dispatch(
          setErrorMessageToVisite({
            visiteID: visite.ID,
            messages: result.messages
          })
        );
      }
      return result.data;
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error("result", err);
    } finally {
      setLoading(false);
    }
  };

  return { enregistreVisite, loading };
};
