import { Loading } from "@ads/ui";
import { Alert } from "antd";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";
import { useSyncFichesSuiviByVisiteIDWithContext } from "../context/useSyncFichesSuiviByVisiteIDWithContext";
import { IAHEditionForm } from "../iah/ui/IAHEditionForm";

export function PageIAH() {
  const { iPKVisite, ficheSuiviID } = useParams<RouteParam>();
  const fichesSuivisServices = useSyncFichesSuiviByVisiteIDWithContext(
    +iPKVisite
  );

  const ficheSuivi = fichesSuivisServices.data.fichesSuivis?.find(
    (data) => data.formulaire.typeForm.id === ficheSuiviID
  );

  if (fichesSuivisServices.loadings.readLoading) {
    return <Loading>Chargement des données en cours</Loading>;
  } else if (ficheSuivi) {
    return (
      <IAHEditionForm
        data={ficheSuivi}
        loadings={fichesSuivisServices.loadings}
        visiteID={+iPKVisite}
      />
    );
  } else {
    return (
      <Alert
        message="Erreur"
        showIcon
        description={
          <div>
            Une erreur inconnue est survenue pendant le chargement. <br />
            Veuillez contacter l'administrateur
          </div>
        }
        type="error"
      />
    );
  }
}
