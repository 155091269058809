var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class SelectionProduitItemListener {
    constructor(id, onAfterDataSelectedChange) {
        this.idListener = id;
        this.onAfterDataSelectedChange = onAfterDataSelectedChange;
    }
    onBeforeDataSelectedChanged(selectedData) {
        return __awaiter(this, void 0, void 0, function* () {
            return selectedData;
        });
    }
    onAfterDataSelectedChanged(selectedData) {
        this.onAfterDataSelectedChange(selectedData);
    }
    onBeforeDataItemSelected(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return data;
        });
    }
    onAfterDataItemSelected(_data) { }
    onBeforeDataItemUnselected(selectedData) {
        return __awaiter(this, void 0, void 0, function* () {
            return selectedData;
        });
    }
    onAfterDataItemUnselected(_selectedData) { }
    onBeforeDataItemSelectedReset() {
        return __awaiter(this, void 0, void 0, function* () {
            return true;
        });
    }
    onAfterDataItemSelectedReset() { }
}
