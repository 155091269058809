import { useEffect } from "react";
import { ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./assets/styles/global";

import frFR from "antd/lib/locale/fr_FR";

import { useAppDispatch } from "./hooks/store";
import { reconnexion, chargeParams } from "./store/redux/services/utilisateur";
import { reconnexionStatus } from "store/redux/features/utilisateur/utilisateur.slice";

import "moment/locale/fr";
import "antd/dist/antd.less";
import "./assets/styles/antd-theme.less";

import { AuthPayload } from "./interface/utilisateur";

import RouterApp from "./router";
import { getCookie } from "./utils/cookies";
import nomadTheme from "utils/nomad-theme";
import {
  ApiConfig,
  ApplicationContext,
  ApplicationSettings
} from "@ads/react-common-module";
import config from "config";

const App = () => {
  const apiConfig = {
    baseURL: config.apiURL
  } as ApiConfig;

  const appSettings = {
    apiConfig: apiConfig,
    theme: nomadTheme
  } as ApplicationSettings;

  const dispatch = useAppDispatch();

  ApplicationContext.instantiate(null, appSettings);

  useEffect(() => {
    const reconnect = async () => {
      dispatch(reconnexionStatus({ reconnexion: true }));
      const result = await dispatch(reconnexion());

      if (result.type === "utilisateur/reconnexion/fulfilled") {
        const utilisateur = result.payload as AuthPayload;
        dispatch(chargeParams(utilisateur.identity.iFKAnnuaire));
      }
      if (result.type === "utilisateur/reconnexion/rejected") {
        dispatch(reconnexionStatus({ reconnexion: false }));
      }
    };

    if (getCookie(config.localStorageIdentityKey)) {
      reconnect();
    }
  }, [dispatch]);

  return (
    <ThemeProvider theme={nomadTheme}>
      {
        <ConfigProvider locale={frFR}>
          <RouterApp />
          <GlobalStyle />
        </ConfigProvider>
      }
    </ThemeProvider>
  );
};

export default App;
