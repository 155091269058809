import { Module } from "@ads/react-common-module";
export * from "./ui";
// export * from "./api";
// export * from "./hooks";
// export * from "./features";
export const MESSAGES_VISITE_BASE_PATH = "messages";
export const MESSAGES_VISITE_DETAIL_PATH = "messages/:messageID";
export const MODULE_NAME = "moduleMessagesVisite";
export class MessagesVisiteModule extends Module {
    constructor() {
        super(MODULE_NAME, null, null);
    }
}
