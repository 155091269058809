var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
import { FamilleAdapter, ModeleAdapter, TypeProduitAdapter, CompteurAdapter, } from "../..";
import { LocalisationAdapter } from "../../..";
var AppareilAdapter = /** @class */ (function (_super) {
    __extends(AppareilAdapter, _super);
    function AppareilAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeProduitAdapter = new TypeProduitAdapter();
        _this.familleAdapter = new FamilleAdapter();
        _this.modeleAdapter = new ModeleAdapter();
        _this.compteurAdapter = new CompteurAdapter();
        _this.localisationAdapter = new LocalisationAdapter();
        return _this;
    }
    AppareilAdapter.prototype.toClient = function (src) {
        return {
            produitId: src.IDProduit,
            refProduit: src.sRefProduit,
            typeProduitFk: src.sFKTypeProduit,
            codeNatureProduit: src.sCodeNatureProduit,
            libelle: src.sLibelle,
            refFabricant: src.sRefFabricant,
            inactif: src.bInactif,
            previsionInactif: src.bPrevisionInactif,
            arretCdeInterne: src.dArretCdeInterne,
            arretCommande: src.bArretCommande,
            typeProduit: src.typeProduit && this.typeProduitAdapter.toClient(src.typeProduit),
            famille: src.famille && this.familleAdapter.toClient(src.famille),
            numeroSerie: src.sNumeroSerie,
            refProduitAppareil: src.sRefProduitAppareil,
            information: src.sInformation,
            codeAffectation: src.sCodeAffectation,
            derniereDateMouvement: src.derniereDateMouvement,
            suiviReglage: src.bSuiviReglage,
            modele: src.modele && this.modeleAdapter.toClient(src.modele),
            compteur: src.compteur && this.compteurAdapter.toClient(src.compteur),
            localisationActuelle: src.localisationActuelle &&
                this.localisationAdapter.toClient(src.localisationActuelle),
            dateAppairage: src.dAppairage,
            estPatientAnonyme: src.bPatientAnonyme,
            numeroECNPatient: src.sNumeroECNPatient
        };
    };
    AppareilAdapter.prototype.toAPI = function (src) {
        return {
            IDProduit: src.produitId,
            sRefProduit: src.refProduit,
            sFKTypeProduit: src.typeProduitFk,
            sCodeNatureProduit: src.codeNatureProduit,
            sLibelle: src.libelle,
            sRefFabricant: src.refFabricant,
            bInactif: src.inactif,
            bPrevisionInactif: src.previsionInactif,
            dArretCdeInterne: src.arretCdeInterne,
            bArretCommande: src.arretCommande,
            typeProduit: src.typeProduit && this.typeProduitAdapter.toAPI(src.typeProduit),
            famille: src.famille && this.familleAdapter.toAPI(src.famille),
            sNumeroSerie: src.numeroSerie,
            sRefProduitAppareil: src.refProduitAppareil,
            sInformation: src.information,
            sCodeAffectation: src.codeAffectation,
            derniereDateMouvement: src.derniereDateMouvement,
            bSuiviReglage: src.suiviReglage,
            modele: src.modele && this.modeleAdapter.toAPI(src.modele),
            compteur: src.modele && new CompteurAdapter().toAPI(src.compteur),
            localisationActuelle: src.localisationActuelle &&
                this.localisationAdapter.toAPI(src.localisationActuelle),
            dAppairage: src.dateAppairage,
            bPatientAnonyme: src.estPatientAnonyme,
            sNumeroECNPatient: src.numeroECNPatient
        };
    };
    return AppareilAdapter;
}(Adapter));
export { AppareilAdapter };
