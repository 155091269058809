import {
  ProgrammeEducatifAdapter,
  ProgrammeEducatifModel
} from "@ads/isadom-model";
import {
  getDefaultApiInstance,
  useApiDeleteV3
} from "@ads/react-common-module";
import { DataSourceDeleteResult } from "@ads/react-common-module/lib/esm/data-source/model/DataSourceDeleteResult";
import { apiIsadomErrorStrategy } from "@ads/ui";

export interface ApiDeleteProgrammeEducatifByVisiteIDAndIDHookInput {
  visiteID: number;
  peID: number;
}

export function useApiDeleteProgrammeEducatifByVisiteIDAndID(
  criteria?: ApiDeleteProgrammeEducatifByVisiteIDAndIDHookInput
): DataSourceDeleteResult<
  ProgrammeEducatifModel,
  Record<string, string | number>
> {
  const adapter = new ProgrammeEducatifAdapter();
  const uri = criteria
    ? `/visites/${criteria.visiteID}/programmes-educatifs/${criteria.peID}`
    : `/visites/:visiteID/programmes-educatifs/:peID`;
  return useApiDeleteV3(getDefaultApiInstance(), uri, {
    errorStrategy: apiIsadomErrorStrategy,
    adaptResult: (data) => {
      const result = adapter.toClient(data.data);
      return result;
    }
  });
}
