import { BaseParametreEditionListDataNotifier, IBaseParametreEditionListAdapterProps, IBaseParametreEditionListDataListener, IBaseParametreEditionListDataNotifier, IBaseParametreEditionListProps } from ".generated/list";
import { IParametreEditionModel } from "@ads/isadom-model";
import { IListDataAdapter } from "@ads/ui";
import { IParamatreEditionListHookResult } from "../hook/useParametreEditionList";
import { ParameteEditionListItemAdapter } from "../ui";

export class ParamatreEditionListHookResultAdapter implements IBaseParametreEditionListProps {
    adapter: IListDataAdapter<IParametreEditionModel, IBaseParametreEditionListDataListener, IBaseParametreEditionListAdapterProps>;
    
    listener?: IBaseParametreEditionListDataListener | undefined;
    
    notifier: IBaseParametreEditionListDataNotifier | null;
    
    data: IParametreEditionModel[];

    constructor(service: IParamatreEditionListHookResult, listenerID: string){
        this.adapter = ParameteEditionListItemAdapter
        this.notifier = new BaseParametreEditionListDataNotifier()
        this.listener = {
            idListener: listenerID,
            onItemChange(item) {
                service.modifyItem(item);
            },
            onDeleteItemClick(item){
                service.deleteItem(item)
            }
        } as IBaseParametreEditionListDataListener
        this.data = service.data
    }

}