import { useEffect } from "react";
import styled from "styled-components";
import { notification, Popconfirm } from "antd";
import { v4 } from "uuid";
import { DeleteButton } from "@ads/ui";
import useDeleteVisite from "hooks/useDeleteVisite";
import { useAppSelector, useAppDispatch } from "hooks/store";
import errorAdapter from "utils/errorAdapter";
import { getTournee } from "modules/feuille-de-route/store/redux/services/feuille-de-route.services";

const WrapperDeleteButton = styled.div`
  position: absolute;
  top: -1.8rem;
  right: -1.6rem;
  z-index: 60;
  cursor: pointer;

  circle {
    transition: 0.15s linear;
  }
  &:hover {
    circle {
      fill: ${({ theme }) => theme.colors.danger[100]};
    }
  }
`;

interface Props {
  visiteId: number;
}

const DeleteVisite = ({ visiteId }: Props) => {
  const dispatch = useAppDispatch();
  const { handleDelete, isSuccess, errors } = useDeleteVisite(visiteId);
  const { dDate, iPKIntervenant } = useAppSelector((state) => state.tournee);

  // Affichage des erreurs
  useEffect(() => {
    if (errors) {
      errorAdapter(errors)?.forEach((error: any) => {
        notification.error({
          key: v4(),
          message: "Erreur",
          description: error.message || "Erreur interne du serveur"
        });
      });
    }
  }, [errors]);

  // Affichage du message en cas de succès
  useEffect(() => {
    if (isSuccess) {
      notification.success({
        key: v4(),
        message: "Confirmation",
        description: "La visite a bien été supprimée."
      });

      dispatch(
        getTournee({
          dDate,
          iPKIntervenant: iPKIntervenant.toString(),
          selection: "en-cours"
        })
      );
    }
  }, [isSuccess]);

  return (
    <Popconfirm
      title="Souhaitez-vous supprimer cette visite ?"
      onConfirm={handleDelete}
      okText="Oui"
      cancelText="Non"
    >
      <WrapperDeleteButton>
        <DeleteButton />
      </WrapperDeleteButton>
    </Popconfirm>
  );
};

export default DeleteVisite;
