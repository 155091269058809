import { Loading } from "@ads/ui";
import { ILoadingsProps } from "../interfaces";

export const withLoading = <T extends ILoadingsProps>(Element: React.ComponentType<T>): React.FC<T> => (props: T) => {
  return (
    <>
      {props.loadings.readLoading &&
        <Loading>Chargement des données en cours</Loading>
      }
      {!props.loadings.readLoading && !props.loadings.writeLoading &&
        <Element {...props} />
      }
    </>
  )
}