import {
	AdsException,
	NotificationManager,
} from "components/core/errors/ErrorManager";
import { useEffect } from "react";

export const withError =
	<T extends Record<string, any>>(Element: React.ComponentType<T>): React.FC<T> =>
		(props: T) => {
			useEffect(() => {
				function displayError(exception: AdsException<any>) {
					if (exception.notification) {
						NotificationManager.getInstance().notify(exception.notification);
					}
				}

				window.addEventListener("unhandledrejection", (e) => {
					displayError(e.reason);
				});

				window.addEventListener("error", (e) => {
					displayError(e.error);
				});

				return () => {
					window.removeEventListener("unhandledrejection", (e) => {
						displayError(e.reason);
					});
					window.removeEventListener("error", (e) => {
						displayError(e.error);
					});
				};
			}, []);

			return <Element {...props} />;
		};
