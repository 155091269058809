import { FicheSuiviModel } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { apiPutFicheSuiviByVisiteID } from "../api/apiPutFicheSuiviByVisiteID";
import { adsNotification } from "@ads/ui";

export async function updateFicheSuiviByVisiteID(
  visiteID: number,
  fichesSuivis: FicheSuiviModel | null
): Promise<FicheSuiviModel | null> {
  if (fichesSuivis) {
    apiPutFicheSuiviByVisiteID(getDefaultApiInstance(), visiteID, fichesSuivis)
      .then((result) => {
        adsNotification.success({ message: "Fiche de Suivi sauvegardée" });
        return result.data;
      })
      .catch(() => {
        adsNotification.error({
          message: "Erreur lors de la sauvegarde de la fiche de suivi"
        });
        return null;
      });
  } else {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((_, reject) => {
    // do nothing.
  });
}
