import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import ScrollToTop from "../utils/ScrollToTop";
import PageConnexion from "../pages/utilisateur/connexion/PageConnexion";
import Page404 from "../pages/erreur/PageErreur";
import RouterFeuilleDeRoute from "./RouterFeuilleDeRoute";
import { RouterSaisieDeVisite } from "./RouterSaisieDeVisite";
import ProtectedRoute from "components/core/routes/ProtectedRoute";

const RouterApp = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Redirect exact from="/" to="/feuille-de-route/a-faire" />
        <Redirect
          exact
          from="/feuille-de-route"
          to="/feuille-de-route/a-faire"
        />
        <Redirect
          exact
          from="/visites/:iPKVisite"
          to="/visites/:iPKVisite/appareils/configuration"
        />
        <Route exact path="/utilisateur/connexion" component={PageConnexion} />
        <ProtectedRoute path="/visites/:iPKVisite/:theme">
          <RouterSaisieDeVisite />
        </ProtectedRoute>
        <RouterFeuilleDeRoute />
        <Route path="*" component={Page404} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
