var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../..";
import { CodePrescriptionAdapter } from ".";
var PrescriptionAdapter = /** @class */ (function (_super) {
    __extends(PrescriptionAdapter, _super);
    function PrescriptionAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.codePrescriptionAdapter = new CodePrescriptionAdapter();
        return _this;
    }
    PrescriptionAdapter.prototype.toClient = function (src) {
        return {
            ID: src.prescriptionID && src.prescriptionID,
            codePrescription: src.codePrescription &&
                this.codePrescriptionAdapter.toClient(src.codePrescription),
            dateDebut: src.dDebut && src.dDebut,
            dateFin: src.dFin && src.dFin,
            etat: src.sEtat && src.sEtat,
        };
    };
    PrescriptionAdapter.prototype.toAPI = function (src) {
        return {
            prescriptionID: src.ID && src.ID,
            codePrescription: src.codePrescription &&
                this.codePrescriptionAdapter.toAPI(src.codePrescription),
            dDebut: src.dateDebut && src.dateDebut,
            dFin: src.dateFin && src.dateFin,
            sEtat: src.etat && src.etat,
        };
    };
    return PrescriptionAdapter;
}(Adapter));
export { PrescriptionAdapter };
