var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter, AdresseAdapter, TiersAdapter } from "../..";
import { IntervenantAdapter } from "../../tiers/intervenant/adapter";
import { TypeVisiteAdapter } from ".";
var RendezVousAdapter = /** @class */ (function (_super) {
    __extends(RendezVousAdapter, _super);
    function RendezVousAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.adresseAdapter = new AdresseAdapter();
        _this.tiersAdapter = new TiersAdapter();
        _this.intervenantAdapter = new IntervenantAdapter();
        _this.typeVisiteAdapter = new TypeVisiteAdapter();
        return _this;
    }
    RendezVousAdapter.prototype.toClient = function (src) {
        return {
            ID: src.ID,
            adresse: this.adresseAdapter.toClient(src.adresse),
            tiersVisite: this.tiersAdapter.toClient(src.tiersVisite),
            intervenantPrincipal: this.intervenantAdapter.toClient(src.intervenantPrincipal),
            tIntervenantsAutre: this.intervenantAdapter.toClients(src.tIntervenantsAutre),
            tTypeVisite: this.typeVisiteAdapter.toClients(src.tTypeVisite),
            sInformation: src.sInformation,
            date: src.date,
            hDebut: src.hDebut,
            hFin: src.hFin,
        };
    };
    RendezVousAdapter.prototype.toAPI = function (src) {
        return {
            ID: src.ID,
            adresse: src.adresse && this.adresseAdapter.toAPI(src.adresse),
            tiersVisite: src.tiersVisite && this.tiersAdapter.toAPI(src.tiersVisite),
            intervenantPrincipal: src.intervenantPrincipal && this.intervenantAdapter.toAPI(src.intervenantPrincipal),
            tIntervenantsAutre: src.tIntervenantsAutre && this.intervenantAdapter.toAPIs(src.tIntervenantsAutre),
            tTypeVisite: src.tTypeVisite && this.typeVisiteAdapter.toAPIs(src.tTypeVisite),
            sInformation: src.sInformation,
            date: src.date,
            hDebut: src.hDebut,
            hFin: src.hFin,
        };
    };
    return RendezVousAdapter;
}(Adapter));
export { RendezVousAdapter };
