import { useEffect, useState } from "react";
import { Alert } from "antd";
import { Loading } from "@ads/ui";
import MobileHistorique from "components/ui/organisms/navigation-appareil/historique/MobileHistorique";
import DesktopHistorique from "components/ui/organisms/navigation-appareil/historique/DesktopHistorique";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";
import TitleMain from "components/ui/atoms/titles/TitleMain";
import useGetHistoriqueFromAPI from "api/historique";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";
import { useParams } from "react-router-dom";
import { RouteParam } from "interface/core/RouteParam";

const TemplateHistorique = () => {
  const { iPKVisite } = useParams<RouteParam>();
  const [historique, setHistorique] = useState<any>(undefined);
  const { data: visite } = useGetVisiteQuery(+iPKVisite);
  const iPKTiers = visite?.tiersVisite.iPKTiers;
  const dDate = visite?.date?.replaceAll("-", "");

  const {
    data,
    loading,
    error: errorHistorique
  } = useGetHistoriqueFromAPI({
    iPKTiers,
    dDate
  });

  useEffect(() => {
    if (data !== undefined) {
      setHistorique(data);
    }
  }, [data]);

  if (loading) {
    return (
      <>
        <Loading>Chargement en cours de l'historique...</Loading>
      </>
    );
  }

  if (errorHistorique) {
    return (
      <WrapperAlert>
        <Alert
          message="Error"
          showIcon
          description={
            errorHistorique !== ""
              ? errorHistorique
              : "Une erreur est survenue pendant le chargement de l'historique"
          }
          type="error"
        />
      </WrapperAlert>
    );
  }

  return (
    <>
      <TitleMain>Historique de la configuration des appareils</TitleMain>
      <MobileHistorique historique={historique} />
      <DesktopHistorique historique={historique} />
    </>
  );
};

export default TemplateHistorique;
