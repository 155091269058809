import { SelectSearchV2 } from "@ads/ui";
import { ISelectTypeVisiteTypePrestaByIntervenantHookService } from "../hook/interfaces/ISelectTypeVisiteTypePrestaByIntervenantHookService";

export interface SelectTypeVisiteTypePrestaProps {
  services: ISelectTypeVisiteTypePrestaByIntervenantHookService;
}

export function SelectTypeVisiteTypePresta({
  services,
}: SelectTypeVisiteTypePrestaProps) {
  return (
    <>
      <SelectSearchV2
        {...services.autoSearchTypeVisiteService}
        transformSearchResultsToSelectOptions={(typevisite) => {
          return typevisite.sLibelle;
        }}
        placeholder="Type de visite"
      />
      <br />
      <SelectSearchV2
        {...services.autoSearchTypePrestaService}
        transformSearchResultsToSelectOptions={(typevisite) => {
          return typevisite.libelle;
        }}
        placeholder="Types de prestations"
      />
    </>
  );
}
