import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { Select } from "antd";
import { SelectWrapper, FormItemWrapper, Label, ErrorFormMsg } from "@ads/ui";
import { motion } from "framer-motion";
import { MotifRetraitDMAPI, AppareilAPI } from "@ads/isadom-model";
import { RouteParam } from "interface/core/RouteParam";

import {
  useGetAppareilFromAPI,
  useGetMotifsRetraitAppareilFromAPI,
} from "api/appareil";
import { FormRetrait } from "../organisms/navigation-appareil/Retrait";

const { Option } = Select;

/**
 * Champ de formulaire select et label pour gérer les types de retrait.
 * @author Johan Petrikovsky
 * @date 2021-09-13
 */
const MotifsRetrait = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormRetrait>();
  const { IDProduit } = useParams<RouteParam>();
  const [appareil, setAppareil] = useState<AppareilAPI>();
  const [motifsRetrait, setMotifsRetrait] = useState<MotifRetraitDMAPI[]>([]);

  const { data: appareilData } = useGetAppareilFromAPI({
    appareilID: IDProduit,
  });

  useEffect(() => {
    if (appareilData) {
      setAppareil(appareilData);
    }
  }, [appareilData]);

  const { data } = useGetMotifsRetraitAppareilFromAPI({
    appareilID: appareil ? `${appareil.modele.IDProduit}` : undefined,
  });

  useEffect(() => {
    if (data) {
      setMotifsRetrait(data);
    }
  }, [data]);

  return (
    <FormItemWrapper direction="vertical">
      <Label htmlFor="type">Motif</Label>
      <SelectWrapper selectMode="light">
        <Controller
          control={control}
          name="motif.idMotif"
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Choisissez un motif de retrait"
              allowClear
            >
              {motifsRetrait.map((motif: MotifRetraitDMAPI) => (
                <Option
                  key={motif.iPKMotifRetraitDM}
                  value={motif.iPKMotifRetraitDM}
                >
                  {motif.sLibelle}
                </Option>
              ))}
            </Select>
          )}
        />
      </SelectWrapper>
      {errors.motif?.idMotif && errors.motif.idMotif.type === "required" && (
        <motion.div
          initial={{ x: -10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <ErrorFormMsg>Le motif de retrait est obligatoire</ErrorFormMsg>
        </motion.div>
      )}
    </FormItemWrapper>
  );
};

export default MotifsRetrait;
