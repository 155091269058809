import { TypeProgrammeEducatifAdapter } from "@ads/isadom-model";
import { getDefaultApiInstance, useApiGetV3 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export function useApiGetTypeProgrammeEducatifList() {
  const adapter = new TypeProgrammeEducatifAdapter();
  return useApiGetV3(getDefaultApiInstance(), "/programmes-educatifs/types", {
    errorStrategy: apiIsadomErrorStrategy,
    adaptResult: (data) => {
      const result = adapter.toClients(data.data);
      return result;
    }
  });
}
