import { useState } from "react";
import { useFormSelectionTypePrestationItem, } from "..";
import { SelectionProduitItemListener } from "../../../../produit/component/selection/hook/interface/impl/SelectionProduitItemListener";
import { useTableFormSelectionProduit } from "../../../../produit/component/selection/hook/useTableFormSelectionProduit";
const ID = "@ads/1/useSelectionConsommable";
export function useTableFormSelectionConsommable(input) {
    //Résultat du hook de sélection de Produit (nature/famille/sousFamille/typeproduit)
    const resultHookParent = useTableFormSelectionProduit(input);
    /****************************************/
    /**************LOCAL STATE***************/
    /****************************************/
    const initTypePrestationParams = new Array();
    //Tableau des familles sélectionnées
    for (const famille of input.familleConfig.defaultSelectedData) {
        initTypePrestationParams.push(famille.idFamille);
    }
    //Instanciation du hook avec les paramètres venant de l'input
    const typePrestationResult = useFormSelectionTypePrestationItem({
        config: input.typePrestationConfig,
        initParams: { familleIDs: initTypePrestationParams },
    });
    /**
     * State permettant de gérer le filtre sur  la référence ou le libelle
     * Valeur par défaut = pas de valeur ("")
     */
    const [refOrLibelle, setRefOrLibelle] = useState("");
    /**
     * Service permettant de gérer le formulaire de selection d'un produit
     * (Formulaire de recherche avancée d'un produit)
     */
    /****************************************/
    /*******************EFFECT***************/
    /****************************************/
    const listenerSelectionFamilles = new SelectionProduitItemListener(ID, //Instancation de l'id du listener
    (data) => {
        const familleDataIDs = new Array();
        for (const famille of data) {
            familleDataIDs.push(resultHookParent.familleSelectionService
                .getKeyFromData(famille)
                .toString());
        }
        //Mise à jour de l'input du type de prestation en fonction de la famille
        typePrestationResult.requestReloadData({ familleIDs: familleDataIDs });
    });
    resultHookParent.familleSelectionService.registerListener(listenerSelectionFamilles);
    /**
     * Si la modification du type de prestation est désactivé alors
     * je supprime le traitement permettant de modifier le type de prestation selectionée
     */
    if (!input.typePrestationConfig.allowChange) {
        typePrestationResult.notifyDataSelected = () => {
            throw new Error("Le type de prestation ne peut être modifié.");
        };
    }
    /****************************************/
    /**************INTERNAl FUNCION**********/
    /****************************************/
    return Object.assign(Object.assign({}, resultHookParent), { typePrestationSelectionService: typePrestationResult, refOrLibelle,
        setRefOrLibelle });
}
