import { EvenementStandardModel, VisiteModel } from "@ads/isadom-model";
import styled from "styled-components";
import { Breadcrumb, PrimaryButton, Icons } from "@ads/ui";

import MenuTab from "components/ui/molecules/MenuTab";

import {
  EvenementStandardInformationsGenerales,
  // EvenementStandardReleves,
} from "./";

const Wrapper = styled.div`
  margin-top: 2.5rem;
  position: relative;

  ${({ theme }) => theme.media.md} {
    background-color: ${({ theme }) => theme.colors.neutral[130]};
  }
`;

const ContentTab = styled("div")`
  position: relative;
  padding: 2.2rem 1.9rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  box-shadow: 0 8px 1rem rgb(80 70 70 / 25%);
  margin-bottom: 8.6rem;
  ${({ theme }) => theme.media.md} {
    position: static;
    padding: 4rem 6rem;
    margin-bottom: 0;
    box-shadow: 0 4px 1rem rgba(80, 70, 70, 0.25);
  }
`;

const EditButtonWrapper = styled.div`
  float: right;
`;
interface EvenementStandardViewProps {
  visite: VisiteModel;
  type: string;
  evenement: EvenementStandardModel;
}

export const EvenementStandardView = ({
  visite,
  type,
  evenement,
}: EvenementStandardViewProps) => {
  const breadcrumbsItems = [
    {
      item: "Evenements",
      to: `/visites/${visite.ID}/evenements/${type}`,
    },
    {
      item: `${evenement?.typeEvenement.libelle} le ${new Intl.DateTimeFormat(
        "fr-FR"
      ).format(new Date(evenement.dateDebut))}`,
      to: "",
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbsItems} size="M" />
      <Wrapper>
        <MenuTab
          tabs={[
            { id: "informations", label: "Informations générales", url: "" },
          ]}
          subTabActive={"informations"}
        />
        <ContentTab>
          <EditButtonWrapper>
            <PrimaryButton
              as="button"
              iconPosition="left"
              icon={Icons.InsertText}
              type="button"
              label="Editer"
              buttonStyle="primary"
              size="S"
            />
          </EditButtonWrapper>

          <EvenementStandardInformationsGenerales evenement={evenement} />
        </ContentTab>

        {/* <EvenementStandardReleves evenement={evenement} /> // TODO : v2  */}
      </Wrapper>
    </>
  );
};
