import { useFormSelectionItem, } from "../../../../common";
import { getTypeProduitsByNatures } from "../../../features/getTypeProduitsByNatures";
export function useFormSelectionTypeProduitItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.typeProduitId;
        },
        config: input.config,
        initParams: input.initParams,
        isDataMatchWithFilter(data, filter) {
            for (const nature of filter) {
                if (data.codeNatureProduit === nature.id) {
                    return true;
                }
            }
            return false;
        },
        onGetDataRequested: getTypeProduitsByNatures,
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
