import { getDefaultApiInstance } from "@ads/react-common-module";
import { useQuestionnaireDetailAutoFetch } from ".";
import { useGetQuestionnaireByIDAndVisiteID } from "../../..";
import { apiDeleteQuestionnaireFromVisite } from "../../../api/apiDeleteQuestionnaireFromVisite";
import { apiPutVisiteQuestionnaire } from "../../../api/apiPutVisiteQuestionnaire";
export function useQuestionnaireDetailVisite(visiteID, questionnaireID, disableActions) {
    return useQuestionnaireDetailAutoFetch({
        dataFetchFunction: useGetQuestionnaireByIDAndVisiteID,
        fetchParams: {
            visiteID: visiteID,
            questionnaireID: questionnaireID,
        },
        performDelete: (questionnaireID) => apiDeleteQuestionnaireFromVisite(getDefaultApiInstance(), visiteID, questionnaireID),
        performUpdate: (questionnaire) => apiPutVisiteQuestionnaire(getDefaultApiInstance(), visiteID, questionnaire),
        disableActions: disableActions,
    });
}
