import { apiGet } from "@ads/react-common-module";
import { HttpResponseAdapter } from "@ads/isadom-model";
import { DocumentVisiteAdapter } from "..";
export function apiGetDocumentsByVisiteID(api, visiteID) {
    if (api) {
        const adapter = new HttpResponseAdapter(new DocumentVisiteAdapter());
        return apiGet(api, `/visites/${visiteID}/documents`, adapter);
    }
    else {
        throw new Error("La configuration d'api est null");
    }
}
