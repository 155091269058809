import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import styled from "styled-components";

interface Props {
  errors: FetchBaseQueryError | SerializedError | undefined;
}

const Error = styled.p`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.danger[100]};
`;

const ErrorAppareil = ({ errors }: Props) => {
  if (errors && "status" in errors) {
    const errorsBack: any = errors;

    return errorsBack.data.messages.map((error: any) => (
      <Error>{error.message}</Error>
    ));
  }
  return <></>;
};

export default ErrorAppareil;
