import { HEADER_HEIGHT } from "./Header-v2";
import { HeaderTemplate } from "./HeaderTemplate";

export interface HeaderPageUpsertVisiteProps {

}

export function HeaderPageUpsertVisite({}: HeaderPageUpsertVisiteProps) {
  const size = HEADER_HEIGHT;
  return (
    
    <>
      <HeaderTemplate size={size} />
    </>
  );
}
