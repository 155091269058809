var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter/Adapter";
var TypeLocalisationAdapter = /** @class */ (function (_super) {
    __extends(TypeLocalisationAdapter, _super);
    function TypeLocalisationAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TypeLocalisationAdapter.prototype.toClient = function (src) {
        if (src) {
            return {
                typeLocalisationID: src.typeLocalisationId,
                interne: src.bInterne,
                libelle: src.sLibelle,
            };
        }
        else {
            return {};
        }
    };
    TypeLocalisationAdapter.prototype.toAPI = function (src) {
        return {
            typeLocalisationId: src.typeLocalisationID,
            bInterne: src.interne,
            sLibelle: src.libelle
        };
    };
    return TypeLocalisationAdapter;
}(Adapter));
export { TypeLocalisationAdapter };
