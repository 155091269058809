import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
export function createAdsStore(staticReducers) {
    // Create a store with the root reducer function being the one exposed by the manager.
    const tempStore = configureStore({
        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware();
        },
        reducer: Object.assign({}, staticReducers),
    });
    const injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        const reducers = Object.assign(Object.assign({}, staticReducers), store.asyncReducers);
        store.replaceReducer(combineReducers(Object.assign({}, reducers)));
    };
    const store = Object.assign(Object.assign({}, tempStore), { injectReducer: injectReducer, asyncReducers: {} });
    return store;
}
