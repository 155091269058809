import { FC, useState } from "react";
import { Checkbox, Popconfirm } from "antd";
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";

interface Props {
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
}

const TitlePopup = () => <>Souhaitez-vous poursuivre ?</>;

/**
 * Checkbox théorique spécifique de l’appareil, au clic affiche une popup si des données sont déjà présentes (booléen hasData à true) dans les inputs de la colonne théorique.
 * Si la popup est confirmée, mise à jour du champ isTheorique
 * @author Johan Petrikovsky
 * @date 2021-10-12
 * @param stateFormGrilleReglage -  State du formulaire
 * @param setStateFormGrilleReglage - Mise à joour du state du formulaire
 * @param indexGrille - Grille courante
 * @returns {JSX}
 */
const CheckboxTheorique: FC<Props> = ({
  modesReglagesAppareil,
  setModesReglagesAppareil,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const [isVisible, setIsVisible] = useState(false);

  /**
   * @desc Mise à jour du booléen bTheoriqueAppareil
   */
  const setTheoriqueAppareil = () => {
    const newReglageAppareil: ModesReglagesAppareil = {
      ...modesReglagesAppareil,
      modesSaisis: modesReglagesAppareil.modesSaisis.map((modeSaisi) => {
        if (modeSaisi.reglageModeID === modesReglagesAppareil.modeActif) {
          return {
            ...modeSaisi,
            tabGrille: modeSaisi.tabGrille.map((grille, indexGrille) => {
              if (indexGrille === modesReglagesAppareil.grilleActive) {
                return {
                  ...grille,
                  tabReglage: grille.tabReglage.map((reglage) => {
                    return {
                      ...reglage,
                      bTheoriqueAppareil: !reglage.bTheoriqueAppareil,
                    };
                  }),
                };
              }
              return grille;
            }),
          };
        }
        return modeSaisi;
      }),
    };
    setModesReglagesAppareil(newReglageAppareil);
  };

  const handleConfirm = () => {
    setIsChecked(!isChecked);
    setIsVisible(false);
    setTheoriqueAppareil();
  };

  const handleCancel = () => {
    setIsChecked(false);
    setIsVisible(false);
  };

  const handleClick = () => {
    setIsVisible(true);
  };

  return (
    <>
      <Popconfirm
        title={TitlePopup}
        visible={isVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Oui"
        cancelText="Non"
      >
        <Checkbox
          onClick={handleClick}
          defaultChecked={isChecked}
          checked={isChecked}
        >
          Théorique spécifique de l’appareil
        </Checkbox>
      </Popconfirm>
    </>
  );
};

export default CheckboxTheorique;
