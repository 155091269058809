import { jsx as _jsx } from "react/jsx-runtime";
//import { SelectWrapper } from "@ads/ui";
import { Select } from "antd";
import { FiltreWrapper } from "@ads/ui";
export const FiltreListeConfigurationConsommables = ({ configurationsConsommable, setFiltreConfiguration, }) => {
    const typesPrestations = [];
    const typesPrestationsOptions = [];
    configurationsConsommable.forEach((configConsoSpecTierVisite) => {
        if (configConsoSpecTierVisite.typePrestation) {
            const value = configConsoSpecTierVisite.typePrestation.id;
            if (!typesPrestationsOptions.find((option) => option.value === value)) {
                typesPrestations.push(configConsoSpecTierVisite.typePrestation);
                typesPrestationsOptions.push({
                    value,
                    label: value,
                });
            }
        }
    });
    const handleChange = (idTypePrestation) => {
        // Reset du filtre
        if (typeof idTypePrestation === "undefined") {
            const filtre = {
                idTypePrestation: "",
            };
            setFiltreConfiguration(filtre);
        }
        // Construction du filtre
        else {
            const filtre = {
                idTypePrestation,
            };
            setFiltreConfiguration(filtre);
        }
    };
    return (_jsx(FiltreWrapper, Object.assign({ libelle: "Filtrer par prestation" }, { children: _jsx(Select, { optionFilterProp: "children", options: typesPrestationsOptions, showSearch: true, allowClear: true, onChange: handleChange, disabled: (configurationsConsommable === null || configurationsConsommable === void 0 ? void 0 : configurationsConsommable.length) === 0 }, void 0) }), void 0));
};
