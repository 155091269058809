import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { TacheItemAdapter } from "./TacheItemAdapter";
import { EtatRealisation } from "@ads/isadom-model";
import { useState, useEffect } from "react";
const TypeVisiteTitle = styled.div `
  margin: 2rem 2rem 0;
  font-size: 2rem;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  width: auto;
  color: ${(props) => props.theme.colors.primary[100]};
`;
const TypeVisiteContainer = styled.div ``;
export function ChecklistListItemAdapter({ data, notifier, }) {
    const stateNullMotifNR = {
        iPKParametre: 0,
        sTable: "",
        sCode: "",
        sLibelle: "",
    };
    const [checklistState, setChecklistState] = useState(data);
    const handleTacheOK = (tacheID, checked) => {
        if (notifier === null || notifier === void 0 ? void 0 : notifier.notifyItemChange) {
            for (let i = 0; i < checklistState.taches.length; i++) {
                const task = checklistState.taches[i];
                if (task.id === tacheID) {
                    if (checked) {
                        task.etatRealisation = EtatRealisation.REALISE;
                        task.motifNonRealisation = stateNullMotifNR;
                    }
                    else {
                        task.etatRealisation = EtatRealisation.NON_RENSEIGNE;
                        task.motifNonRealisation = stateNullMotifNR;
                    }
                    checklistState.taches[i] = task;
                    notifier === null || notifier === void 0 ? void 0 : notifier.notifyItemChange(checklistState);
                }
            }
        }
    };
    const handleTacheNR = (tacheID, checked) => {
        if (notifier === null || notifier === void 0 ? void 0 : notifier.notifyItemChange) {
            for (let i = 0; i < checklistState.taches.length; i++) {
                const task = checklistState.taches[i];
                if (task.id === tacheID) {
                    if (checked) {
                        task.etatRealisation = EtatRealisation.NON_REALISE;
                    }
                    else {
                        task.etatRealisation = EtatRealisation.NON_RENSEIGNE;
                        task.motifNonRealisation = stateNullMotifNR;
                    }
                    checklistState.taches[i] = task;
                    notifier === null || notifier === void 0 ? void 0 : notifier.notifyItemChange(checklistState);
                }
            }
        }
    };
    const handleTextNRUpdate = (key, value) => {
        if (notifier === null || notifier === void 0 ? void 0 : notifier.notifyItemChange) {
            const task = checklistState.taches[key];
            task.information = value;
            checklistState.taches[key] = task;
            notifier === null || notifier === void 0 ? void 0 : notifier.notifyItemChange(checklistState);
        }
    };
    const handleNRType = (key, value) => {
        let task = checklistState.taches[key];
        data.nonRealisationList.forEach((e) => {
            if (e.sCode === value &&
                checklistState &&
                notifier &&
                notifier.notifyItemChange) {
                task.motifNonRealisation = e;
                checklistState.taches[key] = task;
                notifier.notifyItemChange(checklistState);
            }
        });
    };
    useEffect(() => {
        setChecklistState(data);
    }, []);
    return (_jsxs(TypeVisiteContainer, { children: [_jsx(TypeVisiteTitle, { children: checklistState.libelle }, void 0), checklistState.taches.map((value, _key) => (_jsx(TacheItemAdapter, { value: value, notifyNR: (checked) => handleTacheNR(value.id, checked), notifyOK: (checked) => handleTacheOK(value.id, checked), notifyTextUpdate: (text) => handleTextNRUpdate(_key, text), notifyNRJustif: (text) => handleNRType(_key, text), typesNR: data.nonRealisationList }, _key)))] }, void 0));
}
