import { LigneCommandeVisiteModel } from "@ads/isadom-model";
import { FormItemWrapper, CheckboxV3 } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1rem;

  .form-item {
    margin-bottom: 0;
  }
  .label {
    margin-bottom: 0;
  }
`;

interface CommandeItemCheckboxProps {
  item: LigneCommandeVisiteModel;
}

const CommandeItemIndependante = ({ item }: CommandeItemCheckboxProps) => {
  const { setIndependante } = useFormulaireCommandesStore();
  return (
    <Wrapper>
      <FormItemWrapper direction="vertical">
        <CheckboxV3
          checked={item.independante}
          onChange={(evt: any) => {
            setIndependante(item.ID, evt.target.checked);
          }}
        >
          Commande indépendante
        </CheckboxV3>
      </FormItemWrapper>
    </Wrapper>
  );
};

export default CommandeItemIndependante;
