//const TAG = "AbstractNotifier"
export class AbstractNotifier {
    constructor() {
        this.listenerMap = new Map();
    }
    registerListener(listener) {
        if (listener.idListener !== undefined && listener.idListener !== null) {
            this.listenerMap.set(listener.idListener, listener);
        }
        else {
            throw new Error("L'id du listener n'est pas initialisé");
        }
    }
    unregisterListener(id) {
        try {
            this.listenerMap.delete(id);
            return true;
        }
        catch (err) {
            console.error(err);
            return false;
        }
    }
    getListenerOrderByKeys() {
        let listenerOrdered = new Array();
        let keysArrays = Array.from(this.listenerMap.keys()).sort();
        for (const key of keysArrays) {
            const data = this.listenerMap.get(key);
            listenerOrdered.push(data);
        }
        return listenerOrdered;
    }
}
