import { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Alert } from "antd";
import { Loading } from "@ads/ui";
import { RouteParam } from "../../interface/core/RouteParam";
import TemplateFeuilleDeRoute from "./ui/templates/TemplateFeuilleDeRoute";
import { TypeRendezVous } from "interface/typeRendezVous";
import { selectTypeRdv } from "./store/redux/features/feuille-de-route.slice";
import { useAppSelector, useAppDispatch } from "./hooks/store";
import { ListeRdv } from "./interfaces/feuille-de-route.interfaces";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";

const FeuilleDeRouteModule = () => {
  const dispatch = useAppDispatch();
  const { typeRdv } = useParams<RouteParam>();
  const { identite } = useAppSelector((state: any) => state.auth);
  const { tournee } = useAppSelector((state: any) => state);
  const { hasError, isLoading, dDate, iPKIntervenant } = useAppSelector(
    (state: any) => state.tournee
  );

  const listesRdv: ListeRdv = {
    "a-faire": tournee?.tRdvAFaire || [],
    "en-cours": tournee?.tVisiteEnCours || [],
    effectue: tournee?.tVisiteTerminee || []
  } as unknown as ListeRdv;

  const intervenantId =
    iPKIntervenant === -1 || iPKIntervenant === 0
      ? identite.iFKAnnuaire
      : iPKIntervenant;
  const selection: TypeRendezVous = typeRdv || "a-faire";
  const date = moment(dDate).format("YYYYMMDD");

  useEffect(() => {
    dispatch(selectTypeRdv({ selection }));
  }, [selection, dispatch]);

  if (isLoading) {
    return (
      <>
        <Loading>Chargement des rendez-vous en cours.</Loading>
      </>
    );
  }

  if (hasError) {
    return (
      <WrapperAlert>
        <Alert
          message="Erreur"
          showIcon
          description="Une erreur est survenue pendant le chargement des rendez-vous. Veuillez contactez un administrateur."
          type="error"
        />
      </WrapperAlert>
    );
  }

  return selection !== "ajouter-rdv" ? (
    <TemplateFeuilleDeRoute
      intervenantID={intervenantId}
      date={date}
      selection={selection}
      items={listesRdv[selection]}
    />
  ) : (
    <></>
  );
};

export default FeuilleDeRouteModule;
