import { ProgrammeEducatifFormAdapter } from "../common/ProgrammeEducatifFormAdapter";
import {
  Form,
} from "@ads/react-common-module";
import moment from "moment";
import { useFormPE } from "modules/suivi-patient/programme-educatif/hooks/useFormPE";

export interface ProgrammeEducatifFormVisiteTemplateProps {
  visiteID: number;
}

export function ProgrammeEducatifCreateFormVisiteTemplate({
  visiteID
}: ProgrammeEducatifFormVisiteTemplateProps) {
  
  const services = useFormPE({
    visiteID,
    defaultFormData: {
      id: undefined,
      nautID: undefined,
      dDebut: moment().format("YYYY-MM-DD"),
      dFin: moment().format("YYYY-MM-DD"),
      etape: {
        iPKParametre: 0,
        sTable: "",
        sCode: "",
        sLibelle: ""
      },
      typeProgrammeEducatif: {
        etpID: 0,
        type: "",
        libelle: "",
        texteParDefaut: ""
      }
    }
  });

  return (
    <Form
      services={services}
      formAdapter={ProgrammeEducatifFormAdapter}
      withSaveLoading={null}
      withFetchLoading={null}
      withDeleteLoading={null}
    />
  );
}
