import { EvenementTelesuiviModel } from "@ads/isadom-model";
import { Detail, Details, Nom, Valeur } from "../../atoms/viewFields";

interface EvenementTelesuiviDetailsProps {
  evenement?: EvenementTelesuiviModel;
}

const objDate = new Intl.DateTimeFormat("fr-FR");

export const EvenementTelesuiviDetails = ({
  evenement,
}: EvenementTelesuiviDetailsProps) => (
  <Details>
    <Detail>
      <Nom>Type d'évènement</Nom>
      <Valeur>{evenement?.typeEvenement.libelle}</Valeur>
    </Detail>
    <Detail>
      <Nom>Date de début</Nom>
      <Valeur>
        {evenement &&
          evenement.dateDebut !== "0000-00-00" &&
          objDate.format(new Date(evenement.dateDebut))}
      </Valeur>
    </Detail>
    <Detail>
      <Nom>Date de fin</Nom>
      <Valeur>
        {evenement?.dateFin &&
          evenement.dateFin !== "0000-00-00" &&
          objDate.format(new Date(evenement.dateFin))}
      </Valeur>
    </Detail>
    <Detail>
      <Nom>Accord Patient</Nom>
      <Valeur>{evenement?.estDecision ? "Oui" : "Non"}</Valeur>
    </Detail>
    <Detail>
      <Nom>Accord patient le</Nom>
      <Valeur>
        {evenement?.dateAccord && evenement.dateAccord !== "0000-00-00"
          && objDate.format(new Date(evenement.dateAccord))}
      </Valeur>
    </Detail>
    <Detail>
      <Nom>Motif du refus</Nom>
      <Valeur>{evenement?.motifRefus}</Valeur>
    </Detail>
  </Details>
);
