import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Radio, Space } from "antd";
import { Card } from "../atoms/Card";
function getReponseFromQuestion(reponseLibelle, question) {
    for (const response of question.reponsesPossibles) {
        if (response.libelle === reponseLibelle) {
            return response;
        }
    }
    return null;
}
export function QuestionMolecule(props) {
    const { question, listener, disableActions } = props;
    const onChange = (event) => {
        const selectedResponse = getReponseFromQuestion(event.target.value, question);
        if (selectedResponse) {
            question.reponseSaisie = selectedResponse;
            listener.onQuestionChanged(question);
        }
        else {
            throw new Error("La réponse choisit ne correspond à aucune réponses possibles de la question");
        }
    };
    return (_jsxs(Card, Object.assign({ style: { cursor: "auto" } }, { children: [_jsx("h4", { children: _jsxs("b", { children: [question.libelle, " : "] }, void 0) }, void 0), _jsx("div", Object.assign({ style: { marginTop: "1.5rem" } }, { children: disableActions ? (_jsx("h4", { children: question.reponseSaisie.libelle !== ""
                        ? question.reponseSaisie.libelle
                        : "Aucune réponse saisie" }, void 0)) : (_jsx(Radio.Group, Object.assign({ onChange: onChange, value: question.reponseSaisie.libelle }, { children: _jsx(Space, Object.assign({ direction: "vertical" }, { children: question.reponsesPossibles.map((value) => (_jsx(Radio, Object.assign({ value: value.libelle }, { children: value.libelle }), value.reponseID))) }), void 0) }), void 0)) }), void 0)] }), void 0));
}
