import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { NavItem } from "components/ui/templates/TemplateSaisieVisiteOLD";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";

function NavigationAppareil() {
  const { tabActive, iPKVisite } = useParams<RouteParam>();
  const navItems: Array<NavItem> = [
    {
      id: "configuration",
      to: `/visites/${iPKVisite}/appareils/configuration`,
      label: "Configuration",
      active: tabActive === "" || tabActive === "configuration",
      visible: true
    },
    {
      id: "historique",
      to: `/visites/${iPKVisite}/appareils/historique`,
      label: "Historique",
      active: tabActive === "historique",
      visible: true
    },
    {
      id: "observances",
      to: `/visites/${iPKVisite}/appareils/observances`,
      label: "Observances",
      active: tabActive === "observances",
      visible: true
    }
  ];

  return (
    <NavigationPrimary
      navItems={navItems || []}
      isOpen={false}
      isLargeNav={false}
    />
  );
}

export default NavigationAppareil;
