/* eslint-disable react-hooks/exhaustive-deps */
import {
  AdresseModel,
  IntervenantModel,
  RendezVousModel,
  TiersModel,
  TypePrestationModel,
  TypeVisiteModel,
  VisiteModel
} from "@ads/isadom-model";
import { ETypeTiersID, useApiGetIntervenantByID } from "@ads/module-tiers";
import { useEffect, useState } from "react";
import { useInfoRdvVisite } from "../../InforRdvVisite/hook/useInfoRdvVisite";
import moment, { Moment } from "moment";

import {
  filterForPrelevementDM,
  filterForPrelevementDMC,
  useSelectLocalisationByIntervenantVisite
} from "../../SelectLocalisation/hook/useSelectLocalisationByIntervenantVisite";
import { useSelectTiersVisite } from "../../SelectTiersVisite/hook/useSelectTiersVisite";
import { useSelectTypeVisiteTypePrestaVisite } from "../../SelectTypeVisiteTypePrestaVisite/hook/useSelectTypeVisiteTypePrestaVisite";
import { IFormCreerVisiteHookInput } from "./interfaces/IFormCreerVisiteHookInput";
import { IFormCreerVisiteHookService } from "./interfaces/IFormCreerVisiteHookService";
import { useApiPostVisite } from "modules/feuille-de-route/hooks/useApiPostVisite";
import { useSelectAdresseByTiers } from "../../SelectAdresseByTiers/hook/useSelectAdresseByTiers";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "hooks/store";
import { deleteRdvFromTournee } from "modules/feuille-de-route/store/redux/features/feuille-de-route.slice";

export function useFormCreerVisite({
  rdv
}: IFormCreerVisiteHookInput): IFormCreerVisiteHookService {
  const [initialRdv, setInitialRdv] = useState<
    RendezVousModel | null | undefined
  >(rdv);
  const [tiersVisite, setTiersVisite] = useState(rdv ? rdv.tiersVisite : null);

  const [dDebut, setDDebut] = useState<Moment>(
    rdv ? moment(rdv.date) : moment()
  );
  const [hDebut, setHDebut] = useState<Moment>(
    rdv ? moment(rdv.hDebut, "HH:mm:ss.000") : moment()
  );

  const [informations, setInformations] = useState<string>(
    rdv ? rdv.sInformation : ""
  );
  const [intervenantPrincipalID, setIntervenantPrincipalID] = useState(-1);
  const [intervenantPrincipal, setIntervenantPrincipal] =
    useState<IntervenantModel>();

  const {
    data: visiteCreated,
    loading: visiteCreating,
    post: postVisite
  } = useApiPostVisite();

  // const {
  //   data: intervenantPrincipalFromApi,
  //   fetch: fetchIntervenantPrincipal
  // } = useApiGetIntervenantByID(intervenantPrincipalID);

  const {
    intervenant: intervenantPrincipalFromApi,
    fetchIntervenant: fetchIntervenantPrincipal
  } = useApiGetIntervenantByID(intervenantPrincipalID);

  /**
   * Type visite / Type presta
   */
  const serviceTypeVisiteTypePresta = useSelectTypeVisiteTypePrestaVisite({
    intervenantID: null,
    defaultTypeVisiteList: rdv ? rdv.tTypeVisite : []
  });

  const serviceLocalisationPrelevementDM =
    useSelectLocalisationByIntervenantVisite({
      defaultIntervenant: null,
      isDefault: filterForPrelevementDM
    });

  const serviceLocalisationPrelevementDMC =
    useSelectLocalisationByIntervenantVisite({
      defaultIntervenant: null,
      isDefault: filterForPrelevementDMC
    });

  const serviceInfoRdv = useInfoRdvVisite({
    rdvOrVisite: undefined
  });

  const serviceSelectTiersVisite = useSelectTiersVisite({
    onTiersSelected(tiers) {
      setTiersVisite(tiers);
    },
    defaultTypeTiersID: ETypeTiersID.PATIENT
  });

  const serviceSelectIntervenantPrincipal = useSelectTiersVisite({
    onTiersSelected(tiers) {
      if (tiers) {
        setIntervenantPrincipalID(tiers.tiersID);
      } else {
        setIntervenantPrincipalID(-1);
        setIntervenantPrincipal(undefined);
      }
    },
    defaultTypeTiersID: ETypeTiersID.INTERVENANT
  });

  const serviceSelectIntervenantSecondaire = useSelectTiersVisite({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTiersSelected(tiers) {},
    defaultTypeTiersID: ETypeTiersID.INTERVENANT
  });

  useEffect(() => {
    if (intervenantPrincipalID && intervenantPrincipalID !== -1) {
      fetchIntervenantPrincipal(intervenantPrincipalID);
    }
  }, [intervenantPrincipalID]);

  useEffect(() => {
    if (
      intervenantPrincipalFromApi &&
      intervenantPrincipalFromApi.tiersID !== -1
    ) {
      setIntervenantPrincipal(intervenantPrincipalFromApi);
    }
  }, [intervenantPrincipalFromApi]);

  useEffect(() => {
    serviceAdresse.notifyTiersChanged(tiersVisite);
  }, [tiersVisite]);

  useEffect(() => {
    if (intervenantPrincipal) {
      const previousIntervenant =
        serviceSelectIntervenantPrincipal.serviceSearchSelectTiers
          ?.selectService.selectedData[0];
      //Dans le cas ou l'intervenant principal vient du rendez vous
      //cela à été fait pour ne pas refetch l'intervenant que nous avons déjà dans le rdv
      if (
        !previousIntervenant ||
        intervenantPrincipal.tiersID !== previousIntervenant?.tiersID
      ) {
        serviceSelectIntervenantPrincipal.serviceSearchSelectTiers?.selectService.notifyDataSelectedWithoutControl(
          intervenantPrincipal
        );
      }

      //Traitement classique
      serviceTypeVisiteTypePresta.serviceSelectionTypeVisiteTypePresta.notifyIntervenantIDChanged(
        intervenantPrincipal.tiersID
      );

      serviceLocalisationPrelevementDM.notifyIntervenantChanged(
        intervenantPrincipal
      );
      const locPardefaultDM =
        intervenantPrincipal.localisationAutorisees.filter(
          filterForPrelevementDM
        );

      if (locPardefaultDM && locPardefaultDM.length === 1) {
        serviceLocalisationPrelevementDM.notifyDataSelectedWithoutControl(
          locPardefaultDM[0]
        );
      } else {
        serviceLocalisationPrelevementDM.notifyDataSelectedReset();
      }

      serviceLocalisationPrelevementDMC.notifyIntervenantChanged(
        intervenantPrincipal
      );

      const locPardefaultDMC =
        intervenantPrincipal.localisationAutorisees.filter(
          filterForPrelevementDMC
        );

      if (locPardefaultDMC && locPardefaultDMC.length === 1) {
        serviceLocalisationPrelevementDMC.notifyDataSelectedWithoutControl(
          locPardefaultDMC[0]
        );
      } else {
        serviceLocalisationPrelevementDMC.notifyDataSelectedReset();
      }
    }
  }, [intervenantPrincipal]);

  function handleRdvChange(rdv: RendezVousModel | null) {
    setInitialRdv(rdv);

    if (rdv && rdv.ID !== 0) {
      if (rdv.tiersVisite.tiersID !== 0) {
        serviceSelectTiersVisite.serviceSearchSelectTiers?.selectService.notifyDataSelectedWithoutControl(
          rdv.tiersVisite
        );
      }
      serviceInfoRdv.notifyRdvOrVisiteChanged(rdv);

      if (rdv.intervenantPrincipal.tiersID !== 0) {
        setIntervenantPrincipal(rdv.intervenantPrincipal);
      }

      setInformations(rdv.sInformation);
      notifyDateDebutChange(moment(rdv.date));
      notifyHeureDebutChange(moment(rdv.hDebut, "HH:mm:ss.000"));
      /*serviceSelectIntervenantSecondaire.serviceSearchSelectTiers.selectService.notifyDataSelectedAllWithoutControl(
        rdv.tIntervenantsAutre
      );*/
    }
  }

  function notifyDateDebutChange(dDebut: Moment) {
    setDDebut(dDebut);
  }

  function notifyHeureDebutChange(hDebut: Moment) {
    setHDebut(hDebut);
  }

  function notifyDateHeureDebutChange(dhDebut: Moment) {
    setDDebut(dhDebut);
    setHDebut(dhDebut);
  }

  function handleCreate() {
    const visite: VisiteModel = {
      sInformation: informations,
      tTypeVisite:
        serviceTypeVisiteTypePresta.serviceTypeVisiteTypePrestaList.typesVisites.map(
          clearDataToTypeVisite
        ),
      intervenantPrincipal: {
        tiersID: intervenantPrincipal?.tiersID
          ? intervenantPrincipal.tiersID
          : -1
      } as IntervenantModel,
      tIntervenantsAutre:
        serviceSelectIntervenantSecondaire.serviceSearchSelectTiers.selectService.selectedData.map(
          clearDataToTiers
        ),
      Rdv: {
        ID: initialRdv?.ID ? initialRdv?.ID : null
      } as RendezVousModel,
      date: moment(dDebut).format("YYYY-MM-DD"),
      hDebut: moment(hDebut).format("HH:mm:00.000"),
      tiersVisite: {
        tiersID: tiersVisite?.tiersID
      } as TiersModel,
      adresse: {
        adresseID:
          serviceAdresse.selectionAdresseServices.selectedData.length > 0
            ? serviceAdresse.selectionAdresseServices.selectedData[0].adresseID
            : null,
        typeLocalisation: {
          typeLocalisationID:
            serviceAdresse.selectionAdresseServices.selectedData.length > 0
              ? serviceAdresse.selectionAdresseServices.selectedData[0]
                  .typeLocalisation.typeLocalisationID
              : null
        }
      } as AdresseModel
    } as VisiteModel;

    postVisite(visite);
  }

  const serviceAdresse = useSelectAdresseByTiers({
    tiers: tiersVisite
  });

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (visiteCreated) {
      if (visiteCreated.Rdv.ID !== 0) {
        dispatch(deleteRdvFromTournee(visiteCreated.Rdv.ID));
      }
      history.push(`/visites/${visiteCreated?.ID}`);
    }
  }, [visiteCreated]);

  return {
    tiersVisite,
    visiteCreating,
    dDebut,
    hDebut,
    informations,
    notifyInformationsChanged: setInformations,
    hasIntervenantPrincipal: intervenantPrincipal ? true : false,
    serviceTypeVisiteTypePresta,
    serviceLocalisationPrelevementDM,
    serviceLocalisationPrelevementDMC,
    serviceInfoRdv,
    serviceSelectIntervenantPrincipal,
    serviceSelectIntervenantSecondaire,
    serviceSelectTiersVisite,
    notifyInitialRdvChange: handleRdvChange,
    notifyDateDebutChange,
    notifyDateHeureDebutChange,
    notifyHeureDebutChange,
    createVisite: handleCreate,
    serviceSelectionAdresseByTiers: serviceAdresse
  };
}

function clearDataToTypeVisite(typevisite: TypeVisiteModel): TypeVisiteModel {
  return {
    sPKTypeVisite: typevisite.sPKTypeVisite,
    tTypePrestation: typevisite.tTypePrestation.map(clearDataToTypePresta)
  } as TypeVisiteModel;
}

function clearDataToTypePresta(
  typepresta: TypePrestationModel
): TypePrestationModel {
  return {
    id: typepresta.id
  } as TypePrestationModel;
}

function clearDataToTiers(tiers: TiersModel): TiersModel {
  return {
    tiersID: tiers.tiersID
  } as TiersModel;
}
