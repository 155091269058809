import { HttpResponseAPI, HttpResponseModel } from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

import { Ville } from "../store";

export async function apiGetVilles(): Promise<HttpResponseModel<Ville[]>> {
  try {
    const api = getDefaultApiInstance();

    if (!api) {
      throw new Error("La configuration d'api n'est pas initialisée");
    }

    const response: HttpResponseAPI<Ville[]> = await apiGetV2(
      api,
      `/villes/codepostal`,
      apiIsadomErrorStrategy
    );

    return response;
  } catch (err: any) {
    return new Promise((_resolve, reject) => {
      reject(err.message);
    });
  }
}
