import styled from "styled-components";

const ContentTab = styled("div")`
    position: relative;
    padding: 2.2rem 1.9rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background: ${(props) => props.theme.colors.neutral[100]};
    box-shadow: 0 8px 1rem rgb(80 70 70 / 25%);
    margin-bottom: 8.6rem;

    ${({ theme }) => theme.media.md} {
    position: static;
    padding: 4rem 6rem;
    margin-bottom: 0;
    box-shadow: 0 4px 1rem rgba(80, 70, 70, 0.25);
    }
`;

export default ContentTab;
