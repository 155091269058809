var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { TypeVisiteAdapter } from "./TypeVisiteAdapter";
var UpdateVisiteAdapter = /** @class */ (function (_super) {
    __extends(UpdateVisiteAdapter, _super);
    function UpdateVisiteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeVisiteAdapter = new TypeVisiteAdapter();
        return _this;
    }
    UpdateVisiteAdapter.prototype.toClient = function (src) {
        return {
            tiersVisite: {
                tiersID: src.tiersVisite.iPKTiers,
            },
            intervenantPrincipal: {
                intervenantId: src.intervenantPrincipal.iPKTiers,
            },
            typeVisites: this.typeVisiteAdapter.toClients(src.tTypeVisite),
            adresse: {
                adresseId: src.adresse.iPKAdresse,
            },
            date: src.date,
            hDebut: src.hDebut,
            natureLocalisationPreleveDMC: src.sNatureLocalisationPreleveDMC,
            localisationPreleveDM: src.iFKLocalisationPreleveDM,
            natureLocalisationPreleveDM: src.sNatureLocalisationPreleveDM,
            localisationPreleveDMC: src.iFKLocalisationPreleveDMC,
            information: src.sInformation,
        };
    };
    UpdateVisiteAdapter.prototype.toAPI = function (src) {
        return {
            tiersVisite: {
                iPKTiers: src.tiersVisite.tiersID,
            },
            intervenantPrincipal: {
                iPKTiers: src.intervenantPrincipal.intervenantId,
            },
            tTypeVisite: this.typeVisiteAdapter.toAPIs(src.typeVisites),
            adresse: {
                iPKAdresse: src.adresse.adresseId,
            },
            date: src.date,
            hDebut: src.hDebut,
            sNatureLocalisationPreleveDMC: src.natureLocalisationPreleveDMC,
            iFKLocalisationPreleveDM: src.localisationPreleveDM,
            sNatureLocalisationPreleveDM: src.natureLocalisationPreleveDM,
            iFKLocalisationPreleveDMC: src.localisationPreleveDMC,
            sInformation: src.information,
        };
    };
    return UpdateVisiteAdapter;
}(Adapter));
export { UpdateVisiteAdapter };
