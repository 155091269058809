import { PaddingDiv } from "components-v2/header/PaddingDiv";
import Workflow from "components/ui/organisms/Workflow";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import HeaderV2 from "./Header-v2";

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  background: transparent linear-gradient(#eaae3a 0%, #e9853a 100%) 0% 0%
    no-repeat padding-box;
  z-index: 100;
`;

export const NAV_HEIGHT = 8;

const WrapperNavPrimary = styled.div`
  z-index: 110; /* stack over header */
  margin: 1rem 0 -4rem 3rem;
  height: ${NAV_HEIGHT}rem;

  ${({ theme }) => theme.media.xl} {
    margin: 1rem 0 -4rem 7rem;
  }
`;

const WorkflowWrapper = styled.div`
  position: relative;
  top: 0;
  height: auto;
`;

export interface HeaderTemplateProps {
  displayWorkflow?: boolean;
  navigation?: JSX.Element;
  iPKVisite?: number;
  size: number;
}

export function HeaderTemplate({
  displayWorkflow = false,
  navigation,
  iPKVisite,
  size
}: HeaderTemplateProps) {
  const wrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });
  const scrollHandler = () => {
    if (wrapRef.current) {
      const workflowElement = wrapRef.current.style;
      workflowElement.top = `-${window.scrollY}px`;
      workflowElement.height = `calc(8rem - ${window.scrollY}px)`;
    }
  };

  return (
    <>
      <HeaderWrapper className="header-wrapper">
        <HeaderV2 />
        {displayWorkflow && iPKVisite && (
          <WorkflowWrapper className="workflow-wrapper" ref={wrapRef}>
            <Workflow iPKVisite={iPKVisite} />
          </WorkflowWrapper>
        )}
        {navigation && (
          <WrapperNavPrimary className="wrapper-navigation-primary">
            {navigation}
          </WrapperNavPrimary>
        )}
      </HeaderWrapper>
      <PaddingDiv size={size} />
    </>
  );
}
