import { MessageAdapter } from "@ads/isadom-model";
import { apiPost } from "@ads/react-common-module";
export const apiSaveMessage = (api, visiteID, message) => {
    if (api) {
        return apiPost(api, `/visites/${visiteID}/messages`, new MessageAdapter(), message);
    }
    return new Promise((_, reject) => {
        reject("La configuration api est null");
    });
};
