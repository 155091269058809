import {
  ConfigAppareilSpecTiersVisiteAPI,
  ConfigAppareilsTiersAPI,
  AppareilAPI,
  HttpResponseAPI,
  HttpResponseAdapter,
  TypeProduitAdapter,
  TypeProduitAPI,
  TypeProduitModel
} from "@ads/isadom-model";
import { ApiInstance, apiIsadomGet } from "@ads/react-common-module";
import { useState, useEffect } from "react";
import axios from "axios";
import api from "api";

import { MotifRetraitDMAPI } from "@ads/isadom-model";
import { apiIsadomErrorStrategy } from "@ads/ui";

interface GetConfigurationAppareilsTiersProps {
  visiteID: string | undefined;
}

export const useGetConfigurationAppareilsTiersFromAPI = ({
  visiteID
}: GetConfigurationAppareilsTiersProps) => {
  const [data, setData] = useState<ConfigAppareilsTiersAPI>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    const url = `visites/${visiteID}/appareils`;
    setLoading(true);

    api
      .get(url)
      .then((response) => {
        setData(response.data);
      })
      .catch((error: any) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 500) {
            setError("Erreur interne du serveur.");
          } else {
            setError(error.response?.data || "Erreur inconnue");
          }
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (visiteID) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiteID]);

  return { data, error, loading };
};

interface GetAppareilConfigurationSpecTiersProps {
  visiteID: string | undefined;
  appareilID: string | undefined;
}

export const useGetAppareilConfigurationSpecTiersFromAPI = ({
  visiteID,
  appareilID
}: GetAppareilConfigurationSpecTiersProps) => {
  const [data, setData] = useState<ConfigAppareilSpecTiersVisiteAPI>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    const url = `visites/${visiteID}/appareils/${appareilID}`;
    setLoading(true);
    api
      .get(url)
      .then((response) => {
        setData(response.data);
      })
      .catch((error: any) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 500) {
            setError("Erreur interne du serveur.");
          } else {
            setError(error.response?.data || "Erreur inconnue");
          }
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (visiteID && appareilID) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visiteID, appareilID]);

  return { data, error, loading };
};

interface GetAppareilProps {
  appareilID: string | undefined;
}

export const useGetAppareilFromAPI = ({ appareilID }: GetAppareilProps) => {
  const [data, setData] = useState<AppareilAPI>();
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);

  const fetchData = () => {
    const url = `appareils/${appareilID}`;

    api
      .get(url)
      .then((response) => {
        setData(response.data);
      })
      .catch((error: any) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 500) {
            setError("Erreur interne du serveur.");
          } else {
            setError(error.response?.data || "Erreur inconnue");
          }
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (appareilID) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appareilID]);

  return { data, error, loading };
};

interface GetMotifsRetraitAppareilProps {
  appareilID: string | undefined;
}

export const useGetMotifsRetraitAppareilFromAPI = ({
  appareilID
}: GetMotifsRetraitAppareilProps) => {
  const [data, setData] = useState<MotifRetraitDMAPI[]>();
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);

  const fetchData = () => {
    const url = `/modeles/${appareilID}/motifs-retrait`;

    api
      .get(url)
      .then((response) => {
        setData(response.data);
      })
      .catch((error: any) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 500) {
            setError("Erreur interne du serveur.");
          } else {
            setError(error.response?.data || "Erreur inconnue");
          }
        } else {
          setError(error);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (appareilID) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appareilID]);

  return { data, error, loading };
};

export async function apiGetTypeProduitByID(
  api: ApiInstance | null,
  id: string
): Promise<TypeProduitModel> {
  const adapter = new HttpResponseAdapter(new TypeProduitAdapter());
  const result = await apiIsadomGet<HttpResponseAPI<TypeProduitAPI>>(
    api,
    `/types-produits/${id}`,
    apiIsadomErrorStrategy
  );
  return adapter.toClient(result).data as TypeProduitModel;
}
