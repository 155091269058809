import TemplateErreur from "../../components/ui/templates/TemplateErreur";

export default function Page404() {
  return (
    <>

      <TemplateErreur type={404} />

    </>
  );
}
