import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from "antd";
import { Breadcrumb } from "@ads/ui";
import { Content } from "../atoms/Content";
import { useGetMessageByIDFromAPI } from "../../api/useGetMessageByIDFromAPI";
import MessageLectureSeule from "../organisms/MessageLectureSeule";
export function MessageLectureSeuleTemplate({ visiteID, messageID, }) {
    const { data: message, isLoading, error } = useGetMessageByIDFromAPI(visiteID, messageID);
    if (isLoading) {
        return null;
    }
    if (error) {
        return (_jsx(Alert, { message: "Impossible de charger le message", showIcon: true, type: "error" }, void 0));
    }
    const breadcrumbItems = [
        {
            item: "Messages",
            to: `/visites/${visiteID}/messages`,
        },
        {
            item: `Message en cours`,
            to: "",
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Breadcrumb, { size: "M", breadcrumbItems: breadcrumbItems }, void 0), _jsx(Content, { children: message && _jsx(MessageLectureSeule, { message: message }, void 0) }, void 0)] }, void 0));
}
