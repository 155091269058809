import ButtonDelete from "components/ui/atoms/buttons/ButtonDelete";
import styled from "styled-components";
import { ITypeVisiteTypePrestaListHookService } from "../hook/interfaces/ITypeVisiteTypePrestaListHookService";

const Wrapper = styled("div")`
  position: relative;
  margin-top: 8px;
`;

const WrappeSubTitle = styled.span`
  font-weight: bold;
`;

const WrapperDelete = styled("div")`
  position: absolute;
  z-index: 10;
  top: -1rem;
  right: -1rem;

  button {
    max-width: 2.2rem;
    max-height: 2.2rem;
  }

  svg {
    max-width: 1.5rem;
  }
`;

const WrapperCard = styled.div`
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.neutral[120]};
  border-radius: 0.5rem;
  &.visible {
    opacity: 1;
  }
  padding: 1rem;
  margin-bottom: 2rem;
`;

interface TypeVisiteTypePrestaListProps {
  services: ITypeVisiteTypePrestaListHookService;
  isDeletable?: boolean;
}

export function TypeVisiteTypePrestaList({
  services,
  isDeletable = false,
}: TypeVisiteTypePrestaListProps) {
  return (
    <>
      {services.typesVisites.map((typeVisite) => {
        return (
          <Wrapper>
            {isDeletable && (
              <WrapperDelete>
                <ButtonDelete
                  handleClick={() => {
                    services.notifyTypeVisiteDeleted(typeVisite.sPKTypeVisite);
                  }}
                  tooltip="Suppression d'un type de visite et des prestations liées"
                />
              </WrapperDelete>
            )}
            <WrapperCard>
              <div>
                <WrappeSubTitle>Type de visite : </WrappeSubTitle>
                <span> {typeVisite.sLibelle}</span>
              </div>
              <div>
                <WrappeSubTitle>Type de prestation : </WrappeSubTitle>
                {typeVisite.tTypePrestation.length === 0
                  ? "Aucune prestation pour ce type de visite"
                  : typeVisite.tTypePrestation.map((prestation, index) => {
                    if (index === typeVisite.tTypePrestation.length - 1) {
                      return <span>{prestation.libelle}.</span>;
                    } else {
                      return <span>{prestation.libelle}, </span>;
                    }
                  })}
              </div>
            </WrapperCard>
          </Wrapper>
        );
      })}
    </>
  );
}
