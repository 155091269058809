import { EvenementTelesuiviDTOModel } from "@ads/isadom-model";
import { truncate } from "@ads/react-common-module";
import { DeleteButton, IconSeparator } from "@ads/ui";

import {
  Annulation,
  Btn,
  CardItem,
  DateAccord,
  DateDebut,
  DateFin,
  Decision,
  EvenementStatus,
  Information,
  LabelItem,
  Status,
  TypeEvenement,
  Validation,
  WrapperButton,
} from "../../atoms/itemFields";

interface ListItemProps {
  visiteID: number;
  type: string;
  evenement: EvenementTelesuiviDTOModel;
  removeEvenement: () => void;
}

const objDate = new Intl.DateTimeFormat("fr-FR");

export const EvenementTelesuiviListItem = ({
  visiteID,
  type,
  evenement,
  removeEvenement,
}: ListItemProps) => {
  return (
    <CardItem
      to={`/visites/${visiteID}/evenements/${type}/${evenement.evenementVisiteID
        ? `${evenement.evenementVisiteID}?origine=visite`
        : `${evenement.ID}?origine=isadom`
        }`}
    >
      {evenement.evenementVisiteID ? (
        <WrapperButton
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            removeEvenement();
          }}
        >
          <DeleteButton />
        </WrapperButton>
      ) : (
        ""
      )}
      <Status>
        <EvenementStatus />
      </Status>
      <TypeEvenement>{evenement.libelleTypeEvenement}</TypeEvenement>
      <DateDebut>
        <LabelItem>Date de début : </LabelItem>
        {evenement.dateDebut !== "0000-00-00"
          ? objDate.format(new Date(evenement.dateDebut))
          : " - "}
      </DateDebut>
      <DateFin>
        <LabelItem>Date de fin : </LabelItem>
        {evenement.dateFin !== "0000-00-00"
          ? objDate.format(new Date(evenement.dateFin))
          : " - "}
      </DateFin>
      <Information>
        <LabelItem>Informations : </LabelItem>
        {truncate(evenement.information, 135)}
      </Information>
      <Decision>
        <LabelItem>Décision : </LabelItem>
        {evenement.estDecision ? "Oui" : "Non"}
      </Decision>
      <DateAccord>
        <LabelItem>Date d'accord : </LabelItem>
        {evenement.dateAccord && evenement.dateAccord !== "0000-00-00"
          ? objDate.format(new Date(evenement.dateAccord))
          : " - "}
      </DateAccord>
      <Validation>
        <LabelItem>Validé : </LabelItem>
        {evenement.estPeriodeValidite ? "Oui" : "Non"}
      </Validation>
      <Annulation>
        <LabelItem>Annulé : </LabelItem>
        {evenement.estAnnule ? "Oui" : "Non"}
      </Annulation>
      <Btn>
        <IconSeparator iconSize="3rem" iconColor="#524D72" />
      </Btn>
    </CardItem>
  );
};
