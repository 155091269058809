import { useEffect, useState } from "react";
export function useList({ fetchService, }) {
    const [dataState, setDataState] = useState([]);
    const { data, loading, fetch } = fetchService;
    // Lors du premier appel on fetch la donnée
    useEffect(() => {
        fetch();
    }, []);
    useEffect(() => {
        setDataState(data);
    }, [data]);
    return {
        data: dataState,
        fetchLoading: loading,
        notifyDataChanged: setDataState,
    };
}
