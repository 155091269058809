import { Module } from "@ads/react-common-module";
export * from "./ui";
export * from "./Containers";
// export * from "./api";
// export * from "./hooks";
export const CONSOMMABLES_CONFIGURATION_PATH = "consommables/configuration";
export const CONSOMMABLES_CONFIGURATION_DETAIL_PATH = "consommables";
export const CONSOMMABLES_LIVRAISONS_PATH = "consommables/livraisons";
export const CONSOMMABLES_RETOURS_PATH = "consommables/retours";
export const CONSOMMABLES_HISTORIQUE_PATH = "consommables/historique";
export const MODULE_NAME = "moduleConsommables";
export class ConsommablesModule extends Module {
    constructor() {
        super(MODULE_NAME, null, null);
    }
}
