// TODO: move to @ads/ui

import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { IconDropdown } from "@ads/ui";

const NavigationSecondary = styled.nav`
  line-height: 1;
  font-size: 0%;
  ${({ theme }) => theme.media.md} {
    display: block;
  }

  li {
    display: none;
    &.active {
      display: block;
      order: -1;
      ${({ theme }) => theme.media.md} {
        order: 0;
      }
    }
    ${({ theme }) => theme.media.md} {
      display: block;
    }
  }

  ul {
    list-style-type: none;
    display: inline-flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background: ${(props) => props.theme.colors.neutral[100]};
    box-shadow: 1px -4px 1.2rem rgb(80 70 70 / 21%);
    ${({ theme }) => theme.media.md} {
      flex-direction: row;
      display: flex;
      height: 6.5rem;
      gap: 6rem;
      padding: 0 6rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.neutral[110]};
      box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
    }

    &:hover li {
      display: block;
      .active {
        color: ${({ theme }) => theme.colors.primary[100]};
      }
    }
  }
`;

const NavigationItem = styled(NavLink)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  text-decoration: none;
  transition: color 0.25s ease-in-out;
  height: 100%;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.font.type.primaryMedium};
  border-top: 1px solid #e6e6e6;

  ${({ theme }) => theme.media.md} {
    justify-content: center;
    padding: 0;
    border: none;
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.font.type.primary};
  }

  &.active {
    display: flex;
    gap: 7px;
    color: ${(props) => props.theme.colors.neutral[190]};
    padding: 2.2rem 1.9rem;

    ${({ theme }) => theme.media.md} {
      color: ${(props) => props.theme.colors.primary[100]};

      padding: 0;
      gap: 0;

      &:after {
        content: "";
        position: absolute;
        bottom: -6px;
        display: block;
        width: 100%;
        height: 6px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        background-color: ${({ theme }) => theme.colors.primary[100]};
      }
    }

    .icon {
      display: inherit;
      ${({ theme }) => theme.media.md} {
        display: none;
      }
    }
  }
  
  &:hover,
  &.active {
    color: ${(props) => props.theme.colors.primary[100]};
  }

  .icon {
    display: none;
  }


`;
interface NavigationSecondaryTabConfig {
  id: string;
  label: string;
  url: string;
}
interface MenuTabProps {
  tabs: NavigationSecondaryTabConfig[];
  subTabActive:
  | "fiche"
  | "reglages"
  | "intervention"
  | "retrait"
  | "obligatoires"
  | "autres"
  | "informations";
}

const MenuTab = ({ tabs, subTabActive }: MenuTabProps) => (
  <NavigationSecondary className="menu-fiche-appareil">
    <ul>
      {tabs.map((tab: NavigationSecondaryTabConfig) => (
        <li className={subTabActive === tab.id ? "active" : ""} key={tab.id}>
          <NavigationItem to={tab.url}>
            {tab.label}
            <IconDropdown iconColor="#ED5B2E" iconSize="1.5rem" />
          </NavigationItem>
        </li>
      ))}
    </ul>
  </NavigationSecondary>
);

export default MenuTab;
