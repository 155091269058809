import { ProgrammeEducatifModel } from "@ads/isadom-model";
import {
  ApplicationContext,
  DataSourceGetResult,
  useForm
} from "@ads/react-common-module";
import { DataSourceDeleteResult } from "@ads/react-common-module/lib/esm/data-source/model/DataSourceDeleteResult";
import { adsNotification, DEFAULT_NOTIFICATION_DURATION } from "@ads/ui";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGetPEMetadata } from "../features/useGetPEMetadata";
import { useUpdatePEVisite } from "../features/useUpdatePEVisite";
import { MetadataProgrammeEducatifService } from "../models/MetadataProgrammeEducatifService";
import { compareProgrammeEducatif } from "../utils/compareProgrammeEducatif";

export interface FormPEHookInput {
  //   saveService: UseApiPostPutV3Output<ProgrammeEducatifModel>;
  visiteID: number;
  defaultFormData?: ProgrammeEducatifModel;
  handleSaveResult?: (result: ProgrammeEducatifModel) => void;
  handleCancel?: () => void;
  fetchService?: DataSourceGetResult<ProgrammeEducatifModel>;
  handleFetchResult?: (result: ProgrammeEducatifModel) => void;
  deleteService?: DataSourceDeleteResult<
    ProgrammeEducatifModel,
    Record<string, string | number>
  >;
  handleDeleteResult?: (result: ProgrammeEducatifModel) => void;
}

export function useFormPE({
  visiteID,
  defaultFormData,
  fetchService,
  deleteService,
  handleDeleteResult,
  handleFetchResult,
  handleSaveResult,
  handleCancel
}: FormPEHookInput) {
  const metadataService = useGetPEMetadata();
  const history = useHistory();

  const services = useForm<
    ProgrammeEducatifModel,
    MetadataProgrammeEducatifService
  >({
    metadata: metadataService,
    defaultFormData,
    compareTo: compareProgrammeEducatif,
    fetchServices: fetchService
      ? {
          ...fetchService,
          onResult(result) {
            if (handleFetchResult) {
              handleFetchResult(result);
            }
          }
        }
      : undefined,
    saveService: {
      ...useUpdatePEVisite(visiteID),
      onResult(result) {
        const theme = ApplicationContext.getInstance()
          .getAppSettings()
          .getData()?.theme;
        adsNotification.success({
          message: "Programme éducatif sauvegardé avec succès.",
          duration: theme?.duration?.notification
            ? theme.duration.notification
            : DEFAULT_NOTIFICATION_DURATION
        });
        history.goBack();
        if (handleSaveResult) {
          handleSaveResult(result);
        }
      }
    },
    deleteServices: deleteService
      ? {
          ...deleteService,
          onResult(result) {
            history.goBack();
            if (handleDeleteResult) {
              handleDeleteResult(result);
            }
          }
        }
      : undefined,
    handleCancel() {
      history.goBack();
      if (handleCancel) {
        handleCancel();
      }
    }
  });

  useEffect(() => {
    if (services.fetchServices?.fetch) {
      services.fetchServices.fetch();
    }
  }, []);

  return services;
}
