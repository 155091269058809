import React from "react";
import { LocalisationAdapter } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { DEFAULT_SEARCH_GUARD_LENGTH, ScannerSearch, 
// ScannerSearch,
SearchAdvanced, useGetAdvancedSearchProps, } from "@ads/ui";
import { apiSearchAppareil } from "../../../api/apiSearchAppareil";
import { tableSelectionAppareilColumns } from "./tableSelectionAppareilColumns";
import { FormSelectionAppareilAdapter } from "..";
import { ParamDisponibilite } from "../hook/interface/IParamDisponibilite";
export function SelectionAppareil({ config, onMaterielsSelected, hasScanner = false, }) {
    const api = getDefaultApiInstance();
    /**
     * @description Récupération de l'identifiant d'un appareil
     * @param item Item sur lequel il faut récupérer l'id
     * @returns L'id de l'item passé en paramètre
     */
    const getKeyFromData = (item) => {
        return item.appareilID;
    };
    /**
     * @description Permet de transformer un résultat de recherche en option pour le select simplifié
     * @param item Item à convertir
     * @returns L'item convertir en option pour le select simplifié
     */
    const transformSearchResultsToSelectOptions = (item) => `${item.libelleModele} (ref : ${item.referenceAppareil} / ns : ${item.numeroSerie})`;
    /**
     * @description Permet de transformer les data du formulaire de recherche avancée en paramètre pour la recherche
     * @param formData Donnée du formulaire de recherche avancée
     * @returns Les paramètres à utilisé pour la recherche
     */
    const transformModalFormDataToSearchParam = (formData) => {
        var _a;
        const natures = new Array();
        natures.push(formData.data.nature.id);
        return {
            natures: formData.data.nature ? natures : undefined,
            typeProduitID: formData.data.typeProduit
                ? formData.data.typeProduit.typeProduitId
                : "",
            familleID: formData.data.famille ? formData.data.famille.idFamille : "",
            sousFamilleID: formData.data.sousFamille
                ? formData.data.sousFamille.sousFamilleId
                : "",
            ref_sn: formData.data.ref_sn,
            estDisponible: formData.data.disponibilite,
            typeLocalisationID: formData.data.typeLocalisationID,
            localisation: formData.data.localisation &&
                new LocalisationAdapter().toAPI(formData.data.localisation),
            modeleID: (_a = formData.data.modele) === null || _a === void 0 ? void 0 : _a.produitID,
        };
    };
    /**
     * @description Permet de transformer les data du formulaire de recherche simplifié en paramètre pour la recherche
     * @param formData Donnée du formulaire de recherche simplifié
     * @returns Les paramètres à utilisé pour la recherche
     */
    const transformSelectFormDataToSearchParam = (formData) => {
        return {
            natures: undefined,
            typeProduitID: "",
            familleID: "",
            sousFamilleID: "",
            ref_sn: formData.trim(),
            estDisponible: ParamDisponibilite.tous,
        };
    };
    /**
     * Permet de préciser la config par défaut du formulaire de recherche avancée
     */
    const defaultModalFormData = {
        config: config,
    };
    const searchAdvancedInput = useGetAdvancedSearchProps({
        hookParams: {
            api,
            defaultModalFormData: defaultModalFormData,
            getKeyFromData,
            typeSelection: "simple",
            modalSearch: apiSearchAppareil,
            selectSearch: apiSearchAppareil,
            transformModalFormDataToSearchParam,
            transformSelectFormDataToSearchParam,
        },
        tableSearchConfiguration: {
            columns: tableSelectionAppareilColumns,
            formAdapter: FormSelectionAppareilAdapter,
            modalTitleAdapter: "Recherche d'un appareil",
        },
        selectSearchConfiguration: {
            transformSearchResultsToSelectOptions,
            searchGuardLength: DEFAULT_SEARCH_GUARD_LENGTH,
            placeholder: "Code ou N° série",
            widget: hasScanner ? ScannerSearch : undefined,
        },
        onSubmit: onMaterielsSelected,
    });
    return React.createElement(SearchAdvanced, Object.assign({}, searchAdvancedInput));
}
