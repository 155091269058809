import { apiPost } from "@ads/react-common-module";
import { LocalisationAdapter } from "@ads/isadom-model";
export const apiGetLocalisations = (api) => {
    if (api) {
        const adapter = new LocalisationAdapter();
        return apiPost(api, `/types-localisations/internes/localisations/search`, adapter, {});
    }
    return new Promise((_, reject) => {
        reject("La configuration api est null");
    });
};
