import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Alert } from "antd";
import { FormWidget, Loading } from "@ads/ui";
import { ObservanceVisiteModel } from "@ads/isadom-model";
import { RouteParam } from "interface/core/RouteParam";
import useObservancesForm from "../../hooks/useObervancesForm";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";
import TitleMain from "components/ui/atoms/titles/TitleMain";
import { FormObservance } from "../organisms/FormObservance";
import WrapperError from "components/ui/organisms/wrappers/WrapperError";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";

const TemplateObservance = () => {
  const [observances, setObservances] = useState<ObservanceVisiteModel[]>([]);
  const { iPKVisite } = useParams<RouteParam>();
  const { data: visite } = useGetVisiteQuery(+iPKVisite);
  const visiteID = parseInt(iPKVisite, 10);
  const { isLoadingData, isErrorData, data, saveObservances, isLoadingSave } =
    useObservancesForm(visiteID);

  const handleSave = async () => {
    saveObservances(visiteID, observances);
  };

  useEffect(() => {
    if (data) {
      setObservances(data);
    }
  }, [data]);

  if (isLoadingData) {
    return <Loading>Chargement en cours des observances...</Loading>;
  }

  if (isErrorData) {
    return (
      <WrapperAlert>
        <Alert
          message="Error"
          showIcon
          description="Une erreur est survenue pendant le chargement des observances"
          type="error"
        />
      </WrapperAlert>
    );
  }

  return (
    <>
      <TitleMain>Observances</TitleMain>

      {observances.length > 0 ? (
        <FormWidget handleSave={handleSave} isLoadingSave={isLoadingSave}>
          {observances.map((observance: ObservanceVisiteModel) => (
            <FormObservance
              key={observance.ID}
              observance={observance}
              observances={observances}
              setObservances={setObservances}
              visite={visite}
            />
          ))}
        </FormWidget>
      ) : (
        <WrapperError>
          <Alert
            message=""
            showIcon
            description={"Aucune donnée d'observance"}
            type="info"
          />
        </WrapperError>
      )}
    </>
  );
};

export default TemplateObservance;
