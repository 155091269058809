import { FC } from "react";
import { FieldTimeOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface Props {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const BoutonOpenModal: FC<Props> = ({ setIsModalVisible }: Props) => {
  return (
    <Button className="button-modal" onClick={() => setIsModalVisible(true)}>
      <FieldTimeOutlined />
    </Button>
  );
};

export default BoutonOpenModal;
