import styled from "styled-components";

import { ButtonResetCommande } from "../atoms/ButtonResetCommande";
import FiltersCommande from "../molecules/FiltersCommande";
import BreadcrumbCommande from "./BreadcrumbCommande";

const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;

const WrapperHeadersRight = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 2rem;
`;

interface CommandeHeaderProps {
  resetData: () => void;
}

const CommandeHeader = ({ resetData }: CommandeHeaderProps) => {
  const breadcrumbItems = [
    {
      item: "Commandes",
      to: "",
    },
    {
      item: "Nouvelle commande",
      to: "",
    },
  ];

  return (
    <WrapperHeader>
      <BreadcrumbCommande breadcrumbItems={breadcrumbItems} />
      <WrapperHeadersRight>
        <FiltersCommande />
        <ButtonResetCommande resetData={resetData} />
      </WrapperHeadersRight>
    </WrapperHeader>
  );
};

export default CommandeHeader;
