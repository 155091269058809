import { PPT } from "@ads/isadom-model";
import { SelectV3 } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 60%;
`;

const FormAdresseVoie = () => {
  const { typesVoies, setTypeVoie } = useFormulaireCommandesStore();
  return (
    <Wrapper>
      <label htmlFor="voie">Voie</label>
      <SelectV3
        disabled={false}
        id="type-voies"
        name="type-voie"
        onSelect={(value) => {
          const typeVoie = typesVoies.find((t) => t.sCode === value);
          if (typeVoie) {
            setTypeVoie(typeVoie);
          }
        }}
        showSearch
        filterOption={(input: string, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={typesVoies.map((typeVoie: PPT) => ({
          value: typeVoie.sCode,
          label: typeVoie.sLibelle
        }))}
        selectMode={"light"}
        placeholder="Sélectionnez un type de voie"
        defaultValue={undefined}
        allowClear
      />
    </Wrapper>
  );
};

export default FormAdresseVoie;
