import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { EvenementTelesuiviModel } from "@ads/isadom-model";
import { Informations } from "../../atoms/itemFields";

interface EvenementTelesuiviInformationsFormProps {
  evenement?: EvenementTelesuiviModel;
  onInformationsChange: (evenement: EvenementTelesuiviModel) => void;
}

export const EvenementTelesuiviInformationsForm = ({
  evenement,
  onInformationsChange,
}: EvenementTelesuiviInformationsFormProps) => (
  <Informations>
    <ReactQuill
      theme="snow"
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline"],
          [{ color: [] }, { background: [] }],
          ["clean"],
        ],
      }}
      defaultValue={evenement?.informations ? evenement.informations : ""}
      onChange={(content) => {
        onInformationsChange({
          ...evenement,
          informations: content,
        } as EvenementTelesuiviModel);
      }}
    />
  </Informations>
);
