
import TemplateConfiguration from "modules/appareils/ui/templates/TemplateConfiguration";
import TemplateRetrait from "modules/appareils/ui/templates/TemplateRetrait";
import TemplateAppareil from "modules/appareils/ui/templates/TemplateAppareil";
import TemplateHistorique from "modules/appareils/ui/templates/TemplateHistorique";
import TemplateObservance from "modules/appareils/ui/templates/TemplateObservance";
import ProtectedRoute from "components/core/routes/ProtectedRoute";

const RouterAppareil = () => {
  return (
    <>
      <ProtectedRoute exact path="/visites/:iPKVisite/appareils/configuration">
        <TemplateConfiguration />
      </ProtectedRoute>
      <ProtectedRoute exact path="/visites/:iPKVisite/appareils/historique">
        <TemplateHistorique />
      </ProtectedRoute>
      <ProtectedRoute exact path="/visites/:iPKVisite/appareils/observances">
        <TemplateObservance />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/visites/:iPKVisite/appareils/:tabActive/:IDProduit/:subTabActive"
        render={({ match }) => {
          switch (match.params.subTabActive) {
            case "fiche":
            case "reglages":
              return <TemplateAppareil />
            case "retrait":
              return <TemplateRetrait />
          }
        }}
      />

    </>
  )
}

export default RouterAppareil

