import { INavigationItem } from "@ads/ui";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";

export const NavigationFinVisite = () => {
  const { tabActive, iPKVisite } = useParams<RouteParam>();

  const navItems: Array<INavigationItem> = [
    {
      to: `/visites/${iPKVisite}/fin-de-visite/observations-internes`,
      label: "Observations internes",
      active: tabActive === "observations-internes",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/fin-de-visite/enregistrement`,
      label: "Enregistrement",
      active: tabActive === "enregistrement",
      visible: true
    }
  ];

  return (
    <NavigationPrimary
      navItems={navItems || []}
      isOpen={false}
      isLargeNav={false}
    />
  );
};
