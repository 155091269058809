import { useEffect } from "react";
import { useSelectVisiteStore } from "../store/visiteStore";
import { useApiGetVisiteByID } from "./useApiGetVisiteByID";

export function useSyncVisiteByIDWithContext(visiteID: number) {
  const { visitesMap, addVisite } = useSelectVisiteStore();
  const { fetch, loading, data } = useApiGetVisiteByID(visiteID);

  useEffect(() => {
    if (!visitesMap.has(visiteID)) {
      fetch();
    }
  }, []);

  useEffect(() => {
    if (data) {
      addVisite(data);
    }
  }, [data]);


  return { data, loading };
}
