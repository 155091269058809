var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { notification } from "antd";
import { apiAjouteConsommableConfigurationVisite } from "../api/apiAjouteConsommableConfigurationVisite";
/**
 *
 * @param api
 * @param visiteID
 * @param configConsoSpecTiersVisiteModel
 * @returns true en cas de succès false en cas d'erreur
 */
export const ajouteConfigurationConsommable = ({ api, visiteID, configConsoSpecTiersVisiteModel, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const configurationConsommable = yield apiAjouteConsommableConfigurationVisite(api, visiteID, configConsoSpecTiersVisiteModel);
        notification.success({
            key: `${configurationConsommable.configCsoVisiteID}`,
            message: "Confirmation",
            description: "La configuration a bien été enregistrée.",
        });
        return true;
    }
    catch (error) {
        console.error("👩‍🚒 Une erreur est survenue dans ajouteConfigurationConsommable.ts", error);
        return false;
    }
});
