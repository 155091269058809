import { Tooltip } from "antd";
import styled from "styled-components";
import { MinusOutlined } from "@ant-design/icons";

const StyleButtonDelete = styled("button")`
  display: flex;
  height: 3.1rem;
  width: 3.1rem;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 2rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.danger[100]};
  cursor: pointer;

  svg path {
    fill: ${({ theme }) => theme.colors.neutral[100]};
  }
`;

interface PropsButtonDelete {
  tooltip?: string;
  handleClick?: () => void;
}

const ButtonDelete = ({ handleClick, tooltip }: PropsButtonDelete) => {
  return tooltip ? (
    <Tooltip title={tooltip}>
      <StyleButtonDelete type="button" onClick={handleClick}>
        <MinusOutlined />
      </StyleButtonDelete>
    </Tooltip>
  ) : (
    <StyleButtonDelete type="button" onClick={handleClick}>
      <MinusOutlined />
    </StyleButtonDelete>
  );
};

export default ButtonDelete;
