import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { IconSeparator } from "@ads/ui";
import { ConsommableDetail } from "./ConsommableConfigurationDetail";
import { NavLink } from "react-router-dom";
const CardItem = styled(NavLink) `
  display: grid;
  margin-bottom: 2.6rem;
  grid-template-areas:
    "status status status"
    "prestations prestations btn"
    "details details btn";
  grid-template-columns: 25% 63% 12%;
  min-height: 13.6rem;
  padding: 0 0.6rem;
  background: ${({ disabled, theme }) => disabled ? theme.colors.neutral[120] : theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  transition: transform 0.1s ease-out, box-shadow 0.2s, color 0.2s ease-in-out;

  &:last-child {
    margin: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary[100]};
  }

  ${({ theme }) => theme.media.sm} {
    grid-template-areas:
      "status prestations btn"
      "details details btn";

    grid-template-columns: 15% 75% 10%;
    padding: 0.6rem 2rem;
  }

  ${({ theme }) => theme.media.md} {
    grid-template-areas:
      "status details btn"
      "prestations details btn";

    grid-template-columns: 15% 75% 10%;
    padding: 0.6rem 2rem;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-areas: "status prestations details btn";
    grid-template-columns: 5% 15% 75% 5%;
    grid-template-rows: auto;
    padding: 0.6rem 0;
  }
`;
const Status = styled.div `
  grid-area: status;
  display: flex;

  height: 100%;

  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  padding: 1.7rem;

  ${({ theme }) => theme.media.sm} {
    padding: 1.7rem 0;
  }
  ${({ theme }) => theme.media.md} {
    padding: 1.7rem;
    margin-right: 2rem;
  }

  ${({ theme }) => theme.media.lg} {
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 0;
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;
const ConsommableStatus = styled.div `
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.neutral[130]};
  border-radius: 50%;
`;
const Prestations = styled.div `
  grid-area: prestations;
  display: flex;
  height: 100%;
  padding: 1.7rem;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => theme.media.md} {
    padding: 1.7rem 0 1.7rem 1rem;
    align-items: flex-start;
  }
  ${({ theme }) => theme.media.lg} {
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`;
const PrestationLabel = styled.span `
  padding: 0.5rem 1.4rem;
  background: ${({ theme }) => theme.colors.neutral[130]};
  border-radius: 1.7rem;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.7rem;
  max-width: 14rem;
`;
const Btn = styled("button") `
  grid-area: btn;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background: none;

  ${({ theme }) => theme.media.sm} {
    border-left: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }

  ${({ theme }) => theme.media.lg} {
    justify-content: center;
  }
`;
const WrapperPrestationLabel = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ConsommableCard = ({ visiteID, config }) => {
    var _a;
    return (_jsxs(CardItem, Object.assign({ disabled: config.bRetraitConfig, as: config.bRetraitConfig ? "div" : NavLink, to: `/visites/${visiteID}/consommables/configuration/${config.configCsoVisiteID}` }, { children: [_jsx(Status, { children: _jsx(ConsommableStatus, {}, void 0) }, void 0), _jsx(Prestations, { children: config.typePrestation && (_jsx(WrapperPrestationLabel, { children: _jsx(PrestationLabel, { children: (_a = config.typePrestation) === null || _a === void 0 ? void 0 : _a.id }, void 0) }, void 0)) }, void 0), _jsx(ConsommableDetail, { configConsoSpecTiersVisite: config }, void 0), _jsx(Btn, { children: _jsx(IconSeparator, { iconSize: "3rem", iconColor: "#524D72" }, void 0) }, void 0)] }), void 0));
};
