import { FC } from "react";
import { FormItemWrapper, Label, Textarea } from "@ads/ui";
import styled from "styled-components";
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import getModeActif, {
  ModeActif,
} from "modules/appareils/functions/getModeActif";

interface Props {
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
  disabled?: boolean;
}

const WrapperTextArea = styled.div`
  height: 16rem;
`;

const TextAreaCommentaires: FC<Props> = ({
  modesReglagesAppareil,
  setModesReglagesAppareil,
  disabled = false,
}) => {
  /**
   * Modification  du commenaire du mode saisis actif
   * @param e
   */
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // On recupere le mode saisi actif
    const modeSaisieActif = getModeActif(modesReglagesAppareil);

    // Mise á jour du commentaire pour le mode actif
    if (modeSaisieActif.mode) {
      const commentaire = e.target.value;

      const modeUpdated: ModeActif = {
        ...modeSaisieActif,
        mode: { ...modeSaisieActif.mode, sInformation: commentaire },
      };

      const newModeSaisi = [...modesReglagesAppareil.modesSaisis];
      newModeSaisi[modeSaisieActif.indexModeSaisi] = modeUpdated.mode;

      setModesReglagesAppareil({
        ...modesReglagesAppareil,
        modesSaisis: newModeSaisi,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error("👨‍🚒 Pas de modeSaisieActif");
    }
  };

  const modeSaisieActif = getModeActif(modesReglagesAppareil);

  return (
    <FormItemWrapper direction="vertical">
      <Label htmlFor="commentaire">Commentaires</Label>
      <WrapperTextArea>
        <Textarea
          id="commentaire"
          onChange={handleChange}
          name="commentaire"
          value={modeSaisieActif.mode?.sInformation}
          selectMode="light"
          disabled={disabled}
        />
      </WrapperTextArea>
    </FormItemWrapper>
  );
};

export default TextAreaCommentaires;
