import moment from "moment";
import "moment/locale/fr";
export const getDateFr = (date) => moment(date).locale("fr").format("L");
export const getTimeFromDate = (date) => moment(date).format("h:mm");
export const getTimeFromBackTime = (time) => time.slice(0, -7);
export function toFrDate(date) {
    return getDateFr(new Date(date)) !== "Invalid date"
        ? getDateFr(new Date(date))
        : "__/__/____";
}
