import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Input } from "antd";
import { useState } from "react";
const NumLotInput = styled(Input) `
  min-width: 3em;
  width: 6em;
  text-align: center;

  ${({ theme }) => theme.media.lg} {
    width: 12rem;
  }
`;
const ContainerForms = styled.form `
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.lg} {
    justify-content: flex-end;
  }
`;
export const FormLivraisonVisiteNumLot = ({ livraison, refreshData, }) => {
    var _a, _b, _c, _d;
    const [timer, setTimer] = useState();
    const [numLot, setNumLot] = useState(((_a = livraison.lot) === null || _a === void 0 ? void 0 : _a.sLot) || "");
    const handleChange = (e) => {
        const newNumLot = e.target.value;
        setNumLot(newNumLot);
        window.clearTimeout(timer);
        setTimer(window.setTimeout(() => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const data = Object.assign(Object.assign({}, livraison), { lot: {
                    iPKProduitDMCLot: ((_a = livraison.lot) === null || _a === void 0 ? void 0 : _a.iPKProduitDMCLot) || -1,
                    iFKProduit: ((_b = livraison.lot) === null || _b === void 0 ? void 0 : _b.iFKProduit) || -1,
                    sLot: newNumLot,
                    dLimiteConso: ((_c = livraison.lot) === null || _c === void 0 ? void 0 : _c.dLimiteConso) || "",
                    dLimiteUtilisation: ((_d = livraison.lot) === null || _d === void 0 ? void 0 : _d.dLimiteUtilisation) || "",
                    iPKEmplacement: ((_e = livraison.lot) === null || _e === void 0 ? void 0 : _e.iPKEmplacement) || -1,
                    sCodeEmplacement: ((_f = livraison.lot) === null || _f === void 0 ? void 0 : _f.sCodeEmplacement) || "",
                    iPKStock: ((_g = livraison.lot) === null || _g === void 0 ? void 0 : _g.iPKStock) || -1,
                    iStockTheorique: ((_h = livraison.lot) === null || _h === void 0 ? void 0 : _h.iStockTheorique) || -1,
                } });
            refreshData(data);
        }, 500));
    };
    return (_jsx(ContainerForms, { children: _jsx(NumLotInput, { type: "text", onChange: handleChange, placeholder: "N\u00B0 de lot", id: `slot-${livraison.ligneLivraisonID}-${(_b = livraison.lot) === null || _b === void 0 ? void 0 : _b.sLot}`, defaultValue: ((_c = livraison.lot) === null || _c === void 0 ? void 0 : _c.sLot) || undefined, value: numLot, name: `slot-${livraison.ligneLivraisonID}-${(_d = livraison.lot) === null || _d === void 0 ? void 0 : _d.sLot}` }, void 0) }, void 0));
};
