import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Loading } from "@ads/ui";
import { Alert } from "antd";
import { useGetConsommablesConfigurationByVisiteID } from "../hooks/useGetConsommablesConfigurationByVisiteID";
import { ConsommablesConfigurationTemplate } from "../ui/templates";
export const ContainerConsommableConfiguration = ({ visiteID, SelectionConsommable, }) => {
    const { data: configurationsConsommable, loading, errors, setData: setConfigurationsConsommable, } = useGetConsommablesConfigurationByVisiteID(visiteID);
    if (loading) {
        return (_jsx(_Fragment, { children: _jsx(Loading, { children: "Chargement en cours de la configuration des consommables." }, void 0) }, void 0));
    }
    if (errors) {
        return (_jsx(Alert, { showIcon: true, type: "error", message: "Une erreur est survenue...", description: "La configuration des consommables n'a pu \u00EAtre r\u00E9cup\u00E9r\u00E9e." }, void 0));
    }
    return (_jsx(ConsommablesConfigurationTemplate, { visiteID: visiteID, setConfigurationsConsommable: setConfigurationsConsommable, configurationsConsommable: configurationsConsommable, SelectionConsommable: SelectionConsommable }, void 0));
};
