import { PPT, TypeProgrammeEducatifModel } from "@ads/isadom-model";
import { useGetWithCache } from "@ads/react-common-module";
import { useEffect } from "react";
import { useSelectPEStore } from "../components/context/PEContext";
import { MetadataProgrammeEducatifService } from "../models/MetadataProgrammeEducatifService";
import { useSyncEtapeWithContext } from "./useSyncEtapeWithContext";
import { useSyncTypePEWithContext } from "./useSyncTypePEWithContext";

export function useGetPEMetadata(): MetadataProgrammeEducatifService {

    const { metadata, setMetadata } = useSelectPEStore()
    const serviceTypePE = useGetWithCache<TypeProgrammeEducatifModel[]>({
        criteria: undefined,
        getFromCache: () => metadata?.typePEListService?.data ? metadata.typePEListService.data : null,
        getFromRemote: useSyncTypePEWithContext
    })

    const serviceEtape = useGetWithCache<PPT[]>({
        criteria: undefined,
        getFromCache: () => metadata?.etapeListService?.data ? metadata.etapeListService.data : null,
        getFromRemote: useSyncEtapeWithContext
    })

    useEffect(() => {
        Promise.all([serviceTypePE.fetch(), serviceEtape.fetch()]).then((values) => {
            setMetadata({
                typePEListService: {
                    ...serviceTypePE,
                    data: values[0]
                },
                etapeListService: {
                    ...serviceEtape,
                    data: values[1]
                },
            })
        })


    }, [])

    return {
        etapeListService: serviceEtape,
        typePEListService: serviceTypePE
    }
}
