import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Search } from "@ads/ui";
import { useSelectTypeQuestionnaire } from "../..";
import { useForm, FormProvider } from "react-hook-form";
export function SearchTypeQuestionnaire(props) {
    const methods = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {},
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
    });
    const searchProps = {
        name: props.name,
        defaultOption: [],
        defaultValue: [],
        useSelection: useSelectTypeQuestionnaire,
        columns: [],
        transformToOption: (item) => {
            return {
                value: item.typeQuestionnaireID,
                label: item.libelle
            };
        },
        FormSelect: (_jsx(_Fragment, {}, void 0)),
        onSelect: props.onSelect
    };
    return _jsx("div", Object.assign({ className: props.className }, { children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsx(Search, Object.assign({}, searchProps), void 0) }), void 0) }), void 0);
}
