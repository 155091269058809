import { VisiteAdapter, VisiteAPI, VisiteModel } from "@ads/isadom-model";
import {
  getDefaultApiInstance,
  useApiPostV2,
  UseApiPostV2Output,
} from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export function useApiPostVisite(): UseApiPostV2Output<
  VisiteModel,
  VisiteModel
> {
  const adapter = new VisiteAdapter();
  const { loading, data, post } = useApiPostV2<
    VisiteAPI,
    VisiteModel,
    VisiteAPI
  >(getDefaultApiInstance(), "/visites", {
    adaptResult(result: VisiteAPI): VisiteModel {
      return adapter.toClient(result);
    },
    errorStrategy: apiIsadomErrorStrategy,
  });
  function postVisite(visite: VisiteModel) {
    const visiteAPI = adapter.toAPI(visite);

    post(visiteAPI);
  }
  return {
    loading,
    data,
    post: (visite) => postVisite(visite),
  };
}
