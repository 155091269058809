var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
export function useForm(input) {
    const [readLoding, setReadLoading] = useState(false);
    const [writeLoding, _setWriteLoading] = useState(false);
    const [formulaireState, setFormulaireState] = useState(null);
    useEffect(() => {
        setReadLoading(true);
        input.data.then((data) => {
            setFormulaireState(data);
        }).finally(() => {
            setReadLoading(false);
        });
    }, []);
    function onFormulaireChange(formulaire) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (formulaireState === null) {
                throw new Error("Le formulaire est null.");
            }
            if ((_a = input.callbacks) === null || _a === void 0 ? void 0 : _a.onFormulaireChange) {
                formulaire = yield input.callbacks.onFormulaireChange(formulaire);
            }
            setFormulaireState(formulaire);
            return formulaire;
        });
    }
    function updateFormulaire(formulaire) {
        return __awaiter(this, void 0, void 0, function* () {
            if (formulaire) {
                formulaire = yield input.features.updateFormulaire(formulaire);
                setFormulaireState(formulaire);
                return formulaire;
            }
            else {
                setFormulaireState(null);
                return null;
            }
        });
    }
    return {
        data: formulaireState,
        actions: {
            notifyFormulaireChange: onFormulaireChange
        },
        features: {
            updateFormulaire: updateFormulaire
        },
        loadings: {
            readLoading: readLoding,
            writeLoading: writeLoding
        }
    };
}
