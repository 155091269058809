import {
  AffectationProduitModel,
  // AffectationProduitModel,
  LigneCommandeVisiteModel
} from "@ads/isadom-model";
import { FormItemWrapper, Label, Select, SelectWrapper } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  min-width: 15rem;
  text-align: center;
  order: 3;
  width: 90%;
  ${({ theme }) => theme.media.sm} {
    width: auto;
  }
  ${({ theme }) => theme.media.md} {
    margin: 0 1rem 0 0;
    order: 2;
  }
  .mode-light {
    text-align: left;
    width: 100%;
    ${({ theme }) => theme.media.md} {
      width: calc(100% + 2rem);
    }
  }
`;

interface CommandeItemAffectationProps {
  item: LigneCommandeVisiteModel;
}

const CommandeItemAffectation = ({ item }: CommandeItemAffectationProps) => {
  const { affectations, setAffectation } = useFormulaireCommandesStore();
  const codeNatureProduit = (item.produit?.typeProduit.codeNatureProduit ||
    "C") as unknown as "C" | "M"; // C est inclus dans M.
  const options: AffectationProduitModel[] = affectations[codeNatureProduit];

  return (
    <Wrapper>
      <FormItemWrapper direction="vertical">
        <Label htmlFor={`affectation-${item.ID}-${item.famille?.idFamille}`}>
          Affectation
        </Label>
        <SelectWrapper selectMode="light">
          <Select
            disabled={false}
            id={`affectation-${item.ID}-${item.famille?.idFamille}`}
            name={`affectation-${item.ID}-${item.famille?.idFamille}`}
            onSelect={(value) => {
              const affectation = options.find((a) => `${a.code}` === value);
              if (affectation) {
                setAffectation(item.ID, affectation);
              }
            }}
            showSearch
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            itemsSelect={options.map(
              (affectation: AffectationProduitModel) => ({
                value: `${affectation.code}`,
                content: affectation.libelle
              })
            )}
            selectMode={"light"}
            placeholder="Sélectionnez une affectation"
            defaultValue={
              item.affectationProduit
                ? `${item.affectationProduit.code}`
                : undefined
            }
            allowClear
          />
        </SelectWrapper>
      </FormItemWrapper>
    </Wrapper>
  );
};

export default CommandeItemAffectation;
