import { FormItemWrapper, Label, Textarea } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.md} {
    width: calc(100% / 3);
  }
  ${({ theme }) => theme.media.xl} {
    width: 100%;
  }
`;

const HeadersCommentaire = () => {
  const { commande, setCommentaire } = useFormulaireCommandesStore();

  return (
    <Wrapper>
      <FormItemWrapper direction="vertical">
        <Label htmlFor="commentaire">Commentaire</Label>
        <Textarea
          onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
            setCommentaire(evt.target.value);
          }}
          value={commande ? commande.commentaire : ""}
          id="sInformation"
          name="sInformation"
        />
      </FormItemWrapper>
    </Wrapper>
  );
};

export default HeadersCommentaire;
