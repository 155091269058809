import { EquipeModel } from "@ads/isadom-model";
import { FormItemWrapper, SelectWrapper, Label, SelectV3 } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";

const HeadersSelectPreparation = () => {
  const { commande, equipes, setEquipePreparation } =
    useFormulaireCommandesStore();

  return (
    <FormItemWrapper direction="vertical">
      <Label htmlFor="equipe-preparation">&Agrave; préparer par</Label>
      <SelectV3
        disabled={false}
        id="equipe-preparation"
        name="equipe-preparation"
        onSelect={(value) => {
          const equipe = equipes.find((e) => e.equipeID === value);
          if (equipe) {
            setEquipePreparation(equipe);
          }
        }}
        showSearch
        filterOption={(input: string, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={equipes.map((equipe: EquipeModel) => ({
          value: `${equipe.equipeID}`,
          label: equipe.libelle
        }))}
        selectMode={"light"}
        placeholder="Sélectionnez une équipe"
        defaultValue={
          commande?.equipePreparation
            ? `${commande.equipePreparation.equipeID}`
            : undefined
        }
        allowClear
      />
    </FormItemWrapper>
  );
};

export default HeadersSelectPreparation;
