var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { restaureConfigurationConsommable } from "../../features/restaureConfigurationConsommable";
import { useState } from "react";
import { notification } from "antd";
import { v4 } from "uuid";
const ButtonRestaure = styled.button `
  position: absolute;
  top: -1.5rem;
  right: -0.5rem;
  z-index: 10;
  padding: 1rem;
  width: auto;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.quinary[100]};
  border: 0;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.quinary[110]};
  }

  ${({ theme }) => theme.media.md} {
    top: -1.5rem;
    right: -1.5rem;
  }
`;
export const ButtonRestaureConfigConso = ({ visiteID, configurationConsommable, configurationsConsommable, setConfigurationsConsommable, }) => {
    const api = getDefaultApiInstance();
    const [isLoading, setIsLoading] = useState(false);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const isSuccess = yield restaureConfigurationConsommable({
            api,
            visiteID,
            configurationConsommable,
        });
        setIsLoading(false);
        if (isSuccess) {
            const copyConfigurationsConsommable = [...configurationsConsommable];
            const indexConfigurationConsommable = copyConfigurationsConsommable.findIndex((item) => item.configCsoVisiteID == configurationConsommable.configCsoVisiteID);
            const newConfigurationConsommable = Object.assign(Object.assign({}, copyConfigurationsConsommable[indexConfigurationConsommable]), { bRetraitConfig: false });
            copyConfigurationsConsommable[indexConfigurationConsommable] =
                newConfigurationConsommable;
            setConfigurationsConsommable(copyConfigurationsConsommable);
        }
        else {
            notification.error({
                key: v4(),
                message: "Erreur",
                description: (_jsxs(_Fragment, { children: ["Une erreur est survenue pendant la restauration du consommable.", " ", _jsx("br", {}, void 0), " Veuillez contacter un administrateur."] }, void 0)),
            });
        }
    });
    return (_jsx(ButtonRestaure, Object.assign({ disabled: isLoading, onClick: handleClick }, { children: "Restaurer" }), void 0));
};
