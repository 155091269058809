import styled from "styled-components";
import { FormItemWrapper, Label } from "@ads/ui";
import { DatePicker, Switch } from "antd";
import { Details } from "../../atoms/viewFields";

import { EvenementRGPDModel } from "@ads/isadom-model";
import moment from "moment";
import locale from "antd/es/date-picker/locale/fr_FR";

const DatePickerANTD = styled(DatePicker)`
  background: ${(props) => props.theme.colors.neutral[130]};
`;

const SwitchWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

interface EvenementRGPDDetailsProps {
  evenement?: EvenementRGPDModel;
  onChange: (evenement: EvenementRGPDModel) => void;
}

export const EvenementRGPDDetailsForm = ({
  evenement,
  onChange,
}: EvenementRGPDDetailsProps) => (
  <Details>
    <FormItemWrapper direction="vertical">
      <Label htmlFor="dateDebut" selectMode="dark">
        Date de début
      </Label>
      <DatePickerANTD
        name="dateDebut"
        id="dateDebut"
        defaultValue={
          evenement && evenement.dateDebut !== "0000-00-00"
            ? moment(evenement.dateDebut)
            : undefined
        }
        format="DD/MM/YYYY"
        locale={locale}
        onChange={(value) => {
          onChange({
            ...evenement,
            dateDebut: value ? value.format("YYYY-MM-DD") : "",
          } as EvenementRGPDModel);
        }}
        placeholder="Date de début"
      />
    </FormItemWrapper>
    <FormItemWrapper direction="vertical">
      <Label htmlFor="dateFin" selectMode="dark">
        Date de fin
      </Label>
      <DatePickerANTD
        name="dateFin"
        id="dateFin"
        defaultValue={
          evenement && evenement.dateFin !== "0000-00-00"
            ? moment(evenement.dateFin)
            : undefined
        }
        format="DD/MM/YYYY"
        locale={locale}
        onChange={(value) => {
          onChange({
            ...evenement,
            dateFin: value ? value.format("YYYY-MM-DD") : "",
          } as EvenementRGPDModel);
        }}
        placeholder="Date de début"
      />
    </FormItemWrapper>
    <SwitchWrapper>
      <Label htmlFor="estRealisationPrestation" selectMode="dark">
        Réalisation prestation
      </Label>
      <Switch
        id="estRealisationPrestation"
        onChange={(checked: boolean) => {
          onChange({
            ...evenement,
            estRealisationPrestation: checked,
          } as EvenementRGPDModel);
        }}
        defaultChecked={evenement?.estRealisationPrestation}
      />
    </SwitchWrapper>
    <SwitchWrapper>
      <Label htmlFor="estTelesuivi" selectMode="dark">
        Télésuivi
      </Label>
      <Switch
        id="estTelesuivi"
        onChange={(checked: boolean) => {
          onChange({
            ...evenement,
            estTelesuivi: checked,
          } as EvenementRGPDModel);
        }}
        defaultChecked={evenement?.estTelesuivi}
      />
    </SwitchWrapper>
    <SwitchWrapper>
      <Label htmlFor="estEnvoiInformation" selectMode="dark">
        Envoi information
      </Label>
      <Switch
        id="estEnvoiInformation"
        onChange={(checked: boolean) => {
          onChange({
            ...evenement,
            estEnvoiInformation: checked,
          } as EvenementRGPDModel);
        }}
        defaultChecked={evenement?.estEnvoiInformation}
      />
    </SwitchWrapper>
    <SwitchWrapper>
      <Label htmlFor="estProgrammeRecherche" selectMode="dark">
        Programme de recherche
      </Label>
      <Switch
        id="estProgrammeRecherche"
        onChange={(checked: boolean) => {
          onChange({
            ...evenement,
            estProgrammeRecherche: checked,
          } as EvenementRGPDModel);
        }}
        defaultChecked={evenement?.estProgrammeRecherche}
      />
    </SwitchWrapper>
    <SwitchWrapper>
      <Label htmlFor="estEnquete" selectMode="dark">
        Enquete
      </Label>
      <Switch
        id="estEnquete"
        onChange={(checked: boolean) => {
          onChange({
            ...evenement,
            estEnquete: checked,
          } as EvenementRGPDModel);
        }}
        defaultChecked={evenement?.estEnquete}
      />
    </SwitchWrapper>
  </Details>
);
