import {
  ListFilterTemplate,
  ListProps,
  ListViewService,
  useListFilter
} from "@ads/react-common-module";
import { ErrorView } from "components-v2/error/ErrorView";
import { useHistory } from "react-router-dom";
import { ProgrammeEducatifListDecorator } from "./ProgrammeEducatifListDecorator";
import {
  PEClickItemID,
  ProgrammeEducatifListItemAdapter
} from "./ProgrammeEducatifListItemAdapter";
import { ProgrammeEducatifModel } from "@ads/isadom-model";
import { useGetPEListByVisiteID } from "../../features/useGetPEListByVisiteID";
import { useDeletePEByVisiteIDAndID } from "../../features/useDeletePEByVisiteIDAndID";
import { ProgrammeEducatifFetchLoadingView } from "./ProgrammeEducatifFetchLoadingView";
import { useRaZPE } from "../../features/useRaZPe";

export interface ProgrammeEducatifVisiteListTemplateProps {
  visiteID: number;
}

export interface ProgrammeEducatifVisiteListViewService
  extends ListViewService<PEClickItemID> {
  onRAZClick(): void;
}

export function ProgrammeEducatifVisiteListTemplate({
  visiteID
}: ProgrammeEducatifVisiteListTemplateProps) {
  const history = useHistory();

  const razService = useRaZPE({ visiteID });
  const deleteOneService = useDeletePEByVisiteIDAndID();

  const service = useListFilter({
    fetchService: useGetPEListByVisiteID(visiteID),
    match(filter, data) {
      return filter
        ? filter === data.typeProgrammeEducatif.etpID.toString()
        : true;
    }
  });

  function handleClickItem(id: PEClickItemID) {
    history.push(
      `/visites/${visiteID}/programmes/programmes-educatifs/${id.id}?source=${id.source}`
    );
  }

  async function handleRAZ() {
    await razService.delete({ visiteID });
  }

  async function handleItemDelete(id: PEClickItemID) {
    deleteOneService.delete({
      visiteID: visiteID,
      peID: id.id
    });
  }

  const listProps: Omit<
    ListProps<
      ProgrammeEducatifModel,
      PEClickItemID,
      ProgrammeEducatifVisiteListViewService
    >,
    "data"
  > = {
    itemViewAdapter: ProgrammeEducatifListItemAdapter,
    className: "ProgrammeEducatif-list",
    services: {
      forceDisplayFetchLoading: razService.loading || deleteOneService.loading,
      onItemClick: handleClickItem,
      onRAZClick: handleRAZ,
      OnItemDeleteClick: handleItemDelete
    }
  };

  return (
    <ListFilterTemplate
      errorView={
        <ErrorView message="Erreur lors du chargement de la liste des programmes éducatifs." />
      }
      loadingView={<ProgrammeEducatifFetchLoadingView visiteID={visiteID} />}
      listDecorator={ProgrammeEducatifListDecorator}
      service={service}
      listProps={listProps}
    />
  );
}
