import styled from "styled-components";
import { 
  Annulation, 
  Btn, 
  HeaderCard,
  DateAccord, 
  DateDebut, 
  DateFin, 
  Decision,
  Information, 
  Status, 
  TypeEvenement, 
  Validation 
} from "../../atoms/itemFields";

const TypeEvenementBold = styled(TypeEvenement)`
  font-weight: bold;
`;

export const EvenementTelesuiviListHeader = () => (
  <HeaderCard>
    <Status />
    <TypeEvenementBold>Type d'évènement</TypeEvenementBold>
    <DateDebut>Du</DateDebut>
    <DateFin>au</DateFin>
    <Information>Informations</Information>
    <Decision>Décision</Decision>
    <DateAccord>Date accord</DateAccord>
    <Validation>Validé</Validation>
    <Annulation>Annulé</Annulation>
    <Btn />
  </HeaderCard>
);
