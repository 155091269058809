import styled from "styled-components";

export const TableWrapper = styled.div`
  th {
    font-family: ${({ theme }) => theme.font.type.primaryMedium};
    text-transform: uppercase;
    ${({ theme }) => theme.media.sm} {
      font-size: 0.9rem;
      font-style: bold;
    }
    ${({ theme }) => theme.media.md} {
      font-size: 1.2rem;
      font-style: bold;
    }
  }

  .center-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-wrapper {
    box-shadow: 1px -0.4rem 1.2rem rgb(80 70 70 / 21%);
    border-radius: 0.6rem;
  }

  .ant-tooltip-content {
    padding: 2rem 2rem;
  }

  .ant-tooltip-inner {
    padding: 2rem 2rem;
  }

  ${({ theme }) => theme.media.md} {
    display: inherit;
    margin-top: 4rem;

    .preponderance {
      text-align: center;
    }

    .refProduit {
      padding-left: 3rem !important;
    }

    .ant-table-thead > tr > th {
      background-color: ${({ theme }) => theme.colors.neutral[100]};
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background: none;
    }

    .ant-table-tbody > tr > td {
      font-size: 1.4rem;
      line-height: 2rem;
      padding: 1.6rem 0.8rem;
    }
  }
`;