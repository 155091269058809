import { ProgrammeEducatifModel } from "@ads/isadom-model";
import {
  ListFilterAdapterProps,
  ListFilterHookService,
  removeDuplicateFromArray
} from "@ads/react-common-module";
import { HeaderList } from "components/core/ui/headerList/ui/HeaderListNomad";
import { RouteParam } from "interface/core/RouteParam";
import { useHistory, useParams } from "react-router-dom";
import { PEClickItemID } from "./ProgrammeEducatifListItemAdapter";
import { ProgrammeEducatifVisiteListViewService } from "./ProgrammeEducatifVisiteListTemplate";

export interface ProgrammeEducatifListDecoratorProps
  extends ListFilterAdapterProps<
    ProgrammeEducatifModel,
    string,
    PEClickItemID,
    ListFilterHookService<ProgrammeEducatifModel, string>,
    ProgrammeEducatifVisiteListViewService
  > {}

export function ProgrammeEducatifListDecorator({
  listView,
  service,
  uiService
}: ProgrammeEducatifListDecoratorProps) {
  const { iPKVisite } = useParams<RouteParam>();

  function makeOptions(data: ProgrammeEducatifModel[]) {
    const array = data.map((value) => {
      return {
        value: value.typeProgrammeEducatif.etpID.toString(),
        label: value.typeProgrammeEducatif.libelle
      };
    });

    return removeDuplicateFromArray(
      array,
      (item1, item2) => item1.value === item2.value
    );
  }

  const history = useHistory();

  return (
    <>
      <HeaderList
        handleAddClick={() => {
          history.push(
            `/visites/${iPKVisite}/programmes/programmes-educatifs/create`
          );
        }}
        handleDelAllClick={
          service.data?.length ? uiService.onRAZClick : undefined
        }
        nomModuleSingulier={"Programme éducatif"}
        nomModulePluriel={"Programmes éducatifs"}
        lenghtList={service.dataFiltered ? service.dataFiltered.length : 0}
        filtersList={[
          {
            libelle: "Filtrer par type de programme éducatif",
            items: service.data ? makeOptions(service.data) : [],
            handleChange: service.filter
          }
        ]}
      />

      <br />
      {listView}
    </>
  );
}
