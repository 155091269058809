import styled from "styled-components";
import { EvenementTelesuiviModel } from "@ads/isadom-model";
import {
  EvenementTelesuiviDetails,
  EvenementTelesuiviInformations,
  EvenementTelesuiviTableauAppareils,
} from "../../molecules/evenementTelesuivi";

const Wrapper = styled.div`
  display: block;
  width: 100%;
  max-width: 80rem;

  ${({ theme }) => theme.media.md} {
    display: flex;
  }
`;

interface EvenementTelesuiviInformationsGeneralesProps {
  evenement?: EvenementTelesuiviModel;
}

export const EvenementTelesuiviInformationsGenerales = ({
  evenement,
}: EvenementTelesuiviInformationsGeneralesProps) => {
  return (
    <Wrapper>
      <EvenementTelesuiviInformations
        informations={evenement ? evenement.informations : ""}
      />
      <EvenementTelesuiviDetails evenement={evenement} />
      <EvenementTelesuiviTableauAppareils evenement={evenement} />
    </Wrapper>

  );
};
