import styled from "styled-components";
import { TiersModel } from "@ads/isadom-model";
import { Label } from "@ads/ui";
import SubTitleForm from "components/ui/atoms/titles/SubTitleForm";
import { getDateFr } from "utils/convertDateTime";
import { TypeVisiteTypePrestaList } from "../../TypeVisiteTypePrestaList/ui/TypeVisiteTypePrestaList";
import { IInfoRdvVisiteHookService } from "../hook/interfaces/IInfoRdvVisiteHookService";

export interface InfoRdvVisiteProps {
  libelle: "Rendez-vous" | "Visite";
  services: IInfoRdvVisiteHookService;
}

const WrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0;
  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    gap: 8px;
  }
`;

const InfoItem = styled.p`
  display: inline-block;
  font-weight: 600;
  color: #4d4848;
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin-bottom: 0.5rem;
  min-width: 15rem;
`;

export function InfoRdvVisite({ services, libelle }: InfoRdvVisiteProps) {
  return (
    <>
      <SubTitleForm>
        Informations {libelle === "Rendez-vous" ? "du " : "de la "}{" "}
        {libelle.toLowerCase()}
      </SubTitleForm>

      {/*=======================
                    Date 
        =======================*/}
      {services.rdvOrVisite?.date &&
        services.rdvOrVisite?.date !== "0000-00-00" && (
          <WrapperItem>
            <InfoItem>{libelle} le</InfoItem>
            {services.rdvOrVisite?.date &&
              getDateFr(services.rdvOrVisite?.date)}{" "}
            à {services.rdvOrVisite?.hDebut.substring(0, 5)}
          </WrapperItem>
        )}
      {/*=======================
                Tiers visité 
        =======================*/}
      <InfoTiers
        tiers={services.rdvOrVisite?.tiersVisite}
        label="Tiers visité"
      />
      {/*=======================
           Intervenant prinicpal 
        =======================*/}
      <InfoTiers
        tiers={services.rdvOrVisite?.intervenantPrincipal}
        label="Intervenant principal"
      />
      {/*=======================
           Intervenant secondaire 
        =======================*/}
      <InfoTiers
        tiers={
          services.rdvOrVisite?.tIntervenantsAutre &&
            services.rdvOrVisite?.tIntervenantsAutre?.length > 0
            ? services.rdvOrVisite?.tIntervenantsAutre[0]
            : undefined
        }
        label="Intervenant secondaire"
      />
      {/*=============================================
           Types de visites / Types de prestation 
        ============================================*/}
      {services.serviceTypeVisiteTypePrestaList.typesVisites.length > 0 && (
        <>
          <Label htmlFor="TV/TP">Types de visites / Types de prestations</Label>
          <TypeVisiteTypePrestaList
            services={services.serviceTypeVisiteTypePrestaList}
            isDeletable={false}
          />
        </>
      )}
      {/*=============================
           Information pour la visite 
        ==============================*/}
      <Label htmlFor="InfoVisite">Informations</Label>
      <div>{services.rdvOrVisite?.sInformation}</div>
    </>
  );
}

interface InfoTiersProps {
  label: string;
  tiers?: TiersModel;
}

export function InfoTiers({ tiers, label }: InfoTiersProps) {
  return (
    <WrapperItem>
      {tiers?.nom && (
        <>
          <InfoItem>{label}</InfoItem>
          {tiers?.typeTiers} {tiers?.nom} {tiers?.prenom} {tiers?.codeTiers}
        </>
      )}
    </WrapperItem>
  );
}
