import { FC, useRef } from "react";
import styled from "styled-components";
import WrapperBlur from "../organisms/wrappers/WrapperBlur";
import WrapperMain from "../organisms/wrappers/WrapperMain";
import WrapperMainContent from "../organisms/wrappers/WrapperMainContent";
import FiltresTournee from "modules/feuille-de-route/ui/molecules/FiltresTournee";
import { IconsV3, MultiRollingPanel } from "@ads/ui";
import { HeaderFeuilleDeRoute } from "components-v2/header/HeaderFeuilleDeRoute";
import WrapperApp from "../organisms/wrappers/WrapperApp";
import { v4 } from "uuid";

const WrapperPanelLeft = styled("div")`
  position: relative;
  z-index: 200;
  display: block;
  top: 4rem;
  max-width: calc(100vw - 5.4rem);
  height: 100%;
  ${({ theme }) => theme.media.xl} {
    top: 4rem;
  }
`;

interface PropsTemplateFeuilleDeRoute {
  navigation: JSX.Element;
}

/**
 * @desc affiche les volets déroulants
 */
const TemplateFeuilleDeRoute: FC<PropsTemplateFeuilleDeRoute> = ({
  children,
  navigation
}) => {
  const refWrapperMainContent = useRef<HTMLDivElement>(null);
  const refWrapperBur = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  return (
    <WrapperApp>
      <HeaderFeuilleDeRoute navigation={navigation} />
      <WrapperMain className="wrapper-main">
        <WrapperBlur className="wrapper-blur" ref={refWrapperBur}>
          <WrapperPanelLeft ref={refWrapper} className="info-panel-left">
            <MultiRollingPanel
              panels={[
                {
                  key: v4(),
                  icon: <IconsV3.Calendar />,
                  children: <FiltresTournee />
                }
              ]}
              onToggle={(isOpen) => {
                if (isOpen) {
                  refWrapperMainContent?.current?.classList.add("isOpenLeft");
                } else {
                  refWrapperMainContent?.current?.classList.remove(
                    "isOpenLeft"
                  );
                }
              }}
            />
          </WrapperPanelLeft>

          <WrapperMainContent
            id="wrapper-main-content"
            ref={refWrapperMainContent}
            className="wrapper-main-content"
          >
            {children}
          </WrapperMainContent>
        </WrapperBlur>
      </WrapperMain>
    </WrapperApp>
  );
};

export default TemplateFeuilleDeRoute;
