import { useEffect, useState } from "react";
import moment from "moment";
import { VisiteModel } from "@ads/isadom-model";
import { BaseError, ERROR_UNKNOWN } from "@ads/react-common-module";

export interface IFinVisiteHookInput {
  visite: VisiteModel | undefined;
}

export interface IFinVisiteHookService {
  visite: VisiteModel | undefined;
  notifyDateFinChange(dFin: Date): void;
  notifyHeureFinChange(hFin: Date): void;
  notifyDateHeureFinChange(dhFin: Date): void;
  notifySyntheseChange(synthese: string): void;
  notifyValidateVisite(validate: boolean): void;
}

export function useFinVisite(
  input: IFinVisiteHookInput
): IFinVisiteHookService {
  const [visite, setVisite] = useState(input.visite);

  function notifyDateFinChange(dFin: Date) {
    if (visite) {
      setVisite({
        ...visite,
        dFin: moment(dFin).format("YYYY-MM-DD"),
      });
    }
  }

  function notifyHeureFinChange(hFin: Date) {
    if (visite) {
      setVisite({
        ...visite,
        hFin: moment(hFin).format("HH:mm:00.000"),
      });
    } else {
      throw new BaseError(ERROR_UNKNOWN);
    }
  }

  function notifyDateHeureFinChange(dhFin: Date) {
    if (visite) {
      setVisite({
        ...visite,
        dFin: moment(dhFin).format("YYYY-MM-DD"),
        hFin: moment(dhFin).format("HH:mm:00.000"),
      });
    } else {
      throw new BaseError(ERROR_UNKNOWN);
    }
  }

  function notifySyntheseChange(synthese: string) {
    if (visite) {
      setVisite({
        ...visite,
        sInformation: synthese,
      });
    } else {
      throw new BaseError(ERROR_UNKNOWN);
    }
  }

  function notifyValidateVisite(validate: boolean): void {
    if (visite) {
      setVisite({
        ...visite,
        bValide: validate,
      });
    } else {
      throw new BaseError(ERROR_UNKNOWN);
    }
  }

  useEffect(() => {
    if (visite && (!visite.dFin || !visite.hFin)) {
      const currentDate = moment();
      const newVisite = visite;
      if (!newVisite.dFin) {
        newVisite.dFin = currentDate.format("YYYY-MM-DD");
      }
      if (!newVisite.hFin) {
        newVisite.hFin = currentDate.format("HH:mm:00.000");
      }
      setVisite(newVisite);
    }
  }, [visite]);

  return {
    visite,
    notifyDateFinChange,
    notifyHeureFinChange,
    notifyDateHeureFinChange,
    notifySyntheseChange,
    notifyValidateVisite,
  };
}
