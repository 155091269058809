var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import { Modal, notification } from "antd";
import { Icons, PrimaryButton, theme, Filtre } from "@ads/ui";
import { useApi } from "@ads/react-common-module";
import { apiRaZMessages } from "../../api/apiRaZMessages";
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.xl} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }
`;
const WrapperButtonAjout = styled(Link) `
  min-width: 4rem;
  margin: 1rem 0;
  ${({ theme }) => theme.media.xl} {
    margin: 0;
  }
`;
const WrapperTitre = styled.div `
  margin-bottom: 2rem;
  white-space: nowrap;
  font-size: 2.2rem;
  ${({ theme }) => theme.media.xl} {
    margin: 0;
  }
`;
const WrapperButtonReset = styled.div `
  //min-width: 3rem;
`;
const WrapperButtons = styled.div `
  order: 1;
  display: flex;
  gap: 2rem;
  ${({ theme }) => theme.media.md} {
    order: 2;
  }
`;
const WrapperFiltre = styled.div `
  width: 100%;
  order: 2;
  ${({ theme }) => theme.media.md} {
    width: 32rem;
    order: 1;
  }
`;
const ContentHeader = styled.div `
  display: flex;
  flex-direction: column;
  gap: 2rem;
  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const HeaderListeMessage = ({ items, setMessageListFilters, selection, }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { iPKVisite } = useParams();
    const typeObjetsID = items.map((item) => item.typeObjet.ID);
    const itemsSansDoublon = items.filter((item, index) => typeObjetsID.indexOf(item.typeObjet.ID) === index);
    const typeObjets = itemsSansDoublon === null || itemsSansDoublon === void 0 ? void 0 : itemsSansDoublon.map((item) => ({
        value: item.typeObjet.ID,
        label: item.typeObjet.libelle,
    }));
    const api = useApi();
    const history = useHistory();
    const handleConfirmRaZ = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield apiRaZMessages(api, +iPKVisite);
            history.push(`/visites/${iPKVisite}/messages/`);
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: "Les messages n'ont pas pu être supprimés.",
                duration: theme.duration.notification,
            });
        }
    });
    const typesObjets = [];
    items.forEach((message) => {
        if (typesObjets.find((typeObjet) => typeObjet.ID === message.typeObjet.ID) ===
            undefined) {
            typesObjets.push(message.typeObjet);
        }
    });
    const handleChange = (value) => {
        if (typeof value === "undefined") {
            setMessageListFilters(undefined);
        }
        else {
            const typeObjet = typesObjets.find((t) => t.ID === value);
            if (typeObjet) {
                setMessageListFilters(typeObjet);
            }
        }
    };
    return (_jsxs(Wrapper, { children: [_jsxs(WrapperTitre, { children: [(items === null || items === void 0 ? void 0 : items.length) === 0 && `Aucun message à envoyer`, (items === null || items === void 0 ? void 0 : items.length) === 1 && `1 message à envoyer`, (items === null || items === void 0 ? void 0 : items.length) > 1 && `${items === null || items === void 0 ? void 0 : items.length} messages à envoyer`] }, void 0), _jsxs(ContentHeader, { children: [_jsx(WrapperFiltre, { children: _jsx(Filtre, { items: typeObjets, libelle: "Filtrer par type d'objet", handleChange: handleChange }, void 0) }, void 0), selection !== "effectue" && (_jsxs(WrapperButtons, { children: [_jsx(WrapperButtonAjout, Object.assign({ to: `/visites/${iPKVisite}/messages/nouveau` }, { children: _jsx(PrimaryButton, { as: "button", iconPosition: "left", icon: Icons.AddButton, type: "button", label: "Ajouter un message", buttonStyle: "light", size: "S" }, void 0) }), void 0), (items === null || items === void 0 ? void 0 : items.length) > 0 && (_jsxs(_Fragment, { children: [_jsx(WrapperButtonReset, { children: _jsx(PrimaryButton, { as: "button", iconPosition: "left", type: "button", label: "R\u00E0Z", buttonStyle: "light", size: "S", onClick: () => setIsModalVisible(true) }, void 0) }, void 0), _jsxs(Modal, Object.assign({ mask: false, visible: isModalVisible, onOk: () => {
                                            handleConfirmRaZ();
                                            setIsModalVisible(false);
                                        }, onCancel: () => {
                                            setIsModalVisible(false);
                                        } }, { children: ["Attention la suppression de l'ensemble des messages est irr\u00E9versible.", _jsx("br", {}, void 0), " Souhaitez-vous continuer ?"] }), void 0)] }, void 0))] }, void 0))] }, void 0)] }, void 0));
};
export default HeaderListeMessage;
