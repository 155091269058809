import styled from "styled-components";
import {
  Breadcrumb,
  FormItemWrapper,
  FormWidget,
  Label,
  Textarea,
} from "@ads/ui";
import { VisiteModel } from "@ads/isadom-model";

import { Content } from "../atoms/Content";
import { useObservationsFinVisite } from "modules/fin-de-visite/hook/useObservationsFInVisite";
import TitleMain from "components/ui/atoms/titles/TitleMain";

interface TemplateObservationsInternesProps {
  visite: VisiteModel;
}

const WrapperObservationTypeVisitePrestation = styled.div`
  margin-top: 2rem;
`;

export const TemplateObservationsInternes = ({
  visite,
}: TemplateObservationsInternesProps) => {
  const breadcrumbItems = [
    {
      item: `Fin de visite`,
      to: `/visites/${visite.ID}/fin-de-visite`,
    },
    {
      item: `Observations internes`,
      to: `/visites/${visite.ID}/fin-de-visite/observations-internes`,
    },
  ];

  const service = useObservationsFinVisite({ visite });

  return (
    <FormWidget
      handleSave={() => service.saveVisite(service.visite)}
      isLoadingSave={service.saveLoading}
    >
      <Breadcrumb size="M" breadcrumbItems={breadcrumbItems} />
      {service.visite.tTypeVisite.map((typeVisite) => {
        let typeVisiteFormated = typeVisite.sLibelle.toLowerCase();
        if (typeVisiteFormated.charAt(typeVisiteFormated.length - 1) === ".") {
          typeVisiteFormated = typeVisiteFormated.substring(
            0,
            typeVisiteFormated.length - 1
          );
        }
        return (
          <Content>
            <FormItemWrapper direction="vertical">
              <TitleMain>{`Visite ${typeVisiteFormated}`}</TitleMain>
              {typeVisite.tTypePrestation &&
                typeVisite.tTypePrestation.length > 0 &&
                typeVisite.tTypePrestation.map((typePrestation) => {
                  return (
                    <WrapperObservationTypeVisitePrestation>
                      <Label htmlFor={"type-prestation-" + typePrestation.id}>
                        {typePrestation.libelle} :
                      </Label>
                      <Textarea
                        id={"type-prestation-" + typePrestation.id}
                        name={"type-prestation-" + typePrestation.id}
                        value={
                          typePrestation.informationContext
                            ? typePrestation.informationContext
                            : ""
                        }
                        onChange={(e: any) =>
                          service.notifyObservationTypePrestationChange(
                            typeVisite.sPKTypeVisite,
                            typePrestation.id,
                            e.target.value
                          )
                        }
                      />
                    </WrapperObservationTypeVisitePrestation>
                  );
                })}
              {!typeVisite.tTypePrestation ||
                (typeVisite.tTypePrestation.length === 0 && (
                  <WrapperObservationTypeVisitePrestation>
                    <Textarea
                      id={"type-prestation-" + typeVisite.sPKTypeVisite}
                      name={"type-prestation-" + typeVisite.sPKTypeVisite}
                      value={
                        typeVisite.informationContext
                          ? typeVisite.informationContext
                          : ""
                      }
                      onChange={(e: any) =>
                        service.notifyObservationTypeVisiteChange(
                          typeVisite.sPKTypeVisite,
                          e.target.value
                        )
                      }
                    />
                  </WrapperObservationTypeVisitePrestation>
                ))}
            </FormItemWrapper>
          </Content>
        );
      })}
    </FormWidget>
  );
};
