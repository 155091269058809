import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormWidget } from "@ads/ui";
import styled from "styled-components";
import ContentReglageDesktop from "./ContentReglageDesktop";
import {
  ModesReglagesAppareil,
  PayloadGrille,
  PayloadReglage,
  PayloadReglageValeur,
  PayloaGrilles,
} from "modules/appareils/interfaces/modes-reglages.interfaces";
import { RouteParam } from "interface/core/RouteParam";
import usePostGrilles from "modules/appareils/hooks/usePostGrilles";
import errorAdapter from "utils/errorAdapter";
import { notification } from "antd";
import { v4 } from "uuid";
import useWindowSize from "../../../../hooks/useWindowSize";
import nomadTheme from "../../../../utils/nomad-theme";

const FormWidgetReglage = styled(FormWidget)`
  &.form-widget-background {
    background-color: transparent;
    box-shadow: none;
  }
`;

interface Props {
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
  shouldRefetch: boolean;
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}
/**
 * Partie inférieure de la page modes de réglages. Contient deux parties, un formulaire de filtre et une partie contenue.
 * @returns
 */

const ContentReglage: FC<Props> = ({
  modesReglagesAppareil,
  setModesReglagesAppareil,
  shouldRefetch,
  setShouldRefetch,
}) => {
  const { IDProduit: appareilId, iPKVisite: visiteId } =
    useParams<RouteParam>();

  const { isLoading, postGrilles, errors } = usePostGrilles(
    visiteId,
    appareilId,
    shouldRefetch,
    setShouldRefetch
  );

  // Affichage des erreurs
  useEffect(() => {
    if (errors) {
      errorAdapter(errors)?.forEach((error: any) => {
        notification.error({
          key: v4(),
          message: "Erreur",
          description: error.message || "Erreur interne du serveur",
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleSubmit = () => {
    const modeReglageAppareil = modesReglagesAppareil.modes.find(
      (modeReglageAppareil) =>
        modeReglageAppareil.reglageModeID === modesReglagesAppareil.modeActif
    );

    const modeSaisiReglageAppareil =
      modesReglagesAppareil.modesSaisis.find(
        (modeReglageAppareil) =>
          modeReglageAppareil.reglageModeID === modesReglagesAppareil.modeActif
      ) || modeReglageAppareil; // Si aucune saisie n'a été faite et que l'on souhaite enregistrer

    if (!modeReglageAppareil || !modeSaisiReglageAppareil) {
      if (!modeSaisiReglageAppareil) {
        // eslint-disable-next-line no-console
        console.error(
          "👨‍🚒 modeSaisiReglageAppareil est falsy",
          modeSaisiReglageAppareil
        );
      }
      return;
    }

    const payloadInit: Omit<PayloaGrilles, "tabGrille"> = {
      reglageProduitModeId: modeReglageAppareil.reglageProduitModeID,
      reglageModeID: modeReglageAppareil.reglageModeID,
      familleID: modesReglagesAppareil.idFamille,
      modeleID: modesReglagesAppareil.idModele,
      typePrestationID: modesReglagesAppareil.idPrestation,
      sInformation: modeSaisiReglageAppareil.sInformation,
    };

    const tabGrille = modeSaisiReglageAppareil?.tabGrille.map((grille) => {
      const grilleBackInit = {
        sIdRegroupement: "",
        sLibelle: grille.sLibelle,
        reglageModeID: modeReglageAppareil.reglageModeID,
      };

      const reglages = grille.tabReglage.map((ligneGrille) => {
        const reglageInit: Omit<PayloadReglage, "tabReglageValeur"> = {
          sCode: ligneGrille.sCode,
          sLibelle: ligneGrille.sLibelle,
          bModeHoraire: ligneGrille.bModeHoraire,
          bTheoriqueAppareil: ligneGrille.bTheoriqueAppareil,
        };

        const tabReglageValeur = ligneGrille.tabReglageValeur.map(
          (reglageValeur) => {
            const payloadReglageValeur: PayloadReglageValeur = {
              reglageID: reglageValeur.reglageID,
              sTypeReglage: reglageValeur.sTypeReglage,
              sValeur: reglageValeur.sValeur,
              iCPNautReglageGrille: reglageValeur.iCPNautReglageGrille,
              sIDRegroupement: reglageValeur.sIDRegroupement,
              tabReglageValeurHoraire: reglageValeur.tabReglageValeurHoraire,
            };

            return payloadReglageValeur;
          }
        );

        const reglage: PayloadReglage = {
          ...reglageInit,
          tabReglageValeur,
        };

        return reglage;
      });

      const grilleBackComplete: PayloadGrille = {
        ...grilleBackInit,
        tabReglage: reglages,
      };

      return grilleBackComplete;
    });

    const payload: PayloaGrilles = {
      ...payloadInit,
      tabGrille: tabGrille,
    };
    postGrilles(payload);
  };

  const [windowWith] = useWindowSize();

  const offsetX = windowWith > parseInt(nomadTheme.breakpoints.md) ? -20 : -10;

  return (
    <>
      <FormWidgetReglage
        handleSave={handleSubmit}
        offsetX={offsetX}
        locked={false}
        isLoadingSave={isLoading}
      >
        <ContentReglageDesktop
          modesReglagesAppareil={modesReglagesAppareil}
          setModesReglagesAppareil={setModesReglagesAppareil}
        />
      </FormWidgetReglage>
    </>
  );
};

export default ContentReglage;
