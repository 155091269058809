import styled from "styled-components";
import { ConfigAppareilSpecTiersVisiteAPI, VisiteAPI } from "@ads/isadom-model";
import AppareilItem from "../molecules/AppareilItem";
import { Dispatch, SetStateAction } from "react";
import { PropsFilters } from "../templates/TemplateConfiguration";

const WrapperAppareils = styled("div")`
  margin-top: 3rem;
`;
interface ListeAppareilsProps {
  appareilsAbsents: ConfigAppareilSpecTiersVisiteAPI[];
  items: ConfigAppareilSpecTiersVisiteAPI[];
  visite: VisiteAPI | undefined;
  filtresAppareils: PropsFilters;
  setFiltresAppareils: Dispatch<SetStateAction<PropsFilters>>
}

const ListeAppareils = ({
  items: configAppareils,
  appareilsAbsents,
  visite,
  filtresAppareils,
  setFiltresAppareils
}: ListeAppareilsProps) => {

  return (
    <WrapperAppareils>
      {configAppareils.map(
        (configurationProduitSpec: ConfigAppareilSpecTiersVisiteAPI) => (
          <AppareilItem
            configurationProduitSpec={configurationProduitSpec}
            appareilsAbsents={appareilsAbsents}
            key={configurationProduitSpec.produit.IDProduit}
            filtresAppareils={filtresAppareils}
            setFiltresAppareils={setFiltresAppareils}
            visite={visite}
          />
        )
      )}
    </WrapperAppareils>
  );
};

export default ListeAppareils;
