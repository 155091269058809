import { useEffect, useState } from "react";
import { BaseError, ERROR_UNKNOWN } from "../error";
export function useDataListFilter({ data, match, }) {
    const [dataFiltered, setDataFiltered] = useState(data);
    function filter(filter) {
        if (data) {
            const array = [];
            for (const item of data) {
                if (match(filter, item)) {
                    array.push(item);
                }
            }
            setDataFiltered(array);
        }
        else {
            throw new BaseError(ERROR_UNKNOWN);
        }
    }
    useEffect(() => {
        setDataFiltered(data);
    }, [data]);
    return {
        data: dataFiltered,
        filter,
    };
}
