import { FamilleAdapter } from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";
export function apiGetAllFamilles() {
    const api = getDefaultApiInstance();
    if (api) {
        const adapter = new FamilleAdapter();
        return apiGet(api, "/familles", adapter);
    }
    throw new Error("La config d'api par défaut n'est pas initialisé");
}
