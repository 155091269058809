import styled from "styled-components";

const WrapperStyle = styled("div")`
  display: flex;
  .a {
    fill: none;
    stroke: ${(props) => props.theme.colors.neutral[100]};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.25rem;
  }
`;

const IconLoupe = () => (
  <WrapperStyle>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 19.193 25.029"
    >
      <g transform="translate(1.25 1.25)">
        <circle className="a" cx="7.672" cy="7.672" r="7.672" />
        <line
          className="a"
          x2="4.254"
          y2="6.961"
          transform="translate(11.971 15.1)"
        />
      </g>
    </svg>
  </WrapperStyle>
);

export default IconLoupe;
