export const tableSelectionConsommableColumns = [
    {
        title: "Modèle",
        dataIndex: "libelle",
        key: "libelle",
        getValue: (item) => {
            return `${item.libelle}`;
        },
    },
    {
        title: "Type de produit",
        /*
        dataIndex prend en paramètre un tableau de string
        pour pouvoir accéder aux paramètres de l'objet passé en props
        */
        dataIndex: ["libelleTypeProduit"],
        key: "libelleTypeProduit",
        getValue: (item) => {
            return `${item.libelleTypeProduit}`;
        },
    },
    {
        title: "Famille",
        dataIndex: ["libelleFamille"],
        key: "libelleFamille",
        getValue: (item) => {
            return `${item.libelleFamille}`;
        },
    },
];
