import styled from "styled-components";
import { LigneCommandeVisiteModel } from "@ads/isadom-model";

import CommandeItemAffectation from "../molecules/CommandeItemAffectation";
import CommandeItemDateLivraisonSouhaitee from "../molecules/CommandeItemDateLivraisonSouhaitee";
import CommandeItemHeader from "../molecules/CommandeItemHeader";
import CommandeItemPrestation from "../molecules/CommandeItemPrestation";
import CommandeItemQuantite from "../molecules/CommandeItemQuantite";
import CommandeItemIndependante from "../molecules/CommandeItemIndependante";

const WrapperItem = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  padding: 1rem;
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.2rem solid transparent;

  ${({ theme }) => theme.media.xl} {
    margin-top: 0;
  }
`;

const ItemTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;

const ItemBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;

const TopLeft = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  ${({ theme }) => theme.media.md} {
    width: 30%;
    margin-bottom: 0;
  }
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  ${({ theme }) => theme.media.sm} {
    flex-wrap: nowrap;
  }
  ${({ theme }) => theme.media.md} {
    justify-content: flex-end;
    margin-top: 0;
    width: 70%;
  }
`;

interface ListCommandItemProps {
  item: LigneCommandeVisiteModel;
}

const ListCommandItem = ({ item }: ListCommandItemProps) => (
  <WrapperItem>
    <ItemTop>
      <TopLeft>
        <CommandeItemHeader item={item} />
      </TopLeft>
      <TopRight>
        <CommandeItemQuantite item={item} />
        <CommandeItemAffectation item={item} />
        <CommandeItemDateLivraisonSouhaitee item={item} />
      </TopRight>
    </ItemTop>
    <ItemBottom>
      <CommandeItemPrestation item={item} />
      <CommandeItemIndependante item={item} />
    </ItemBottom>
  </WrapperItem>
);

export default ListCommandItem;
