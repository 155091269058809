import { Collapse, Tag } from "antd";
import styled from "styled-components";
const { Panel } = Collapse;
export const CustomPanel = styled(Panel) `
  .ant-collapse-header {
    padding: 0 !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;
export const Nomination = styled.span `
  font-weight: 700;
  font-size: 1.6rem;
`;
export const SectionTitle = styled.p `
  font-weight: 700;
  margin-left: 0.6rem;
  margin-top: 1.6rem;
`;
export const Separateur = styled.hr `
  background-color: ${({ theme }) => theme.colors.neutral[120]};
  border: none;
  margin: 0.6rem 0;
  height: 1px;
`;
export const DateNaissance = styled.div `
  font-weight: 700;
  font-style: italic;
  font-size: 1.2rem;
`;
export const Alerte = styled.div `
  font-weight: 700;
  font-style: italic;
  font-size: 1.2rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  margin-bottom: 0.6rem;
`;
export const InformationsPatient = styled.div `
  padding: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
`;
export const AdressesPatient = styled.div `
  padding-bottom: 1rem;
`;
export const CustomTag = styled(Tag) `
  background: ${({ theme }) => theme.colors.neutral[120]};
  margin-bottom: 0.6rem;
`;
export const IconAlert = styled.img `
  width: 2.2rem;
  height: 2.2rem;
  margin-left: 0.2rem;
`;
export const ContentFichePatient = styled.div `
  padding: 1rem 2rem;
  overflow-y: auto;
  /* max-height: calc(100vh - 7rem); */
  padding: 1rem 2rem;
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutral[120]};
    border: 3px solid transparent;
    border-radius: 4px;
  }
`;
