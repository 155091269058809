import { useState } from "react";
import { useTableFormSelectionProduit } from "../../../../produit";
import { useFormSelectionModeleItem } from "../../../../modele";
import { SelectionProduitItemListener } from "../../../../produit/component/selection/hook/interface/impl/SelectionProduitItemListener";
import { useFormSelectionLocalisationItem } from "../../../../localisation/component/selection/hook/useFormSelectionLocalisationItem";
import { ParamDisponibilite } from "./interface/IParamDisponibilite";
import { useFormTypeLocalisationItem, } from "../../../../type-localisation";
const ID = "@ads/1/useFormSelectionAppareil";
export function useFormSelectionAppareil(input) {
    /****************************************/
    /***************LOCAL VAR****************/
    /****************************************/
    var _a, _b;
    const defaultDisponibilite = input.disponibiliteConfig.defaultSelectedData.length > 0
        ? input.disponibiliteConfig.defaultSelectedData[0]
        : ParamDisponibilite.tous;
    /****************************************/
    /**************LOCAL STATE***************/
    /****************************************/
    const [codeOrRef, setCodeOrRef] = useState("");
    const [disponibilite, setDisponibilite] = useState(defaultDisponibilite);
    /****************************************/
    /******************HOOK******************/
    /****************************************/
    const servicesProduit = useTableFormSelectionProduit(input);
    const serviceModele = useFormSelectionModeleItem({
        config: input.modeleConfig,
        initParams: {
            natures: servicesProduit.natureSelectionService.config.defaultSelectedData.map((nature) => nature.id),
            typeProduitID: (_a = servicesProduit.typeProduitSelectionService.config
                .defaultSelectedData[0]) === null || _a === void 0 ? void 0 : _a.typeProduitId,
        },
    });
    const serviceTypeLocalisation = useFormTypeLocalisationItem({
        config: input.typelocalisationConfig,
    });
    const serviceLocalisation = useFormSelectionLocalisationItem({
        config: input.localisationConfig,
        initParams: {
            //la route ne gère pas plusieurs type de loc en entrée. On c'est donc basé sur la première selectionné
            typeLocalisationID: (_b = input.typelocalisationConfig.defaultSelectedData[0]) === null || _b === void 0 ? void 0 : _b.typeLocalisationID,
        },
    });
    /********************************************/
    /******************LISTENER******************/
    /********************************************/
    const listenerSelectionTypeProduit = new SelectionProduitItemListener(ID, (data) => {
        const typeProduitDataIDs = new Array();
        for (const typeProduit of data) {
            typeProduitDataIDs.push(servicesProduit.typeProduitSelectionService
                .getKeyFromData(typeProduit)
                .toString());
        }
        // Le webservice ne sait pas gerer plusieurs type de produit
        const typeProduitID = typeProduitDataIDs.length > 0 ? typeProduitDataIDs[0] : 0;
        // Mise à jour du select modele en fonction de la famille
        serviceModele.requestReloadData(Object.assign(Object.assign({}, serviceModele.currentCriteria), { typeProduitID: typeProduitID }));
    });
    servicesProduit.typeProduitSelectionService.registerListener(listenerSelectionTypeProduit);
    const listenerSelectionFamilles = new SelectionProduitItemListener(ID, (data) => {
        const familleDataIDs = new Array();
        for (const famille of data) {
            familleDataIDs.push(servicesProduit.familleSelectionService
                .getKeyFromData(famille)
                .toString());
        }
        // Le webservice ne sait pas gerer plusieurs familles
        const familleID = familleDataIDs.length > 0 ? familleDataIDs[0] : 0;
        // Mise à jour du select modele en fonction de la famille
        serviceModele.requestReloadData(Object.assign(Object.assign({}, serviceModele.currentCriteria), { familleID: familleID }));
    });
    servicesProduit.familleSelectionService.registerListener(listenerSelectionFamilles);
    const listenerTypeLocalisation = new SelectionProduitItemListener(ID, (data) => {
        var _a;
        serviceLocalisation.requestReloadData({
            //la route ne gère pas plusieurs type de loc en entrée. On c'est donc basé sur la première selectionné
            typeLocalisationID: (_a = data[0]) === null || _a === void 0 ? void 0 : _a.typeLocalisationID,
        });
    });
    serviceTypeLocalisation.registerListener(listenerTypeLocalisation);
    const selectionAppareilService = {
        natureSelectionService: servicesProduit.natureSelectionService,
        typeProduitSelectionService: servicesProduit.typeProduitSelectionService,
        familleSelectionService: servicesProduit.familleSelectionService,
        sousFamilleSelectionService: servicesProduit.sousFamilleSelectionService,
        modeleService: serviceModele,
        typeLocalisationService: serviceTypeLocalisation,
        codeOrRef,
        setCodeOrRef,
        localisationService: serviceLocalisation,
        disponibilite,
        setDisponibilite,
    };
    return selectionAppareilService;
}
