var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
var TypeObjetAdapter = /** @class */ (function (_super) {
    __extends(TypeObjetAdapter, _super);
    function TypeObjetAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TypeObjetAdapter.prototype.toClient = function (src) {
        return {
            ID: src.typeObjetID,
            libelle: src.sLibelle,
            corpsMessageParDefaut: src.sTexteParDefaut,
        };
    };
    TypeObjetAdapter.prototype.toAPI = function (src) {
        return {
            typeObjetID: src.ID,
            sLibelle: src.libelle,
            sTexteParDefaut: src.corpsMessageParDefaut,
        };
    };
    return TypeObjetAdapter;
}(Adapter));
export { TypeObjetAdapter };
