import { HEADER_HEIGHT } from "./Header-v2";
import { HeaderTemplate, NAV_HEIGHT } from "./HeaderTemplate";

export interface HeaderFeuilleDeRouteProps {
  navigation?: JSX.Element;
}

export function HeaderFeuilleDeRoute({
  navigation
}: HeaderFeuilleDeRouteProps) {
  const size = HEADER_HEIGHT + NAV_HEIGHT;
  return (
    <>
      <HeaderTemplate navigation={navigation} size={size} />
    </>
  );
}
