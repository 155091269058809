import styled from "styled-components";
import { useParams } from "react-router";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";
import { RouteParam } from "interface/core/RouteParam";
import { ConfigAppareilSpecTiersVisiteAPI } from "@ads/isadom-model";

const Infos = styled.div`
  grid-area: infos;
  font-family: ${({ theme }) => theme.font.type.primaryMedium};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.7rem 0 1.7rem 2rem;
  ${({ theme }) => theme.media.sm} {
    height: 100%;
  }

  ${({ theme }) => theme.media.lg} {
    justify-content: center;
    padding: 0 2rem;
    border-right: 1px solid ${(props) => props.theme.colors.neutral[110]};
  }
`;

const AppareilInfoInstallation = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;

	.bold {
		font-weight: bold;
	}

  ${({ theme }) => theme.media.lg} {
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

const AppareilInfosPreponderance = styled.div`
  color: ${({ theme }) => theme.colors.primary[100]};
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin-top: 0.3rem;
  ${({ theme }) => theme.media.lg} {
    margin-top: 0.8rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

interface PropsDetailItemAppareil {
	configurationProduitSpec: ConfigAppareilSpecTiersVisiteAPI;
}

const InfosAppareilItem = ({
	configurationProduitSpec,
}: PropsDetailItemAppareil) => {
	const dateInstallation = new Date(
		configurationProduitSpec.dateInstallation
	).toLocaleDateString("fr");

	const { iPKVisite: idVisite } = useParams<RouteParam>();

	// Récupération de la visite à distance
	const { data: visite } = useGetVisiteQuery(+idVisite);
	const dateVisite = visite?.date;

	// Preponderance
	let preponderance = "";

	if (configurationProduitSpec.preponderance === "S") {
		preponderance += "secondaire";
	} else if (configurationProduitSpec.preponderance === "A") {
		preponderance += "alterné";
	} else if (configurationProduitSpec.preponderance === "P") {
		preponderance += "principal";
	}

	return (
		<Infos>
			<AppareilInfoInstallation>
				Installé le{" "}
				{dateInstallation !== "Invalid Date"
					? dateInstallation
					: dateVisite && new Date(dateVisite).toLocaleDateString("fr")}{" "}
				{configurationProduitSpec.produit.sCodeAffectation &&
					<>
						en <span className="bold">
							{configurationProduitSpec.produit.sCodeAffectation.toLowerCase()}
						</span>
					</>
				}
			</AppareilInfoInstallation>
			<AppareilInfosPreponderance>
				{preponderance && "Appareil " + preponderance}
			</AppareilInfosPreponderance>
		</Infos>
	);
};

export default InfosAppareilItem;
