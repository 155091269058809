import { TypeVisiteModel } from "@ads/isadom-model";
import { adsNotification, useListSelection } from "@ads/ui";
import { ITypeVisiteTypePrestaListHookInput } from "./interfaces/ITypeVisiteTypePrestaListHookInput";
import { ITypeVisiteTypePrestaListHookService } from "./interfaces/ITypeVisiteTypePrestaListHookService";

export function useTypeVisiteTypePrestaList({
  defaultTypesVisitesTypesPresta = [],
  readOnliTypesVisitesTypesPresta,
}: ITypeVisiteTypePrestaListHookInput): ITypeVisiteTypePrestaListHookService {
  const services = useListSelection({
    data: defaultTypesVisitesTypesPresta,
    defaultSelectedData: defaultTypesVisitesTypesPresta,
    getKeyFromData(typeVisite: TypeVisiteModel) {
      return typeVisite.sPKTypeVisite;
    },
    typeSelection: "simple",
  });

  function handleTypeVisiteAdded(newTypeVisite: TypeVisiteModel) {
    let isTypeVisiteAlreadyExists = false;
    const updatedTypesVisited = [];
    services.data.forEach((typeVisite) => {
      if (typeVisite.sPKTypeVisite === newTypeVisite.sPKTypeVisite) {
        newTypeVisite.tTypePrestation.forEach((presta) => {
          if (
            !typeVisite.tTypePrestation.find(
              (element) => element.id === presta.id
            )
          ) {
            typeVisite.tTypePrestation.push(presta);
          }
        });
        isTypeVisiteAlreadyExists = true;
      }
      updatedTypesVisited.push(typeVisite);
    });
    if (!isTypeVisiteAlreadyExists) {
      updatedTypesVisited.push(newTypeVisite);
    }
    services.notifyData(updatedTypesVisited);
  }

  function getTypeVisiteByID(id: string) {
    const index = services.data.findIndex(
      (typeVisite) => typeVisite.sPKTypeVisite === id
    );
    if (index >= 0) {
      return services.data[index];
    } else {
      return null;
    }
  }

  function handleTypeVisiteDeleted(typeVisiteID: string) {
    const selectionnableData = services.data;
    services.notifyData(
      selectionnableData.filter((typeVisite) => {
        if (
          typeVisite.sPKTypeVisite === typeVisiteID &&
          readOnliTypesVisitesTypesPresta
        ) {
          readOnliTypesVisitesTypesPresta.forEach((roTypeVisite) => {
            if (roTypeVisite.sPKTypeVisite === typeVisiteID) {
              adsNotification.info({
                message:
                  "Attention ! Le type de visite et les prestations issue du rendez-vous ne seront pas supprimés.",
              });
            }
          });
        }
        return typeVisite.sPKTypeVisite !== typeVisiteID;
      })
    );
  }

  return {
    typesVisites: services.data,
    notifyTypesVisitesChanged: services.notifyData,
    notifyTypeVisiteAdded: handleTypeVisiteAdded,
    notifyTypeVisiteDeleted: handleTypeVisiteDeleted,
    getTypeVisiteByID: getTypeVisiteByID,
  };
}
