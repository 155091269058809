import styled from "styled-components";
export const TitleList = styled.h3 `
  margin: 4rem 0 1rem;
  padding: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary[100]};

  ${({ theme }) => theme.media.sm} {
  }
`;
export const TitleMain = styled.h1 `
  padding: 0 0 0 1rem;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.8rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2.2rem;
  }
`;
