import React from "react";
import styled from "styled-components";
import { FormSelectionFamilleItem } from "../../../../famille";
import { FormSelectionNatureItem } from "../../../../nature/components/selection/ui/FormSelectionNatureItem";
import { FormSelectionSousFamilleItem } from "../../../../sous-famille/component/selection";
import { FormSelectionTypeProduitItem } from "../../../../type-produit/component/selection/ui/FormSelectionTypeProduitItem";
const WrapperProduit = styled.div `
  display: flex;
  flex-direction: column;
`;
export function SelectionProduit(service) {
    return (React.createElement(WrapperProduit, null,
        React.createElement(FormSelectionNatureItem, Object.assign({}, service.natureSelectionService, { libelle: "Nature de produit :" })),
        React.createElement(FormSelectionTypeProduitItem, Object.assign({}, service.typeProduitSelectionService, { libelle: "Type de produit :" })),
        React.createElement(FormSelectionFamilleItem, Object.assign({}, service.familleSelectionService, { libelle: "Famille :" })),
        React.createElement(FormSelectionSousFamilleItem, Object.assign({}, service.sousFamilleSelectionService, { libelle: "Sous famille :" }))));
}
