import { useEffect, useState } from "react";
export function useChampSelect(input) {
    const [data, setData] = useState(input.data);
    useEffect(() => {
        setData(input.data);
    }, [input.data]);
    const updateChamp = (value) => {
        input.updateValues(value);
    };
    const updateCommentaire = (value) => {
        data.valeur.commentaire = value;
    };
    return {
        data: data,
        updateValues: updateChamp,
        updateCommentaire: updateCommentaire,
    };
}
