import { createSlice } from "@reduxjs/toolkit";
import { App } from "interface/app/App";

const initialState: App = {
  bOnline: true,
  sLayoutMode: "mobile",
  sLayoutWidth: window.innerWidth,
  panel: {
    right: {
      isOpen: false
    }
  }
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    togglePanelRight(state: App) {
      state.panel.right.isOpen = !state.panel.right.isOpen;
    }
  }
});

export const { togglePanelRight } = appSlice.actions;

export default appSlice.reducer;
