import {
  VisiteModel,
  VisiteAdapter,
  HttpResponseModel,
  HttpResponseAdapter,
  VisiteAPI,
  HttpResponseAPI,
} from "@ads/isadom-model";
import {
  ApiIsadomHttpResponseError,
  apiPutV2,
  BaseError,
  getDefaultApiInstance,
  HttpResponseError,
} from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiPutVisite(
  visite: VisiteModel
): Promise<HttpResponseModel<VisiteModel | undefined>> {
  const api = getDefaultApiInstance();
  const adapter = new VisiteAdapter();
  try {
    let result;
    if (visite.bValide) {
      result = await apiPutV2(api, `visites`, adapter.toAPI(visite));
    } else {
      result = await apiPutV2(
        api,
        `visites`,
        adapter.toAPI(visite),
        apiIsadomErrorStrategy
      );
    }

    if (visite.bValide) {
      return new HttpResponseAdapter(adapter).toClient(
        result as HttpResponseAPI<VisiteAPI>
      ) as HttpResponseModel<VisiteModel>;
    } else {
      return {
        messages: [],
        data: adapter.toClient(result as VisiteAPI),
      } as HttpResponseModel<VisiteModel>;
    }
  } catch (e) {
    if (e instanceof BaseError) {
      if (e instanceof HttpResponseError) {
        const newErrorType: ApiIsadomHttpResponseError =
          e as ApiIsadomHttpResponseError;
        const response: HttpResponseModel<undefined> = {
          messages: newErrorType.data.messages,
          data: undefined,
        };
        return response;
      }
    }
    throw e;
  }
}
