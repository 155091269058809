import { jsx as _jsx } from "react/jsx-runtime";
import { Card, Tabs } from "antd";
import { ChampGroup } from "../../group/ChampGroup";
const { TabPane } = Tabs;
export function Formulaire(props) {
    return (_jsx(Card, { children: _jsx(Tabs, Object.assign({ defaultActiveKey: "0" }, { children: props.data &&
                props.data.groupes.map((e, i) => {
                    if (e.champs.length !== 0) {
                        return (_jsx(TabPane, Object.assign({ tab: e.libelle }, { children: _jsx(ChampGroup, { data: e, callbacks: {
                                    onChange(grp) {
                                        const newForm = props.data;
                                        if (newForm) {
                                            const index = newForm.groupes.findIndex((value) => value.id === grp.id);
                                            if (index !== -1) {
                                                newForm.groupes[index] = grp;
                                            }
                                            if (props.callbacks.onChange) {
                                                props.callbacks.onChange(newForm);
                                            }
                                        }
                                    },
                                } }, void 0) }), i));
                    }
                }) }), void 0) }, void 0));
}
