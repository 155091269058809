var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { apiListeConsommablesConfigurationVisite } from "../api/apiListeConsommablesConfigurationVisite";
export const useGetConsommablesConfigurationByVisiteID = (visiteID) => {
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState();
    const [loading, setLoading] = useState(true);
    const getConsommablesConfiguration = (visiteID) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const configurationsConsommables = yield apiListeConsommablesConfigurationVisite(visiteID);
            setData(configurationsConsommables);
            setErrors(null);
        }
        catch (error) {
            console.error("👨‍🚒 une erreur est survenue dans useGetConsommableByProduitID ", error);
            setData([]);
            setErrors(null);
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        if (visiteID) {
            getConsommablesConfiguration(visiteID);
        }
    }, [visiteID]);
    return { data, loading, errors, setData };
};
