import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading, Breadcrumb } from "@ads/ui";
import { Skeleton, Alert } from "antd";
import styled from "styled-components";
import { useBlocNoteForm } from "../hook";
const Wrapper = styled("div") `
  .label {
    display: block;
    font-weight: 700;
    ${({ theme }) => theme.media.md} {
      display: inline-block;
      min-width: 12rem;
    }
  }
  .destinataires {
    .destinataire {
      .separator {
        display: inline-block;
        margin-right: 0.8rem;
      }
      &:last-child {
        .separator {
          display: none;
        }
      }
    }
  }
`;
const Corps = styled("div") `
  ${({ theme }) => theme.media.md} {
    display: grid;
    grid-template-columns: 12rem auto;
  }
`;
export function BlocNoteLectureSeul(props) {
    const input = {
        idBlocNote: props.blocNoteID,
        idVisite: props.visiteID,
    };
    const data = [
        {
            item: "Bloc-notes",
            to: `/visites/${props.visiteID}/bloc-notes`,
        },
        {
            item: `Note #${props.blocNoteID}`,
            to: "",
        },
    ];
    const controler = useBlocNoteForm(input);
    if (controler.isLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Loading, { children: "Chargement en cours de la note" }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0)] }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Breadcrumb, { size: "M", breadcrumbItems: data }, void 0), _jsxs(Wrapper, { children: [_jsxs("p", { children: [_jsx("span", Object.assign({ className: "label" }, { children: "Type de notes :" }), void 0), " ", controler.note.typeNoteID] }, void 0), _jsxs(Corps, { children: [_jsx("span", Object.assign({ className: "label" }, { children: "Note :" }), void 0), _jsx("div", { children: controler.note.text }, void 0)] }, void 0), controler.error && (_jsx(Alert, { message: "Erreur", showIcon: true, description: "Erreur de chargement de donn\u00E9e", type: "error" }, void 0))] }, void 0)] }, void 0));
}
