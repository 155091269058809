import { MessageAdapter } from "@ads/isadom-model";
import { apiPut } from "@ads/react-common-module";
export const apiEditMessage = (api, visiteID, message, messageID) => {
    if (api) {
        return apiPut(api, `/visites/${visiteID}/messages/${messageID}`, new MessageAdapter(), message);
    }
    return new Promise((_, reject) => {
        reject("La configuration api est null");
    });
};
