import { FicheSuiviModel } from "@ads/isadom-model";
import { getFichesSuivisByVisiteID } from "modules/fiche-suivi/feature/getFichesSuivisByVisiteID";
import { updateFicheSuiviByVisiteID } from "modules/fiche-suivi/feature/updateFicheSuiviByVisiteID";
import { useFichesSuivisList } from "../fiches-suivis-list/hook/useFichesSuivisList";
import { useSelectFicheSuiviStore } from "./visiteContext";

export function useSyncFichesSuiviByVisiteIDWithContext(visiteID: number) {
  const { fichesSuivi, setFichesSuivi } = useSelectFicheSuiviStore();

  const fichesSuivisServices = useFichesSuivisList({
    data: {
      fichesSuivis: fichesSuivi
        ? new Promise((result) => {
            result(fichesSuivi);
          })
        : new Promise((result, reject) => {
            getFichesSuivisByVisiteID(visiteID)
              .then((data) => {
                setFichesSuivi(data);
                result(data);
              })
              .catch((error) => {
                reject(error);
              });
          })
    },
    features: {
      updateFicheSuivi(
        fichesSuivisList: FicheSuiviModel | null
      ): Promise<FicheSuiviModel | null> {
        return updateFicheSuiviByVisiteID(visiteID, fichesSuivisList);
      }
    }
  });

  return fichesSuivisServices;
}
