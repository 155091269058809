import { useFormSelectionItem, } from "../../../../common";
export function useFormSelectionSousFamilleItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.sousFamilleId;
        },
        config: input.config,
        initParams: input.initParams,
        isDataMatchWithFilter(data, familleIDs) {
            if (familleIDs !== null) {
                for (const familleID of familleIDs) {
                    if (familleID == data.famille) {
                        return true;
                    }
                }
            }
            return false;
        },
        onGetDataRequested: null
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
