import { ConfigConsoSpecTiersVisiteAdapter, } from "@ads/isadom-model";
import { apiPutV2 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";
export const apiEditeConsommableConfigurationVisite = (api, visiteID, configConsoSpecTiersVisite) => {
    const adapter = new ConfigConsoSpecTiersVisiteAdapter();
    if (!visiteID || !configConsoSpecTiersVisite) {
        return new Promise((_, reject) => {
            reject("visiteID ou configConsoSpecTiersVisite sont vide");
        });
    }
    else {
        return apiPutV2(api, `/visites/${visiteID}/consommables/${configConsoSpecTiersVisite.configCsoVisiteID}`, adapter.toAPI(configConsoSpecTiersVisite), apiIsadomErrorStrategy);
    }
};
