import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Card from "../Card";

import { useAppDispatch } from "../../../../hooks/store";
import { selectRdv } from "modules/feuille-de-route/store/redux/features/feuille-de-route.slice";
import { TypeRendezVous } from "interface/typeRendezVous";
import { useAppSelector } from "modules/feuille-de-route/hooks/store";

const CardRdv = styled(Card)`
  display: grid;
  grid-template-areas:
    "horaires horaires horaires modifier"
    "prestations prestations prestations prestations"
    "infos infos infos patient"
    "infos infos infos patient"
    "infos infos infos patient";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;

  width: 100%;
  margin: 2rem 0 0;
  list-style: none;
  cursor: pointer;

  position: relative;
  z-index: 50;

  transition: transform 0.1s ease-out, box-shadow 0.2s;

  &:hover {
    color: inherit;
    transform: scale(1.008);
    box-shadow: 0 0 1rem rgb(80 70 70 / 35%);
  }

  ${({ theme }) => theme.media.xl} {
    .isOpenLeft & {
      grid-template-areas:
        "horaires horaires horaires modifier"
        "infos infos infos infos"
        "prestations prestations prestations prestations"
        "mobile mobile patient patient";

      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  @media (min-width: 1340px) {
    .isOpenLeft & {
      grid-template-areas:
        "horaires horaires horaires modifier"
        "infos infos infos infos"
        "prestations prestations prestations patient"
        "mobile mobile mobile patient";

      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-areas:
      "horaires horaires horaires modifier"
      "infos infos infos infos"
      "prestations prestations prestations prestations"
      "mobile mobile mobile patient";

    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    grid-template-areas:
      "horaires infos prestations modifier"
      "horaires infos prestations patient"
      "horaires mobile mobile patient";

    grid-template-rows: auto;
    grid-template-columns: 8.8rem clamp(30rem, 30%, 37rem) 5fr 14rem;
  }
`;

interface CardRdvListProps {
  selection: TypeRendezVous;
  iPKVisite: number;
  children: React.ReactNode;
}

const CardRdvList = ({
  children,
  selection,
  iPKVisite,
}: CardRdvListProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { tRdvAFaire, tVisiteEnCours, tVisiteTerminee } = useAppSelector(
    (state) => state.tournee
  );

  const onCardClick = async () => {
    let selectedIndex = -1;

    if (selection === "a-faire") {
      selectedIndex = tRdvAFaire.findIndex((rdv) => rdv.rendezvousID === iPKVisite);
    } else if (selection === "en-cours") {
      selectedIndex = tVisiteEnCours.findIndex(
        (visite) => visite.visiteID === iPKVisite
      );
    } else {
      selectedIndex = tVisiteTerminee.findIndex(
        (visite) => visite.visiteID === iPKVisite
      );
    }

    if (selection !== "a-faire") {
      await dispatch(selectRdv({ selection, selectedIndex }));
      history.push(`/visites/${iPKVisite}/appareils/configuration`);
    } else {
      history.push("/visites?rdvID=" + iPKVisite);
    }
  };

  return (
    <CardRdv
      className="card-rdv"
      onClick={onCardClick}
      id={"visite-" + iPKVisite}
    >
      {children}
    </CardRdv>
  );
};

export default CardRdvList;
