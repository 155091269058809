import { FC, useEffect } from "react";
import { RendezVousModel } from "@ads/isadom-model";

import { useFormCreerVisite } from "modules/feuille-de-route/components/FormCreerVisite/hook/useFormCreerVisite";
import { FormCreerVisite } from "modules/feuille-de-route/components/FormCreerVisite/ui/FormCreerVisite";
import { Alert } from "antd";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";
import { Loading } from "@ads/ui";
import WrapperMain from "components/ui/organisms/wrappers/WrapperMain";
import WrapperMainContent from "components/ui/organisms/wrappers/WrapperMainContent";
import WrapperApp from "components/ui/organisms/wrappers/WrapperApp";
import { HeaderPageUpsertVisite } from "components-v2/header/HeaderPageUpsertVisite";

interface Props {
  rdv?: RendezVousModel;
  isLoading?: boolean;
  isError?: boolean;
}

export const PageCreerVisite = ({ rdv, isLoading, isError }: Props) => {
  const services = useFormCreerVisite({ rdv });

  useEffect(() => {
    if (rdv) {
      services.notifyInitialRdvChange(rdv);
      services.serviceInfoRdv.notifyRdvOrVisiteChanged(rdv);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    <Loading>Chargement en cours du rendez-vous...</Loading>;
  }

  if (isError) {
    return (
      <Wrapper>
        <WrapperAlert>
          <Alert
            message="Erreur"
            showIcon
            description="Une erreur est survenue pendant le chargement du rendez-vous. Veuillez contactez un administrateur."
            type="error"
          />
        </WrapperAlert>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FormCreerVisite services={services} />
    </Wrapper>
  );
};

export default PageCreerVisite;

const Wrapper: FC = ({ children }) => (
  <WrapperApp>
    <HeaderPageUpsertVisite />
    <WrapperMain className="wrapper-main">
      <WrapperMainContent
        id="wrapper-main-content"
        className="wrapper-main-content"
      >
        {children}
      </WrapperMainContent>
    </WrapperMain>
  </WrapperApp>
);
