var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiSearchV2 } from "@ads/react-common-module";
export const apiGetConsommableByProduitID = (api, produitID) => __awaiter(void 0, void 0, void 0, function* () {
    if (api || produitID) {
        const consommables = apiSearchV2(api, `/produits/recherche`, {
            produitID: produitID,
            natures: ["C"],
            estActif: "1",
        });
        return consommables;
    }
    return new Promise((_, reject) => {
        reject("La configuration api ou le produitID sont null");
    });
});
