export const triChronoASC = (tableau: any[]) => {
  const data = [...tableau];

  return data.sort((a: any, b: any) => {
    const dateA = Date.parse(`${a.date} ${a.hDebut}`);
    const dateB = Date.parse(`${b.date} ${b.hDebut}`);

    if (dateA > dateB) {
      return -1;
    }

    if (dateA < dateB) {
      return 1;
    }

    return 0;
  });
};
