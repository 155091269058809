import styled from "styled-components";
import FormFiltresTournee from "../organisms/FormFiltresTournee";

const Wrapper = styled.div`
  min-height: 100vh;
  background: ${(props) => props.theme.colors.neutral[100]};
  width: calc(100vw - 5.4rem);
  box-shadow: 0px 0px 2.0rem #72554340;
  ${(props) => props.theme.media.md} {
    width: 100%;
  }
`;

const FilterWrapper = styled("div")`
  padding: 1rem 1rem;
  ${(props) => props.theme.media.sm} {
    padding: 1rem 2rem;
  }
  ${(props) => props.theme.media.md} {
    width: 46.4rem;
    min-height: calc(100vh - 16.2rem);
  }
`;

const FiltresTournee = () => (
  <Wrapper>
    <FilterWrapper>
      <FormFiltresTournee />
    </FilterWrapper>
  </Wrapper>
);

export default FiltresTournee;
