import { Adapter } from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";

export function apiGetEvenementsByVisiteIDv2<ApiResultType, ReturnType>(
  visiteID: number,
  adapter: Adapter<ReturnType, ApiResultType>,
  type: string
): Promise<ReturnType[]> {
  const api = getDefaultApiInstance();
  let volet = "";

  if (type === "non-reserves") {
    volet = "STD";
  } else if (type === "irelm-teleobs") {
    volet = "TS";
  } else if (type === "rgpd") {
    volet = "RGPD";
  }

  if (api) {
    return apiGet(
      api,
      `visites/${visiteID}/evenements?volet=${volet}&dto=1`,
      adapter
    ) as Promise<ReturnType[]>;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
