var Adapter = /** @class */ (function () {
    function Adapter() {
    }
    Adapter.prototype.toClients = function (srcs) {
        var result = [];
        if (srcs) {
            for (var _i = 0, srcs_1 = srcs; _i < srcs_1.length; _i++) {
                var src = srcs_1[_i];
                if (src) {
                    result.push(this.toClient(src));
                }
            }
        }
        return result;
    };
    Adapter.prototype.toAPIs = function (srcs) {
        var result = [];
        if (srcs) {
            for (var _i = 0, srcs_2 = srcs; _i < srcs_2.length; _i++) {
                var src = srcs_2[_i];
                result.push(this.toAPI(src));
            }
        }
        return result;
    };
    return Adapter;
}());
export { Adapter };
