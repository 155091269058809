import { SelectV3 } from "@ads/ui";
import { useFormulaireCommandesStore, Ville } from "modules/commande/store";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.sm} {
    width: 55%;
  }

  ${({ theme }) => theme.media.md} {
    width: 100%;
  }
  ${({ theme }) => theme.media.xxl} {
    width: 55%;
  }
`;

const FormVille = () => {
  const { villes } = useFormulaireCommandesStore();
  return (
    <Wrapper>
      <label htmlFor="ville">Ville</label>
      <SelectV3
        disabled={false}
        id="villes"
        name="villes"
        onSelect={(value) => {
          const ville = villes.find((v) => v.villeID === value);
          if (ville) {
            console.log("TODO set address city: " + JSON.stringify(ville));
          }
        }}
        showSearch
        filterOption={(input: string, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={villes.map((ville: Ville) => ({
          value: ville.villeID,
          label: ville.ville
        }))}
        selectMode={"light"}
        placeholder="Sélectionnez une ville"
        defaultValue={undefined}
        allowClear
      />
    </Wrapper>
  );
};

export default FormVille;
