import { EvenementStandardModel } from "@ads/isadom-model";
import styled from "styled-components";

const Releves = styled.div``;

interface EvenementStandardRelevesProps {
  evenement: EvenementStandardModel;
}

export const EvenementStandardReleves = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  evenement,
}: EvenementStandardRelevesProps) => <Releves>TODO SB</Releves>;
