import { PPT } from "@ads/isadom-model";
import { getDefaultApiInstance, useApiGetV3 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export function useApiGetEtapeList() {
  return useApiGetV3<PPT[]>(
    getDefaultApiInstance(),
    "/parametres/programmes-educatifs/etapes",
    {
      errorStrategy: apiIsadomErrorStrategy,
      adaptResult(result) {
        return result.data;
      }
    }
  );
}
