var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
var TypeTiersAdapter = /** @class */ (function (_super) {
    __extends(TypeTiersAdapter, _super);
    function TypeTiersAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TypeTiersAdapter.prototype.toClient = function (src) {
        return {
            typeTiersID: src.typeTiersID,
            libelle: src.sLibelle,
            isInactif: src.bInactif,
            isInterne: src.bInterne,
            isVisMsgOK: src.bVisMsgOK,
        };
    };
    TypeTiersAdapter.prototype.toAPI = function (src) {
        return {
            typeTiersID: src.typeTiersID,
            sLibelle: src.libelle,
            bInactif: src.isInactif,
            bInterne: src.isInterne,
            bVisMsgOK: src.isVisMsgOK,
        };
    };
    return TypeTiersAdapter;
}(Adapter));
export { TypeTiersAdapter };
