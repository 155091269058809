import {
  CommandeVisiteModel,
  HttpMessageModel,
  LigneCommandeVisiteModel,
  VisiteModel
} from "@ads/isadom-model";
import { adsNotification } from "@ads/ui";
import { useEffect } from "react";
import {
  apiDeleteCommandeVisite,
  apiGetAdressesTiers,
  apiGetCommandeByVisiteID,
  apiGetEquipesPreparation,
  apiGetModesLivraison,
  apiGetPrestationsTiers,
  apiGetTypesAffectationProduit,
  apiPutCommandeVisite,
  apiGetTypesVoies
} from "../api";
import { apiGetVilles } from "../api/apiGetVilles";
import { useFormulaireCommandesStore } from "../store/formulaireCommandeStore";

const formatResponseMessages = (messages: HttpMessageModel[]) => {
  let message = "";

  messages.forEach((m) => {
    message += m.message + "\n";
  });

  return message;
};

export const useFormulaireCommandeAPI = (visite: VisiteModel) => {
  const {
    setIsLoadingFetch,
    setIsLoadingSave,
    setIsLoadingReset,
    setIsError,
    setResponseMessages,
    setCommande,
    setEquipesOptions,
    setAdressesOptions,
    setModesLivraisonOptions,
    setPrestationsTiersOptions,
    setAffectationsOptions,
    setTypesVoiesOptions,
    setVillesOptions
  } = useFormulaireCommandesStore();

  const initialiseCommandeLines = (
    commande: CommandeVisiteModel
  ): CommandeVisiteModel => {
    const initialized = commande;

    initialized.lignes = initialized.lignes.map(
      (ligne: LigneCommandeVisiteModel, index: number) => {
        if (ligne.ID === 0) {
          ligne.ID = -++index;
        }

        return ligne;
      }
    );

    return initialized;
  };

  const fetchData = async (visite: VisiteModel) => {
    try {
      setIsLoadingFetch(true);

      const [
        commande,
        equipes,
        adresses,
        modesLivraison,
        prestationsTiers,
        typesAffectation,
        typesVoies,
        villes
      ] = await Promise.all([
        apiGetCommandeByVisiteID(visite.ID),
        apiGetEquipesPreparation(),
        apiGetAdressesTiers(visite.tiersVisite.tiersID),
        apiGetModesLivraison(),
        apiGetPrestationsTiers(visite.tiersVisite.tiersID, visite.date),
        apiGetTypesAffectationProduit(),
        apiGetTypesVoies(),
        apiGetVilles()
      ]);

      setCommande(initialiseCommandeLines(commande.data));
      setEquipesOptions(equipes.data);
      setAdressesOptions(adresses.data);
      setModesLivraisonOptions(modesLivraison.data);
      setPrestationsTiersOptions(prestationsTiers.data);
      setAffectationsOptions(typesAffectation);
      setTypesVoiesOptions(typesVoies.data);
      setVillesOptions(villes.data);

      setResponseMessages(commande.messages);
    } catch (error) {
      // console.error(error);
      setIsError(true);
    } finally {
      setIsLoadingFetch(false);
    }
  };

  const saveData = async (commande: CommandeVisiteModel) => {
    try {
      setIsLoadingSave(true);
      // await apiPutCommandeVisite(visite.ID, commande);
      const { data, messages } = await apiPutCommandeVisite(
        visite.ID,
        commande
      );
      setCommande(data);
      adsNotification.success({
        message: "La commande a été enregistrée."
      });
      setResponseMessages(messages);
    } catch (error) {
      // console.error(error);
      setIsError(true);
    } finally {
      setIsLoadingSave(false);
    }
  };

  const resetData = async () => {
    try {
      setIsLoadingReset(true);
      const { messages } = await apiDeleteCommandeVisite(visite.ID);
      const { data } = await apiGetCommandeByVisiteID(visite.ID);

      setCommande(initialiseCommandeLines(data));
      setResponseMessages(messages);
      adsNotification.success({
        message: formatResponseMessages(messages) //  "La commande a été réinitialisée.",
      });
    } catch (error) {
      // console.error(error);
      setIsError(true);
    } finally {
      setIsLoadingReset(false);
    }
  };

  useEffect(() => {
    if (visite) {
      fetchData(visite);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visite]);

  return { saveData, resetData };
};
