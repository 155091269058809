var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter, AdresseAdapter, LocalisationInterneAutoriseeAdapter, TypePrestationAdapter, TypeVisiteAdapter, } from "../../..";
var IntervenantAdapter = /** @class */ (function (_super) {
    __extends(IntervenantAdapter, _super);
    function IntervenantAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.adresseAdapter = new AdresseAdapter();
        _this.localisationInterneAutoriseeAdapter = new LocalisationInterneAutoriseeAdapter();
        _this.typePrestationAutoriseeAdapter = new TypePrestationAdapter();
        _this.typeVisiteAutoriseeAdapter = new TypeVisiteAdapter();
        return _this;
    }
    IntervenantAdapter.prototype.toClient = function (src) {
        return {
            tiersID: src.iPKTiers,
            codeTiers: src.sCodeTiers,
            prenom: src.sPrenom,
            nom: src.sNom,
            typeTiers: src.sTypeTiers,
            mail: src.sMail,
            telgsm: src.sTelgsm,
            adressePrincipale: src.adressePrincipale &&
                this.adresseAdapter.toClient(src.adressePrincipale),
            tAdresse: src.tAdresse && this.adresseAdapter.toClients(src.tAdresse),
            fonction: src.sFKFonction,
            equipe: src.sFKEquipe,
            estReleveCompteur: src.bReleveCompteur,
            localisationAutorisees: this.localisationInterneAutoriseeAdapter.toClients(src.tLocalisationAutorise),
            typesPrestationsAutorisees: this.typePrestationAutoriseeAdapter.toClients(src.tTypePrestaAutorise),
            typesVisitesAutorisees: this.typeVisiteAutoriseeAdapter.toClients(src.tTypeVisiteAutorise),
        };
    };
    IntervenantAdapter.prototype.toAPI = function (src) {
        return {
            iPKTiers: src.tiersID,
            sCodeTiers: src.codeTiers,
            sPrenom: src.prenom,
            sNom: src.nom,
            sTypeTiers: src.typeTiers,
            sMail: src.mail,
            sTelgsm: src.telgsm,
            adressePrincipale: src.adressePrincipale &&
                this.adresseAdapter.toAPI(src.adressePrincipale),
            tAdresse: this.adresseAdapter.toAPIs(src.tAdresse ? src.tAdresse : []),
            sFKFonction: src.fonction,
            sFKEquipe: src.equipe,
            bReleveCompteur: src.estReleveCompteur,
            tLocalisationAutorise: this.localisationInterneAutoriseeAdapter.toAPIs(src.localisationAutorisees),
            tTypePrestaAutorise: this.typePrestationAutoriseeAdapter.toAPIs(src.typesPrestationsAutorisees),
            tTypeVisiteAutorise: this.typeVisiteAutoriseeAdapter.toAPIs(src.typesVisitesAutorisees),
        };
    };
    return IntervenantAdapter;
}(Adapter));
export { IntervenantAdapter };
