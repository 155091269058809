import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Label = styled.div `
  position: absolute;
  top: -1rem;
  left: 1rem;
  padding: 0 0.4rem;
  height: 2rem;
  line-height: 2rem;
  color: #fff;
  background: rgb(86, 191, 239);
`;
export const LabelNew = () => _jsx(Label, { children: "Nouveau" }, void 0);
