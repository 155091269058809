var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { TypeLocalisationAdapter } from "..";
var AdresseAdapter = /** @class */ (function (_super) {
    __extends(AdresseAdapter, _super);
    function AdresseAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeLocalisationAdapter = new TypeLocalisationAdapter();
        return _this;
    }
    AdresseAdapter.prototype.toClient = function (src) {
        return {
            adresseID: src.iPKAdresse,
            adresseAvecRC: src.sAdresseAvecRC,
            adresseComplete: src.sAdresseComplete,
            adressePrincipale: src.bAdressePrincipale,
            adresseSansCPVille: src.sAdresseSansCPVille,
            codePostal: src.sCodePostal,
            dateDebut: src.dDebut,
            dateFin: src.dFin,
            enCours: src.bEnCours,
            libelle: src.sLibelle,
            numeroVoie: src.sNumeroVoie,
            telAutre: src.sTelAutre,
            telFixe: src.sTelFixe,
            telInformation: src.sTelInformation,
            typeLocalisation: this.typeLocalisationAdapter.toClient(src.typeLocalisation),
            ville: src.sVille,
            adresse: src.sAdresse,
        };
    };
    AdresseAdapter.prototype.toAPI = function (src) {
        return {
            iPKAdresse: src.adresseID,
            bAdressePrincipale: src.adressePrincipale,
            bEnCours: src.enCours,
            dDebut: src.dateDebut,
            dFin: src.dateFin,
            sAdresse: src.adresse,
            sAdresseAvecRC: src.adresseAvecRC,
            sAdresseComplete: src.adresseComplete,
            sAdresseSansCPVille: src.adresseSansCPVille,
            sCodePostal: src.codePostal,
            sLibelle: src.libelle,
            sNumeroVoie: src.numeroVoie,
            sTelAutre: src.telAutre,
            sTelFixe: src.telFixe,
            sTelInformation: src.telInformation,
            sVille: src.ville,
            typeLocalisation: this.typeLocalisationAdapter.toAPI(src.typeLocalisation),
        };
    };
    return AdresseAdapter;
}(Adapter));
export { AdresseAdapter };
