import React from "react";
import { FormItemWrapper, Label, SelectWrapper, } from "@ads/ui";
import { Select } from "antd";
const { Option } = Select;
export function FormSelectionItem(props) {
    const defaultSelectedDataIds = [];
    for (const defaultSelectedData of props.config.defaultSelectedData) {
        defaultSelectedDataIds.push(props.getKeyFromData(defaultSelectedData));
    }
    const selectedDataIds = [];
    for (const selectedData of props.selectedData) {
        selectedDataIds.push(props.getKeyFromData(selectedData));
    }
    return props.config.visible ? (React.createElement(FormItemWrapper, { direction: "vertical" },
        React.createElement(Label, { htmlFor: props.libelle }, props.libelle),
        React.createElement(SelectWrapper, { selectMode: "light", isLoading: props.loading },
            React.createElement(Select, { allowClear: true, showSearch: true, loading: props.loading, mode: props.config.typeSelection === "multiple" ? "multiple" : undefined, onSelect: (value) => {
                    props.notifyDataSelected(value);
                }, disabled: !props.config.allowChange, onDeselect: (value) => {
                    props.notifyDataUnSelected(value);
                }, onClear: props.notifyDataSelectedReset, defaultValue: defaultSelectedDataIds, value: selectedDataIds, filterOption: (input, option) => {
                    if ((option === null || option === void 0 ? void 0 : option.children) && !(option.children instanceof Array)) {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    else {
                        return false;
                    }
                } }, props.data.map((item) => (React.createElement(Option, { key: props.getKeyFromData(item), value: props.getKeyFromData(item) }, props.getOptionLibelle(item)))))))) : (React.createElement(React.Fragment, null));
}
