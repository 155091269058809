import React from "react";
import styled from "styled-components";
import IconLoupe from "../svg/icons/IconLoupe";
import IconLoading from "../svg/icons/IconLoading";

interface PropsStyle {
  type: string;
  disabled?: boolean;
}

const StyleButton = styled("button") <PropsStyle>`
  display: flex;
  gap: 7px;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 5.1rem 1.5rem;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.neutral[110]
      : props.theme.colors.secondary[100]};
  border: none;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.neutral[190]
      : props.theme.colors.neutral[100]};
  border-radius: 3rem;
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
`;

interface Props {
  children: React.ReactNode;
  type: "button" | "submit" | "reset";
  disabled?: boolean;
}

const ButtonSearch: React.FC<Props> = ({ children, type, disabled }: Props) => (
  <StyleButton type={type || "submit"} disabled={disabled || false}>
    {disabled ? <IconLoading /> : <IconLoupe />}
    {children}
  </StyleButton>
);

export default ButtonSearch;
