/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChecklistModel, ChecklistVisiteModel } from "@ads/isadom-model";
import { PPT } from "@ads/isadom-model";
import {
  IChecklistListHookInput,
  IChecklistListHookResult,
  useChecklistList,
  getMotifsNonRealisationTaches
} from "@ads/module-checklist";
import { getDefaultApiInstance } from "@ads/react-common-module";
import {
  apiGetChecklistByVisiteID,
  apiUpdateChecklistByVisiteID
} from "api/checklist";
import { useEffect, useState } from "react";

export interface IChecklistVisiteListHookInput {
  visiteID: number;
}

const TAG = "useChecklistVisiteList";
const ID = "@ads/" + TAG;

export function useChecklistVisiteList(
  input: IChecklistVisiteListHookInput
): IChecklistVisiteListHookResult {
  const api = getDefaultApiInstance();
  const [motifsNonRealisationTache, setMotifsNonRealisationTache] = useState<
    PPT[]
  >([]);
  useEffect(() => {
    getMotifsNonRealisationTaches().then((response: PPT[]) => {
      setMotifsNonRealisationTache(response);
    });
  }, []);
  const legacyHookInput: IChecklistListHookInput<ChecklistVisiteModel, number> =
    {
      fetchParams: input.visiteID,
      getDataFunction: apiGetChecklistByVisiteID,
      isEquals(compare: ChecklistVisiteModel, toCompare: ChecklistVisiteModel) {
        return (
          compare.reference === toCompare.reference &&
          compare.referenceID === toCompare.referenceID
        );
      },
      data: [],
      update(checklist) {
        return apiUpdateChecklistByVisiteID(api, input.visiteID, checklist);
      },
      delete: null
    };

  const legacyHook = useChecklistList(legacyHookInput);

  const listenerCheckList = {
    idListener: ID,
    onBeforeDeleteItem: function (
      item: ChecklistModel | null
    ): Promise<ChecklistModel | null> {
      throw new Error("Function not implemented.");
    },
    onAfterDeleteItem: function (item: ChecklistModel): void {
      throw new Error("Function not implemented.");
    },
    onBeforeAddItem: function (
      item: ChecklistModel | null
    ): Promise<ChecklistModel | null> {
      throw new Error("Function not implemented.");
    },
    onAfterAddItem: function (item: ChecklistModel): void {
      throw new Error("Function not implemented.");
    },
    onBeforemModifyItem: async function (
      item: ChecklistModel | null
    ): Promise<ChecklistModel | null> {
      return item;
    },
    onAfterModifyItem: function (item: ChecklistModel): void {},
    onBeforeDeleteAll: function (): Promise<boolean> {
      throw new Error("Function not implemented.");
    },
    onAfterDeleteAll: function (): void {
      throw new Error("Function not implemented.");
    }
  };
  legacyHook.registerListener(listenerCheckList);

  return {
    ...legacyHook,
    motifsNonRealisationTache: motifsNonRealisationTache
  };
}

export interface IChecklistVisiteListHookResult
  extends IChecklistListHookResult {
  motifsNonRealisationTache: PPT[];
}
