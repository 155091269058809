import {
  IntervenantModel,
  LocalisationInterneAutoriseeModel
} from "@ads/isadom-model";
import { useEffect, useState } from "react";
import { ISelectLocalisationByIntervenantVisiteHookInput } from "./interfaces/ISelectLocalisationByIntervenantVisiteHookInput";
import { ISelectLocalisationByIntervenantVisiteHookService } from "./interfaces/ISelectLocalisationByIntervenantVisiteHookService";
import { useSelectLocalisation } from "./useSelectLocalisation";

export function filterForPrelevementDM(
  localisation: LocalisationInterneAutoriseeModel
) {
  return localisation.bLocalisationPreleveDM;
}

export function filterForPrelevementDMC(
  localisation: LocalisationInterneAutoriseeModel
) {
  return localisation.bLocalisationPreleveDMC;
}

export function useSelectLocalisationByIntervenantVisite({
  defaultIntervenant,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isDefault,
  defaultSelectedData = []
}: ISelectLocalisationByIntervenantVisiteHookInput): ISelectLocalisationByIntervenantVisiteHookService {
  const [intervenant, setIntervenant] = useState(defaultIntervenant);
  const services = useSelectLocalisation({
    typeSelection: "simple",
    defaultSelectedLocalisation: defaultSelectedData,
    defaultSelectionableLocalisation: defaultIntervenant
      ? defaultIntervenant.localisationAutorisees
      : []
  });

  function handleIntervenantChanged(intervenant: IntervenantModel) {
    setIntervenant(intervenant);
  }

  useEffect(() => {
    if (intervenant) {
      services.notifyData(intervenant.localisationAutorisees);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervenant]);

  return {
    ...services,
    notifyIntervenantChanged: handleIntervenantChanged
  };
}
