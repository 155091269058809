import { Adapter } from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";

export function apiGetEvenementByIDv2<ApiResultType, ReturnType>(
  ID: number,
  adapter: Adapter<ReturnType, ApiResultType>
): Promise<ReturnType> {
  const api = getDefaultApiInstance();
  if (api) {
    return apiGet(api, `evenements/${ID}`, adapter) as Promise<ReturnType>;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
