import { useEffect, useState } from "react";
import styled from "styled-components";
import { FormItemWrapper } from "@ads/ui";
import { AxiosResponse } from "axios";
import { Alert } from "antd";
import moment, { Moment } from "moment";
import locale from "antd/es/date-picker/locale/fr_FR";
import { Calendar as CalendarANTD } from "antd";
import ButtonSearch from "components/ui/atoms/buttons/ButtonSearch";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store";
import { getTournee } from "../../store/redux/services/feuille-de-route.services";
import { FormProvider, useForm } from "react-hook-form";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";
import api from "api";
import { TiersAdapter, TiersModel, TypeTiersModel } from "@ads/isadom-model";
import { TiersAPI } from "@ads/isadom-model";
import {
  SelectionTiersV3,
  TiersModule,
  useDefaultSelectionTiersV3Props,
} from "@ads/module-tiers";
import { ModuleProvider } from "@ads/react-common-module";


const Wrapper = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 20rem);
  ${(props) => props.theme.media.sm} {
    padding-right: 1rem;
  }
`;

const WrapperCalendar = styled("div")`
  /* Background calendar */
  .ant-picker-calendar-mini .ant-picker-calendar-header,
  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    background: ${(props) => props.theme.colors.neutral[110]};
  }
  /* color header button */
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${(props) => props.theme.colors.primary[100]};
  }
  /* color border button header */
  .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: ${(props) => props.theme.colors.primary[100]};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: ${(props) => props.theme.colors.primary[100]};
  }
  /* Size day */
  .ant-picker-cell .ant-picker-cell-inner {
    min-width: 3.2rem;
    min-height: 3.2rem;
    line-height: 3.2rem;
    border-radius: 1rem;
  }

  /* Background date selected */
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${(props) => props.theme.colors.primary[100]};
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: none;
  }
`;

const TitleFilter = styled("h2")`
  font-size: 1.4rem;
  font-weight: bold;
`;

const DateWrapper = styled("div")`
  margin-top: 2rem;
`;

const ButtonWrapper = styled("div")`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

function getTiersByIdFromAPI(
  tiersID: number
): Promise<AxiosResponse<TiersAPI>> {
  const url = `tiers/${tiersID}`;
  return api.get(url);
}

const FormFiltresTournee = () => {
  const dispatch = useAppDispatch();
  const authData = useAppSelector((state) => state.auth.identite?.iFKAnnuaire);
  const tournee = useAppSelector((state) => state.tournee);
  const [alertNoData, setAlert] = useState(false);

  const intervenantID =
    tournee.iPKIntervenant === -1 ? authData : tournee.iPKIntervenant;

  const [intervenant, setIntervenant] = useState<TiersModel | undefined>();

  useEffect(() => {
    if (intervenantID) {
      getTiersByIdFromAPI(intervenantID).then((result) => {
        setIntervenant(new TiersAdapter().toClient(result.data));
      });
    }
  }, [intervenantID]);

  const [date, setDate] = useState(
    tournee.dDate === undefined ? moment().format("YYYYMMDD") : tournee.dDate
  );
  const methods = useForm();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setAlert(false);

    if (`${intervenant?.tiersID}` !== "undefined") {
      dispatch(
        getTournee({
          dDate: date,
          iPKIntervenant: `${intervenant?.tiersID}`,
          selection: "en-cours",
        })
      );
      document.getElementById("wrapper-main-content")?.classList.remove("is-open-left")
    } else {
      setAlert(true);
    }
  };

  const services = useDefaultSelectionTiersV3Props({
    formConfig: {
      ouConfig: {
        allowChange: true,
        defaultData: "",
      },
      quiConfig: {
        allowChange: true,
        defaultData: "",
      },
      typesTiersConfig: {
        allowChange: false,
        defaultSelectedData: [
          {
            libelle: "Personnel interne",
            typeTiersID: "INT",
          } as TypeTiersModel,
        ],
        typeSelection: "simple",
        visible: true,
      },
    },
    onTiersSelected: (tiersSelected: TiersModel[]) => {
      if (tiersSelected.length > 0) {
        setIntervenant(tiersSelected[0]);
      }
    },
    selectionConfig: {
      defaultSelectedData: intervenant ? [intervenant] : [],
      typeSelection: "simple",
    },
  });

  const tiersModule = new TiersModule();

  if (!intervenant) {
    return null;
  }

  return (
    <form onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <FormItemWrapper direction="vertical">
          <ModuleProvider module={tiersModule}>
            <SelectionTiersV3 service={services} />
          </ModuleProvider>
        </FormItemWrapper>
      </FormProvider>
      <Wrapper>
        <DateWrapper>
          <TitleFilter>Date</TitleFilter>
        </DateWrapper>
        <WrapperCalendar>
          <CalendarANTD
            locale={locale}
            fullscreen={false}
            defaultValue={moment(date)}
            onChange={(value: Moment) => {
              setDate(value.format("YYYYMMDD"));
            }}
          />
        </WrapperCalendar>
        <ButtonWrapper>
          <ButtonSearch type="submit" disabled={tournee.isLoading}>
            {tournee.isLoading
              ? "Récupération de la liste des rendez-vous..."
              : "Rechercher les rendez-vous"}
          </ButtonSearch>
        </ButtonWrapper>
        {alertNoData ? (
          <WrapperAlert>
            <Alert
              message="Erreur"
              showIcon
              description="Veuillez sélectionner un intervenant"
              type="error"
            />
          </WrapperAlert>
        ) : (
          ""
        )}
      </Wrapper>
    </form>
  );
};

export default FormFiltresTournee;
