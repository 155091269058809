import { ConfigConsoSpecTiersVisiteAdapter, } from "@ads/isadom-model";
import { apiPostV2 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";
export const apiAjouteConsommableConfigurationVisite = (api, visiteID, configConsoSpecTiersVisiteModel) => {
    const adapter = new ConfigConsoSpecTiersVisiteAdapter();
    if (api && visiteID) {
        return apiPostV2(api, `/visites/${visiteID}/consommables`, adapter.toAPI(configConsoSpecTiersVisiteModel), apiIsadomErrorStrategy);
    }
    return new Promise((_, reject) => {
        reject("La configuration api est nulle");
    });
};
