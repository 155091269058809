var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LivraisonVisiteAdapter, } from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
export const apiListeConsommablesLivraisonsVisite = (visiteID) => __awaiter(void 0, void 0, void 0, function* () {
    const api = getDefaultApiInstance();
    if (api) {
        const adapter = new LivraisonVisiteAdapter();
        const resultAPI = yield apiGetV2(api, `/visites/${visiteID}/livraisons`);
        return adapter.toClients(resultAPI);
    }
    return new Promise((_resolve, reject) => {
        reject("La configuration d'api n'est pas initialisée");
    });
});
