import {
  FicheSuiviApi,
  FicheSuiviModel,
  FicheSuivisAdapter,
  HttpResponseAdapter,
  HttpResponseAPI,
  HttpResponseModel,
} from "@ads/isadom-model";
import { ApiInstance, apiPutV2 } from "@ads/react-common-module";

export async function apiPutFicheSuiviByVisiteID(
  api: ApiInstance | null,
  visiteID: number,
  fichesSuivis: FicheSuiviModel
): Promise<HttpResponseModel<FicheSuiviModel>> {
  const ficheSuiviAdapter = new FicheSuivisAdapter();
  const result: HttpResponseAPI<FicheSuiviApi[]> = await apiPutV2(
    api,
    "/visites/" + visiteID + "/fiches-suivis",
    ficheSuiviAdapter.toAPI(fichesSuivis)
  );
  const adapter = new HttpResponseAdapter(ficheSuiviAdapter);
  return adapter.toClient(result) as HttpResponseModel<FicheSuiviModel>;
}
