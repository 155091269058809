var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TypePrestationAdapter } from ".";
import { Adapter, AdresseAdapter, TiersAdapter } from "../../..";
var PrestationAdapter = /** @class */ (function (_super) {
    __extends(PrestationAdapter, _super);
    function PrestationAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typePrestationAdapter = new TypePrestationAdapter();
        _this.tiersAdapter = new TiersAdapter();
        _this.adresseAdapter = new AdresseAdapter();
        return _this;
    }
    PrestationAdapter.prototype.toClient = function (src) {
        //TODO FBR convertion des dates / heures en timestamps
        return {
            id: src.prestationID,
            type: src.typePrestation &&
                this.typePrestationAdapter.toClient(src.typePrestation),
            tiersOrigine: src.tiersOrigine && this.tiersAdapter.toClient(src.tiersOrigine),
            adresseAppareillage: src.adresseAppareillage &&
                this.adresseAdapter.toClient(src.adresseAppareillage),
            dureeTraitement: src.iDureeTraitement,
            information: src.sInformation,
            informationOrigine: src.sInformationOrigine,
            informationTraitement: src.sInformationTraitement,
            isALD: src.bALD,
            isAnnule: src.bAnnule,
            isCollecteDechet: src.bCollecteDechet,
            isProtocole: src.bCollecteDechet,
            isRisqueVital: src.bRisqueVital,
            medecinExerciceID: src.iFKMedecinExercice,
            protocolID: src.iFKProtocole,
            tiersContact: src.tiersContact && this.tiersAdapter.toClient(src.tiersContact),
            typeDureeTraitement: src.iTypeDureeTraitement,
        };
    };
    PrestationAdapter.prototype.toAPI = function (src) {
        //TODO FBR convertion des dates / heures en timestamps
        return {
            prestationID: src.id,
            typePrestation: src.type && this.typePrestationAdapter.toAPI(src.type),
            tiersOrigine: src.tiersOrigine && this.tiersAdapter.toAPI(src.tiersOrigine),
            adresseAppareillage: src.adresseAppareillage &&
                this.adresseAdapter.toAPI(src.adresseAppareillage),
            iDureeTraitement: src.dureeTraitement,
            sInformation: src.information,
            sInformationOrigine: src.informationOrigine,
            sInformationTraitement: src.informationTraitement,
            bALD: src.isALD,
            bAnnule: src.isAnnule,
            bCollecteDechet: src.isCollecteDechet,
            bRisqueVital: src.isRisqueVital,
            iFKMedecinExercice: src.medecinExerciceID,
            iFKProtocole: src.protocolID,
            tiersContact: src.tiersContact && this.tiersAdapter.toAPI(src.tiersContact),
            iTypeDureeTraitement: src.typeDureeTraitement,
        };
    };
    return PrestationAdapter;
}(Adapter));
export { PrestationAdapter };
