var QuestionnaireModelBuilder = /** @class */ (function () {
    function QuestionnaireModelBuilder() {
    }
    QuestionnaireModelBuilder.prototype.build = function () {
        return {
            questionnaireID: this.questionnaireID,
            status: this.status,
            dateSaisie: this.dateSaisie,
            dateValidation: this.dateValidation,
            examenID: this.examenID,
            gereValidation: this.gereValidation,
            information: this.information,
            nautVisiteID: this.nautVisiteID,
            score: this.score,
            scoreMax: this.scoreMax,
            typeQuestionnaire: this.typeQuestionnaire,
            visProgrammeID: this.visProgrammeID,
            visiteID: this.visiteID,
            themes: this.themes,
            tiersQuestionne: this.tiersQuestionne,
            tiersValidation: this.tiersValidation
        };
    };
    QuestionnaireModelBuilder.prototype.setTypeQuestionnaire = function (typeQuestionnaire) {
        this.gereValidation = typeQuestionnaire.besoinValidation;
        this.scoreMax = typeQuestionnaire.scoreMax;
        this.typeQuestionnaire = typeQuestionnaire;
        this.themes = typeQuestionnaire.themes;
        return this;
    };
    QuestionnaireModelBuilder.prototype.setStatus = function (status) {
        this.status = status;
        return this;
    };
    return QuestionnaireModelBuilder;
}());
export { QuestionnaireModelBuilder };
