import { FC } from "react";
import { Input } from "antd";
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import { GrilleAPI, ModeReglageAPI } from "@ads/isadom-model";
import getModeActif from "modules/appareils/functions/getModeActif";

interface Props {
	indexGrille: number;
	modesReglagesAppareil: ModesReglagesAppareil;
	setModesReglagesAppareil: React.Dispatch<
		React.SetStateAction<ModesReglagesAppareil>
	>;
}

const InputLibelle: FC<Props> = ({
	indexGrille,
	modesReglagesAppareil,
	setModesReglagesAppareil,
}) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const modeSaisieActif = getModeActif(modesReglagesAppareil);

		if (modeSaisieActif.mode) {
			const libelle = e.target.value;
			const grilles: GrilleAPI[] = [...modeSaisieActif.mode.tabGrille];

			// Mise à jour du libellé pour la grille active
			grilles[indexGrille] = {
				...grilles[indexGrille],
				sLibelle: libelle,
			};

			// Mise à jour du mode
			const modeUpdated: ModeReglageAPI = {
				...modeSaisieActif.mode,
				tabGrille: grilles,
			};

			const newModeSaisi = [...modesReglagesAppareil.modesSaisis];
			newModeSaisi[modeSaisieActif.indexModeSaisi] = modeUpdated;

			setModesReglagesAppareil({
				...modesReglagesAppareil,
				modesSaisis: newModeSaisi,
			});
		}
	};

	const modeActif = getModeActif(modesReglagesAppareil);
	const inputValue = modeActif?.mode.tabGrille[indexGrille].sLibelle;

	return (
		<>
			<Input
				placeholder="Libellé du nouveau réglage"
				value={inputValue}
				onChange={handleChange}
			/>
		</>
	);
};

export default InputLibelle;
