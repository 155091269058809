var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
import { ChampListAdapter, ChampNumeriqueAdapter, ChampBoolAdapter } from ".";
import { ChampChaineAdapter } from "./ChampChaineAdapter";
var AbstractChampAdapter = /** @class */ (function (_super) {
    __extends(AbstractChampAdapter, _super);
    function AbstractChampAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.champListAdapter = new ChampListAdapter();
        _this.champTextAdapter = new ChampChaineAdapter();
        _this.champBoolAdapter = new ChampBoolAdapter();
        _this.champNumAdapter = new ChampNumeriqueAdapter();
        return _this;
    }
    AbstractChampAdapter.prototype.toClient = function (src) {
        if (src.typeChamp.sCode === "LST") {
            return this.champListAdapter.toClient(src);
        }
        else if (src.typeChamp.sCode === "BOL") {
            return this.champBoolAdapter.toClient(src);
        }
        else if (src.typeChamp.sCode === "TXT" || src.typeChamp.sCode === "LNG") {
            return this.champTextAdapter.toClient(src);
        }
        else if (src.typeChamp.sCode === "NUM") {
            return this.champNumAdapter.toClient(src);
        }
        else {
            throw new Error("Champ sans type existant");
        }
    };
    AbstractChampAdapter.prototype.toAPI = function (src) {
        if (src.typeChamp.libelle === "LST") {
            return this.champListAdapter.toAPI(src);
        }
        else if (src.typeChamp.libelle === "BOL") {
            return this.champBoolAdapter.toAPI(src);
        }
        else if (src.typeChamp.libelle === "TXT" ||
            src.typeChamp.libelle === "LNG") {
            return this.champTextAdapter.toAPI(src);
        }
        else if (src.typeChamp.libelle === "NUM") {
            return this.champNumAdapter.toAPI(src);
        }
        else {
            throw new Error("Champ sans type existant");
        }
    };
    return AbstractChampAdapter;
}(Adapter));
export { AbstractChampAdapter };
