import styled from "styled-components";
export const FormArea = styled.div `
  margin: 4rem 0;
  max-width: 840px;

  .form-item {
    margin-bottom: 0;
    justify-content: space-between;
  }

  ${({ theme }) => theme.media.md} {
    grid-template-columns: 33% 66%;
  }
`;
export const ConfigurationArea = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 3rem;

  ${({ theme }) => theme.media.sm} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
