var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReducer, useEffect, useState } from "react";
import styled from "styled-components";
import { Select, Skeleton, Modal } from "antd";
import { FormItemWrapper, SelectWrapper, Label, Input, Textarea, Loading, } from "@ads/ui";
import { useGetAllTypesObjetsFromAPI } from "../../api/useGetAllTypesObjetsFromAPI";
import { apiGetAllTypeObjetDestinataires } from "../../api/apiGetAllTypeObjetDestinataires";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { DestinataireToTiersAdapter } from "./DestinataireToTiersAdapter";
const { Option } = Select;
const Form = styled("div") `
  max-width: 80rem;
`;
const FormItem = styled("div") `
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }
`;
export const MessageForm = ({ intervenantID, message, setMessageData, selectionDestinataire }) => {
    const [typeObjet, setTypeObjet] = useState("");
    const [isRendered, setIsRendered] = useState(false);
    const initialFormState = {
        ID: 0,
        typeObjetID: "",
        objet: "",
        corps: "",
        destinataires: [],
    };
    if (message) {
        initialFormState.ID = message.ID;
        initialFormState.typeObjetID = message.typeObjet.ID;
        initialFormState.objet = message.objet;
        initialFormState.corps = message.corps;
        initialFormState.destinataires = message.destinatairesPrincipaux.map((destinataire) => ({
            label: destinataire.prenom.length > 0
                ? `${destinataire.prenom} ${destinataire.nom} (${destinataire.codeAnnuaire})`
                : `${destinataire.nom} (${destinataire.codeAnnuaire})`,
            value: destinataire.tiersID,
            tiersID: destinataire.tiersID,
            key: destinataire.tiersID,
            codeAnnuaire: destinataire.codeAnnuaire,
            typeTiers: destinataire.typeTiersID,
            prenom: destinataire.prenom,
            nom: destinataire.nom,
        }));
    }
    const messageFormReducer = (state, action) => {
        switch (action.type) {
            case "HANDLE_CHANGE":
                return Object.assign(Object.assign({}, state), { [action.key]: action.payload });
            case "HANDLE_CONFIRM_TYPE_OBJET_CHANGE":
                return Object.assign(Object.assign({}, state), { alerteRaZ: true });
            case "HANDLE_TYPE_OBJET_CHANGE":
                return Object.assign(Object.assign({}, state), { destinataires: action.payload, alerteRaZ: false });
            default:
                return state;
        }
    };
    const [formState, dispatch] = useReducer(messageFormReducer, Object.assign({}, initialFormState));
    const api = getDefaultApiInstance();
    const { data: typesObjets, isLoading } = useGetAllTypesObjetsFromAPI();
    useEffect(() => {
        const getAllDestinatairesParDefaut = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { data } = yield apiGetAllTypeObjetDestinataires(api, formState.typeObjetID, intervenantID);
                if (isRendered || !message) {
                    const destinataires = data.map((tiers) => ({
                        label: tiers.prenom.length > 0
                            ? `${tiers.prenom} ${tiers.nom} (${tiers.codeAnnuaire})`
                            : `${tiers.nom} (${tiers.codeAnnuaire})`,
                        value: tiers.tiersID,
                        key: tiers.tiersID,
                        tiersID: tiers.tiersID,
                        codeAnnuaire: tiers.codeAnnuaire,
                        typeTiers: tiers.typetiersID,
                        prenom: tiers.prenom,
                        nom: tiers.nom,
                    }));
                    setMessageData((messageData) => (Object.assign(Object.assign({}, messageData), { destinataires })));
                    dispatch({
                        type: "HANDLE_TYPE_OBJET_CHANGE",
                        key: "destinataires",
                        payload: destinataires,
                    });
                }
            }
            catch (error) {
                console.error("👨‍🚒 ERROR", error);
            }
        });
        if (formState.typeObjetID) {
            getAllDestinatairesParDefaut();
        }
    }, [formState.typeObjetID]);
    const [modalVisible, setModalVisible] = useState("");
    const doChangeTypeObjet = (value) => {
        setIsRendered(true);
        setMessageData((messageData) => (Object.assign(Object.assign({}, messageData), { typeObjetID: value })));
        dispatch({
            type: "HANDLE_CHANGE",
            key: "typeObjetID",
            payload: value,
        });
        const corps = typesObjets === null || typesObjets === void 0 ? void 0 : typesObjets.find((typeObjet) => typeObjet.ID === value);
        setMessageData((messageData) => (Object.assign(Object.assign({}, messageData), { corps: (corps === null || corps === void 0 ? void 0 : corps.corpsMessageParDefaut) || "" })));
        dispatch({
            type: "HANDLE_CHANGE",
            key: "corps",
            payload: (corps === null || corps === void 0 ? void 0 : corps.corpsMessageParDefaut) || "",
        });
    };
    const handleChangeTypeObjet = (value) => {
        setTypeObjet(value);
        if (!formState.typeObjetID) {
            doChangeTypeObjet(value);
        }
        else if (value !== formState.typeObjetID) {
            setModalVisible("changeTypeObjet");
        }
    };
    const updateDestinataires = (selectedItems) => {
        const destinataires = selectedItems.map((selectedItem) => {
            var _a, _b, _c, _d;
            return ({
                key: selectedItem.key,
                label: ((_a = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.item) === null || _a === void 0 ? void 0 : _a.label) || (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label),
                value: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.tiersID) || selectedItem.key,
                tiersID: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.tiersID) || selectedItem.key,
                codeAnnuaire: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.codeAnnuaire,
                typeTiers: ((_b = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.item) === null || _b === void 0 ? void 0 : _b.typeTiers) || selectedItem.typeTiers,
                prenom: ((_c = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.item) === null || _c === void 0 ? void 0 : _c.prenom) || selectedItem.prenom,
                nom: ((_d = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.item) === null || _d === void 0 ? void 0 : _d.nom) || selectedItem.nom,
            });
        });
        setMessageData((messageData) => (Object.assign(Object.assign({}, messageData), { destinataires: destinataires })));
        dispatch({
            type: "HANDLE_CHANGE",
            key: "destinataires",
            payload: destinataires,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    };
    if (isLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Loading, { children: "Chargement en cours du formulaire" }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0)] }, void 0));
    }
    const SelectionDestinataire = selectionDestinataire;
    const defaultDestinataires = new Array();
    if (message === null || message === void 0 ? void 0 : message.destinatairesPrincipaux) {
        for (const destinataire of message.destinatairesPrincipaux) {
            defaultDestinataires.push(new DestinataireToTiersAdapter(destinataire));
        }
    }
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit }, { children: [_jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "typeObjetID" }, { children: "Type d'objet" }), void 0), _jsx(SelectWrapper, Object.assign({ selectMode: "light" }, { children: _jsx(Select, Object.assign({ id: "typeObjetID", showSearch: true, allowClear: true, value: formState.typeObjetID, onSelect: handleChangeTypeObjet, placeholder: "S\u00E9lectionnez le type d'objet", filterOption: (input, option) => {
                                return (option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0);
                            } }, { children: typesObjets === null || typesObjets === void 0 ? void 0 : typesObjets.map((typeObjet) => (_jsx(Option, Object.assign({ value: typeObjet.ID }, { children: typeObjet.libelle }), `${typeObjet.ID}`))) }), void 0) }), void 0), _jsx(Modal, Object.assign({ title: "", visible: modalVisible === "changeTypeObjet", onOk: () => {
                            setModalVisible("");
                            doChangeTypeObjet(typeObjet);
                        }, onCancel: () => setModalVisible("") }, { children: _jsxs("p", { children: ["Des destinataires sont param\u00E9tr\u00E9s pour cet objet de message. ", _jsx("br", {}, void 0), "Voulez-vous vider la liste des destinataires ?"] }, void 0) }), void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "objet" }, { children: "Objet" }), void 0), _jsx(Input, { type: "text", id: "objet", name: "objet", value: formState.objet, onChange: (e) => {
                            setMessageData((messageData) => (Object.assign(Object.assign({}, messageData), { objet: e.target.value })));
                            dispatch({
                                type: "HANDLE_CHANGE",
                                key: e.target.id,
                                payload: e.target.value,
                            });
                        }, selectMode: "light", placeholder: "Saisissez l'objet du message" }, void 0)] }), void 0), _jsx(FormItem, { children: _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "destinatairesPrincipaux" }, { children: "Destinataire(s)" }), void 0), _jsx(SelectionDestinataire, { onDestinatairesSelected: (tiers) => {
                                updateDestinataires(tiers);
                            }, defaultTiersSelected: defaultDestinataires }, void 0)] }), void 0) }, void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "corps" }, { children: "Corps" }), void 0), _jsx(Textarea, { id: "corps", name: "corps", value: formState.corps, onChange: (e) => {
                            setMessageData((messageData) => (Object.assign(Object.assign({}, messageData), { corps: e.target.value })));
                            dispatch({
                                type: "HANDLE_CHANGE",
                                key: e.target.id,
                                payload: e.target.value,
                            });
                        }, selectMode: "light", placeholder: "Saisissez le corps du message" }, void 0)] }), void 0)] }), void 0));
};
