import React from "react";
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }
    static getDerivedStateFromError() {
        //console.log("🚀 ~ file: ErrorBoundary.tsx ~ line 10 ~ ErrorBoundary ~ getDerivedStateFromError ~ error", error)
        // Update state so the next render will show the fallback UI.
    }
    componentDidCatch() {
        //console.log("🚀 ~ file: ErrorBoundary.tsx ~ line 7 ~ ErrorBoundary ~ componentDidCatch ~ info", info)
        //console.log("🚀 ~ file: ErrorBoundary.tsx ~ line 7 ~ ErrorBoundary ~ componentDidCatch ~ error", error)
    }
    render() {
        window.addEventListener("error", () => {
            //console.log("🚀 ~ file: ErrorBoundary.tsx ~ line 24 ~ ErrorBoundary ~ window.addEventListener ~ event", event)
            //event.stopPropagation()
            //return true
        });
        window.addEventListener("unhandledrejection", () => {
            //console.log("🚀 ~ file: ErrorBoundary.tsx ~ line 28 ~ ErrorBoundary ~ window.addEventListener ~ event", event)
            //event.stopPropagation()
            //return true
        });
        return this.props.children;
    }
}
