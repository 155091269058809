var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../..";
var ValeurPossibleAdapter = /** @class */ (function (_super) {
    __extends(ValeurPossibleAdapter, _super);
    function ValeurPossibleAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ValeurPossibleAdapter.prototype.toClient = function (src) {
        return {
            id: src.id,
            score: src.rScore,
            valeur: src.sValeur,
            module: src.sModule
        };
    };
    ValeurPossibleAdapter.prototype.toAPI = function (src) {
        return {
            id: src.id,
            rScore: src.score,
            sValeur: src.valeur,
            sModule: src.module,
        };
    };
    return ValeurPossibleAdapter;
}(Adapter));
export { ValeurPossibleAdapter };
