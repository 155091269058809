var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter, TypePrestationAdapter } from "../..";
var TypeVisiteAdapter = /** @class */ (function (_super) {
    __extends(TypeVisiteAdapter, _super);
    function TypeVisiteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typePrestationAdapter = new TypePrestationAdapter();
        return _this;
    }
    TypeVisiteAdapter.prototype.toClient = function (src) {
        return {
            sPKTypeVisite: src.sPKTypeVisite,
            sLibelle: src.sLibelle,
            bLivraisonPossible: src.bLivraisonPossible,
            bDesappareillage: src.bDesappareillage,
            bPrestationObligatoire: src.bPrestationObligatoire,
            iDelaiControleRdv: src.iDelaiControleRdv,
            bSuiviCompteur: src.bSuiviCompteur,
            bSaisieSmart: src.bSaisieSmart,
            bInactif: src.bInactif,
            tTypePrestation: this.typePrestationAdapter.toClients(src.tTypePrestation),
            informationContext: src.sInformationContexte,
        };
    };
    TypeVisiteAdapter.prototype.toAPI = function (src) {
        return {
            sPKTypeVisite: src.sPKTypeVisite,
            sLibelle: src.sLibelle,
            bLivraisonPossible: src.bLivraisonPossible,
            bDesappareillage: src.bDesappareillage,
            bPrestationObligatoire: src.bPrestationObligatoire,
            iDelaiControleRdv: src.iDelaiControleRdv,
            bSuiviCompteur: src.bSuiviCompteur,
            bSaisieSmart: src.bSaisieSmart,
            bInactif: src.bInactif,
            tTypePrestation: this.typePrestationAdapter.toAPIs(src.tTypePrestation),
            sInformationContexte: src.informationContext,
        };
    };
    return TypeVisiteAdapter;
}(Adapter));
export { TypeVisiteAdapter };
