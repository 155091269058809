import {
  CommandeVisiteAPI,
  CommandeVisiteModel,
  HttpResponseAPI,
  HttpResponseModel
} from "@ads/isadom-model";
import { apiDeleteV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiDeleteCommandeVisite(
  visiteID: number
): Promise<HttpResponseModel<CommandeVisiteModel>> {
  try {
    if (!visiteID) {
      throw new Error("L'identifiant de la visite est manquant.");
    }

    const api = getDefaultApiInstance();

    if (!api) {
      throw new Error("La configuration d'api n'est pas initialisée");
    }

    const { messages }: HttpResponseAPI<CommandeVisiteAPI> = await apiDeleteV2(
      api,
      `/visites/${visiteID}/raz/commandes`,
      apiIsadomErrorStrategy
    );

    return { data: undefined as unknown as CommandeVisiteModel, messages };
  } catch (err: any) {
    return new Promise((_resolve, reject) => {
      reject(err.message);
    });
  }
}
