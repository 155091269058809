import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading } from "@ads/ui";
import { Skeleton, Alert } from "antd";
import { useGetConsommableConfigurationByConfigConsoID } from "../../hooks/useGetConsommableConfigurationByConfigConsoID";
import { ConsommableConfigurationDetail } from "../organisms/ConfigurationConsommableDetail";
export const ConsommablesConfigurationDetailTemplate = ({ visiteID, configConsoID, }) => {
    const { isLoading, configConsoSpecTiersVisiteModel, error } = useGetConsommableConfigurationByConfigConsoID(visiteID, configConsoID);
    if (isLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Loading, { children: "Chargement en cours de la configuration du consommable." }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0)] }, void 0));
    }
    if (error) {
        return (_jsx(Alert, { showIcon: true, type: "error", message: "Une erreur est survenue...", description: "La configuration du consommable n'a pu \u00EAtre r\u00E9cup\u00E9r\u00E9e." }, void 0));
    }
    return configConsoSpecTiersVisiteModel ? (_jsx(ConsommableConfigurationDetail, { visiteID: visiteID, configConsoSpecTiersVisite: configConsoSpecTiersVisiteModel }, void 0)) : (_jsx(_Fragment, {}, void 0));
};
