import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Input } from "antd";
import { useState } from "react";
const QteInput = styled(Input) `
  min-width: 3em;
  width: 4em;
  text-align: center;
`;
const Label = styled.label `
  margin-right: 1.6rem;
  white-space: nowrap;
`;
const ContainerForms = styled.form `
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const FormLivraisonVisiteQuantite = ({ livraison, disabled, lot, refreshData, }) => {
    var _a, _b;
    const [timer, setTimer] = useState();
    const [quantite, setQuantite] = useState(livraison.iQuantite || 0);
    const handleChange = (e) => {
        const quantite = parseInt(e.target.value, 10);
        window.clearTimeout(timer);
        if (Number.isNaN(quantite) || quantite < 0) {
            return;
        }
        setQuantite(quantite);
        setTimer(window.setTimeout(() => {
            const data = Object.assign(Object.assign({}, livraison), { iQuantite: quantite });
            refreshData(data);
        }, 500));
    };
    const handleFocus = (e) => {
        e.target.select();
    };
    return (_jsxs(ContainerForms, { children: [!lot && _jsx(Label, { children: "Quantit\u00E9 livr\u00E9e :" }, void 0), _jsx(QteInput, { type: "text", onChange: handleChange, onFocus: handleFocus, id: `qte-${livraison.ligneLivraisonID}-${(_a = livraison.consommable) === null || _a === void 0 ? void 0 : _a.IDProduit}`, defaultValue: livraison.iQuantite, value: quantite, name: `qte-${livraison.ligneLivraisonID}-${(_b = livraison.consommable) === null || _b === void 0 ? void 0 : _b.IDProduit}`, disabled: disabled }, void 0)] }, void 0));
};
