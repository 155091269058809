import { FC, useState } from "react";
import styled from "styled-components";
import {
	FormItemWrapper,
	Label,
	PrimaryButton,
	Icons,
	IconFilter,
} from "@ads/ui";
import { AppareilAPI, VisiteAPI } from "@ads/isadom-model";

import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import BoutonAjoutGrille from "../atoms/BoutonAjoutGrille";
import SelectModesReglage from "../atoms/SelectModesReglage";
import TextAreaCommentaires from "../atoms/TextareaCommentaires";
import SelectPrestations from "../atoms/SelectPrestations";
import SelectAppareilDeMesure from "../molecules/SelectAppareilDeMesure";

const WrapperForm = styled.form`
  display: grid;

  grid-template-areas:
    "mode mode filterButton"
    "appareil prestation prestation"
    "commentaires commentaires commentaires"
    "options options options";
  grid-template-columns: 1fr 1fr 5rem;
  grid-template-rows: auto;
  column-gap: 3rem;

  .mode {
    grid-area: mode;
  }
  .appareil {
    grid-area: appareil;
  }
  .prestation {
    grid-area: prestation;
  }
  .commentaires {
    grid-area: commentaires;
  }
  .options {
    grid-area: options;
  }

  .appareil,
  .prestation,
  .commentaires,
  .options {
    display: none;
  }

  &.display-filter .appareil,
  &.display-filter .prestation,
  &.display-filter .commentaires,
  &.display-filter .options {
    display: block;
  }

  ${({ theme }) => theme.media.md} {
    grid-template-areas:
      "mode mode "
      "appareil prestation "
      "commentaires options ";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 3rem;
    .appareil,
    .prestation,
    .commentaires,
    .options {
      display: block;
    }
  }

  ${({ theme }) => theme.media.xxl} {
    grid-template-areas:
      "mode mode commentaires options "
      "appareil prestation commentaires options ";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 2rem;
    &.display-filter .appareil,
    &.display-filter .prestation,
    &.display-filter .commentaires,
    &.display-filter .options {
      display: block;
    }
  }

  .ajouterGrille {
    grid-area: ajouterGrille;
  }
  .afficheThéorique {
    grid-area: afficheThéorique;
  }
`;

const WrapperOptions = styled.div`
  ${({ theme }) => theme.media.md} {
    border-left: 1px solid ${(props) => props.theme.colors.neutral[130]};
    padding: 0 0 0 2rem;
  }

  ${({ theme }) => theme.media.lg} {
    border-left: 1px solid ${(props) => props.theme.colors.neutral[130]};
    padding: 0 0 0 2rem;
  }
`;

const WrapperButton = styled.div`
  .button {
    display: block;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    width: 100%;
  }
  ${({ theme }) => theme.media.md} {
    .button {
      width: inherit;
      padding: 0.5rem 5rem 0 0;
    }
  }
  ${({ theme }) => theme.media.md} {
    padding-top: 2.5rem;
    .button {
      width: inherit;
    }
  }
`;

const WrapperText = styled.span`
  display: none;
  ${({ theme }) => theme.media.md} {
    display: flex;
  }
  ${({ theme }) => theme.media.lg} {
    display: flex;
  }
`;

const WrapperFilterbutton = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.media.md} {
    display: none;
  }
  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`;

const Filterbutton = styled.button<{ filterIsOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  margin-top: 2.6rem;
  background: ${(props) =>
		props.filterIsOpen
			? props.theme.colors.primary[100]
			: props.theme.colors.quinary[100]};
  border-radius: 1.3rem;
  border: none;
  cursor: pointer;
`;

interface PropsFormHeaderReglage {
	appareil?: AppareilAPI;
	modesReglagesAppareil: ModesReglagesAppareil;
	setModesReglagesAppareil: React.Dispatch<
		React.SetStateAction<ModesReglagesAppareil>
	>;
	visite: VisiteAPI | undefined;
}

/**
 * @description affichage des champs Mode, Commentaire, Recherche appareil de mesure..
 * @date 2022-05-18
 * @param  modesReglagesAppareil
 * @param  setModesReglagesAppareil
 * @param  visite
 * @returns {JSX}
 */
const FormHeaderReglage: FC<PropsFormHeaderReglage> = ({
	modesReglagesAppareil,
	setModesReglagesAppareil,
	visite,
}): JSX.Element => {
	const [filter, setFilter] = useState<boolean>(false);

	/**
	 * @description: Mise à jour du champ affiche théorique inactif
	 */
	const handleClick = () => {
		setModesReglagesAppareil({
			...modesReglagesAppareil,
			bAfficheTheoriqueInactif: !modesReglagesAppareil.bAfficheTheoriqueInactif,
		});
	};


	const handleFilterClick = () => {
		setFilter(!filter);
	};

	return (
		<WrapperForm className={filter ? "display-filter" : ""}>
			<div className="mode">
				<SelectModesReglage
					modesReglagesAppareil={modesReglagesAppareil}
					setModesReglagesAppareil={setModesReglagesAppareil}
					disabled={visite?.bValide}
				/>
			</div>
			<div className="filterButton">
				<WrapperFilterbutton>
					<Filterbutton onClick={handleFilterClick} filterIsOpen={filter}>
						<IconFilter iconColor="white" iconSize="2rem" />
					</Filterbutton>
				</WrapperFilterbutton>
			</div>
			<div className="appareil">
				<FormItemWrapper direction="vertical">
					<Label htmlFor="sAppareil" minWidth="14rem">
						Appareil de mesure
					</Label>
					<SelectAppareilDeMesure
						modesReglagesAppareil={modesReglagesAppareil}
						setModesReglagesAppareil={setModesReglagesAppareil}
						disabled={visite?.bValide}
					/>
				</FormItemWrapper>
			</div>
			<div className="prestation">
				<SelectPrestations idPrestation={modesReglagesAppareil.idPrestation} />
			</div>
			<div className="commentaires">
				<TextAreaCommentaires
					modesReglagesAppareil={modesReglagesAppareil}
					setModesReglagesAppareil={setModesReglagesAppareil}
					disabled={visite?.bValide}
				/>
			</div>
			{!visite?.bValide && (
				<WrapperOptions className="options">
					<WrapperText>Options</WrapperText>
					<WrapperButton>
						<div className="button">
							<BoutonAjoutGrille
								modesReglagesAppareil={modesReglagesAppareil}
								setModesReglagesAppareil={setModesReglagesAppareil}
							/>
						</div>
						<div className="button" style={{ marginTop: "1.5rem" }}>
							<PrimaryButton
								onClick={handleClick}
								label={`${modesReglagesAppareil.bAfficheTheoriqueInactif
									? "Afficher théorique inactif"
									: "Masquer théorique inactif"
									}`}
								as="button"
								iconPosition="left"
								icon={Icons.Eye}
								type={"button"}
								buttonStyle="neutral130"
								size="S"
								minWidth="100%"
							/>
						</div>
					</WrapperButton>
				</WrapperOptions>
			)}
		</WrapperForm>
	);
};
export default FormHeaderReglage;
