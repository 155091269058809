import { EvenementRGPDModel } from "@ads/isadom-model";
import { Detail, Details, Nom, Valeur } from "../../atoms/viewFields";

interface EvenementRGPDDetailsProps {
  evenement?: EvenementRGPDModel;
}

const objDate = new Intl.DateTimeFormat("fr-FR");

export const EvenementRGPDDetails = ({
  evenement,
}: EvenementRGPDDetailsProps) => (
  <Details>
    <Detail>
      <Nom>Du</Nom>
      <Valeur>
        {evenement &&
          evenement.dateDebut !== "0000-00-00" &&
          objDate.format(new Date(evenement.dateDebut))}
      </Valeur>
    </Detail>
    <Detail>
      <Nom>Au</Nom>
      <Valeur>
        {evenement &&
          evenement.dateFin !== "0000-00-00" &&
          objDate.format(new Date(evenement.dateFin))}
      </Valeur>
    </Detail>
    <Detail>
      <Nom>Réalisation prestation</Nom>
      <Valeur>{evenement?.estRealisationPrestation ? "Oui" : "Non"}</Valeur>
    </Detail>
    <Detail>
      <Nom>Télésuivi</Nom>
      <Valeur>{evenement?.estTelesuivi ? "Oui" : "Non"}</Valeur>
    </Detail>
    <Detail>
      <Nom>Envoi information</Nom>
      <Valeur>{evenement?.estEnvoiInformation ? "Oui" : "Non"}</Valeur>
    </Detail>
    <Detail>
      <Nom>Programme de recherche</Nom>
      <Valeur>{evenement?.estProgrammeRecherche ? "Oui" : "Non"}</Valeur>
    </Detail>
    <Detail>
      <Nom>Enquete</Nom>
      <Valeur>{evenement?.estEnquete ? "Oui" : "Non"}</Valeur>
    </Detail>
  </Details>
);
