import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, notification } from "antd";
import styled from "styled-components";
import { EvenementDTOModel } from "@ads/isadom-model";

import {
  EvenementStandardListHeader,
  EvenementStandardListItem,
} from "../../molecules/evenementStandard";
import { apiDeleteEvenement } from "../../../api";

import errorAdapter from "utils/errors/errorAdapter";

const ListItemContainer = styled.div`
  position: relative;
  margin: 0 0 1.6em;
  transition: transform 0.1s ease-out;

  & button {
    transition: all 0.1s ease-out;
    transform: scale(1);
  }
  &:hover button {
    transform: scale(1.08);
    top: -1.85rem;
    right: -1.85rem;
  }
`;

interface EvenementStandardListProps {
  visiteID: number;
  type: string;
  evenements: EvenementDTOModel[];
}

export const EvenementStandardList = ({
  visiteID,
  type,
  evenements,
}: EvenementStandardListProps) => {
  const history = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [evtToDelete, setEvtToDelete] = useState<EvenementDTOModel>();
  const removeEvenement = async () => {
    if (evtToDelete && evtToDelete.evenementVisiteID) {
      try {
        await apiDeleteEvenement(visiteID, evtToDelete.evenementVisiteID);
        notification.success({
          key: `form_success`,
          message: "Confirmation",
          description: "L'évènement a bien été supprimé.",
        });
        history.push(`/visites/${visiteID}/evenements/${type}`);
      } catch (error: any) {
        if (typeof error === "object") {
          errorAdapter(error)?.forEach((e: any) => {
            notification.error({
              key: `form_erreur`,
              message: "Erreur",
              description: e.message || "Erreur interne du serveur",
            });
          });
        } else {
          notification.error({
            key: `form_erreur`,
            message: "Erreur",
            description: error || "Erreur interne du serveur",
          });
        }
      }
    }
  };

  return (
    <>
      {evenements?.length > 0 && <EvenementStandardListHeader />}
      {evenements.map((evt: EvenementDTOModel) => (
        <ListItemContainer key={evt.ID}>
          <EvenementStandardListItem
            removeEvenement={() => {
              setEvtToDelete(evt);
              setConfirmDelete(true);
            }}
            visiteID={visiteID}
            type={type}
            evenement={evt}
          />
        </ListItemContainer>
      ))}
      <Modal
        visible={confirmDelete}
        onOk={() => {
          removeEvenement();
          setConfirmDelete(false);
        }}
        onCancel={() => setConfirmDelete(false)}
      >
        <p>
          Attention la suppression de l'évènement est irréversible. <br />
          Souhaitez-vous continuer ?
        </p>
      </Modal>
    </>
  );
};
