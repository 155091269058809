import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/fr_FR";
const ContainerForms = styled.form `
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.lg} {
    justify-content: flex-end;
  }
`;
const DatePickerANTD = styled(DatePicker) `
  width: 12rem;
  background: ${(props) => props.theme.colors.neutral[100]};
`;
export const FormLivraisonVisiteDLCLot = ({ livraison, refreshData, }) => {
    var _a, _b;
    const [timer, setTimer] = useState();
    const handleChange = (newDLC) => {
        window.clearTimeout(timer);
        setTimer(window.setTimeout(() => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const data = Object.assign(Object.assign({}, livraison), { lot: {
                    iPKProduitDMCLot: ((_a = livraison.lot) === null || _a === void 0 ? void 0 : _a.iPKProduitDMCLot) || -1,
                    iFKProduit: ((_b = livraison.lot) === null || _b === void 0 ? void 0 : _b.iFKProduit) || -1,
                    sLot: ((_c = livraison.lot) === null || _c === void 0 ? void 0 : _c.sLot) || "",
                    dLimiteConso: newDLC || "",
                    dLimiteUtilisation: ((_d = livraison.lot) === null || _d === void 0 ? void 0 : _d.dLimiteUtilisation) || "",
                    iPKEmplacement: ((_e = livraison.lot) === null || _e === void 0 ? void 0 : _e.iPKEmplacement) || -1,
                    sCodeEmplacement: ((_f = livraison.lot) === null || _f === void 0 ? void 0 : _f.sCodeEmplacement) || "",
                    iPKStock: ((_g = livraison.lot) === null || _g === void 0 ? void 0 : _g.iPKStock) || -1,
                    iStockTheorique: ((_h = livraison.lot) === null || _h === void 0 ? void 0 : _h.iStockTheorique) || -1,
                } });
            refreshData(data);
        }, 500));
    };
    return (_jsx(ContainerForms, { children: _jsx(DatePickerANTD, { name: `dlc-${livraison.ligneLivraisonID}-${(_a = livraison.lot) === null || _a === void 0 ? void 0 : _a.sLot}`, id: `dlc-${livraison.ligneLivraisonID}-${(_b = livraison.lot) === null || _b === void 0 ? void 0 : _b.sLot}`, defaultValue: livraison.lot &&
                livraison.lot.dLimiteConso !== "0000-00-00" &&
                livraison.lot.dLimiteConso !== ""
                ? moment(livraison.lot.dLimiteConso)
                : undefined, format: "DD/MM/YYYY", locale: locale, onChange: (value) => {
                handleChange((value === null || value === void 0 ? void 0 : value.format("YYYY-MM-DD")) || "");
            }, placeholder: "DLC" }, void 0) }, void 0));
};
