import styled from "styled-components";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

const Informations = styled.div`
  margin: 0 2rem 0 0;
  padding: 0.6rem;
  height: 100%;
  min-height: 20rem;
  width: calc(50% - 1rem);
  border: 1px solid ${(props) => props.theme.colors.neutral[130]};
  border-radius: 0.6rem;

  & .quill .ql-container.ql-snow {
    border: none;
  }
`;
interface EvenementStandardInformationsProps {
  informations: string;
}

export const EvenementStandardInformations = ({
  informations,
}: EvenementStandardInformationsProps) => (
  <Informations>
    <ReactQuill
      readOnly
      theme="snow"
      modules={{ toolbar: false }}
      value={informations}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange={(text) => {
      }}
    />
  </Informations>
);
