import { useApiGetEtapeList } from "../api/useApiGetEtapeList"
import { useSelectPEStore } from "../components/context/PEContext"

export function useSyncEtapeWithContext() {
    const { metadata, setMetadata } = useSelectPEStore()
    const service = useApiGetEtapeList()
    async function fetchWithSync() {
        const result = await service.fetch()
        setMetadata({
            typePEListService: metadata.typePEListService,
            etapeListService: service
        })
        return result
    }

    return {
        ...service,
        fetch: fetchWithSync
    }
}