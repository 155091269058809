import { FC } from "react";
import moment from "moment";
import { Breadcrumb } from "@ads/ui";
import { useApiGetIntervenantByID } from "@ads/module-tiers";
import { TypeRendezVous } from "interface/typeRendezVous";
import { Alert } from "antd";
interface Props {
  intervenantID: number | undefined;
  selection: TypeRendezVous;
  date: string;
}

interface BreadcrumbItem {
  item: React.ReactNode;
  to: string;
}

const BreadcrumbContainer: FC<Props> = ({
  intervenantID,
  selection,
  date
}: Props) => {
  const { intervenant, loading, error } = useApiGetIntervenantByID(
    intervenantID || 0
  );

  let breadcrumbItems: BreadcrumbItem[] = [
    {
      item: "Feuille de route",
      to: `/feuille-de-route/${selection}`
    },
    {
      item: (
        <>
          {intervenant?.prenom} {intervenant?.nom}
          {selection === "en-cours" ? " au " : " du "}
          {moment(date).format("DD/MM/YYYY")}
        </>
      ),
      to: ""
    }
  ];

  if (loading) {
    breadcrumbItems = [
      {
        item: "Feuille de route",
        to: ``
      }
    ];
  }

  if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Une erreur est survenue..."
        description="Le chargement de l'intervenant a échoué."
      />
    );
  }

  return <Breadcrumb size="M" breadcrumbItems={breadcrumbItems} />;
};

export default BreadcrumbContainer;
