import ProtectedRoute from "components/core/routes/ProtectedRoute";
import { RouteParam } from "interface/core/RouteParam";
import { Switch, useParams } from "react-router-dom";
import {
  ConstantesListVisite,
  ConstanteList,
  ConstanteListItem
} from "../components/constante-list/ui";
import { ConstanteRespiratoireList } from "../components/constante-list/ui/ConstanteRespiratoireList";
import { PageFicheSuivi } from "../components/pages/PageFicheSuivi";
import { PageIAH } from "../components/pages/PageIAH";
import { PageParamEdition } from "../components/pages/PageParamEdition";

const RouterFicheSuivi = () => {
  const { iPKVisite } = useParams<RouteParam>();

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`/visites/${iPKVisite}/suivi-patient/constantes`}
      >
        <ConstantesListVisite
          visiteID={iPKVisite ? parseInt(iPKVisite) : null}
          listAdapter={ConstanteList}
          listRespiratoireAdapter={ConstanteRespiratoireList}
          itemConstanteAdapter={ConstanteListItem}
          itemRespiratoireAdapter={ConstanteListItem}
        />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/visites/:iPKVisite/suivi-patient/fiches-suivi/:ficheSuiviID"
      >
        <PageFicheSuivi />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/visites/:iPKVisite/suivi-patient/iah/:ficheSuiviID"
      >
        <PageIAH />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/visites/:iPKVisite/suivi-patient/param-edition"
      >
        <PageParamEdition />
      </ProtectedRoute>
    </Switch>
  );
};

export default RouterFicheSuivi;
