import styled from "styled-components";

const WrapperMessagerie = styled("div")`
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  z-index: 1000;
`;

export default WrapperMessagerie;
