import styled from "styled-components";

const WrapperError = styled("div")`
  margin: 2rem 0;
  max-width: 84rem;
  p {
    margin: 0;
  }
`;

export default WrapperError;
