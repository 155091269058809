import { IHeaderListProps } from "./interfaces/IHeaderListProps";
import styled from "styled-components";
import TitleMain from "components/ui/atoms/titles/TitleMain";
import {
  Button,
  Filtre,
  Icons,
  Modal,
  ModalPropsFactory,
  PrimaryButton
} from "@ads/ui";

const HeaderListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;

const WrapperFiltres = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    gap: 2rem;
  }
`;

const TitleHeaderList = styled(TitleMain)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export function HeaderList(props: IHeaderListProps) {
  return (
    <HeaderListWrapper>
      <TitleHeaderList>
        {props.lenghtList === 0 && `0 ${props.nomModuleSingulier}`}
        {props.lenghtList === 1 && `1 ${props.nomModuleSingulier}`}
        {props.lenghtList > 1 &&
          `${props.lenghtList} ${props.nomModulePluriel}`}
      </TitleHeaderList>
      <div
        style={{ display: "flex", justifyContent: "space-around", gap: "2rem" }}
      >
        <WrapperFiltres>
          {props.filtersList.map((e, _key) => {
            return (
              <Filtre
                key={_key}
                libelle={e.libelle}
                items={e.items}
                handleChange={e.handleChange}
              />
            );
          })}
        </WrapperFiltres>
        <PrimaryButton
          as="button"
          iconPosition="left"
          icon={Icons.AddButton}
          type="button"
          label={`Ajouter ${props.nomModuleSingulier.toLowerCase()}`}
          buttonStyle="light"
          size="S"
          onClick={props.handleAddClick}
        />
        {props.handleDelAllClick && (
          <Modal
            {...ModalPropsFactory.createDeleteModalProps({
              onOk: props.handleDelAllClick,
              modalController: ({ showModal }) => (
                <Button
                  label="RàZ"
                  as="button"
                  type="button"
                  buttonStyle="secondary"
                  size="S"
                  onClick={showModal}
                  icon={Icons.Chrono}
                />
              )
            })}
          />
        )}
      </div>
    </HeaderListWrapper>
  );
}
