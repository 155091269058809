var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TiersAdapter } from "../../tiers";
import { Adapter } from "../../_adapter";
import { ThemeAdapter } from "./ThemeAdapter";
import { TypeQuestionnaireAdapter } from "./TypeQuestionnaireAdapter";
var QuestionnaireAdapter = /** @class */ (function (_super) {
    __extends(QuestionnaireAdapter, _super);
    function QuestionnaireAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    QuestionnaireAdapter.prototype.toClient = function (src) {
        var questionnaire = {
            dateSaisie: src.dGrille,
            dateValidation: src.dValide,
            examenID: src.iFKExamen,
            gereValidation: src.bValide,
            information: src.sInformation,
            nautVisiteID: src.iFKNautVisite,
            questionnaireID: src.iPKQuestionnaire,
            score: src.iScore,
            scoreMax: src.iScoreMax,
            themes: src.tabTheme && new ThemeAdapter().toClients(src.tabTheme),
            tiersQuestionne: src.tiersQuestionne && new TiersAdapter().toClient(src.tiersQuestionne),
            tiersValidation: src.tiersValidation && new TiersAdapter().toClient(src.tiersValidation),
            typeQuestionnaire: new TypeQuestionnaireAdapter().toClient(src.questionnaireDefinition),
            visProgrammeID: src.iFKVisProgramme,
            visiteID: src.iFKVisite,
        };
        //Les réponses possible ne sont qu'au niveau du type
        //=> on ajoute les réponses possible au niveau du questionnaire 
        if (questionnaire.typeQuestionnaire.themes && (!questionnaire.themes || questionnaire.themes.length === 0)) {
            questionnaire.themes = questionnaire.typeQuestionnaire.themes;
        }
        else if (questionnaire.themes && questionnaire.typeQuestionnaire.themes) {
            for (var i = 0; i < questionnaire.themes.length; i++) {
                for (var j = 0; j < questionnaire.themes[i].questions.length; j++) {
                    for (var k = 0; k < questionnaire.typeQuestionnaire.themes.length; k++) {
                        for (var l = 0; l < questionnaire.typeQuestionnaire.themes[k].questions.length; l++) {
                            if (questionnaire.themes[i].questions[j].libelle === questionnaire.typeQuestionnaire.themes[k].questions[l].libelle) {
                                questionnaire.themes[i].questions[j].reponsesPossibles = questionnaire.typeQuestionnaire.themes[k].questions[l].reponsesPossibles;
                            }
                        }
                    }
                }
            }
        }
        return questionnaire;
    };
    QuestionnaireAdapter.prototype.toAPI = function (src) {
        var type = new TypeQuestionnaireAdapter().toAPI(src.typeQuestionnaire);
        return {
            bValide: src.gereValidation,
            dGrille: src.dateSaisie,
            dValide: src.dateValidation,
            iFKExamen: src.examenID,
            iFKNautVisite: src.nautVisiteID,
            iFKVisProgramme: src.visProgrammeID,
            iFKVisite: src.visiteID,
            iPKQuestionnaire: src.questionnaireID,
            iScore: src.score,
            iScoreMax: src.scoreMax,
            questionnaireDefinition: type,
            sFKGrille: type.sPKGrille,
            sInformation: src.information,
            sInsert: "",
            sUpdate: "",
            tabTheme: src.themes && new ThemeAdapter().toAPIs(src.themes),
            tiersQuestionne: src.tiersQuestionne && new TiersAdapter().toAPI(src.tiersQuestionne),
            tiersValidation: src.tiersValidation && new TiersAdapter().toAPI(src.tiersValidation)
        };
    };
    return QuestionnaireAdapter;
}(Adapter));
export { QuestionnaireAdapter };
