import { IConstanteModel } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { apiGetConstanteListByVisiteID } from "modules/fiche-suivi/api/apiGetConstanteListByVisiteID";

export async function getConstanteListByVisiteID(
  visiteID: number
): Promise<IConstanteModel[]> {
  const api = getDefaultApiInstance();

  return (await apiGetConstanteListByVisiteID(api, visiteID)).data;
}
