var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Select } from "@ads/ui";
import { Button, Modal, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { CheckCircleOutlined, InfoCircleOutlined, CloseOutlined, } from "@ant-design/icons";
const Grid = styled.div `
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
`;
const GridItem = styled.div `
  display: flex;
  align-items: end;
  margin-bottom: 16px;

  &.center {
    justify-content: center;
  }
  &.document {
    width: 100%;
    align-items: baseline;
  }
  &.type-document {
    width: 75%;
  }
  &.button {
    width: 15%;
  }
`;
const DocumentName = styled.span `
  font-size: 1.4rem;
  padding-left: 1rem;
  ${({ theme }) => theme.media.lg} {
    font-size: 1.6rem;
  }
`;
export function ItemListUploader(props) {
    const [modalVisible, setModalVisible] = useState("");
    const makeChoix = () => {
        const choix = [];
        if (props.typesDoc) {
            props.typesDoc.forEach((e) => {
                choix.push({ value: e.id, content: e.libelle });
            });
        }
        return choix;
    };
    const [choicesState, setChoicesState] = useState(makeChoix());
    const [fileState, setFileState] = useState(props.data);
    const handleDelete = () => __awaiter(this, void 0, void 0, function* () {
        yield props.deleteDoc(props.data);
    });
    useEffect(() => {
        setChoicesState(makeChoix());
    }, [props.typesDoc]);
    useEffect(() => {
        setFileState(props.data);
    }, [props.data]);
    return (_jsxs(Grid, { children: [_jsxs(GridItem, Object.assign({ className: "document" }, { children: [_jsxs(Tooltip, Object.assign({ title: fileState.isDocumentExist
                            ? "Fichier synchronisé avec la GED"
                            : "Fichier Non Synchro" }, { children: [fileState.isDocumentExist && (_jsx(CheckCircleOutlined, { style: { fontSize: "25px", color: "#17b409" } }, void 0)), !fileState.isDocumentExist && (_jsx(InfoCircleOutlined, { style: { fontSize: "25px", color: "#b31010" } }, void 0))] }), void 0), _jsx(DocumentName, { children: props.data.URL }, void 0)] }), void 0), _jsx(GridItem, Object.assign({ className: "type-document" }, { children: _jsx(Select, { id: "item-list-ged" + props.data.URL, name: "choix-list-ged" + props.data.URL, onSelect: (value, option) => {
                        if (fileState.typeDocument.id !== value) {
                            props.modifyDoc(props.data, {
                                id: value,
                                libelle: option.children,
                            });
                        }
                    }, showSearch: true, filterOption: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, itemsSelect: choicesState, selectMode: "light", placeholder: props.data.typeDocument.libelle
                        ? props.data.typeDocument.libelle
                        : "Type de document", defaultValue: props.data.typeDocument.libelle
                        ? props.data.typeDocument.libelle
                        : "" }, void 0) }), void 0), _jsx(GridItem, Object.assign({ className: "center button", style: { top: "10rem" }, onClick: () => {
                    setModalVisible("deleteObjet");
                } }, { children: _jsx(Button, { type: "primary", color: "#524d72", disabled: props.stateLoading, shape: "circle", icon: _jsx(CloseOutlined, {}, void 0) }, void 0) }), void 0), _jsx(Modal, Object.assign({ title: "", visible: modalVisible === "deleteObjet", onOk: () => {
                    handleDelete();
                    setModalVisible("");
                }, onCancel: () => setModalVisible("") }, { children: _jsx("p", { children: "Voulez-vous supprimer le document ?" }, void 0) }), void 0)] }, void 0));
}
