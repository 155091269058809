import { Select } from "@ads/ui";
import styled from "styled-components";

const FilterProduitWrapper = styled.div``;

const Label = styled.h4``;
const FilterProduit = () => {
  return (
    <FilterProduitWrapper>
      <Label>Ajouter un produit</Label>
      <Select id="" name="" selectMode="light" />
    </FilterProduitWrapper>
  );
};

export default FilterProduit;
