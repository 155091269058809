import { apiGet } from "@ads/react-common-module";
import { TypePrestationAdapter } from "@ads/isadom-model";
export const apiGetTypePrestaByFamilleID = (api, familleID) => {
    if (api) {
        const adapter = new TypePrestationAdapter();
        return apiGet(api, `/familles/${familleID}/types-presta`, adapter);
    }
    return new Promise((_, reject) => {
        reject("La configration api est nulle !");
    });
};
