import { AdresseModel } from "@ads/isadom-model";
import { FormItemWrapper, Label, SelectV3, PrimaryButton } from "@ads/ui";
import { AnimatePresence, motion } from "framer-motion";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
import { useState } from "react";
import styled from "styled-components";
import FormulaireSaisieAdresse from "../organisms/FormulaireSaisieAdresse";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.media.md} {
    width: calc(100% / 3);
  }
  ${({ theme }) => theme.media.xl} {
    width: 100%;
  }
`;
const WrapperAdresse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
`;
const HeadersAdresse = () => {
  const { commande, adresses, setAdresse } = useFormulaireCommandesStore();
  const [isOpen, setIsOpen] = useState(false);

  if (!commande) {
    return null;
  }

  const options = adresses
    .filter((adresse: AdresseModel) => adresse.enCours)
    .map((adresse: AdresseModel) => ({
      value: `${adresse.adresseID}`,
      label: adresse.adresseComplete || adresse.adresse
    }));

  return (
    <Wrapper>
      <FormItemWrapper direction="vertical">
        <Label htmlFor="adresse">Adresse</Label>
        <SelectV3
          disabled={false}
          id="adresse"
          name="adresse"
          onSelect={(value) => {
            const adresse = adresses.find(
              (a) => a.adresseID === parseInt(value, 10)
            );
            if (adresse) {
              setAdresse(adresse);
            }
          }}
          showSearch
          filterOption={(input: string, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={options}
          selectMode={"light"}
          placeholder="Sélectionnez une adresse"
          defaultValue={
            commande?.adresse ? `${commande.adresse.adresseID}` : undefined
          }
          allowClear
        />
      </FormItemWrapper>
      <WrapperAdresse>
        <PrimaryButton
          as="button"
          buttonStyle="secondary"
          label="Saisir une adresse"
          size="S"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        />
      </WrapperAdresse>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: "0", opacity: 0 }}
            exit={{ height: "0", opacity: 0 }}
            animate={{ height: "100%", opacity: 1 }}
          >
            <FormulaireSaisieAdresse
              onFormAdresseSubmit={() => setIsOpen(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default HeadersAdresse;
