import React, { useEffect } from "react";
import { FormSelectionAppareil } from "./FormSelectionAppareil";
import { useFormSelectionAppareil } from "../hook";
export function FormSelectionAppareilAdapter({ formData, isFormDataLoading, submitSearch, notifyFormDataChanged, }) {
    if (!formData) {
        throw new Error("FormData is not instantiate");
    }
    const { natureSelectionService, typeProduitSelectionService, familleSelectionService, sousFamilleSelectionService, typeLocalisationService, localisationService, modeleService, setCodeOrRef, codeOrRef, disponibilite, setDisponibilite, } = useFormSelectionAppareil(formData === null || formData === void 0 ? void 0 : formData.config);
    useEffect(() => {
        console.log(formData.data);
        if (formData === null || formData === void 0 ? void 0 : formData.data) {
            submitSearch();
        }
    }, [formData.data]);
    return (React.createElement(FormSelectionAppareil, { natureSelectionService: natureSelectionService, typeProduitSelectionService: typeProduitSelectionService, familleSelectionService: familleSelectionService, sousFamilleSelectionService: sousFamilleSelectionService, typeLocalisationService: typeLocalisationService, localisationService: localisationService, modeleService: modeleService, codeOrRef: codeOrRef, setCodeOrRef: setCodeOrRef, notifyFormDataChanged: notifyFormDataChanged, formData: formData, disponibilite: disponibilite, setDisponibilite: setDisponibilite, isSearchLoading: isFormDataLoading }));
}
