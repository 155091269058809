import { Alert } from "antd";
import styled from "styled-components";

import { VisiteModel } from "@ads/isadom-model";
import { TypeRendezVous } from "interface/typeRendezVous";
import { getDateFr } from "utils/convertDateTime";
import RdvListItem from "components/ui/molecules/rdv/RdvListItem";
import WrapperError from "components/ui/organisms/wrappers/WrapperError";
import TitleList from "components/ui/atoms/titles/TitleList";
import WrapperExitAnimation from "components/core/animations/WrapperExitAnimation";

interface ListeRdvFeuilleDeRouteProps {
  selection: TypeRendezVous;
  items: any;
}

const WrapperTournee = styled(WrapperExitAnimation)`
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 3rem;
  }
`;

const WrapperRendezVous = styled.div`
  margin: 2.8rem 0;
  padding: 2.3rem 1.6rem;
  border-radius: 0.6rem;
  background-color: ${({ theme }) => theme.colors.neutral[130]};
  box-shadow: 0px 0px 1rem #c3bdb458;
`;

const ListeItemsFeuilleDeRoute = ({
  selection,
  items
}: ListeRdvFeuilleDeRouteProps) => {
  if (items.length === 0) {
    return (
      <WrapperError>
        <Alert
          message="Information"
          showIcon
          description="Aucun rendez-vous trouvé"
          type="info"
        />
      </WrapperError>
    );
  }

  type RendezVousByDate = {
    date: string;
    rdvList: VisiteModel[];
  };

  // Je parcours mes rdv et j'initialise mes valeurs précédentes à vide
  const rdvSortByDate: RendezVousByDate[] = items.reduce(
    (previousValue: RendezVousByDate[], currentValue: VisiteModel) => {
      // second passage
      if (previousValue?.length > 0) {
        // Je cherche si mon rdv est déjà présent dans les valeurs précédentes, je récupère l'index
        const index = previousValue.findIndex(
          (rendezVousByDate: RendezVousByDate) =>
            rendezVousByDate?.date === currentValue.date
        );

        // Si il y a déjà une correspondance de la date dans les valeurs précédentes (index !== -1)
        if (index !== -1) {
          // Je vais ajouter mon renvezVous à la liste des rdv présent à cette date
          const newRdvByDate: RendezVousByDate = {
            ...previousValue[index],
            rdvList: [...previousValue[index].rdvList, currentValue]
          };
          previousValue[index] = newRdvByDate;
          return [...previousValue];
        }

        // Sinon il faut ajouter une nouvelle entrée à la date du rendez vous
        const newRdvByDate: RendezVousByDate = {
          date: currentValue.date,
          rdvList: [currentValue]
        };
        return [...previousValue, newRdvByDate];
      }
      // premier passage
      else {
        const rendezVousByDateList: RendezVousByDate[] = [
          {
            date: currentValue.date,
            rdvList: [currentValue]
          }
        ];
        return rendezVousByDateList;
      }
    },
    []
  );

  return (
    <WrapperTournee>
      {rdvSortByDate.map((rdvByDate) => {
        if (selection === "en-cours") {
          return (
            <WrapperRendezVous
              className="bloc-rendez-vous"
              key={rdvByDate.date}
            >
              <TitleList>
                {`Visite${
                  rdvByDate.rdvList.length > 1 ? "s" : ""
                } du ${getDateFr(rdvByDate.date)}`}
              </TitleList>
              {rdvByDate.rdvList.map((rdv: any) => {
                return (
                  <RdvListItem
                    selection={selection}
                    visite={rdv}
                    key={rdv.visiteID}
                  />
                );
              })}
            </WrapperRendezVous>
          );
        }
        return rdvByDate.rdvList.map((rdv: any) => (
          <RdvListItem selection={selection} visite={rdv} key={rdv.visiteID} />
        ));
      })}
    </WrapperTournee>
  );
};

export default ListeItemsFeuilleDeRoute;
