import { EvenementRGPDModel } from "@ads/isadom-model";
import ReactQuill from "react-quill";
import { Informations } from "../../atoms/itemFields";

interface EvenementRGPDInformationsProps {
  evenement?: EvenementRGPDModel;
  onChange: (evenement: EvenementRGPDModel) => void;
}

export const EvenementRGPDInformationsForm = ({
  evenement,
  onChange,
}: EvenementRGPDInformationsProps) => (
  <Informations>
    <ReactQuill
      style={{ height: "80%" }}
      theme="snow"
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      }}
      defaultValue={evenement?.informations}
      onChange={(content) =>
        onChange({
          ...evenement,
          informations: content,
        } as EvenementRGPDModel)
      }
    />
  </Informations>
);
