var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { notification } from "antd";
import { apiEditeConsommableConfigurationVisite } from "../api/apiEditeConsommableConfigurationVisite";
import { apiRetireConsommableConfigurationVisite } from "../api/apiRetireConsommableConfigurationVisite";
export function retireConfigurationConsommable({ api, visiteID, item, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const configDTO = Object.assign(Object.assign({}, item), { bRetraitConfig: true });
        try {
            if (configDTO.bAjoutConfig) {
                const result = yield apiRetireConsommableConfigurationVisite(api, visiteID, configDTO.configCsoVisiteID);
                if (result.status === 200) {
                    notification.success({
                        key: `${item.configCsoVisiteID}`,
                        message: "Confirmation",
                        description: "Le consommable a bien été retiré.",
                    });
                    return true;
                }
                else {
                    notification.error({
                        key: `${item.configCsoVisiteID}`,
                        message: "Erreur",
                        description: "Le consommable n'a pas été retiré.",
                    });
                    return false;
                }
            }
            else {
                const result = yield apiEditeConsommableConfigurationVisite(api, visiteID, configDTO);
                if (result.bRetraitConfig) {
                    notification.success({
                        key: `${item.configCsoVisiteID}`,
                        message: "Confirmation",
                        description: "Le consommable a bien été retiré.",
                    });
                    return true;
                }
                else {
                    notification.error({
                        key: `${item.configCsoVisiteID}`,
                        message: "Erreur",
                        description: "Le consommable n'a pas été retiré.",
                    });
                    return false;
                }
            }
        }
        catch (err) {
            console.error("👨‍🚒 - Un erreur est survenue - retireConfigurationConsommable.tsx ", err);
            notification.error({
                key: `${item.configCsoVisiteID}`,
                message: "Erreur",
                description: "Le consommable n'a pas été retiré.",
            });
            return false;
        }
    });
}
