var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
var CompteurAdapter = /** @class */ (function (_super) {
    __extends(CompteurAdapter, _super);
    function CompteurAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CompteurAdapter.prototype.toClient = function (src) {
        return {
            produitDMId: src.iFKProduitDM,
            typeIntervention: src.sFKTypeIntervention,
            compteurPrecedent: src.iCompteurPrecedent,
            compteurActuel: src.iCompteurActuel,
            compteurCumule: src.iCompteurCumule,
            dateCompteurPrecedent: src.dCompteurPrecedent,
            compteurHS: src.bCompteurHS,
            termine: src.bTermine,
        };
    };
    CompteurAdapter.prototype.toAPI = function (src) {
        return {
            iFKProduitDM: src.produitDMId,
            sFKTypeIntervention: src.typeIntervention,
            iCompteurPrecedent: src.compteurPrecedent,
            iCompteurActuel: src.compteurActuel,
            iCompteurCumule: src.compteurCumule,
            dCompteurPrecedent: src.dateCompteurPrecedent,
            bCompteurHS: src.compteurHS,
            bTermine: src.termine,
        };
    };
    return CompteurAdapter;
}(Adapter));
export { CompteurAdapter };
