import { ProgrammeEducatifAdapter } from "@ads/isadom-model";
import {
  getDefaultApiInstance,
  useApiDeleteV3
} from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export interface ApiRAZPeListHookInput {
  visiteID: number;
}

export function useApiRAZPEList(criteria?: ApiRAZPeListHookInput) {
  const adapter = new ProgrammeEducatifAdapter();
  const uri = criteria
    ? `/visites/${criteria.visiteID}/raz/programmes-educatifs`
    : `/visites/:visiteID/raz/programmes-educatifs`;
  return useApiDeleteV3(getDefaultApiInstance(), uri, {
    errorStrategy: apiIsadomErrorStrategy,
    adaptResult(result) {
      return adapter.toClients(result.data);
    }
  });
}
