import { useFormSelectionItem } from "../../../../common";
import { getAllTypeTiers } from "../../../features/getAllTypesTiers";
export function useFormSelectionTypeTiersItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.typeTiersID;
        },
        initParams: undefined,
        config: input.config,
        isDataMatchWithFilter(_data, _filter) {
            return true;
        },
        onGetDataRequested: getAllTypeTiers,
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
