var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { AdresseAdapter } from "../../localisation/adapter/AdresseAdapter";
var TiersAdapter = /** @class */ (function (_super) {
    __extends(TiersAdapter, _super);
    function TiersAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.adresseAdapter = new AdresseAdapter();
        return _this;
    }
    TiersAdapter.prototype.toClient = function (src) {
        return {
            tiersID: src.iPKTiers,
            codeTiers: src.sCodeTiers,
            mail: src.sMail,
            nom: src.sNom,
            prenom: src.sPrenom,
            telgsm: src.sTelgsm,
            typeTiers: src.sTypeTiers,
            adressePrincipale: src.adressePrincipale &&
                this.adresseAdapter.toClient(src.adressePrincipale),
            tAdresse: src.tAdresse && this.adresseAdapter.toClients(src.tAdresse),
        };
    };
    TiersAdapter.prototype.toAPI = function (src) {
        return {
            iPKTiers: src.tiersID,
            adressePrincipale: src.adressePrincipale &&
                this.adresseAdapter.toAPI(src.adressePrincipale),
            sCodeTiers: src.codeTiers,
            sMail: src.mail,
            sNom: src.nom,
            sPrenom: src.prenom,
            sTelgsm: src.telgsm,
            sTypeTiers: src.typeTiers && src.typeTiers,
            tAdresse: src.tAdresse && this.adresseAdapter.toAPIs(src.tAdresse),
        };
    };
    return TiersAdapter;
}(Adapter));
export { TiersAdapter };
