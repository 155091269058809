var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { TypeProduitAdapter, FamilleAdapter } from ".";
import { MotifRetraitDMAdapter } from "../appareil";
var ModeleAdapter = /** @class */ (function (_super) {
    __extends(ModeleAdapter, _super);
    function ModeleAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeProduitAdapter = new TypeProduitAdapter();
        _this.motifRetraitDMAdapter = new MotifRetraitDMAdapter();
        _this.familleAdapter = new FamilleAdapter();
        return _this;
    }
    ModeleAdapter.prototype.toClient = function (src) {
        return {
            produitId: src.IDProduit,
            refProduit: src.sRefProduit,
            typeProduitFK: src.sFKTypeProduit,
            codeNatureProduit: src.sCodeNatureProduit,
            libelle: src.sLibelle,
            refFabricant: src.sRefFabricant,
            inactif: src.bInactif,
            previsionInactif: src.bPrevisionInactif,
            arretCdeInterne: src.dArretCdeInterne,
            arretCommande: src.bArretCommande,
            typeProduit: src.typeProduit && this.typeProduitAdapter.toClient(src.typeProduit),
            famille: src.famille && this.familleAdapter.toClient(src.famille),
            modeleId: src.iPKModele,
            suiviCompteur: src.bSuiviCompteur,
            motifRetraitDM: src.tabMotifRetraitDM &&
                this.motifRetraitDMAdapter.toClients(src.tabMotifRetraitDM),
        };
    };
    ModeleAdapter.prototype.toAPI = function (src) {
        return {
            IDProduit: src.produitId,
            sRefProduit: src.refProduit,
            sFKTypeProduit: src.typeProduitFK,
            sCodeNatureProduit: src.codeNatureProduit,
            sLibelle: src.libelle,
            sRefFabricant: src.refFabricant,
            bInactif: src.inactif,
            bPrevisionInactif: src.previsionInactif,
            dArretCdeInterne: src.arretCdeInterne,
            bArretCommande: src.arretCommande,
            typeProduit: src.typeProduit && this.typeProduitAdapter.toAPI(src.typeProduit),
            famille: src.famille && this.familleAdapter.toAPI(src.famille),
            iPKModele: src.modeleId,
            bSuiviCompteur: src.suiviCompteur,
            tabMotifRetraitDM: src.motifRetraitDM &&
                this.motifRetraitDMAdapter.toAPIs(src.motifRetraitDM),
        };
    };
    return ModeleAdapter;
}(Adapter));
export { ModeleAdapter };
