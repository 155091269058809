export class Observable {
    constructor() {
        this.observersMap = new Map();
    }
    setData(data) {
        this.data = data;
        this.notify(data);
    }
    getData() {
        return this.data;
    }
    subscribe(id, observer) {
        if (this.observersMap.has(id)) {
            throw new Error("Il y à déjà un observer avec cette id");
        }
        this.observersMap.set(id, observer);
    }
    unsubscribe(id) {
        this.observersMap.delete(id);
    }
    notify(data) {
        for (const observer of Array.from(this.observersMap.values())) {
            observer.onChange(data);
        }
    }
}
