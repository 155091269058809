var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { TypeFormulaireAdapter } from "./typeFormulaire/TypeFormulaireAdapter";
import { GroupAdapter } from './group/GroupAdapter';
var FormulaireAdapter = /** @class */ (function (_super) {
    __extends(FormulaireAdapter, _super);
    function FormulaireAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeFormAdapter = new TypeFormulaireAdapter();
        _this.groupAdapter = new GroupAdapter();
        return _this;
    }
    FormulaireAdapter.prototype.toClient = function (src) {
        return {
            groupes: this.groupAdapter.toClients(src.tGroupes),
            typeForm: this.typeFormAdapter.toClient(src.typeFormulaire),
            id: src.id,
        };
    };
    FormulaireAdapter.prototype.toAPI = function (src) {
        return {
            tGroupes: this.groupAdapter.toAPIs(src.groupes),
            typeFormulaire: this.typeFormAdapter.toAPI(src.typeForm),
            id: src.id,
        };
    };
    return FormulaireAdapter;
}(Adapter));
export { FormulaireAdapter };
