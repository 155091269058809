var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
import { RetraitAdapter } from ".";
import { AdresseAdapter, TiersAdapter } from "../../..";
import { AppareilAdapter } from ".";
import { LocalisationInterneAdapter } from "../../../localisation/adapter/LocalisationInterneAdapter";
import { TypePrestationAdapter } from "../../../medical/prestation/adapter";
var ConfigAppSpecTiersVisiteAdapter = /** @class */ (function (_super) {
    __extends(ConfigAppSpecTiersVisiteAdapter, _super);
    function ConfigAppSpecTiersVisiteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.appareilAdapter = new AppareilAdapter();
        _this.adresseAdapter = new AdresseAdapter();
        _this.localisationInterneAdapter = new LocalisationInterneAdapter();
        _this.typePrestationAdapter = new TypePrestationAdapter();
        _this.retraitAdapter = new RetraitAdapter();
        _this.tiersAdapter = new TiersAdapter();
        return _this;
    }
    ConfigAppSpecTiersVisiteAdapter.prototype.toClient = function (src) {
        if (!src) {
            return null;
        }
        return {
            produit: this.appareilAdapter.toClient(src.produit),
            sTypeConfig: src.sTypeConfig,
            dateInstallation: src.dateInstallation,
            adresseInstallation: this.adresseAdapter.toClient(src.adresseInstallation),
            dateRetrait: src.dateRetrait,
            localisationRetrait: this.localisationInterneAdapter.toClient(src.localisationRetrait),
            preponderance: src.preponderance,
            typePrestation: this.typePrestationAdapter.toClient(src.typePrestation),
            bAbsent: src.bAbsent,
            bPresent: src.bPresent,
            bAjout: src.bAjout,
            bRetrait: src.bRetrait,
            retrait: this.retraitAdapter.toClient(src.retrait),
            tiers: this.tiersAdapter.toClient(src.tiers),
        };
    };
    ConfigAppSpecTiersVisiteAdapter.prototype.toAPI = function (src) {
        if (!src) {
            return null;
        }
        return {
            produit: this.appareilAdapter.toAPI(src.produit),
            sTypeConfig: src.sTypeConfig,
            dateInstallation: src.dateInstallation,
            adresseInstallation: this.adresseAdapter.toAPI(src.adresseInstallation),
            dateRetrait: src.dateRetrait,
            localisationRetrait: this.localisationInterneAdapter.toAPI(src.localisationRetrait),
            preponderance: src.preponderance,
            typePrestation: this.typePrestationAdapter.toAPI(src.typePrestation),
            bAbsent: src.bAbsent,
            bPresent: src.bPresent,
            bAjout: src.bAjout,
            bRetrait: src.bRetrait,
            retrait: this.retraitAdapter.toAPI(src.retrait),
            tiers: this.tiersAdapter.toAPI(src.tiers),
        };
    };
    return ConfigAppSpecTiersVisiteAdapter;
}(Adapter));
export { ConfigAppSpecTiersVisiteAdapter };
