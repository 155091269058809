import styled from "styled-components";

const TextCodeAnnuaire = styled.div`
  margin-top: 0.4rem;
  font-family: ${({ theme }) => theme.font.type.primaryMedium};
  font-size: 1.7rem;
  line-height: 2.6rem;
`;

export default TextCodeAnnuaire;
