import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormItemWrapper, Label, SelectWrapper, } from "@ads/ui";
import { Select } from "antd";
const { Option } = Select;
export function FormSelectionItem(props) {
    const defaultSelectedDataIds = [];
    for (const defaultSelectedData of props.config.defaultSelectedData) {
        defaultSelectedDataIds.push(props.getKeyFromData(defaultSelectedData));
    }
    const selectedDataIds = [];
    for (const selectedData of props.selectedData) {
        selectedDataIds.push(props.getKeyFromData(selectedData));
    }
    return props.config.visible ? (_jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: props.libelle }, { children: props.libelle }), void 0), _jsx(SelectWrapper, Object.assign({ selectMode: "light", isLoading: props.loading }, { children: _jsx(Select, Object.assign({ allowClear: true, showSearch: true, loading: props.loading, mode: props.config.typeSelection === "multiple" ? "multiple" : undefined, onSelect: (value) => {
                        props.notifyDataSelected(value);
                    }, disabled: !props.config.allowChange, onDeselect: (value) => {
                        props.notifyDataUnSelected(value);
                    }, onClear: props.notifyDataSelectedReset, defaultValue: defaultSelectedDataIds, value: selectedDataIds, filterOption: (input, option) => {
                        if ((option === null || option === void 0 ? void 0 : option.children) && !(option.children instanceof Array)) {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }
                        else {
                            return false;
                        }
                    } }, { children: props.data.map((item) => (_jsx(Option, Object.assign({ value: props.getKeyFromData(item) }, { children: props.getOptionLibelle(item) }), props.getKeyFromData(item)))) }), void 0) }), void 0)] }), void 0)) : (_jsx(_Fragment, {}, void 0));
}
