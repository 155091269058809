import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { adsNotification, FormItemWrapper, Label, theme, FormWidget, } from "@ads/ui";
import { ThemeMolecule } from "./ThemeMolecule";
import { Card, Input } from "../atoms";
import { GridLayout, TitleMain } from "..";
import { Alert, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import { useState } from "react";
export function QuestionnaireDetailMolecule(props) {
    const { disableActions, questionnaire } = props;
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const onThemeChangeListener = {
        onThemeChanged: (theme) => {
            if (questionnaire && questionnaire.themes) {
                for (let i = 0; i < (questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.themes.length); i++) {
                    if ((questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.themes[i].themeID) === theme.themeID) {
                        questionnaire.themes[i] = theme;
                        props.onQuestionnaireChange(questionnaire);
                    }
                }
            }
        },
    };
    const onInformationChanged = (information) => {
        if (!questionnaire)
            return;
        questionnaire.information = information;
        props.onQuestionnaireChange(questionnaire);
    };
    const onClickDelete = () => {
        if (props.performDelete) {
            props
                .performDelete()
                .then(() => {
                const pathname = window.location.pathname.split("/");
                pathname.pop();
                history.push(pathname.join("/"));
            })
                .catch((err) => adsNotification.error({
                message: err,
                duration: theme.duration.notification,
            }));
        }
    };
    const saveQuestionnaire = () => {
        if (props.performUpdate && questionnaire) {
            console.log(questionnaire);
            setIsLoading(true);
            props
                .performUpdate(questionnaire)
                .then(() => {
                adsNotification.success({
                    message: "Modification sauvegardée avec succès.",
                    duration: theme.duration.notification,
                });
                const pathname = window.location.pathname.split("/");
                pathname.pop();
                history.push(pathname.join("/"));
            })
                .catch((err) => console.error(err))
                .finally(() => setIsLoading(false));
        }
        else {
            adsNotification.success({
                message: "Une erreur est survenue lors de la modification. Veuillez essayer à nouveau.",
                duration: theme.duration.notification,
            });
        }
    };
    let handleSave = undefined;
    let handleCancel = undefined;
    let handleDelete = undefined;
    if (!props.disableActions) {
        handleSave = saveQuestionnaire;
        handleCancel = props.cancelLocalChange;
        handleDelete = onClickDelete;
    }
    if (!props.performDelete) {
        handleDelete = undefined;
        console.log("on utilise pas de performDelete");
    }
    if (questionnaire) {
        return (_jsxs(FormWidget, Object.assign({ handleSave: handleSave, handleCancel: handleCancel, handleDelete: handleDelete, isLoadingSave: isLoading }, { children: [_jsx(TitleMain, { children: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.typeQuestionnaire.libelle }, void 0), _jsx("br", {}, void 0), _jsx(Card, { children: _jsxs(GridLayout, Object.assign({ nbColumn: 3, columnGap: "20px" }, { children: [_jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "sTest" }, { children: "Programme \u00E9ducatif" }), void 0), _jsx(Input, { type: "string", id: "programme", name: "programme", selectMode: "light", defaultValue: "", disabled: true }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "sTest" }, { children: "Score" }), void 0), _jsx(Input, { type: "string", id: "score", name: "score", selectMode: "light", value: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.score, disabled: true }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "sTest" }, { children: "Information" }), void 0), _jsx(Input, { type: "string", id: "information", name: "information", selectMode: "light", onChange: (event) => onInformationChanged(event.target.value), value: questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.information, disabled: disableActions }, void 0)] }), void 0)] }), void 0) }, void 0), (questionnaire === null || questionnaire === void 0 ? void 0 : questionnaire.themes) &&
                    questionnaire.themes.map((theme, key) => (_jsx(ThemeMolecule, { listener: onThemeChangeListener, theme: theme, disableActions: disableActions }, key)))] }), void 0));
    }
    else if (props.questionnaireLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0)] }, void 0));
    }
    else if (props.fetchError) {
        return (_jsx(Alert, { message: "", showIcon: true, description: "Erreur lors de la r\u00E9cup\u00E9ration du questionnaire. Veuillez essayer \u00E0 nouveau. Si le probl\u00E8me persiste, contactez votre administrateur.", type: "error" }, void 0));
    }
    else {
        return (_jsx(Alert, { message: "", showIcon: true, description: "Une erreur inconnue est survenue. Veuillez essayer \u00E0 nouveau. Si le probl\u00E8me persiste, contactez votre administrateur.", type: "error" }, void 0));
    }
}
