import { useState } from "react";
import styled from "styled-components";
// Interface
import { VisiteAPI, VisiteDTOAPI } from "@ads/isadom-model";
// Functions
import {
  getTimeFromDate,
  getTimeFromBackTime,
  getDateFr
} from "utils/convertDateTime";
import checkIsVisite from "utils/type-guards/checkIsVisite";
// Components - Molecules
import CardRdvList from "./CardRdvList";
import Infos from "./Infos";
import WrapperBtnPatient from "./WrapperBtnPatient";
// Components - ATOM
import { TypeRendezVous } from "../../../../interface/typeRendezVous";
import DeleteVisite from "./DeleteVisite";
import TextAdresse from "../../atoms/text/TextAdresse";
import TextCodeAnnuaire from "../../atoms/text/TextCodeAnnuaire";
import TextMobile from "../../atoms/text/TextMobile";
import TextNom from "../../atoms/text/TextNom";
import TextTime from "../../atoms/text/TextTime";
import ModalDossierPatient from "../ModalDossierPatient";
import BtnEdit from "components/ui/atoms/buttons/ButtonEdit";
import { IconEdit } from "@ads/ui";
import { useHistory } from "react-router-dom";

const TypesVisite = styled.div<{ total: number }>`
  display: grid;
  grid-area: prestations;
  grid-template-columns: 1fr;
  text-align: right;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.media.xl} {
    padding: 0 3rem;

    .isOpenLeft & {
      padding-left: 0;
      margin-top: 2rem;
    }
  }

  @media (min-width: 1330px) {
    grid-area: prestations;
    display: block;
    text-align: inherit;
    margin: 0;
    padding: 0 4rem;
  }

  .title {
    display: none;
    gap: 8px;
    text-transform: uppercase;
    font-family: PoppinsMedium, sans-serif;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-align: left;

    .type-visite {
      width: 26rem;
    }

    ${({ theme }) => theme.media.xl} {
      display: flex;
    }
  }
`;

const WrapperRdv = styled.div`
  position: relative;
`;

const WrapperTypeVisitePrestations = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 1.6rem;

  @media (min-width: 1440px) {
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Type = styled.span`
  display: grid;
  grid-template-columns: 1fr;
  grid-area: prestations;
  font-family: PoppinsMedium, sans-serif;
  line-height: 2rem;
  font-size: 1.4rem;
  text-align: left;

  ${({ theme }) => theme.media.sm} {
    position: static;
    display: block;
    width: 26rem;
    margin: 0rem;
    line-height: 1.25em;
  }
`;

const ListPrestations = styled("ul")`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  flex-wrap: wrap;
  align-items: flex-start;

  ${({ theme }) => theme.media.lg} {
    align-items: left;
  }

  @media (min-width: 1330px) {
    flex-direction: row;
  }
`;

const Prestation = styled.div`
  padding: 0.5rem 1.7rem 0.6rem;
  background: ${(props) => props.theme.colors.neutral[130]};
  border-radius: 1.4rem;
  font-size: 1.4rem;
  font-family: PoppinsMedium, sans-serif;
  text-align: center;
`;

interface PropsRdvListItem {
  selection: TypeRendezVous;
  visite: VisiteDTOAPI;
}

const RdvListItem: React.FC<PropsRdvListItem> = ({
  selection,
  visite
}: PropsRdvListItem): JSX.Element => {
  const isVisite = checkIsVisite(visite);
  const [openDossierPatient, setOpenDossierPatient] = useState<boolean>(false);

  const horaireRdv = visite.hDebut ? getTimeFromBackTime(visite.hDebut) : "";
  const horaireDebutVisite = isVisite
    ? (visite as VisiteAPI).hDebut.slice(1, 0)
    : "";
  const horaireFinVisite = isVisite
    ? visite.hFin && getTimeFromDate(visite.hFin)
    : "";

  // Si la date de la visite est differente de celle du rdv
  const dateFR =
    isVisite &&
    visite.Rdv.date !== visite.date &&
    visite.date !== "0000-00-00" &&
    getDateFr(visite.Rdv.date);
  const history = useHistory();

  return (
    <WrapperRdv>
      {selection === "en-cours" && <DeleteVisite visiteId={visite.visiteID} />}

      <CardRdvList
        selection={selection}
        iPKVisite={visite.visiteID || visite.rendezvousID}
      >
        {selection === "en-cours" && (
          <BtnEdit
            as="button"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.preventDefault();
              event.stopPropagation();
              history.push("/visites/" + visite.visiteID + "/modifier");
            }}
          >
            <IconEdit iconColor="#524D72" iconSize="2rem" />
            Modifier
          </BtnEdit>
        )}

        <TextTime className="text-time">
          <span className="time-item">{horaireDebutVisite || horaireRdv}</span>
          {horaireFinVisite && horaireFinVisite !== "Invalid date" && (
            <span className="time-item">{horaireFinVisite}</span>
          )}
          {dateFR && dateFR !== "Date non renseignée" && (
            <span className="date-differente">{`(Rendez-vous du ${dateFR})`}</span>
          )}
        </TextTime>

        <Infos className="infos">
          {dateFR && dateFR !== "Date non renseignée" && (
            <span className="infos__date-differente">{`(Rendez-vous du ${dateFR})`}</span>
          )}
          <TextNom className="nom">{visite.tiersNom}</TextNom>
          <TextCodeAnnuaire className="code-annuaire">
            {visite.tiersCode}
          </TextCodeAnnuaire>
          <TextAdresse className="text-adresse">
            {visite.adresse}
            <br />
            {`${visite.codePostal} ${visite.ville}`}
          </TextAdresse>
          {visite.telephoneFixe && (
            <TextMobile className="telephone">{visite.telephoneGSM}</TextMobile>
          )}
        </Infos>

        <TypesVisite
          className="types-visite"
          total={visite.tTypeVisite?.length || 0}
        >
          <div className="title">
            <p className="type-visite">
              Type de visite ({visite.tTypeVisite?.length})
            </p>
            <p className="type-prestation">Type prestation</p>
          </div>

          {visite.tTypeVisite?.map((typeVisite) => (
            <WrapperTypeVisitePrestations
              className="wrapper-type-visite-prestations"
              key={`${visite.visiteID} ${typeVisite.libelle}`}
            >
              <Type className="type">{typeVisite.libelle}</Type>
              {typeVisite.tTypePrestation?.length > 0 && (
                <>
                  <ListPrestations className="list-prestations">
                    {typeVisite.tTypePrestation?.map((typePrestation) => (
                      <li>
                        <Prestation className="prestation">
                          {typePrestation.libelle}
                        </Prestation>
                      </li>
                    ))}
                  </ListPrestations>
                </>
              )}
            </WrapperTypeVisitePrestations>
          ))}
        </TypesVisite>
        <WrapperBtnPatient className="wrapper-btn-patient">
          <ModalDossierPatient
            patientID={visite.tiersID}
            setOpenModal={setOpenDossierPatient}
            opened={openDossierPatient}
          />
        </WrapperBtnPatient>
      </CardRdvList>
    </WrapperRdv>
  );
};

export default RdvListItem;
