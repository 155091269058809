import {
  TypeTiersModel,
  TypeTiersAdapter,
  HttpResponseAdapter,
  HttpResponseModel,
} from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";

export function apiGetTypesTiers(): Promise<
  HttpResponseModel<TypeTiersModel[]>
> {
  const api = getDefaultApiInstance();
  if (api) {
    const adapter = new HttpResponseAdapter(new TypeTiersAdapter());
    return apiGet(api, `/types-tiers`, adapter) as Promise<
      HttpResponseModel<TypeTiersModel[]>
    >;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
