import { Alert } from "antd";

export interface ErrorViewProps {
  message: string;
}

export function ErrorView({ message }: ErrorViewProps) {
  const core = `${message} Si le problème persiste contacter votre administateur.`;

  return <Alert type="error" message={core} />;
}
