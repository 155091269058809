import { ConsommablesConfigurationAjouteTemplate, ConsommablesLivraisonsTemplate, ContainerConsommableConfiguration, HistoriqueConsommationTemplate } from '@ads/module-consommables-visite'
import ProtectedRoute from 'components/core/routes/ProtectedRoute'
import { RouteParam } from 'interface/core/RouteParam';
import ContainerConsommableConfigurationDetail from 'modules/consommable/component/containers/ContainerConsommableConfigurationDetail';
import ContainerRetourConsommable from 'modules/consommable/component/containers/ContainerRetourConsommable';
import { SelectionConsommableConfigConso, SelectionLivraisonConsommable } from "modules/consommable/component/selection";
import { Switch, useParams } from 'react-router-dom';
import { useGetVisiteQuery } from 'store/redux/services/nomadApi';

const RouterConsommable = () => {
  const { iPKVisite } = useParams<RouteParam>();
  const { data: visite } = useGetVisiteQuery(+iPKVisite);

  return (
    <Switch>
      <ProtectedRoute exact path="/visites/:iPKVisite/consommables/configuration">
        <ContainerConsommableConfiguration
          visiteID={+iPKVisite}
          SelectionConsommable={SelectionConsommableConfigConso}
        />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path="/visites/:iPKVisite/consommables/configuration/:configConsoID"

      >
        <ContainerConsommableConfigurationDetail />
      </ProtectedRoute>

      <ProtectedRoute exact path="/visites/:iPKVisite/consommables/livraisons">
        {visite && <ConsommablesLivraisonsTemplate
          SelectionLivraisonConsommable={
            SelectionLivraisonConsommable
          }
          visiteID={+iPKVisite}
          intervenantID={visite.intervenantPrincipal.iPKTiers}
        />}
      </ProtectedRoute>

      <ProtectedRoute exact path="/visites/:iPKVisite/consommables/retours">
        <ContainerRetourConsommable />
      </ProtectedRoute>

      <ProtectedRoute exact path="/visites/:iPKVisite/consommables/historique">
        {visite && <HistoriqueConsommationTemplate
          visiteID={+iPKVisite}
          date={visite.date}
          tiersID={visite.tiersVisite.iPKTiers}
        />}
      </ProtectedRoute>

      <ProtectedRoute
        path="/visites/:iPKVisite/consommables/ajoute/:consommableID"
        exact
      >
        <ConsommablesConfigurationAjouteTemplate
          visiteID={+iPKVisite} />
      </ProtectedRoute>
    </Switch>
  )
}

export default RouterConsommable