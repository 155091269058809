var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { apiGetConsommableByProduitID } from "../api/apiGetConsommableByProduitID";
import { getDefaultApiInstance } from "@ads/react-common-module";
export const useGetConsommableByProduitID = (produitID) => {
    const [consommablesDTOModel, setConsommablesDTOModel] = useState();
    const [consommablesErrors, setConsommablesErrors] = useState();
    const [isConsommablesLoading, setIsConsommablesLoading] = useState(false);
    const api = getDefaultApiInstance();
    const getConsommable = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsConsommablesLoading(true);
        yield apiGetConsommableByProduitID(api, produitID)
            .then((result) => {
            setConsommablesDTOModel(result.data[0]);
            setConsommablesErrors(null);
            setIsConsommablesLoading(false);
        })
            .catch((err) => {
            console.error("👨‍🚒 une erreur est survenue dans useGetConsommableByProduitID ", err);
            setConsommablesErrors(err);
            setIsConsommablesLoading(false);
        });
    });
    useEffect(() => {
        getConsommable();
    }, []);
    return { consommablesDTOModel, isConsommablesLoading, consommablesErrors };
};
