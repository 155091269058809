var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ConsommableAdapter, LotAdapter, } from "../../../produit/consommable/adapter";
import { TypePrestationAdapter } from "../../../medical/prestation/adapter";
import { Adapter } from "../../../_adapter";
import { AppareilAdapter } from "../../../produit/appareil/adapter/AppareilAdapter";
import { AffectationProduitAdapter } from "./AffectationProduitAdapter";
var LivraisonVisiteAdapter = /** @class */ (function (_super) {
    __extends(LivraisonVisiteAdapter, _super);
    function LivraisonVisiteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.consommableAdapter = new ConsommableAdapter();
        _this.lotAdapter = new LotAdapter();
        _this.typePrestationAdapter = new TypePrestationAdapter();
        _this.appareilAdapter = new AppareilAdapter();
        _this.affectationProduitAdapter = new AffectationProduitAdapter();
        return _this;
    }
    LivraisonVisiteAdapter.prototype.toClient = function (src) {
        return {
            ligneLivraisonID: src.ligneLivraisonID,
            consommable: this.consommableAdapter.toClient(src.consommable),
            iQuantite: src.iQuantite,
            lot: src.lot && this.lotAdapter.toClient(src.lot),
            typePrestation: src.typePrestation &&
                this.typePrestationAdapter.toClient(src.typePrestation),
            appareil: src.appareil && this.appareilAdapter.toClient(src.appareil),
            affectationProduit: src.affectationProduit &&
                this.affectationProduitAdapter.toClient(src.affectationProduit),
        };
    };
    LivraisonVisiteAdapter.prototype.toAPI = function (src) {
        return {
            ligneLivraisonID: src.ligneLivraisonID,
            consommable: this.consommableAdapter.toAPI(src.consommable),
            iQuantite: src.iQuantite,
            lot: src.lot && this.lotAdapter.toAPI(src.lot),
            typePrestation: src.typePrestation &&
                this.typePrestationAdapter.toAPI(src.typePrestation),
            appareil: src.appareil && this.appareilAdapter.toAPI(src.appareil),
            affectationProduit: src.affectationProduit &&
                this.affectationProduitAdapter.toAPI(src.affectationProduit),
        };
    };
    return LivraisonVisiteAdapter;
}(Adapter));
export { LivraisonVisiteAdapter };
