import {
  HttpResponseAdapter,
  HttpResponseModel,
  IConstanteAdapter,
  IConstanteModel,
} from "@ads/isadom-model";
import { apiGet, ApiInstance } from "@ads/react-common-module";

export async function apiGetConstanteListByVisiteID(
  api: ApiInstance | null,
  visiteID: number
): Promise<HttpResponseModel<IConstanteModel[]>> {
  if (api) {
    const adapter = new HttpResponseAdapter(new IConstanteAdapter());
    return apiGet(api, `/visites/${visiteID}/constantes`, adapter) as Promise<
      HttpResponseModel<IConstanteModel[]>
    >;
  } else {
    throw new Error("La configuration d'api est null");
  }
}
