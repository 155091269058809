import { Controller, useFormContext } from "react-hook-form";
import { FormItemWrapper, Label, SelectWrapper } from "@ads/ui";
import styled from "styled-components";
import { Select } from "antd";
import { AdresseAPI } from "@ads/isadom-model";
import { VisiteAPI } from "@ads/isadom-model";

const InfosAdresse = styled.span`
  font-weight: bold;
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral[130]};
  width: 100%;
  border-radius: 1rem;
  padding: 2rem;
`;

interface Props {
  adresseInstallation: AdresseAPI | undefined;
  visite: VisiteAPI;
}

export interface FieldsAdresse {
  adresseInstallation: number;
  adresse: number | undefined;
}

/**
 * Le composant SelectAdresse affiche dans un Select, à l'aide de la variable tAdresse,
 * les adresses du tiers.
 * Sinon il affiche la seule adresse connue.
 * @param configAppareil
 *  @param idProduit
 */
const SelectAdresse = ({ adresseInstallation, visite }: Props): JSX.Element => {
  const methods = useFormContext<FieldsAdresse>();

  const adresses = visite.tiersVisite.tAdresse.map((adresse) => ({
    value: adresse.iPKAdresse,
    label: adresse.sAdresseComplete,
  }));

  if (visite) {
    adresses?.push({
      value: visite.tiersVisite.adressePrincipale.iPKAdresse,
      label: visite.tiersVisite.adressePrincipale.sAdresseComplete,
    });
  }

  if (visite.tiersVisite.tAdresse.length > 0) {
    return (
      <FormItemWrapper direction="vertical">
        <Label htmlFor="type">Adresse</Label>

        <SelectWrapper selectMode="light">
          <Controller
            name="adresseInstallation"
            control={methods.control}
            defaultValue={adresseInstallation?.iPKAdresse}
            render={() => (
              <Select
                defaultValue={adresseInstallation?.sAdresseComplete}
                options={adresses}
              />
            )}
          />
        </SelectWrapper>
      </FormItemWrapper>
    );
  } else {
    return (
      <Wrapper>
        <InfosAdresse className="label">Adresse : </InfosAdresse>
        {`${adresseInstallation?.sAdresseComplete}`}
      </Wrapper>
    );
  }
};

export default SelectAdresse;
