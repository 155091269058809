// Retourne les initiales d'une personne à partir de son prénom et nom
export const getInitials = (sPrenom?: string, sNom?: string): string => {
  const firstname = sPrenom?.trim();
  const lastname = sNom?.trim();
  let initials = "";

  if (firstname?.indexOf("-")) {
    firstname?.split("-").forEach((part) => {
      initials += part[0];
    });
  } else {
    initials += firstname ? firstname[0] : "";
  }

  if (lastname?.indexOf(" ")) {
    lastname?.split(" ").forEach((part) => {
      initials += part[0];
    });
  } else {
    initials += lastname ? lastname[0] : "";
  }

  return initials.toUpperCase();
};
