import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const Wrapper = styled("div") `
  .label {
    display: block;
    font-weight: 700;
    ${({ theme }) => theme.media.md} {
      display: inline-block;
      min-width: 12rem;
    }
  }
  .destinataires {
    .destinataire {
      .separator {
        display: inline-block;
        margin-right: 0.8rem;
      }
      &:last-child {
        .separator {
          display: none;
        }
      }
    }
  }
`;
const Corps = styled("div") `
  ${({ theme }) => theme.media.md} {
    display: grid;
    grid-template-columns: 12rem auto;
  }
`;
const MessageLectureSeule = ({ message, }) => {
    return (_jsxs(Wrapper, { children: [_jsxs("p", { children: [_jsx("span", Object.assign({ className: "label" }, { children: "Type d'objet :" }), void 0), " ", message.typeObjet.libelle] }, void 0), _jsxs("p", { children: [_jsx("span", Object.assign({ className: "label" }, { children: "Objet :" }), void 0), " ", message.objet] }, void 0), _jsxs("p", Object.assign({ className: "destinataires" }, { children: [_jsx("span", Object.assign({ className: "label" }, { children: "Destinataires :" }), void 0), message.destinatairesPrincipaux.map((destinataire) => (_jsxs("span", Object.assign({ className: "destinataire" }, { children: [destinataire.nom, "\u00A0", destinataire.prenom, _jsx("span", Object.assign({ className: "separator" }, { children: "," }), void 0)] }), void 0)))] }), void 0), _jsxs(Corps, { children: [_jsx("span", Object.assign({ className: "label" }, { children: "Corps :" }), void 0), _jsx("div", { children: message.corps }, void 0)] }, void 0)] }, void 0));
};
export default MessageLectureSeule;
