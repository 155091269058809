import { EvenementStandardModel } from "@ads/isadom-model";
import styled from "styled-components";

import {
  EvenementStandardDetails,
  EvenementStandardInformations,
} from "../../molecules/evenementStandard";

const InformationsGenerales = styled.div``;

const Content = styled.div`
  display: flex;
  width: 100%;
`;

interface EvenementStandardInformationsGeneralesProps {
  evenement: EvenementStandardModel;
}

export const EvenementStandardInformationsGenerales = ({
  evenement,
}: EvenementStandardInformationsGeneralesProps) => (
  <InformationsGenerales>
    <Content>
      <EvenementStandardInformations informations={evenement.informations} />
      <EvenementStandardDetails evenement={evenement} />
    </Content>
  </InformationsGenerales>
);
