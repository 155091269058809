import styled from "styled-components";
export const Grid = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  max-width: 1080px;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
export const GridItem = styled.div ``;
export const GridLong = styled.div `
  ${({ theme }) => theme.media.md} {
    grid-area: 3 / 1 / 3 / 3;
  }

  ${({ theme }) => theme.media.lg} {
    grid-area: 3 / 1 / 4 / 4;
  }
`;
