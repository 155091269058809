import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ModeReglageAPI } from "@ads/isadom-model";
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";

import {
  useGetAppareilQuery,
  useGetAppareilVisiteQuery,
  useGetDefinitionsGrillesReglagesQuery,
  useGetValeursPrecedentesGrillesQuery,
  useGetValeursSaisiesGrillesQuery,
} from "store/redux/services/nomadApi";

/**
 * Retourne toutes les données pour remplir les grilles
 * @param iPKTiers
 * @param IDProduit
 * @param iPKVisite
 * @param shouldRefetch - booléen qui va déclencher les appels backs et mettre à jour les données après la soumission du formulaire
 */
const useGrilles = (
  shouldRefetch = false,
  setShouldRefetch: Dispatch<SetStateAction<boolean>>,
  iPKTiers?: number,
  IDProduit?: string,
  iPKVisite?: string
) => {
  const initialState: ModesReglagesAppareil = {
    libelle: "",
    idAppareilDeMesure: "",
    idPrestation: "",
    idModele: 0,
    idFamille: "",
    modes: [],
    modesPrecedents: [],
    modesSaisis: [],
    modeActif: "",
    grilleActive: 0,
    bAfficheTheoriqueInactif: false,
  };

  const [modesReglagesAppareil, setModesReglagesAppareil] =
    useState<ModesReglagesAppareil>(initialState);

  const { data: configAppareil } = useGetAppareilVisiteQuery(
    {
      visiteID: iPKVisite,
      appID: IDProduit,
    },
    {
      skip: !iPKVisite || !IDProduit,
    }
  );

  const {
    data: grillesDefinitionData,
    isLoading: grillesDefinitionLoading,
    error: grillesDefinitionError,
  } = useGetDefinitionsGrillesReglagesQuery({
    iPKTiers,
    iPKAppareil: IDProduit,
  });

  const {
    data: grillesDataPrecedent,
    isLoading: grillesLoadingPrecedent,
    error: grillesErrorPrecedent,
  } = useGetValeursPrecedentesGrillesQuery({
    iPKTiers,
    iPKAppareil: IDProduit,
  });

  const {
    data: grillesDataSaisies,
    isLoading: grillesLoadingVisite,
    error: grillesErrorVisite,
    refetch,
  } = useGetValeursSaisiesGrillesQuery({ iPKVisite, iPKAppareil: IDProduit });

  if (shouldRefetch) {
    refetch();
    setShouldRefetch(false);
  }

  const {
    data: appareil,
    isLoading: appareilLoading,
    error: appareilError,
  } = useGetAppareilQuery(IDProduit);

  useEffect(() => {
    // Si certaines grilles n'ont pas de valeurs saisies, on initialise la grille pour pouvoir la saisie au niveau du champ input
    const grillesSaisies: ModeReglageAPI[] | undefined =
      grillesDefinitionData?.map((grilleDefinition) => {
        // Si j'ai ma grille dans ma définition et dans ma saisie je retourne la saisie
        const grilleDataSaisie =
          grillesDataSaisies &&
          grillesDataSaisies.find(
            (grilleDataSaisie) =>
              grilleDataSaisie.reglageModeID === grilleDefinition.reglageModeID
          );

        return grilleDataSaisie || grilleDefinition;
      });

    if (
      appareil &&
      grillesDefinitionData &&
      grillesDataPrecedent &&
      grillesDataSaisies
    ) {
      // idPrestation, on prend celui de grille saisie ou précédente
      const idPrestation =
        Array.isArray(grillesDataSaisies) && grillesDataSaisies.length > 0
          ? grillesDataSaisies[0]?.typePrestationID
          : Array.isArray(grillesDataPrecedent) &&
            grillesDataPrecedent.length > 0
          ? grillesDataPrecedent[0]?.typePrestationID
          : ""; // [0] car toutes les grilles ont le même id de prestation

      const modesReglagesAppareil: ModesReglagesAppareil = {
        ...initialState,
        idPrestation,
        idFamille: appareil.famille.sPKFamille,
        idModele: appareil.modele.IDProduit,
        modes: grillesDefinitionData,
        modesPrecedents: grillesDataPrecedent,
        modesSaisis: grillesSaisies || grillesDataSaisies,
        modeActif:
          grillesDefinitionData.length > 0
            ? grillesDefinitionData[0].reglageModeID
            : "",
        grilleActive: 0,
      };

      setModesReglagesAppareil(modesReglagesAppareil);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appareil,
    grillesDefinitionData,
    grillesDataPrecedent,
    grillesDataSaisies,
  ]);

  return {
    appareil,
    modesReglagesAppareil,
    setModesReglagesAppareil,
    configAppareil,
    isLoading:
      grillesDefinitionLoading ||
      grillesLoadingPrecedent ||
      grillesLoadingVisite ||
      appareilLoading,
    hasError:
      grillesDefinitionError ||
      grillesErrorPrecedent ||
      grillesErrorVisite ||
      appareilError,
  };
};

export default useGrilles;
