import styled from "styled-components";

import { EvenementTelesuiviModel } from "@ads/isadom-model";

import { Checkbox, Table } from "antd";

import { TableWrapper } from "../../molecules/TableWrapper";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";

const AppareilsTable = styled(Table)`
  margin-top: 2rem;
`;

const ModeleName = styled.div`
  font-weight: bold;
`;

interface EvenementTelesuiviTableauAppareilsProps {
  evenement?: EvenementTelesuiviModel;
  loading?: boolean;
  handleChange?: (evenement: EvenementTelesuiviModel) => void;
}

export const EvenementTelesuiviTableauAppareils = ({
  evenement,
  loading,
  handleChange = undefined,
}: EvenementTelesuiviTableauAppareilsProps) => {
  const dataSource: any[] = [];
  const objDate = new Intl.DateTimeFormat("fr-FR");

  if (evenement?.appareilsTelesuivis) {
    evenement.appareilsTelesuivis.forEach((appareilTelesuivis) => {
      dataSource.push({
        key: appareilTelesuivis.appareil.produitId,
        modele: appareilTelesuivis.appareil.modele.libelle,
        reference: appareilTelesuivis.appareil.refProduitAppareil,
        ns: appareilTelesuivis.appareil.numeroSerie,
        installation:
          appareilTelesuivis.dateInstallation &&
            appareilTelesuivis.dateInstallation !== "0000-00-00"
            ? objDate.format(new Date(appareilTelesuivis.dateInstallation))
            : " - ",
        retrait:
          appareilTelesuivis.dateRetrait &&
            appareilTelesuivis.dateRetrait !== "0000-00-00"
            ? objDate.format(new Date(appareilTelesuivis.dateRetrait))
            : " - ",
        estObservance: appareilTelesuivis.estObservance,
        patientAnonyme: appareilTelesuivis.estPatientAnonyme,
        appairage:
          appareilTelesuivis.dateAppairage &&
            appareilTelesuivis.dateAppairage !== "0000-00-00"
            ? objDate.format(new Date(appareilTelesuivis.dateAppairage))
            : " - ",
        numeroECN: appareilTelesuivis.numeroECNPatient || " - ",
      });
    });
  }

  const columns = [
    {
      title: "Informations",
      key: "informations",
      responsive: ["xs"] as Breakpoint[],
      render: (record: any) => {
        if (record) {
          return (
            <>
              <ModeleName>{record.modele}</ModeleName>
              {record.reference}
            </>
          );
        }
      },
    },
    {
      title: "Modèle",
      dataIndex: "modele",
      key: "modele",
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Référence",
      dataIndex: "reference",
      key: "reference",
      responsive: ["sm"] as Breakpoint[],
      render: (value: string) => {
        return <div className="center-cell">{value}</div>;
      },
    },
    {
      title: "N/S",
      dataIndex: "ns",
      key: "ns",
      responsive: ["sm"] as Breakpoint[],
      render: (value: string) => {
        return <div className="center-cell">{value}</div>;
      },
    },
    {
      title: "Installation",
      dataIndex: "installation",
      key: "installation",
      responsive: ["lg"] as Breakpoint[],
      render: (value: string) => {
        return <div className="center-cell">{value}</div>;
      },
    },
    {
      title: "Retrait",
      dataIndex: "retrait",
      key: "retrait",
      responsive: ["lg"] as Breakpoint[],
      render: (value: string) => {
        return <div className="center-cell">{value}</div>;
      },
    },
    {
      title: evenement?.estDecision ? "Modem actif" : "Card to cloud",
      key: "estObservance",
      render: (record: any) => {
        return (
          <div className="center-cell">
            <Checkbox
              checked={record.estObservance}
              disabled={!handleChange}
              onChange={(event) => {
                handleChange &&
                  handleChange({
                    ...evenement,
                    appareilsTelesuivis: evenement?.appareilsTelesuivis.map(
                      (appareilTelesuivis) =>
                        appareilTelesuivis.appareil.produitId === record.key
                          ? {
                            ...appareilTelesuivis,
                            estObservance: event.target.checked,
                          }
                          : appareilTelesuivis
                    ),
                  } as EvenementTelesuiviModel);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Patient anonyme",
      key: "patientAnonyme",
      render: (record: any) => {

        return (
          <div className="center-cell">
            <Checkbox
              checked={record.patientAnonyme}
              disabled={!handleChange}
              onChange={(event) => {
                handleChange &&
                  handleChange({
                    ...evenement,
                    appareilsTelesuivis: evenement?.appareilsTelesuivis.map(
                      (appareilTelesuivis) =>
                        appareilTelesuivis.appareil.produitId === record.key
                          ? {
                            ...appareilTelesuivis,
                            estPatientAnonyme: event.target.checked,
                          }
                          : appareilTelesuivis
                    ),
                  } as EvenementTelesuiviModel);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Appairage",
      dataIndex: "appairage",
      key: "appairage",
      responsive: ["lg"] as Breakpoint[],
      render: (value: string) => {
        return <div className="center-cell">{value}</div>;
      },
    },
    {
      title: "Numéro ECN",
      dataIndex: "numeroECN",
      key: "numeroECN",
      responsive: ["lg"] as Breakpoint[],
      render: (value: string) => {
        return <div className="center-cell">{value}</div>;
      },
    },
  ];

  return (
    <TableWrapper>
      <AppareilsTable
        size="middle"
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        loading={loading}
      />
    </TableWrapper>
  );
};
