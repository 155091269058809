import { useState } from "react";
import { useFormSelectionTypeTiersItem } from "../../../../type-tiers";
export function useFormSelectionTiers(input) {
    const [qui, setQui] = useState(input.quiConfig.defaultData);
    const [ou, setOu] = useState(input.ouConfig.defaultData);
    const serviceTypeTiers = useFormSelectionTypeTiersItem({
        config: input.typesTiersConfig,
        initParams: undefined
    });
    const result = {
        typesTiersServices: serviceTypeTiers,
        ouServices: {
            ou: ou,
            setOu: setOu
        },
        quiServices: {
            qui: qui,
            setQui: setQui
        }
    };
    return result;
}
