import { ProgrammeEducatifModel } from "@ads/isadom-model";
import {  DataSourceGetResult, useGetWithCache } from "@ads/react-common-module";
import { useSelectPEStore } from "../components/context/PEContext";
import { useSyncPEByVisiteIDWithContext } from "./useSyncPEByVisiteIDWithContext";

export function useGetPEListByVisiteID(visiteID: number): DataSourceGetResult<ProgrammeEducatifModel[]> {

    const { peList } = useSelectPEStore()
    return useGetWithCache<ProgrammeEducatifModel[]>({
        criteria: undefined,
        getFromCache: () => peList,
        getFromRemote: () => useSyncPEByVisiteIDWithContext(visiteID)
    })
}