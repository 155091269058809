import {
  apiDelete,
  getDefaultApiInstance,
} from "@ads/react-common-module";


export function apiDeleteEvenement(
  visiteID: number,
  evenementVisiteId: number,
): Promise<any> {
  const api = getDefaultApiInstance();
  if (api) {
    return apiDelete(
      api, 
      `visites/${visiteID}/evenements/${evenementVisiteId}`
    );
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
