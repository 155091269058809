import { Observable } from "./Observable";
export class ApplicationContext {
    constructor(connectedUser, appSettings) {
        this.connectedUser = new Observable();
        this.appSettings = new Observable();
        this.appSettings.setData(appSettings);
        this.connectedUser.setData(connectedUser);
    }
    static instantiate(userConnected, appSettings) {
        if (this.instance === null) {
            this.instance = new ApplicationContext(userConnected, appSettings);
        }
        return this.instance;
    }
    static getInstance() {
        if (this.instance === null) {
            throw new Error("L'application context n'est pas instantié. Veuillez appeler la méthode instantiate");
        }
        return this.instance;
    }
    setUserConnected(user) {
        this.connectedUser.setData(user);
    }
    getConnectedUser() {
        return this.connectedUser;
    }
    setAppSettings(settings) {
        this.appSettings.setData(settings);
    }
    getAppSettings() {
        return this.appSettings;
    }
    getTheme() {
        var _a;
        const theme = (_a = ApplicationContext.getInstance()
            .getAppSettings()
            .getData()) === null || _a === void 0 ? void 0 : _a.theme;
        return theme;
    }
}
ApplicationContext.instance = null;
