import {
  DiagnostiqueEducatifDetailModel,
  DiagnostiqueEducatifModel
} from "@ads/isadom-model";
import { UseFormHookService } from "@ads/react-common-module";
import { FormWidget, Label, Loading, Textarea } from "@ads/ui";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
  transition: all 0.2s ease-in-out;
`;

const TextAreaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

const TextAreaWrapper = styled.div`
  width: 48%;
  margin-top: 2rem;
`;

export function DiagnosticEducatifFormAdapter({
  formData,
  saveService,
  hasChanged,
  notifyFormDataChanged,
  deleteServices
}: UseFormHookService<DiagnostiqueEducatifModel>) {
  function handleValueChange(value: string, id: number) {
    if (formData) {
      const detailArray: DiagnostiqueEducatifDetailModel[] =
        formData.diagnostiqueDetail;
      for (let i = 0; i < detailArray.length; i++) {
        if (detailArray[i].id === id) {
          detailArray[i].valeur = value;
        }
      }
      notifyFormDataChanged({
        ...formData,
        diagnostiqueDetail: detailArray
      });
    }
  }

  return (
    <FormWidget
      handleSave={
        hasChanged
          ? () => (formData ? saveService.request(formData) : undefined)
          : undefined
      }
      handleDelete={() => {
        deleteServices?.delete();
      }}
      deleteLabel="RàZ"
      isLoadingDelete={deleteServices?.loading}
      isLoadingSave={saveService.loading}
    >
      <Wrapper>
        <TextAreaContainer>
          {!formData && <Loading>Chargement du détail en cours.</Loading>}
          {formData &&
            formData.diagnostiqueDetail.map((value, key) => (
              <TextAreaWrapper key={key}>
                <Label htmlFor="">{value.libelle}</Label>
                <Textarea
                  id=""
                  name=""
                  value={value.valeur}
                  onChange={(e: any) => {
                    handleValueChange(e.target.value, value.id);
                  }}
                />
              </TextAreaWrapper>
            ))}
        </TextAreaContainer>
      </Wrapper>
    </FormWidget>
  );
}
