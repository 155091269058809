// Libs
import { useState } from "react";
import styled from "styled-components";
import { NavLink, useParams } from "react-router-dom";
import { Loading, PrimaryButton } from "@ads/ui";
import { Alert } from "antd";
// Interfaces
import { RouteParam } from "interface/core/RouteParam";
// Hooks
import useGrilles from "modules/appareils/hooks/useGrilles";
import useHeaderAppareil from "modules/appareils/hooks/useHeaderAppareil";
// Components
import UIModeReglage from "modules/appareils/ui/organisms/ModeReglage";
import PanelsAppareil from "modules/appareils/ui/organisms/PanelsAppareil";
import BreadcrumbAppareil from "modules/appareils/ui/organisms/BreadcrumbAppareil";
import ErrorAppareil from "modules/appareils/ui/molecules/ErrorAppareil";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.colors.neutral[130]};
`;

const WrapperButton = styled(NavLink)`
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
  ${({ theme }) => theme.media.md} {
    margin-top: inherit;
    position: absolute;
    top: -1.5rem;
    right: 0rem;
  }
`;

const WrapperBreadcrumb = styled("div")`
  position: relative;
`;

const DetailAppareil = () => {
  const { subTabActive, iPKVisite, IDProduit } = useParams<RouteParam>();
  const [shouldRefetch, setShouldRefetch] = useState(false); // Permet de déclencer un appel back après soumission du formulaire et ainsi obtenir des données à jour.

  const { data: visite } = useGetVisiteQuery(+iPKVisite);

  const iPKTiers = visite?.tiersVisite.iPKTiers;

  const {
    appareil,
    modesReglagesAppareil,
    setModesReglagesAppareil,
    configAppareil,
    isLoading,
    hasError
  } = useGrilles(
    shouldRefetch,
    setShouldRefetch,
    iPKTiers,
    IDProduit,
    iPKVisite
  );

  const headerAppareil = useHeaderAppareil(
    iPKVisite,
    configAppareil?.produit,
    IDProduit,
    appareil?.famille.bSuiviReglage || false
  );

  if (hasError) {
    return <ErrorAppareil />;
  }

  if (!visite) {
    return (
      <Alert message="Erreur lors du chargement des informations de la visite. Veuillez essayer à nouveau." />
    );
  }

  return (
    <>
      {isLoading ? (
        <Loading>Chargement en cours de l'appareil</Loading>
      ) : (
        appareil && (
          <WrapperBreadcrumb>
            <BreadcrumbAppareil
              breadcrumbItems={headerAppareil.breadcrumbItems}
            />
            {!configAppareil?.bAjout && (
              <WrapperButton
                to={`/visites/${iPKVisite}/appareils/configuration/${IDProduit}/retrait`}
              >
                <PrimaryButton
                  as="button"
                  type="button"
                  buttonStyle="danger"
                  size="S"
                  label="Retirer"
                  disabled={configAppareil?.bAbsent}
                />
              </WrapperButton>
            )}
            <PanelsAppareil
              headerAppareil={headerAppareil}
              appareil={appareil}
              modesReglagesAppareil={modesReglagesAppareil}
              setModesReglagesAppareil={setModesReglagesAppareil}
              visite={visite}
            />
            <Wrapper>
              {subTabActive === "reglages" && (
                <UIModeReglage
                  shouldRefetch={shouldRefetch}
                  setShouldRefetch={setShouldRefetch}
                  modesReglagesAppareil={modesReglagesAppareil}
                  setModesReglagesAppareil={setModesReglagesAppareil}
                />
              )}
            </Wrapper>
          </WrapperBreadcrumb>
        )
      )}
    </>
  );
};

export default DetailAppareil;
