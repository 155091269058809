import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import api from "api";
import { v4 } from "uuid";
import {
  HistoriqueDTO,
  Preponderance
} from "modules/appareils/interfaces/appareils.interfaces";

interface GetHistoriqueProps {
  iPKTiers: number | undefined;
  dDate: string | undefined;
}

function transformPreponderance(preponderance: Preponderance): string {
  if (preponderance === "Alterné") {
    return "A";
  }
  if (preponderance === "Principal") {
    return "P";
  }
  return "";
}

const useGetHistoriqueFromAPI = ({ iPKTiers, dDate }: GetHistoriqueProps) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback<any>(
    ({ iPKTiers, dDate }: GetHistoriqueProps) => {
      const url = `tiers/${iPKTiers}/historiques/${dDate}/appareils`;
      setLoading(true);

      api
        .get(url)
        .then((response) => {
          const { data } = response;

          const historiques = data.map((historique: HistoriqueDTO) => ({
            key: v4(),
            refProduit: historique.produitID,
            famille: historique.libelleFamille,
            modele: historique.modeleID,
            preponderance: transformPreponderance(historique.preponderance),
            installation: new Date(historique.dInstallation).toLocaleDateString(
              "fr"
            ),
            rappelCommentairesDm: historique.commentaireInstallation,
            retrait: new Date(historique.dRetrait).toLocaleDateString("fr"),
            rappelLocalisation: historique.commentaireRetrait,
            affectation: historique.affectation
          }));

          setData(historiques);
        })
        .catch((error: any) => {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 500) {
              setError("Erreur interne du serveur.");
            } else {
              setError(error.response?.data || "Erreur inconnue");
            }
          } else {
            setError(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    if (iPKTiers && dDate) {
      fetchData({ iPKTiers, dDate });
    }
  }, [iPKTiers, dDate, fetchData]);

  return { fetchData, data, error, loading };
};

export default useGetHistoriqueFromAPI;
