import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  height: 7rem;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  & svg {
    display: block;
    height: 7rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 12.6rem;

    & svg {
      height: 12.6rem;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    height: 18.2rem;

    & svg {
      height: 18.2rem;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    height: 23rem;

    & svg {
      height: 23rem;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    height: 29rem;

    & svg {
      height: 29rem;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    height: 36.9rem;

    & svg {
      height: 36.9rem;
    }
  }
`;

const Wave = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default function Waves() {
  return (
    <Wrapper>
      <Wave xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 320">
        <path
          fill="#eba250"
          fillOpacity="0.5"
          d="M0,96L40,106.7C80,117,160,139,240,128C320,117,400,75,480,58.7C560,43,640,53,720,80C800,107,880,149,960,149.3C1040,149,1120,107,1200,101.3C1280,96,1360,128,1920,144L1920,160L1920,640L1920,640C1360,640,1280,640,1200,640C1120,640,1040,640,960,640C880,640,800,640,720,640C640,640,560,640,480,640C400,640,640,640,240,640C160,640,80,640,40,640L0,640Z"
        ></path>
      </Wave>
      <Wave xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 320">
        <path
          fill="#eba250"
          fillOpacity="0.5"
          d="M0,288L60,261.3C120,235,240,181,360,181.3C480,181,600,235,720,240C840,245,960,203,1080,208C1200,213,1320,267,1380,293.3L1920,640L1920,640L1380,640C1320,640,1200,640,1080,640C960,640,840,640,720,640C600,640,480,640,360,640C240,640,120,640,60,640L0,640Z"
        ></path>
      </Wave>
    </Wrapper>
  );
}
