import { BlocNoteVisiteModel } from "@ads/isadom-model";
import {
  apiDeleteBlocNoteVisite,
  apiGetBlocNoteByVisiteID,
  BlocNoteForm,
  IBlocNoteListFilterHookInput,
  ListBlocNoteFilter,
  useBlocNoteFilter
} from "@ads/module-blocnote";
import { Alert } from "antd";
import ProtectedRoute from "components/core/routes/ProtectedRoute";
import { RouteParam } from "interface/core/RouteParam";
import { Switch, useParams } from "react-router-dom";
import { BlocNoteTemplateListHeader } from "./ui/BlocNoteTemplateListHeader";

export function RouterBlocNote() {
  const { iPKVisite } = useParams<RouteParam>();

  const inputHook: IBlocNoteListFilterHookInput<
    BlocNoteVisiteModel,
    number,
    ListBlocNoteFilter
  > = {
    filter: function (
      filterState: ListBlocNoteFilter,
      item: BlocNoteVisiteModel
    ): boolean {
      if (filterState.libelleTypeBlocNote === "") {
        return true;
      } else {
        return item.typeNote.libelle === filterState.libelleTypeBlocNote;
      }
    },
    getDataFunction: apiGetBlocNoteByVisiteID,
    fetchParams: parseInt(iPKVisite),
    data: [],
    isEquals: function (
      itemCompare: BlocNoteVisiteModel,
      itemToCompare: BlocNoteVisiteModel
    ): boolean {
      return itemCompare.idBlocNoteVisite === itemToCompare.idBlocNoteVisite;
    },
    update: null,
    delete: apiDeleteBlocNoteVisite,
    isDeletable: function (note: BlocNoteVisiteModel): boolean {
      return note.id === 0;
    }
  };

  const hookService = useBlocNoteFilter(inputHook);


  return (
    <Switch>
      <ProtectedRoute exact path="/visites/:iPKVisite/bloc-notes">
        <BlocNoteTemplateListHeader
          visiteID={parseInt(iPKVisite)}
          service={hookService}
        />
      </ProtectedRoute>
      <ProtectedRoute exact path="/visites/:iPKVisite/bloc-notes/:blocNoteID">
        <BlocNoteDetailRouter />
      </ProtectedRoute>
    </Switch>
  );
}

function BlocNoteDetailRouter() {
  const { blocNoteID, iPKVisite } = useParams<RouteParam>();
  if (blocNoteID) {
    return (
      <BlocNoteForm visiteID={parseInt(iPKVisite)} blocNoteID={blocNoteID} />
    );
  } else {
    return (
      <Alert
        message="Error"
        showIcon
        description="Une erreur est survenue pendant le chargement de la note."
        type="error"
      />
    );
  }
}
