import { ProgrammeEducatifModel } from "@ads/isadom-model";

export function compareProgrammeEducatif(
  initFormData: ProgrammeEducatifModel,
  newFormData: ProgrammeEducatifModel
): number {
  return initFormData.id === newFormData.id &&
    initFormData.commentaire === newFormData.commentaire &&
    initFormData.dDebut === newFormData.dDebut &&
    initFormData.dFin == newFormData.dFin
    ? 0
    : -1;
}
