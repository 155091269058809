import { Select } from "antd";
import { FormItemWrapper, Label } from "@ads/ui";
import { ModeReglageAPI } from "@ads/isadom-model";

import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import { FC } from "react";

interface Props {
	modesReglagesAppareil: ModesReglagesAppareil;
	setModesReglagesAppareil: React.Dispatch<
		React.SetStateAction<ModesReglagesAppareil>
	>;
	disabled?: boolean;
}

const SelectModesReglage: FC<Props> = ({
	modesReglagesAppareil,
	setModesReglagesAppareil,
	disabled = false,
}: Props) => {
	const handleChangeModeReglage = (idMode: string) => {
		setModesReglagesAppareil({
			...modesReglagesAppareil,
			modeActif: idMode,
			grilleActive: 0,
		});
	};

	return (
		<FormItemWrapper direction="vertical">
			<Label htmlFor="mode">Mode</Label>
			<Select
				id="mode"
				value={modesReglagesAppareil.modeActif}
				onChange={(value) => handleChangeModeReglage(value)}
				disabled={disabled}
			>
				{modesReglagesAppareil.modes.map((mode: ModeReglageAPI) => (
					<Select.Option key={mode.reglageModeID} value={mode.reglageModeID}>
						{mode.reglageModeID}
					</Select.Option>
				))}
			</Select>
		</FormItemWrapper>
	);
};

export default SelectModesReglage;
