var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { adsNotification } from "@ads/ui";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { useEffect, useState } from "react";
import { apiDeleteDocumentByVisiteAndDocumentID, apiGetDocumentsByVisiteID, apiGetTypesDocuments, apiPostDataDocumentByVisiteAndDocumentID, apiPostMetaDataDocumentByVisiteID, apiPutMetaDataDocumentByVisiteIDAndDocumentID, } from "../../../api";
import { DocumentUploadStatus, } from "./interfaces/IDocumentWithStatus";
import { v4 as uuid } from "uuid";
export function useUploaderDocumentVisite(input) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUpload, setIsLoadingUpload] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [typesDocuments, setTypesDocuments] = useState([]);
    const isEqual = (file1, file2) => {
        return file1.idDocFromFile === file2.idDocFromFile;
    };
    const api = getDefaultApiInstance();
    const getDoc = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = (e) => __awaiter(this, void 0, void 0, function* () {
                const newDoc = {
                    documentDATA: e.target.result.substr(e.target.result.indexOf(",") + 1),
                    id: 0,
                    typeDocument: {
                        id: "",
                        libelle: "",
                    },
                    URL: file.name,
                    isDocumentExist: false,
                    status: DocumentUploadStatus.TO_SYNC,
                    idDocFromFile: uuid(),
                };
                resolve(newDoc);
            });
            reader.readAsDataURL(file);
        });
    };
    const addDocument = (newFile) => {
        setSelectedFiles([...selectedFiles, newFile]);
    };
    const addDocuments = (fileList) => {
        fileList.forEach((doc) => __awaiter(this, void 0, void 0, function* () {
            addDocument(yield getDoc(doc));
        }));
    };
    const modifyDoc = (file) => {
        const tempListFiles = selectedFiles.map((f) => {
            if (isEqual(file, f)) {
                return file;
            }
            return f;
        });
        setSelectedFiles(tempListFiles);
    };
    const modifyTypeDoc = (file, newTypeDoc) => {
        const newFile = file;
        newFile.typeDocument = newTypeDoc;
        newFile.status = DocumentUploadStatus.TO_SYNC;
        modifyDoc(file);
    };
    const deleteDocument = (file) => {
        const documentVisiteID = file.visiteID;
        if (documentVisiteID) {
            apiDeleteDocumentByVisiteAndDocumentID(input.visiteId, documentVisiteID)
                .then(() => {
                adsNotification.success({ message: "Document supprimé de la Ged !" });
                const tempList = selectedFiles.filter((f) => !isEqual(f, file));
                setSelectedFiles(tempList);
            })
                .catch(() => {
                adsNotification.error({ message: "Erreur lors de la suppression" });
            });
        }
        else {
            const tempList = selectedFiles.filter((f) => !isEqual(f, file));
            setSelectedFiles(tempList);
        }
    };
    const saveDocumentData = (message) => {
        if (message.document.documentDATA) {
            apiPostDataDocumentByVisiteAndDocumentID(api, message.idVisite, message.idDocument, message.document.documentDATA)
                .then(() => {
                adsNotification.success({
                    message: "Ajout du fichier réussi",
                });
                message.document.status = DocumentUploadStatus.SYNC;
                message.document.isDocumentExist = true;
                message.document.visiteID = message.idDocument;
                console.log(message.document);
                modifyDoc(message.document);
            })
                .catch(() => {
                adsNotification.error({
                    message: "Erreur lors de l'upload du document ",
                });
            });
        }
    };
    const saveDocument = (docToSave) => {
        if (docToSave.isDocumentExist && docToSave.visiteID) {
            const result = apiPutMetaDataDocumentByVisiteIDAndDocumentID(input.visiteId, docToSave.visiteID, docToSave)
                .then((_result) => {
                adsNotification.success({
                    message: "Mise à jour des méta-données de :" + docToSave.URL,
                });
            })
                .catch(() => {
                adsNotification.error({
                    message: "Erreur lors de la mise à jour des méta-données de : " +
                        docToSave.URL,
                });
            });
            return result;
        }
        else {
            const result = apiPostMetaDataDocumentByVisiteID(api, input.visiteId, docToSave).then((response) => {
                console.log(response);
                console.log(docToSave);
                saveDocumentData({
                    document: docToSave,
                    idDocument: response.data.visiteID
                        ? response.data.visiteID
                        : response.data.id,
                    idVisite: input.visiteId,
                });
            });
            return result;
        }
    };
    const saveDocuments = () => __awaiter(this, void 0, void 0, function* () {
        setIsLoadingUpload(true);
        const mapPromises = selectedFiles.map((e, _i) => {
            if (e.status === DocumentUploadStatus.TO_SYNC) {
                if (e.typeDocument.id === "") {
                    adsNotification.error({
                        message: "Le type de document est absent du document :" + e.URL,
                    });
                    return new Promise((resolve, _reject) => {
                        resolve(e);
                    });
                }
                else {
                    return saveDocument(e);
                }
            }
            return new Promise((resolve, _reject) => {
                resolve(e);
            });
        });
        Promise.all(mapPromises).finally(() => {
            setIsLoadingUpload(false);
        });
    });
    useEffect(() => {
        setIsLoading(true);
        apiGetTypesDocuments(api)
            .then((response) => {
            setTypesDocuments(response.data);
            setIsLoading(false);
        })
            .catch(() => {
            adsNotification.error({
                message: "Erreur lors de la récupération des types de documents disponible",
            });
            setIsLoading(false);
        });
        apiGetDocumentsByVisiteID(api, input.visiteId)
            .then((result) => {
            const tempList = [];
            result.data.forEach((element) => tempList.push(Object.assign(Object.assign({}, element), { status: DocumentUploadStatus.SYNC, idDocFromFile: uuid(), isDocumentExist: true })));
            setSelectedFiles(tempList);
            setIsLoading(false);
        })
            .catch(() => {
            adsNotification.error({
                message: "Erreur lors de la récupération de la GED visite",
            });
            setIsLoading(false);
        });
    }, []);
    return {
        data: selectedFiles,
        typeDocuments: typesDocuments,
        isLoading: isLoading,
        isLoadingUpload: isLoadingUpload,
        addDocuments: addDocuments,
        deleteDocument: deleteDocument,
        modifyTypeDoc: modifyTypeDoc,
        saveDocument: saveDocument,
        saveDocuments: saveDocuments,
    };
}
