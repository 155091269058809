import { useState } from "react";
import { Button, Icons } from "@ads/ui";
import { Modal, notification } from "antd";
// import { useHistory, useParams } from "react-router-dom";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
// import { RouteParam } from "interface/core/RouteParam";

interface ButtonResetCommandeProps {
  resetData: () => void;
}

export const ButtonResetCommande = ({
  resetData
}: ButtonResetCommandeProps) => {
  // const { iPKVisite } = useParams<RouteParam>();
  const { isLoadingReset } = useFormulaireCommandesStore();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const history = useHistory();

  const handleConfirmRaZ = async () => {
    try {
      await resetData();
      // history.push(`/visites/${iPKVisite}/commandes/`);
    } catch (error: any) {
      notification.error({
        message: "Erreur",
        description: "La commande n'a pas pu être réinitialisée.",
        duration: 20
      });
    }
  };

  return (
    <>
      <Button
        as="button"
        type="button"
        buttonStyle={isLoadingReset ? "neutral130" : "secondary"}
        size="S"
        icon={isLoadingReset ? undefined : Icons.Chrono}
        iconPosition="left"
        label="RàZ"
        onClick={() => setIsModalVisible(true)}
        isLoading={isLoadingReset}
        disabled={isLoadingReset}
      />

      <Modal
        mask={false}
        visible={isModalVisible}
        onOk={() => {
          handleConfirmRaZ();
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        Attention la supression de vos modifications est irréversible.
        <br /> Souhaitez-vous continuer ?
      </Modal>
    </>
  );
};
