import { QuestionnaireModelBuilder, QuestionnaireModelStatus, } from "@ads/isadom-model";
import { useEffect, useState } from "react";
import { useQuestionnaireListVisite } from ".";
import { useGetQuestionnaireObliagtoireByVisiteID } from "../../..";
import { useGetTypeQuestionnaireObligatoireByVisiteID } from "../../../api/useGetTypeQuestionnaireObligatoireByVisiteID";
export function useQuestionnaireObligatoireListVisite(visiteID, tiersVisiteID) {
    const [typesQuestionnairesObligatoires, setTypesQuestionnairesObligatoires] = useState([]);
    const typeQuestionnairesObligatoiresResult = useGetTypeQuestionnaireObligatoireByVisiteID(visiteID);
    const service = useQuestionnaireListVisite(visiteID, tiersVisiteID, useGetQuestionnaireObliagtoireByVisiteID, false);
    useEffect(() => {
        //Ajout des questionnaires obligatoires non saisie à l'IHM.
        if (service.questionnaires) {
            const questionnaireNonPresent = new Array();
            for (const typeQuestionnaire of typesQuestionnairesObligatoires) {
                let find = false;
                for (const questionnaire of service.questionnaires) {
                    if (questionnaire.typeQuestionnaire.typeQuestionnaireID ===
                        typeQuestionnaire.typeQuestionnaireID) {
                        find = true;
                    }
                }
                if (!find) {
                    const builder = new QuestionnaireModelBuilder();
                    builder
                        .setTypeQuestionnaire(typeQuestionnaire)
                        .setStatus(QuestionnaireModelStatus.TO_CREATE_ON_CLICK);
                    const questionnaire = builder.build();
                    questionnaireNonPresent.push(questionnaire);
                }
            }
            service.setBaseQuestionnaires([
                ...service.questionnaires,
                ...questionnaireNonPresent,
            ]);
        }
        //Ajout des filtres pour les questionnaires obligatoires
        const filterLibelle = new Array();
        for (const typeQuestionnaire of typesQuestionnairesObligatoires) {
            filterLibelle.push(typeQuestionnaire.libelle);
        }
        service.addFiltersOptions(filterLibelle);
    }, [typesQuestionnairesObligatoires, service.questionnaires]);
    useEffect(() => {
        //MAJ des types de questionnaires obligatories en fonction du resultat de l'appel API
        let typeFilter = [];
        if (!typeQuestionnairesObligatoiresResult.isLoading &&
            typeQuestionnairesObligatoiresResult.data) {
            //Obligatoire de passer par une variable temporaire pour éviter un clignotement
            //Le temps que le setTypesQuestionnairesObligatoires la fonction filter est
            //déclanché sans avoir les types de questionnaires obligatoire
            typeFilter = typeQuestionnairesObligatoiresResult.data;
            setTypesQuestionnairesObligatoires(typeFilter);
        }
    }, [typeQuestionnairesObligatoiresResult.isLoading]);
    const optionHeader = {
        title: service.questionnaires && service.questionnaires.length > 0
            ? service.questionnaires.length > 1
                ? service.questionnaires.length + " questionnaires obligatoires "
                : "1 questionnaire obligatoire"
            : "Aucun questionnaire obligatoire",
    };
    return {
        optionsList: optionHeader,
        questionnairesLoading: service.questionnairesLoading,
        fetchError: service.fetchError,
        questionnaires: service.questionnaires,
        filter: service.filter,
        createQuestionnaire: service.createQuestionnaire,
        createQuestionnaireFromType: service.createQuestionnaireFromType,
        addFiltersOptions: service.addFiltersOptions,
        setBaseQuestionnaires: service.setBaseQuestionnaires,
    };
}
