import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Input } from "antd";
const QteInput = styled(Input) `
  min-width: 3em;
  width: 4em;
  text-align: center;
`;
const Label = styled.label `
  margin-right: 1.6rem;
  white-space: nowrap;
`;
const ContainerForms = styled.form `
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const FormRetourVisiteQuantite = ({ retour, disabled, lot, refreshData, }) => {
    const handleChange = (e) => {
        const iQuantite = parseInt(e.target.value, 10);
        if (Number.isNaN(iQuantite) || iQuantite < 0) {
            return;
        }
        const data = Object.assign(Object.assign({}, retour), { iQuantite });
        refreshData(data);
    };
    const handleFocus = (e) => {
        e.target.select();
    };
    const uniqueID = `qte-${retour.ligneRetourID}`;
    return (_jsxs(ContainerForms, { children: [!lot && _jsx(Label, Object.assign({ htmlFor: uniqueID }, { children: "Quantit\u00E9 reprise :" }), void 0), _jsx(QteInput, { type: "text", onChange: handleChange, onFocus: handleFocus, id: uniqueID, name: uniqueID, value: retour.iQuantite, disabled: disabled }, void 0)] }, void 0));
};
