import { ListDataNotifier } from "@ads/ui";
/**
 * @description
 *  Implémentation basique de l'interface IBaseChampListDataNotifier
 *
 */
export class BaseChampListDataNotifier extends ListDataNotifier {
    registerListener(listener) {
        try {
            if (listener.idListener && listener.idListener !== undefined) {
                this.listenerMap.set(listener.idListener, listener);
                return true;
            }
            return false;
        }
        catch (err) {
            console.log(err);
            return false;
        }
    }
    notifyChampAddClick(item) {
        for (const listener of this.listenerMap.values()) {
            if (listener.onAddChampClick) {
                listener.onAddChampClick(item);
            }
        }
    }
}
