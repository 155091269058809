import { TypeTiersModel } from "@ads/isadom-model";
import { useEffect, useState } from "react";
import { ISelectionTiersDestinatairePEHookInput } from "../../parametre-edition-list/hook";
import { ISelectionTiersDestinatairePEHookResult } from "./interface";

export function useSelectionTiersDestinatairePE(
  input: ISelectionTiersDestinatairePEHookInput
): ISelectionTiersDestinatairePEHookResult {
  const [typeTiers, setTypeTiers] = useState<TypeTiersModel[] | null>(null);

  useEffect(() => {
    input.features.getAllTypeTiers().then((typeTiers) => {
      setTypeTiers(typeTiers);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    typeTiers: typeTiers,
  };
}
