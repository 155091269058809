var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TypePrestationAdapter } from "../../medical/prestation/adapter";
import { Adapter } from "../../_adapter";
var ObservanceDeambulationAdapter = /** @class */ (function (_super) {
    __extends(ObservanceDeambulationAdapter, _super);
    function ObservanceDeambulationAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typePrestationAdapter = new TypePrestationAdapter();
        return _this;
    }
    ObservanceDeambulationAdapter.prototype.toClient = function (src) {
        if (!src) {
            return null;
        }
        return {
            ID: src.observanceVisiteID,
            typePrestation: this.typePrestationAdapter.toClient(src.typePrestation),
            dureeTP: "".concat(src.rDureeTP, " "),
            dureeCalculee: "".concat(src.rDureeCalculee),
            dureeEstimee: "".concat(src.rDureeEstimee),
            information: src.sInformation,
            estInfoMedecin: src.bInfoMedecin,
            debutUtilisation: src.dDebutUtilisation,
            finUtilisation: src.dFinUtilisation,
            dureeUtilisation: "".concat(src.iDureeUtilisation),
            dureeMediane: "".concat(src.rDureeMediane),
            dernierReleve: src.dDernierReleve,
            derniereDuree: "".concat(src.rDerniereDuree),
        };
    };
    ObservanceDeambulationAdapter.prototype.toAPI = function (src) {
        if (!src) {
            return null;
        }
        return {
            observanceVisiteID: src.ID,
            typePrestation: this.typePrestationAdapter.toAPI(src.typePrestation),
            rDureeTP: parseFloat(src.dureeTP),
            rDureeCalculee: parseFloat(src.dureeCalculee),
            rDureeEstimee: parseFloat(src.dureeEstimee),
            sInformation: src.information,
            bInfoMedecin: src.estInfoMedecin,
            dDebutUtilisation: src.debutUtilisation,
            dFinUtilisation: src.finUtilisation,
            iDureeUtilisation: parseFloat(src.dureeUtilisation),
            rDureeMediane: src.dureeMediane,
            dDernierReleve: src.dernierReleve,
            rDerniereDuree: parseFloat(src.derniereDuree),
        };
    };
    return ObservanceDeambulationAdapter;
}(Adapter));
export { ObservanceDeambulationAdapter };
