import { Card } from "antd";
import { IAHHookInput, useIAH } from "../hook/useIAH";
import styled from "styled-components";
import { IAHItem } from "./IAHItem";
import { IIAHModel } from "@ads/isadom-model";

const Grid = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export interface IAHProps {
  data: IAHHookInput;
  callbacks: {
    onChange?(data: IIAHModel): Promise<IIAHModel>;
  };
}

export function IAHEdition(props: IAHProps) {
  const service = useIAH(props.data);

  return (
    <Card>
      <Grid className="iah-grid">
        <IAHItem
          libelle={"IAHAA"}
          value={service.IAH.IAHAA}
          oldValue={service.IAHPrecedente.IAHAA}
          onChange={function (value: string): void {
            service.updateIAHChamp("IAHAA", value);
          }}
        />
        <IAHItem
          libelle={"IAH"}
          value={service.IAH.IAH}
          oldValue={service.IAHPrecedente.IAH}
          onChange={function (value: string): void {
            service.updateIAHChamp("IAH", value);
          }}
        />
        <IAHItem
          libelle={"IH"}
          value={service.IAH.IH}
          oldValue={service.IAHPrecedente.IH}
          onChange={function (value: string): void {
            service.updateIAHChamp("IH", value);
          }}
        />
        <IAHItem
          libelle={"IAO"}
          value={service.IAH.IAO}
          oldValue={service.IAHPrecedente.IAO}
          onChange={function (value: string): void {
            service.updateIAHChamp("IAO", value);
          }}
        />
        <IAHItem
          libelle={"IAC"}
          value={service.IAH.IAC}
          oldValue={service.IAHPrecedente.IAC}
          onChange={function (value: string): void {
            service.updateIAHChamp("IAC", value);
          }}
        />
        <IAHItem
          libelle={"Fuite"}
          value={service.IAH.fuite}
          oldValue={service.IAHPrecedente.fuite}
          onChange={function (value: string): void {
            service.updateIAHChamp("fuite", value);
          }}
        />
      </Grid>
    </Card>
  );
}
