var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";
import { Modal, notification } from "antd";
import { LivraisonDetail } from "../atoms/LivraisonDetail";
import errorAdapter from "../../utils/errorAdapter";
import { useState } from "react";
import { FormLivraisonVisiteNumLot } from "../molecules/FormLivraisonVisiteNumLot";
import { FormLivraisonVisiteDLCLot } from "../molecules/FormLivraisonVisiteDLCLot";
import { FormLivraisonVisiteQuantite } from "../molecules/FormLivraisonVisiteQuantite";
import { apiEnregistreConsommablesLivraisons } from "../../api/apiEnregistreConsommablesLivraisons";
const WrapperMain = styled.div `
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2.6rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
const WrapperDetailPrestations = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px 20px;
`;
const LivraisonVisiteStyled = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.6rem 0;
  margin-top: 2rem;
  margin-bottom: 2.6rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  transition: transform 0.1s ease-out, box-shadow 0.2s;

  ${({ theme }) => theme.media.sm} {
    padding: 0.6rem 0;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: auto auto;
  }
`;
const Prestations = styled.div `
  display: flex;

  b {
    margin-right: 0.4rem;
  }

  ${({ theme }) => theme.media.md} {
  }
  ${({ theme }) => theme.media.lg} {
  }
`;
const FormArea = styled.div `
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  justify-content: flex-end;
  gap: 10px;
  ${({ theme }) => theme.media.lg} {
  }
`;
const List = styled.div `
  margin: 2.6rem 0 0;
`;
export const ModalBonLivraisonForm = ({ livraisons, bonLivraisonID, visiteID, isModalVisible, setIsModalVisible, refreshData, }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const handleOk = () => __awaiter(void 0, void 0, void 0, function* () {
        if (bonLivraisonID && livraisons) {
            try {
                setIsLoading(true);
                yield apiEnregistreConsommablesLivraisons(+visiteID, livraisons.map((livraison) => livraison.ligneLivraisonID < 0
                    ? Object.assign(Object.assign({}, livraison), { ligneLivraisonID: -1 }) : livraison));
                setIsLoading(false);
                setIsModalVisible(false);
                notification.success({
                    key: v4(),
                    message: "Confirmation",
                    description: "La livraison a bien été enregistrée.",
                });
                history.push(`/visites/${visiteID}/consommables/livraisons`);
            }
            catch (error) {
                setIsLoading(false);
                errorAdapter(error).forEach((e) => {
                    notification.error({
                        key: v4(),
                        message: "Erreur",
                        description: e.message || "Erreur interne du serveur",
                    });
                });
            }
        }
    });
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (_jsx(Modal, Object.assign({ confirmLoading: isLoading, visible: isModalVisible, width: "80rem", onOk: handleOk, onCancel: handleCancel }, { children: _jsx(WrapperMain, { children: _jsx(List, { children: livraisons &&
                    livraisons.map((livraison) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        return (_jsxs(LivraisonVisiteStyled, { children: [_jsxs(WrapperDetailPrestations, { children: [_jsx(LivraisonDetail, { livraison: livraison }, void 0), _jsxs(Prestations, { children: [["FM", "PD"].indexOf(((_b = (_a = livraison.consommable) === null || _a === void 0 ? void 0 : _a.typeProduit) === null || _b === void 0 ? void 0 : _b.typeProduitId) || "") !== -1 &&
                                                    ((_c = livraison.typePrestation) === null || _c === void 0 ? void 0 : _c.libelle) && (_jsxs(_Fragment, { children: [_jsx("b", { children: "Appareil :" }, void 0), " ", (_d = livraison.appareil) === null || _d === void 0 ? void 0 : _d.libelle, " -", " ", (_e = livraison.appareil) === null || _e === void 0 ? void 0 : _e.refProduit] }, void 0)), ["FM", "PD"].indexOf(((_g = (_f = livraison.consommable) === null || _f === void 0 ? void 0 : _f.typeProduit) === null || _g === void 0 ? void 0 : _g.typeProduitId) || "") === -1 &&
                                                    ((_h = livraison.typePrestation) === null || _h === void 0 ? void 0 : _h.libelle) && (_jsxs(_Fragment, { children: [_jsx("b", { children: "Prestation :" }, void 0), " ", (_j = livraison.typePrestation) === null || _j === void 0 ? void 0 : _j.libelle] }, void 0))] }, void 0)] }, void 0), _jsx(FormArea, { children: livraison.lot ? (_jsxs(_Fragment, { children: [_jsx(FormLivraisonVisiteNumLot, { livraison: livraison, refreshData: refreshData }, void 0), _jsx(FormLivraisonVisiteDLCLot, { livraison: livraison, refreshData: refreshData }, void 0), _jsx(FormLivraisonVisiteQuantite, { livraison: livraison, disabled: false, lot: false, refreshData: refreshData }, void 0)] }, void 0)) : (_jsx(FormLivraisonVisiteQuantite, { livraison: livraison, disabled: false, lot: false, refreshData: refreshData }, void 0)) }, void 0)] }, v4()));
                    }) }, void 0) }, void 0) }), void 0));
};
