import { AdsTheme } from "@ads/react-common-module";
import { DEFAULT_NOTIFICATION_DURATION } from "@ads/ui";

export const breakpoints = {
  xs: "0px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
};

const nomadTheme: AdsTheme = {
  breakpoints,
  media: {
    xs: "0px",
    sm: `@media (min-width: ${breakpoints.sm})`,
    md: `@media (min-width: ${breakpoints.md})`,
    lg: `@media (min-width: ${breakpoints.lg})`,
    xl: `@media (min-width: ${breakpoints.xl})`,
    xxl: `@media (min-width: ${breakpoints.xxl})`,
  },
  colors: {
    primary: {
      // Orange
      80: "#9B300F",
      90: "#BA3E18",
      100: "#ED5B2E",
      110: "#FF8A65",
      120: "#FFA689",
    },
    secondary: {
      // Bleu clair
      80: "#226D90",
      90: "#2489B7",
      100: "#56BFEF",
      110: "#99DEFD",
      120: "#BCE9FD",
    },
    tertiary: {
      // BLEU FONCE
      80: "#4A3E89",
      90: "#4E39BE",
      100: "#6B53E8",
      110: "#806AF4",
      120: "#9B89FC",
    },
    quaternary: {
      // orange
      80: "#BE7609",
      90: "#E58A00",
      100: "#FDA929",
      110: "#FFCE83",
      120: "#FFDFAF",
    },
    quinary: {
      // violet
      80: "#2A2834",
      90: "#332E4E",
      100: "#524D72",
      110: "#716B9D",
      120: "#8581A5",
    },
    neutral: {
      // gris
      100: "#FFFFFF",
      110: "#F5F4F4",
      120: "#EDEDED",
      130: "#E8E4E4",
      140: "#CEC5C5",
      150: "#B4ACAC",
      160: "#8B8080",
      170: "#676262 ",
      180: "#4D4848", // Typos
      190: "#000",
    },
    danger: {
      80: "#FF7455",
      90: "#FD5B37",
      100: "#F4430C",
      110: "#DD2C06",
      120: "#C9200C",
    },
    gradient: {
      // DEGRADE
      1: "transparent linear-gradient(150deg, #30B7F3 0%, #306CF3 100%) 0% 0% no-repeat padding-box;",
      2: "transparent linear-gradient(133deg, #30B7F3 0%, #306CF3 100%) 0% 0% no-repeat padding-box;",
    },
  },
  font: {
    type: {
      primary:
        '"PoppinsRegular", "Helvetica Neue", Helvetica, Arial, sans-serif',
      primaryMedium:
        '"PoppinsMedium", "Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    weight: {
      regular: "400",
      bold: "700",
      extrabold: "800",
      black: "900",
    },
    size: {
      s1: 12,
      s2: 14,
      s3: 16,
      m1: 20,
      m2: 24,
      m3: 28,
      l1: 32,
      l2: 40,
      l3: 48,
      code: 90,
    },
  },
  duration: {
    notification: DEFAULT_NOTIFICATION_DURATION
  }
};

export default nomadTheme;
