import { RendezVousModel } from "@ads/isadom-model";
import { useState } from "react";
import { useTypeVisiteTypePrestaList } from "../../TypeVisiteTypePrestaList/hook/useTypeVisiteTypePrestaList";
import { IInfoRdvVisiteHookInput } from "./interfaces/IInfoRdvVisiteHookInput";
import { IInfoRdvVisiteHookService } from "./interfaces/IInfoRdvVisiteHookService";

export function useInfoRdvVisite({
  rdvOrVisite
}: IInfoRdvVisiteHookInput): IInfoRdvVisiteHookService {
  const [rdvOrVisiteState, setRdvOrVisiteState] = useState<
    RendezVousModel | undefined
  >(rdvOrVisite);

  const serviceTypeVisiteTypePrestaList = useTypeVisiteTypePrestaList({
    defaultTypesVisitesTypesPresta: rdvOrVisiteState
      ? rdvOrVisiteState.tTypeVisite
      : undefined
  });

  function handleRdvOrVisiteChanged(rdv: RendezVousModel | undefined) {
    setRdvOrVisiteState(rdv);
    if (rdv)
      serviceTypeVisiteTypePrestaList.notifyTypesVisitesChanged(
        rdv?.tTypeVisite
      );
  }

  return {
    rdvOrVisite: rdvOrVisiteState,
    serviceTypeVisiteTypePrestaList,
    notifyRdvOrVisiteChanged: handleRdvOrVisiteChanged
  };
}
