var ChecklistModel = /** @class */ (function () {
    function ChecklistModel() {
    }
    ChecklistModel.prototype.compareTo = function (objectToCompare) {
        if (this.libelle < objectToCompare.libelle) {
            return -1;
        }
        else if (this.libelle > objectToCompare.libelle) {
            return 1;
        }
        else {
            return 0;
        }
    };
    return ChecklistModel;
}());
export { ChecklistModel };
