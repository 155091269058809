import styled from "styled-components";
import { IBaseParametreEditionListAdapterProps } from ".generated/list";
import {
  AdresseModel,
  IParametreEditionModel,
  TiersModel,
} from "@ads/isadom-model";
import {
  Button,
  FormItemWrapper,
  Input,
  itemSelect,
  Label,
  Select,
} from "@ads/ui";
import { Card as CardANTD, Row, Col } from "antd";

const Card = styled(CardANTD)`
  margin-bottom: 1.6rem;
`;

const Wrapper = styled.div`
  max-width: 108rem;
`;

function getAllAdresseOfTiers(tiers?: TiersModel) {
  const adresse: AdresseModel[] = [];
  if (tiers && tiers.tAdresse) {
    adresse.push(...tiers.tAdresse);
  }
  if (tiers && tiers.adressePrincipale) {
    adresse.push(tiers.adressePrincipale);
  }
  return adresse;
}

export function ParameteEditionListItemAdapter(
  props: IBaseParametreEditionListAdapterProps
) {
  if (!props.data) {
    return <></>;
  }
  return (
    <>
      <Card>
        <Wrapper>
          <Row gutter={16}>
            <Col lg={12}>
              <FormItemWrapper direction="vertical">
                <Label htmlFor={"pe_tiers_" + props.data.id}>Tiers</Label>
                <Input
                  id={"pe_tiers_" + props.data.id}
                  selectMode="light"
                  disabled
                  value={
                    props.data.tiers.nom +
                    " " +
                    props.data.tiers.prenom +
                    " (" +
                    props.data.tiers.codeTiers +
                    ")"
                  }
                  defaultValue={
                    props.data.tiers.nom +
                    " " +
                    props.data.tiers.prenom +
                    " (" +
                    props.data.tiers.codeTiers +
                    ")"
                  }
                  name="tiers"
                  type="text"
                />
              </FormItemWrapper>
            </Col>
            <Col lg={12}>
              <FormItemWrapper direction="vertical">
                <Label htmlFor={"pe_adresse_" + props.data.id}>Adresse</Label>
                <Select
                  selectMode="light"
                  id={"pe_adresse_" + props.data.id}
                  name="toto"
                  value={props.data.adresse.adresseComplete}
                  itemsSelect={getAllAdresseOfTiers(props.data.tiers).map(
                    (data) => {
                      return {
                        value: data.adresseID.toString(),
                        content: data.adresseComplete,
                      } as itemSelect;
                    }
                  )}
                  onSelect={(data) => {
                    if (props.notifier && props.notifier.notifyItemChange) {
                      const newData: IParametreEditionModel = {
                        ...props.data,
                        adresse: getAllAdresseOfTiers(props.data.tiers).find(
                          (element) => element.adresseID.toString() === data
                        ) as AdresseModel,
                      };
                      props.notifier.notifyItemChange(newData);
                    }
                  }}
                />
              </FormItemWrapper>
            </Col>
          </Row>
          <Button
            size="S"
            as="button"
            type="button"
            buttonStyle="danger"
            label="Supprimer"
            onClick={() => {
              if (props.notifier && props.notifier.notifyItemDeleteClick) {
                props.notifier.notifyItemDeleteClick(props.data);
              }
            }}
          />
        </Wrapper>
      </Card>
    </>
  );
}
