import { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { Loading } from "@ads/ui";
import useQuery from "../../../hooks/useQuery";
import {
  pageTransition,
  pageVariants
} from "../../core/animations/pageTransition";
import nomadLogoSrc from "../../../assets/img/logo.svg";
import Waves from "../atoms/Waves";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import {
  connexion,
  chargeParams
} from "../../../store/redux/services/utilisateur";
import { AuthPayload } from "../../../interface/utilisateur";
import config from "../../../config";

const Wrapper = styled(motion.div)`
  padding: 0 0 2rem;
  min-height: 100vh;
  min-width: 32rem;
  background: transparent linear-gradient(#eaae3a 0%, #e9853a 100%) 0% 0%
    no-repeat padding-box;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0;
  }
`;

const NomadLogo = styled(motion.img)`
  display: block;
  margin: 0 auto;
  width: 39%;
  max-width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 30rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    position: absolute;
    z-index: 2;
    margin: calc((100vh - 40rem) / 2) 0 0 calc((50vw - 42.2rem) / 2);
    width: auto;
  }
`;

const Title = styled.h1`
  margin: 0;
  padding: 0;
  max-width: 100%;
  font-size: 1.571428571428571em;
  font-weight: normal;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 30rem;
    font-size: 1.714285714285714em;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 1.714285714285714em;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}) {
    font-size: 2.142857142857143em;
  }
`;

const Intro = styled.p`
  margin: 0;
  padding: 1rem 0 2rem;
  max-width: 100%;
  font-size: 1.4rem;
  line-height: 1.428571428571429em;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 2rem 0 4rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 2rem 0 4rem;
  }
`;

const LoginForm = styled(motion.form)`
  position: relative;
  z-index: 2;
  margin: 0.5rem 2rem;
  padding: 3rem 2rem 2rem;
  min-height: 40rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  border-radius: 1.3rem;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 2rem auto;
    padding: 6rem 4rem 3rem;
    width: 46rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 6rem 4rem 3rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    float: left;
    margin: 2.8em 0 0 50%;
    padding: 11rem 6.4rem 5rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    margin: 7.8em 0 0 50%;
    padding: 11rem 8.4rem 5rem;
    width: 50rem;
  }
`;

const LoginWaves = styled(motion.div)``;

const IFrame = styled.iframe`
  border: 0;
  height: 27rem;
  width: 100%;
`;

const ReconnexionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 3rem;
  margin: 0;
`;

const ReconnexionNotice = styled.div`
  position: relative;
  transform: translateY(-33%);
  padding: 0 2rem 2rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  border-radius: 1.3rem;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 40rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: 50rem;
  }
`;

const TemplateConnexion = () => {
  const dispatch = useAppDispatch();
  const { bConnecte, bReconnexion } = useAppSelector((state) => state.auth);
  const history = useHistory();
  const query = useQuery();
  const redirect = query.get("redirect") || "/feuille-de-route/a-faire";

  useEffect(() => {
    const getToken = async (evt: MessageEvent) => {
      const {
        origin,
        data: { token }
      } = evt;
      console.log("EVT", evt);
      console.log(config.identityServerURL);
      if (origin === config.identityServerURL) {
        const result = await dispatch(connexion(token));

        if (result.type === "utilisateur/connexion/fulfilled") {
          const utilisateur = result.payload as AuthPayload;
          dispatch(chargeParams(utilisateur.identity.iFKAnnuaire));
        }
      }
    };

    window.addEventListener("message", getToken, false);

    return () => window.removeEventListener("message", getToken);
  }, [dispatch]);

  useEffect(() => {
    if (bConnecte) {
      history.push(redirect);
    }
  }, [bConnecte, history, redirect]);

  if (bReconnexion) {
    return (
      <ReconnexionWrapper>
        <ReconnexionNotice>
          <Loading>
            Reconnexion en cours.
            <br />
            Merci de patienter quelques instants.
          </Loading>
        </ReconnexionNotice>
      </ReconnexionWrapper>
    );
  }

  return bConnecte ? null : (
    <Wrapper exit={{ opacity: 0 }} transition={pageTransition}>
      <NomadLogo
        initial="initial"
        animate="enter"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
        src={nomadLogoSrc}
      />

      <LoginForm
        initial="initial"
        animate="enter"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Title>Bienvenue</Title>

        <Intro>
          Entrez votre identifiant et votre mot de passe pour accéder à
          l'application.
        </Intro>

        <IFrame
          src={`${config.identityServerURL}/login?theme=nomad&src=${window.location.origin}${window.location.pathname}`}
        />
      </LoginForm>

      <LoginWaves
        initial="initial"
        animate="enter"
        exit="exit"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Waves />
      </LoginWaves>
    </Wrapper>
  );
};

export default TemplateConnexion;
