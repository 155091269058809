var MessageModelBuilder = /** @class */ (function () {
    function MessageModelBuilder() {
    }
    MessageModelBuilder.prototype.build = function () {
        return {
            ID: this.ID,
            typeObjet: this.typeObjet,
            objet: this.objet,
            corps: this.corps,
            destinatairesPrincipaux: this.destinatairesPrincipaux,
        };
    };
    MessageModelBuilder.prototype.setTypeObjet = function (typeObjet) {
        this.typeObjet = typeObjet;
        return this;
    };
    MessageModelBuilder.prototype.setObjet = function (objet) {
        this.objet = objet;
        return this;
    };
    MessageModelBuilder.prototype.setCorps = function (message) {
        this.corps = message;
        return this;
    };
    MessageModelBuilder.prototype.setDestinatairesPrincipaux = function (destinatairesPrincipaux) {
        this.destinatairesPrincipaux = destinatairesPrincipaux;
        return this;
    };
    return MessageModelBuilder;
}());
export { MessageModelBuilder };
