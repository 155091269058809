import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading } from "@ads/ui";
import { Alert, Table } from "antd";
import { useGetConsommablesHistoriqueConsommations } from "../../hooks/useGetConsommablesHistoriqueConsommations";
import { HistoriqueConsommationHeader } from "../organisms/HistoriqueConsommationHeader";
const columns = [
    {
        title: "Date",
        dataIndex: "date",
        sortDirections: ["ascend", "descend", "ascend"],
        defaultSortOrder: "descend",
        sorter: (a, b) => {
            if (a.date > b.date) {
                return -1;
            }
            if (a.date < b.date) {
                return 1;
            }
            return 0;
        },
        width: 80,
        className: "",
        render: (text) => {
            return new Intl.DateTimeFormat("fr-FR").format(new Date(text));
        },
    },
    {
        title: "Quantité",
        dataIndex: "quantite",
        sorter: (a, b) => {
            if (a.quantite > b.quantite) {
                return -1;
            }
            if (a.quantite < b.quantite) {
                return 1;
            }
            return 0;
        },
        width: 60,
        className: "quantite",
    },
    {
        title: "Libellé",
        dataIndex: "libelle",
        sorter: (a, b) => a.libelle.localeCompare(b.libelle),
        width: 200,
        className: "libelle",
    },
    {
        title: "Référence",
        dataIndex: "refProduit",
        sorter: (a, b) => a.refProduit.localeCompare(b.refProduit),
        width: 80,
        className: "refProduit",
    },
];
export const HistoriqueConsommationTemplate = ({ visiteID, date, tiersID, }) => {
    var _a, _b;
    const { data, loading, error } = useGetConsommablesHistoriqueConsommations(tiersID, date);
    if (loading) {
        return (_jsx(Loading, { children: "Chargement en cours de l'historique des consommations." }, void 0));
    }
    if (error && ((_a = error === null || error === void 0 ? void 0 : error.alertes) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        return (_jsx(Alert, { showIcon: true, type: "warning", message: "Attention !", description: error === null || error === void 0 ? void 0 : error.alertes.map((error) => error.message) }, void 0));
    }
    if (error && ((_b = error === null || error === void 0 ? void 0 : error.metiers) === null || _b === void 0 ? void 0 : _b.length) > 0) {
        return (_jsx(Alert, { showIcon: true, type: "error", message: "Erreur !", description: error.metiers.map((error) => error.message) }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsx(HistoriqueConsommationHeader, { visiteID: visiteID }, void 0), _jsx(Table, { columns: columns || [], dataSource: data, size: "middle", pagination: false }, void 0)] }, void 0));
};
