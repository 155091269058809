import { getDefaultApiInstance } from "@ads/react-common-module";
import axios from "axios";
export function getMotifsNonRealisationTaches() {
    const api = getDefaultApiInstance();
    if (api) {
        return new Promise((resolve, reject) => {
            api
                .get("/parametres/motifs-non-realisation-tache")
                .then((response) => {
                resolve(response.data);
            })
                .catch((error) => {
                var _a, _b;
                if (axios.isAxiosError(error)) {
                    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                        reject("Erreur interne du serveur.");
                    }
                    else {
                        reject(((_b = error.response) === null || _b === void 0 ? void 0 : _b.data) || "Erreur inconnue");
                    }
                }
                else {
                    reject(error);
                }
            });
        });
    }
    return new Promise((_resolve, reject) => {
        reject("La configuration d'api n'est pas initialisé");
    });
}
