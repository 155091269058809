import { ProgrammeEducatifModel } from "@ads/isadom-model";
import { useDeleteWithCache } from "@ads/react-common-module";
import { DataSourceDeleteResult } from "@ads/react-common-module/lib/esm/data-source/model/DataSourceDeleteResult";
import { useApiDeleteProgrammeEducatifByVisiteIDAndID } from "../api";
import { useSelectPEStore } from "../components/context/PEContext";

export interface DeleteProgrammeEducatifByVisiteIDAndIDHookInput
  extends Record<string, string | number> {
  visiteID: number;
  peID: number;
}

export function useDeletePEByVisiteIDAndID(
  criteria?: DeleteProgrammeEducatifByVisiteIDAndIDHookInput
) {
  const { setPEList, peList } = useSelectPEStore();
  return useDeleteWithCache<
    ProgrammeEducatifModel,
    DeleteProgrammeEducatifByVisiteIDAndIDHookInput
  >({
    criteria,
    deleteFromCache(criteriaRequest) {
      let result: ProgrammeEducatifModel | null = null;
      if (peList) {
        const newData = [];
        // les criteria peuvent être passé soit à l'initialisation de la function (hook)
        // soit lors de l'appel de la function delete
        // Les paramètres passé par la fonction delete sont prioritaires.
        const trueCriteria = criteriaRequest ? criteriaRequest : criteria;
        for (const data of peList) {
          if (data.nautID !== trueCriteria?.peID) {
            newData.push(data);
          } else {
            result = data;
          }
        }
        setPEList(newData);
      }
      return result;
    },
    deleteFromRemote: (criteria) => {
      return useApiDeleteProgrammeEducatifByVisiteIDAndID(
        criteria
      ) as unknown as DataSourceDeleteResult<
        ProgrammeEducatifModel,
        DeleteProgrammeEducatifByVisiteIDAndIDHookInput
      >;
    }
  });
}
