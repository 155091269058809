import { TypeTiersModel } from "@ads/isadom-model";
import { SelectionDestinataireProps } from "@ads/module-messages-visite";
import { ISelectionTiersV2Props, SelectionTiersV2 } from "@ads/module-tiers";

export function SelectionDestinataire(props: SelectionDestinataireProps) {
  const selectionProps: ISelectionTiersV2Props = {
    onTiersSelected: props.onDestinatairesSelected,
    formConfig: {
      ouConfig: {
        allowChange: true,
        defaultData: ""
      },
      quiConfig: {
        allowChange: true,
        defaultData: ""
      },
      typesTiersConfig: {
        allowChange: false,
        defaultSelectedData: [
          {
            typeTiersID: "DIET",
            libelle: "Diététicien(ne)"
          } as TypeTiersModel,
          {
            typeTiersID: "GRD",
            libelle: "Groupe de diffusion"
          } as TypeTiersModel,
          {
            typeTiersID: "INT",
            libelle: "Personnel interne"
          } as TypeTiersModel,
          {
            typeTiersID: "LABO",
            libelle: "Laboratoire"
          } as TypeTiersModel
        ],
        visible: true,
        data: [],
        typeSelection: "multiple"
      }
    },
    selectionConfig: {
      defaultSelectedData: props.defaultTiersSelected
        ? props.defaultTiersSelected
        : [],
      typeSelection: "multiple"
    }
  };
  /*const selectionProps: ISelectionTiersProps = {
        allowChangeTypeTiers: true,
        onTiersSelected: props.onDestinatairesSelected,
        typeSelection: "multiple",
        selectSearchParamsConstante: {
            qui: "",
            typetiers: ["INT", "GRD"],
            ville_cp: ""
        },
        listener: {
            onDataSelectedChanged(data){

            }
        },
        defaultFormData: {
            config : {
                allowChangeTypeTiers: true,
            },
            listTypeTiers: [
                {
                    typeTiersID: 'INT',
                    libelle: 'Personnels internes'
                } as TypeTiersModel,
                {
                    typeTiersID: 'GRD',
                    libelle: 'Groupe de diffusion'
                } as TypeTiersModel
            ],
            ou: "",
            qui: ""
        }

    } */

  return <SelectionTiersV2 {...selectionProps} />;
}
