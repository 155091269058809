import styled from "styled-components";

const SubTitleForm = styled("h3")`
  font-family: "PoppinsBold", sans-serif;
  font-size: 2.2rem;
  line-height: 1.2;

  ${({ theme }) => theme.media.md} {
    font-size: 2.4rem;
  }
`;

export default SubTitleForm;
