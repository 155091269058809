import { GenericError } from "../interface/erreur/api/genericError";
import { ItemStoreErrors } from "../interface/store/itemStoreErrors";
import { IErrorBackEnd } from "../interface/erreur/api/errorBackEnd";

const errorAdapter = (error: IErrorBackEnd) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_, value] of Object.entries(error)) {
    const errors = value as GenericError[];
    if (errors.length > 0) {
      const messagesAndIds: ItemStoreErrors[] = errors.map((error) => ({
        sType: error.type,
        message: error.message,
        idConcerne: error.idConcerne,
      }));

      return messagesAndIds;
    }
  }
};

export default errorAdapter;
