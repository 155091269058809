import ProtectedRoute from "components/core/routes/ProtectedRoute";
import NavigationFeuilleDeRoute from "modules/feuille-de-route/ui/organisms/NavigationFeuilleDeRoute";
import TemplateFeuilleDeRoute from "components/ui/templates/TemplateFeuilleDeRoute";
import FeuilleDeRouteModule from "modules/feuille-de-route/FeuilleDeRouteModule";
import ContainerCreerVisite from "modules/feuille-de-route/containers/ContainerCreerVisite";

const RouterFeuilleDeRoute = () => {
  return (
    <>
      {/* CREATION DE VISITE */}
      <ProtectedRoute exact path="/visites" component={ContainerCreerVisite} />
      {/* FEUILLE DE ROUTE */}
      <ProtectedRoute exact path="/feuille-de-route/:typeRdv">
        <TemplateFeuilleDeRoute navigation={<NavigationFeuilleDeRoute />}>
          <FeuilleDeRouteModule />
        </TemplateFeuilleDeRoute>
      </ProtectedRoute>
    </>
  );
};

export default RouterFeuilleDeRoute;
