import { LigneCommandeVisiteModel } from "@ads/isadom-model";
import { FormItemWrapper, DateTimePicker, Label } from "@ads/ui";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  min-width: 15rem;
  text-align: center;
  align-items: center;
  order: 2;
  ${({ theme }) => theme.media.md} {
    order: 3;
  }
`;

const StyledDateTimePicker = styled(DateTimePicker)`
  width: 12rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  margin: auto;
`;

interface CommandeItemCalendarProps {
  item: LigneCommandeVisiteModel;
}

const CommandeItemDateLivraisonSouhaitee = ({
  item
}: CommandeItemCalendarProps) => {
  const { setDateLivraisonSouhaitee } = useFormulaireCommandesStore();

  return (
    <Wrapper>
      <FormItemWrapper direction="vertical">
        <Label htmlFor="livraison-souhaitee">Livraison souhaitée</Label>
        <StyledDateTimePicker
          name={`livraison-souhaitee`}
          id={`livraison-souhaitee`}
          defaultValue={
            item.dateLivraisonSouhaitee !== "0000-00-00"
              ? moment(item.dateLivraisonSouhaitee)
              : undefined
          }
          format="DD/MM/YYYY"
          locale={locale}
          onChange={(value) => {
            if (value) {
              setDateLivraisonSouhaitee(
                item.ID,
                value?.format("YYYY-MM-DD") || ""
              );
            }
          }}
          placeholder="Livraison souhaitée"
        />
      </FormItemWrapper>
    </Wrapper>
  );
};

export default CommandeItemDateLivraisonSouhaitee;
