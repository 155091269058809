import { useParams } from "react-router-dom";
import {
  CONSOMMABLES_CONFIGURATION_PATH,
  CONSOMMABLES_LIVRAISONS_PATH,
  CONSOMMABLES_RETOURS_PATH,
  CONSOMMABLES_HISTORIQUE_PATH
} from "@ads/module-consommables-visite";
import { RouteParam } from "interface/core/RouteParam";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { INavigationItem } from "@ads/ui";

const NavigationConsommable = () => {
  const { iPKVisite, tabActive } = useParams<RouteParam>();

  const navItems: INavigationItem[] = [
    {
      to: `/visites/${iPKVisite}/${CONSOMMABLES_CONFIGURATION_PATH}`,
      label: "Configuration",
      active: tabActive === "configuration",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/${CONSOMMABLES_LIVRAISONS_PATH}`,
      label: "Livraisons",
      active: tabActive === "livraisons",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/${CONSOMMABLES_RETOURS_PATH}`,
      label: "Retours",
      active: tabActive === "retours",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/${CONSOMMABLES_HISTORIQUE_PATH}`,
      label: "Historique",
      active: tabActive === "historique",
      visible: true
    }
  ];

  return (
    <NavigationPrimary
      navItems={navItems || []}
      isOpen={false}
      isLargeNav={false}
    />
  );
};

export default NavigationConsommable;
