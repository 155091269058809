var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TypeLocalisationAdapter } from ".";
import { Adapter } from "../../_adapter";
var LocalisationAdapter = /** @class */ (function (_super) {
    __extends(LocalisationAdapter, _super);
    function LocalisationAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeLocalisationAdapter = new TypeLocalisationAdapter();
        return _this;
    }
    LocalisationAdapter.prototype.toClient = function (src) {
        return {
            nature: src.sNature,
            localisationId: src.localisationId,
            libelle: src.sLibelle,
            complement: src.sComplement,
            typeLocalisation: src.typeLocalisation &&
                this.typeLocalisationAdapter.toClient(src.typeLocalisation),
        };
    };
    LocalisationAdapter.prototype.toAPI = function (src) {
        return {
            sNature: src.nature,
            localisationId: src.localisationId,
            sLibelle: src.libelle,
            sComplement: src.complement,
            typeLocalisation: src.typeLocalisation &&
                this.typeLocalisationAdapter.toAPI(src.typeLocalisation),
        };
    };
    return LocalisationAdapter;
}(Adapter));
export { LocalisationAdapter };
