import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { SelectWrapper, Label, Select } from "@ads/ui";
const Wrapper = styled.div `
  & label {
    white-space: nowrap;
  }

  ${({ theme }) => theme.media.md} {
    display: flex;
    gap: 1rem;
    width: 100%;

    & label {
      width: 8rem;
      line-height: 4.8rem;
    }
  }
`;
export const FormLivraisonVisitePrestation = ({ livraison, refreshData, configConsommables, }) => {
    var _a, _b;
    if (!livraison.consommable) {
        return null;
    }
    const onSelect = (value) => {
        var _a, _b;
        if (!livraison.consommable) {
            return;
        }
        const typePrestation = (_b = (_a = livraison.consommable.famille) === null || _a === void 0 ? void 0 : _a.tTypePrestation) === null || _b === void 0 ? void 0 : _b.find((p) => p.id === value);
        const data = Object.assign(Object.assign({}, livraison), { typePrestation });
        refreshData(data);
    };
    const options = (_b = (_a = livraison.consommable.famille) === null || _a === void 0 ? void 0 : _a.tTypePrestation) === null || _b === void 0 ? void 0 : _b.map((typePrestation) => {
        // console.log("typePrestation", typePrestation);
        return {
            value: typePrestation.id,
            content: typePrestation.libelle,
        };
    });
    let prestationId;
    const consommable = configConsommables.find((c) => c.produit.IDProduit === livraison.consommable.IDProduit);
    if (consommable) {
        if (consommable.typePrestation) {
            prestationId = consommable.typePrestation.id;
        }
        else if (options.length === 1) {
            prestationId = options[0].value;
        }
    }
    return (_jsxs(Wrapper, { children: [_jsx(Label, Object.assign({ htmlFor: `type-prestation-${livraison.ligneLivraisonID}` }, { children: "Prestation :" }), void 0), _jsx(SelectWrapper, Object.assign({ selectMode: "light" }, { children: _jsx(Select, { id: `type-prestation-${livraison.ligneLivraisonID}`, name: `type-prestation-${livraison.ligneLivraisonID}`, onSelect: onSelect, showSearch: true, filterOption: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, itemsSelect: options, selectMode: "light", placeholder: "S\u00E9lectionnez un type de prestation", defaultValue: prestationId }, void 0) }), void 0)] }, void 0));
};
