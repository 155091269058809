import {
  ModeLivraisonAdapter,
  ModeLivraisonAPI,
  ModeLivraisonModel,
  HttpResponseAPI,
  HttpResponseModel,
} from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiGetModesLivraison(): Promise<
  HttpResponseModel<ModeLivraisonModel[]>
> {
  try {
    const api = getDefaultApiInstance();

    if (!api) {
      throw new Error("La configuration d'api n'est pas initialisée");
    }

    const adapter = new ModeLivraisonAdapter();
    const { data, messages }: HttpResponseAPI<ModeLivraisonAPI[]> =
      await apiGetV2(
        api,
        `/parametres/modes-livraison`,
        apiIsadomErrorStrategy
      );

    return { data: adapter.toClients(data), messages };
  } catch (err: any) {
    return new Promise((_resolve, reject) => {
      reject(err.message);
    });
  }
}
