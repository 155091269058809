import { useFormSelectionNatureItem } from "../../../../nature/components/selection/hook/useFormSelectionNatureItem";
import { useFormSelectionTypeProduitItem, } from "../../../../type-produit/component/selection/hook";
import { SelectionProduitItemListener } from "./interface/impl/SelectionProduitItemListener";
import { useFormSelectionFamilleItem, } from "../../../../famille/component/selection/hook";
import { useFormSelectionSousFamilleItem, } from "../../../../sous-famille/component/selection";
const ID = "@ads/useTableFormSelectionProduit";
export function useTableFormSelectionProduit(input) {
    /****************************************/
    /***************LOCAL VAR****************/
    /****************************************/
    /****************************************/
    /**************LOCAL STATE***************/
    /****************************************/
    // N/A
    /****************************************/
    /******************HOOK******************/
    /****************************************/
    const selectionNature = useFormSelectionNatureItem({
        config: input.natureConfig,
    });
    const selectionTypeProduit = useFormSelectionTypeProduitItem({
        config: input.typeProduitConfig,
        initParams: input.natureConfig.defaultSelectedData,
    });
    const selectionFamille = useFormSelectionFamilleItem({
        config: input.familleConfig,
        initParams: {
            natureIDs: input.natureConfig.defaultSelectedData.map((data) => data.id),
            typeProduitIDs: input.typeProduitConfig.defaultSelectedData.map((data) => data.typeProduitId),
        },
    });
    const selectionSousFamille = useFormSelectionSousFamilleItem({
        config: input.sousFamilleConfig,
        initParams: null,
    });
    const listenerSelectionNature = new SelectionProduitItemListener(ID, (data) => {
        selectionTypeProduit.requestReloadData(data);
    });
    selectionNature.registerListener(listenerSelectionNature);
    const selectionTypeProduitListener = new SelectionProduitItemListener(ID, (data) => {
        selectionFamille.requestReloadData({
            natureIDs: selectionNature.selectedData.map((data) => data.id),
            typeProduitIDs: data.map((data) => data.typeProduitId),
        });
    });
    selectionTypeProduit.registerListener(selectionTypeProduitListener);
    const selectionFamilleListener = new SelectionProduitItemListener(ID, (selectedData) => {
        const sousFamilleData = new Array();
        for (const famille of selectedData) {
            sousFamilleData.push(...famille.sousFamille);
        }
        selectionSousFamille.setSelectionnableData(sousFamilleData);
    });
    selectionFamille.registerListener(selectionFamilleListener);
    return {
        natureSelectionService: selectionNature,
        typeProduitSelectionService: selectionTypeProduit,
        familleSelectionService: selectionFamille,
        sousFamilleSelectionService: selectionSousFamille,
    };
}
