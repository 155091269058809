import { useDataListFilter } from "../hook";
import { useList } from "./useList";
export function useListFilter({ fetchService, match, }) {
    const { data, fetchLoading, notifyDataChanged } = useList({
        fetchService: fetchService,
    });
    // Hook qui gère le filtre des composants
    const { data: dataFiltered, filter } = useDataListFilter({
        data,
        match,
    });
    return {
        data,
        fetchLoading,
        filter,
        dataFiltered,
        notifyDataChanged: notifyDataChanged,
    };
}
