import { ProgrammeEducatifAdapter } from "@ads/isadom-model";
import { getDefaultApiInstance, useApiGetV3 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export function useApiGetPEByTiersIDAndID(tiersID: number, id: number) {
  const adapter = new ProgrammeEducatifAdapter();
  return useApiGetV3(
    getDefaultApiInstance(),
    `/tiers/${tiersID}/programmes-educatifs/${id}`,
    {
      errorStrategy: apiIsadomErrorStrategy,
      adaptResult: (data) => {
        const result = adapter.toClient(data.data);
        return result;
      }
    }
  );
}
