var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { natureProduit } from "@ads/isadom-model";
import { useFormSelectionItem, } from "../../../../common";
export function useFormSelectionNatureItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.id;
        },
        config: input.config,
        initParams: null,
        isDataMatchWithFilter(_data, _filter) {
            return true;
        },
        onGetDataRequested() {
            return __awaiter(this, void 0, void 0, function* () {
                return [natureProduit("M"), natureProduit("C"), natureProduit("F")];
            });
        },
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
