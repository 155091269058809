import { Table, Carousel } from "antd";
import styled from "styled-components";
import {
  LigneTable,
  ModesReglagesAppareil,
  TypeReglage,
} from "../../interfaces/modes-reglages.interfaces";
import FormGrilleReglage from "../../../../modules/appareils/ui/molecules/FormGrilleReglage";
import InputGrille from "../../../../modules/appareils/ui/atoms/InputGrille";

const StyleTable = styled("div")`
  th {
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.font.type.primaryMedium};
    text-transform: uppercase;
  }

  th.l-th {
    font-size: 1rem;
    text-align: center;
  }

  .divider-right {
    border-right: 1px solid #f0f0f0;
  }

  .col-code {
    padding-left: 2rem !important;
    ${({ theme }) => theme.media.md} {
      padding-left: 4.8rem !important; /* surcharge ANTD */
    }
  }

  .ant-table-thead > tr > th {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }

  .ant-table-tbody > tr > td {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 1.6rem 0.8rem;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
  }

  .ipt-text {
    display: block;
    margin: 0 auto;
    width: 8.4rem;
    height: 4rem;
    border: none;
    text-align: center;
    border-radius: 1.3rem;

    background-color: ${({ theme }) => theme.colors.neutral[110]};
    transition: background-color 0.1s linear;
    &.danger {
      color: ${({ theme }) => theme.colors.neutral[100]};
      background-color: ${({ theme }) => theme.colors.danger[100]};
    }
    &.warning {
      color: ${({ theme }) => theme.colors.neutral[100]};
      background-color: #fda929;
    }
  }

  /* Carousel */
  .ant-carousel .slick-dots {
    gap: 7px;
    bottom: -4.5rem;
  }
  .ant-carousel .slick-dots li {
    width: auto;
    height: auto;
  }
  .ant-carousel .slick-dots li.slick-active {
    width: auto;
    height: auto;
  }
  .ant-carousel .slick-dots li button {
    background-color: ${({ theme }) => theme.colors.neutral[170]};
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background-color: ${({ theme }) => theme.colors.primary[100]};
  }

  .masquer {
    display: none;
  }
`;

const createColumn = (typeReglage: TypeReglage) => {
  const columnInfo = {
    titleColumn: "",
    titleChildren1: "",
    keyChildren1: "",
    titleChildren2: "",
    keyChildren2: "",
  };
  switch (typeReglage) {
    case "T":
      columnInfo.titleColumn = "Théorique";

      columnInfo.titleChildren1 = "Précédent";
      columnInfo.keyChildren1 = "theoriquePrecedent";

      columnInfo.titleChildren2 = "À remplir";
      columnInfo.keyChildren2 = "theoriqueRemplir";
      break;

    case "M":
      columnInfo.titleColumn = "Mesuré";

      columnInfo.titleChildren1 = "Précédent";
      columnInfo.keyChildren1 = "mesurePrecedent";

      columnInfo.titleChildren2 = "À remplir";
      columnInfo.keyChildren2 = "mesureRemplir";
      break;

    case "C":
      columnInfo.titleColumn = "Constaté";

      columnInfo.titleChildren1 = "Précédent";
      columnInfo.keyChildren1 = "constatePrecedent";

      columnInfo.titleChildren2 = "À remplir";
      columnInfo.keyChildren2 = "constateRemplir";
      break;

    case "E":
      columnInfo.titleColumn = "En partant";

      columnInfo.titleChildren1 = "Précédent";
      columnInfo.keyChildren1 = "enPartantPrecedent";

      columnInfo.titleChildren2 = "À remplir";
      columnInfo.keyChildren2 = "enPartantRemplir";
      break;
  }

  const column = {
    title: columnInfo.titleColumn,
    responsive: ["lg"],
    children: [
      {
        title: columnInfo.titleChildren1,
        dataIndex: columnInfo.keyChildren1,
        key: columnInfo.keyChildren1,
        width: 75,
        className: "l-th ",
        render: (text: string, record: LigneTable) => {
          // Gestion de l'affichage du théorique inactif
          let className = record.isTheoriqueInactif ? "theo-inactif" : "";

          if (record.theoriquePrecedent) {
            className += " theo-precedent";
          }

          if (record.modesReglagesAppareil.bAfficheTheoriqueInactif) {
            className += " masquer";
          }

          return <span className={className}>{text}</span>;
        },
      },
      {
        title: columnInfo.titleChildren2,
        dataIndex: columnInfo.keyChildren2,
        key: columnInfo.keyChildren2,
        width: 75,
        className: "l-th",

        render: (text: string, record: LigneTable) => {
          return <InputGrille record={record} typeReglage={typeReglage} />;
        },
      },
    ],
  };

  return column;
};

/**
 * Test si la valeur courante est en dehors des bornes min/max, dans ce cas retourne danger
 * Test si la valeur courante est différente de la valeur précédente dans ce cas retourne warning
 * Sinon retourne la classe de base ipt-text pour input-text
 * @author Johan Petrikovsky
 * @date 2021-09-29
 * @param {number} sValeurMin - Valeur min du reglage
 * @param {number} sValeurMax - Valeur max du reglage
 * @param {string} valeurCourante - Valeur à comparer
 * @param {string} valeurPrecedente - Valeur précédente du réglage
 * @returns {string}
 */

const columnsBasic: any = [
  {
    title: "Code",
    dataIndex: "sCode",
    width: 100,
    className: "col-code",
    // onFilter: (value: any, record: any) => record.name.indexOf(value) === 0,
    defaultSortOrder: "descend",
  },
  {
    title: "Libellé",
    dataIndex: "sLibelle",
    width: 200,
    responsive: ["lg"],
  },
  {
    title: "Min",
    dataIndex: "sValeurMin",
    defaultSortOrder: "descend",
    width: 75,
    className: "",
    responsive: ["lg"],
  },
  {
    title: "Max",
    dataIndex: "sValeurMax",
    defaultSortOrder: "descend",
    width: 75,
    className: " divider-right",
    responsive: ["lg"],
  },
];

interface Props {
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
}

const ContentReglageDesktop = ({
  modesReglagesAppareil,
  setModesReglagesAppareil,
}: Props) => {
  const onChange = (currentSlide: number) => {
    setModesReglagesAppareil({
      ...modesReglagesAppareil,
      grilleActive: currentSlide,
    });
  };

  const modeReglage = modesReglagesAppareil.modes.find(
    (modeReglageAppareil) =>
      modesReglagesAppareil.modeActif === modeReglageAppareil.reglageModeID
  );

  const modeReglagePrecedent = modesReglagesAppareil.modesPrecedents.find(
    (modeReglageAppareil) =>
      modesReglagesAppareil.modeActif === modeReglageAppareil.reglageModeID
  );

  const modeReglageSaisi = modesReglagesAppareil.modesSaisis.find(
    (modeReglageAppareil) =>
      modesReglagesAppareil.modeActif === modeReglageAppareil.reglageModeID
  );

  // Construction des colonnes du formulaire
  // Ordre important : Théorique --> Mesure --> Constate --> En partant
  const columnsModeReglage = [...columnsBasic];
  if (modeReglage?.bSuiviReglageTheorique) {
    columnsModeReglage.push(createColumn("T"));
  }

  if (modeReglage?.bSuiviReglageMesure) {
    columnsModeReglage.push(createColumn("M"));
  }

  if (modeReglage?.bSuiviReglageConstate) {
    columnsModeReglage.push(createColumn("C"));
  }

  if (modeReglage?.bSuiviReglageEnPartant) {
    columnsModeReglage.push(createColumn("E"));
  }

  // - Je loop sur mes grilles précédentes
  // - J'assigne les valeurs précédentes à ligneTable

  const grillesTableauPrecedent = modeReglagePrecedent?.tabGrille.map(
    (grilleReglagesPrecedent) => {
      if (grilleReglagesPrecedent?.tabReglage?.length === 0) {
        const grilleTableau = grilleReglagesPrecedent.tabReglage.map(
          (reglage, indexLigne) => {

            let isTheoriqueInactif = false;
            if (reglage.tabReglageValeur.length > 0) {
              isTheoriqueInactif = reglage.tabReglageValeur[0]?.bTheoriqueInactif;
            }

            const ligneTable = {
              key: reglage.sCode,
              sCode: reglage.sCode,
              sLibelle: reglage.sLibelle,
              sValeurMin: reglage.sValeurMin,
              sValeurMax: reglage.sValeurMax,
              sValeurMoy: reglage.sValeurMoy,
              theoriquePrecedent:
                reglage.tabReglageValeur.find(
                  (reglageValeur) => reglageValeur.sTypeReglage === "T"
                )?.sValeur || "",
              constatePrecedent:
                reglage.tabReglageValeur.find(
                  (reglageValeur) => reglageValeur.sTypeReglage === "C"
                )?.sValeur || "",
              enPartantPrecedent:
                reglage.tabReglageValeur.find(
                  (reglageValeur) => reglageValeur.sTypeReglage === "E"
                )?.sValeur || "",
              mesurePrecedent:
                reglage.tabReglageValeur.find(
                  (reglageValeur) => reglageValeur.sTypeReglage === "M"
                )?.sValeur || "",
              indexValeur: undefined,
              isModeHoraire: reglage.bModeHoraire,
              indexLigne,
              enPartantHoraires: [],
              isTheoriqueInactif, // TODO - doit etre déplacé par Sebastien et ne plus être à ce niveau
              bSuiviReglageTheorique: modeReglage?.bSuiviReglageTheorique,
              modesReglagesAppareil,
              setModesReglagesAppareil,
            } as unknown as LigneTable;
            return ligneTable;
          }
        );
        return grilleTableau;
      } else {
        return []
      }
    }
  );

  // Valeurs saisies en cours de visite
  const grillesTableauSaisi = modeReglageSaisi?.tabGrille.map(
    (grille) => {
      const grilleTableau = grille.tabReglage.map((reglage) => {
        const ligneTable = {
          key: reglage.sCode,
          sCode: reglage.sCode,
          sLibelle: reglage.sLibelle,
          sValeurMin: reglage.sValeurMin,
          sValeurMax: reglage.sValeurMax,
          sValeurMoy: reglage.sValeurMoy,
          isModeHoraire: reglage.bModeHoraire,
          theoriqueRemplir:
            reglage.tabReglageValeur.find(
              (reglageValeur) => reglageValeur.sTypeReglage === "T"
            )?.sValeur || "",
          constateRemplir:
            reglage.tabReglageValeur.find(
              (reglageValeur) => reglageValeur.sTypeReglage === "C"
            )?.sValeur || "",
          enPartantRemplir:
            reglage.tabReglageValeur.find(
              (reglageValeur) => reglageValeur.sTypeReglage === "E"
            )?.sValeur || "",
          mesureRemplir:
            reglage.tabReglageValeur.find(
              (reglageValeur) => reglageValeur.sTypeReglage === "M"
            )?.sValeur || "",
          theoriqueHoraires:
            reglage.tabReglageValeur.find(
              (reglageValeur) => reglageValeur.sTypeReglage === "T"
            )?.tabReglageValeurHoraire || [],
          constateHoraires:
            reglage.tabReglageValeur.find(
              (reglageValeur) => reglageValeur.sTypeReglage === "C"
            )?.tabReglageValeurHoraire || [],
          mesureHoraires:
            reglage.tabReglageValeur.find(
              (reglageValeur) => reglageValeur.sTypeReglage === "M"
            )?.tabReglageValeurHoraire || [],
          enPartantHoraires:
            reglage.tabReglageValeur.find(
              (reglageValeur) => reglageValeur.sTypeReglage === "E"
            )?.tabReglageValeurHoraire || [],
          modesReglagesAppareil,
          setModesReglagesAppareil,
        };
        return ligneTable;
      });
      return grilleTableau;
    }
  );

  const grillesDefinition = modeReglage?.tabGrille.map(
    (grille) => {
      const grilleTableau = grille.tabReglage.map((reglage) => {
        const ligneTable = {
          key: reglage.sCode,
          sCode: reglage.sCode,
          sLibelle: reglage.sLibelle,
          sValeurMin: reglage.sValeurMin,
          sValeurMax: reglage.sValeurMax,
          sValeurMoy: reglage.sValeurMoy,
          theoriqueRemplir: "",
          constateRemplir: "",
          enPartantRemplir: "",
          mesureRemplir: "",
          modesReglagesAppareil,
          setModesReglagesAppareil,
        };
        return ligneTable;
      });
      return grilleTableau;
    }
  );

  // grille complete avec précédent et en cours
  const grillesSaisiePrecedent = grillesTableauSaisi?.map(
    (grilleSaisie, indexGrille) => {
      if (
        grillesTableauPrecedent &&
        Array.isArray(grillesTableauPrecedent[indexGrille])
      ) {
        const grillePrecedent = grillesTableauPrecedent[indexGrille];
        const fullGrille = grilleSaisie.map((reglageEnCours, indexReglage) => {
          const reglagePrecedent = grillePrecedent[indexReglage];

          const ligneTable = {
            key: reglageEnCours.sCode,
            sCode: reglageEnCours.sCode,
            sLibelle: reglageEnCours.sLibelle,
            sValeurMin: reglageEnCours.sValeurMin,
            sValeurMax: reglageEnCours.sValeurMax,
            sValeurMoy: reglageEnCours.sValeurMoy,
            isModeHoraire:
              reglageEnCours.isModeHoraire || reglagePrecedent?.isModeHoraire,
            theoriquePrecedent: reglagePrecedent?.theoriquePrecedent,
            constatePrecedent: reglagePrecedent?.constatePrecedent,
            enPartantPrecedent: reglagePrecedent?.enPartantPrecedent,
            mesurePrecedent: reglagePrecedent?.mesurePrecedent,
            theoriqueRemplir: reglageEnCours.theoriqueRemplir,
            constateRemplir: reglageEnCours.constateRemplir,
            mesureRemplir: reglageEnCours.mesureRemplir,
            enPartantRemplir: reglageEnCours.enPartantRemplir,
            indexValeur: undefined,
            indexLigne: indexReglage,
            modesReglagesAppareil,
            setModesReglagesAppareil,
          } as unknown as LigneTable;
          return ligneTable;
        });

        return fullGrille;
      } else {
        const fullGrille = grilleSaisie.map((reglageEnCours, indexReglage) => {
          const ligneTable = {
            key: reglageEnCours.sCode,
            sCode: reglageEnCours.sCode,
            sLibelle: reglageEnCours.sLibelle,
            sValeurMin: reglageEnCours.sValeurMin,
            sValeurMax: reglageEnCours.sValeurMax,
            sValeurMoy: reglageEnCours.sValeurMoy,
            theoriquePrecedent:
              grillesTableauPrecedent &&
                grillesTableauPrecedent.length > 0 &&
                grillesTableauPrecedent[0][indexReglage]?.theoriquePrecedent
                ? grillesTableauPrecedent[0][indexReglage]?.theoriquePrecedent
                : "",
            constatePrecedent:
              grillesTableauPrecedent &&
                grillesTableauPrecedent.length > 0 &&
                grillesTableauPrecedent[0][indexReglage]?.constatePrecedent
                ? grillesTableauPrecedent[0][indexReglage].constatePrecedent
                : "",
            enPartantPrecedent:
              grillesTableauPrecedent &&
                grillesTableauPrecedent.length > 0 &&
                grillesTableauPrecedent[0][indexReglage]?.enPartantPrecedent
                ? grillesTableauPrecedent[0][indexReglage].enPartantPrecedent
                : "",
            mesurePrecedent:
              grillesTableauPrecedent &&
                grillesTableauPrecedent.length > 0 &&
                grillesTableauPrecedent[0][indexReglage]?.mesurePrecedent
                ? grillesTableauPrecedent[0][indexReglage].mesurePrecedent
                : "",
            theoriqueRemplir: reglageEnCours.theoriqueRemplir,
            constateRemplir: reglageEnCours.constateRemplir,
            mesureRemplir: reglageEnCours.mesureRemplir,
            enPartantRemplir: reglageEnCours.enPartantRemplir,
            theoriqueHoraires: reglageEnCours.theoriqueHoraires,
            constateHoraires: reglageEnCours.constateHoraires,
            mesureHoraires: reglageEnCours.mesureHoraires,
            enPartantHoraires: reglageEnCours.enPartantHoraires,
            isModeHoraire: reglageEnCours.isModeHoraire,
            indexValeur: undefined,
            indexLigne: indexReglage,
            modesReglagesAppareil,
            setModesReglagesAppareil,
          } as unknown as LigneTable;
          return ligneTable;
        });
        return fullGrille;

        // Je créé un nouvel objet ligneTable qui retourne les valeurs de la grille saisi en cours de visite
      }
    }
  );

  // Si je n'ai pas de grilles avec mes données en cours / précédentes, j'affiche mes grilles vides qui se basent sur les définitions.
  const grillesABoucler = grillesSaisiePrecedent || grillesDefinition;
  // Si je n'ai pas de grille saisie precedent je me base sur la définition

  return (
    <StyleTable>
      <Carousel afterChange={onChange} dotPosition="bottom">
        {grillesABoucler?.map((grille, index) => {
          return (
            <div key={`formGrilleReglage-table-${index}`}>
              <FormGrilleReglage
                indexGrille={index}
                modesReglagesAppareil={modesReglagesAppareil}
                setModesReglagesAppareil={setModesReglagesAppareil}
              />
              <form>
                <Table
                  columns={columnsModeReglage}
                  dataSource={grille as []}
                  size="middle"
                  pagination={false}
                />
              </form>
            </div>
          );
        })}
      </Carousel>
    </StyleTable>
  );
};

export default ContentReglageDesktop;
