var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { SelectWrapper, Label, Select } from "@ads/ui";
const Wrapper = styled.div `
  display: flex;
  gap: 1rem;
  width: 100%;

  & label {
    white-space: nowrap;
    line-height: 4.8rem;
  }

  ${({ theme }) => theme.media.lg} {
    & label {
      width: 8rem;
    }
  }
`;
const Notice = styled.div `
  line-height: 4.8rem;
`;
export const FormLivraisonVisiteAppareil = ({ livraison, refreshData, livraisons, configConsommables, }) => {
    var _a, _b;
    const onSelect = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (!livraisons) {
            return;
        }
        const appareil = livraisons.find((l) => l.consommable.IDProduit === parseInt(value, 10));
        // console.log("appareil", appareil, value, livraisons);
        if (!appareil) {
            return;
        }
        const data = Object.assign(Object.assign({}, livraison), { appareil: appareil.appareil });
        refreshData(data);
    });
    // const options = livraisons.map((appareil) => ({
    //   value: `${appareil?.appareil?.produitId}`,
    //   content: `${appareil?.appareil?.libelle} - ${appareil?.appareil?.refProduit}`,
    // }));
    const options = configConsommables.map((c) => ({
        value: `${c.produit.IDProduit}`,
        content: `${c.produit.sLibelle} - ${c.produit.sRefProduit}`,
    }));
    return (_jsxs(Wrapper, { children: [_jsx(Label, Object.assign({ htmlFor: `appareil-${livraison.ligneLivraisonID}` }, { children: "Appareil :" }), void 0), livraisons.length > 0 ? (_jsx(SelectWrapper, Object.assign({ selectMode: "light" }, { children: _jsx(Select, { id: `appareil-${livraison.ligneLivraisonID}`, name: `appareil-${livraison.ligneLivraisonID}`, onSelect: onSelect, showSearch: true, filterOption: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, itemsSelect: options, selectMode: "light", placeholder: "S\u00E9lectionnez un appareil", defaultValue: ((_a = livraison.appareil) === null || _a === void 0 ? void 0 : _a.produitId)
                        ? `${(_b = livraison.appareil) === null || _b === void 0 ? void 0 : _b.produitId}`
                        : undefined }, void 0) }), void 0)) : (_jsx(Notice, { children: "aucun appareil dans la config" }, void 0))] }, void 0));
};
