import { useEffect, useState } from "react";
import { BaseError, ERROR_UNKNOWN } from "../error";
import { deepCopy } from "../utils";
export function useForm({ metadata, compareTo, defaultFormData, saveService, fetchServices, deleteServices, handleCancel, }) {
    //on garde une trace des données initiales pour pouvoir :
    // - implémenter un RAZ
    // - implémenter le hasChanged
    const [initialFormData, setInitialFormData] = useState(defaultFormData ? defaultFormData : undefined);
    //Donnée du formulaire à jours avec les saisies utilisateurs.
    const [formDataState, setFormDataState] = useState(initialFormData);
    //permet de savoir si le formulaire à changé par rapport au données initial.
    const [hasChanged, setHasChanged] = useState(false);
    //Permet de remettre à jours les données par défaut du formulaire si ces dernières changes
    useEffect(() => {
        if (defaultFormData && !initialFormData) {
            console.log("useEffect defaultFormData");
            setInitialFormData(deepCopy(defaultFormData));
        }
    }, [defaultFormData]);
    useEffect(() => {
        if (initialFormData) {
            console.log("useEffect initialFormData");
            setFormDataState(deepCopy(initialFormData));
        }
    }, [initialFormData]);
    useEffect(() => {
        if (formDataState) {
            setHasChanged(!internalCompareTo(initialFormData, formDataState));
        }
        else if (!initialFormData) {
            setHasChanged(true);
        }
    }, [formDataState]);
    /**
     *
     * @param initFormData
     * @param newFormData
     * @returns -1 si initFormData > newFormData, 0 si initFormData == newFormData et 1 si initFormData < newFormData
     */
    function internalCompareTo(initFormData, newFormData) {
        if (initFormData) {
            return compareTo(initFormData, newFormData) === 0;
        }
        else if (!newFormData && !initialFormData) {
            return false;
        }
        else {
            return true;
        }
    }
    //Permet de remettre à jours les données du formulaire et de changer le hasChanged si besoin
    function handleFormDataChanged(formData) {
        setFormDataState(formData);
    }
    /**
     * Permet de sauvegarder le formulaire.
     * @param formDataInput
     */
    function internalSaveForm(formDataInput, pathParameters) {
        let dataToSave;
        if (formDataInput) {
            setFormDataState(formDataInput);
            dataToSave = formDataInput;
        }
        else if (formDataState) {
            dataToSave = formDataState;
        }
        else {
            throw new BaseError(ERROR_UNKNOWN);
        }
        return saveService.request(dataToSave, pathParameters).then((result) => {
            setFormDataState(result);
            if (saveService.onResult) {
                saveService.onResult(result);
            }
        });
    }
    /**
     * Function permettant d'implémenter le mécanisme de callback onResult
     * @returns Promise<IFormData> Résultat de la récupération des données
     */
    function internalFetch() {
        return fetchServices === null || fetchServices === void 0 ? void 0 : fetchServices.fetch().then((data) => {
            if (!initialFormData) {
                setInitialFormData(data);
            }
            else {
                setFormDataState(data);
            }
            if (fetchServices.onResult) {
                fetchServices.onResult(data);
            }
        });
    }
    /**
     * Function permettant d'implémenter le mécanisme de callback onResult
     * @returns Promise<IFormData> Résultat de la suppréssion des données.
     */
    function internalDelete() {
        return deleteServices === null || deleteServices === void 0 ? void 0 : deleteServices.delete().then((data) => {
            if (deleteServices.onResult) {
                deleteServices.onResult(data);
            }
        });
    }
    return {
        metadata,
        formData: formDataState,
        hasChanged,
        fetchServices: fetchServices
            ? {
                data: fetchServices.data,
                loading: fetchServices.loading,
                fetch: internalFetch,
            }
            : undefined,
        deleteServices: deleteServices
            ? Object.assign(Object.assign({}, deleteServices), { delete: internalDelete }) : undefined,
        saveService: Object.assign(Object.assign({}, saveService), { request: internalSaveForm }),
        handleCancel,
        notifyFormDataChanged: handleFormDataChanged,
    };
}
