import { getDefaultApiInstance, apiDeleteV2 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";
export const apiRaZConsommablesLivraisonsVisite = (visiteID) => {
    try {
        if (!visiteID) {
            throw new Error("L'identifiant de la visite est obligatoire");
        }
        const api = getDefaultApiInstance();
        if (!api) {
            throw new Error("La configuration api est nulle");
        }
        return apiDeleteV2(api, `/visites/${visiteID}/raz/consommables/livraisons`, apiIsadomErrorStrategy);
    }
    catch (err) {
        return new Promise((_, reject) => {
            reject(err.message);
        });
    }
};
