import styled from "styled-components";
import { FormItemWrapper, FormWidget, Label, Textarea } from "@ads/ui";
import { Card } from "antd";
import { Breadcrumb } from "@ads/ui";
import { InfoRdvVisite } from "../../InforRdvVisite/ui/InfoRdvVisite";
import { SelectTiersVisite } from "../../SelectTiersVisite/ui/SelectTiersVisite";
import { SelectLocalisation } from "../../SelectLocalisation/ui/SelectLocalisation";
import { SelectTypeVisiteTypePrestaVisite } from "../../SelectTypeVisiteTypePrestaVisite/ui/SelectTypeVisiteTypePrestaVisite";
import { IFormModifierVisiteHookService } from "../hook/interfaces/IFormModifierVisiteHookService";
import SubTitleForm from "components/ui/atoms/titles/SubTitleForm";

const WrapperMain = styled.div`
  max-width: 144rem;
  margin: 0 auto;
`;

const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  ${({ theme }) => theme.media.xl} {
    display: grid;

    grid-template-columns: 1fr;
    grid-template-columns: auto 33%;
  }

  .form-item.direction-horizontal {
    flex-direction: column;
    align-items: flex-start;
    ${({ theme }) => theme.media.md} {
      flex-direction: row;
      align-items: center;
    }
  }

  label {
    min-width: 18rem;
  }

  .column {
    position: relative;
  }

  .form-widget-background {
    position: relative;
    margin-top: 0;
  }

  .sub-title {
    font-size: 1.7rem;
    font-family: "PoppinsBold", sans-serif;
    margin-bottom: 1.7rem;
  }

  .ant-card-body {
    padding: 1.2rem;
    ${({ theme }) => theme.media.md} {
      padding: 2.4rem;
    }
  }
`;

const WrapperTypeVisitePrestation = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperItemsForm = styled.div`
  margin: 4rem 0;
`;

const ColLeft = styled.div`
  .ant-card {
    margin-bottom: 1.6rem;
  }
`;

export interface FormModifierVisiteProps {
  services: IFormModifierVisiteHookService;
  isLoadingSave: boolean;
}

const breadcrumbItems = [
  {
    to: "/feuille-de-route/a-faire",
    item: "Feuille de route",
  },
  {
    to: "",
    item: "Modification de visite",
  },
];

export function FormModifierVisite({
  services,
  isLoadingSave,
}: FormModifierVisiteProps) {
  return (
    <WrapperMain>
      <Breadcrumb size="M" breadcrumbItems={breadcrumbItems} />

      <FormWidget
        isLoadingSave={isLoadingSave}
        handleSave={() => {
          services.features.updateVisite();
        }}
      >
        <WrapperForm className="wrapper-form-modifier-visite">
          <Card>
            <SubTitleForm>Formulaire de modification</SubTitleForm>

            {/*======================
                Information de la visite
              ======================*/}
            <FormItemWrapper>
              <Label htmlFor="InfoVisite">Informations</Label>
              <Textarea
                id="infoVisiteRDV"
                name="infoVisiteRDV"
                value={services.infoVisite}
                onChange={(e: any) => {
                  services.features.setInformationVisite(e.target.value);
                }}
              />
            </FormItemWrapper>
            {/*======================
                Intervenant secondaire
              ======================*/}
            <SelectTiersVisite
              label={"Intervenant secondaire"}
              services={services.serviceSelectIntervenantSecondaire}
            />
            {/*======================
                TV / TP
              ======================*/}
            <WrapperItemsForm>
              <WrapperTypeVisitePrestation>
                <div>
                  <Label htmlFor="types-visite">
                    Types de visites & types de prestations
                  </Label>
                </div>
                <div>
                  <SelectTypeVisiteTypePrestaVisite
                    services={services.serviceTypeVisiteTypePresta}
                  />
                </div>
              </WrapperTypeVisitePrestation>
            </WrapperItemsForm>

            <WrapperItemsForm className="localisations">
              <h3 className="sub-title">Localisations par défaut</h3>
              {/*======================
                Localisation DM
              ======================*/}
              <SelectLocalisation
                services={services.serviceLocalisationPrelevementDM}
                label="Prélèvement DM"
              />
              {/*======================
                Localisation DMC
              ======================*/}
              <SelectLocalisation
                services={services.serviceLocalisationPrelevementDMC}
                label="Prélèvement DMC"
              />
            </WrapperItemsForm>
          </Card>

          <ColLeft>
            {services.serviceInfoRdv.rdvOrVisite?.ID !== 0 && (
              <Card>
                <InfoRdvVisite
                  services={services.serviceInfoRdv}
                  libelle="Rendez-vous"
                />
              </Card>
            )}

            <Card>
              <InfoRdvVisite
                services={services.serviceInfoVisite}
                libelle="Visite"
              />
            </Card>
          </ColLeft>
        </WrapperForm>
      </FormWidget>
    </WrapperMain>
  );
}
