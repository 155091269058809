import { Breadcrumb } from "@ads/ui";
import styled from "styled-components";

const WrapperBreadcrumb = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

interface Props {
  breadcrumbItems: {
    item: React.ReactNode;
    to: string;
  }[];
}

const BreadcrumbCommande = ({ breadcrumbItems }: Props) => {
  return (
    <WrapperBreadcrumb>
      <Breadcrumb size="M" breadcrumbItems={breadcrumbItems} />
    </WrapperBreadcrumb>
  );
};

export default BreadcrumbCommande;
