import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FormItemWrapper, Input, Label, } from "@ads/ui";
import { FormSelectionTypeTiersItem } from "../../../../type-tiers";
import { useFormSelectionTiers } from "../hook/useFormSelectionTiers";
export function FormSelectionTiersAdapter(props) {
    var _a, _b, _c, _d;
    const config = props.formData
        ? props.formData.config
        : {};
    const services = useFormSelectionTiers(config);
    return (_jsxs(_Fragment, { children: [_jsx(FormSelectionTypeTiersItem, Object.assign({}, services.typesTiersServices, { libelle: "Type tiers" }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "qui" }, { children: "Nom ou code" }), void 0), _jsx(Input, { id: "qui", name: "qui", type: "text", defaultValue: (_b = (_a = props.formData) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.qui, onChange: (e) => {
                            const newValue = e.currentTarget.value;
                            services.quiServices.setQui(newValue);
                        }, placeholder: "Nom ou code", selectMode: "light" }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "ou" }, { children: "Code postal ou ville" }), void 0), _jsx(Input, { id: "o\u00F9", name: "o\u00F9", defaultValue: (_d = (_c = props.formData) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.ou, onChange: (e) => {
                            const newValue = e.currentTarget.value;
                            services.ouServices.setOu(newValue);
                        }, type: "text", placeholder: "Code postal ou ville du tiers", selectMode: "light" }, void 0)] }), void 0), _jsx(Button, { as: "button", buttonStyle: "secondary", size: "S", type: "button", label: "Rechercher", onClick: () => {
                    const formDataToSubmit = {
                        config: props.formData
                            ? props.formData.config
                            : {},
                        data: {
                            typesTiers: services.typesTiersServices.selectedData,
                            ou: services.ouServices.ou,
                            qui: services.quiServices.qui,
                        },
                    };
                    props.notifyFormDataChanged(formDataToSubmit);
                    props.submitSearch(props.transformFormDataToSearchParam(formDataToSubmit));
                } }, void 0)] }, void 0));
}
