import { Adapter } from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";

export function apiGetVisiteEvenementByIDv2<ReturnType, ApiResultType>(
  visiteID: number,
  ID: number,
  adapter: Adapter<ReturnType, ApiResultType>
): Promise<ReturnType> {
  const api = getDefaultApiInstance();
  if (api) {
    return apiGet(
      api,
      `visites/${visiteID}/evenements/${ID}`,
      adapter
    ) as Promise<ReturnType>;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
