import styled from "styled-components";
import FiltresConfigurationAppareils from "./FiltresConfigurationAppareils";
import { ConfigAppareilSpecTiersAPI } from "@ads/isadom-model";

interface PropsFilters {
  prestation: string[];
  masquerAppareilsAbsents: boolean;
}

const WrapperFilterButton = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 2.5rem;

  align-items: center;
  margin-top: 0.9rem;
  ${({ theme }) => theme.media.lg} {
    margin-top: 0;
  }
`;

interface PropsFiltresListeAppareils {
  listeAppareils: ConfigAppareilSpecTiersAPI[];
  setFiltersAppareils: React.Dispatch<React.SetStateAction<PropsFilters>>;
  filtresAppareils: PropsFilters;
}

const FiltresListeConfigurationAppareils = ({
  listeAppareils,
  setFiltersAppareils,
  filtresAppareils,
}: PropsFiltresListeAppareils) => {
  return (
    <WrapperFilterButton>
      <FiltresConfigurationAppareils
        listeAppareils={listeAppareils}
        setFiltersAppareils={setFiltersAppareils}
        filtresAppareils={filtresAppareils}
      />
    </WrapperFilterButton>
  );
};

export default FiltresListeConfigurationAppareils;
