import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from "antd";
import styled from "styled-components";
import MessageItem from "./MessageItem";
const WrapperAlert = styled.div `
  margin-top: 2rem;
`;
const MessageList = ({ visiteID, messages, selection }) => (_jsxs(_Fragment, { children: [!messages ||
            (messages.length === 0 && (_jsx(WrapperAlert, { children: _jsx(Alert, { message: "", showIcon: true, description: "La liste des messages est vide", type: "info" }, void 0) }, void 0))), messages === null || messages === void 0 ? void 0 : messages.map((message) => (_jsx(MessageItem, { visiteID: visiteID, message: message, selection: selection }, message.ID)))] }, void 0));
export default MessageList;
