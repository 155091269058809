import { useState, useEffect } from "react";
import axios from "axios";
import { TypePrestationAPI } from "@ads/isadom-model";
import config from "../config";

const useTypesPrestationsFamille = (familleID: string | undefined | number) => {
  const [typesPrestations, setTypesPrestations] = useState<
    TypePrestationAPI[]
  >();
  const [errorTypesPrestations, setError] = useState("");
  const [loadingTypesPrestations, setloading] = useState(false);

  const url = `${config.apiURL}familles/${familleID}/types-presta`;

  useEffect(() => {
    if (familleID) {
      setloading(true);
      axios
        .get(url, { withCredentials: false })
        .then(({ data }) => {
          const typesPrestationsFamille: TypePrestationAPI[] = data;
          setTypesPrestations(typesPrestationsFamille);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [familleID, url]);

  return { typesPrestations, errorTypesPrestations, loadingTypesPrestations };
};

export default useTypesPrestationsFamille;
