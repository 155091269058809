import { List } from "@ads/ui";
import { IBaseParametreEditionListProps } from "./interfaces/IBaseParametreEditionListProps";

/***
 * @description
 *  Composant React d'une liste de ParametreEdition
 *
 */
export function BaseParametreEditionList(props: IBaseParametreEditionListProps) {
  return (
    <>
      <List {...props} />
    </>
  );
}