import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { ConsommableCard } from "../atoms/ConsommableConfigurationCard";
import { ButtonRemoveConfigConso } from "../atoms/ButtonRemoveConfigConso";
import { ButtonRestaureConfigConso } from "../atoms/ButtonRestaureConfigConso";
import { LabelNew } from "../atoms/LabelNew";
const WrapperConfigConsoItem = styled.div `
  position: relative;
  margin: 0 0 2.65em;
  transition: transform 0.1s ease-out;

  & button {
    transition: all 0.1s ease-out;
    transform: scale(1);
  }
`;
export const ConfigConsoItem = ({ visiteID, configurationConsommable, configurationsConsommable, setConfigurationsConsommable, }) => {
    return (configurationConsommable && (_jsxs(WrapperConfigConsoItem, { children: [!configurationConsommable.bRetraitConfig && (_jsx(ButtonRemoveConfigConso, { visiteID: visiteID, configurationConsommable: configurationConsommable, configurationsConsommable: configurationsConsommable, setConfigurationsConsommable: setConfigurationsConsommable }, void 0)), configurationConsommable.bRetraitConfig && (_jsx(ButtonRestaureConfigConso, { visiteID: visiteID, configurationConsommable: configurationConsommable, configurationsConsommable: configurationsConsommable, setConfigurationsConsommable: setConfigurationsConsommable }, void 0)), configurationConsommable.bAjoutConfig && _jsx(LabelNew, {}, void 0), _jsx(ConsommableCard, { visiteID: visiteID, config: configurationConsommable }, void 0)] }, void 0)));
};
