import {
  CommandeVisiteAdapter,
  CommandeVisiteAPI,
  CommandeVisiteModel,
  HttpResponseAPI,
  HttpResponseModel,
} from "@ads/isadom-model";
import { apiPutV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiPutCommandeVisite(
  visiteID: number,
  commande: CommandeVisiteModel
): Promise<HttpResponseModel<CommandeVisiteModel>> {
  try {
    const api = getDefaultApiInstance();

    if (!api) {
      throw new Error("La configuration d'api n'est pas initialisée.");
    }

    if (!visiteID) {
      throw new Error("L'identifiant de la visite est manquant.");
    }

    if (!commande) {
      throw new Error("Les informations de la commandes sont manquantes.");
    }

    const adapter = new CommandeVisiteAdapter();
    const { data, messages }: HttpResponseAPI<CommandeVisiteAPI> =
      await apiPutV2(
        api,
        `/visites/${visiteID}/commandes`,
        adapter.toAPI(commande),
        apiIsadomErrorStrategy
      );

    return { data: adapter.toClient(data), messages };
  } catch (err: any) {
    return new Promise((_resolve, reject) => {
      reject(err.message);
    });
  }
}
