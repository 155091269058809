import {
  AffectationProduitModel,
  AffectationProduitAPI,
  AffectationProduitAdapter
} from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";

export interface AffectationProduitFormModel {
  C: AffectationProduitModel[];
  M: AffectationProduitModel[];
}

export const apiGetTypesAffectationProduit =
  async (): Promise<AffectationProduitFormModel> => {
    try {
      const api = getDefaultApiInstance();

      if (!api) {
        throw new Error("La configuration d'api n'est pas initialisée");
      }

      const adapter: AffectationProduitAdapter =
        new AffectationProduitAdapter();

      const affectationsConsommablesAPI = await apiGetV2<
        AffectationProduitAPI[]
      >(api, `/natures-produits/C/affectations`);

      const affectationConsommables = adapter.toClients(
        affectationsConsommablesAPI
      );

      const affectationsMaterielsAPI = await apiGetV2<AffectationProduitAPI[]>(
        api,
        `/natures-produits/M/affectations`
      );

      const affectationMateriels = adapter.toClients(affectationsMaterielsAPI);

      return { C: affectationConsommables, M: affectationMateriels };
    } catch (err: any) {
      return new Promise((_resolve, reject) => {
        reject(err.message);
      });
    }
  };
