var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { SelectWrapper, Label, Select } from "@ads/ui";
const Wrapper = styled.div `
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-direction: column;

  & label {
    white-space: nowrap;
    line-height: 4.8rem;
  }

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    & label {
    }
  }
`;
const Notice = styled.div `
  line-height: 4.8rem;
`;
export const FormRetourVisiteLocalisation = ({ localisationsRetraits, retour, refreshAllData, }) => {
    var _a, _b;
    const onSelect = (value) => __awaiter(void 0, void 0, void 0, function* () {
        const localisationRetour = localisationsRetraits.find((l) => l.localisationId === parseInt(value, 10));
        if (localisationRetour) {
            const data = { localisationRetour };
            refreshAllData(data);
        }
    });
    const options = localisationsRetraits.map((localisation) => ({
        value: `${localisation.localisationId}`,
        content: `${localisation.libelle}`,
    }));
    const uniqueID = `localisation-retrait-${retour.ligneRetourID}`;
    return (_jsxs(Wrapper, { children: [_jsx(Label, Object.assign({ htmlFor: uniqueID }, { children: "Localisation\u00A0de\u00A0retrait :" }), void 0), localisationsRetraits.length > 0 ? (_jsx(SelectWrapper, Object.assign({ selectMode: "light" }, { children: _jsx(Select, { id: uniqueID, name: uniqueID, onSelect: onSelect, showSearch: true, filterOption: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, itemsSelect: options, selectMode: "light", placeholder: "S\u00E9lectionnez une localisation", value: ((_a = retour.localisationRetour) === null || _a === void 0 ? void 0 : _a.localisationId)
                        ? `${(_b = retour.localisationRetour) === null || _b === void 0 ? void 0 : _b.localisationId}`
                        : undefined }, void 0) }), void 0)) : (_jsx(Notice, { children: "aucune localisation de retour" }, void 0))] }, void 0));
};
