import { VisiteAdapter, VisiteAPI } from "@ads/isadom-model";
import { getDefaultApiInstance, useApiGetV2 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export function useApiGetVisiteByID(visiteID: number) {
  const adapter = new VisiteAdapter();
  return useApiGetV2(getDefaultApiInstance(), `/visites/${visiteID}`, {
    errorStrategy: apiIsadomErrorStrategy,
    adaptResult(result: VisiteAPI) {
      const adaptModel = adapter.toClient(result);
      return adaptModel;
    }
  });
}
