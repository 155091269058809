import { FC, useState } from "react";
import { Select, Spin } from "antd";
import { useSearchAppareilsMutation } from "store/redux/services/nomadApi";
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import ErrorAppareil from "../atoms/ErrorAppareil";

const { Option } = Select;

interface Props {
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
  disabled?: boolean;
}

const SelectAppareilDeMesure: FC<Props> = ({
  modesReglagesAppareil,
  setModesReglagesAppareil,
  disabled = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [searchAppareils, { isLoading, error, data: appareils }] =
    useSearchAppareilsMutation();

  const handleSearch = (value: string) => {
    if (value.length > 2) {
      setIsOpen(true);
      searchAppareils({
        ref_sn: value,
      });
    }
  };

  const handleSelect = (idAppareilDeMesure: string) => {
    setIsOpen(false);
    setModesReglagesAppareil({
      ...modesReglagesAppareil,
      idAppareilDeMesure,
    });
  };

  const handleClear = () => {
    setModesReglagesAppareil({
      ...modesReglagesAppareil,
      idAppareilDeMesure: "",
    });
  };

  return (
    <>
      <ErrorAppareil errors={error} />
      <Select
        showSearch
        placeholder="Rechercher un appareil"
        onChange={handleSelect}
        onSearch={handleSearch}
        onClear={handleClear}
        onBlur={() => setIsOpen(false)}
        notFoundContent={
          isLoading ? <Spin size="small" /> : <>Aucune données</>
        }
        open={isOpen}
        allowClear
        disabled={disabled}
      >
        {Array.isArray(appareils?.data) &&
          appareils?.data.map((appareil) => (
            <Option key={appareil.appareilID} value={appareil.appareilID}>
              {appareil.numeroSerie} - {appareil.libelleModele}
            </Option>
          ))}
      </Select>
    </>
  );
};

export default SelectAppareilDeMesure;
