var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PrescriptionAdapter, PrestationAdapter } from "../../../../medical";
import { ConfigConsoSpecTiersVisiteAdapter } from "../../../../produit";
import { Adapter } from "../../../../_adapter";
import { PatientAdapter } from "../../adapter";
var FichePatientAdapter = /** @class */ (function (_super) {
    __extends(FichePatientAdapter, _super);
    function FichePatientAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.patientAdapter = new PatientAdapter();
        _this.consommableAdapter = new ConfigConsoSpecTiersVisiteAdapter();
        _this.prescriptionAdapter = new PrescriptionAdapter();
        _this.prestationAdapter = new PrestationAdapter();
        return _this;
    }
    FichePatientAdapter.prototype.toClient = function (src) {
        return {
            alerte: src.ALERTE,
            appareil: src.APPAREIL,
            consommable: this.consommableAdapter.toClients(src.CONSOMMABLE),
            prescription: this.prescriptionAdapter.toClients(src.PRESCRIPTION),
            prestation: this.prestationAdapter.toClients(src.PRESTATION),
            tiers: this.patientAdapter.toClient(src.TIERS[0]),
        };
    };
    FichePatientAdapter.prototype.toAPI = function (src) {
        return {
            ALERTE: src.alerte,
            APPAREIL: src.appareil,
            CONSOMMABLE: this.consommableAdapter.toAPIs(src.consommable),
            PRESCRIPTION: this.prescriptionAdapter.toAPIs(src.prescription),
            PRESTATION: this.prestationAdapter.toAPIs(src.prestation),
            TIERS: this.patientAdapter.toAPI(src.tiers),
        };
    };
    return FichePatientAdapter;
}(Adapter));
export { FichePatientAdapter };
