import { createContext, useContext, useReducer } from "react";
import {
  AdresseModel,
  AffectationProduitModel,
  CommandeVisiteModel,
  EquipeModel,
  FamilleModel,
  HttpMessageModel,
  ModeLivraisonModel,
  PrestationModel,
  ProduitModel,
  VisiteModel,
  PPT
} from "@ads/isadom-model";
import { AffectationProduitFormModel } from "../api/apiGetTypesAffectationProduit";

export interface Ville {
  villeID: number;
  codePostal: string;
  ville: string;
}
interface StoreInterface {
  visite?: VisiteModel;
  commande?: CommandeVisiteModel;
  equipes: EquipeModel[];
  adresses: AdresseModel[];
  modesLivraison: ModeLivraisonModel[];
  prestations: PrestationModel[];
  affectations?: AffectationProduitFormModel;
  typesVoies: PPT[];
  villes: Ville[];
  isLoadingFetch: boolean;
  isError: boolean;
  isLoadingReset: boolean;
  isLoadingSave: boolean;
  responseMessages: HttpMessageModel[];
}

interface ActionInterface {
  type: string;
  payload: any;
}

interface ProviderInterface {
  children: JSX.Element | JSX.Element[];
}

const initialState: StoreInterface = {
  visite: undefined,
  commande: undefined,
  equipes: [],
  adresses: [],
  modesLivraison: [],
  prestations: [],
  affectations: undefined,
  typesVoies: [],
  villes: [],
  isLoadingFetch: false,
  isError: false,
  isLoadingReset: false,
  isLoadingSave: false,
  responseMessages: []
};

const actions = {
  SET_VISITE: "SET_VISITE",
  SET_COMMANDE: "SET_COMMANDE",
  SET_ADRESSE: "SET_ADRESSE",
  SET_TYPE_VOIE: "SET_TYPE_VOIE",
  SET_EQUIPE_PREPARATION: "SET_EQUIPE_PREPARATION",
  SET_MODE_LIVRAISON: "SET_MODE_LIVRAISON",
  SET_COMMENTAIRE: "SET_COMMENTAIRE",
  SET_COMMANDES_INDEPENDANTES: "SET_COMMANDES_INDEPENDANTES",
  TOGGLE_COMMANDE_INDEPENDANTE: "TOGGLE_COMMANDE_INDEPENDANTE",
  SET_PRESTATION: "SET_PRESTATION",
  SET_QUANTITE: "SET_QUANTITE",
  SET_AFFECTATION: "SET_AFFECTATION",
  SET_DATE_LIVRAISON_SOUHAITEE: "SET_DATE_LIVRAISON_SOUHAITEE",
  SET_COMMANDE_INDEPENDANTE: "SET_COMMANDE_INDEPENDANTE",
  ADD_FAMILLE: "ADD_FAMILLE",
  ADD_PRODUIT: "ADD_PRODUIT",
  DELETE_COMMANDE: "DELETE_COMMANDE",
  RESET_COMMANDES: "RESET_COMMANDES",
  SET_ADRESSES_OPTIONS: "SET_ADRESSES_OPTIONS",
  SET_TYPES_VOIES_OPTIONS: "SET_TYPES_VOIES_OPTIONS",
  SET_VILLES_OPTIONS: "SET_VILLES_OPTIONS",
  SET_EQUIPES_OPTIONS: "SET_EQUIPES_OPTIONS",
  SET_MODES_LIVRAISON_OPTIONS: "SET_MODES_LIVRAISON_OPTIONS",
  SET_PRESTATIONS_OPTIONS: "SET_PRESTATIONS_OPTIONS",
  SET_AFFECTATIONS_OPTIONS: "SET_AFFECTATIONS_OPTIONS",
  SET_IS_LOADING_FETCH: "SET_IS_LOADING_FETCH",
  SET_IS_LOADING_SAVE: "SET_IS_LOADING_SAVE",
  SET_IS_LOADING_RESET: "SET_IS_LOADING_RESET",
  SET_IS_ERROR: "SET_IS_ERROR",
  SET_RESPONSE_MESSAGES: "SET_RESPONSE_MESSAGES"
};

const reducer = (state: StoreInterface, action: ActionInterface) => {
  switch (action.type) {
    case actions.SET_VISITE:
      return {
        ...state,
        visite: action.payload
      };
    case actions.SET_IS_LOADING_FETCH:
      return {
        ...state,
        isLoadingFetch: action.payload
      };
    case actions.SET_IS_LOADING_SAVE:
      return {
        ...state,
        isLoadingSave: action.payload
      };
    case actions.SET_IS_LOADING_RESET:
      return {
        ...state,
        isLoadingReset: action.payload
      };
    case actions.SET_IS_ERROR:
      return {
        ...state,
        isError: action.payload
      };
    case actions.SET_RESPONSE_MESSAGES:
      return {
        ...state,
        responseMessages: action.payload
      };
    case actions.SET_COMMANDE:
      return {
        ...state,
        commande: action.payload
      };
    case actions.SET_EQUIPES_OPTIONS:
      return {
        ...state,
        equipes: action.payload
      };
    case actions.SET_ADRESSES_OPTIONS:
      return {
        ...state,
        adresses: action.payload
      };
    case actions.SET_TYPES_VOIES_OPTIONS:
      return {
        ...state,
        typesVoies: action.payload
      };
    case actions.SET_VILLES_OPTIONS:
      return {
        ...state,
        villes: action.payload
      };
    case actions.SET_MODES_LIVRAISON_OPTIONS:
      return {
        ...state,
        modesLivraison: action.payload
      };
    case actions.SET_PRESTATIONS_OPTIONS:
      return {
        ...state,
        prestations: action.payload
      };
    case actions.SET_AFFECTATIONS_OPTIONS:
      return {
        ...state,
        affectations: action.payload
      };
    case actions.SET_ADRESSE:
      return {
        ...state,
        commande: { ...state.commande, adresse: action.payload }
      };
    case actions.SET_TYPE_VOIE:
      return {
        ...state,
        commande: { ...state.commande, typeVoie: action.payload }
      };
    case actions.SET_EQUIPE_PREPARATION:
      return {
        ...state,
        commande: { ...state.commande, equipePreparation: action.payload }
      };
    case actions.SET_MODE_LIVRAISON:
      return {
        ...state,
        commande: { ...state.commande, modeLivraison: action.payload }
      };
    case actions.SET_COMMENTAIRE:
      return {
        ...state,
        commande: { ...state.commande, commentaire: action.payload }
      };
    case actions.SET_COMMANDES_INDEPENDANTES:
      return {
        ...state,
        commande: {
          ...state.commande,
          lignes: state.commande?.lignes.map((ligne) => ({
            ...ligne,
            independante: action.payload
          }))
        }
      };
    case actions.ADD_PRODUIT:
      return {
        ...state,
        commande: {
          ...state.commande,
          lignes: [action.payload, state.commande?.lignes || []]
        }
      };
    case actions.ADD_FAMILLE:
      return {
        ...state,
        commande: {
          ...state.commande,
          lignes: [action.payload, state.commande?.lignes || []]
        }
      };
    case actions.SET_DATE_LIVRAISON_SOUHAITEE:
      return {
        ...state,
        commande: {
          ...state.commande,
          lignes: state.commande?.lignes.map((ligne) =>
            ligne.ID === action.payload.ligneID
              ? { ...ligne, dateLivraisonSouhaitee: action.payload.date }
              : ligne
          )
        }
      };
    case actions.SET_QUANTITE:
      return {
        ...state,
        commande: {
          ...state.commande,
          lignes: state.commande?.lignes.map((ligne) =>
            ligne.ID === action.payload.ligneID
              ? { ...ligne, quantite: action.payload.quantite }
              : ligne
          )
        }
      };
    case actions.SET_COMMANDE_INDEPENDANTE:
      return {
        ...state,
        commande: {
          ...state.commande,
          lignes: state.commande?.lignes.map((ligne) =>
            ligne.ID === action.payload.ligneID
              ? { ...ligne, independante: action.payload.value }
              : ligne
          )
        }
      };
    default:
      return state;
  }
};

interface FormulaireCommandeProviderInterface {
  visite: VisiteModel;
  commande: CommandeVisiteModel;
  equipes: EquipeModel[];
  adresses: AdresseModel[];
  modesLivraison: ModeLivraisonModel[];
  prestations: PrestationModel[];
  affectations: AffectationProduitFormModel;
  typesVoies: PPT[];
  villes: Ville[];
  isLoadingFetch: boolean;
  isError: boolean;
  isLoadingReset: boolean;
  isLoadingSave: boolean;
  responseMessages: HttpMessageModel[];
  setVisite: (visite: VisiteModel) => void;
  setIsLoadingFetch: (loading: boolean) => void;
  setIsLoadingSave: (loading: boolean) => void;
  setIsLoadingReset: (loading: boolean) => void;
  setIsError: (loading: boolean) => void;
  setResponseMessages: (messages: HttpMessageModel[]) => void;
  setCommande: (commande: CommandeVisiteModel) => void;
  setAdressesOptions: (adresses: AdresseModel[]) => void;
  setTypesVoiesOptions: (typesVoies: PPT[]) => void;
  setEquipesOptions: (equipes: EquipeModel[]) => void;
  setModesLivraisonOptions: (equipes: ModeLivraisonModel[]) => void;
  setPrestationsTiersOptions: (prestations: PrestationModel[]) => void;
  setAffectationsOptions: (affectations: AffectationProduitFormModel) => void;
  setVillesOptions: (villes: Ville[]) => void;
  setAdresse: (adresse: AdresseModel) => void;
  setTypeVoie: (typeVoie: PPT) => void;
  setEquipePreparation: (equipe: EquipeModel) => void;
  setModeLivraison: (modeLivraison: ModeLivraisonModel) => void;
  setCommentaire: (commentaire: string) => void;
  setCommandesIndependantes: (value: boolean) => void;
  setDateLivraisonSouhaitee: (ligneID: number, date: string) => void;
  addProduit: (produit: ProduitModel) => void;
  addFamille: (famille: FamilleModel) => void;
  setQuantite: (ligneID: number, quantite: number) => void;
  setPrestation: (ligneID: number, prestation: PrestationModel) => void;
  setAffectation: (
    ligneID: number,
    affectation: AffectationProduitModel
  ) => void;
  setIndependante: (ligneID: number, value: boolean) => void;
}

const FormulaireCommandesStore = createContext(
  undefined as unknown as FormulaireCommandeProviderInterface
);
FormulaireCommandesStore.displayName = "formulaireCommandesStore";

export const useFormulaireCommandesStore = () =>
  useContext(FormulaireCommandesStore);

export const FormulaireCommandeProvider = ({ children }: ProviderInterface) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    isLoadingFetch: state.isLoadingFetch,
    isError: state.isError,
    isLoadingReset: state.isLoadingReset,
    isLoadingSave: state.isLoadingSave,
    responseMessages: state.responseMessages,
    visite: state.visite,
    commande: state.commande,
    equipes: state.equipes,
    adresses: state.adresses,
    modesLivraison: state.modesLivraison,
    villes: state.villes,
    prestations: state.prestations,
    affectations: state.affectations,
    typesVoies: state.typesVoies,
    setIsLoadingFetch: (value: boolean) => {
      dispatch({ type: actions.SET_IS_LOADING_FETCH, payload: value });
    },
    setIsLoadingSave: (value: boolean) => {
      dispatch({ type: actions.SET_IS_LOADING_SAVE, payload: value });
    },
    setIsLoadingReset: (value: boolean) => {
      dispatch({ type: actions.SET_IS_LOADING_RESET, payload: value });
    },
    setIsError: (value: boolean) => {
      dispatch({ type: actions.SET_IS_ERROR, payload: value });
    },
    setResponseMessages: (messages: HttpMessageModel[]) => {
      dispatch({ type: actions.SET_RESPONSE_MESSAGES, payload: messages });
    },
    setVisite: (visite: VisiteModel) => {
      dispatch({ type: actions.SET_VISITE, payload: visite });
    },
    setCommande: (commande: CommandeVisiteModel) => {
      dispatch({ type: actions.SET_COMMANDE, payload: commande });
    },
    setAdressesOptions: (adresses: AdresseModel[]) => {
      dispatch({ type: actions.SET_ADRESSES_OPTIONS, payload: adresses });
    },
    setEquipesOptions: (equipes: EquipeModel[]) => {
      dispatch({ type: actions.SET_EQUIPES_OPTIONS, payload: equipes });
    },
    setModesLivraisonOptions: (modesLivraison: ModeLivraisonModel[]) => {
      dispatch({
        type: actions.SET_MODES_LIVRAISON_OPTIONS,
        payload: modesLivraison
      });
    },
    setVillesOptions: (villes: Ville[]) => {
      dispatch({
        type: actions.SET_VILLES_OPTIONS,
        payload: villes
      });
    },
    setPrestationsTiersOptions: (prestations: PrestationModel[]) => {
      dispatch({
        type: actions.SET_PRESTATIONS_OPTIONS,
        payload: prestations
      });
    },
    setAffectationsOptions: (affectations: AffectationProduitFormModel) => {
      dispatch({
        type: actions.SET_AFFECTATIONS_OPTIONS,
        payload: affectations
      });
    },
    setTypesVoiesOptions: (typesVoies: PPT[]) => {
      dispatch({
        type: actions.SET_TYPES_VOIES_OPTIONS,
        payload: typesVoies
      });
    },
    setAdresse: (adresse: AdresseModel) => {
      dispatch({ type: actions.SET_ADRESSE, payload: adresse });
    },
    setTypeVoie: (typeVoie: PPT) => {
      dispatch({ type: actions.SET_TYPES_VOIES_OPTIONS, payload: typeVoie });
    },
    setEquipePreparation: (equipe: EquipeModel) => {
      dispatch({ type: actions.SET_EQUIPE_PREPARATION, payload: equipe });
    },
    setModeLivraison: (modeLivraison: ModeLivraisonModel) => {
      dispatch({ type: actions.SET_MODE_LIVRAISON, payload: modeLivraison });
    },
    setCommentaire: (commentaire: string) => {
      dispatch({ type: actions.SET_COMMENTAIRE, payload: commentaire });
    },
    setCommandesIndependantes: (value: boolean) => {
      dispatch({ type: actions.SET_COMMANDES_INDEPENDANTES, payload: value });
    },
    addProduit: (produit: ProduitModel) => {
      dispatch({ type: actions.ADD_PRODUIT, payload: produit });
    },
    addFamille: (famille: FamilleModel) => {
      dispatch({ type: actions.ADD_FAMILLE, payload: famille });
    },
    setDateLivraisonSouhaitee: (ligneID: number, date: string) => {
      dispatch({
        type: actions.SET_DATE_LIVRAISON_SOUHAITEE,
        payload: { ligneID, date }
      });
    },
    setQuantite: (ligneID: number, quantite: number) => {
      dispatch({
        type: actions.SET_QUANTITE,
        payload: { ligneID, quantite }
      });
    },
    setPrestation: (ligneID: number, prestation: PrestationModel) => {
      dispatch({
        type: actions.SET_PRESTATION,
        payload: { ligneID, prestation }
      });
    },
    setAffectation: (ligneID: number, affectation: AffectationProduitModel) => {
      dispatch({
        type: actions.SET_AFFECTATION,
        payload: { ligneID, affectation }
      });
    },
    setIndependante: (ligneID: number, value: boolean) => {
      dispatch({
        type: actions.SET_COMMANDE_INDEPENDANTE,
        payload: { ligneID, value }
      });
    }
  };

  return (
    <FormulaireCommandesStore.Provider value={value}>
      {children}
    </FormulaireCommandesStore.Provider>
  );
};
