import styled from "styled-components";

export const Details = styled.div`
  padding: 0.3rem;
  margin-top: 0.6rem;

  ${({ theme }) => theme.media.md} {
    width: calc(50% - 1rem);
    padding: 0rem;
    margin-top: 0rem;
  }
`;

export const Detail = styled.div`
  display: flex;
  margin: 0 0 1rem;
  width: 50%;

  ${({ theme }) => theme.media.md} {
    max-width: calc(50% - 1rem);
  }
`;

export const Nom = styled.div`
  margin: 0 1rem 0 0;
  min-width: 100%;
  font-weight: bold;
`;

export const Valeur = styled.div`
  width: 100%;
`;
