import styled from "styled-components";
export const Card = styled("div") `
  padding: 1.8rem;
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  cursor: pointer;

  margin-bottom: 1rem;
  ${({ theme }) => theme.media.md} {
    padding: 2.5rem;
  }
`;
