import { useEffect, useReducer } from "react";
import styled from "styled-components";
import { DatePicker } from "antd";
import {
  EvenementStandardModel,
  TypeEvenementModel,
  TypeTiersModel,
  LieuExerciceMedecinModel,
  PrestationModel,
  CureModel,
  PPT,
  VisiteModel,
  PrescriptionAPI
} from "@ads/isadom-model";
import {
  FormItemWrapper,
  Label,
  Textarea,
  SelectWrapper,
  Select
} from "@ads/ui";
import {
  SelectionTiersV3,
  TiersModule,
  useDefaultSelectionTiersV3Props
} from "@ads/module-tiers";
import { ModuleProvider } from "@ads/react-common-module";

import moment from "moment";
import locale from "antd/es/date-picker/locale/fr_FR";

import { apiGetLieuxExerciceByTiersID } from "modules/evenement/api/apiGetLieuxExerciceByTiersID";
import {
  apiGetPrescriptionsByTiersID,
  PrescriptionSearchParams
} from "modules/evenement/api";
import { apiGetTypesTiers } from "modules/evenement/api/apiGetTypesTiers";
import {
  apiGetCuresByTiersID,
  apiGetTypesEvenementsByVisiteID,
  CureSearchParams
} from "modules/evenement/api";
import { Details } from "../../atoms/viewFields";
import { apiGetPrestationsByTiersID } from "modules/evenement/api/apiGetPrestationsByTiersID";
import { TiersModel } from "@ads/isadom-model";

const DatePickerANTD = styled(DatePicker)`
  background: ${(props) => props.theme.colors.neutral[130]};
`;
interface EvenementStandardState {
  typesEvenementsOptions: TypeEvenementModel[];
  typesTiersOptions: TypeTiersModel[];
  lieuxOptions: LieuExerciceMedecinModel[];
  prestationsOptions: PrestationModel[];
  prescriptionsOptions: PrescriptionAPI[];
  curesOptions: CureModel[];
  precisionsOptions: PPT[];
}
interface StateReducerAction {
  type: string;
  payload: any;
}
interface EvenementStandardDetailsFormProps {
  visite: VisiteModel;
  evenement: EvenementStandardModel;
  handleChange: (evenement: EvenementStandardModel) => void;
}

export const EvenementStandardDetailsForm = ({
  visite,
  evenement,
  handleChange
}: EvenementStandardDetailsFormProps) => {
  const initialState: EvenementStandardState = {
    typesEvenementsOptions: [],
    typesTiersOptions: [],
    lieuxOptions: [],
    prestationsOptions: [],
    prescriptionsOptions: [],
    curesOptions: [],
    precisionsOptions: []
  };

  const reducer = (
    state: EvenementStandardState,
    action: StateReducerAction
  ) => {
    switch (action.type) {
      case "setTypesEvenementsOptions":
        return { ...state, typesEvenementsOptions: action.payload };

      case "setTypesTiersOptions":
        return { ...state, typesTiersOptions: action.payload };

      case "setLieuxOptions":
        return { ...state, lieuxOptions: action.payload };

      case "setPrestationsOptions":
        return { ...state, prestationsOptions: action.payload };

      case "setPrescriptionsOptions":
        return { ...state, prescriptionsOptions: action.payload };

      case "setCuresOptions":
        return { ...state, curesOptions: action.payload };

      case "setPrecisionsOptions":
        return { ...state, precisionsOptions: action.payload };

      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const services = useDefaultSelectionTiersV3Props({
    formConfig: {
      ouConfig: {
        allowChange: true,
        defaultData: ""
      },
      quiConfig: {
        allowChange: true,
        defaultData: ""
      },
      typesTiersConfig: {
        allowChange: true,
        defaultSelectedData: state.typesTiersOptions,
        typeSelection: "simple",
        visible: true
      }
    },
    onTiersSelected: (tiersSelected: TiersModel[]) => {
      if (tiersSelected.length > 0) {
        handleChange({
          ...evenement,
          tiersOrigine: tiersSelected[0],
          lieuExercice: undefined
        } as EvenementStandardModel);
      }
    },
    selectionConfig: {
      defaultSelectedData: evenement.tiersOrigine
        ? [evenement.tiersOrigine]
        : [],
      typeSelection: "simple"
    }
  });

  const tiersModule = new TiersModule();

  const getSearchDate = (dateDebut: string | undefined) => {
    let searchDate = new Date();

    if (dateDebut && dateDebut !== "0000-00-00") {
      searchDate = new Date(dateDebut);
    }

    return searchDate.toISOString().slice(0, 10).split("-").join("");
  };

  useEffect(() => {
    const getTypesTiers = async () => {
      try {
        const result = await apiGetTypesTiers();
        const typesTiers = result.data.filter(
          (typeTiers: TypeTiersModel) =>
            !typeTiers.isInactif && typeTiers.typeTiersID !== "GRD"
        );

        dispatch({ type: "setTypesTiersOptions", payload: typesTiers });
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    getTypesTiers();
  }, []);

  useEffect(() => {
    const getTypesEvenement = async (visiteID: number) => {
      try {
        const result = (await apiGetTypesEvenementsByVisiteID(visiteID, "STD"))
          .data;

        dispatch({ type: "setTypesEvenementsOptions", payload: result });
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    if (visite.ID) {
      getTypesEvenement(visite.ID);
    }
  }, [visite.ID]);

  useEffect(() => {
    const getLieuxExercice = async (tiersID: number) => {
      try {
        const result = await apiGetLieuxExerciceByTiersID(tiersID);

        dispatch({ type: "setLieuxOptions", payload: result });
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    if (evenement.tiersOrigine) {
      getLieuxExercice(evenement.tiersOrigine?.tiersID);
    }
  }, [evenement.tiersOrigine]);

  useEffect(() => {
    const getPrestations = async (tiersID: number) => {
      try {
        const result = await apiGetPrestationsByTiersID(
          tiersID,
          getSearchDate(evenement?.dateDebut)
        );

        dispatch({ type: "setPrestationsOptions", payload: result });
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    if (evenement.tiers?.tiersID) {
      getPrestations(evenement.tiers.tiersID);
    }
  }, [evenement?.dateDebut, evenement.tiers.tiersID]);

  useEffect(() => {
    const getPrescriptions = async (tiersID: number, prestationID?: number) => {
      try {
        const searchParams: PrescriptionSearchParams = {
          tiersID,
          dDebut: getSearchDate(evenement?.dateDebut),
          prestationID
        };
        const result = (await apiGetPrescriptionsByTiersID(searchParams)).data;

        dispatch({
          type: "setPrescriptionsOptions",
          payload: result
        });
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    if (evenement.tiers?.tiersID) {
      getPrescriptions(evenement.tiers.tiersID, evenement.prestation?.id);
    }
  }, [evenement?.dateDebut, evenement.prestation?.id, evenement.tiers.tiersID]);

  useEffect(() => {
    const getCures = async (tiersID: number) => {
      try {
        const searchParams: CureSearchParams = {
          tiersID,
          dDebut: getSearchDate(evenement?.dateDebut),
          prestationID: undefined,
          prescriptionID: undefined
        };
        const result = await apiGetCuresByTiersID(searchParams);

        dispatch({ type: "setCuresOptions", payload: result });
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    if (evenement.tiers?.tiersID) {
      getCures(evenement.tiers?.tiersID);
    }
  }, [evenement?.dateDebut, evenement.tiers?.tiersID]);

  useEffect(() => {
    if (evenement.typeEvenement) {
      dispatch({
        type: "setPrecisionsOptions",
        payload: evenement.typeEvenement?.precisions
      });
    }
  }, [evenement.typeEvenement]);

  return (
    <Details>
      <FormItemWrapper direction="vertical">
        <Label htmlFor="type-evenement">&Eacute;vènement</Label>
        <SelectWrapper selectMode="light">
          <Select
            disabled={
              state.typesEvenementsOptions.length === 0 ||
              !!evenement.evenementVisiteID
            }
            id="type-evenement"
            name="type-evenement"
            onSelect={(value) => {
              handleChange({
                ...evenement,
                typeEvenement: {
                  ID: value,
                  precisions: state.typesEvenementsOptions.find(
                    (t: TypeEvenementModel) => t.ID === value
                  ).precisions
                },
                precision: undefined
              } as unknown as EvenementStandardModel);
            }}
            showSearch
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            itemsSelect={state.typesEvenementsOptions.map(
              (typeEvenement: TypeEvenementModel) => ({
                value: typeEvenement.ID,
                content: typeEvenement.libelle
              })
            )}
            selectMode={"light"}
            placeholder="Sélectionnez un type d'évènement"
            defaultValue={
              evenement.typeEvenement
                ? `${evenement.typeEvenement.ID}`
                : undefined
            }
            allowClear
          />
        </SelectWrapper>
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="dateDebut" selectMode="dark">
          Date de début
        </Label>
        <DatePickerANTD
          name="dateDebut"
          id="dateDebut"
          defaultValue={
            evenement.dateDebut !== "0000-00-00"
              ? moment(evenement.dateDebut, "YYYY-MM-DD")
              : undefined
          }
          format="DD/MM/YYYY"
          locale={locale}
          onChange={(value) => {
            handleChange({
              ...evenement,
              dateDebut: value ? value.format("YYYY-MM-DD") : ""
            } as EvenementStandardModel);
          }}
          placeholder="Date de début"
        />
      </FormItemWrapper>
      <FormItemWrapper direction="vertical">
        <Label htmlFor="dateFin" selectMode="dark">
          Date de fin
        </Label>
        <DatePickerANTD
          name="dateFin"
          id="dateFin"
          defaultValue={
            evenement.dateFin !== "0000-00-00" &&
            evenement.dateFin !== undefined
              ? moment(evenement.dateFin, "YYYY-MM-DD")
              : undefined
          }
          format="DD/MM/YYYY"
          locale={locale}
          onChange={(value) => {
            handleChange({
              ...evenement,
              dateFin: value ? value.format("YYYY-MM-DD") : ""
            } as EvenementStandardModel);
          }}
          placeholder="Date de début"
        />
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="tiers-origine">Tiers d'origine</Label>
        <ModuleProvider module={tiersModule}>
          <SelectionTiersV3 service={services} />
        </ModuleProvider>
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="lieu-exercice">Lieu d'exercice</Label>
        <SelectWrapper selectMode="light">
          <Select
            disabled={
              state.lieuxOptions.length === 0 ||
              !evenement.tiersOrigine ||
              evenement.tiersOrigine.typeTiers !== "MED"
            }
            id="lieu-exercice"
            name="lieu-exercice"
            onSelect={(value) => {
              handleChange({
                ...evenement,
                lieuExercice: {
                  ID: parseInt(value, 10)
                }
              } as EvenementStandardModel);
            }}
            showSearch
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            itemsSelect={state.lieuxOptions.map(
              (lieuExercice: LieuExerciceMedecinModel) => ({
                value: `${lieuExercice.ID}`,
                content: `${lieuExercice.adresse?.adresseComplete}${
                  lieuExercice.adresse?.libelle
                    ? ` (${lieuExercice.adresse?.libelle})`
                    : ``
                }`
              })
            )}
            selectMode={"light"}
            placeholder="Sélectionnez un lieu d'exercice"
            value={
              evenement.lieuExercice
                ? `${evenement.lieuExercice.ID}`
                : undefined
            }
            allowClear
          />
        </SelectWrapper>
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="prestation">Prestation</Label>
        <SelectWrapper selectMode="light">
          <Select
            disabled={state.prestationsOptions.length === 0}
            id="prestation"
            name="prestation"
            onSelect={(value) => {
              handleChange({
                ...evenement,
                prestation: {
                  id: parseInt(value, 10)
                }
              } as EvenementStandardModel);
            }}
            showSearch
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            itemsSelect={state.prestationsOptions.map(
              (prestation: PrestationModel) => ({
                value: `${prestation.id}`,
                content: prestation.type.libelle
              })
            )}
            selectMode={"light"}
            placeholder="Sélectionnez un type de prestation"
            defaultValue={
              evenement.prestation ? `${evenement.prestation.id}` : undefined
            }
            allowClear
          />
        </SelectWrapper>
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="prescription">Prescription</Label>
        <SelectWrapper selectMode="light">
          <Select
            disabled={state.prescriptionsOptions.length === 0}
            id="prescription"
            name="prescription"
            onSelect={(value) => {
              handleChange({
                ...evenement,
                prescription: {
                  ID: parseInt(value, 10)
                }
              } as EvenementStandardModel);
            }}
            showSearch
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            itemsSelect={state.prescriptionsOptions.map(
              (prescription: PrescriptionAPI) => ({
                value: `${prescription.prescriptionID}`,
                content: prescription.codePrescription.sLibelle
              })
            )}
            selectMode={"light"}
            placeholder="Sélectionnez une prescription"
            defaultValue={
              evenement.prescription
                ? `${evenement.prescription.ID}`
                : undefined
            }
            allowClear
          />
        </SelectWrapper>
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="cure">Cure</Label>
        <SelectWrapper selectMode="light">
          <Select
            disabled={state.curesOptions.length === 0}
            id="cure"
            name="cure"
            onSelect={(value) => {
              handleChange({
                ...evenement,
                cure: {
                  ID: parseInt(value, 10)
                }
              } as EvenementStandardModel);
            }}
            showSearch
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            itemsSelect={state.curesOptions.map((cure: CureModel) => ({
              value: `${cure.ID}`,
              content: cure.cure
            }))}
            selectMode={"light"}
            placeholder="Sélectionnez une cure"
            defaultValue={evenement.cure ? `${evenement.cure.ID}` : undefined}
            allowClear
          />
        </SelectWrapper>
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="precision">Précision</Label>
        <SelectWrapper selectMode="light">
          <Select
            disabled={state.precisionsOptions.length === 0}
            id="precision"
            name="precision"
            onSelect={(value) => {
              handleChange({
                ...evenement,
                precision: {
                  iPKParametre: parseInt(value, 10)
                }
              } as EvenementStandardModel);
            }}
            showSearch
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            itemsSelect={state.precisionsOptions.map((precision: PPT) => ({
              value: `${precision.iPKParametre}`,
              content: precision.sLibelle
            }))}
            selectMode={"light"}
            placeholder="Sélectionnez une précision"
            defaultValue={
              evenement.precision
                ? `${evenement.precision.iPKParametre}`
                : undefined
            }
            allowClear
          />
        </SelectWrapper>
      </FormItemWrapper>

      <Label htmlFor="motif">Motif</Label>
      <Textarea
        id="motif"
        name="motif"
        value={evenement.motif}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          handleChange({
            ...evenement,
            motif: e.target.value
          } as EvenementStandardModel);
        }}
        selectMode="light"
        placeholder=""
      />
    </Details>
  );
};
