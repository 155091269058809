import { Alert, Table, Tooltip } from "antd";
import styled from "styled-components";
import { CommentOutlined } from "@ant-design/icons";
import { IHistoriqueTab } from "interface/produit/appareil/historique";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";

const WrapperAlertDesktop = styled(WrapperAlert)`
  display: none;
  ${({ theme }) => theme.media.md} {
    display: inherit;
  }
`;

const Wrapper = styled.div`
  display: none;
  th {
    font-family: ${({ theme }) => theme.font.type.primaryMedium};
    text-transform: uppercase;
    ${({ theme }) => theme.media.md} {
      font-size: 1.2rem;
      font-style: bold;
    }
  }

  .center-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-wrapper {
    box-shadow: 1px -0.4rem 1.2rem rgb(80 70 70 / 21%);
    border-radius: 0.6rem;
  }

  .ant-tooltip-content {
    padding: 2rem 2rem;
  }

  .ant-tooltip-inner {
    padding: 2rem 2rem;
  }

  ${({ theme }) => theme.media.md} {
    display: inherit;
    margin-top: 4rem;

    .preponderance {
      text-align: center;
    }

    .refProduit {
      padding-left: 3rem !important;
    }

    .ant-table-thead > tr > th {
      background-color: ${({ theme }) => theme.colors.neutral[100]};
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background: none;
    }

    .ant-table-tbody > tr > td {
      font-size: 1.4rem;
      line-height: 2rem;
      padding: 1.6rem 0.8rem;
    }
  }
`;

const WrapperIconAntd = styled.span`
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 2rem;
  transition: all 0.3s linear;
  svg {
    path {
      color: ${({ theme }) => theme.colors.neutral[180]};
    }
    &:hover {
      path {
        color: ${({ theme }) => theme.colors.primary[100]};
      }
    }
  }
`;

const sort = (a: any, b: any, columnName: string) => {
  if (a[columnName] < b[columnName]) {
    return -1;
  }
  if (a[columnName] > b[columnName]) {
    return 1;
  }
  return 0;
};

const columns = [
  {
    title: "Ref. Produit",
    dataIndex: "refProduit",
    sorter: {
      compare: (a: any, b: any) => sort(a, b, "refProduit"),
    },
    key: "refProduit",
    width: 100,
    className: "refProduit",
  },
  {
    title: "Famille",
    dataIndex: "famille",
    sorter: {
      compare: (a: any, b: any) => sort(a, b, "famille"),
    },
    key: "famille",
    width: 120,
    className: "famille",
  },
  {
    title: "Modèle",
    dataIndex: "modele",
    sorter: {
      compare: (a: any, b: any) => sort(a, b, "modele"),
    },
    key: "modele",
    width: 120,
    className: "modele",
  },
  {
    title: "",
    dataIndex: "preponderance",
    key: "preponderance",
    width: 20,
    className: "preponderance",
  },
  {
    title: "Installation",
    dataIndex: "installation",
    sorter: (a: any, b: any) => {
      a = a.installation.split("/").reverse().join("");
      b = b.installation.split("/").reverse().join("");
      return a > b ? 1 : a < b ? -1 : 0;
    },
    key: "installation",
    width: 100,
    className: "installation",
  },
  {
    title: "",
    dataIndex: "rappelCommentairesDm",
    key: "rappelCommentairesDm",
    width: 20,
    render: (text: string) => {
      if (text) {
        return (
          <div className="center-cell">
            <Tooltip placement="topLeft" title={text}>
              <WrapperIconAntd className="commentaires">
                <CommentOutlined />
              </WrapperIconAntd>
            </Tooltip>
          </div>
        );
      }
    },
  },
  {
    title: "Retrait",
    dataIndex: "retrait",
    sorter: (a: any, b: any) => {
      a = a.retrait.split("/").reverse().join("");
      b = b.retrait.split("/").reverse().join("");
      return a > b ? 1 : a < b ? -1 : 0;
    },
    key: "retrait",
    width: 100,
    className: "retrait",
  },
  {
    title: "",
    dataIndex: "rappelLocalisation",
    key: "rappelLocalisation",
    width: 10,
    className: "rappelLocalisation",
    render: (text: string) => {
      if (text) {
        return (
          <div className="center-cell">
            <Tooltip placement="topLeft" title={text}>
              <WrapperIconAntd className="commentaires">
                <CommentOutlined />
              </WrapperIconAntd>
            </Tooltip>
          </div>
        );
      }
    },
  },
  {
    title: "Affectation",
    dataIndex: "affectation",
    key: "affectation",
    width: 120,
    className: "affectation",
  },
];

interface DesktopHistoriqueProps {
  historique?: Array<IHistoriqueTab>;
}

const DesktopHistorique = ({ historique }: DesktopHistoriqueProps) => {
  if (historique && historique?.length > 15) {
    return (
      <Wrapper>
        <Table
          columns={columns}
          dataSource={historique}
          size="middle"
          pagination={{
            position: ["bottomRight"],
            defaultPageSize: 15,
            showSizeChanger: false,
          }}
        />
      </Wrapper>
    );
  }
  if (historique && historique?.length === 0) {
    return (
      <WrapperAlertDesktop>
        <Alert
          message="Information"
          showIcon
          description="Aucun historique trouvé"
          type="info"
        />
      </WrapperAlertDesktop>
    );
  }
  return (
    <Wrapper>
      <Table
        columns={columns}
        dataSource={historique}
        size="middle"
        pagination={false}
      />
    </Wrapper>
  );
};

export default DesktopHistorique;
