import { IConstanteModel } from "@ads/isadom-model";
import { ILoadingsProps } from "modules/fiche-suivi/common/interfaces";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { ConstanteListItemProps } from "./";


const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Item = styled.div`
  display: block;
`;


interface ConstanteListPropsCallbacks {
  callbacks: {
    onChange?(data: IConstanteModel): Promise<IConstanteModel>;
  };
}

export type ConstanteListProps = {
  data: IConstanteModel[] | null;
  itemAdapter: FunctionComponent<ConstanteListItemProps>;
} & ILoadingsProps &
  ConstanteListPropsCallbacks;

export function ConstanteList(props: ConstanteListProps) {
  return (
    <>
      <Wrapper>
        {props.data?.map((value) => {
          return (
            <Item className="item">
              {props.itemAdapter({
                data: value,
                callbacks: {
                  onChange: props.callbacks?.onChange,
                },
              })}
            </Item>
          );
        })}
      </Wrapper>
    </>
  );
}
