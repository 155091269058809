/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AdresseModel,
  IParametreEditionModel,
  TiersModel
} from "@ads/isadom-model";
import { ApiInstance } from "@ads/react-common-module";
import { getAllTypeTiers } from "modules/fiche-suivi/feature/getAllTypeTiers";
import { useState } from "react";
import {
  ISelectionTiersDestinatairePEHookResult,
  useSelectionTiersDestinatairePE
} from "../../parametre-edition-list";
import {
  IParamatreEditionListHookResult,
  useParametreEditionList
} from "../../parametre-edition-list/hook/useParametreEditionList";
import { IParametreEditionHookInput } from "./interfaces";

export interface IParametreEditionHookResultFeatures {
  notifyTiersSelected(tiers: TiersModel[]): void;
  getActualPE(): IParametreEditionModel[];
}

export interface IParametreEditionHookResult {
  serviceSelectionTiers: ISelectionTiersDestinatairePEHookResult;
  servicePEList: IParamatreEditionListHookResult;
  features: IParametreEditionHookResultFeatures;
}

export function useParametreEdition(
  input: IParametreEditionHookInput
): IParametreEditionHookResult {
  const [parametresEditions, setParametreEditions] = useState(
    input.data.parametresEditions
  );

  const serviceSelectionTiers = useSelectionTiersDestinatairePE({
    features: {
      getAllTypeTiers: getAllTypeTiers
    }
  });

  const servicePEList = useParametreEditionList({
    data: parametresEditions,
    fetchParams: {},
    getDataFunction(api: ApiInstance | null, params: Record<string, any>) {
      return new Promise((resolve, _reject) => {
        resolve(parametresEditions);
      });
    },
    isEquals(data1, data2) {
      return data1.tiers.tiersID === data2.tiers.tiersID;
    },
    delete(peID) {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    update(pe) {
      return new Promise((resolve) => {
        resolve(pe);
      });
    }
  });

  servicePEList.registerListener({
    idListener: "@ads/useParametreEdition",
    onBeforeDeleteItem: async function (
      item: IParametreEditionModel | null
    ): Promise<IParametreEditionModel | null> {
      return item;
    },
    onAfterDeleteItem: function (item: IParametreEditionModel): void {},
    onBeforeAddItem: async function (
      item: IParametreEditionModel | null
    ): Promise<IParametreEditionModel | null> {
      return item;
    },
    onAfterAddItem: function (item: IParametreEditionModel): void {},
    onBeforemModifyItem: async function (
      item: IParametreEditionModel | null
    ): Promise<IParametreEditionModel | null> {
      if (item) {
        const newPE = parametresEditions;
        const index = newPE.findIndex(
          (value) => value.tiers.tiersID === item?.tiers.tiersID
        );
        if (index !== -1) {
          newPE[index] = item;
          setParametreEditions([...newPE]);
        } else {
          throw new Error(
            "Le paramètre d'édition n'existe pas. Impossible de le modifier"
          );
        }
      }
      return item;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onAfterModifyItem: function (item: IParametreEditionModel): void {
      // do nothing.
    },
    onBeforeDeleteAll: async function (): Promise<boolean> {
      return true;
    },
    onAfterDeleteAll: function (): void {}
  });

  function onTiersSelected(tiersSelected: TiersModel[]): void {
    const newPe = [...parametresEditions];
    for (const tiers of tiersSelected) {
      const index = newPe.findIndex(
        (value) => value.tiers.tiersID === tiers.tiersID
      );
      if (index === -1) {
        const pe: IParametreEditionModel = {
          id: 0,
          adresse: tiers.adressePrincipale
            ? tiers.adressePrincipale
            : ({} as AdresseModel),
          tiers: tiers
        };
        newPe.push(pe);
      }
    }
    setParametreEditions([...newPe]);
  }

  function getActualPE() {
    return parametresEditions;
  }

  return {
    servicePEList: servicePEList,
    serviceSelectionTiers: serviceSelectionTiers,
    features: {
      notifyTiersSelected: onTiersSelected,
      getActualPE: getActualPE
    }
  };
}
