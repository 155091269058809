import { VisiteModel } from "@ads/isadom-model";
import { useState } from "react";
import { useEnregistreVisite } from "./useEnregistreVisite";

export interface ObservationsFinVisiteHookInput {
  visite: VisiteModel;
}

export interface ObservationsFinVisiteHookService {
  visite: VisiteModel;
  saveLoading: boolean;
  notifyObservationTypePrestationChange(
    typeVisiteID: string,
    typePrestationID: string,
    observation: string
  ): void;
  notifyObservationTypeVisiteChange(
    typeVisiteID: string,
    observation: string
  ): void;
  saveVisite(visite: VisiteModel): void;
}

export function useObservationsFinVisite(
  input: ObservationsFinVisiteHookInput
): ObservationsFinVisiteHookService {
  const [visite, setVisite] = useState(input.visite);

  function notifyObservationTypePrestationChange(
    typeVisiteID: string,
    typePrestationID: string,
    observation: string
  ): void {
    const newVisite = visite;
    for (let i = 0; i < newVisite.tTypeVisite.length; i++) {
      for (
        let j = 0;
        j < newVisite.tTypeVisite[i].tTypePrestation.length;
        j++
      ) {
        if (
          typeVisiteID === newVisite.tTypeVisite[i].sPKTypeVisite &&
          typePrestationID === newVisite.tTypeVisite[i].tTypePrestation[j].id
        ) {
          newVisite.tTypeVisite[i].tTypePrestation[j].informationContext =
            observation;
          break;
        }
      }
    }
    setVisite({ ...newVisite });
  }

  function notifyObservationTypeVisiteChange(
    typeVisiteID: string,
    observation: string
  ): void {
    const newVisite = visite;
    for (let i = 0; i < newVisite.tTypeVisite.length; i++) {
      if (typeVisiteID === newVisite.tTypeVisite[i].sPKTypeVisite) {
        newVisite.tTypeVisite[i].informationContext = observation;
        break;
      }
    }
    setVisite({ ...newVisite });
  }

  const { enregistreVisite, loading } = useEnregistreVisite();

  function saveVisite(visite: VisiteModel) {
    enregistreVisite(visite).then((visiteUpdated) => {
      if (visiteUpdated) {
        setVisite({ ...visiteUpdated });
      }
    });
  }

  return {
    visite,
    saveLoading: loading,
    notifyObservationTypePrestationChange,
    notifyObservationTypeVisiteChange,
    saveVisite: saveVisite,
  };
}
