import { RendezVousAdapter } from "@ads/isadom-model";
import { PageCreerVisite } from "pages/feuille-de-route/PageCreerVisite";
import { FC } from "react";
import { useGetRendezVousQuery } from "store/redux/services/nomadApi";

/**
 * @desc  Container qui permet de récupérer le rendez-vous en fonction de l'iPKVisite
 * et de passer le rendez-vous à la vue dans un état défini car dans PageCreerVisite on ne peut pas faire
 * if (rdv) useFormCreerVisite({ rdv }); et useFormCreerVisite a besoin d'un rdv défini pour fonctionner
 */
const ContainerCreerVisite: FC = () => {
  const rdvID = new URLSearchParams(window.location.search).get("rdvID");
  if (!rdvID) return <PageCreerVisite />;

  const {
    data: rendezVousAPI,
    isError,
    isLoading
  } = useGetRendezVousQuery(+rdvID);
  const rdv = rendezVousAPI && new RendezVousAdapter().toClient(rendezVousAPI);

  if (rdv) {
    return (
      <PageCreerVisite rdv={rdv} isError={isError} isLoading={isLoading} />
    );
  }
  return <></>;
};

export default ContainerCreerVisite;
