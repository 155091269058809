import ProtectedRoute from "components/core/routes/ProtectedRoute";
import PageMessages from "pages/visites/messages/PageMessages";
import { Switch } from "react-router-dom";

export function MessagerieRouter() {
  return (
    <>
      <Switch>
        {/* Page listing */}
        <ProtectedRoute
          path="/visites/:iPKVisite/messages/:messageID?"
          exact
          component={PageMessages}
        />
      </Switch>
    </>
  );
}
