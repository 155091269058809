import { TypeTiersAdapter, TypeTiersModel } from "@ads/isadom-model";
import { ApiInstance, apiSearch } from "@ads/react-common-module";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITypeTiersSearchParamsAPI {}

export function apiSearchTypeTiers(
  api: ApiInstance | null,
  searchParams: ITypeTiersSearchParamsAPI
): Promise<TypeTiersModel[]> {
  if (api) {
    const adapter = new TypeTiersAdapter();
    return apiSearch(
      api,
      `/types-tiers/recherche`,
      adapter,
      searchParams
    ) as Promise<TypeTiersModel[]>;
  } else {
    throw new Error("La configuration d'api est null");
  }
}
