import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ConfigurationConsommablesListHeader, } from "../organisms/ConfigurationConsommablesListHeader";
import { ConfigConsoItem } from "../molecules/ConfigConsoItem";
import styled from "styled-components";
const WrapperList = styled.div `
  margin-top: 3rem;
`;
export const ConsommablesConfigurationTemplate = ({ configurationsConsommable: configurationsConsommableAPI, visiteID, setConfigurationsConsommable, SelectionConsommable, }) => {
    // Filtre des consommables
    const [filtreConfiguration, setFiltreConfiguration] = useState();
    // On applique les filtres sur la liste des consommables
    const configurationsConsommableFiltered = configurationsConsommableAPI.filter((configurationConsommable) => {
        var _a;
        // Si null on retourne le consommable
        if (!(filtreConfiguration === null || filtreConfiguration === void 0 ? void 0 : filtreConfiguration.idTypePrestation)) {
            return true;
        }
        return (((_a = configurationConsommable === null || configurationConsommable === void 0 ? void 0 : configurationConsommable.typePrestation) === null || _a === void 0 ? void 0 : _a.id) ===
            (filtreConfiguration === null || filtreConfiguration === void 0 ? void 0 : filtreConfiguration.idTypePrestation));
    });
    const configurationsConsommable = filtreConfiguration
        ? configurationsConsommableFiltered
        : configurationsConsommableAPI;
    return (_jsxs(_Fragment, { children: [_jsx(ConfigurationConsommablesListHeader, { visiteID: visiteID, configurationsConsommable: configurationsConsommableAPI, setFiltreConfiguration: setFiltreConfiguration, SelectionConsommable: SelectionConsommable }, void 0), _jsx(WrapperList, { children: configurationsConsommable.map((configurationConsommable) => (_jsx(ConfigConsoItem, { visiteID: visiteID, configurationConsommable: configurationConsommable, configurationsConsommable: configurationsConsommable, setConfigurationsConsommable: setConfigurationsConsommable }, configurationConsommable.configCsoVisiteID +
                    configurationConsommable.produit.IDProduit))) }, void 0)] }, void 0));
};
