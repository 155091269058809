import { AdresseModel } from "@ads/isadom-model";
import { PrimaryButton } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store";
import styled from "styled-components";
import FormAdresseNumber from "../molecules/FormAdresseNumber";
import FormAdresseVoie from "../molecules/FormAdresseVoie";
import FormCodePostal from "../molecules/FormCodePostal";
import FormLibelleAdresse from "../molecules/FormLibelleAdresse";
import FormNomDeVoie from "../molecules/FormNomDeVoie";
import FormTelephone from "../molecules/FormTelephone";
import FormVille from "../molecules/FormVille";

const WrapperForm = styled.form`
  padding: 1rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.neutral[110]};
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
`;

const WrapperAdresse = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const WrapperLocalite = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
  }
  ${({ theme }) => theme.media.md} {
    flex-direction: column;
  }
  ${({ theme }) => theme.media.xxl} {
    flex-direction: row;
  }
`;

interface FormSaisieAdresseProps {
  onFormAdresseSubmit: () => void;
}

const FormulaireSaisieAdresse = ({
  onFormAdresseSubmit
}: FormSaisieAdresseProps) => {
  const { setAdressesOptions, adresses, setAdresse } =
    useFormulaireCommandesStore();

  const handleSaveAdresse = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const adresseSaisie: AdresseModel = {
      adresseID: -1,
      typeLocalisation: {
        typeLocalisationID: "PAT",
        libelle: "",
        interne: false
      },
      adresseComplete: "",
      adresseSansCPVille: "",
      adresseAvecRC: "",
      codePostal: "31130",
      ville: "Balma",
      telFixe: "05 62 57 08 08",
      telAutre: "",
      telInformation: "",
      libelle: "Adresse livraison",
      numeroVoie: "12",
      adresse: "Charles de Gaulle",
      dateDebut: "",
      dateFin: "",
      enCours: true,
      adressePrincipale: false
    };

    if (adresses.findIndex((a: AdresseModel) => a.adresseID === -1) === -1) {
      setAdressesOptions([adresseSaisie, ...adresses]);
    } else {
      setAdressesOptions(
        adresses.map((a: AdresseModel) =>
          a.adresseID === -1 ? adresseSaisie : a
        )
      );
    }
    setAdresse(adresseSaisie);

    onFormAdresseSubmit();
  };

  return (
    <WrapperForm onSubmit={handleSaveAdresse}>
      <FormLibelleAdresse />
      <WrapperAdresse>
        <FormAdresseNumber />
        <FormAdresseVoie />
      </WrapperAdresse>
      <FormNomDeVoie />
      <WrapperLocalite>
        <FormCodePostal />
        <FormVille />
      </WrapperLocalite>
      <FormTelephone />
      <PrimaryButton
        as="button"
        buttonStyle="primary"
        label="Utiliser cette adresse"
        size="S"
        type="submit"
      />
    </WrapperForm>
  );
};

export default FormulaireSaisieAdresse;
