import { Card, DatePicker } from "antd";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  Breadcrumb,
  ButtonCircle,
  Checkbox,
  FormItemWrapper,
  FormWidget,
  Label,
  Textarea
} from "@ads/ui";
import { VisiteModel } from "@ads/isadom-model";
import locale from "antd/es/date-picker/locale/fr_FR";
import { TimePicker as TimePickerANTD } from "antd";
import { useEnregistreVisite } from "modules/fin-de-visite/hook/useEnregistreVisite";
import { useFinVisite } from "modules/fin-de-visite/hook/useFinVisite";
import { BaseError, ERROR_UNKNOWN } from "@ads/react-common-module";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface TemplateEnregistrementProps {
  visite: VisiteModel;
}
interface FormEnregistreVisite {
  finVisite: any;
  synthese: string;
}

export const TemplateEnregistrement = ({
  visite
}: TemplateEnregistrementProps) => {
  const breadcrumbItems = [
    {
      item: `Fin de visite`,
      to: `/visites/${visite.ID}/fin-de-visite`
    },
    {
      item: `Enregistrement`,
      to: `/visites/${visite.ID}/fin-de-visite/enregistrement`
    }
  ];

  const { enregistreVisite, loading } = useEnregistreVisite();

  const finVisiteService = useFinVisite({ visite });

  const onSubmit = async () => {
    if (finVisiteService.visite) {
      enregistreVisite({
        ...finVisiteService.visite
      });
    } else {
      throw new BaseError(ERROR_UNKNOWN);
    }
  };

  const methods = useForm<FormEnregistreVisite>();

  return (
    <>
      <Breadcrumb size="M" breadcrumbItems={breadcrumbItems} />

      <FormWidget
        handleSave={methods.handleSubmit(onSubmit)}
        isLoadingSave={loading}
      >
        <Card>
          <Label selectMode="dark" htmlFor="dFin">
            Date et heure de fin de visite
          </Label>
          <FormItemWrapper direction="horizontal">
            <DatePicker
              value={moment(finVisiteService.visite?.dFin, "YYYY-MM-DD")}
              format="DD/MM/YYYY"
              locale={locale}
              onChange={(value) => {
                if (value) {
                  finVisiteService.notifyDateFinChange(value?.toDate());
                }
              }}
              disabled={loading}
              placeholder="Date"
            />
            <TimePickerANTD
              value={moment(finVisiteService.visite?.hFin, "HH:mm:00")}
              placeholder="Heure"
              format={"HH:mm:00"}
              disabled={loading}
              onChange={(value) => {
                if (value) {
                  finVisiteService.notifyHeureFinChange(value?.toDate());
                }
              }}
            />
            <ButtonCircle
              backgroundColorButton="#ED5B2E"
              iconColor="#FFF"
              size="4rem"
              icon="chrono"
              disabled={loading}
              onClick={() => {
                const currentDate = moment().toDate();
                finVisiteService.notifyDateHeureFinChange(currentDate);
              }}
            />
          </FormItemWrapper>

          <Checkbox
            libelle="Valider la visite"
            checked={finVisiteService.visite?.bValide}
            onChange={(value: CheckboxChangeEvent) => {
              finVisiteService.notifyValidateVisite(value.target.checked);
            }}
          />

          <br />

          <>
            <Label selectMode="dark" htmlFor="synthese">
              Synthèse de la visite :
            </Label>

            <Textarea
              id="synthèseFinVisite"
              name="synthèseFinVisite"
              value={finVisiteService.visite?.sInformation}
              rows={4}
              disabled={loading}
              onChange={(value: any) => {
                finVisiteService.notifySyntheseChange(value.target.value);
              }}
            />
          </>
        </Card>
      </FormWidget>
    </>
  );
};
