import { LigneCommandeVisiteModel } from "@ads/isadom-model";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const FamilleLabel = styled.div`
  font-weight: 600;
`;

const ProductLabel = styled.div``;

interface CommandeItemHeaderProps {
  item: LigneCommandeVisiteModel;
}

const CommandeItemHeader = ({ item }: CommandeItemHeaderProps) => (
  <Wrapper>
    <FamilleLabel>{item.famille?.libelle}</FamilleLabel>
    <ProductLabel>{`${item.produit?.libelle} (${item.produit?.refProduit})`}</ProductLabel>
  </Wrapper>
);

export default CommandeItemHeader;
