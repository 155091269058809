import { IConstanteModel } from "@ads/isadom-model";
import { Input } from "@ads/ui";
import { Card } from "antd";
import { useEffect, useState } from "react";
import { ConstanteListProps } from "./";

export function ConstanteRespiratoireList(props: ConstanteListProps) {
  function handleConstanteChange(code: string, value: string) {
    if (props.data && props.callbacks.onChange) {
      for (const constante of props.data) {
        if (constante.code === code) {
          const newConstante = constante;
          newConstante.valeur = value;
          props.callbacks.onChange(newConstante);
        }
      }
    }
  }

  const AARepCode = ["SatAARep", "DebAARep", "PAARep"];
  const AAEffCode = ["SatAAEff", "DebAAEff", "PAAEff"];
  const O2RepCode = ["SatO2Rep", "DebO2Rep", "PO2Rep"];
  const O2EffCode = ["SatO2Eff", "DebO2Eff", "PO2Eff"];
  const VACode = ["SatVA", "DebVA", "PVA"];

  return (
    <Card>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>SaO2</th>
            <th>Débit O2</th>
            <th>Pouls</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Air ambiant repos</th>
            {AARepCode.map((code) => (
              <Td
                code={code}
                data={props.data}
                handleConstanteChange={handleConstanteChange}
              />
            ))}
          </tr>

          <tr>
            <th>Air ambiant effort</th>
            {AAEffCode.map((code) => (
              <Td
                code={code}
                data={props.data}
                handleConstanteChange={handleConstanteChange}
              />
            ))}
          </tr>

          <tr>
            <th>Sous O2 repos</th>
            {O2RepCode.map((code) => (
              <Td
                code={code}
                data={props.data}
                handleConstanteChange={handleConstanteChange}
              />
            ))}
          </tr>

          <tr>
            <th>Sous O2 effort</th>
            {O2EffCode.map((code) => (
              <Td
                code={code}
                data={props.data}
                handleConstanteChange={handleConstanteChange}
              />
            ))}
          </tr>

          <tr>
            <th>Sous VA</th>
            {VACode.map((code) => (
              <Td
                code={code}
                data={props.data}
                handleConstanteChange={handleConstanteChange}
              />
            ))}
          </tr>
        </tbody>
      </table>
    </Card>
  );
}

interface TdProps {
  code: string;
  data: IConstanteModel[] | null;
  handleConstanteChange(code: string, value: string): void;
}

function Td(props: TdProps) {
  const [constante, setConstante] = useState<IConstanteModel | undefined>(
    props.data
      ? props.data.find((value) => value.code === props.code)
      : undefined
  );

  useEffect(() => {
    if (constante)
      props.handleConstanteChange(constante.code, constante.valeur);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constante]);

  /*FBR règle métier. Le champ ne peut pas croiser les valeur de air ambiant et de débit o2*/
  function disableImpossibleField(): boolean {
    return constante
      ? ["DebAARep", "DebAAEff"].indexOf(constante?.code) !== -1
      : true;
  }

  return (
    <td>
      <Input
        name={props.code}
        selectMode="light"
        type="text"
        value={constante?.valeur}
        defaultValue={constante?.valeur}
        disabled={disableImpossibleField()}
        onChange={(e) => {
          if (constante) {
            setConstante({
              ...constante,
              valeur: e.target.value,
            });
          }
        }}
      />
    </td>
  );
}
