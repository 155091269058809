var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
var ConsommableConsommationDTOAdapter = /** @class */ (function (_super) {
    __extends(ConsommableConsommationDTOAdapter, _super);
    function ConsommableConsommationDTOAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConsommableConsommationDTOAdapter.prototype.toClient = function (src) {
        return {
            produitID: src.produitID,
            refProduit: src.sRefProduit,
            libelle: src.sLibelle,
            date: src.dMouvement,
            quantite: src.iQuantite,
        };
    };
    ConsommableConsommationDTOAdapter.prototype.toAPI = function (src) {
        return {
            produitID: src.produitID,
            sRefProduit: src.refProduit,
            sLibelle: src.libelle,
            dMouvement: src.date,
            iQuantite: src.quantite,
        };
    };
    return ConsommableConsommationDTOAdapter;
}(Adapter));
export { ConsommableConsommationDTOAdapter };
