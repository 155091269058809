import { FC, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import moment from "moment";
import { PrimaryButton } from "@ads/ui";
import { ReglageValeurHoraireAPI } from "@ads/isadom-model";
import HoraireItem from "../molecules/HoraireItem";
import { Horaire } from "modules/appareils/interfaces/appareils.interfaces";

const format = "HH:mm";

const WrapperButton = styled("div")`
  margin-top: 2.4rem;
`;

const Grid = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 1rem;

  .head {
    font-weight: bold;
  }

  .line {
    display: flex;
    justify-content: space-between;
  }

  .line-item {
    width: 33%;
  }
`;

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  reglagesHoraires: ReglageValeurHoraireAPI[] | undefined;
  handleHoraire: (
    value: string,
    reglagesValeursHoraires: ReglageValeurHoraireAPI[]
  ) => void;
}

const ModalHoraires: FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  handleHoraire,
  reglagesHoraires,
}: Props) => {
  const horairesInit: Horaire[] | undefined = reglagesHoraires?.map(
    (reglageHoraire) => {
      const horaire: Horaire = {
        heureDebut: reglageHoraire.tDebut,
        heureFin: reglageHoraire.tFin,
        value: reglageHoraire.sValeur,
      };
      return horaire;
    }
  );

  const [horaires, setHoraires] = useState<Horaire[]>(horairesInit || []);

  const handleClick = () => {
    setHoraires([
      ...horaires,
      {
        heureDebut: "",
        heureFin: "",
        value: "",
      },
    ]);
  };

  const handleOk = () => {
    horaires.forEach((horaire) => {
      if (!horaire) return;
    });

    // On fait la somme de toutes les valeurs du tableau
    const reducer = (previousValue: number, horaire: Horaire) => {
      const debut = moment(horaire.heureDebut, format);
      const fin = moment(horaire.heureFin, format);

      let difference = 0;

      // Si une tranche horaire va de minuit on ne peut pas calculer la différence,, on la calcul donc en dur
      if (
        horaire.heureDebut === "00:00:00.000" &&
        horaire.heureFin === "00:00:00.000"
      ) {
        difference = 1440;
      } else {
        difference = fin.diff(debut, "minute");
      }

      // Si l'heure de fin est égale à minuit le retour est négatif
      const diffPositive = difference < 0 ? difference * -1 : difference;

      if (horaire.value) {
        return previousValue + (diffPositive * +horaire.value) / 60;
      }

      return previousValue;
    };
    // valeur du champ et de reglage valeur
    const inputValue = horaires.reduce(reducer, 0).toFixed(2);

    // valeur de reglagevaleur horaire
    const reglagesValeursHoraires = horaires.map((horaire) => {
      const reglageValeurHoraire: ReglageValeurHoraireAPI = {
        iFKNautReglage: 0,
        iPKNautReglageGrilleHoraire: 0,
        sCodereglage: "",
        tDebut: horaire.heureDebut,
        tFin: horaire.heureFin,
        sValeur: horaire.value as unknown as string,
      };
      return reglageValeurHoraire;
    });

    handleHoraire(inputValue.toString(), reglagesValeursHoraires);
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Saisie de plages horaires"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={() => setIsModalVisible(false)}
    >
      <Grid>
        <div className="head line">
          <div className="line-item">De</div>
          <div className="line-item">À</div>
          <div className="line-item">Valeur</div>
        </div>

        {horaires.map((horaire, index) => (
          <HoraireItem
            key={horaire.heureDebut + index}
            horaire={horaire}
            index={index}
            horaires={horaires}
            setHoraires={setHoraires}
          />
        ))}
      </Grid>
      <WrapperButton>
        <PrimaryButton
          as="button"
          type="button"
          buttonStyle="secondary"
          size="S"
          label="Ajouter une plage"
          iconPosition="left"
          onClick={handleClick}
        />
      </WrapperButton>
    </Modal>
  );
};

export default ModalHoraires;
