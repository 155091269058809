import styled from "styled-components";
import useHeaderAppareil from "modules/appareils/hooks/useHeaderAppareil";
import BreadcrumbAppareil from "../organisms/BreadcrumbAppareil";
import Retrait from "components/ui/organisms/navigation-appareil/Retrait";
import { useParams } from "react-router-dom";
import { RouteParam } from "interface/core/RouteParam";
import ErrorAppareil from "../molecules/ErrorAppareil";
import { WidgetWrapper } from "@ads/ui";
import { useGetAppareilConfigurationSpecTiersFromAPI } from "api/appareil";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 0.8rem;
  padding: 2rem;
  box-shadow: 1px -0.4rem 1.2rem rgb(80 70 70 / 21%);
`;


const TemplateRetrait = () => {
	const { iPKVisite, IDProduit } = useParams<RouteParam>();

	const { data: configAppareil, error } =
		useGetAppareilConfigurationSpecTiersFromAPI({
			visiteID: iPKVisite,
			appareilID: IDProduit,
		});

	const headerAppareil = useHeaderAppareil(
		iPKVisite,
		configAppareil?.produit,
		IDProduit,
		true
	);

	if (error) {
		return <ErrorAppareil />;
	}

	return (
		<>
			<BreadcrumbAppareil breadcrumbItems={headerAppareil.breadcrumbItems} />
			<WidgetWrapper>
				<Wrapper>
					<Retrait />
				</Wrapper>
			</WidgetWrapper>
		</>
	);
};

export default TemplateRetrait;
