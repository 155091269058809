import React from "react";
export const tableSelectionAppareilColumns = [
    {
        title: "Modèle/ Référence/ N° de série",
        render: (record) => (React.createElement(React.Fragment, null,
            record.libelleModele,
            React.createElement("br", null),
            record.referenceModele,
            React.createElement("br", null),
            record.numeroSerie)),
        responsive: ["xs"],
    },
    {
        title: "Modèle",
        dataIndex: "libelleModele",
        key: "libelleModele",
        getValue: (item) => {
            return `${item.libelleModele}`;
        },
        responsive: ["sm"],
    },
    {
        title: "Référence",
        dataIndex: "referenceModele",
        key: "referenceModele",
        getValue: (item) => {
            return `${item.referenceModele}`;
        },
        responsive: ["sm"],
    },
    {
        title: "N° de série",
        dataIndex: "numeroSerie",
        key: "numeroSerie",
        getValue: (item) => {
            return `${item.numeroSerie}`;
        },
        responsive: ["sm"],
    },
    {
        title: "Localisation actuelle/ Disponible",
        render: (record) => (React.createElement(React.Fragment, null,
            record.libelleLocalisation,
            React.createElement("br", null),
            record.materielDisponible)),
        responsive: ["xs"],
    },
    {
        title: "Localisation actuelle",
        dataIndex: "libelleLocalisation",
        key: "libelleLocalisation",
        getValue: (item) => {
            return `${item.libelleLocalisation}`;
        },
        responsive: ["sm"],
    },
    {
        title: "Disponible",
        dataIndex: "materielDisponible",
        key: "materielDisponible",
        getValue: (item) => {
            return item.materielDisponible ? "Oui" : "Non";
        },
        responsive: ["sm"],
    },
];
