var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../";
var TypeTacheVisiteAdapter = /** @class */ (function (_super) {
    __extends(TypeTacheVisiteAdapter, _super);
    function TypeTacheVisiteAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TypeTacheVisiteAdapter.prototype.toClient = function (src) {
        return {
            id: src.typeTacheID,
            information: src.sInformation,
            isCrGenerationAuto: src.bCrGenerationAuto,
            isInactif: src.bInactif,
            isInformatif: src.bInformatif,
            isReserve: src.bReserver,
            isValidation: src.bValidation,
            libelle: src.sLibelle,
            ordre: src.iOrdre,
            reference: src.sReference
        };
    };
    TypeTacheVisiteAdapter.prototype.toAPI = function (src) {
        return {
            typeTacheID: src.id,
            sLibelle: src.libelle,
            sReference: src.reference,
            bReserver: src.isReserve,
            bInactif: src.isInactif,
            bInformatif: src.isInformatif,
            bValidation: src.isValidation,
            sInformation: src.information,
            iOrdre: src.ordre,
            bCrGenerationAuto: src.isCrGenerationAuto,
        };
    };
    return TypeTacheVisiteAdapter;
}(Adapter));
export { TypeTacheVisiteAdapter };
