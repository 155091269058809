import {
  AdresseAdapter,
  AdresseAPI,
  AdresseModel,
  HttpResponseAPI,
  HttpResponseModel,
} from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiGetAdressesTiers(
  tiersID: number
): Promise<HttpResponseModel<AdresseModel[]>> {
  try {
    if (!tiersID) {
      throw new Error("L'identifiant de la visiteu tiers visité est manquant.");
    }

    const api = getDefaultApiInstance();

    if (!api) {
      throw new Error("La configuration d'api n'est pas initialisée");
    }

    const adapter = new AdresseAdapter();
    const { data, messages }: HttpResponseAPI<AdresseAPI[]> = await apiGetV2(
      api,
      `/tiers/${tiersID}/adresses/visite-livraison`,
      apiIsadomErrorStrategy
    );

    return { data: adapter.toClients(data), messages };
  } catch (err: any) {
    return new Promise((_resolve, reject) => {
      reject(err.message);
    });
  }
}
