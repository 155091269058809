var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDefaultApiInstance } from "@ads/react-common-module";
import { adsNotification } from "@ads/ui";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiDeleteBlocNoteVisite, apiPutBlocsNotes, getAllTypesNotesFromAPI, } from "../../..";
import { apiGetBlocNoteByVisiteID } from "../../../api/apiGetBlocNoteByVisiteID";
export function useBlocNoteForm(input) {
    const api = getDefaultApiInstance();
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [noteModel, setNoteModel] = useState();
    const [noteState, setNoteState] = useState({
        typeNoteID: "",
        text: "",
        isClosed: false,
    });
    const [typesNotes, setTypesNotes] = useState([]);
    const [error, setError] = useState(false);
    useEffect(() => {
        setError(false);
        // Récupération de tous les types de notes
        getAllTypesNotesFromAPI(api).then((response) => {
            setTypesNotes(response);
        });
        // Initialisation de la note à partir de l'id
        if (input.idVisite && input.idBlocNote !== "nouveau") {
            apiGetBlocNoteByVisiteID(api, input.idVisite).then((result) => {
                result.forEach((e) => {
                    if (e.idBlocNoteVisite.toString() === input.idBlocNote ||
                        e.id.toString() === input.idBlocNote) {
                        setNoteState({
                            typeNoteID: e.typeNote.id,
                            text: e.information,
                            isClosed: false,
                        });
                        setNoteModel(e);
                    }
                });
                setLoading(false);
            });
        }
        else {
            setLoading(false);
        }
    }, []);
    const saveNote = () => __awaiter(this, void 0, void 0, function* () {
        setError(false);
        if (noteState.text === "" && noteState.typeNoteID === "") {
            adsNotification.error({
                message: "Veuillez saisir une note et choisir un type de note",
            });
            return;
        }
        else if (noteState.text !== "" && noteState.typeNoteID === "") {
            adsNotification.error({ message: "Veuillez choisir un type de note" });
            return;
        }
        else if (noteState.text === "" && noteState.typeNoteID !== "") {
            adsNotification.error({ message: "Veuillez saisir une note" });
            return;
        }
        // Récupérer donnée du type de note
        typesNotes.forEach((e) => __awaiter(this, void 0, void 0, function* () {
            if (e.id === noteState.typeNoteID) {
                // Objet a envoyer en base :
                let notesToBeSaved = {
                    information: noteState.text,
                    masquer: noteState.isClosed,
                    typeNote: e,
                    id: (noteModel === null || noteModel === void 0 ? void 0 : noteModel.id) ? noteModel === null || noteModel === void 0 ? void 0 : noteModel.id : 0,
                    idBlocNoteVisite: (noteModel === null || noteModel === void 0 ? void 0 : noteModel.idBlocNoteVisite)
                        ? noteModel === null || noteModel === void 0 ? void 0 : noteModel.idBlocNoteVisite
                        : 0,
                };
                yield apiPutBlocsNotes(api, input.idVisite, notesToBeSaved).then(() => {
                    adsNotification.success({
                        message: "Modification sauvegardée avec succès",
                    });
                    history.push(`/visites/${input.idVisite}/bloc-notes/`);
                    return 0;
                });
            }
        }));
    });
    const deleteNote = () => {
        if (input.idBlocNote === "nouveau") {
            adsNotification.success({
                message: "Suppression avec succès",
            });
            history.push(`/visites/${input.idVisite}/bloc-notes/`);
        }
        if (input.idVisite && input.idBlocNote)
            apiDeleteBlocNoteVisite(api, input.idVisite, noteModel).then(() => {
                adsNotification.success({
                    message: "Suppression avec succès",
                });
                history.push(`/visites/${input.idVisite}/bloc-notes/`);
            });
    };
    return {
        note: noteState,
        noteModel: noteModel,
        isLoading: isLoading,
        typeNotes: typesNotes,
        setNote: setNoteState,
        saveNote: saveNote,
        deleteNote: deleteNote,
        idVisite: input.idVisite,
        isDeletable: input.isDeletable,
        error: error,
    };
}
