var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TypeDocumentAdapter } from "./TypeDocumentAdapter";
import { Adapter } from "../..";
var DocumentVisiteAdapter = /** @class */ (function (_super) {
    __extends(DocumentVisiteAdapter, _super);
    function DocumentVisiteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeDocumentAdapter = new TypeDocumentAdapter();
        return _this;
    }
    DocumentVisiteAdapter.prototype.toClient = function (src) {
        return {
            id: src.documentID,
            typeDocument: this.typeDocumentAdapter.toClient(src.typeDocument),
            URL: src.sURL,
            isDocumentExist: src.documentExist,
            visiteID: src.documentVisiteID,
        };
    };
    DocumentVisiteAdapter.prototype.toAPI = function (src) {
        return {
            documentID: src.id,
            typeDocument: this.typeDocumentAdapter.toAPI(src.typeDocument),
            sURL: src.URL,
            documentExist: src.isDocumentExist,
            documentVisiteID: src.visiteID ? src.visiteID : 0,
        };
    };
    return DocumentVisiteAdapter;
}(Adapter));
export { DocumentVisiteAdapter };
