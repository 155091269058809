import styled from "styled-components";

const Div = styled.div<DivProps>`
  height: ${(props) => props.size}rem;
  background-color: ${({ theme }) => theme.colors.neutral[110]};
`;

export interface DivProps {
  size?: number;
}

export function PaddingDiv({ size }: DivProps) {
  return <Div size={size} className="div-padding-top" />;
}
