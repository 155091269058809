import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/redux";
import { setCookie } from "utils/cookies";
import { triChronoASC } from "../../../utils";
import {
  HttpMessageModel,
  VisiteDTOAPI,
} from "@ads/isadom-model";
import type { ItemStoreTournee } from "../../../../../interface/store/ItemStoreTournee";
import { TypeRendezVous } from "interface/typeRendezVous";
import { getTournee } from "../services/feuille-de-route.services";

interface SelectTypeRdvPayload {
  selection: TypeRendezVous;
}

interface SelectRdvPayload {
  selection: TypeRendezVous;
  selectedIndex: number;
}

const initialState: ItemStoreTournee = {
  dDate: undefined,
  iPKIntervenant: -1,
  selectedIndex: -1,
  selection: "a-faire",
  tRdvAFaire: [],
  tVisiteEnCours: [],
  tVisiteTerminee: [],
  isLoading: false,
  hasError: null,
  visiteErrorMessagesMap: {},
};

interface UpdateErrorMessageVisite {
  visiteID: number;
  messages: HttpMessageModel[];
}

export const tourneeSlice = createSlice({
  name: "tournee",
  initialState,
  reducers: {
    setErrorMessageToVisite: (
      state: ItemStoreTournee,
      {
        payload: { visiteID, messages },
      }: PayloadAction<UpdateErrorMessageVisite>
    ) => {
      if (!state.visiteErrorMessagesMap) {
        state.visiteErrorMessagesMap = {};
      }
      state.visiteErrorMessagesMap[visiteID] = messages;
    },
    selectTypeRdv: (
      state: ItemStoreTournee,
      { payload: { selection } }: PayloadAction<SelectTypeRdvPayload>
    ) => {
      state.selection = selection;
    },
    selectRdv: (
      state: ItemStoreTournee,
      { payload }: PayloadAction<SelectRdvPayload>
    ) => {
      const { selection, selectedIndex } = payload;

      state.selectedIndex = selectedIndex;
      state.selection = selection;
    },
    resetTournee: () => {
      return initialState;
    },
    deleteRdvFromTournee: (state: ItemStoreTournee, { payload }: PayloadAction<number>) => {
      state.tRdvAFaire = state.tRdvAFaire.filter((rdv) => rdv.rendezvousID !== payload);
    },
    addVisiteToVisiteEncour: (state: ItemStoreTournee, { payload }: PayloadAction<VisiteDTOAPI>) => {
      state.tVisiteEnCours.push(payload);
    },
    deleteVisiteEnCours: (
      state: ItemStoreTournee,
      { payload: idVisite }: PayloadAction<number>
    ) => {
      state.tVisiteEnCours = state.tVisiteEnCours.filter(
        (visiteEnCours) => visiteEnCours.visiteID !== idVisite
      );
    },
  },

  extraReducers: (builder: any) => {
    builder.addCase(getTournee.pending, (state: ItemStoreTournee) => {
      state.isLoading = true;
      state.hasError = null;
    });
    builder.addCase(
      getTournee.fulfilled,
      (state: ItemStoreTournee, action: any) => {
        const {
          dDate,
          iPKIntervenant,
          tRdvAFaire,
          tVisiteEnCours,
          tVisiteTermine,
          selection,
          visiteErrorMessagesMap,
        } = action.payload;
        triChronoASC(tRdvAFaire);
        triChronoASC(tVisiteEnCours);
        triChronoASC(tVisiteTermine);

        state.dDate = dDate;
        state.iPKIntervenant = parseInt(iPKIntervenant, 10);
        state.selection = selection;
        state.selectedIndex = -1;
        state.tRdvAFaire = tRdvAFaire;
        state.tVisiteEnCours = tVisiteEnCours;
        state.tVisiteTerminee = tVisiteTermine;
        state.isLoading = false;
        state.hasError = null;
        state.visiteErrorMessagesMap = visiteErrorMessagesMap;

        setCookie(
          "tournee",
          JSON.stringify({ dDate, iPKIntervenant, selection }),
          1
        );
      }
    );
    builder.addCase(
      getTournee.rejected,
      (state: ItemStoreTournee, action: any) => {
        state.dDate = undefined;
        state.iPKIntervenant = -1;
        state.selectedIndex = -1;
        state.selection = "a-faire";
        state.tRdvAFaire = [];
        state.tVisiteEnCours = [];
        state.tVisiteTerminee = [];
        state.isLoading = false;
        state.hasError = action.payload;
      }
    );
  },
});

export function selectErrorMessageByVisiteIDFromStore(
  state: RootState,
  visiteID: number
) {
  if (!state.tournee.visiteErrorMessagesMap) {
    return undefined;
  }
  return state.tournee.visiteErrorMessagesMap[visiteID];
}

export const {
  selectTypeRdv,
  selectRdv,
  resetTournee,
  deleteVisiteEnCours,
  deleteRdvFromTournee,
  setErrorMessageToVisite,
  addVisiteToVisiteEncour,
} = tourneeSlice.actions;

export default tourneeSlice.reducer;
