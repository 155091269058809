var TypeProduitBuilder = /** @class */ (function () {
    function TypeProduitBuilder() {
    }
    TypeProduitBuilder.prototype.build = function () {
        return {
            typeProduitId: this.typeProduitId,
            libelle: this.libelle,
            codeNatureProduit: this.codeNatureProduit,
            suiviTiers: this.suiviTiers,
            suiviIntervention: this.suiviIntervention,
            inactif: this.inactif,
        };
    };
    TypeProduitBuilder.prototype.setTypeProduitId = function (typeproduitID) {
        this.typeProduitId = typeproduitID;
        return this;
    };
    return TypeProduitBuilder;
}());
export { TypeProduitBuilder };
