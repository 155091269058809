import { createAsyncThunk } from "@reduxjs/toolkit";
import { verify as verifyJwt } from "jsonwebtoken";
// import axios from "axios";

import type { AuthPayload, Params } from "../../../interface/utilisateur";
import { setCookie, deleteCookie, getCookie } from "utils/cookies";

import config from "../../../config";
import axios from "axios";

export const connexion = createAsyncThunk(
  "utilisateur/connexion",
  async (token: string) => {
    const response = await new Promise((resolve, reject) => {
      if (token) {
        try {
          const identity = verifyJwt(token, config.jwtPUBLIC);
          setCookie(config.localStorageIdentityKey, token, 0.5);
          resolve({
            token,
            identity
          });
        } catch {
          reject(null);
        }
      } else {
        reject(null);
      }
    });

    return response as AuthPayload;
  }
);

export const reconnexion = createAsyncThunk(
  "utilisateur/reconnexion",
  async () => {
    const response = await new Promise((resolve, reject) => {
      const token = getCookie(config.localStorageIdentityKey);

      if (token) {
        try {
          const identity = verifyJwt(token, config.jwtPUBLIC);
          resolve({
            token,
            identity
          });
        } catch {
          deleteCookie(config.localStorageIdentityKey);
          reject(null);
        }
      } else {
        deleteCookie(config.localStorageIdentityKey);
        reject(null);
      }
    });

    return response as AuthPayload;
  }
);

export const deconnexion = createAsyncThunk(
  "utilisateur/deconnexion",
  async () => {
    const response = await new Promise((resolve, reject) => {
      try {
        deleteCookie(config.localStorageIdentityKey);
        resolve(true);
      } catch {
        reject(false);
      }
    });

    return response;
  }
);

export const chargeParams = createAsyncThunk(
  "utilisateur/chargeParams",
  async (iPKUtilisateur: number | undefined) => {
    // eslint-disable-next-line no-async-promise-executor
    const response = await new Promise(async (resolve, reject) => {
      if (iPKUtilisateur) {
        try {
          const data = await axios.get(
            `${config.apiURL}intervenants/${iPKUtilisateur}`,
            { withCredentials: false }
          );
          resolve(data.data);
        } catch {
          reject(null);
        }
      } else {
        reject(null);
      }
    });

    return response as Params;
  }
);
