import { IConstanteModel } from "@ads/isadom-model";
import { useEffect, useState } from "react";
import {
  IConstanteListHookInput,
  IConstanteListHookResult,
} from "./interfaces";

export function useConstanteList(
  input: IConstanteListHookInput
): IConstanteListHookResult {
  const [readLoding, setReadLoading] = useState(false);
  const [writeLoding] = useState(false);
  const [constantes, setConstantes] = useState<IConstanteModel[] | null>(null);

  useEffect(() => {
    setReadLoading(true);
    input.data
      .then((data: IConstanteModel[]) => {
        setConstantes(data);
      })
      .finally(() => {
        setReadLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onConstanteChange(
    constante: IConstanteModel
  ): Promise<IConstanteModel> {
    if (constantes === null) {
      throw new Error("Le tableau de constante est null.");
    }
    if (constantes.length === 0) {
      throw new Error("Le tableau de constante est vide.");
    }
    const index = constantes.findIndex(
      (element) => element.parametrageID === constante.parametrageID
    );
    if (index === -1) {
      throw new Error("La constante n'existe pas dans le tableau.");
    }

    if (input.callbacks?.onConstanteChange) {
      constante = await input.callbacks.onConstanteChange(constante);
    }
    const updatingConstante = constantes;
    updatingConstante[index] = constante;
    setConstantes(updatingConstante);
    return constante;
  }

  async function updateConstantes(
    constantes: IConstanteModel[] | null
  ): Promise<IConstanteModel[] | null> {
    if (constantes) {
      constantes = await input.features.updateConstantes(constantes);
      setConstantes(constantes ? [...constantes] : null);
      return constantes;
    } else {
      setConstantes(null);
      return null;
    }
  }

  return {
    data: constantes,
    actions: {
      notifyConstanteChange: onConstanteChange,
    },
    features: {
      updateConstantes: updateConstantes,
    },
    loadings: {
      readLoading: readLoding,
      writeLoading: writeLoding,
    },
  };
}
