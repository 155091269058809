import { BlocNoteModel, BlocNoteVisiteModel } from "@ads/isadom-model";
import {
  BaseBlocNoteListDataNotifier,
  BlocNoteItemAdapter,
  IBaseBlocNoteListDataListener,
  IBlocNoteListFilterHookService,
  ListBlocNoteFilter
} from "@ads/module-blocnote";
import { BaseError, ERROR_UNKNOWN } from "@ads/react-common-module";
import { Loading, Breadcrumb, IListFilterHookListener } from "@ads/ui";
import { Alert } from "antd";
import { HeaderList } from "components/core/ui/headerList/ui/HeaderListNomad";
import { useHistory } from "react-router-dom";
import { BlocNoteList } from "./BlocNoteList";

interface BlocNoteTemplateListProps<
  BlocNoteModelModel extends BlocNoteModel,
  ListFilterHookListener extends IListFilterHookListener<
    BlocNoteModelModel,
    ListBlocNoteFilter
  >
> {
  visiteID: number;
  service: IBlocNoteListFilterHookService<
    BlocNoteModelModel,
    ListFilterHookListener,
    ListBlocNoteFilter
  >;
}

export function BlocNoteTemplateListHeader<
  ListFilterHookListener extends IListFilterHookListener<
    BlocNoteVisiteModel,
    ListBlocNoteFilter
  >
>(
  props: BlocNoteTemplateListProps<BlocNoteVisiteModel, ListFilterHookListener>
) {
  const history = useHistory();

  const redirect = (noteId: string) => {
    history.push(`/visites/${props.visiteID}/bloc-notes/${noteId}`);
  };

  //Ajout d'un filtre
  // Fonction
  const makeFilterTypeList = (array: any[]) => {
    const filterListItems: { label: string; value: string }[] = [];
    array.forEach((e: { typeNote: { libelle: string } }) => {
      if (!filterListItems.find((item) => item.label === e.typeNote.libelle)) {
        filterListItems.push({
          label: e.typeNote.libelle,
          value: e.typeNote.libelle
        });
      }
    });
    return filterListItems;
  };

  const handleChangeID = (value: string) => {
    if (value) {
      props.service.setFilter({
        libelleTypeBlocNote: value
      } as unknown as ListBlocNoteFilter);
    } else {
      props.service.setFilter({
        libelleTypeBlocNote: ""
      } as unknown as ListBlocNoteFilter);
    }
  };

  if (props.service.isLoading) {
    return <Loading>Chargement en cours de la liste des notes</Loading>;
  }

  if (props.service.error) {
    return (
      <Alert
        message="Erreur"
        showIcon
        description={
          "Une erreur est survenue pendant le chargement de la liste des notes"
        }
        type="error"
      />
    );
  }

  const data = [
    {
      item: "Bloc-notes",
      to: ""
    }
  ];

  return (
    <>
      <Breadcrumb size="M" breadcrumbItems={data} />
      <HeaderList
        handleAddClick={() => redirect("nouveau")}
        nomModuleSingulier={"Note"}
        nomModulePluriel={"Notes"}
        lenghtList={props.service.dataFiltered.length}
        filtersList={[
          {
            libelle: "Filtrer par type de note",
            items: makeFilterTypeList(props.service.data),
            handleChange: handleChangeID
          }
        ]}
      />
      <BlocNoteList<BlocNoteVisiteModel>
        data={props.service.dataFiltered}
        adapter={BlocNoteItemAdapter}
        notifier={new BaseBlocNoteListDataNotifier()}
        listener={
          {
            idListener: "listenerBN",
            onItemClick(item: BlocNoteVisiteModel) {
              if (item.idBlocNoteVisite) {
                redirect(item.idBlocNoteVisite.toString());
              } else if (item.id) {
                redirect(item.id.toString());
              } else {
                throw new BaseError(
                  ERROR_UNKNOWN,
                  "Impossible de rediriger vers le détails d'une note."
                );
              }
            },
            onDeleteItemClick(item: BlocNoteVisiteModel) {
              props.service.deleteItem(item);
            }
          } as IBaseBlocNoteListDataListener
        }
        isDeletable={props.service.isDeletable}
      />
    </>
  );
}
