import { useEffect, useState } from "react";
import { calculateScore } from "../../../";
import { deepCopy } from "@ads/react-common-module";
export function useQuestionnaireDetailAutoFetch(props) {
    /****************************************/
    /**************LOCAL STATE***************/
    /****************************************/
    const [questionnaire, setQuestionnaire] = useState();
    /****************************************/
    /***************LOCAL VAR****************/
    /****************************************/
    const { disableActions } = props;
    const initialApiResult = props.dataFetchFunction(props.fetchParams);
    /****************************************/
    /**************INTERNAl FUNCION**********/
    /****************************************/
    const onUpdate = (questionnaire) => {
        questionnaire.score = calculateScore(questionnaire);
        setQuestionnaire(Object.assign({}, questionnaire));
    };
    const upload = () => {
        return new Promise((resolve, reject) => {
            if (props.performUpdate && questionnaire) {
                props
                    .performUpdate(questionnaire)
                    .then(() => {
                    resolve();
                })
                    .catch((error) => {
                    reject(error);
                });
            }
        });
    };
    const deleteQuestionnaire = () => {
        return new Promise((resolve, reject) => {
            if (props.performDelete && questionnaire) {
                props
                    .performDelete(questionnaire.questionnaireID)
                    .then(() => {
                    resolve();
                })
                    .catch((error) => {
                    reject(error);
                });
            }
        });
    };
    /****************************************/
    /*****************EFFECT*****************/
    /****************************************/
    useEffect(() => {
        if (!initialApiResult.isLoading && initialApiResult.data) {
            setQuestionnaire(deepCopy(initialApiResult.data));
        }
    }, [initialApiResult.isLoading]);
    if (props.performDelete) {
        return {
            questionnaire: questionnaire,
            disableActions: disableActions,
            questionnaireLoading: initialApiResult.isLoading,
            fetchError: initialApiResult.error,
            onQuestionnaireChange: onUpdate,
            performUpdate: upload,
            performDelete: deleteQuestionnaire,
            cancelLocalChange: () => setQuestionnaire(initialApiResult.data),
        };
    }
    else {
        return {
            questionnaire: questionnaire,
            disableActions: disableActions,
            questionnaireLoading: initialApiResult.isLoading,
            fetchError: initialApiResult.error,
            onQuestionnaireChange: onUpdate,
            performUpdate: upload,
            cancelLocalChange: () => setQuestionnaire(initialApiResult.data),
        };
    }
}
