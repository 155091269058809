var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { AppareilAdapter } from "..";
// import { ProduitAdapter } from ".";
var ConfigProduitSpecAdapter = /** @class */ (function (_super) {
    __extends(ConfigProduitSpecAdapter, _super);
    function ConfigProduitSpecAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // private produitAdapter = new ProduitAdapter();
        _this.appareilAdapter = new AppareilAdapter();
        return _this;
    }
    ConfigProduitSpecAdapter.prototype.toClient = function (src) {
        if (!src) {
            return null;
        }
        return {
            produit: this.appareilAdapter.toClient(src.produit),
            sTypeConfig: src.sTypeConfig,
        };
    };
    ConfigProduitSpecAdapter.prototype.toAPI = function (src) {
        if (!src) {
            return null;
        }
        return {
            produit: this.appareilAdapter.toAPI(src.produit),
            sTypeConfig: src.sTypeConfig,
        };
    };
    return ConfigProduitSpecAdapter;
}(Adapter));
export { ConfigProduitSpecAdapter };
