import { IBaseParametreEditionListHookListener } from "./../IBaseParametreEditionListHookListener";
import { IParametreEditionModel } from "@ads/isadom-model";
import { IBaseParametreEditionListHookNotifier } from "./../IBaseParametreEditionListHookNotifier";
import { ListHookNotifier } from "@ads/ui";

/**
 * @description
 * Implémentation basique du notifier pour les liste de ParametreEdition
 * 
 */
export class BaseParametreEditionListHookNotifier<
BaseParametreEditionListHookListener extends IBaseParametreEditionListHookListener
> extends ListHookNotifier<IParametreEditionModel, BaseParametreEditionListHookListener>
implements IBaseParametreEditionListHookNotifier {
 
}