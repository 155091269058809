import { ModeReglageAPI } from "@ads/isadom-model";
import { ModesReglagesAppareil } from "../interfaces/modes-reglages.interfaces";

export interface ModeActif {
  indexModeSaisi: number;
  mode: ModeReglageAPI;
}

/**
 * @author Johan Petrikovsky
 * @date 22/04/2022
 * @desc Retourne le mode actif, c'est à dire celui sélectionné par l'utilisateur
 * @param modesReglagesAppareil
 * @returns index du mode saisi ainsi que le mode sous forme d'objet
 */
const getModeActif = (
  modesReglagesAppareil: ModesReglagesAppareil
): ModeActif => {
  const indexModeSaisi = modesReglagesAppareil.modesSaisis.findIndex(
    (modeSaisi) => modeSaisi.reglageModeID === modesReglagesAppareil.modeActif
  );

  const modeActif = modesReglagesAppareil.modesSaisis[indexModeSaisi];

  return {
    indexModeSaisi,
    mode: modeActif,
  };
};

export default getModeActif;
