import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Alert, Skeleton } from "antd";
import { Loading } from "@ads/ui";
import { useGetAllMessagesFromAPI } from "../../api/useGetAllMessagesFromAPI";
import MessageList from "../molecules/MessageList";
import HeaderListeMessage from "../molecules/HeaderListMessage";
const WrapperAlert = styled.div `
  margin-top: 2rem;
`;
export function MessagesListeTemplate({ visiteID, selection }) {
    const { data: messages, isLoading, error } = useGetAllMessagesFromAPI(visiteID);
    const [messageListFilters, setMessageListFilters] = useState(undefined);
    const [messageFiltres, setMessagesFiltres] = useState([]);
    useEffect(() => {
        if (messages) {
            setMessagesFiltres(messages);
        }
    }, [messages]);
    useEffect(() => {
        if (messageListFilters) {
            setMessagesFiltres((messages === null || messages === void 0 ? void 0 : messages.filter((message) => message.typeObjet.ID === messageListFilters.ID)) || []);
        }
        else {
            setMessagesFiltres(messages);
        }
    }, [messageListFilters]);
    if (isLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Loading, { children: "Chargement en cours de la liste des messages" }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0)] }, void 0));
    }
    if (error) {
        return (_jsx(WrapperAlert, { children: _jsx(Alert, { message: "Une erreur est survenue, la liste des message n'a pu \u00EAtre r\u00E9cup\u00E9r\u00E9e.", showIcon: true, type: "error" }, void 0) }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsx(HeaderListeMessage, { items: messages || [], selection: selection, setMessageListFilters: setMessageListFilters }, void 0), _jsx(MessageList, { selection: selection, visiteID: visiteID, messages: messageFiltres }, void 0)] }, void 0));
}
