import { TypeVisiteModel } from "@ads/isadom-model";
import { BaseError, ERROR_UNKNOWN } from "@ads/react-common-module";
import { SelectionHookListener, useAutoSearch } from "@ads/ui";
import { apiGetTypePrestaByTypeVisite } from "modules/feuille-de-route/api/apiGetTypePrestaByTypeVisite";
import { apiGetTypeVisiteByIntervenatID } from "modules/feuille-de-route/api/apiGetTypeVisiteByIntervenatID";
import { useState } from "react";
import { ISelectTypeVisiteTypePrestaByIntervenantHookService } from "./interfaces/ISelectTypeVisiteTypePrestaByIntervenantHookService";

export interface SelectTypeVisiteTypePrestaByIntervenantHookInput {
  intervenantID: number | null;
}

export function useSelectTypeVisiteTypePrestaByIntervenant({
  intervenantID: defaultIntervenantID,
}: SelectTypeVisiteTypePrestaByIntervenantHookInput): ISelectTypeVisiteTypePrestaByIntervenantHookService {
  const [intervenantID, setIntervenantID] = useState(defaultIntervenantID);

  const serviceTypeVisite = useAutoSearch({
    fetch: apiGetTypeVisiteByIntervenatID,
    typeSelection: "simple",
    defaultCriteria: intervenantID ? intervenantID : undefined,
    getKeyFromData(typeVisite) {
      return typeVisite.sPKTypeVisite;
    },
  });

  serviceTypeVisite.selectService.registerListener(
    new (class extends SelectionHookListener<TypeVisiteModel> {
      onAfterDataSelectedChanged(selectedData: TypeVisiteModel[]): void {
        if (selectedData && selectedData.length > 0 && intervenantID) {
          serviceTypePresta.notifyCriteriaChanged({
            intervenantID: intervenantID,
            typeVisiteID: selectedData[0].sPKTypeVisite,
          });
        } else if (selectedData && selectedData.length > 0 && !intervenantID) {
          throw new BaseError(
            ERROR_UNKNOWN,
            "Le composant ne devrait pas être initialisé. L'intervenant ne peut pas être non défini."
          );
        }
      }
      async onBeforeDataItemSelectedReset(): Promise<boolean> {
        serviceTypePresta.selectService.notifyDataReset();
        serviceTypePresta.selectService.notifyDataSelectedReset();
        return true;
      }
    })("@ads/SelectTypeVisiteTypePrestaByIntervenant")
  );

  const serviceTypePresta = useAutoSearch({
    fetch: apiGetTypePrestaByTypeVisite,
    typeSelection: "multiple",
    getKeyFromData(typeVisite) {
      return typeVisite.id;
    },
  });

  function getTypeVisiteTypePrestaSelected() {
    if (
      serviceTypeVisite.selectService.selectedData &&
      serviceTypeVisite.selectService.selectedData.length > 0
    ) {
      const typeVisite = serviceTypeVisite.selectService.selectedData[0];
      const typePrestaArray = serviceTypePresta.selectService.selectedData;
      typeVisite.tTypePrestation = typePrestaArray;
      return typeVisite;
    } else {
      throw new BaseError(
        ERROR_UNKNOWN,
        "Un type de visite doit être selectionné."
      );
    }
  }

  function handleIntervenantIDChanged(intervenantID: number | null) {
    setIntervenantID(intervenantID);
    if (intervenantID != null) {
      serviceTypeVisite.selectService.notifyDataSelectedReset();
      serviceTypeVisite.notifyCriteriaChanged(intervenantID);
    }
  }

  return {
    autoSearchTypeVisiteService: serviceTypeVisite,
    autoSearchTypePrestaService: serviceTypePresta,
    getTypeVisiteTypePrestaSelected: getTypeVisiteTypePrestaSelected,
    notifyIntervenantIDChanged: handleIntervenantIDChanged,
  };
}
