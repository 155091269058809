import { TitleMain } from "@ads/module-grille";
import { adsNotification, FormWidget } from "@ads/ui";
import { updateFicheSuiviByVisiteID } from "modules/fiche-suivi/feature/updateFicheSuiviByVisiteID";
import { useFicheSuivi } from "../../fiche-suivi/hook/useFicheSuivi";
import { ParamatreEditionListHookResultAdapter } from "../../parametre-edition-list";
import { FicheSuiviProps } from "../../fiche-suivi/ui";
import { ParametreEdition } from "./ParametreEdition";
import nomadTheme from "utils/nomad-theme";

export function ParametreEditionForm(props: FicheSuiviProps) {
  const service = useFicheSuivi({
    data: {
      ficheSuivi: props.data,
    },
    callbacks: {
      async onChange(fs) {
        if (props.callbacks?.onChange) {
          return props.callbacks.onChange(fs);
        }
        return fs;
      },
    },
    features: {
      async updateFicheSuivi(fs) {
        const newFS = fs;
        if (newFS) {
          newFS.parametresEditions =
            service.parametreEditionService.servicePEList.data;
        }
        const result = updateFicheSuiviByVisiteID(props.visiteID, newFS);
        result.then(() => {
          adsNotification.success({
            message: "Formulaire sauvegardé avec succès.",
            duration: nomadTheme.duration.notification,
          });
        });
        return result;
      },
    },
  });
  return (
    <FormWidget
      handleSave={() =>
        service.features.updateFicheSuivi(service.features.getFicheSuivi())
      }
    >
      <TitleMain>Paramètres d'éditions</TitleMain>
      <br />
      <ParametreEdition
        listPEProps={
          new ParamatreEditionListHookResultAdapter(
            service.parametreEditionService.servicePEList,
            "@ads/FicheSuivi"
          )
        }
        selectionTiersProps={{
          data: {
            typeTiers:
              service.parametreEditionService.serviceSelectionTiers.typeTiers,
          },
          callbacks: {
            onTiersSelected(tiersSelected) {
              service.parametreEditionService.features.notifyTiersSelected(
                tiersSelected
              );
            },
          },
        }}
      />
    </FormWidget>
  );
}
