import { EvenementRGPDModel, EvenementStandardModel, EvenementTelesuiviModel, EvenementRGPDAdapter, EvenementTelesuiviAdapter, EvenementStandardAdapter, VisiteModel } from "@ads/isadom-model";
import { useState, useCallback, useEffect } from "react";
import { apiGetEvenementByIDv2, apiGetVisiteEvenementByIDv2 } from "../api";

const useGetEvenement = (evenementID: number | string | undefined, tabActive: string | undefined, visiteModel: VisiteModel | undefined) => {
  if (!evenementID || evenementID === "nouveau") return { evenement: undefined, loading: false }

  const [loading, setLoading] = useState<boolean>(true);
  const [evenement, setEvenement] =
    useState<
      EvenementRGPDModel | EvenementStandardModel | EvenementTelesuiviModel
    >();

  const getEvenementByID = useCallback(async () => {

    let result;

    if (tabActive === "rgpd") {
      const adapter = new EvenementRGPDAdapter();
      result = await apiGetEvenementByIDv2(+evenementID, adapter);
    } else if (tabActive === "irelm-teleobs") {
      const adapter = new EvenementTelesuiviAdapter();
      result = await apiGetEvenementByIDv2(+evenementID, adapter);
    } else {
      // Évènement non réservé
      const adapter = new EvenementStandardAdapter();
      result = await apiGetEvenementByIDv2(+evenementID, adapter);
    }

    if (result) {
      setEvenement(result);
    }
    setLoading(false);

  }, [evenementID, tabActive]);

  const getVisiteEvenementByID = useCallback(async () => {

    if (!visiteModel) return
    let result;

    if (tabActive === "rgpd") {
      const adapter = new EvenementRGPDAdapter();
      result = await apiGetVisiteEvenementByIDv2(
        visiteModel.ID,
        +evenementID,
        adapter
      ) as EvenementRGPDModel;
    } else if (tabActive === "irelm-teleobs") {
      const adapter = new EvenementTelesuiviAdapter();
      result = await apiGetVisiteEvenementByIDv2(
        visiteModel.ID,
        +evenementID,
        adapter
      );
    } else {
      // Évènement non réservé
      const adapter = new EvenementStandardAdapter();
      result = await apiGetVisiteEvenementByIDv2(
        visiteModel.ID,
        +evenementID,
        adapter
      );
    }

    if (result) {
      setEvenement(result);
    }

    setLoading(false);


  }, [visiteModel, tabActive, evenementID]);

  useEffect(() => {
    try {
      if (evenementID) {
        if (location.search.includes("origine=isadom")) {
          getEvenementByID();
        }
        if (location.search.includes("origine=visite")) {
          getVisiteEvenementByID();
        }
      }

      if (!evenementID && !evenementID) {
        setLoading(false);
      }

    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return () => {
      setLoading(false)
      setEvenement(undefined)
    }
  }, []);

  return { evenement, loading }
}

export default useGetEvenement