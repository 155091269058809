import { ProgrammeEducatifModel } from "@ads/isadom-model";
import {
  BaseError,
  UseFormHookService
} from "@ads/react-common-module";
import {
  DateTimePicker,
  FormWidget,
  Label,
  Loading,
  Select,
  SelectWrapper,
  Textarea
} from "@ads/ui";
import locale from "antd/es/date-picker/locale/fr_FR";
import { MetadataProgrammeEducatifService } from "modules/suivi-patient/programme-educatif/models/MetadataProgrammeEducatifService";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
  transition: all 0.2s ease-in-out;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    width: 30%;
  }
`;

const DateTimePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SelectsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  ${({ theme }) => theme.media.sm} {
    /* width: 65%; */
  }
  ${({ theme }) => theme.media.md} {
    width: 65%;
    flex-direction: row;
  }
  ${({ theme }) => theme.media.xl} {
    width: 50%;
  }
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const DateTimePickerProgramme = styled(DateTimePicker)`
  width: auto;
  ${({ theme }) => theme.media.sm} {
    width: 20rem;
  }
  ${({ theme }) => theme.media.md} {
    width: auto;
  }
  ${({ theme }) => theme.media.xl} {
    width: 24rem;
  }
`;

const SelectProgramme = styled(Select)`
  width: auto;
  ${({ theme }) => theme.media.md} {
    max-width: 1rem;
  }
  ${({ theme }) => theme.media.lg} {
    max-width: auto;
  }
`;

export const ERROR_MSG_FORMDATA_NULL = "PE-FORM-NULL"

export function ProgrammeEducatifFormAdapter({
  metadata,
  formData,
  saveService,
  notifyFormDataChanged,
  deleteServices,
  handleCancel
}: UseFormHookService<ProgrammeEducatifModel, MetadataProgrammeEducatifService>) {
  if (!formData) {
    throw new BaseError(ERROR_MSG_FORMDATA_NULL);
  }else {
    const isDeletable = formData.id === 0;

    return (
      <FormWidget
        handleSave={() => {
          saveService.request(formData);
        }}
        isLoadingSave={saveService.loading}
        handleDelete={isDeletable ? deleteServices?.delete : undefined}
        isLoadingDelete={deleteServices?.loading}
        handleCancel={handleCancel ? handleCancel : undefined}
      >
        <Wrapper>
          <TopWrapper>
            <DateWrapper>
              <DateTimePickerWrapper>
                <Label htmlFor="">Du</Label>
                <DateTimePickerProgramme
                  format="DD/MM/YYYY"
                  value={moment(formData.dDebut)}
                  locale={locale}
                  onChange={(value) => {
                    notifyFormDataChanged({
                      ...formData,
                      dDebut: value
                        ? value.format("YYYY-MM-DD")
                        : formData.dDebut
                    });
                  }}
                  onBlur={() => {}}
                  placeholder="Selectionnez une date"
                />
              </DateTimePickerWrapper>
              <DateTimePickerWrapper>
                <Label htmlFor="">Au</Label>
                <DateTimePickerProgramme
                  format="DD/MM/YYYY"
                  value={moment(formData.dFin)}
                  locale={locale}
                  onChange={(value) => {
                    notifyFormDataChanged({
                      ...formData,
                      dFin: value ? value.format("YYYY-MM-DD") : formData.dFin
                    });
                  }}
                  placeholder="Selectionnez une date"
                />
              </DateTimePickerWrapper>
            </DateWrapper>
            <SelectsWrapper>
              <SelectWrapper selectMode="light">
                <Label htmlFor="">Type de programme</Label>
                {metadata?.typePEListService?.loading ? (
                  <Loading>
                    Chargement des types de programmes éducatifs en cours.
                  </Loading>
                ) : (
                  <SelectProgramme
                    selectMode="light"
                    id=""
                    name=""
                    defaultValue={formData.typeProgrammeEducatif.libelle}
                    itemsSelect={metadata?.typePEListService?.data?.map(
                      (value) => {
                        return {
                          value: value.etpID.toString(),
                          content: value.libelle
                        };
                      }
                    )}
                    onSelect={(value) => {
                      if (metadata?.typePEListService?.data) {
                        for (const type of metadata.typePEListService.data) {
                          if (value === type.etpID.toString()) {
                            notifyFormDataChanged({
                              ...formData,
                              typeProgrammeEducatif: type
                            });
                          }
                        }
                      }
                    }}
                    optionFilterProp={""}
                  />
                )}
              </SelectWrapper>
              <SelectWrapper selectMode="light">
                <Label htmlFor="">Etape</Label>
                {metadata?.etapeListService?.loading ? (
                  <Loading>Chargement des étapes en cours.</Loading>
                ) : (
                  <SelectProgramme
                    selectMode="light"
                    id=""
                    name=""
                    itemsSelect={metadata?.etapeListService?.data?.map(
                      (value) => {
                        return {
                          value: value.iPKParametre.toString(),
                          content: value.sLibelle
                        };
                      }
                    )}
                    defaultValue={formData.etape?.sLibelle}
                    optionFilterProp={""}
                    onSelect={(value) => {
                      if (metadata?.etapeListService?.data) {
                        for (const etape of metadata.etapeListService.data) {
                          if (value === etape.iPKParametre.toString()) {
                            notifyFormDataChanged({
                              ...formData,
                              etape: etape
                            });
                          }
                        }
                      }
                    }}
                  />
                )}
              </SelectWrapper>
            </SelectsWrapper>
          </TopWrapper>
          <TextAreaWrapper>
            <Textarea
              id=""
              name=""
              value={
                formData.commentaire
                  ? formData.commentaire
                  : formData.typeProgrammeEducatif?.texteParDefaut
              }
              onChange={(e: any) => {
                notifyFormDataChanged({
                  ...formData,
                  commentaire: e.target.value
                });
              }}
            />
          </TextAreaWrapper>
        </Wrapper>
      </FormWidget>
    );
  }
}
