import { useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { FormItemWrapper, Label, SelectWrapper, FormWidget } from "@ads/ui";
import { Switch, Select, Alert } from "antd";
import { useAppDispatch } from "../../../../hooks/store";
import {
  updateConfigurationAppareil,
  updateCompteurAppareil,
} from "../../../../store/redux/services/configurationAppareil";

import errorAdapter from "../../../../utils/errors/errorAdapter";
import formNotification from "../../../../utils/errors/formNotification";
import { IErrorBackEnd } from "../../../../interface/erreur/api/errorBackEnd";
import { RouteParam } from "../../../../interface/core/RouteParam";
import { UpdateAppareil } from "../../../../interface/updateAppareil";
import { UpdateCompteur } from "../../../../interface/updateCompteur";
import setSelectPreponderance from "../../../../utils/setSelectPreponderance";
import { FieldsFormCompteur } from "../../molecules/form/FormCompteur";
import SelectAdresse from "../../molecules/SelectAdresse";
import FormCompteur from "../../molecules/form/FormCompteur";
import useWindowSize from "../../../../hooks/useWindowSize";
import nomadTheme from "../../../../utils/nomad-theme";
import {
  useGetAppareilVisiteQuery,
  useGetTypesPrestationsFamillesQuery,
} from "store/redux/services/nomadApi";
import { VisiteAPI } from "@ads/isadom-model";

export interface FormConfigurationAppareil extends FieldsFormCompteur {
  produit: number;
  localisationActuelle: number;
  adresseInstallation: number;
  preponderance: string;
  typePrestation: string;
  bAbsent: boolean;
}

const WrapperSelect = styled("div")`
  margin-top: 2.8rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  ${({ theme }) => theme.media.sm} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Infos = styled("div")`
  background-color: ${(props) => props.theme.colors.neutral[130]};
  font-size: 1.8rem;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1.3rem;
  font-family: ${({ theme }) => theme.font.type.primaryMedium};
  ${({ theme }) => theme.media.sm} {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .item-info {
    ${({ theme }) => theme.media.md} {
      display: inline-block;
      margin-bottom: 0;
    }
  }
  .item-info .label {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
    font-weight: normal;
  }
`;

const WrapperSwitch = styled("div")`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  ${({ theme }) => theme.media.sm} {
    height: 100%;
  }
`;

const WrapperAutres = styled("div")`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.6rem;
  ${({ theme }) => theme.media.sm} {
    grid-template-columns: 25% 25%;
  }
`;

const FormWidgetFiche = styled(FormWidget)`
  &.form-widget-background {
    background-color: transparent;
    box-shadow: none;
  }
`;

interface AppareilFicheProps {
  visite: VisiteAPI | undefined;
}

const Fiche = ({ visite }: AppareilFicheProps) => {

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const { iPKVisite, IDProduit } = useParams<RouteParam>();

  const {
    data: configAppareil,
    isLoading,
    refetch,
  } = useGetAppareilVisiteQuery(
    {
      visiteID: iPKVisite,
      appID: IDProduit,
    },
    {
      skip: !iPKVisite || !IDProduit,
    }
  );

  const [isAbsent, setIsAbsent] = useState<boolean | undefined>(
    configAppareil?.bAbsent
  );

  const handleChangeAppareilAbsent = () => {
    setIsAbsent(!isAbsent);
  };

  const releveCompteur =
    !isLoading && configAppareil?.produit.compteur.iCompteurActuel
      ? configAppareil?.produit.compteur.iCompteurActuel
      : undefined;

  const methods = useForm<FormConfigurationAppareil>({
    defaultValues: {
      compteurHS: !isLoading && configAppareil?.produit?.compteur.bCompteurHS,
      releveCompteur,
      bAbsent: !isLoading && configAppareil?.bAbsent,
    },
  });

  const idFamille = configAppareil?.produit?.modele.famille.sPKFamille;

  const { data: typesPrestations } =
    useGetTypesPrestationsFamillesQuery(idFamille);

  const tPrestations = typesPrestations?.map((prestation) => ({
    value: prestation.sPKTypePrestation,
    label: prestation.sLibelle,
  }));

  /**
   * @author Bastien
   * Le formulaire est composé de deux endpoints, l'un pour le compteur
   * l'autre pour l'appareil, il est nécessaire de vérifier que les deux
   * soient fullfilled pour envoyer un message de succès.
   */
  const onSubmit = async (formData: FormConfigurationAppareil) => {
    setIsLoadingSave(true);
    const dataAppareilToSend: UpdateAppareil = {
      produit: {
        IDProduit: +IDProduit,
      },
      adresseInstallation: {
        iPKAdresse:
          formData.adresseInstallation ||
          visite?.tiersVisite.adressePrincipale.iPKAdresse,
      },
      typePrestation: {
        sPKTypePrestation: formData.typePrestation || "",
      },
      preponderance: formData.preponderance,
      bAbsent: formData.bAbsent || false,
    };

    const dataCompteurToSend: UpdateCompteur = {
      iCompteurActuel: formData.releveCompteur,
      bCompteurHS: formData.compteurHS,
    };

    const resultActionAppareil = await dispatch(
      updateConfigurationAppareil({
        IDVisite: +iPKVisite,
        data: dataAppareilToSend,
      })
    );

    //Verifcation du formulaire d'update de l'appareil
    if (updateConfigurationAppareil.fulfilled.match(resultActionAppareil)) {
      // Verifcation du formulaire d'update du compteur
      if (configAppareil?.produit?.modele.bSuiviCompteur && !isAbsent) {
        const resultAction = await dispatch(
          updateCompteurAppareil({
            IDVisite: +iPKVisite,
            IDAppareil: +IDProduit,
            data: dataCompteurToSend,
          })
        );
        if (updateCompteurAppareil.fulfilled.match(resultAction)) {
          formNotification({ type: "success" });
          refetch();
          history.push(`/visites/${iPKVisite}/appareils/configuration`);
        } else {
          errorAdapter(resultAction.payload as IErrorBackEnd)?.forEach(
            (error) => formNotification({ type: "error", error: error.message })
          );
        }
      }
      // Si Appareil sans compteur et requête fullfilled
      else {
        refetch();
        formNotification({ type: "success" });
        history.push(`/visites/${iPKVisite}/appareils/configuration`);
      }
    } else if (
      updateConfigurationAppareil.rejected.match(resultActionAppareil)
    ) {
      if (resultActionAppareil.payload) {
        errorAdapter(resultActionAppareil.payload as IErrorBackEnd)?.forEach(
          (error) => formNotification({ type: "error", error: error.message })
        );
      }
    }
    setIsLoadingSave(false);
  };

  const [windowWith] = useWindowSize();
  const offsetX = windowWith > parseInt(nomadTheme.breakpoints.md) ? -75 : -35;

  if (visite) {
    if (visite.bValide) {
      return (
        <>
          <Label htmlFor="type">Informations générales</Label>
          <Infos>
            <p className="item-info">
              <span className="label">Modèle</span>{" "}
              {configAppareil?.produit?.modele.sLibelle}
            </p>
            <p className="item-info">
              <span className="label">Référence</span>{" "}
              {configAppareil?.produit?.modele?.sRefProduit}
            </p>
            <p className="item-info">
              <span className="label">N° de série</span>{" "}
              {configAppareil?.produit?.sNumeroSerie}
            </p>
            <p className="item-info">
              <span className="label">Date d'installation</span>{" "}
              {configAppareil?.produit &&
                new Date(
                  configAppareil?.produit?.derniereDateMouvement
                ).toLocaleDateString("fr")}
            </p>
          </Infos>
          <SelectAdresse
            visite={visite}
            adresseInstallation={configAppareil?.adresseInstallation}
          />
          <WrapperSelect>
            <FormItemWrapper direction="vertical">
              {configAppareil && (
                <Label htmlFor="type">
                  {configAppareil.typePrestation.sLibelle !== ""
                    ? "Type de prestation : " +
                    configAppareil.typePrestation.sLibelle
                    : "Pas de type de prestation"}
                </Label>
              )}
            </FormItemWrapper>
            <FormItemWrapper direction="vertical">
              {configAppareil && (
                <Label htmlFor="type">
                  {configAppareil.preponderance !== ""
                    ? "Prépondérance : " + configAppareil.preponderance
                    : "Pas de type de prépondérance"}
                </Label>
              )}
            </FormItemWrapper>
          </WrapperSelect>
          {configAppareil?.produit?.modele.bSuiviCompteur && (
            <FormCompteur
              produit={configAppareil?.produit}
              isAbsent={isAbsent}
            />
          )}
          <WrapperAutres>
            <FormItemWrapper direction="vertical">
              <Label htmlFor="type">
                {configAppareil?.bAbsent
                  ? "Appareil absent"
                  : "Appareil présent"}
              </Label>
            </FormItemWrapper>
          </WrapperAutres>
        </>
      );
    } else {
      return (
        <>
          {
            <FormWidgetFiche
              isLoadingSave={isLoadingSave}
              handleSave={methods.handleSubmit(onSubmit)}
              offsetX={offsetX}
              offsetY={-120}
            >
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Label htmlFor="type">Informations générales</Label>
                  <Infos>
                    <p className="item-info">
                      <span className="label">Modèle</span>{" "}
                      {configAppareil?.produit?.modele.sLibelle}
                    </p>
                    <p className="item-info">
                      <span className="label">Référence</span>{" "}
                      {configAppareil?.produit?.modele?.sRefProduit}
                    </p>
                    <p className="item-info">
                      <span className="label">N° de série</span>{" "}
                      {configAppareil?.produit?.sNumeroSerie}
                    </p>
                    <p className="item-info">
                      <span className="label">Date d'installation</span>{" "}
                      {configAppareil?.produit &&
                        new Date(
                          configAppareil?.produit?.derniereDateMouvement
                        ).toLocaleDateString("fr")}
                    </p>
                  </Infos>
                  <SelectAdresse
                    visite={visite}
                    adresseInstallation={configAppareil?.adresseInstallation}
                  />
                  <WrapperSelect>
                    <FormItemWrapper direction="vertical">
                      <Label htmlFor="type">Type de prestation</Label>
                      <SelectWrapper selectMode="light">
                        <Controller
                          name="typePrestation"
                          control={methods.control}
                          defaultValue={
                            configAppareil?.typePrestation.sPKTypePrestation
                          }
                          render={({ field }) => (
                            <Select {...field} options={tPrestations} />
                          )}
                        />
                      </SelectWrapper>
                    </FormItemWrapper>
                    <FormItemWrapper direction="vertical">
                      <Label htmlFor="type">Prépondérance</Label>
                      <SelectWrapper selectMode="light">
                        <Controller
                          name="preponderance"
                          control={methods.control}
                          defaultValue={configAppareil?.preponderance}
                          render={({ field }) => (
                            <Select
                              {...field}
                              defaultValue={configAppareil?.preponderance}
                              options={setSelectPreponderance()}
                            />
                          )}
                        />
                      </SelectWrapper>
                    </FormItemWrapper>
                  </WrapperSelect>
                  {configAppareil?.produit?.modele.bSuiviCompteur && (
                    <FormCompteur
                      produit={configAppareil?.produit}
                      isAbsent={isAbsent}
                    />
                  )}
                  <WrapperAutres>
                    <FormItemWrapper direction="vertical">
                      <Label htmlFor="type">Appareil absent</Label>
                      <WrapperSwitch>
                        <Controller
                          control={methods.control}
                          name="bAbsent"
                          defaultValue={configAppareil?.bAbsent}
                          render={({ field: { value, onChange } }) => (
                            <Switch
                              onChange={onChange}
                              checked={value}
                              onClick={handleChangeAppareilAbsent}
                            />
                          )}
                        />
                      </WrapperSwitch>
                    </FormItemWrapper>
                  </WrapperAutres>
                </form>
              </FormProvider>
            </FormWidgetFiche>
          }
        </>
      );
    }
  } else {
    return (
      <Alert
        message="Erreur inattendue. Veuillez essayer à nouveau. Si le problème persite, veuillez contacter votre administrateur.  "
        type="error"
      />
    );
  }
};

export default Fiche;
