import { TypePrestationAdapter, TypePrestationAPI, TypePrestationModel } from "@ads/isadom-model";
import { apiGetV2, ApiInstance } from "@ads/react-common-module";

export interface TypePrestaCriteria {
  intervenantID: number;
  typeVisiteID: string;
}

export async function apiGetTypePrestaByTypeVisite(
  api: ApiInstance | null,
  criteria: TypePrestaCriteria
): Promise<TypePrestationModel[]> {
  const result = await apiGetV2<TypePrestationAPI[]>(
    api,
    `/intervenants/${criteria.intervenantID}/types-visites/${criteria.typeVisiteID}/types-prestations`
  );

  return new TypePrestationAdapter().toClients(result);
}
