import React, { FC, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert } from "antd";
import styled from "styled-components";
import { v4 } from "uuid";
import {
  ButtonCircle,
  IconStatusWithFont,
  IconsV3,
  MultiRollingPanel,
  Loading
} from "@ads/ui";

import { HttpMessageModel } from "@ads/isadom-model";
import { RouteParam } from "interface/core/RouteParam";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";
import PanelChecklist from "../organisms/panels/PanelChecklist";
import { PanelGED } from "../organisms/panels/PanelGed";
import WrapperBlur from "../organisms/wrappers/WrapperBlur";
import WrapperMessagerie from "../organisms/wrappers/WrapperMessagerie";
import PanelFichePatient from "../molecules/FichePatient";
import WrapperMain from "../organisms/wrappers/WrapperMain";
import WrapperMainContent from "../organisms/wrappers/WrapperMainContent";
import { useSyncVisiteByIDWithContext } from "modules/visites/hooks/useSyncVisiteByIDWithContext";
import { VisiteErrorMessagePanel } from "modules/fin-de-visite/ui/molecule/VisiteErrorMessagePanel";
import { useAppSelector } from "hooks/store";
import { selectErrorMessageByVisiteIDFromStore } from "modules/feuille-de-route/store/redux/features/feuille-de-route.slice";
import { HeaderSaisieDeVisite } from "components-v2/header/HeaderSaisieDeVisite";
import WrapperApp from "../organisms/wrappers/WrapperApp";

interface PropsTemplateSaisieVisite {
  navigation: JSX.Element;
}

const MULTI_ROLLING_PANNEL_TOP = "23.5rem";
const MULTI_ROLLING_PANNEL_TOP_STICKY = "13.5rem";

const MultiRollingPanelStyled = styled(MultiRollingPanel)`
  top: ${MULTI_ROLLING_PANNEL_TOP};
  z-index: 1100;
  .is-sticky.no-header & {
    top: ${MULTI_ROLLING_PANNEL_TOP_STICKY};
  }
`;

const PanelCommonWrapper = styled.div`
  height: calc(100vh - ${MULTI_ROLLING_PANNEL_TOP});
  width: 46.5rem;
  overflow: auto;
  max-width: 46.5rem;
  background-color: white;

  .is-sticky.no-header & {
    height: calc(100vh - ${MULTI_ROLLING_PANNEL_TOP_STICKY});
  }
`;

/**

 * @desc affiche les volets déroulants
 */
const TemplateSaisieVisite: FC<PropsTemplateSaisieVisite> = ({
  children,
  navigation
}) => {
  const refWrapperBur = useRef<HTMLDivElement>(null);
  const refWrapperMainContent = useRef<HTMLDivElement>(null);
  const { iPKVisite } = useParams<RouteParam>();

  const { data: visite } = useGetVisiteQuery(+iPKVisite);

  const { loading } = useSyncVisiteByIDWithContext(+iPKVisite);

  const scroll = window.scrollY;

  let displayWorkflow = true;
  useEffect(() => {
    if (scroll > 0) {
      displayWorkflow = false;
    } else displayWorkflow = true;
  }, []);

  const messages: HttpMessageModel[] | undefined = useAppSelector((state) =>
    selectErrorMessageByVisiteIDFromStore(
      state,
      iPKVisite ? parseInt(iPKVisite) : -1
    )
  );

  const getBaseTemplate = (content: React.ReactNode) => {
    return (
      <WrapperApp>
        <HeaderSaisieDeVisite
          iPKVisite={+iPKVisite}
          navigation={navigation}
          displayWorkflow={displayWorkflow}
        />
        {iPKVisite && (
          <WrapperMessagerie>
            <Link to={`/visites/${iPKVisite}/messages`}>
              <ButtonCircle
                backgroundColorButton="#ED5B2E"
                iconColor="#FFF"
                size="5rem"
              />
            </Link>
          </WrapperMessagerie>
        )}
        <WrapperMain className="wrapper-main">
          <WrapperBlur className="wrapper-blur" ref={refWrapperBur}>
            <WrapperMainContent
              ref={refWrapperMainContent}
              className={`wrapper-main-content`}
            >
              {content}
            </WrapperMainContent>
          </WrapperBlur>
        </WrapperMain>
      </WrapperApp>
    );
  };

  if (loading && !visite) {
    return getBaseTemplate(
      <Loading>
        <></>
      </Loading>
    );
  } else if (visite) {
    const patientID = visite.tiersVisite.iPKTiers;
    const panels = [
      {
        key: v4(),
        icon: <IconsV3.Patient />,
        children: (
          <PanelCommonWrapper>
            <PanelFichePatient patientID={patientID} />
          </PanelCommonWrapper>
        )
      },
      {
        key: v4(),
        icon: <IconsV3.Document />,
        children: (
          <PanelCommonWrapper>
            <PanelGED visiteID={+iPKVisite} />
          </PanelCommonWrapper>
        )
      },
      {
        key: v4(),
        icon: <IconsV3.Checklist />,
        children: (
          <PanelCommonWrapper>
            <PanelChecklist visiteID={+iPKVisite} />
          </PanelCommonWrapper>
        )
      },
      {
        key: v4(),
        icon:
          messages && messages.length > 0 ? (
            <IconStatusWithFont
              iconColor={messages && messages.length > 0 ? "#f4430c" : ""}
              iconSize=""
            />
          ) : (
            <IconsV3.Statut />
          ),
        children: (
          <PanelCommonWrapper>
            <VisiteErrorMessagePanel
              visiteID={+iPKVisite}
              messages={messages}
            />
          </PanelCommonWrapper>
        )
      }
    ];
    return (
      <WrapperApp>
        <HeaderSaisieDeVisite
          iPKVisite={+iPKVisite}
          navigation={navigation}
          displayWorkflow={displayWorkflow}
        />
        {iPKVisite && (
          <WrapperMessagerie>
            <Link to={`/visites/${iPKVisite}/messages`}>
              <ButtonCircle
                backgroundColorButton="#ED5B2E"
                iconColor="#FFF"
                size="5rem"
              />
            </Link>
          </WrapperMessagerie>
        )}
        <WrapperMain className="wrapper-main">
          <WrapperBlur className="wrapper-blur" ref={refWrapperBur}>
            <MultiRollingPanelStyled
              panels={panels}
              side="right"
              onToggle={(isOpen) => {
                if (refWrapperMainContent.current) {
                  if (isOpen) {
                    refWrapperMainContent.current.classList.add("isOpenRight");
                  } else {
                    refWrapperMainContent.current.classList.remove(
                      "isOpenRight"
                    );
                  }
                }
              }}
            />
            <WrapperMainContent
              ref={refWrapperMainContent}
              className={`wrapper-main-content`}
            >
              {children}
            </WrapperMainContent>
          </WrapperBlur>
        </WrapperMain>
      </WrapperApp>
    );
  } else
    return getBaseTemplate(
      <Alert message="Erreur lors du chargement des informations de la visite. Veuillez essayer à nouveau." />
    );
};

export default TemplateSaisieVisite;
