import { IConstanteModel } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { apiPutConstanteRespiratoireListByVisiteID } from "../api/apiPutConstanteRespiratoireListByVisiteID";

export async function updateConstantesRespiratoiresByVisiteID(visiteID: number, constantes: IConstanteModel[] | null):  Promise<IConstanteModel[] | null> {
    if(constantes){
        return await (await apiPutConstanteRespiratoireListByVisiteID(getDefaultApiInstance(), visiteID, constantes)).data
    }else{
        return null
    }
}  