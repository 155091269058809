import { Controller, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";
import { FormItemWrapper, Input, Label, ErrorFormMsg } from "@ads/ui";
import * as yup from "yup";
import { motion } from "framer-motion";
import { Switch } from "antd";
import { AppareilAPI } from "@ads/isadom-model";
import { getDateFr } from "../../../../utils/convertDateTime";

const WrapperReleve = styled("div")`
  margin-top: 1rem;
  ${({ theme }) => theme.media.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
`;

const WrapperCompteur = styled("div")`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
  }
`;

const WrapperCompteurPrecedent = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 4rem;
  font-weight: bold;
`;

const WrapperSwitch = styled("div")`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  ${({ theme }) => theme.media.sm} {
    height: 100%;
  }
`;

export const schemaFieldsCompteur = {
  releveCompteur: yup.number(),
  compteurHS: yup.boolean(),
};

export interface FieldsFormCompteur {
  releveCompteur: number | undefined;
  compteurHS: boolean;
}

interface PropsFormCompteur {
  produit: AppareilAPI | undefined;
  isAbsent?: boolean;
}

interface PropsController {
  propsController?: Record<string, any>;
  defaultValue?: number;
}

/**
 * Champs de formulaires pour gérer les compteurs : Compteur HS, valeur actuelle et valeur précédente
 * @author Bastien - Johan
 * @date 2021-09-10
 * @param {any} {produit
 * @param {any} control}:Props
 * @returns {any}
 */
const FormCompteur = ({ produit, isAbsent }: PropsFormCompteur) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FieldsFormCompteur>();

  const isCompteurHS = useWatch({
    control,
    name: "compteurHS",
  });

  // Input number empty si pas de données back
  const propsController: PropsController = {};
  if (produit?.compteur.iCompteurActuel !== 0) {
    propsController.defaultValue = produit?.compteur.iCompteurActuel;
  }

  const isReleveCompteurRequired = isCompteurHS || isAbsent ? false : true;
  const isReleveCompteurDisabled = isCompteurHS || isAbsent ? true : false;

  return (
    <WrapperReleve>
      <WrapperCompteur>
        <FormItemWrapper direction="vertical">
          <Label htmlFor="releveCompteur">Compteur actuel</Label>
          <Controller
            control={control}
            name={"releveCompteur"}
            rules={{
              required: isReleveCompteurRequired,
            }}
            {...propsController}
            render={({ field }) => (
              <Input
                disabled={isReleveCompteurDisabled}
                id="releveCompteur"
                type="number"
                selectMode="light"
                {...field}
                placeholder={
                  isReleveCompteurDisabled
                    ? ""
                    : "Saisissez la valeur du compteur"
                }
              />
            )}
          />
          {errors.releveCompteur && errors.releveCompteur.type === "required" && (
            <motion.div
              initial={{ x: -10, opacity: 0 }}
              animate={{ x: -15, y: 5, opacity: 1 }}
            >
              <ErrorFormMsg>La saisie du compteur est obligatoire</ErrorFormMsg>
            </motion.div>
          )}
        </FormItemWrapper>
        <WrapperCompteurPrecedent className="item-info">
          <span className="label">Compteur précédent</span>
          {produit?.compteur.iCompteurPrecedent === 0
            ? "Aucun compteur renseigné"
            : `${produit?.compteur.iCompteurPrecedent} le ${getDateFr(
              produit?.compteur.dCompteurPrecedent || ""
            )}`}
        </WrapperCompteurPrecedent>
      </WrapperCompteur>
      <FormItemWrapper direction="vertical">
        <WrapperSwitch>
          <Label htmlFor="compteurHS">Compteur HS</Label>
          <Controller
            control={control}
            name="compteurHS"
            render={({ field: { onChange } }) => <Switch onChange={onChange} />}
          />
        </WrapperSwitch>
      </FormItemWrapper>
    </WrapperReleve>
  );
};

export default FormCompteur;
