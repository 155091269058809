import { FicheSuiviModel } from "@ads/isadom-model";
import { useEffect, useState } from "react";
import {
  IFichesSuivisListHookInput,
  IFichesSuivisListHookService
} from "./interfaces";

export function useFichesSuivisList(
  input: IFichesSuivisListHookInput
): IFichesSuivisListHookService {
  const [readLoading, setReadLoading] = useState(false);
  const [hasReadingError, setHasReadingError] = useState(false);
  const [writeLoading] = useState(false);
  const [fichesSuivis, setFichesSuivis] =
    useState<FicheSuiviModel[] | null>(null);

  useEffect(() => {
    setReadLoading(true);
    input.data.fichesSuivis
      .then((data: FicheSuiviModel[]) => {
        setFichesSuivis(data);
        setHasReadingError(false);
      })
      .catch((error) => {
        setHasReadingError(true);
        // eslint-disable-next-line no-console
        console.error("👩‍🚒 useFichesSuivisList error", error);
      })
      .finally(() => {
        setReadLoading(false);
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onFicheSuiviChange(
    fs: FicheSuiviModel
  ): Promise<FicheSuiviModel> {
    if (fichesSuivis === null) {
      throw new Error("Le tableau de constante est null.");
    }
    if (fichesSuivis.length === 0) {
      throw new Error("Le tableau de constante est vide.");
    }

    if (input.callbacks?.onFicheSuiviChange) {
      fs = await input.callbacks.onFicheSuiviChange(fs);
    }

    setFichesSuivis([...fichesSuivis]);
    return fs;
  }

  //TODO FBR manage write loading
  async function updateFicheSuivi(
    ficheSuivi: FicheSuiviModel | null
  ): Promise<FicheSuiviModel | null> {
    if (ficheSuivi) {
      ficheSuivi = await input.features.updateFicheSuivi(ficheSuivi);
      if (ficheSuivi) {
        const newFichesSuivis = fichesSuivis ? fichesSuivis : [];
        const index = newFichesSuivis?.findIndex(
          (value) => value.formulaire.id === ficheSuivi?.formulaire.id
        );
        if (index !== -1) {
          newFichesSuivis[index] = ficheSuivi;
        } else {
          newFichesSuivis?.push(ficheSuivi);
        }
      }
      return ficheSuivi;
    } else {
      setFichesSuivis(null);
      return null;
    }
  }

  const service: IFichesSuivisListHookService = {
    data: {
      fichesSuivis: fichesSuivis
    },
    loadings: {
      readLoading: readLoading,
      writeLoading: writeLoading
    },
    errors: {
      hasReadingError
    },
    features: {
      updateFicheSuivi: updateFicheSuivi
    },
    actions: {
      notifyFicheSuiviChange: onFicheSuiviChange
    }
  };

  return service;
}
