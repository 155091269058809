import {  ProgrammeEducatifModel } from "@ads/isadom-model";
import {  BaseError, ERROR_UNKNOWN, useListContext } from "@ads/react-common-module";
import { createContext, useContext, useState } from "react";
import { MetadataProgrammeEducatifService } from "../../models/MetadataProgrammeEducatifService";

export interface IPEContextStore {
  peList: ProgrammeEducatifModel[] | null;
  metadata: MetadataProgrammeEducatifService
}

const initalState: IPEContextStore = {
  peList: null,
  metadata: {
    etapeListService: undefined,
    typePEListService: undefined
  }
};

interface StoreProviderInterface extends IPEContextStore {
  setPEList(pe: ProgrammeEducatifModel[] | null): void;
  setMetadata(metadata: MetadataProgrammeEducatifService): void
  addPE(pe: ProgrammeEducatifModel): ProgrammeEducatifModel[]
  getPEByNautID(peID: number): ProgrammeEducatifModel | null
  getPEByIsaID(peID: number): ProgrammeEducatifModel | null
}

const StoreContext = createContext(initalState as StoreProviderInterface);

export function useSelectPEStore() {
  return useContext(StoreContext);
}

interface ProviderInterface {
  children: JSX.Element | JSX.Element[];
}

export function PEStoreProvider({ children }: ProviderInterface) {
  
  const [metadata, setMetadata] = useState(initalState.metadata)

  const {data, addDataItem, setDataList} = useListContext<ProgrammeEducatifModel, number>(
    {
      initialData: initalState.peList,
      getID(item){
        if(item.id){
          return item.id
        }else if(item.nautID){
          return item.nautID
        }else{
          throw new BaseError(ERROR_UNKNOWN)
        }
        
      }
  })

  const value : StoreProviderInterface = {
    peList: data,
    metadata,
    setMetadata,
    addPE: addDataItem,
    setPEList: setDataList,
    getPEByIsaID(id){
      if(data){
        for(const pe of data){
          if(pe.id === id){
            return pe
          }
        }
      }
      return null
    },
    getPEByNautID(peID) {
        if(data){
          for(const pe of data){
            if(pe.nautID === peID){
              return pe
            }
          }
        }
        return null
      },
  }

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
}
