var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Alert, notification } from "antd";
import { Breadcrumb, FormWidget, theme } from "@ads/ui";
import { useApi } from "@ads/react-common-module";
import { useHistory } from "react-router-dom";
import { Content } from "../atoms/Content";
import { MessageForm } from "../organisms/MessageForm";
import { useGetMessageByIDFromAPI } from "../../api/useGetMessageByIDFromAPI";
import { apiEditMessage } from "../../api/apiEditMessage";
import { apiDeleteMessage } from "../../api/apiDeleteMessage";
import errorAdapter from "../../api/errorAdapter";
export function MessageEditionTemplate({ visiteID, intervenantID, messageID, selectionDestinataire }) {
    const api = useApi();
    const history = useHistory();
    const [messageData, setMessageData] = useState();
    const { data: message, isLoading, error, } = useGetMessageByIDFromAPI(visiteID, messageID);
    if (isLoading) {
        return null;
    }
    if (error) {
        return (_jsx(Alert, { message: "Impossible de charger le message", showIcon: true, type: "error" }, void 0));
    }
    const breadcrumbItems = [
        {
            item: "Messages",
            to: `/visites/${visiteID}/messages`,
        },
        {
            item: `Edition d'un message`,
            to: "",
        },
    ];
    const handleSaveMessage = (messageFormData) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            if (!message) {
                console.log("error");
                throw new Error();
            }
            const payload = Object.assign(Object.assign(Object.assign({}, message), messageFormData), { typeObjet: messageFormData.typeObjetID
                    ? {
                        ID: messageFormData.typeObjetID,
                        libelle: "",
                        corpsMessageParDefaut: "",
                    }
                    : message.typeObjet, destinatairesPrincipaux: messageFormData.destinataires
                    ? messageFormData.destinataires.map((destinataire) => ({
                        tiersID: destinataire.value,
                    }))
                    : message.destinatairesPrincipaux });
            yield apiEditMessage(api, visiteID, payload, message.ID);
            notification.success({
                message: "Succès",
                description: "Votre message a bien été sauvegardé.",
                duration: theme.duration.notification,
            });
            //history.push(`/visites/${visiteID}/messages`);
        }
        catch (error) {
            (_a = errorAdapter(error)) === null || _a === void 0 ? void 0 : _a.forEach((e, index) => {
                notification.error({
                    key: `erreur-${index}`,
                    message: "Erreur",
                    description: e.message || "Erreur interne du serveur",
                });
            });
        }
    });
    const handleDeleteMessage = () => __awaiter(this, void 0, void 0, function* () {
        try {
            if (!message) {
                throw new Error();
            }
            yield apiDeleteMessage(api, visiteID, message.ID);
            notification.success({
                message: "Succès",
                description: "Votre message a bien été supprimé.",
                duration: theme.duration.notification,
            });
            history.push(`/visites/${visiteID}/messages`);
        }
        catch (errors) {
            let messages = "";
            errors.metiers.forEach((message) => {
                messages += ` ${message.message}`;
            });
            notification.error({
                message: "Erreur",
                description: messages,
                duration: theme.duration.notification,
            });
        }
    });
    return (_jsxs(FormWidget, Object.assign({ handleSave: () => handleSaveMessage(messageData), handleDelete: () => handleDeleteMessage(), handleCancel: () => history.push(`/visites/${visiteID}/messages`) }, { children: [_jsx(Breadcrumb, { size: "M", breadcrumbItems: breadcrumbItems }, void 0), _jsx(Content, { children: _jsx(MessageForm, { intervenantID: intervenantID, message: message, setMessageData: setMessageData, selectionDestinataire: selectionDestinataire }, void 0) }, void 0)] }), void 0));
}
