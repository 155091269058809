import { EvenementTelesuiviModel } from "@ads/isadom-model";
import styled from "styled-components";
import { Breadcrumb } from "@ads/ui";

import { EvenementTelesuiviInformationsGenerales } from "./";
import MenuTab from "components/ui/molecules/MenuTab";
import ContentTab from "components/ui/molecules/ContentTab";

const Wrapper = styled.div`
  margin: 2.6rem 0 0;
`;

interface EvenementTelesuiviViewProps {
  visiteID: number;
  type: string;
  evenement: EvenementTelesuiviModel;
}

export const EvenementTelesuiviView = ({
  visiteID,
  type,
  evenement,
}: EvenementTelesuiviViewProps) => {
  const breadcrumbsItems = [
    {
      item: "Evenements",
      to: `/visites/${visiteID}/evenements/${type}`,
    },
    {
      item: `${evenement?.typeEvenement.libelle} le ${new Intl.DateTimeFormat(
        "fr-FR"
      ).format(new Date(evenement.dateDebut))}`,
      to: "",
    },
  ];

  return (
    <>
      <Breadcrumb breadcrumbItems={breadcrumbsItems} size="M" />
      <Wrapper>
        <MenuTab
          tabs={[
            { id: "informations", label: "Informations générales", url: "#" },
          ]}
          subTabActive={"informations"}
        />
        <ContentTab>
          <EvenementTelesuiviInformationsGenerales evenement={evenement} />
        </ContentTab>
      </Wrapper>
    </>
  );
};
