import React, { FC } from "react";
import { PrimaryButton, Icons } from "@ads/ui";
import { ModesReglagesAppareil } from "modules/appareils/interfaces/modes-reglages.interfaces";
import { GrilleAPI } from "@ads/isadom-model";

interface Props {
  modesReglagesAppareil: ModesReglagesAppareil;
  setModesReglagesAppareil: React.Dispatch<
    React.SetStateAction<ModesReglagesAppareil>
  >;
}

const BoutonAjoutGrille: FC<Props> = ({
  modesReglagesAppareil,
  setModesReglagesAppareil,
}) => {
  const handleClick = () => {
    // Mode en cours
    const modeSaisi = modesReglagesAppareil.modesSaisis.find(
      (mode) => mode.reglageModeID === modesReglagesAppareil.modeActif
    );

    // On duplique la première grille
    if (!modeSaisi) return;
    const grille = modeSaisi.tabGrille[0];

    const newGrille: GrilleAPI = {
      ...grille,
      tabReglage: grille.tabReglage.map((reglage) => {
        return {
          ...reglage,
          tabReglageValeur: reglage.tabReglageValeur.map((reglageValeur) => {
            return {
              ...reglageValeur,
              iCPNautReglageGrille: 0,
              reglageID: 0,
              sIDRegroupement: "",
            };
          }),
        };
      }),
    };

    setModesReglagesAppareil({
      ...modesReglagesAppareil,
      modes: modesReglagesAppareil.modes.map((mode) =>
        mode.reglageModeID === modesReglagesAppareil.modeActif
          ? {
              ...mode,
              tabGrille: [...mode.tabGrille, { ...mode.tabGrille[0] }],
            }
          : mode
      ),
      modesPrecedents: modesReglagesAppareil.modesPrecedents.map((mode) =>
        mode.reglageModeID === modesReglagesAppareil.modeActif
          ? {
              ...mode,
              tabGrille: [...mode.tabGrille, { ...mode.tabGrille[0] }],
            }
          : mode
      ),
      modesSaisis: modesReglagesAppareil.modesSaisis.map((mode) =>
        mode.reglageModeID === modesReglagesAppareil.modeActif
          ? {
              ...mode,
              tabGrille: [...mode.tabGrille, newGrille],
            }
          : mode
      ),
      grilleActive: modesReglagesAppareil.grilleActive + 1,
    });
  };

  return (
    <PrimaryButton
      onClick={handleClick}
      label="Ajouter une grille"
      as="button"
      iconPosition="left"
      icon={Icons.AjouterGrille}
      type="button"
      buttonStyle="neutral130"
      size="S"
      minWidth="100%"
    />
  );
};

export default BoutonAjoutGrille;
