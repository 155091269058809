import { useFormSelectionItem, } from "../../../../common";
import { getTypesLocalisations } from "../../../feature";
export function useFormTypeLocalisationItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.typeLocalisationID;
        },
        config: input.config,
        initParams: null,
        isDataMatchWithFilter(_data, _filter) {
            return true;
        },
        onGetDataRequested: getTypesLocalisations,
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
