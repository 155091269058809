import { IConstanteModel } from "@ads/isadom-model";
import { adsNotification, FormWidget, WidgetWrapper } from "@ads/ui";
import { Alert } from "antd";
import MarginTitleMain from "components/ui/atoms/titles/MarginTitleMain";
import { ILoadingsProps, withLoading } from "modules/fiche-suivi/common";
import { getConstanteRespiratoireListByVisiteID } from "modules/fiche-suivi/feature/getConstanteRespiratoireListByVisiteID";
import { updateConstantesRespiratoiresByVisiteID } from "modules/fiche-suivi/feature/updateConstantesRespiratoiresByVisiteID";
import { FunctionComponent, useState } from "react";
import {
  getConstanteListByVisiteID,
  updateConstantesByVisiteID,
} from "../../../feature";
import { IConstanteListHookResult } from "../hook/interfaces";
import { useConstanteList } from "../hook/useConstanteList";
import { ConstanteListProps } from "./ConstanteList";
import { ConstanteListItemProps } from "./ConstanteListItem";

interface WithConstantesHookProps {
  listAdapter: FunctionComponent<ConstanteListProps>;
  listRespiratoireAdapter: FunctionComponent<ConstanteListProps>;
  itemRespiratoireAdapter: FunctionComponent<ConstanteListItemProps>;
  itemConstanteAdapter: FunctionComponent<ConstanteListItemProps>;
  visiteID: number | null;
}

export function ConstantesListVisite(props: WithConstantesHookProps) {
  const [data] = useState<Promise<IConstanteModel[]>>(() => {
    if (props.visiteID) {
      return getConstanteListByVisiteID(props.visiteID);
    } else {
      return new Promise((resolve) => resolve([]));
    }
  });

  const [dataRespiratoire] = useState<Promise<IConstanteModel[]>>(() => {
    if (props.visiteID) {
      return getConstanteRespiratoireListByVisiteID(props.visiteID);
    } else {
      return new Promise((resolve) => resolve([]));
    }
  });

  const service = useConstanteList({
    data: data,
    features: {
      async updateConstantes(constantes) {
        if (props.visiteID) {
          return await updateConstantesByVisiteID(props.visiteID, constantes);
        } else {
          return new Promise<IConstanteModel[]>((resolve) => {
            resolve([]);
          });
        }
      },
    },
  });

  const serviceRespiratoire = useConstanteList({
    data: dataRespiratoire,
    features: {
      async updateConstantes(constantes) {
        if (props.visiteID) {
          return await updateConstantesRespiratoiresByVisiteID(
            props.visiteID,
            constantes
          );
        } else {
          return new Promise<IConstanteModel[]>((resolve) => {
            resolve([]);
          });
        }
      },
    },
  });

  const subProps: ConstanteListProps = {
    data: service.data,
    callbacks: {
      onChange(data) {
        return service.actions.notifyConstanteChange(data);
      },
    },
    loadings: service.loadings,
    itemAdapter: props.itemConstanteAdapter,
  };

  const subPropsRespiratoire: ConstanteListProps = {
    data: serviceRespiratoire.data,
    callbacks: {
      onChange(data) {
        return serviceRespiratoire.actions.notifyConstanteChange(data);
      },
    },
    loadings: serviceRespiratoire.loadings,
    itemAdapter: props.itemRespiratoireAdapter,
  };

  const WithLoadingAdapter = withLoading(ConstanteListVisiteContent);

  return (
    <>
      <FormWidget
        handleSave={() => {
          service.features.updateConstantes(service.data).then(() => {
            adsNotification.success({
              message: "Enregistrement constante réussi.",
            });
            serviceRespiratoire.features
              .updateConstantes(serviceRespiratoire.data)
              .then(() => {
                adsNotification.success({
                  message: "Enregistrement constante respiration réussi.",
                });
              });
          });
        }}
      >
        <WithLoadingAdapter
          title="Constantes"
          service={service}
          adapter={props.listAdapter}
          loadings={service.loadings}
          adapterProps={subProps}
        />
        <WithLoadingAdapter
          title="Constantes respiratoires"
          service={serviceRespiratoire}
          adapter={props.listRespiratoireAdapter}
          loadings={serviceRespiratoire.loadings}
          adapterProps={subPropsRespiratoire}
        />
      </FormWidget>
    </>
  );
}

type ConstanteListVisiteContentProps = {
  title: string;
  service: IConstanteListHookResult;
  adapter: FunctionComponent<ConstanteListProps>;
  adapterProps: ConstanteListProps;
} & ILoadingsProps;

function ConstanteListVisiteContent({
  service,
  adapterProps,
  adapter,
  title,
}: ConstanteListVisiteContentProps) {
  if (service.data === null || service.data?.length === 0) {
    return (
      <WidgetWrapper>
        <MarginTitleMain>{title}</MarginTitleMain>
        <br />
        <Alert
          showIcon
          description={
            "Aucunes " +
            title.toLocaleLowerCase() +
            " paramétrées dans votre système."
          }
          type="warning"
        />
      </WidgetWrapper>
    );
  }
  return (
    <>
      <MarginTitleMain>{title}</MarginTitleMain>
      {adapter(adapterProps)}
    </>
  );
}
