var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
import { TiersAdapter } from "../../..";
import { ConfigProduitSpecAdapter } from "../..";
var ConfigAppTiersAdapter = /** @class */ (function (_super) {
    __extends(ConfigAppTiersAdapter, _super);
    function ConfigAppTiersAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tiersAdapter = new TiersAdapter();
        _this.configProduitSpecAdapter = new ConfigProduitSpecAdapter();
        return _this;
    }
    ConfigAppTiersAdapter.prototype.toClient = function (src) {
        if (!src) {
            return null;
        }
        return {
            tabTypeConfig: src.tabTypeConfig,
            sTypeConfig: src.sTypeConfig,
            tabconfigProduitSpec: this.configProduitSpecAdapter.toClients(src.tabconfigProduitSpec),
            tiers: this.tiersAdapter.toClient(src.tiers),
        };
    };
    ConfigAppTiersAdapter.prototype.toAPI = function (src) {
        if (!src) {
            return null;
        }
        return {
            tabTypeConfig: src.tabTypeConfig,
            sTypeConfig: src.sTypeConfig,
            tabconfigProduitSpec: this.configProduitSpecAdapter.toAPIs(src.tabconfigProduitSpec),
            tiers: this.tiersAdapter.toAPI(src.tiers),
        };
    };
    return ConfigAppTiersAdapter;
}(Adapter));
export { ConfigAppTiersAdapter };
