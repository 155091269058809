var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
var DiagnostiqueEducatifDetailAdapter = /** @class */ (function (_super) {
    __extends(DiagnostiqueEducatifDetailAdapter, _super);
    function DiagnostiqueEducatifDetailAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DiagnostiqueEducatifDetailAdapter.prototype.toClient = function (src) {
        return {
            id: src.etp.etpID,
            texteParDefaut: src.etp.texteParDefaut,
            libelle: src.etp.libelle,
            type: src.etp.type,
            valeur: src.valeur,
        };
    };
    DiagnostiqueEducatifDetailAdapter.prototype.toAPI = function (src) {
        return {
            etp: {
                etpID: src.id,
                libelle: src.libelle,
                texteParDefaut: src.texteParDefaut,
                type: src.type,
            },
            valeur: src.valeur,
        };
    };
    return DiagnostiqueEducatifDetailAdapter;
}(Adapter));
export { DiagnostiqueEducatifDetailAdapter };
