export function removeDuplicateFromArray(array, equals) {
    const result = [];
    for (const item1 of array) {
        let found = false;
        for (const item2 of result) {
            if (equals(item1, item2)) {
                found = true;
            }
        }
        if (!found) {
            result.push(item1);
        }
    }
    return result;
}
