import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ConfigAppareilSpecTiersVisiteAPI } from "@ads/isadom-model";
import { UpdateAppareil } from "../../../interface/updateAppareil";
import { UpdateCompteur } from "../../../interface/updateCompteur";
import { IErrorBackEnd } from "../../../interface/erreur/api/errorBackEnd";
import config from "../../../config";
import { CompteurAPI } from "@ads/isadom-model";

interface FetchingParamsUpdate {
  IDVisite: number | undefined;
  data: UpdateAppareil;
}

interface FetchingParamsUpdateCompteur {
  IDVisite: number | undefined;
  IDAppareil: number | undefined;
  data: UpdateCompteur;
}

export const updateConfigurationAppareil = createAsyncThunk<
  ConfigAppareilSpecTiersVisiteAPI,
  FetchingParamsUpdate,
  {
    rejectValue: IErrorBackEnd;
  }
>(
  "configurations/update/appareil",
  async ({ IDVisite, data }: FetchingParamsUpdate, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${config.apiURL}visites/${IDVisite}/appareils`,
        {
          ...data,
          withCredentials: false
        }
      );
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 500) {
          return rejectWithValue({
            messages: [
              {
                message: "Erreur interne du serveur",
                idConcerne: "0",
                type: "A",
                code: ""
              }
            ],
            systemes: [
              {
                message: "Erreur interne du serveur",
                idConcerne: "0",
                type: "A",
                code: ""
              }
            ],
            metiers: [],
            alertes: []
          });
        }
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data as IErrorBackEnd);
      }
    }
  }
);

export const updateCompteurAppareil = createAsyncThunk<
  CompteurAPI,
  FetchingParamsUpdateCompteur,
  {
    rejectValue: IErrorBackEnd;
  }
>(
  "configuration/update/compteur",
  async (
    { IDVisite, IDAppareil, data }: FetchingParamsUpdateCompteur,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.patch(
        `${config.apiURL}visites/${IDVisite}/appareils/${IDAppareil}/compteurs`,
        { ...data, withCredentials: false }
      );
      return response.data;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 500) {
          return rejectWithValue({
            messages: [
              {
                message: "Erreur interne du serveur",
                idConcerne: "0",
                type: "A",
                code: ""
              }
            ],
            systemes: [
              {
                message: "Erreur interne du serveur",
                idConcerne: "0",
                type: "A",
                code: ""
              }
            ],
            metiers: [],
            alertes: []
          });
        }
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data as IErrorBackEnd);
      }
    }
  }
);
