export function natureProduit(id) {
    switch (id) {
        case "C":
            return { id: "C", libelle: "Consommable" };
        case "M":
            return { id: "M", libelle: "Materiel" };
        case "F":
            return { id: "F", libelle: "Forfaitaire" };
    }
}
