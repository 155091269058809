import { getDefaultApiInstance } from "@ads/react-common-module";
import { useSearchForm, } from "@ads/ui";
export function useSearchBonsLivraisons(input) {
    const api = getDefaultApiInstance();
    const searchService = useSearchForm({
        api: api,
        search: input.apiSearch,
        transformFormDataToSearchParam: input.transformFormDataToSearchParam,
    });
    return {
        formDataErrors: searchService.formDataErrors,
        isFormDataLoading: searchService.isFormDataLoading,
        notifyFormDataChanged: searchService.notifyFormDataChanged,
        registerListener: searchService.registerListener,
        submitSearch: searchService.submitSearch,
        formData: searchService.formData,
        searchResult: searchService.searchResult,
        transformFormDataToSearchParam: searchService.transformFormDataToSearchParam,
    };
}
