import { useEffect, useState } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import {
  MultiRollingPanelFixedTitle
} from "@ads/ui";
import {
  ChecklistListItemAdapter,
  BaseChecklistListDataNotifier,
  IBaseChecklistListDataListener,
  ChecklistModelMotifs,
  ChecklistList
} from "@ads/module-checklist";
import { useChecklistVisiteList } from "hooks/useChecklistListVisite";

const CheckListContainer = styled.div``;

const WrapperChecklistBody = styled.div`
  min-width: calc(100vw - 10rem);
  background: #fff;
  padding-top: 8rem;

  ${({ theme }) => theme.media.md} {
    min-width: 40rem;
  }
`;

const ChecklistBody = styled.div`
  direction: ltr;
`;

const WrapperAlert = styled.div`
  padding: 2rem 1rem;
`;

interface ChecklistProps {
  visiteID: number;
}

const PanelChecklist = ({ visiteID }: ChecklistProps) => {
  return <ChecklistVisite visiteID={visiteID} />;
};

function ChecklistVisite(props: { visiteID: number }) {
  const service = useChecklistVisiteList({
    visiteID: props.visiteID
  });

  const makeDataList = () => {
    const newDataList: ChecklistModelMotifs[] = [];
    service.data.forEach((e) => {
      const element: ChecklistModelMotifs = {
        nonRealisationList: service.motifsNonRealisationTache,
        reference: e.reference,
        referenceID: e.referenceID,
        taches: e.taches,
        libelle: e.libelle,
        compareTo: e.compareTo
      };
      newDataList.push(element);
    });
    return newDataList;
  };
  const [dataList, setDataList] = useState<ChecklistModelMotifs[]>(
    makeDataList()
  );

  useEffect(() => {
    setDataList(makeDataList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.data]);

  if (service.data.length === 0) {
    return (
      <CheckListContainer>
        <MultiRollingPanelFixedTitle title="Checklist" />
        <WrapperChecklistBody>
          <WrapperAlert>
            <Alert
              showIcon
              type="info"
              description={`Il n'y a pas de checklist pour ce type de visite.`}
            />
          </WrapperAlert>
        </WrapperChecklistBody>
      </CheckListContainer>
    );
  }
  return (
    <CheckListContainer>
      <MultiRollingPanelFixedTitle title="Checklist" />
      <WrapperChecklistBody>
        <ChecklistBody>
          <ChecklistList
            adapter={ChecklistListItemAdapter}
            data={dataList}
            notifier={new BaseChecklistListDataNotifier()}
            listener={
              {
                idListener: "listenerUI",
                onItemChange(item) {
                  service.modifyItem(item);
                }
              } as IBaseChecklistListDataListener
            }
          />
        </ChecklistBody>
      </WrapperChecklistBody>
    </CheckListContainer>
  );
}

export default PanelChecklist;
