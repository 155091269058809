import { UploaderDocument, useUploaderDocumentVisite } from "@ads/module-ged";
import {
  MultiRollingPanelFixedTitle
} from "@ads/ui";
import styled from "styled-components";

const GEDContainer = styled.div`
  max-width: calc(100vw - 10rem);
`;

const WrapperGEDBody = styled.div`
  overflow-y: auto;
  background: #fff;
  padding-top: 8rem;

  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 6px;
    padding-right: 5px;
    &-thumb {
      transition: all 3s;
      background-color: #dbdbdb;
      border-radius: 5px;
    }
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: #9e9e9e;
    }
  }
`;

const GEDBody = styled.div`
  direction: ltr;
  padding: 1rem;
`;



interface PanelGedProps {
  visiteID: number;
}

export const PanelGED = ({ visiteID }: PanelGedProps) => {
  const serviceGED = useUploaderDocumentVisite({ visiteId: visiteID });

  return (
    <GEDContainer>
      <MultiRollingPanelFixedTitle title="GED" />
      <WrapperGEDBody>
        <GEDBody>
          <UploaderDocument {...serviceGED} />
        </GEDBody>
      </WrapperGEDBody>
    </GEDContainer>
  );
};
