import { LocalisationModel } from "@ads/isadom-model";
import { FormItemWrapper, itemSelect, Label, Select } from "@ads/ui";
import { ISelectLocalisationHookService } from "../hook/interfaces/ISelectLocalisationHookService";

export interface SelectLocalisationProps {
  label: string;
  services: ISelectLocalisationHookService;
}

export function SelectLocalisation({
  label,
  services,
}: SelectLocalisationProps) {
  function transformDataToSelectOption(
    localisation: LocalisationModel
  ): itemSelect {
    return {
      value: services.getKeyFromData(localisation).toString(),
      content: localisation.libelle,
    };
  }

  const handleOnSelectOption = (value: string) => {
    services.notifyDataSelected(value);
  };

  return (
    <FormItemWrapper direction="vertical">
      <Label htmlFor="SelectLocalisation">{label}</Label>
      <Select
        id="SelectLocalisation"
        selectMode="light"
        name="SelectLocalisation"
        itemsSelect={services.data.map((localisation) =>
          transformDataToSelectOption(localisation)
        )}
        value={services.selectedData.map(
          (localisation) => localisation.localisationId.toString()
        )}
        filterOption={(
          input: string,
          option: { label: { toString: () => string } }
        ) => {
          if (option?.label && !(option.label instanceof Array)) {
            return option.label
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase());
          } else {
            return false;
          }
        }}
        mode={services.typeSelection === "multiple" ? "multiple" : undefined}
        onSelect={(value) => handleOnSelectOption(value)}
      />
    </FormItemWrapper>
  );
}
