import { BlocNoteModel } from "@ads/isadom-model";
import {
  IBaseBlocNoteListDataListener,
  BlocNoteListAdapterProps,
  IBaseBlocNoteListDataNotifier,
  BaseBlocNoteListDataNotifier,
  BlocNoteItemAdapter,
} from "@ads/module-blocnote";
import { IListProps } from "@ads/ui";

export interface BlocNoteListVisiteProps<
  BlocNoteModelModel extends BlocNoteModel
  > extends IListProps<
  BlocNoteModelModel,
  IBaseBlocNoteListDataListener,
  BlocNoteListAdapterProps<BlocNoteModelModel>,
  IBaseBlocNoteListDataNotifier
  > {
  isDeletable: (item: BlocNoteModelModel) => boolean;
}

export function BlocNoteList<BlocNoteModelModel extends BlocNoteModel>(
  props: BlocNoteListVisiteProps<BlocNoteModelModel>
) {
  const notifier = new BaseBlocNoteListDataNotifier();
  if (notifier && props.listener) {
    notifier.registerListener(props.listener);
  }

  return (
    <>
      {props.data.map((value) => {
        const item: BlocNoteListAdapterProps<BlocNoteModelModel> = {
          data: value,
          notifier: notifier,
          isDeletable: props.isDeletable,
        };
        return (
          <div key={item.data.id}>
            <BlocNoteItemAdapter
              isDeletable={item.isDeletable}
              notifier={item.notifier}
              data={item.data}
            />
          </div>
        );
      })}
    </>
  );
}
