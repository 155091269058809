import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Input } from "antd";
const NumLotInput = styled(Input) `
  min-width: 3em;
  width: 6em;
  text-align: center;

  ${({ theme }) => theme.media.lg} {
    width: 12rem;
  }
`;
const ContainerForms = styled.form `
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.lg} {
    justify-content: flex-end;
  }
`;
export const FormRetourVisiteNumLot = ({ retour, refreshData, }) => {
    var _a, _b;
    const handleChange = (e) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const sLot = e.target.value;
        const lot = {
            iPKProduitDMCLot: ((_a = retour.lot) === null || _a === void 0 ? void 0 : _a.iPKProduitDMCLot) || -1,
            iFKProduit: ((_b = retour.lot) === null || _b === void 0 ? void 0 : _b.iFKProduit) || -1,
            sLot,
            dLimiteConso: ((_c = retour.lot) === null || _c === void 0 ? void 0 : _c.dLimiteConso) || "",
            dLimiteUtilisation: ((_d = retour.lot) === null || _d === void 0 ? void 0 : _d.dLimiteUtilisation) || "",
            iPKEmplacement: ((_e = retour.lot) === null || _e === void 0 ? void 0 : _e.iPKEmplacement) || -1,
            sCodeEmplacement: ((_f = retour.lot) === null || _f === void 0 ? void 0 : _f.sCodeEmplacement) || "",
            iPKStock: ((_g = retour.lot) === null || _g === void 0 ? void 0 : _g.iPKStock) || -1,
            iStockTheorique: ((_h = retour.lot) === null || _h === void 0 ? void 0 : _h.iStockTheorique) || -1,
        };
        const data = Object.assign(Object.assign({}, retour), { lot });
        refreshData(data);
    };
    const uniqueID = `slot-${retour.ligneRetourID}-${(_a = retour.lot) === null || _a === void 0 ? void 0 : _a.sLot}`;
    return (_jsx(ContainerForms, { children: _jsx(NumLotInput, { type: "text", onChange: handleChange, placeholder: "N\u00B0 de lot", id: uniqueID, value: ((_b = retour.lot) === null || _b === void 0 ? void 0 : _b.sLot) || "", name: uniqueID }, void 0) }, void 0));
};
