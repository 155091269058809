import {
  EquipeAdapter,
  EquipeAPI,
  EquipeModel,
  HttpResponseAPI,
  HttpResponseModel,
} from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiGetEquipesPreparation(): Promise<
  HttpResponseModel<EquipeModel[]>
> {
  try {
    const api = getDefaultApiInstance();

    if (!api) {
      throw new Error("La configuration d'api n'est pas initialisée");
    }

    const adapter = new EquipeAdapter();
    const { data, messages }: HttpResponseAPI<EquipeAPI[]> = await apiGetV2(
      api,
      `/parametres/equipes-preparation`,
      apiIsadomErrorStrategy
    );

    return { data: adapter.toClients(data), messages };
  } catch (err: any) {
    return new Promise((_resolve, reject) => {
      reject(err.message);
    });
  }
}
