import { VisiteAdapter } from "@ads/isadom-model";
import { Alert } from "antd";
import ProtectedRoute from "components/core/routes/ProtectedRoute";
import { RouteParam } from "interface/core/RouteParam";
import { Redirect, Switch, useParams } from "react-router-dom";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";
import { TemplateEnregistrement } from "./ui/templates/TemplateEnregistrement";
import { TemplateObservationsInternes } from "./ui/templates/TemplateObservationsInternes";

export function RouterFinVisite() {
  const { iPKVisite } = useParams<RouteParam>();
  const { data: visite } = useGetVisiteQuery(+iPKVisite);

  if (!visite) {
    return (
      <Alert
        type="error"
        message="Erreur lors de la visite. Veuillez recharger votre page, si le problème persiste, veuillez contacter votre administrateur."
      />
    );
  }

  const visiteModel = new VisiteAdapter().toClient(visite);

  return (
    <Switch>
      <Redirect
        exact
        from="/visites/:iPKVisite/fin-de-visite"
        to="/visites/:iPKVisite/fin-de-visite/observations-internes"
      />
      <ProtectedRoute
        exact
        path="/visites/:iPKVisite/fin-de-visite/observations-internes"
      >
        <TemplateObservationsInternes visite={visiteModel} />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/visites/:iPKVisite/fin-de-visite/enregistrement"
      >
        <TemplateEnregistrement visite={visiteModel} />
      </ProtectedRoute>
    </Switch>
  );
}
