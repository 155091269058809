import styled from "styled-components";
import { PrimaryButton, Icons } from "@ads/ui";

import { EvenementRGPDModel } from "@ads/isadom-model";

import {
  EvenementRGPDDetails,
  EvenementRGPDInformations,
} from "../../molecules/evenementRGPD";

const EditButtonWrapper = styled.div``;

const Content = styled.div`
  display: block;
  width: 100%;
  max-width: 80rem;

  ${({ theme }) => theme.media.md} {
    display: flex;
  }
`;

interface EvenementRGPDFormProps {
  evenement?: EvenementRGPDModel;
}

export const EvenementRGPDInformationsGenerales = ({
  evenement,
}: EvenementRGPDFormProps) => {
  return (
    <>
      <Content>
        <EvenementRGPDInformations
          informations={evenement ? evenement.informations : ""}
        />
        <EvenementRGPDDetails evenement={evenement} />
      </Content>
      <EditButtonWrapper>
        <PrimaryButton
          as="button"
          iconPosition="left"
          icon={Icons.InsertText}
          type="button"
          label="Editer"
          buttonStyle="primary"
          size="S"
        />
      </EditButtonWrapper>
    </>
  );
};
