import {
  QuestionnaireDetailMolecule,
  QuestionnaireDetailTiersTemplate,
  QuestionnaireDetailVisiteTemplate
} from "@ads/module-grille";
import { Alert } from "antd";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";

export interface PageQuestionnaireDetailProps {
  disableActions?: boolean;
  context: "visite" | "tiers";
}

export function PageQuestionnaireDetail(props: PageQuestionnaireDetailProps) {
  const { iPKVisite, questionnaireID } = useParams<RouteParam>();
  if (!questionnaireID) {
    return (
      <Alert
        message="Error"
        showIcon
        description="Une erreur est survenue pendant le chargement du questionnaire."
        type="error"
      />
    );
  } else if (props.context === "visite") {
    return (
      <QuestionnaireDetailVisiteTemplate
        disableActions={props.disableActions}
        children={QuestionnaireDetailMolecule}
        visiteID={parseInt(iPKVisite)}
        questionnaireID={parseInt(questionnaireID)}
      />
    );
  } else if (props.context === "tiers") {
    const { data: visite } = useGetVisiteQuery(+iPKVisite);
    if (visite) {
      return (
        <QuestionnaireDetailTiersTemplate
          tiersID={visite?.tiersVisite.iPKTiers}
          disableActions={props.disableActions}
          children={QuestionnaireDetailMolecule}
          questionnaireID={parseInt(questionnaireID)}
        />
      );
    } else {
      return (
        <Alert
          message="Error"
          showIcon
          description="Une erreur est survenue pendant le chargement du questionnaire."
          type="error"
        />
      );
    }
  } else {
    return (
      <Alert
        message="Error"
        showIcon
        description="Une erreur est survenue pendant le chargement du questionnaire."
        type="error"
      />
    );
  }
}
