import { apiGet } from "@ads/react-common-module";
import { TypeProduitAdapter } from "@ads/isadom-model";
export var NatureProduit;
(function (NatureProduit) {
    NatureProduit["MATERIEL"] = "M";
    NatureProduit["CONSOMMABLE"] = "C";
    NatureProduit["FORFAITAIRE"] = "F";
})(NatureProduit || (NatureProduit = {}));
export const apiGetTypeProduitByNature = (api, nature) => {
    if (api) {
        const adapter = new TypeProduitAdapter();
        return apiGet(api, `/natures-produits/${nature}/types-produits`, adapter);
    }
    return new Promise((_, reject) => {
        reject("La configuration api est null");
    });
};
