var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useChampsList } from "../useChampGroup";
import { BaseChampListDataNotifier } from "../../../.generated/list/ui/interfaces/impl/BaseChampListDataNotifier";
import { ApplicationContext } from "@ads/react-common-module/lib/esm/application-context";
import { ChampAdapter } from "../ChampAdapter";
import { Grid, GridItem, GridLong } from "./style";
import { TypeChampsProps } from "../../champs";
export function ChampGroup(props) {
    ApplicationContext.instantiate(null, null);
    const inputService = {
        data: props.data.champs,
        isEquals: function (itemCompare, itemToCompare) {
            return itemCompare.id === itemToCompare.id;
        },
    };
    const service = useChampsList(inputService);
    service.registerListener({
        idListener: "@ads/ChampGroup",
        onBeforeDeleteItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onAfterDeleteItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onBeforeAddItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onAfterAddItem: function (_item) {
            throw new Error("Function not implemented.");
        },
        onBeforemModifyItem: function (item) {
            return __awaiter(this, void 0, void 0, function* () {
                if (item !== null) {
                    const newGrp = props.data;
                    const index = newGrp.champs.findIndex((value) => value.id === item.id);
                    if (index !== -1) {
                        newGrp.champs[index] = item;
                    }
                    if (props.callbacks.onChange) {
                        props.callbacks.onChange(newGrp);
                    }
                }
                return item;
            });
        },
        onAfterModifyItem: function (_item) { },
        onBeforeDeleteAll: function () {
            throw new Error("Function not implemented.");
        },
        onAfterDeleteAll: function () {
            throw new Error("Function not implemented.");
        },
    });
    const notifier = new BaseChampListDataNotifier();
    notifier.registerListener({
        idListener: "@ads/GroupDataListener",
        onItemChange: (item) => {
            service.modifyItem(item);
        },
        onAddChampClick: {},
    });
    return (_jsx(Grid, Object.assign({ className: "grid" }, { children: props.data.champs.map((champ, index) => {
            if (champ.typeChamp &&
                champ.typeChamp.libelle === TypeChampsProps.Long) {
                return (_jsx(GridLong, Object.assign({ className: "grid-long" }, { children: _jsx(ChampAdapter, { data: champ, notifier: notifier }, void 0) }), index));
            }
            else {
                return (_jsx(GridItem, Object.assign({ className: "grid-item" }, { children: _jsx(ChampAdapter, { data: champ, notifier: notifier }, void 0) }), index));
            }
        }) }), void 0));
}
