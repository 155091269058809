import styled from "styled-components";
export const ConsommableConfigurationDetailZone = styled.div `
  margin-bottom: 2rem;
  padding: 2rem;
  font-size: 1.8rem;
  font-weight: 500;
  background-color: ${(props) => props.theme.colors.neutral[130]};
  border-radius: 1.3rem;

  ${({ theme }) => theme.media.md} {
    display: grid;
    grid-template-columns: 70% 30%;
    max-width: 840px;
  }
`;
