var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TiersAdapter } from "../..";
import { Adapter } from "../../_adapter";
import { TypeEvenementAdapter } from "./TypeEvenementAdapter";
var EvenementAdapter = /** @class */ (function (_super) {
    __extends(EvenementAdapter, _super);
    function EvenementAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tiersAdapter = new TiersAdapter();
        _this.typeEvenementAdapter = new TypeEvenementAdapter();
        return _this;
    }
    EvenementAdapter.prototype.toClient = function (src) {
        return {
            ID: src.evenementID,
            evenementVisiteID: src.evenementVisiteID,
            typeEvenement: src.typeEvenement &&
                this.typeEvenementAdapter.toClient(src.typeEvenement),
            dateDebut: src.dDebut,
            dateFin: src.dFin,
            estAnnule: src.bAnnule,
            tiers: src.tiers && this.tiersAdapter.toClient(src.tiers),
            informations: src.sInformation,
        };
    };
    EvenementAdapter.prototype.toAPI = function (src) {
        return {
            evenementID: src.ID,
            evenementVisiteID: src.evenementVisiteID,
            typeEvenement: src.typeEvenement && this.typeEvenementAdapter.toAPI(src.typeEvenement),
            dDebut: src.dateDebut,
            dFin: src.dateFin,
            bAnnule: src.estAnnule,
            tiers: src.tiers && this.tiersAdapter.toAPI(src.tiers),
            sInformation: src.informations,
        };
    };
    return EvenementAdapter;
}(Adapter));
export { EvenementAdapter };
