import React from "react";
import { natureProduit } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { DEFAULT_SEARCH_GUARD_LENGTH, ScannerSearch, SearchAdvanced, useGetAdvancedSearchProps, } from "@ads/ui";
import { TableFormSelectConsommableAdapter, tableSelectionConsommableColumns, } from "./";
import { apiSearchConsommables } from "../../../../consommable/api/apiSearchConsommables";
export function SelectionConsommable({ config, hasScanner, onConsommablesSelected, }) {
    const api = getDefaultApiInstance();
    /**
     * @description Récupération de l'identifiant d'un appareil
     * @param item Item sur lequel il faut récupérer l'id
     * @returns L'id de l'item passé en paramètre
     */
    const getKeyFromData = (item) => {
        return item.produitID;
    };
    /**
     * @description Permet de transformer un résultat de recherche en option pour le select simplifié
     * @param item Item à convertir
     * @returns L'item convertir en option pour le select simplifié
     */
    const transformSearchResultsToSelectOptions = (item) => `${item.libelle} ${item.refFournissseurPrincipal}`;
    /**
     * @description Permet de transformer les data du formulaire de recherche avancée en paramètres pour la recherche
     * @param formData Données du formulaire de recherche avancée
     * @returns Les paramètres à utiliser pour la recherche
     */
    const transformModalFormDataToSearchParam = (formData) => {
        const natures = new Array();
        natures.push(formData.data.nature.id);
        return {
            natures: formData.data.nature ? natures : undefined,
            typeProduitID: formData.data.typeProduit
                ? formData.data.typeProduit.typeProduitId
                : "",
            familleID: formData.data.famille ? formData.data.famille.idFamille : "",
            sousFamilleID: formData.data.sousFamille
                ? formData.data.sousFamille.sousFamilleId
                : "",
            typePrestationID: formData.data.typePrestationID
                ? formData.data.typePrestationID.id
                : "",
            ref_lib: formData.data.refOrLib,
        };
    };
    /**
     * @description Permet de transformer les data du formulaire de recherche simplifié en paramètre pour la recherche
     * @param formData Donnée du formulaire de recherche simplifié
     * @returns Les paramètres à utiliser pour la recherche
     */
    const transformSelectFormDataToSearchParam = (formData) => {
        return {
            natures: [natureProduit("C").id],
            typeProduitID: "",
            familleID: "",
            sousFamilleID: "",
            typePrestationID: "",
            ref_lib: formData.trim(),
        };
    };
    /**
     * Permet de préciser la config par défaut du formulaire de recherche avancée
     */
    const defaultModalFormData = {
        config: config,
    };
    const searchAdvancedInput = useGetAdvancedSearchProps({
        hookParams: {
            api: api,
            defaultModalFormData: defaultModalFormData,
            getKeyFromData,
            typeSelection: "simple",
            modalSearch: apiSearchConsommables,
            selectSearch: apiSearchConsommables,
            transformModalFormDataToSearchParam,
            transformSelectFormDataToSearchParam,
        },
        tableSearchConfiguration: {
            columns: tableSelectionConsommableColumns,
            formAdapter: TableFormSelectConsommableAdapter,
            modalTitleAdapter: "Recherche d'un consommable",
        },
        selectSearchConfiguration: {
            transformSearchResultsToSelectOptions,
            searchGuardLength: DEFAULT_SEARCH_GUARD_LENGTH,
            placeholder: "Code ou N° série",
            widget: hasScanner ? ScannerSearch : undefined,
        },
        onSubmit: onConsommablesSelected,
    });
    return React.createElement(SearchAdvanced, Object.assign({}, searchAdvancedInput));
}
