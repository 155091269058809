import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { ButtonResetRetoursConso } from "../atoms/ButtonResetRetoursConso";
const Wrapper = styled.div `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 0 2.6rem;

  ${({ theme }) => theme.media.lg} {
    gap: 0;
    flex-direction: row;
  } ;
`;
const WrapperTitre = styled.h1 `
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 0 0 1rem;
  font-weight: normal;
  font-size: 2rem;
  line-height: 5rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2.2rem;
  }
`;
const WrapperActions = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    gap: 2rem;
  } ;
`;
export const RetoursConsommablesListHeader = ({ visiteID, nbConso, SelectionRetourConsommable, resetData, isLoadingReset, addData, }) => {
    const onSelect = (produits) => {
        addData(produits[0]);
    };
    return (_jsxs(Wrapper, { children: [_jsxs(WrapperTitre, { children: [nbConso === 0 && `Aucun consommable`, nbConso === 1 && `1 consommable`, nbConso > 1 && `${nbConso} consommables`] }, void 0), _jsxs(WrapperActions, { children: [_jsx(SelectionRetourConsommable, { onConsommableSelected: onSelect }, void 0), nbConso > 0 && (_jsx(ButtonResetRetoursConso, { resetData: resetData, isLoadingReset: isLoadingReset, visiteID: visiteID }, void 0))] }, void 0)] }, void 0));
};
