import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Checkbox as CheckboxANT, Input, Select as SelectANT } from "antd";
import { EtatRealisation, } from "@ads/isadom-model";
const TacheItemAdapterContent = styled.div `
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-weight: 600;
  font-size: 1.6rem;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.neutral[130]};
  margin: 0 1rem;
  padding: 0 1rem;
`;
const TacheItemAdapterContentLibele = styled.div `
  margin-top: 2rem;
  width: 100%;
  font-family: "Poppins", sans-serif;
`;
const Checkbox = styled(CheckboxANT) `
  padding: 1.5rem 1rem 1.5rem 0;

  .ant-checkbox-inner {
    background-color: ${({ checked, theme }) => checked ? "auto" : theme.colors.neutral[100]};
    border: 1px solid
      ${(props) => props.theme.colors.neutral[160]};
  }
`;
const WrapperCommentBox = styled.div `
  margin-bottom: 2.4rem;
`;
const Select = styled(SelectANT) `
  padding-bottom: 1.5rem;
  width: 100%;

  .ant-select-selector {
    background-color: #fff !important;
  }
`;
const { Option } = Select;
const TacheItemInfoContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
export function TacheItemAdapter({ value, notifyOK, notifyNR, notifyTextUpdate, notifyNRJustif, typesNR, }) {
    const task = value;
    const checkOK = task.etatRealisation === EtatRealisation.REALISE;
    const checkNR = task.etatRealisation === EtatRealisation.NON_REALISE;
    const typeTache = task.typeTache; // Rien compris aux interfaces/classes..., typeTache est bien de type TypeTacheVisiteModel (toutes les propriétés sont bien présentes)
    return (_jsxs(TacheItemAdapterContent, { children: [_jsxs(TacheItemInfoContainer, { children: [_jsx(TacheItemAdapterContentLibele, { children: typeTache.libelle }, void 0), !typeTache.isInformatif && (_jsxs("div", { children: [_jsx(Checkbox, Object.assign({ onChange: (e) => notifyOK(e.target.checked), checked: checkOK }, { children: "OK" }), void 0), _jsx(Checkbox, Object.assign({ onChange: (e) => notifyNR(e.target.checked), checked: checkNR }, { children: "NR" }), void 0)] }, void 0))] }, void 0), _jsxs(WrapperCommentBox, { children: [task.etatRealisation === EtatRealisation.NON_REALISE && (_jsx(Select, Object.assign({ id: "typeTacheID", showSearch: true, allowClear: true, defaultValue: task.motifNonRealisation.sCode, onSelect: notifyNRJustif, placeholder: "S\u00E9lectionnez la raison de la non-r\u00E9alisation" }, { children: typesNR.map((motifNR) => (_jsx(Option, Object.assign({ value: motifNR.sCode }, { children: motifNR.sLibelle }), `${motifNR.sCode}`))) }), void 0)), _jsx(Input, { defaultValue: task.information, placeholder: "Commentaires", onChange: (e) => {
                            e.preventDefault();
                            notifyTextUpdate(e.target.value);
                        } }, void 0)] }, void 0)] }, task.id));
}
