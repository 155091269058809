import { motion } from "framer-motion";
import { FC } from "react";
import { pageVariants, pageTransition } from "./pageTransition";

const WrapperExitAnimation: FC = ({ children }): JSX.Element => {
  return (
    <motion.div exit="exit" variants={pageVariants} transition={pageTransition}>
      {children}
    </motion.div>
  );
};

export default WrapperExitAnimation;
