import { jsx as _jsx } from "react/jsx-runtime";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { AdvancedSearchSelect, DEFAULT_SEARCH_GUARD_LENGTH, useGetAdvancedSearchProps, } from "@ads/ui";
import { apiSearchTiersV2, } from "../../../api/apiSearchTiersV2";
import { FormSelectionTiersAdapter } from "./FormSelectionTiersAdapter";
import { columns } from "./SelectionTiers";
export function defaultTransformSearchResultsToSelectOptions(item) {
    let value = `${item.nom} ${item.prenom}`;
    if (item.codeTiers !== undefined &&
        item.codeTiers !== null &&
        item.codeTiers !== "") {
        value += ` (${item.codeTiers})`;
    }
    return value;
}
/**
 * @deprecated since 1.0.0 prefer used ISelectionTiersV3Props
 * @param param0
 * @returns
 */
export function SelectionTiersV2({ formConfig, selectionConfig, onTiersSelected, transformSearchResultsToSelectOptions = defaultTransformSearchResultsToSelectOptions, }) {
    const api = getDefaultApiInstance();
    /**
     * @description Récupération de l'identifiant d'un tiers
     * @param item Item sur lequel il faut récupérer l'id
     * @returns L'id de l'item passé en paramètre
     */
    const getKeyFromData = (item) => {
        return item.tiersID;
    };
    /**
     * @description Permet de transformer les data du formulaire de recherche avancée en paramètre pour la recherche
     * @param formData Donnée du formulaire de recherche avancée
     * @returns Les paramètres à utilisé pour la recherche
     */
    const transformModalFormDataToSearchParam = (formData) => {
        return {
            qui: formData.data.qui,
            tTypetiersID: formData.data.typesTiers.map((value) => value.typeTiersID),
            ville_cp: formData.data.ou,
        };
    };
    /**
     * @description Permet de transformer les data du formulaire de recherche simplifié en paramètre pour la recherche
     * @param formData Donnée du formulaire de recherche simplifié
     * @returns Les paramètres à utilisé pour la recherche
     */
    const transformSelectFormDataToSearchParam = (formData) => {
        return {
            qui: formData.trim(),
            tTypetiersID: formConfig.typesTiersConfig.defaultSelectedData.map((value) => value.typeTiersID),
            ville_cp: formConfig.ouConfig.defaultData,
        };
    };
    /**
     * Permet de préciser la config par défaut du formulaire de recherche avancée
     */
    const defaultModalFormData = {
        config: formConfig,
        data: {
            typesTiers: formConfig.typesTiersConfig.defaultSelectedData,
            ou: formConfig.ouConfig.defaultData,
            qui: formConfig.quiConfig.defaultData,
        },
    };
    const searchAdvancedInput = useGetAdvancedSearchProps({
        hookParams: {
            api,
            defaulSelectedData: selectionConfig.defaultSelectedData,
            defaultModalFormData: defaultModalFormData,
            getKeyFromData,
            typeSelection: selectionConfig.typeSelection,
            modalSearch: apiSearchTiersV2,
            selectSearch: apiSearchTiersV2,
            transformModalFormDataToSearchParam,
            transformSelectFormDataToSearchParam,
        },
        tableSearchConfiguration: {
            columns: columns,
            formAdapter: FormSelectionTiersAdapter,
            modalTitleAdapter: "Recherche d'un tiers",
        },
        selectSearchConfiguration: {
            transformSearchResultsToSelectOptions,
            searchGuardLength: DEFAULT_SEARCH_GUARD_LENGTH,
            placeholder: "Nom ou prénom",
        },
        onSubmit: onTiersSelected,
    });
    return _jsx(AdvancedSearchSelect, Object.assign({}, searchAdvancedInput), void 0);
}
