import styled from "styled-components";
import FilterFamille from "./FilterFamille";
import FilterProduit from "./FilterProduit";

const WrapperFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2rem;
  margin-top: 2rem;
  flex-direction: column;

  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
  }

  ${({ theme }) => theme.media.md} {
    justify-content: space-between;
    margin-top: 0;
  }
`;
const FilterCommande = () => {
  return (
    <WrapperFilter>
      <FilterFamille />
      <FilterProduit />
    </WrapperFilter>
  );
};

export default FilterCommande;
