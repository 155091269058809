import { INavigationItem } from "@ads/ui";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";

const NavigationEvenement = () => {
  const { iPKVisite, tabActive } = useParams<RouteParam>();

  const navItems: INavigationItem[] = [
    {
      to: `/visites/${iPKVisite}/evenements/irelm-teleobs`,
      label: "IRELM/TELEOBS",
      active: tabActive === "irelm-teleobs",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/evenements/non-reserves`,
      label: "Évènements non réservés",
      active: tabActive === "non-reserves",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/evenements/rgpd`,
      label: "RGPD",
      active: tabActive === "rgpd",
      visible: true
    }
  ];

  return (
    <NavigationPrimary
      navItems={navItems || []}
      isOpen={false}
      isLargeNav={false}
    />
  );
};

export default NavigationEvenement;
