import { Select } from "@ads/ui";
import styled from "styled-components";

const FilterFamilleWrapper = styled.div``;

const Label = styled.h4``;
const FilterFamille = () => {
  return (
    <FilterFamilleWrapper>
      <Label>Ajouter une famille</Label>
      <Select id="" name="" selectMode="light" />
    </FilterFamilleWrapper>
  );
};

export default FilterFamille;
