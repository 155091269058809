import { Select } from "antd";
// import styled from "styled-components";
import { EvenementDTOModel } from "@ads/isadom-model";
import { FiltreWrapper } from "@ads/ui";
interface EvenementListFilterProps {
  data: EvenementDTOModel[];
  setEvenementListFilters: React.Dispatch<
    React.SetStateAction<EvenementDTOModel[]>
  >;
}

export const EvenementListFilter = ({
  data,
  setEvenementListFilters,
}: EvenementListFilterProps) => {
  const typesEvenementOptions: { label: string; value: string }[] = [];

  data.forEach((evenement) => {
    if (
      !typesEvenementOptions.find(
        (option) => option.value === evenement.typeEvenementID
      )
    ) {
      typesEvenementOptions.push({
        value: evenement.typeEvenementID,
        label: evenement.typeEvenementID,
      });
    }
  });

  const handleChange = (value: string) => {
    if (typeof value === "undefined") {
      setEvenementListFilters(data);
    } else {
      setEvenementListFilters(data.filter((t) => t.typeEvenementID === value));
    }
  };

  return (
    <FiltreWrapper libelle="Filtrer par type d'évènements">
      <Select
        optionFilterProp="children"
        options={typesEvenementOptions}
        showSearch
        allowClear
        onChange={handleChange}
        disabled={data?.length === 0}
      />
    </FiltreWrapper>
  );
};;;
