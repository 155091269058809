import { useState } from "react";
import axios from "axios";
import config from "../config";
import { IErrorBackEnd } from "interface/erreur/api/errorBackEnd";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useDeleteVisite = (visiteId: number) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errors, setErrors] = useState<any>();

  const handleDelete = () => {
    axios
      .delete(`${config.apiURL}visites/${visiteId}`)
      .then(() => {
        setIsSuccess(true);
        setHasErrors(false);
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          const error: IErrorBackEnd = {
            metiers: [
              {
                type: "S",
                code: "",
                message: "Une erreur inatendue est survenue",
                idConcerne: ""
              }
            ]
          };
          setErrors(error);
        } else if (error.response) {
          setErrors(error.response.data);
        } else {
          const error: IErrorBackEnd = {
            metiers: [
              {
                type: "S",
                code: "",
                message: "Une erreur interne est survenue",
                idConcerne: ""
              }
            ]
          };
          setErrors(error);
        }
        setHasErrors(true);
        setIsSuccess(false);
      })
      .finally(() => setIsDeleting(false));
  };

  return { isDeleting, isSuccess, hasErrors, errors, handleDelete };
};

export default useDeleteVisite;
