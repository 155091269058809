import api from "api";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  GetTourneeProps,
  Tournee
} from "../../../interfaces/feuille-de-route.interfaces";

import config from "../../../config";

export const getTournee = createAsyncThunk(
  "feuille-de-route/getTournee",
  async (
    { iPKIntervenant, dDate, selection }: GetTourneeProps,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.get(
        `${config.apiURL}intervenants/${iPKIntervenant}/tournees/${dDate}?dto=1`
      );
      return { ...response.data, iPKIntervenant, dDate, selection } as Tournee;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
