import { TypePrestationAdapter } from "@ads/isadom-model";
import { apiPost, getDefaultApiInstance } from "@ads/react-common-module";
export function apiGetAllTypePrestations() {
    const api = getDefaultApiInstance();
    if (api) {
        const adapter = new TypePrestationAdapter();
        //Appel avec un objet vide afin de récupérer tous les types de prestations en
        //attendant d'avoir la route du back
        return apiPost(api, "/types-prestations/recherche", adapter, {});
    }
    throw new Error("La config d'api par défaut n'est pas initialisé");
}
