export const setCookie = (
  cname: string,
  cvalue: string,
  exdays: number
): void => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname: string): string | null => {
  const v = document.cookie.match("(^|;) ?" + cname + "=([^;]*)(;|$)");
  return v ? decodeURIComponent(v[2]) : null;
};

export const deleteCookie = (cname: string): void => {
  document.cookie = cname + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
