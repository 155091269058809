// import { ConsommableModel } from "@ads/isadom-model";
import { apiDelete } from "@ads/react-common-module";
export const apiRetireConsommableConfigurationVisite = (api, visiteID, configCsoVisiteID) => {
    if (api) {
        return apiDelete(api, `/visites/${visiteID}/consommables/${configCsoVisiteID}`);
    }
    return new Promise((_, reject) => {
        reject("La configuration api est nulle");
    });
};
