import { useState } from "react";
import styled from "styled-components";
import { Table, Tooltip, Pagination, Alert } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import { IHistoriqueTab } from "interface/produit/appareil/historique";
import WrapperAlert from "../../wrappers/WrapperAlert";

const WrapperAlertMobile = styled(WrapperAlert)`
  display: inherit;

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: inherit;
  margin-top: 3rem;

  ${({ theme }) => theme.media.md} {
    display: none;
  }

  .ant-pagination {
    display: none;
  }

  .ant-table-wrapper {
    padding-bottom: 2rem;
  }

  .ant-table-thead {
    display: none;
  }

  .columnName {
    font-weight: bold;
    word-wrap: break-word;
    word-break: break-word;
  }

  .produitValue {
    word-wrap: break-word;
    word-break: break-word;
  }
`;

const WrapperIconAntd = styled.span`
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 2rem;
  transition: all 0.3s linear;
  svg {
    path {
      color: ${({ theme }) => theme.colors.neutral[180]};
    }
    &:hover {
      path {
        color: ${({ theme }) => theme.colors.primary[100]};
      }
    }
  }
`;

const WrapperPagination = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PaginationANTD = styled(Pagination)`
  ${({ theme }) => theme.media.md} {
    display: none;
  }
`;

const columns = [
  {
    title: "",
    dataIndex: "columnName",
    key: "columnName",
    width: 100,
    className: "columnName",
  },
  {
    title: "",
    dataIndex: "produitValue",
    key: "produitValue",
    width: 100,
    className: "produitValue",
  },
];

const columnProduitData = [
  "Ref. produit",
  "Famille",
  "Modèle",
  "Prépondérance",
  "Installation",
  "Rappel commentaires Dm",
  "Retrait",
  "Rappel localisation",
  "Affectation",
];

const IconRender = (value: any, i: number) => {
  if (value && (i === 5 || i === 7)) {
    return (
      <div className="center-cell">
        <Tooltip placement="topLeft" title={value}>
          <WrapperIconAntd className="commentaires">
            <CommentOutlined />
          </WrapperIconAntd>
        </Tooltip>
      </div>
    );
  }
  return value;
};

const getDataTable = (historique: any) => {
  const dataTable = [];
  let i = 0;
  for (const [value] of Object.entries(historique)) {
    dataTable.push({
      key: i,
      columnName: columnProduitData[i],
      produitValue: IconRender(value, i),
    });
    i++;
  }
  i = 0;
  return dataTable;
};

const produitsPerPage = 4;

interface MobileHistoriqueProps {
  historique?: Array<IHistoriqueTab>;
}

const MobileHistorique = ({ historique }: MobileHistoriqueProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastProduit = currentPage * produitsPerPage;
  const indexOfFirstProduit = indexOfLastProduit - produitsPerPage;
  const historiqueProduits = historique?.slice(
    indexOfFirstProduit,
    indexOfLastProduit
  );

  const paginate = (page: number) => {
    setCurrentPage(page);
  };

  if (historique && historique?.length === 0) {
    return (
      <WrapperAlertMobile>
        <Alert
          message="Information"
          description="Aucun historique trouvé"
          type="info"
        />
      </WrapperAlertMobile>
    );
  }

  return (
    <>
      <Wrapper>
        {historiqueProduits?.map((historique) => (
          <Table
            columns={columns}
            dataSource={getDataTable(historique)}
            size="middle"
          />
        ))}
      </Wrapper>
      <WrapperPagination>
        <PaginationANTD
          current={currentPage}
          pageSize={produitsPerPage}
          total={historique?.length}
          onChange={paginate}
        />
      </WrapperPagination>
    </>
  );
};

export default MobileHistorique;
