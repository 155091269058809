import { InputV3 } from "@ads/ui";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 40%;
`;

const FormAdresseNumber = () => {
  return (
    <Wrapper>
      <label htmlFor="street-number">N°</label>
      <InputV3
        type="number"
        id="street-number"
        name="street-number"
        selectOnFocus
      />
    </Wrapper>
  );
};

export default FormAdresseNumber;
