import { adsNotification, FormWidget } from "@ads/ui";
import { IAHEdition } from "./IAHEdition";
import { useFicheSuivi } from "../../fiche-suivi/hook/useFicheSuivi";
import { FicheSuiviProps } from "../../fiche-suivi/ui";
import { updateFicheSuiviByVisiteID } from "modules/fiche-suivi/feature/updateFicheSuiviByVisiteID";
import nomadTheme from "utils/nomad-theme";
import TitleMain from "components/ui/atoms/titles/TitleMain";

export function IAHEditionForm(props: FicheSuiviProps) {
  const service = useFicheSuivi({
    data: {
      ficheSuivi: props.data,
    },
    callbacks: {
      async onChange(fs) {
        if (props.callbacks?.onChange) {
          return props.callbacks.onChange(fs);
        }
        return fs;
      },
    },
    features: {
      async updateFicheSuivi(fs) {
        const newFS = fs;
        if (newFS) {
          newFS.parametresEditions =
            service.parametreEditionService.servicePEList.data;
        }
        const result = updateFicheSuiviByVisiteID(props.visiteID, newFS);
        result.then(() => {
          adsNotification.success({
            message: "Formulaire sauvegardé avec succès.",
            duration: nomadTheme.duration.notification,
          });
        });
        return result;
      },
    },
  });
  return (
    <FormWidget
      handleSave={() =>
        service.features.updateFicheSuivi(service.features.getFicheSuivi())
      }
    >
      <TitleMain>IAH</TitleMain>
      <IAHEdition
        data={{
          IAH: props.data.IAH,
          IAHPrecedente: props.data.IAHPrecedent,
        }}
        callbacks={{
          onChange: undefined,
        }}
      />
    </FormWidget>
  );
}
