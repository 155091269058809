import { verify as verifyJwt } from "jsonwebtoken";
import { getTournee } from "./services/feuille-de-route.services";
import { selectRdv } from "./features/feuille-de-route.slice";
import { getCookie } from "utils/cookies";
import config from "../../config";
import moment from "moment";
import { TypeRendezVous } from "interface/typeRendezVous";
import { reconnexionStatus } from "store/redux/features/utilisateur/utilisateur.slice";

interface AuthCookie {
  iFKAnnuaire: string;
}

const feuilleDeRouteMiddleware =
  (store: any) => (next: any) => async (action: any) => {
    if (action.type === "utilisateur/reconnexion/fulfilled") {
      const cookie = getCookie("tournee");
      let result = undefined;
      let selection: TypeRendezVous = "a-faire";

      // Récupération des tableaux des visites/rdv
      if (cookie) {
        // Depuis le cookie
        const params = JSON.parse(cookie);
        result = await store.dispatch(getTournee(params));
      } else {
        // Du jour pour l'intervenant connecté depuis le cookie renvoyé par le serveur d'identité
        const token = getCookie(config.localStorageIdentityKey);

        if (token) {
          try {
            const { iFKAnnuaire } = verifyJwt(
              token,
              config.jwtPUBLIC
            ) as AuthCookie;
            const dDate = moment().format("YYYYMMDD");

            result = await store.dispatch(
              getTournee({
                dDate,
                iPKIntervenant: iFKAnnuaire,
                selection
              })
            );
          } catch {
            throw new Error(
              "Le chargement de la tournée a échoué lors de la reconnexion"
            );
          }
        }
      }

      // Une fois ces données chargées, sélection du rdv en cours
      if (result.type === "feuille-de-route/getTournee/fulfilled") {
        const chunks = window.location.href.split("%2F");
        const iPKVisite = chunks[chunks.indexOf("visites") + 1];
        let selectedIndex = -1;

        const rdvAFaire = result.payload.tRdvAFaire.findIndex(
          (rdv: any) => rdv.ID === parseInt(iPKVisite, 10)
        );
        const visiteEnCours = result.payload.tVisiteEnCours.findIndex(
          (rdv: any) => rdv.ID === parseInt(iPKVisite, 10)
        );
        const visiteTermine = result.payload.tVisiteTermine.findIndex(
          (rdv: any) => rdv.ID === parseInt(iPKVisite, 10)
        );

        if (rdvAFaire !== -1) {
          selectedIndex = rdvAFaire;
          selection = "a-faire";
        } else if (visiteEnCours !== -1) {
          selectedIndex = visiteEnCours;
          selection = "en-cours";
        } else if (visiteTermine !== -1) {
          selectedIndex = visiteTermine;
          selection = "effectue";
        }

        store.dispatch(selectRdv({ selection, selectedIndex }));
      }

      store.dispatch(reconnexionStatus({ reconnexion: false }));
    }

    next(action);
  };

export default feuilleDeRouteMiddleware;
