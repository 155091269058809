import styled from "styled-components";

const TitleList = styled.h3`
  margin: 0 0 0 2.1rem;
  padding: 0;
  font-size: 1.5rem;
  line-height: 2.3rem;
  font-weight: bold;
`;

export default TitleList;
