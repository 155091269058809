import { FormItemWrapper, Input, Label, PrimaryButton, } from "@ads/ui";
import React, { useEffect } from "react";
import styled from "styled-components";
import { FormSelectionTypePrestationItem, } from "..";
import { useTableFormSelectionConsommable } from "../hook/useTableFormSelectionConsommable";
import { SelectionProduit } from "../../../../produit/component/selection/ui/SelectionProduit";
const WrapperConsommable = styled.div `
  display: flex;
  flex-direction: column;
`;
const WrapperMain = styled.div `
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const WrapperButton = styled.div `
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.md} {
    margin: inherit;
  }
`;
export function TableFormSelectConsommableAdapter(props) {
    var _a;
    if (!props.formData) {
        throw new Error("FormData is not instantiate");
    }
    const service = useTableFormSelectionConsommable((_a = props.formData) === null || _a === void 0 ? void 0 : _a.config);
    const handleRef = (e) => {
        service.setRefOrLibelle && service.setRefOrLibelle(e.target.value);
    };
    useEffect(() => {
        var _a;
        if ((_a = props.formData) === null || _a === void 0 ? void 0 : _a.data) {
            props.submitSearch();
        }
    }, [props.formData.data]);
    return (React.createElement(Wrapper, null,
        React.createElement(WrapperMain, null,
            React.createElement(SelectionProduit, { natureSelectionService: service.natureSelectionService, typeProduitSelectionService: service.typeProduitSelectionService, familleSelectionService: service.familleSelectionService, sousFamilleSelectionService: service.sousFamilleSelectionService }),
            React.createElement(WrapperConsommable, null,
                React.createElement(FormSelectionTypePrestationItem, Object.assign({}, service.typePrestationSelectionService, { libelle: "Type de prestation :" })),
                React.createElement(FormItemWrapper, { direction: "vertical" },
                    React.createElement(Label, { htmlFor: "codeNumSerie" }, "Ref ou libell\u00E9"),
                    React.createElement(Input, { id: "refOrLib", name: "refOrLib", selectMode: "light", defaultValue: service.refOrLibelle, onChange: handleRef, type: "text" })),
                React.createElement(WrapperButton, null,
                    React.createElement(PrimaryButton, { as: "button", type: "button", size: "M", isLoading: props.isFormDataLoading, buttonStyle: props.isFormDataLoading ? "neutral130" : "secondary", label: "Rechercher", onClick: () => {
                            var _a, _b;
                            props.notifyFormDataChanged({
                                config: ((_a = props.formData) === null || _a === void 0 ? void 0 : _a.config)
                                    ? (_b = props.formData) === null || _b === void 0 ? void 0 : _b.config
                                    : {},
                                data: {
                                    nature: service.natureSelectionService.selectedData[0],
                                    typeProduit: service.typeProduitSelectionService.selectedData[0],
                                    famille: service.familleSelectionService.selectedData[0],
                                    sousFamille: service.sousFamilleSelectionService.selectedData[0],
                                    refOrLib: service.refOrLibelle,
                                    typePrestationID: service.typePrestationSelectionService.selectedData[0],
                                },
                            });
                        } }))))));
}
