import { FC, useState, useEffect } from "react";
import { Modal } from "antd";
import { FichePatient, FichePatientTemplate } from "@ads/module-tiers";
import styled from "styled-components";

import BtnEditPatient from "../atoms/buttons/ButtonEdit";
import { IconAccount } from "@ads/ui";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  .modal-body {
    padding-top: 0;
  }
`;

interface Props {
  opened?: boolean;
  setOpenModal: any;
  patientID: number;
}

const ModalDossierPatient: FC<Props> = ({
  opened,
  setOpenModal,
  patientID,
}: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setOpenModal(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setOpenModal(false);
  };

  useEffect(() => {
    if (opened) {
      setIsModalVisible(true);
    }
  }, [opened]);

  return (
    <Wrapper>
      <BtnEditPatient as="button" onClick={showModal}>
        <IconAccount iconColor="#524D72" iconSize="2rem" />
        Dossier Patient
      </BtnEditPatient>
      <div onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          width={800}
          onOk={handleOk}
          footer={null}
          title={null}
          okText="Sélectionner"
          cancelText="Annuler"
        >
          <FichePatientTemplate patientID={patientID} children={FichePatient} />
        </Modal>
      </div>
    </Wrapper>
  );
};

export default ModalDossierPatient;
