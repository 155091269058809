import {
  getDefaultApiInstance,
  useApiGetV3,
  UseApiGetV3Output
} from "@ads/react-common-module";
import {
  ProgrammeEducatifAdapter,
  ProgrammeEducatifModel
} from "@ads/isadom-model";
import { apiIsadomErrorStrategy } from "@ads/ui";
export interface useAPIGetProgrammeEducatifByVisiteIDProps {
  visiteID: number;
}

export function useAPIGetProgrammeEducatifByVisiteID(
  visiteID: number
): UseApiGetV3Output<ProgrammeEducatifModel[]> {
  const adapter = new ProgrammeEducatifAdapter();
  return useApiGetV3(
    getDefaultApiInstance(),
    `/visites/${visiteID}/programmes-educatifs`,
    {
      errorStrategy: apiIsadomErrorStrategy,
      adaptResult: (data) => {
        const result = adapter.toClients(data.data);
        return result;
      }
    }
  );
}
