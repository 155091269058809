import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const List = styled.div `
  margin: 2.6rem 0 0;
`;
export const ConfigConsoSpecTiersVisiteList = ({ data, adapter, }) => {
    if (!data) {
        return null;
    }
    return (_jsx(List, { children: data === null || data === void 0 ? void 0 : data.map((onsoSpecTiersVisiteList) => adapter(onsoSpecTiersVisiteList)) }, void 0));
};
