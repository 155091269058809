import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Input as InputANTD } from "antd";
const StyledInput = styled("div") `
  &.mode-dark .ant-input {
    color: ${({ theme }) => theme.colors.neutral[130]};
    background-color: ${({ theme }) => theme.colors.neutral[160]};
    &::-webkit-autofill {
      background-color: ${({ theme }) => theme.colors.neutral[160]};
    }
  }
  &.mode-light .ant-input {
    color: ${({ theme }) => theme.colors.neutral[190]};
    background-color: ${({ theme }) => theme.colors.neutral[130]};
    &::-webkit-autofill {
      background-color: ${({ theme }) => theme.colors.neutral[130]};
    }
  }
`;
export const Input = ({ className, defaultValue, error, id, name, onChange, placeholder = "", selectMode = "dark", type, value, disabled, }) => (_jsx(StyledInput, Object.assign({ error: error, selectMode: selectMode, className: `${className && className} wrapper-input wrapper-${name} mode-${selectMode}` }, { children: _jsx(InputANTD, { id: id, name: name, type: type, defaultValue: defaultValue, value: value, placeholder: placeholder, disabled: disabled, onChange: onChange }, void 0) }), void 0));
