import {
  getDefaultApiInstance,
  UseApiPostPutV3Output,
  useApiPutV3
} from "@ads/react-common-module";
import {
  ProgrammeEducatifAdapter,
  ProgrammeEducatifModel
} from "@ads/isadom-model";
import { apiIsadomErrorStrategy } from "@ads/ui";

export interface ApiPutProgrammeEducatifVisiteHookInput {
  visiteID: number;
}

export function useApiPutProgrammeEducatifVisite({
  visiteID,
}: ApiPutProgrammeEducatifVisiteHookInput): UseApiPostPutV3Output<ProgrammeEducatifModel> {
  const adapter = new ProgrammeEducatifAdapter();
  return useApiPutV3(
    getDefaultApiInstance(),
    `/visites/${visiteID}/programmes-educatifs`,
    {
      adaptBody(body) {
        return adapter.toAPI(body);
      },
      adaptResult(result) {
        return adapter.toClient(result.data);
      },
      errorStrategy: apiIsadomErrorStrategy
    }
  );
}
