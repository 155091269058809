import ListeItemsFeuilleDeRoute from "../molecules/ListeItemsFeuilleDeRoute";
import { TypeRendezVous } from "interface/typeRendezVous";
import BreadcrumbContainer from "modules/appareils/ui/molecules/BreadcrumbContainer";

interface TemplateFeuilleDeRouteProps {
  intervenantID: number | undefined;
  date: string;
  selection: TypeRendezVous;
  items: any;
}

const TemplateFeuilleDeRoute = ({
  intervenantID,
  date,
  selection,
  items
}: TemplateFeuilleDeRouteProps) => {
  return (
    <>
      <BreadcrumbContainer
        intervenantID={intervenantID}
        selection={selection}
        date={date}
      />
      <ListeItemsFeuilleDeRoute selection={selection} items={items} />
    </>
  );
};

export default TemplateFeuilleDeRoute;
