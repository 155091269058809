import { InputV3 } from "@ads/ui";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const FormTelephone = () => {
  return (
    <Wrapper>
      <label htmlFor="telephone">Tel :</label>
      <InputV3 type="text" name="telephone" id="telephone" />
    </Wrapper>
  );
};

export default FormTelephone;
