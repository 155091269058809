import { LigneCommandeVisiteModel } from "@ads/isadom-model";
import { FormItemWrapper, InputV3, Label } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-right: 1rem;
  width: 8rem;
  text-align: center;
  order: 1;
`;

interface CommandeItemQuantiteProps {
  item: LigneCommandeVisiteModel;
}

const CommandeItemQuantite = ({ item }: CommandeItemQuantiteProps) => {
  const { setQuantite } = useFormulaireCommandesStore();

  return (
    <Wrapper>
      <FormItemWrapper direction="vertical">
        <Label htmlFor="quantite">Quantité</Label>
        <InputV3
          type="number"
          name="quantite"
          value={item.quantite}
          selectOnFocus
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            setQuantite(item.ID, parseInt(evt.target.value, 10));
          }}
        />
      </FormItemWrapper>
    </Wrapper>
  );
};

export default CommandeItemQuantite;
