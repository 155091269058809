import { RouteParam } from 'interface/core/RouteParam'
import { useParams } from 'react-router-dom'
import { useGetVisiteQuery } from 'store/redux/services/nomadApi'
import { SelectionRetourConsommable } from "modules/consommable/component/selection";
import { ConsommablesRetoursTemplate } from '@ads/module-consommables-visite';
import { LocalisationInterneAutoriseeAdapter } from '@ads/isadom-model';


const ContainerRetourConsommable = () => {
  const { iPKVisite } = useParams<RouteParam>()
  const { data: visite } = useGetVisiteQuery(+iPKVisite)

  if (!visite) {
    return <></>
  }

  const adapterLocalisationsRetraits =
    new LocalisationInterneAutoriseeAdapter();
  const localisationsRetraits =
    visite?.intervenantPrincipal.tLocalisationAutorise &&
    adapterLocalisationsRetraits.toClients(
      visite?.intervenantPrincipal.tLocalisationAutorise
    );

  return (
    <ConsommablesRetoursTemplate
      visiteID={+iPKVisite}
      SelectionRetourConsommable={SelectionRetourConsommable}
      intervenantID={visite.intervenantPrincipal.iPKTiers}
      localisationsRetraits={localisationsRetraits}
    />
  )
}

export default ContainerRetourConsommable