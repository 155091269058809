import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FieldComment } from "@ads/ui";
import styled from "styled-components";
import { useState } from "react";
import { CommentModal } from "../..";
export const MiniDiv = styled("div") `
  display: block;
  width: 10%;
`;
export function ChampInput(controler) {
    const [isPopoverVisible, setPopoverVisible] = useState(false);
    return (_jsx(_Fragment, { children: _jsx(FieldComment, { buttonProps: {
                hasComment: controler.data.valeur.commentaire !== "",
                onClick: () => {
                    setPopoverVisible(!isPopoverVisible);
                },
            }, inputProps: {
                name: controler.data.libelle,
                type: "textarea",
                onChange: (event) => controler.setValue(event.target.value),
                defaultValue: controler.data.valeur.valeurs && controler.data.valeur.valeurs[0]
                    ? controler.data.valeur.valeurs[0]
                    : "",
                value: controler.data.valeur.valeurs && controler.data.valeur.valeurs[0]
                    ? controler.data.valeur.valeurs[0]
                    : ""
            }, inputType: "input", popoverProps: {
                title: "Commentaire de saisie",
                content: (_jsx(_Fragment, { children: _jsx(CommentModal, { setCommentaire: (value) => {
                            controler.updateCommentaire(value);
                        }, commentaire: controler.data.valeur.commentaire }, void 0) }, void 0)),
                children: (_jsx(CommentModal, { setCommentaire: (value) => {
                        controler.updateCommentaire(value);
                    }, commentaire: controler.data.valeur.commentaire }, void 0)),
                visible: isPopoverVisible,
                handleClose: () => setPopoverVisible(false),
            } }, void 0) }, void 0));
}
