import { useEffect, useState } from "react";
export function useChamps(input) {
    const [data, setData] = useState(input.data);
    useEffect(() => {
        setData(input.data);
    }, [input.data]);
    const updateChamp = (value) => {
        if (input && input.updateValues) {
            input.updateValues(value);
        }
        else {
            data.valeur.valeurs = [value];
        }
    };
    const updateCommentaire = (value) => {
        data.valeur.commentaire = value;
    };
    return {
        data: data,
        updateValues: updateChamp,
        updateCommentaire: updateCommentaire
    };
}
