import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
export function Form({ services, formAdapter, withSaveLoading, withFetchLoading, withDeleteLoading, }) {
    var _a, _b;
    if (services.saveService.loading && withSaveLoading) {
        return (_jsx(_Fragment, { children: withSaveLoading({
                children: formAdapter(services),
                formService: services,
            }) }, void 0));
    }
    else if (((_a = services.fetchServices) === null || _a === void 0 ? void 0 : _a.loading) && withFetchLoading) {
        return (_jsx(_Fragment, { children: withFetchLoading({
                children: formAdapter,
                formService: services,
            }) }, void 0));
    }
    else if (((_b = services.deleteServices) === null || _b === void 0 ? void 0 : _b.loading) && withDeleteLoading) {
        return (_jsx(_Fragment, { children: withDeleteLoading({
                children: formAdapter,
                formService: services,
            }) }, void 0));
    }
    else if (services.formData) {
        return _jsx(_Fragment, { children: formAdapter(services) }, void 0);
    }
    else {
        return _jsx(_Fragment, {}, void 0);
    }
}
