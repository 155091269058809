import { Tooltip } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { getInitials } from "utils/getInitials";
import { resetTournee } from "modules/feuille-de-route/store/redux/features/feuille-de-route.slice";
import { deconnexion } from "../../../store/redux/services/utilisateur";
import btnCalendarDay from "../../../assets/icons/icon-calendar-day.svg";
import btnLogout from "../../../assets/img/btn-logout.svg";

const Navigation = styled("nav")`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: all 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);

  .is-sticky & {
    transform: translateY(2.5rem);

    ${({ theme }) => theme.media.md} {
      transform: translateY(6.4rem);
    }
  }

  .is-sticky.has-header & {
    transition-delay: 75ms;
    transform: translateY(15.3rem);
  }
`;

const UserLink = styled("div")`
  display: inline-block;
  width: 3.8rem;
  height: 3.8rem;
  line-height: 3.4rem;
  font-size: 1em;
  color: ${(props) => props.theme.colors.neutral[100]};
  text-align: center;
  text-decoration: none;
  border: 2px solid ${(props) => props.theme.colors.neutral[100]};
  border-radius: 50%;

  ${({ theme }) => theme.media.md} {
    width: 4.2rem;
    height: 4.2rem;
    line-height: 3.8rem;
  }
`;

const WrapperLink = styled(Link)`
  height: 5rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.1s linear;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary[100]};
    a {
      background-color: ${(props) => props.theme.colors.neutral[100]};
    }
  }
`;

const MainNavigation = () => {
  const dispatch = useAppDispatch();

  const { bConnecte, identite } = useAppSelector((state) => state.auth);

  const deconnecteUtilisateur = async () => {
    const result = await dispatch(deconnexion());
    if (result.type === "utilisateur/deconnexion/fulfilled") {
      dispatch(resetTournee());
    }
  };
  if (!bConnecte) {
    return null;
  }

  return (
    <Navigation>
      <WrapperLink to="/feuille-de-route/a-faire">
        <img src={btnCalendarDay} alt="" />
      </WrapperLink>
      <Tooltip
        title={`
        ${identite?.sCodeCivilite && identite?.sCodeCivilite} 
        ${identite?.sPrenom && identite?.sPrenom} 
        ${identite?.sNom && identite?.sNom} 
        ${identite?.sCodeAnnuaire && `(${identite?.sCodeAnnuaire})`}
      `}
        placement="bottom"
      >
        <WrapperLink to="/feuille-de-route/a-faire">
          <UserLink>
            {identite && getInitials(identite.sPrenom, identite.sNom)}
          </UserLink>
        </WrapperLink>
      </Tooltip>
      <WrapperLink onClick={deconnecteUtilisateur} to="/">
        <img src={btnLogout} alt="" />
      </WrapperLink>
    </Navigation>
  );
};

export default MainNavigation;
