var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
import { AbstractChampAdapter } from '../champ/AbstractChampAdapter';
import { StringParametrageIDAdapter } from '../params/StringParametrageIDAdapter';
var GroupAdapter = /** @class */ (function (_super) {
    __extends(GroupAdapter, _super);
    function GroupAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.champAbstractAdapter = new AbstractChampAdapter();
        _this.straingParamAdapter = new StringParametrageIDAdapter();
        return _this;
    }
    GroupAdapter.prototype.toClient = function (src) {
        return {
            champs: this.champAbstractAdapter.toClients(src.tChamps),
            id: src.id.sID,
            libelle: src.sLibelle,
            module: src.sModule,
            version: src.id.iVersion,
            ordre: src.iOrdre,
        };
    };
    GroupAdapter.prototype.toAPI = function (src) {
        var idToApi = {
            id: src.id,
            version: src.version
        };
        return {
            iOrdre: src.ordre,
            id: this.straingParamAdapter.toAPI(idToApi),
            sLibelle: src.libelle,
            sModule: src.module,
            tChamps: this.champAbstractAdapter.toAPIs(src.champs),
        };
    };
    return GroupAdapter;
}(Adapter));
export { GroupAdapter };
