import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Details = styled.div `
  display: flex;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.md} {
  }
  ${({ theme }) => theme.media.lg} {
  }
`;
const ConsommableNom = styled.div `
  font-weight: 700;
  display: block;
  ${({ theme }) => theme.media.lg} {
    display: inline-block;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;
export const LivraisonDetail = ({ livraison }) => {
    var _a, _b;
    return (_jsx(Details, { children: _jsx(ConsommableNom, { children: `${(_a = livraison.consommable) === null || _a === void 0 ? void 0 : _a.sLibelle} - ${(_b = livraison.consommable) === null || _b === void 0 ? void 0 : _b.sRefProduit}` }, void 0) }, void 0));
};
