import { useEffect, useState } from "react";
import styled from "styled-components";

import { EvenementTelesuiviModel, TypeEvenementModel } from "@ads/isadom-model";
import { Button, FormItemWrapper, Label, SelectWrapper } from "@ads/ui";
import { Select, DatePicker, Switch } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import moment from "moment";
import { apiGetTypesEvenementsByVisiteID } from "modules/evenement/api";
import { Details } from "../../atoms/viewFields";

const DatePickerANTD = styled(DatePicker)`
  background: ${(props) => props.theme.colors.neutral[130]};
`;

const AccordWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperChangeStatusButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

const { Option } = Select;

interface EvenementTelesuiviDetailsFormProps {
  visiteID: number;
  evenement?: EvenementTelesuiviModel;
  onChange: (evenement: EvenementTelesuiviModel) => void;
}

export const EvenementTelesuiviDetailsForm = ({
  visiteID,
  evenement,
  onChange,
}: EvenementTelesuiviDetailsFormProps) => {
  const [typesEvenement, setTypesEvenement] = useState<TypeEvenementModel[]>(
    []
  );
  const [motifsRefus, setMotifsRefus] = useState<any[]>([]);

  const onChangeTypeEvenement = (value: string) => {
    const typeEvenementSelected = typesEvenement.find(
      (typeEvenement) => typeEvenement.ID === value
    );
    onChange({
      ...evenement,
      typeEvenement: typeEvenementSelected,
      motifRefus: undefined,
    } as EvenementTelesuiviModel);
  };

  useEffect(() => {
    const getTypesEvenement = async (visiteID: number) => {
      try {
        const result = (await apiGetTypesEvenementsByVisiteID(visiteID, "TS"))
          .data;
        setTypesEvenement(result);
        const typeEvenement = result.find(
          (typeEvenement) => typeEvenement.ID === evenement?.typeEvenement.ID
        );
        if (typeEvenement?.precisions) {
          setMotifsRefus(typeEvenement.precisions);
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };

    if (visiteID) {
      getTypesEvenement(visiteID);
    }
  }, [visiteID, evenement?.typeEvenement]);

  return (
    <Details>
      <FormItemWrapper direction="vertical">
        <Label htmlFor="typeEvenement">Type d'évènement</Label>
        <SelectWrapper selectMode="light">
          <Select
            id="typeEvenement"
            disabled={!!evenement?.evenementVisiteID || !!evenement?.ID}
            onChange={onChangeTypeEvenement}
            placeholder="Choisissez un type d'évènement"
            value={evenement?.typeEvenement?.ID}
            allowClear
          >
            {typesEvenement &&
              typesEvenement.map((typeEvenement: any) => (
                <Option key={typeEvenement.ID} value={typeEvenement.ID}>
                  {typeEvenement.libelle}
                </Option>
              ))}
          </Select>
        </SelectWrapper>
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="dateDebut" selectMode="dark">
          Date de début
        </Label>
        <DatePickerANTD
          name="dateDebut"
          id="dateDebut"
          defaultValue={
            evenement && evenement.dateDebut !== "0000-00-00"
              ? moment(evenement.dateDebut)
              : undefined
          }
          format="DD/MM/YYYY"
          locale={locale}
          onChange={(value) => {
            onChange({
              ...evenement,
              dateDebut: value ? value.format("YYYY-MM-DD") : undefined,
            } as EvenementTelesuiviModel);
          }}
          placeholder="Date de début"
        />
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="dateFin" selectMode="dark">
          Date de fin
        </Label>
        <DatePickerANTD
          name="dateFin"
          id="dateFin"
          defaultValue={
            evenement && evenement.dateFin !== "0000-00-00"
              ? moment(evenement.dateFin)
              : undefined
          }
          format="DD/MM/YYYY"
          locale={locale}
          onChange={(value) => {
            onChange({
              ...evenement,
              dateFin: value ? value.format("YYYY-MM-DD") : undefined,
            } as EvenementTelesuiviModel);
          }}
          placeholder="Date de fin"
        />
      </FormItemWrapper>

      <FormItemWrapper direction="vertical">
        <Label htmlFor="estDecision">Accord Patient</Label>
        <AccordWrapper>
          <Switch
            id="estDecision"
            onChange={(checked: boolean) => {
              onChange({
                ...evenement,
                estDecision: checked,
                ...(checked
                  ? { precision: undefined }
                  : { dateAccord: undefined }),
              } as EvenementTelesuiviModel);
            }}
            disabled={!!evenement?.ID}
            defaultChecked={evenement?.estDecision}
            checked={evenement?.estDecision}
          />
          <WrapperChangeStatusButton>
            {evenement?.ID ? (
              <Button
                as="button"
                label="Changer de status"
                size="S"
                type="button"
                buttonStyle="primary"
                onClick={() => {
                  onChange({
                    ...evenement,
                    changeStatus: true,
                    estDecision: !evenement.estDecision,
                  } as EvenementTelesuiviModel);
                }}
              />
            ) : (
              ""
            )}
          </WrapperChangeStatusButton>
        </AccordWrapper>
      </FormItemWrapper>
      <FormItemWrapper>
        <Label htmlFor="dateAccord">Accord patient le</Label>
        <DatePickerANTD
          name="dateAccord"
          id="dateAccord"
          disabled={!evenement?.estDecision}
          defaultValue={
            evenement && evenement.dateAccord !== "0000-00-00"
              ? moment(evenement.dateAccord)
              : undefined
          }
          format="DD/MM/YYYY"
          locale={locale}
          onChange={(value) => {
            onChange({
              ...evenement,
              dateAccord: value ? value.format("YYYY-MM-DD") : undefined,
            } as EvenementTelesuiviModel);
          }}
          placeholder="Date d'accord du patient"
        />
      </FormItemWrapper>

      <FormItemWrapper>
        <Label htmlFor="motifRefus">Motif du refus</Label>
        <SelectWrapper selectMode="light">
          <Select
            id="motifRefus"
            onChange={(value) => {
              onChange({
                ...evenement,
                precision: motifsRefus.find((mr) => mr.sCode === value),
              } as EvenementTelesuiviModel);
            }}
            disabled={evenement?.estDecision}
            placeholder="Choisissez un motif de refus"
            value={evenement?.precision?.sCode}
            allowClear
          >
            {motifsRefus?.map((motifRefus: any) => (
              <Option key={motifRefus.sCode} value={motifRefus.sCode}>
                {motifRefus.sLibelle}
              </Option>
            ))}
          </Select>
        </SelectWrapper>
      </FormItemWrapper>
    </Details>
  );
};
