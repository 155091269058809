import styled from "styled-components";

const TextTime = styled.div`
  grid-area: horaires;
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-bottom: 8px;
  margin-bottom: 8px;
  font-family: PoppinsBold, sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  border-bottom: 1px solid #ededed;

  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
  }

  .date-differente {
    font-family: PoppinsMedium, sans-serif;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.danger[100]};

    ${({ theme }) => theme.media.xl} {
      font-size: 1.6rem;
      display: none;
      .isOpenLeft & {
        display: inherit;
      }
    }
  }

  .time-item {
    display: block;
    margin-bottom: 0.4rem;
  }

  ${({ theme }) => theme.media.xl} {
    .isOpenLeft & {
      border: none;
    }
  }

  ${({ theme }) => theme.media.lg} {
    border: none;
  }
`;

export default TextTime;
