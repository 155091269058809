import { INavigationItem } from "@ads/ui";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";

export const NavigationBlocNote = () => {
  const { iPKVisite } = useParams<RouteParam>();

  const navItems: INavigationItem[] = [
    {
      to: `/visites/${iPKVisite}/bloc-notes`,
      label: "Liste des notes",
      active: true,
      visible: true
    }
  ];

  return (
    <NavigationPrimary
      navItems={navItems || []}
      isOpen={false}
      isLargeNav={false}
    />
  );
};
