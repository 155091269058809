import moment from "moment";
import { Horaire } from "modules/appareils/interfaces/appareils.interfaces";
import { FC } from "react";
import { TimePicker, Input } from "antd";

const format = "HH:mm";

interface Props {
  index: number;
  horaire: Horaire;
  horaires: Horaire[];
  setHoraires: React.Dispatch<React.SetStateAction<Horaire[]>>;
}

const HoraireItem: FC<Props> = ({
  horaire,
  horaires,
  setHoraires,
  index,
}: Props) => {
  const handleChangeHoraire = (
    typeHoraire: "heureDebut" | "heureFin",
    momentValue: moment.Moment | null,
    timeString: string
  ) => {
    const newHoraires: Horaire[] = horaires.map((horaire, indexHoraire) => {
      if (index === indexHoraire) {
        return {
          ...horaire,
          [typeHoraire]: timeString,
        };
      }
      return horaire;
    });

    setHoraires(newHoraires);
  };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHoraires: Horaire[] = horaires.map((horaire, indexHoraire) => {
      if (index === indexHoraire) {
        return {
          ...horaire,
          value: e.target.value,
        };
      }
      return horaire;
    });
    setHoraires(newHoraires);
  };

  return (
    <div className="line">
      <TimePicker
        value={
          horaire.heureDebut ? moment(horaire.heureDebut, format) : undefined
        }
        onChange={(moment, timeString) =>
          handleChangeHoraire("heureDebut", moment, timeString)
        }
        placeholder="Heure de début"
        format={format}
      />
      <TimePicker
        value={horaire.heureFin ? moment(horaire.heureFin, format) : undefined}
        onChange={(moment, timeString) =>
          handleChangeHoraire("heureFin", moment, timeString)
        }
        placeholder="Heure de fin"
        format={format}
      />
      <Input
        onChange={handleChangeValue}
        className="line-item"
        value={horaire.value}
        type="number"
        placeholder="Valeur"
      />
    </div>
  );
};

export default HoraireItem;
