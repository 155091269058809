var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { IconDeleteItem } from "@ads/ui";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { retireConfigurationConsommable } from "../../features/retireConfigurationConsommable";
const ButtonRemove = styled.button `
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  z-index: 10;
  margin: 0;
  padding: 0;
  height: 4.2rem;
  width: 4.2rem;
  line-height: 4.2rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  circle {
    transition: all 0.2s linear;
  }
  &:hover {
    circle {
      fill: ${({ theme }) => theme.colors.danger[100]};
    }
  }
`;
export const ButtonRemoveConfigConso = ({ visiteID, configurationConsommable, configurationsConsommable, setConfigurationsConsommable, }) => {
    const api = getDefaultApiInstance();
    const [isLoading, setIsLoading] = useState(false);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const isSuccess = yield retireConfigurationConsommable({
            api,
            visiteID,
            item: configurationConsommable,
        });
        setIsLoading(false);
        if (isSuccess) {
            // Si la configCso est égale á 0 on supprime sinon on met á jour bRetraitConfig
            if (configurationConsommable.configCsoID === 0) {
                const configurationsConsommableFiltered = configurationsConsommable.filter((item) => item.produit.IDProduit !==
                    configurationConsommable.produit.IDProduit);
                setConfigurationsConsommable(configurationsConsommableFiltered);
            }
            else {
                const copyConfigurationsConsommable = [...configurationsConsommable];
                const indexConfigurationConsommable = copyConfigurationsConsommable.findIndex((item) => item.configCsoVisiteID ==
                    configurationConsommable.configCsoVisiteID);
                const newConfigurationConsommable = Object.assign(Object.assign({}, copyConfigurationsConsommable[indexConfigurationConsommable]), { bRetraitConfig: true });
                copyConfigurationsConsommable[indexConfigurationConsommable] =
                    newConfigurationConsommable;
                setConfigurationsConsommable(copyConfigurationsConsommable);
            }
        }
    });
    return (_jsx(ButtonRemove, Object.assign({ disabled: isLoading, onClick: handleClick }, { children: _jsx(IconDeleteItem, { iconSize: "3em", iconColor: "#ffffff" }, void 0) }), void 0));
};
