import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormItemWrapper, SelectWrapper, Label, Textarea } from "@ads/ui";
import { Select } from "antd";
import { Switch } from "../atoms/ConsommableConfigurationDetailFormSwitch";
import { Input } from "../atoms/ConsommableConfigurationDetailFormInput";
import { FormArea, ConfigurationArea, } from "../atoms/ConsommableConfigurationDetailFormArea";
const { Option } = Select;
const handleSubmit = (e) => {
    e.preventDefault();
};
export const ConfigurationConsommableForm = ({ configConsoSpecTiersVisite, formulaireConsommableValues, setFormulaireConsommableValues, }) => {
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit }, { children: [_jsx(FormArea, { children: _jsx("div", { children: _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "idTypeProduit" }, { children: "Information" }), void 0), _jsx(Textarea, { onChange: (evt) => {
                                    setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { sInformation: evt.target.value })));
                                }, value: formulaireConsommableValues.sInformation, id: "sInformation", name: "sInformation" }, void 0)] }), void 0) }, void 0) }, void 0), _jsx(FormArea, { children: _jsx("div", { children: _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "idTypeProduit" }, { children: "Type de prestation" }), void 0), _jsx(SelectWrapper, Object.assign({ placeholder: "", selectMode: "light" }, { children: _jsx(Select, Object.assign({ onChange: (value) => {
                                        setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { sPKTypePrestation: value })));
                                    }, value: formulaireConsommableValues.sPKTypePrestation, allowClear: true, placeholder: "S\u00E9lectionnez un type de prestation" }, { children: configConsoSpecTiersVisite.produit.famille.tTypePrestation.map((typePrestation) => (_jsx(Option, Object.assign({ value: typePrestation.id }, { children: typePrestation.libelle }), typePrestation.id))) }), void 0) }), void 0)] }), void 0) }, void 0) }, void 0), _jsx(FormArea, Object.assign({ className: "form-area" }, { children: _jsxs(ConfigurationArea, { children: [_jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "type" }, { children: "Quantit\u00E9" }), void 0), _jsx(Input, { onChange: (e) => {
                                        setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { iQuantite: +e.target.value })));
                                    }, value: formulaireConsommableValues.iQuantite }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "type" }, { children: "Fr\u00E9quence" }), void 0), _jsx(Input, { onChange: (e) => {
                                        setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { iFrequence: +e.target.value })));
                                    }, value: formulaireConsommableValues.iFrequence }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "type" }, { children: "Pr\u00E9paration de tourn\u00E9e" }), void 0), _jsx(Switch, { onChange: (value) => {
                                        setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { bPreparation: value })));
                                    }, checked: formulaireConsommableValues.bPreparation }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "type" }, { children: "En test" }), void 0), _jsx(Switch, { onChange: (value) => {
                                        setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { bTest: value })));
                                    }, checked: formulaireConsommableValues.bTest }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsxs(Label, Object.assign({ htmlFor: "type" }, { children: ["Afficher dans ", _jsx("br", {}, void 0), " les Compte-Rendus"] }), void 0), _jsx(Switch, { onChange: (value) => {
                                        setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { bAfficherCR: value })));
                                    }, checked: formulaireConsommableValues.bAfficherCR }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "type" }, { children: "Afficher dans la GED" }), void 0), _jsx(Switch, { onChange: (value) => {
                                        setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { bAfficherGED: value })));
                                    }, checked: formulaireConsommableValues.bAfficherGED }, void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "type" }, { children: "Afficher dans l'ordonnance" }), void 0), _jsx(Switch, { onChange: (value) => {
                                        setFormulaireConsommableValues((formulaireConsommableValues) => (Object.assign(Object.assign({}, formulaireConsommableValues), { bAfficherOrdo: value })));
                                    }, checked: formulaireConsommableValues.bAfficherOrdo }, void 0)] }), void 0)] }, void 0) }), void 0)] }), void 0));
};
