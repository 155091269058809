var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RetourVisiteAdapter, } from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";
export const apiListeConsommablesRetoursVisite = (visiteID) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (!visiteID) {
            throw new Error("L'identifiant de la visite est obligatoire");
        }
        const api = getDefaultApiInstance();
        if (!api) {
            throw new Error("La configuration d'api n'est pas initialisée");
        }
        const adapter = new RetourVisiteAdapter();
        const resultAPI = yield apiGetV2(api, `/visites/${visiteID}/retours`, apiIsadomErrorStrategy);
        return adapter.toClients(resultAPI);
    }
    catch (err) {
        return new Promise((_resolve, reject) => {
            reject(err.message);
        });
    }
});
