import { AdresseModel } from "@ads/isadom-model";
import { FormItemWrapper, itemSelect, Label, Select } from "@ads/ui";
import { ISelectAdresseByTiersHookService } from "../hook/interfaces/ISelectAdresseByTiersHookService";

export interface SelectAdresseProps {
	label: string;
	services: ISelectAdresseByTiersHookService;
}

export function SelectAdresse({ services, label }: SelectAdresseProps) {
	function transformDataToSelectOption(adresse: AdresseModel): itemSelect {
		return {
			value: services.selectionAdresseServices
				.getKeyFromData(adresse)
				.toString(),
			content: adresse.adresseComplete,
		};
	}

	const handleOnSelectOption = (value: string) => {
		services.selectionAdresseServices.notifyDataSelected(value);
	};

	return (
		<FormItemWrapper direction="horizontal">
			<Label htmlFor="SelectLocalisation">{label}</Label>
			<Select
				id="SelectLocalisation"
				selectMode="light"
				name="SelectLocalisation"
				itemsSelect={services.selectionAdresseServices.data.map((adresse) =>
					transformDataToSelectOption(adresse)
				)}
				value={services.selectionAdresseServices.selectedData.map((adresse) =>
					adresse.adresseID.toString()
				)}
				filterOption={(
					input: string,
					option: { label: { toString: () => string } }
				) => {
					if (option?.label && !(option.label instanceof Array)) {
						return option.label
							.toString()
							.toLowerCase()
							.includes(input.toLowerCase());
					} else {
						return false;
					}
				}}
				mode={
					services.selectionAdresseServices.typeSelection === "multiple"
						? "multiple"
						: undefined
				}
				onSelect={(value) => handleOnSelectOption(value)}
			/>
		</FormItemWrapper>
	);
}
