import { Helmet } from "react-helmet";
import TemplateConnexion from "../../../components/ui/templates/TemplateConnexion";

import config from "config";

export default function PageConnexion() {
  return (
    <>
      <Helmet title={`Connexion - NOMAD v${config.version}`} />
      <TemplateConnexion />
    </>
  );
}
