import {
  FicheSuiviModel,
  IFormulaireModel,
  IParametreEditionModel
} from "@ads/isadom-model";
import { IFormulaireHookResult, useForm } from "@ads/module-formulaire";
import { useEffect, useState } from "react";
import {
  IParametreEditionHookResult,
  useParametreEdition
} from "../../parametre-edition/hook";

export interface IFicheSuiviHookInputData {
  ficheSuivi: FicheSuiviModel;
}

export interface IFicheSuiviHookInputCallbacks {
  onChange(ficheSuivi: FicheSuiviModel): Promise<FicheSuiviModel>;
}

export interface IFicheSuiviHookInputFeature {
  updateFicheSuivi(
    fichesSuivis: FicheSuiviModel | null
  ): Promise<FicheSuiviModel | null>;
}

export interface IFicheSuiviHookInput {
  data: IFicheSuiviHookInputData;
  features: IFicheSuiviHookInputFeature;
  callbacks?: IFicheSuiviHookInputCallbacks;
}

export interface IFicheSuiviHookServiceActions {
  /*notifyPEChange(pe: IParametreEditionModel): Promise<IParametreEditionModel>,
    notifyFormChange(form:IFormulaireModel) : Promise<IFormulaireModel>,*/
  notifyFicheSuiviChange(fs: FicheSuiviModel): void;
}

export interface IFicheSuiviHookServiceFeatures {
  getFicheSuivi(): FicheSuiviModel;
  updateFicheSuivi(
    fichesSuivis: FicheSuiviModel | null
  ): Promise<FicheSuiviModel | null>;
}

export interface IFicheSuiviHookService {
  formulaireService: IFormulaireHookResult;
  parametreEditionService: IParametreEditionHookResult;
  features: IFicheSuiviHookServiceFeatures;
  actions: IFicheSuiviHookServiceActions;
}

export function useFicheSuivi(
  input: IFicheSuiviHookInput
): IFicheSuiviHookService {
  const [ficheSuivi, setFicheSuivi] = useState<FicheSuiviModel>(
    input.data.ficheSuivi
  );

  const formulaireService = useForm({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: new Promise((resolve, _reject) => {
      const form = input.data.ficheSuivi.formulaire;
      form.groupes.map((e) => {
        e.champs.forEach((champ) => {
          if (
            champ.libelle === "Synthèse médecin" ||
            champ.libelle === "Observations internes" ||
            champ.libelle === "Commentaire général"
          ) {
            champ.typeChamp = {
              libelle: "LNG",
              id: champ.typeChamp.id
            };
            return champ;
          }
        });
        return e;
      });
      resolve(form);
    }),
    features: {
      async updateFormulaire(formulaire: IFormulaireModel) {
        return formulaire;
      }
    },
    callbacks: {
      async onFormulaireChange(formulaire: IFormulaireModel) {
        setFicheSuivi({
          ...ficheSuivi,
          formulaire: formulaire
        });
        return formulaire;
      }
    }
  });

  const parametreEditionService = useParametreEdition({
    data: {
      parametresEditions: ficheSuivi.parametresEditions
    }
  });

  parametreEditionService.servicePEList.registerListener({
    idListener: "@ads/useFicheSuivi",
    onBeforeDeleteItem: async function (
      item: IParametreEditionModel | null
    ): Promise<IParametreEditionModel | null> {
      if (item) {
        const newPE: IParametreEditionModel[] =
          parametreEditionService.servicePEList.data;
        const index = newPE.findIndex(
          (value) => value.tiers.tiersID === item.tiers.tiersID
        );
        if (index !== -1) {
          newPE.splice(index, 1);
        }
        const newFs = {
          ...ficheSuivi,
          parametresEditions: newPE
        };
        setFicheSuivi(newFs);
      }
      return item;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onAfterDeleteItem: function (_item: IParametreEditionModel): void {},
    onBeforeAddItem: async function (
      item: IParametreEditionModel | null
    ): Promise<IParametreEditionModel | null> {
      return item;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onAfterAddItem: function (_item: IParametreEditionModel): void {},
    onBeforemModifyItem: async function (
      item: IParametreEditionModel | null
    ): Promise<IParametreEditionModel | null> {
      if (item) {
        const newPE: IParametreEditionModel[] =
          parametreEditionService.servicePEList.data;
        const index = newPE.findIndex((value) => value.id === item.id);
        if (index !== -1) {
          newPE[index] = item;
        }
        const newFs = {
          ...ficheSuivi,
          parametresEditions: newPE
        };
        setFicheSuivi(newFs);
      }
      return item;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onAfterModifyItem: function (_item: IParametreEditionModel): void {},
    onBeforeDeleteAll: function (): Promise<boolean> {
      throw new Error("Function not implemented.");
    },
    onAfterDeleteAll: function (): void {
      throw new Error("Function not implemented.");
    }
  });

  useEffect(() => {
    if (input.callbacks?.onChange) {
      input.callbacks.onChange(ficheSuivi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ficheSuivi]);

  function getFicheSuivi(): FicheSuiviModel {
    return ficheSuivi;
  }

  function onFicheSuiviChange(ficheSuivi: FicheSuiviModel) {
    setFicheSuivi(ficheSuivi);
  }

  return {
    formulaireService: formulaireService,
    parametreEditionService: parametreEditionService,
    features: {
      getFicheSuivi: getFicheSuivi,
      updateFicheSuivi: input.features.updateFicheSuivi
    },
    actions: {
      notifyFicheSuiviChange: onFicheSuiviChange
    }
  };
}
