import styled from "styled-components";
import {
  NavigationPrimary as NavigationPrimaryADSUI,
  INavigationItem
} from "@ads/ui";
import React from "react";

const StickyWrapper = styled("div")``;

const NavWrapper = styled.div`
  z-index: 10;
  display: flex;

  ${({ theme }) => theme.media.sm} {
    transition: padding 0.25s linear;
    .feuille-de-route & {
      right: initial;
    }
  }
  .nav-button-item a {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    .feuille-de-route & {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }

    ${({ theme }) => theme.media.sm} {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }
  }
`;

interface PropsTopNav {
  navItems: INavigationItem[];
  isOpen: boolean;
  isLargeNav?: boolean;
}

const NavigationPrimary: React.FC<PropsTopNav> = ({
  navItems,
  isOpen,
  isLargeNav
}: PropsTopNav) => {
  return (
    <StickyWrapper>
      <NavWrapper className={isOpen ? "isOpen nav-wrapper" : "nav-wrapper"}>
        <NavigationPrimaryADSUI
          navigationItems={navItems}
          isLargeNav={isLargeNav}
        />
      </NavWrapper>
    </StickyWrapper>
  );
};

export default NavigationPrimary;
