import { useEffect, useState } from "react";

/**
 * Récupère la largeur et la hauteur du contenu visible de la fenêtre de navigation en incluant,
 * s'il est visible, l'ascenseur vertical et le retourne sous forme de tableau
 * @returns [width:number, height:number]
 */
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export default useWindowSize;
