var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { DiagnostiqueEducatifDetailAdapter } from "./DiagnostiqueEducatifDetailAdapter";
var DiagnostiqueEducatifAdapter = /** @class */ (function (_super) {
    __extends(DiagnostiqueEducatifAdapter, _super);
    function DiagnostiqueEducatifAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.diagnostiqueDetailAdapter = new DiagnostiqueEducatifDetailAdapter();
        return _this;
    }
    DiagnostiqueEducatifAdapter.prototype.toClient = function (src) {
        return {
            id: src.diagnostiqueEducatifID,
            dDiagnostique: src.dateDiagnostique,
            diagnostiqueDetail: this.diagnostiqueDetailAdapter.toClients(src.tDiagnostiqueEducatifDetail),
        };
    };
    DiagnostiqueEducatifAdapter.prototype.toAPI = function (src) {
        return {
            diagnostiqueEducatifID: src.id,
            dateDiagnostique: src.dDiagnostique,
            tDiagnostiqueEducatifDetail: this.diagnostiqueDetailAdapter.toAPIs(src.diagnostiqueDetail),
        };
    };
    return DiagnostiqueEducatifAdapter;
}(Adapter));
export { DiagnostiqueEducatifAdapter };
