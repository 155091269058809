import { Adapter } from "@ads/isadom-model";
export class TypeDocumentAdapter extends Adapter {
    toClient(src) {
        return {
            id: src.typeDocumentID,
            libelle: src.sLibelle,
        };
    }
    toAPI(src) {
        return {
            sLibelle: src.libelle,
            typeDocumentID: src.id,
        };
    }
}
