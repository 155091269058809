import { Modal, notification } from "antd";
import { apiDeleteEvenement } from "modules/evenement/api";
import { useState } from "react";
import styled from "styled-components";
import errorAdapter from "utils/errorAdapter";
import { v4 } from "uuid";

import {
  EvenementDTOModel,
  EvenementTelesuiviDTOModel,
} from "@ads/isadom-model";

import {
  EvenementTelesuiviListHeader,
  EvenementTelesuiviListItem,
} from "../../molecules/evenementTelesuivi";
import { useHistory } from "react-router-dom";

const ListItemContainer = styled.div`
  position: relative;
  margin: 0 0 1.6em;
  transition: transform 0.1s ease-out;

  & button {
    transition: all 0.1s ease-out;
    transform: scale(1);
  }
  &:hover button {
    transform: scale(1.08);
    top: -1.85rem;
    right: -1.85rem;
  }
`;

interface EvenementTelesuiviListProps {
  visiteID: number;
  type: string;
  evenements: EvenementTelesuiviDTOModel[] | EvenementDTOModel[];
}

export const EvenementTelesuiviList = ({
  visiteID,
  type,
  evenements,
}: EvenementTelesuiviListProps) => {
  const history = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [evtToDelete, setEvtToDelete] =
    useState<EvenementTelesuiviDTOModel | EvenementDTOModel>();

  const removeEvenement = async () => {
    if (evtToDelete && evtToDelete.evenementVisiteID) {
      try {
        await apiDeleteEvenement(visiteID, evtToDelete.evenementVisiteID);
        notification.success({
          key: `form_success`,
          message: "Confirmation",
          description: "L'évènement a bien été supprimé.",
        });
        history.push(`/visites/${visiteID}/evenements/${type}`);
      } catch (error: any) {
        if (typeof error === "object") {
          errorAdapter(error)?.forEach((e: any) => {
            notification.error({
              key: v4(),
              message: "Erreur",
              description: e.message || "Erreur interne du serveur",
            });
          });
        } else {
          notification.error({
            key: `form_erreur`,
            message: "Erreur",
            description: error || "Erreur interne du serveur",
          });
        }
      }
    }
  };

  return (
    <>
      {evenements?.length > 0 && <EvenementTelesuiviListHeader />}
      {evenements.map((evt: EvenementTelesuiviDTOModel | EvenementDTOModel) => (
        <ListItemContainer key={JSON.stringify(evt)}>
          <EvenementTelesuiviListItem
            removeEvenement={() => {
              setEvtToDelete(evt);
              setConfirmDelete(true);
            }}
            visiteID={visiteID}
            type={type}
            evenement={evt as EvenementTelesuiviDTOModel}
          />
        </ListItemContainer>
      ))}
      <Modal
        visible={confirmDelete}
        onOk={() => {
          removeEvenement();
          setConfirmDelete(false);
        }}
        onCancel={() => setConfirmDelete(false)}
      >
        <p>
          Attention la suppression de l'évènement est irréversible. <br />
          Souhaitez-vous continuer ?
        </p>
      </Modal>
    </>
  );
};
