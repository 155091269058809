import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { Textarea } from "@ads/ui";
import { IconDeleteItem } from "@ads/ui";
import { v4 } from "uuid";
const WrapperDelete = styled.button `
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
`;
const WrapperComment = styled.div `
  textarea {
    ${({ theme }) => theme.media.md} {
      min-width: 640px;
    }
  }
`;
export function CommentModal(props) {
    const [comment, setComment] = useState(props.commentaire);
    const id = useMemo(() => v4(), []);
    return (_jsxs(_Fragment, { children: [_jsxs(WrapperDelete, Object.assign({ type: "button", onClick: () => {
                    setComment("");
                    props.setCommentaire && props.setCommentaire("");
                } }, { children: [_jsx(IconDeleteItem, { iconSize: "3em", iconColor: "#ffffff" }, void 0), " Effacer le commentaire"] }), void 0), _jsx(WrapperComment, { children: _jsx(Textarea, { id: `comment-champ-${id}`, name: `comment-champ-${id}`, value: comment, selectMode: "light", onChange: (e) => {
                        setComment(e.target.value);
                        props.setCommentaire && props.setCommentaire(e.target.value);
                    } }, void 0) }, void 0)] }, void 0));
}
