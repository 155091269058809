import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { useEffect, useState, } from "react";
import { ItemListUploader } from "./ItemListUploader";
import { Button, Loading } from "@ads/ui";
export function UploaderDocument(props) {
    const [isSubmiting, _setIsSubmiting] = useState(false);
    const [listDoc, setListDoc] = useState(props.data);
    useEffect(() => {
        setListDoc(props.data);
    }, [props.data]);
    return (_jsxs(_Fragment, { children: [_jsxs(Upload.Dragger, Object.assign({ accept: "*", beforeUpload: (_file, FileList) => {
                    props.addDocuments(FileList);
                }, listType: "picture", multiple: false, name: "signature", itemRender: (_originFile, _file, _selectedFile) => {
                    // Court-circuite l'affichage des fichiers de l'input
                    return _jsx(_Fragment, {}, void 0);
                } }, { children: [_jsx("p", Object.assign({ className: "ant-upload-drag-icon" }, { children: _jsx(CloudUploadOutlined, {}, void 0) }), void 0), _jsx("p", Object.assign({ className: "ant-upload-text" }, { children: "Glissez ou d\u00E9posez votre document ici" }), void 0), _jsx("p", { className: "ant-upload-hint" }, void 0)] }), void 0), 
            // Charge la liste des documents depuis le hook
            listDoc.map((e, i) => {
                return (_jsx(ItemListUploader, { stateLoading: props.isLoadingUpload, data: e, indexData: i, typesDoc: props.typeDocuments, deleteDoc: props.deleteDocument, modifyDoc: props.modifyTypeDoc }, i));
            }), isSubmiting && (_jsx("p", Object.assign({ style: { color: "white" }, className: "ant-upload-text" }, { children: "Envois en cours..." }), void 0)), props.isLoading && (_jsx(Loading, { children: "Chargement des documents de la visite" }, void 0)), props.data.length > 0 && (_jsx(Button, { as: "button", label: "Enregistrer", size: "S", buttonStyle: props.isLoadingUpload ? "neutral130" : "secondary", type: "button", isLoading: props.isLoadingUpload, disabled: props.isLoadingUpload, onClick: () => {
                    if (!props.isLoadingUpload) {
                        props.saveDocuments();
                    }
                } }, void 0))] }, void 0));
}
