import { TypeDocumentAdapter } from "./TypeDocumentAdapter";
import { Adapter } from "@ads/isadom-model";
export class DocumentVisiteAdapter extends Adapter {
    constructor() {
        super(...arguments);
        this.typeDocumentAdapter = new TypeDocumentAdapter();
    }
    toClient(src) {
        return {
            id: src.documentID,
            typeDocument: this.typeDocumentAdapter.toClient(src.typeDocument),
            URL: src.sURL,
            isDocumentExist: src.documentExist,
            visiteID: src.documentVisiteID,
        };
    }
    toAPI(src) {
        return {
            documentID: src.id,
            typeDocument: this.typeDocumentAdapter.toAPI(src.typeDocument),
            sURL: src.URL,
            documentExist: src.isDocumentExist,
            documentVisiteID: src.visiteID ? src.visiteID : 0,
        };
    }
}
