import {
  ObservanceVisiteAdapter,
  ObservanceVisiteAPI,
  ObservanceVisiteModel,
  HttpResponseAPI,
  HttpResponseModel,
} from "@ads/isadom-model";
import {
  getDefaultApiInstance,
  apiGetV2,
  apiPutV2,
} from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export const apiGetObservancesByVisiteID = async (
  visiteID: number
): Promise<ObservanceVisiteModel[]> => {
  const api = getDefaultApiInstance();

  if (api) {
    const adapter: ObservanceVisiteAdapter = new ObservanceVisiteAdapter();
    const resultAPI = await apiGetV2<ObservanceVisiteAPI[]>(
      api,
      `/visites/${visiteID}/observances`,
      apiIsadomErrorStrategy
    );

    return adapter.toClients(resultAPI);
  }

  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
};

export const apiPutObservancesByVisiteID = async (
  visiteID: number,
  observances: ObservanceVisiteModel[]
): Promise<HttpResponseModel<ObservanceVisiteModel[]>> => {
  const api = getDefaultApiInstance();

  if (api && visiteID && observances) {
    const adapterObservance = new ObservanceVisiteAdapter();
    const { data, messages }: HttpResponseAPI<ObservanceVisiteAPI[]> =
      await apiPutV2(
        api,
        `/visites/${visiteID}/observances`,
        adapterObservance.toAPIs(observances),
        apiIsadomErrorStrategy
      );

    return { data: adapterObservance.toClients(data), messages };
  }
  return new Promise((_, reject) => {
    reject("La configuration api est null");
  });
};
