import { useSelector } from "react-redux";
import { combineReducers } from "redux";
import { v4 as uuidv4 } from "uuid";
import { createAdsStore } from "..";
export class Module {
    constructor(name, slices, submodules) {
        this.reducers = {};
        this.slices = null;
        this.name = name;
        if (slices) {
            for (const slice of slices) {
                this.addSlice(slice.sliceFunction, slice.initialValue);
            }
        }
        this.submodules = submodules;
    }
    init() {
        this.id = uuidv4();
        this.initReducer();
        this.initSubmodules();
        this.initStore();
        return this.store;
    }
    initStore() {
        this.store = createAdsStore(this.reducers);
    }
    initSubmodules() {
        if (this.submodules) {
            for (const submodule of this.submodules) {
                submodule.init();
                this.reducers = Object.assign(Object.assign({}, this.reducers), submodule.reducers);
            }
        }
    }
    initReducer() {
        if (this.slices) {
            const reducers = {};
            for (const key in this.slices) {
                reducers[key] = this.slices[key].reducer;
            }
            this.reducers[this.name] = combineReducers(Object.assign({}, reducers));
        }
    }
    addSlice(sliceFunction, initialValue) {
        if (this.slices === null) {
            this.slices = {};
        }
        const slice = sliceFunction(initialValue);
        this.slices[slice.name] = slice;
    }
    getModuleSelectorFunction() {
        return () => useSelector((state) => state[this.name]);
    }
}
