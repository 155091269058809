import { ProgrammeEducatifModel } from "@ads/isadom-model";
import {
  BaseError,
  ERROR_DEV_UNKNOWN,
  ListItemAdapterProps
} from "@ads/react-common-module";
import { DeleteButton, Modal, ModalPropsFactory } from "@ads/ui";
import { Card, Divider } from "antd";
import moment from "moment";
import styled from "styled-components";

const CardProgramme = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 0 1rem rgb(80 70 70 / 25%);
  height: auto;
  cursor: pointer;

  ${({ theme }) => theme.media.md} {
    height: 7rem;
  }
`;

const CardProgrammeWrapper = styled.div`
  position: relative;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.015);
    .etape-libelle {
      color: ${({ theme }) => theme.colors.primary[100]};
    }
  }
`;

const DateWrapper = styled.span``;
const DateCardWrapper = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary[100]};
`;
const TypeProgrammeWrapper = styled.span`
  background-color: ${({ theme }) => theme.colors.neutral[130]};
  border-radius: 1.7rem;
  padding: 0.5rem 1.4rem;
  margin: 0 1rem;
  font-size: 1.2rem;
  line-height: 1.7rem;
  width: fit-content;
  height: fit-content;
`;
const EtapeLibelle = styled.span`
  transition: all 0.2s ease-in-out;
  font-size: 1.8rem;
`;
const DividerProgramme = styled(Divider)`
  display: none;
  ${({ theme }) => theme.media.md} {
    display: auto;
  }
`;
const WrapperButtonRAZ = styled.div`
  display: block;
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  padding: 0.5rem;
  cursor: pointer;
  ${({ theme }) => theme.media.sm} {
    top: -2.2rem;
    right: -2.2rem;
  }
`;

export interface PEClickItemID {
  id: number;
  source: "isadom" | "isanaut";
}

export interface ProgrammeEducatifListItemAdapterProps
  extends ListItemAdapterProps<ProgrammeEducatifModel, PEClickItemID> {}

export function ProgrammeEducatifListItemAdapter({
  data,
  services
}: ProgrammeEducatifListItemAdapterProps) {
  function handleItemClick() {
    if (services.onItemClick) {
      if (data.id && data.id !== 0) {
        services.onItemClick({
          id: data.id,
          source: "isadom"
        });
      } else if (data.nautID) {
        services.onItemClick({
          id: data.nautID,
          source: "isanaut"
        });
      } else {
        throw new BaseError(ERROR_DEV_UNKNOWN);
      }
    }
  }

  return (
    <CardProgrammeWrapper>
      <CardProgramme
        className="programme-educatif-list-item"
        onClick={handleItemClick}
      >
        <DateWrapper>
          Du{" "}
          <DateCardWrapper>
            {moment(data.dDebut).format("DD/MM/YYYY")}
          </DateCardWrapper>{" "}
          Au{" "}
          <DateCardWrapper>
            {moment(data.dFin).format("DD/MM/YYYY")}
          </DateCardWrapper>
        </DateWrapper>
        <DividerProgramme type="vertical" />
        <span>
          <TypeProgrammeWrapper>
            {data.typeProgrammeEducatif.libelle}
          </TypeProgrammeWrapper>{" "}
          <EtapeLibelle className="etape-libelle">
            {data.etape?.sLibelle}
          </EtapeLibelle>
        </span>
      </CardProgramme>
      {data.nautID && (
        <Modal
          {...ModalPropsFactory.createDeleteModalProps({
            onOk: () => {
              if (services.OnItemDeleteClick)
                services.OnItemDeleteClick({
                  id: data.nautID as number,
                  source: "isanaut"
                });
            },
            modalController: ({ showModal }) => (
              <WrapperButtonRAZ onClick={showModal}>
                <DeleteButton />
              </WrapperButtonRAZ>
            )
          })}
        />
      )}
    </CardProgrammeWrapper>
  );
}
