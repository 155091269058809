import { PPT, HttpResponseAPI, HttpResponseModel } from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiGetTypesVoies(): Promise<HttpResponseModel<PPT[]>> {
  try {
    const api = getDefaultApiInstance();

    if (!api) {
      throw new Error("La configuration d'api n'est pas initialisée");
    }

    const response: HttpResponseAPI<PPT[]> = await apiGetV2(
      api,
      `/parametres?table=ParTypeVoie`,
      apiIsadomErrorStrategy
    );

    return response;
  } catch (err: any) {
    return new Promise((_resolve, reject) => {
      reject(err.message);
    });
  }
}
