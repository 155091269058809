import { INavigationItem } from "@ads/ui";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";

export const NavigationCommande = () => {
  const { iPKVisite } = useParams<RouteParam>();

  const navItems: Array<INavigationItem> = [
    {
      to: `/visites/${iPKVisite}/commande`,
      label: "Nouvelle commande",
      active: true,
      visible: true
    }
  ];

  return (
    <NavigationPrimary navItems={navItems} isOpen={false} isLargeNav={true} />
  );
};
