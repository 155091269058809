var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter, } from "../..";
import { AffectationProduitAdapter } from "../../logistique";
import { TypePrestationAdapter } from "../../medical";
import { FamilleAdapter, ProduitAdapter } from "../../produit";
var LigneCommandeVisiteAdapter = /** @class */ (function (_super) {
    __extends(LigneCommandeVisiteAdapter, _super);
    function LigneCommandeVisiteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.familleAdapter = new FamilleAdapter();
        _this.produitAdapter = new ProduitAdapter();
        _this.typePrestationAdapter = new TypePrestationAdapter();
        _this.affectationProduitAdapter = new AffectationProduitAdapter();
        return _this;
    }
    LigneCommandeVisiteAdapter.prototype.toClient = function (src) {
        return {
            ID: src.ligneCommandeID,
            famille: src.famille && this.familleAdapter.toClient(src.famille),
            produit: src.produit && this.produitAdapter.toClient(src.produit),
            quantite: src.quantite,
            affectationProduit: src.affectationProduit &&
                this.affectationProduitAdapter.toClient(src.affectationProduit),
            dateLivraisonSouhaitee: src.dLivraisonSouhaite,
            typePrestation: src.typePrestation &&
                this.typePrestationAdapter.toClient(src.typePrestation),
            independante: src.independante,
        };
    };
    LigneCommandeVisiteAdapter.prototype.toAPI = function (src) {
        return {
            ligneCommandeID: src.ID,
            famille: src.famille && this.familleAdapter.toAPI(src.famille),
            produit: src.produit && this.produitAdapter.toAPI(src.produit),
            quantite: src.quantite,
            affectationProduit: src.affectationProduit &&
                this.affectationProduitAdapter.toAPI(src.affectationProduit),
            dLivraisonSouhaite: src.dateLivraisonSouhaitee,
            typePrestation: src.typePrestation &&
                this.typePrestationAdapter.toAPI(src.typePrestation),
            independante: src.independante,
        };
    };
    return LigneCommandeVisiteAdapter;
}(Adapter));
export { LigneCommandeVisiteAdapter };
