var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { notification } from "antd";
import { apiEditeConsommableConfigurationVisite } from "../api/apiEditeConsommableConfigurationVisite";
export function restaureConfigurationConsommable({ api, visiteID, configurationConsommable, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const configDTO = Object.assign(Object.assign({}, configurationConsommable), { bRetraitConfig: false });
        try {
            yield apiEditeConsommableConfigurationVisite(api, visiteID, configDTO);
            notification.success({
                key: `${configurationConsommable.configCsoVisiteID}`,
                message: "Confirmation",
                description: "Le consommable a bien été restauré.",
            });
            return true;
        }
        catch (err) {
            console.error("👨‍🚒 Une erreur est survenue dans restaureConfigurationConsommable", err);
            return false;
        }
    });
}
