import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import config from "config";
import {
  EvenementStandardModel,
  EvenementStandardAdapter,
  VisiteAdapter,
} from "@ads/isadom-model";
import styled from "styled-components";
import { apiIsadomErrorStrategy, Breadcrumb, FormWidget, Loading } from "@ads/ui";
import { useHistory, useParams } from "react-router-dom";
import { notification } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import MenuTab from "components/ui/molecules/MenuTab";
import ContentTab from "components/ui/molecules/ContentTab";
import {
  EvenementStandardDetailsForm,
  EvenementStandardInformationsForm,
} from "../../molecules/evenementStandard";
import { useAddEvenementMutation, useGetVisiteQuery, useUpdateEvenementMutation } from "store/redux/services/nomadApi";
import { RouteParam } from "interface/core/RouteParam";
import useGetEvenement from "modules/evenement/hooks/useGetEvenement";
import getBreadcrumbFormEvenement from "modules/evenement/functions/getBreadcrumbFormEvenement";


const InformationsGenerales = styled.div`
  max-width: 80rem;
`;

const Content = styled.div`
  ${({ theme }) => theme.media.md} {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

const tabActive = "non-reserves"

export const EvenementStandardForm = () => {
  const methods = useForm();
  const history = useHistory();
  const { iPKVisite, evenementID } = useParams<RouteParam>()
  const { data: visiteAPI, isLoading: isLoadingVisite } = useGetVisiteQuery(+iPKVisite)
  const visiteClient = visiteAPI && new VisiteAdapter().toClient(visiteAPI);
  const { evenement, loading: isLoadingEvenement } = useGetEvenement(evenementID, tabActive, visiteClient)

  if (!visiteClient) return <></>

  const breadcrumbsItems = getBreadcrumbFormEvenement(evenement, visiteClient, tabActive)

  const evenementVide: EvenementStandardModel = {
    ID: undefined,
    evenementVisiteID: undefined,
    typeEvenement: { ID: "", precisions: [] },
    dateDebut: "0000-00-00",
    dateFin: "0000-00-00",
    estAnnule: false,
    tiers: visiteClient.tiersVisite,
    informations: "",
    motif: "",
    tiersOrigine: undefined,
    lieuExercice: undefined,
    prestation: undefined,
    prescription: undefined,
    cure: undefined,
    precision: undefined,
  };

  const [formData, setFormData] = useState<EvenementStandardModel>(
    evenement || evenementVide
  );

  useEffect(() => {
    if (evenement)
      setFormData(evenement)
  }, [evenement])

  const handleChange = (formData: EvenementStandardModel) => {
    setFormData(formData);
  };

  const [updateEvenement, { isLoading: isLoadingUpdateEvenement }] = useUpdateEvenementMutation()
  const [addEvenement, { isLoading: isLoadingAddEvenement }] = useAddEvenementMutation()
  const adapter = new EvenementStandardAdapter();

  const handleSave = () => {
    /**
     * Si il n'y a pas d'evenementVisiteId il faut faire une requête post (edit d'un évènement ISADOM)
     */
    const evenementApi = adapter.toAPI(formData);
    // Ajout d'un évènement
    if (!evenement || (evenement && !evenement.evenementVisiteID)) {
      addEvenement({ body: evenementApi, params: { visiteId: visiteClient.ID } }).unwrap().then(() => {
        notification.success({
          key: `form_success`,
          message: "Confirmation",
          description: "L'évènement a bien été enregistré.",
        });
        history.push(`/visites/${visiteClient.ID}/evenements/non-reserves`);
      })
        .catch((error: any) => apiIsadomErrorStrategy(error))
    }
    // Mise à jour d'un évènement
    else {
      if (evenement.evenementVisiteID) {
        updateEvenement({ body: evenementApi, params: { visiteId: visiteClient.ID, evenementVisiteId: evenement.evenementVisiteID } }).unwrap()
          .then(() => {
            notification.success({
              key: `form_success`,
              message: "Confirmation",
              description: "L'évènement a bien été mis à jour.",
            });
            history.push(`/visites/${visiteClient.ID}/evenements/non-reserves`);
          })
          .catch((error: any) => apiIsadomErrorStrategy(error))
      }
    }
  };

  const handleCancel = () => {
    history.push(`/visites/${visiteClient.ID}/evenements/non-reserves`);
  };

  // On affiche uniquement le loading en cas d'édition d'un évènement
  if (evenementID !== "nouveau" && (isLoadingVisite || isLoadingEvenement)) {
    return <Loading>Chargement de l'évènement.</Loading>
  }

  const title = evenement ? `Évènement ${evenement.typeEvenement.libelle}` : "Nouvel Évènement";

  return (
    <>
      <Helmet title={`${title} - NOMAD v${config.version}`} />
      {breadcrumbsItems && <Breadcrumb breadcrumbItems={breadcrumbsItems} size="M" />}

      <FormWidget handleCancel={handleCancel} handleSave={handleSave} isLoadingSave={isLoadingUpdateEvenement || isLoadingAddEvenement}>
        <MenuTab
          tabs={[
            { id: "informations", label: "Informations générales", url: "#" },
          ]}
          subTabActive={"informations"}
        />
        <ContentTab>
          <FormProvider {...methods}>
            <InformationsGenerales>
              <Content>
                <EvenementStandardInformationsForm
                  evenement={formData}
                  handleChange={handleChange}
                />
                <EvenementStandardDetailsForm
                  visite={visiteClient}
                  evenement={formData}
                  handleChange={handleChange}
                />
              </Content>
            </InformationsGenerales>
          </FormProvider>
        </ContentTab>

        {/* <EvenementStandardReleves evenement={evenement} /> // TODO : v2  */}
      </FormWidget>
    </>
  );
};
