var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { BlocNoteTypeNoteAdapter } from "./BlocNoteTypeNoteAdapter";
var BlocNoteAdapter = /** @class */ (function (_super) {
    __extends(BlocNoteAdapter, _super);
    function BlocNoteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.adapterBlocNoteTypeNote = new BlocNoteTypeNoteAdapter();
        return _this;
    }
    BlocNoteAdapter.prototype.toClient = function (src) {
        return {
            id: src.blocNoteID,
            information: src.sInformation,
            masquer: src.bMasquer,
            typeNote: this.adapterBlocNoteTypeNote.toClient(src.typeNote),
            date: src.dInsert,
        };
    };
    BlocNoteAdapter.prototype.toAPI = function (src) {
        return {
            bMasquer: src.masquer,
            blocNoteID: src.id,
            sInformation: src.information,
            typeNote: this.adapterBlocNoteTypeNote.toAPI(src.typeNote),
            dInsert: src.date,
        };
    };
    return BlocNoteAdapter;
}(Adapter));
export { BlocNoteAdapter };
