import { EvenementStandardModel } from "@ads/isadom-model";
import ReactQuill from "react-quill";

import { Informations } from "../../atoms/itemFields";
import "react-quill/dist/quill.snow.css";

interface EvenementStandardInformationsFormProps {
  evenement: EvenementStandardModel;
  handleChange: (evenement: EvenementStandardModel) => void;
}

export const EvenementStandardInformationsForm = ({
  evenement,
  handleChange,
}: EvenementStandardInformationsFormProps) => (
  <Informations>
    <ReactQuill
      theme="snow"
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"], // remove formatting button
          // [{ list: "ordered" }, { list: "bullet" }],
          // [{ script: "sub" }, { script: "super" }],
          // [{ indent: "-1" }, { indent: "+1" }],
        ],
      }}
      value={evenement?.informations || ""}
      onChange={(content) =>
        handleChange({
          ...evenement,
          informations: content,
        } as EvenementStandardModel)
      }
    />
  </Informations>
);
