import { INavigationItem } from "@ads/ui";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";

export function NavigationSuiviPatient() {
  const { iPKVisite, tabActive } = useParams<RouteParam>();

  const navItems: INavigationItem[] = [
    {
      to: `/visites/${iPKVisite}/programmes/programmes-educatifs`,
      label: "Programmes Educatifs",
      active: tabActive === "programmes-educatifs",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/programmes/diagnostics-educatifs`,
      label: "Diagnostics Educatifs",
      active: tabActive === "diagnostics-educatifs",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/programmes/observations`,
      label: "Observations",
      active: tabActive === "observations-educatifs",
      visible: true
    }
  ];

  return (
    <NavigationPrimary
      navItems={navItems || []}
      isOpen={false}
      isLargeNav={false}
    />
  );
}
