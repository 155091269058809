import { INavigationItem } from "@ads/ui";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";

const NavigationQuestionnaire = () => {
  const { tabActive, iPKVisite } = useParams<RouteParam>();

  const navItems: INavigationItem[] = [
    {
      to: `/visites/${iPKVisite}/questionnaires/obligatoires`,
      label: "Obligatoires",
      active: tabActive === "" || tabActive === "obligatoires",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/questionnaires/supplémentaires`,
      label: "Supplémentaires",
      active: tabActive === "" || tabActive === "supplémentaires",
      visible: true
    },
    {
      to: `/visites/${iPKVisite}/questionnaires/historique`,
      label: "Historique",
      active: tabActive === "" || tabActive === "historique",
      visible: true
    }
  ];

  return (
    <NavigationPrimary
      navItems={navItems || []}
      isOpen={false}
      isLargeNav={false}
    />
  );
};

export default NavigationQuestionnaire;
