import styled from "styled-components";
export const ConsommableConfigurationDetailInfo = styled.div `
  margin: 0 0 2rem;
  display: grid;
  gap: 3rem;
  grid-template-columns: 95px auto;

  &:last-child {
    margin: 0;
  }

  ${({ theme }) => theme.media.sm} {
    margin: 0;
    display: block;
    gap: 0;
  }
`;
