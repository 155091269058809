var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../..";
var TypeEvenementAdapter = /** @class */ (function (_super) {
    __extends(TypeEvenementAdapter, _super);
    function TypeEvenementAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TypeEvenementAdapter.prototype.toClient = function (src) {
        return {
            ID: src.typeEvenementID,
            libelle: src.sLibelle,
            estPeriodeValidite: src.bPeriodeValidite,
            estPatient: src.bPatient,
            estReserve: src.bReserver,
            estConsentement: src.bConsentement,
            estFinPeriodeObligatoire: src.bFinPeriodeObligatoire,
            estPrecisionObligatoire: src.bPrecisionObligatoire,
            precisions: src.tPrecision,
        };
    };
    TypeEvenementAdapter.prototype.toAPI = function (src) {
        return {
            typeEvenementID: src.ID,
            sLibelle: src.libelle,
            bPeriodeValidite: src.estPeriodeValidite,
            bPatient: src.estPatient,
            bReserver: src.estReserve,
            bConsentement: src.estConsentement,
            bFinPeriodeObligatoire: src.estFinPeriodeObligatoire,
            bPrecisionObligatoire: src.estPrecisionObligatoire,
            tPrecision: src.precisions,
        };
    };
    return TypeEvenementAdapter;
}(Adapter));
export { TypeEvenementAdapter };
