var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormItemWrapper, SelectWrapper, Label, Textarea, Loading, Breadcrumb, FormWidget, } from "@ads/ui";
import { Select, Checkbox, Skeleton, Alert } from "antd";
import styled from "styled-components";
import { useBlocNoteForm } from "../hook/useBlocNoteForm";
import { createRef } from "react";
const { Option } = Select;
const Content = styled("div") `
  transform-style: preserve-3d;
  position: relative;
  padding-top: 2.5rem;
  padding: 2.2rem 1.9rem;
  border-radius: 0.5rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  box-shadow: 0 8px 1rem rgb(80 70 70 / 25%);
  margin-bottom: 8.6rem;

  &::before {
    transform: translateZ(-1px);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 1rem;
    width: 100%;
    /* box-shadow: 1px -4px 12px rgb(80 70 70 / 21%); */
    box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  }

  ${({ theme }) => theme.media.md} {
    padding: 4rem 6rem;
    margin-bottom: 0;
    box-shadow: 0 4px 1rem rgba(80, 70, 70, 0.25);
  }
`;
const Form = styled("div") `
  max-width: 80rem;
`;
export const BlocNoteForm = (props) => {
    var _a;
    const input = {
        idBlocNote: props.blocNoteID,
        idVisite: props.visiteID,
        isDeletable: function (note) {
            {
                return note.id === 0;
            }
        },
    };
    const controler = useBlocNoteForm(input);
    const handleSaveNote = () => __awaiter(void 0, void 0, void 0, function* () {
        yield controler.saveNote();
    });
    const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        yield controler.deleteNote();
    });
    const data = [
        {
            item: "Bloc-notes",
            to: `/visites/${props.visiteID}/bloc-notes`,
        },
        {
            item: "Edition de note",
            to: "",
        },
    ];
    const refForm = createRef();
    const handleChangeTypeNote = (value) => {
        controler.typeNotes.forEach((e) => {
            if (e.id === value) {
                if (e.texteParDefaut != "") {
                    controler.setNote({
                        typeNoteID: value,
                        text: e.texteParDefaut,
                        isClosed: controler.note.isClosed,
                    });
                    return;
                }
                else {
                    controler.setNote({
                        typeNoteID: value,
                        text: controler.note.text,
                        isClosed: controler.note.isClosed,
                    });
                }
            }
        });
    };
    if (controler.isLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Loading, { children: "Chargement en cours de la note" }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0)] }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Breadcrumb, { size: "M", breadcrumbItems: data }, void 0), _jsx(FormWidget, Object.assign({ handleSave: handleSaveNote, 
                // On rend la suprression possible que si la note est supprimable
                handleDelete: controler.noteModel && controler.isDeletable(controler.noteModel)
                    ? () => {
                        handleDelete();
                    }
                    : undefined }, { children: _jsxs(Content, { children: [controler.error && (_jsx(Alert, { message: "Erreur", showIcon: true, description: "Erreur de chargement de la note", type: "error" }, void 0)), _jsxs(Form, Object.assign({ ref: refForm }, { children: [_jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "typeNoteID" }, { children: "Type de notes" }), void 0), _jsxs(SelectWrapper, Object.assign({ selectMode: "light" }, { children: [props.blocNoteID === "nouveau" && (_jsx(Select, Object.assign({ id: "typeNoteID", showSearch: true, value: controler.note.typeNoteID, onSelect: handleChangeTypeNote, placeholder: "S\u00E9lectionnez le type de Note" }, { children: controler.typeNotes.map((typeNote) => (_jsx(Option, Object.assign({ value: typeNote.id }, { children: typeNote.libelle }), `${typeNote.id}`))) }), void 0)), props.blocNoteID !== "nouveau" && controler.noteModel && (_jsx(Label, Object.assign({ htmlFor: "typeNoteID" }, { children: controler.noteModel.typeNote.libelle }), void 0)), _jsx(Checkbox, Object.assign({ defaultChecked: (_a = controler.noteModel) === null || _a === void 0 ? void 0 : _a.masquer, onChange: (e) => controler.setNote({
                                                        typeNoteID: controler.note.typeNoteID,
                                                        text: controler.note.text,
                                                        isClosed: e.target.checked,
                                                    }) }, { children: "Note Cl\u00F4tur\u00E9e" }), void 0)] }), void 0)] }), void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "corps" }, { children: "Note : " }), void 0), _jsx(Textarea, { id: "corps", name: "corps", value: controler.note.text, onChange: (e) => {
                                                controler.setNote({
                                                    typeNoteID: controler.note.typeNoteID,
                                                    text: e.target.value,
                                                    isClosed: controler.note.isClosed,
                                                });
                                            }, selectMode: "light", placeholder: "Saisissez le corps de la  note" }, void 0)] }), void 0)] }), void 0)] }, void 0) }), void 0)] }, void 0));
};
