import { IConstanteModel } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { apiGetConstanteRespiratoireListByVisiteID } from "../api/apiGetConstanteRespiratoireListByVisiteID";

export async function getConstanteRespiratoireListByVisiteID(
  visiteID: number
): Promise<IConstanteModel[]> {
  const api = getDefaultApiInstance();
  return (await apiGetConstanteRespiratoireListByVisiteID(api, visiteID)).data;
}
