import { ProgrammeEducatifModel } from "@ads/isadom-model";
import { DataSourceUpsertResult } from "@ads/react-common-module";
import { useApiPutProgrammeEducatifVisite } from "../api";
import { useSelectPEStore } from "../components/context/PEContext";

export function useUpdatePEVisite(
  visiteID: number
): DataSourceUpsertResult<
  ProgrammeEducatifModel,
  Record<string, string | number>
> {
  const { addPE } = useSelectPEStore();
  const service = useApiPutProgrammeEducatifVisite({ visiteID });
  async function internalUpdate(
    body: ProgrammeEducatifModel,
    pathParameters?: Record<string, string | number>
  ) {
    const result = await service.request(body, pathParameters);
    addPE(result);
    return result;
  }
  return {
    ...service,
    request: internalUpdate
  };
}
