import {
  LieuExerciceMedecinModel,
  LieuExerciceMedecinAdapter,
} from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";

export function apiGetLieuxExerciceByTiersID(
  tiersID: number
): Promise<LieuExerciceMedecinModel[]> {
  const api = getDefaultApiInstance();
  if (api) {
    const adapter = new LieuExerciceMedecinAdapter();
    return apiGet(
      api,
      `tiers/${tiersID}/lieux-exercices?dto=0`,
      adapter
    ) as Promise<LieuExerciceMedecinModel[]>;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
