var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter, FormBoolAdapter } from "../../..";
import { TypeChampAdapter } from "./TypeChampAdapter";
import { StringParametrageIDAdapter } from '../params/StringParametrageIDAdapter';
import { ContrainteRecupereValeurAdapter } from '../contrainte/ContrainteRecupereValeurAdapter';
var ChampBoolAdapter = /** @class */ (function (_super) {
    __extends(ChampBoolAdapter, _super);
    function ChampBoolAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeChampAdapter = new TypeChampAdapter();
        _this.stringParamAdapter = new StringParametrageIDAdapter();
        _this.valeurBoolAdapter = new FormBoolAdapter();
        _this.contrainteAdapter = new ContrainteRecupereValeurAdapter();
        return _this;
    }
    ChampBoolAdapter.prototype.toClient = function (src) {
        return {
            estActif: src.bActif,
            id: src.id.sID,
            information: src.sInformation,
            libelle: src.sLibelle,
            module: src.sModule,
            ordre: src.iOrdre,
            contrainteRecupereValeurPrecedent: this.contrainteAdapter.toClient(src.contrainteRecupereValeurPrecedente),
            typeChamp: this.typeChampAdapter.toClient(src.typeChamp),
            valeur: this.valeurBoolAdapter.toClient(src.valeur),
            version: src.id.iVersion
        };
    };
    ChampBoolAdapter.prototype.toAPI = function (src) {
        var idToApi = {
            id: src.id,
            version: src.version
        };
        return {
            bActif: src.estActif,
            iOrdre: src.ordre,
            id: this.stringParamAdapter.toAPI(idToApi),
            sInformation: src.information,
            sLibelle: src.libelle,
            sModule: src.module,
            contrainteRecupereValeurPrecedente: this.contrainteAdapter.toAPI(src.contrainteRecupereValeurPrecedent),
            typeChamp: this.typeChampAdapter.toAPI(src.typeChamp),
            valeur: this.valeurBoolAdapter.toAPI(src.valeur),
        };
    };
    return ChampBoolAdapter;
}(Adapter));
export { ChampBoolAdapter };
