var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from '../../../_adapter';
var CiviliteAdapter = /** @class */ (function (_super) {
    __extends(CiviliteAdapter, _super);
    function CiviliteAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CiviliteAdapter.prototype.toClient = function (src) {
        return {
            ID: src.iPKParametre,
            code: src.sCode,
            libelle: src.sLibelle,
            table: src.sTable,
        };
    };
    CiviliteAdapter.prototype.toAPI = function (src) {
        return {
            iPKParametre: src.ID,
            sCode: src.code,
            sLibelle: src.libelle,
            sTable: src.table,
        };
    };
    return CiviliteAdapter;
}(Adapter));
export { CiviliteAdapter };
