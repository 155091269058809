import { IParametreEditionModel } from "@ads/isadom-model";
import { ListDataNotifier } from "@ads/ui";
import { IBaseParametreEditionListDataNotifier } from "../IBaseParametreEditionListDataNotifier";
import { IBaseParametreEditionListDataListener } from "../IBaseParametreEditionListDataListener";
/**
 * @description
 *  Implémentation basique de l'interface IBaseParametreEditionListDataNotifier
 * 
 */
export class BaseParametreEditionListDataNotifier
  extends ListDataNotifier<IParametreEditionModel,IBaseParametreEditionListDataListener>
  implements IBaseParametreEditionListDataNotifier {
  registerListener(
    listener: IBaseParametreEditionListDataListener
  ): boolean {
    try {
      if (listener.idListener && listener.idListener !== undefined) {
        this.listenerMap.set(listener.idListener, listener);
        return true;
    }
    return false;
} catch (err) {
      console.log(err);
      return false;
    }
  }

  notifyParametreEditionAddClick(item: IParametreEditionModel) {
    for (const listener of this.listenerMap.values()) {
      if (listener.onAddParametreEditionClick) {
        listener.onAddParametreEditionClick(item);
      }
    }
  }
}