import { IConstanteModel } from "@ads/isadom-model";
import { FormItemWrapper, Input, Label } from "@ads/ui";
import Card from "components/ui/molecules/Card";
import { Row, Col } from "antd";
import { useEffect, useState } from "react";

interface ConstanteListItemPropsCallbacks {
  onChange?(data: IConstanteModel): Promise<IConstanteModel>;
}

export interface ConstanteListItemProps {
  data: IConstanteModel;
  callbacks?: ConstanteListItemPropsCallbacks;
}

export function ConstanteListItem(props: ConstanteListItemProps) {
  const [constante, setConstante] = useState<IConstanteModel>(props.data);

  useEffect(() => {
    if (props.callbacks?.onChange) {
      props.callbacks.onChange(constante);
    }
  }, [constante, props.callbacks]);

  return (
    <>
      {constante && (
        <Card>
          <Label htmlFor={"libelle_" + constante.libelle + "_" + constante.id}>
            {constante.libelle}
          </Label>
          <Row gutter={8}>
            <Col lg={8}>
              <ConstanteItem
                htmlFor={"derniere_valeur_" + constante.id}
                id={constante.id}
                valeur={constante.valeurPrecedente}
                type="text"
                libelle="Dernière valeur"
                disabled={true}
              />
            </Col>
            <Col lg={8}>
              <ConstanteItem
                htmlFor={"date_releve_" + constante.id}
                id={constante.id}
                valeur={constante.dateSaisieValeur}
                type="date"
                libelle="Date du relevé"
                onUpdate={(data: string) => {
                  setConstante({ ...constante, dateSaisieValeur: data });
                }}
              />
            </Col>
            <Col lg={8}>
              <ConstanteItem
                htmlFor={"valeur_releve_" + constante.id}
                id={constante.id}
                valeur={constante.valeur}
                type="text"
                libelle="Valeur du relevé"
                onUpdate={(data: string) => {
                  setConstante({ ...constante, valeur: data });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <ConstanteItem
                htmlFor={"commentaire_" + constante.id}
                id={constante.id}
                valeur={constante.information}
                type="text"
                libelle="Commentaire"
                onUpdate={(data: string) => {
                  setConstante({ ...constante, information: data });
                }}
              />
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}

interface ConstanteItemProps<
  DataType extends string | number | string[] | Date | undefined
> {
  id: number;
  type: string;
  valeur: DataType;
  libelle: string;
  htmlFor: string;
  disabled?: boolean;
  onUpdate?(value: DataType): void;
}

function ConstanteItem<DataType extends string | number | string[] | undefined>(
  props: ConstanteItemProps<DataType>
) {
  return (
    <FormItemWrapper direction="vertical">
      {props.libelle}
      <Input
        id={props.htmlFor}
        type={props.type}
        name={props.htmlFor}
        selectMode="light"
        defaultValue={props.valeur}
        value={props.valeur}
        disabled={props.disabled}
        onChange={(e) => {
          if (props.onUpdate) {
            props.onUpdate(e.target.value as DataType);
          }
        }}
      />
    </FormItemWrapper>
  );
}
