import styled from "styled-components";

import {
  Breadcrumb,
  ButtonCircle,
  FormItemWrapper,
  FormWidget,
  Label,
  Textarea,
} from "@ads/ui";
import { Card, DatePicker, TimePicker } from "antd";
import locale from "antd/es/date-picker/locale/fr_FR";
import moment from "moment";
import { InfoRdvVisite } from "../../InforRdvVisite/ui/InfoRdvVisite";
import { SelectAdresse } from "../../SelectAdresseByTiers/ui/SelectAdresse";
import { SelectLocalisation } from "../../SelectLocalisation/ui/SelectLocalisation";
import { SelectTiersVisite } from "../../SelectTiersVisite/ui/SelectTiersVisite";
import { SelectTypeVisiteTypePrestaVisite } from "../../SelectTypeVisiteTypePrestaVisite/ui/SelectTypeVisiteTypePrestaVisite";
import { IFormCreerVisiteHookService } from "../hook/interfaces/IFormCreerVisiteHookService";
import SubTitleForm from "components/ui/atoms/titles/SubTitleForm";
import { useHistory } from "react-router-dom";

export interface FormCreerVisiteProps {
  services: IFormCreerVisiteHookService;
}

const WrapperMain = styled.div`
  max-width: 144rem;
  margin: 0 auto;
`;

const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  ${({ theme }) => theme.media.xl} {
    display: grid;

    grid-template-columns: 1fr;
    grid-template-columns: auto 33%;

    &.one-col {
      grid-template-columns: auto;
    }
    text-area {
      max-width: 52rem;
    }
  }

  .form-item.direction-horizontal {
    flex-direction: column;
    align-items: flex-start;
    ${({ theme }) => theme.media.md} {
      flex-direction: row;
      align-items: center;
    }
  }

  label {
    min-width: 18rem;
  }

  .column {
    position: relative;
  }

  .form-widget-background {
    position: relative;
    margin-top: 0;
  }

  .sub-title {
    font-size: 1.7rem;
    font-family: "PoppinsBold", sans-serif;
    margin-bottom: 1.7rem;
  }

  .ant-card-body {
    padding: 1.2rem;
    ${({ theme }) => theme.media.md} {
      padding: 2.4rem;
    }
  }
`;

const WrapperTypeVisitePrestation = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperItemsForm = styled.div`
  margin: 4rem 0;
`;

const WrapperDatePicker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 2.5rem;
`;

const SelectAdressWrapper = styled.div`
  max-width: 33rem;
`;

const breadcrumbItems = [
  {
    to: "/feuille-de-route/a-faire",
    item: "Feuille de route",
  },
  {
    to: "",
    item: "Création de visite",
  },
];

export function FormCreerVisite({ services }: FormCreerVisiteProps) {
  const history = useHistory();

  return (
    <>
      <WrapperMain>
        <Breadcrumb size="M" breadcrumbItems={breadcrumbItems} />
        <FormWidget
          handleSave={() => {
            services.createVisite();
          }}
          isLoadingSave={services.visiteCreating}
          handleCancel={() => {
            if (services.serviceInfoRdv.rdvOrVisite) {
              history.push(
                "/feuille-de-route/a-faire#visite-" +
                services.serviceInfoRdv.rdvOrVisite.ID
              );
            } else {
              history.push("/feuille-de-route/en-cours");
            }
          }}
        >
          <WrapperForm
            className={`${services.serviceInfoRdv.rdvOrVisite
              ? "wrapper-form-creer-visite"
              : "wrapper-form-creer-visite one-col"
              }`}
          >
            <div className="column">
              <Card>
                <SubTitleForm className="title">
                  Formulaire de création de visite
                </SubTitleForm>

                <div>
                  {/*======================
                    Date heure visite
                  ======================*/}
                  <Label selectMode="dark" htmlFor="dFin">
                    Date et heure de début de visite
                  </Label>
                  <WrapperDatePicker>
                    <DatePicker
                      value={moment(services.dDebut, "YYYY-MM-DD")}
                      format="DD/MM/YYYY"
                      locale={locale}
                      onChange={(value) => {
                        if (value) {
                          services.notifyDateDebutChange(value);
                        }
                      }}
                      placeholder="Date"
                    />
                    <TimePicker
                      value={moment(services.hDebut, "HH:mm")}
                      placeholder="Heure"
                      format={"HH:mm"}
                      onChange={(value) => {
                        if (value) {
                          services.notifyHeureDebutChange(value);
                        }
                      }}
                    />
                    <div>
                      <ButtonCircle
                        backgroundColorButton="#ED5B2E"
                        iconColor="#FFF"
                        size="4rem"
                        icon="chrono"
                        onClick={() => {
                          const currentDate = moment();
                          services.notifyDateHeureDebutChange(currentDate);
                        }}
                      />
                    </div>
                  </WrapperDatePicker>
                  {/*======================
                    Information de la visite
                  ======================*/}
                  <FormItemWrapper>
                    <Label htmlFor="InfoVisite">Informations</Label>
                    <Textarea
                      id="infoVisiteRDV"
                      name="infoVisiteRDV"
                      onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                        services.notifyInformationsChanged(
                          e.currentTarget.value
                        );
                      }}
                    />
                  </FormItemWrapper>

                  {/*======================
                Tiers visité
              ======================*/}
                  <SelectTiersVisite
                    label="Tiers visité"
                    services={services.serviceSelectTiersVisite}
                  />

                  {services.tiersVisite && (
                    <SelectAdressWrapper>
                      <SelectAdresse
                        services={services.serviceSelectionAdresseByTiers}
                        label="Adresse de la visite"
                      />
                    </SelectAdressWrapper>
                  )}
                </div>
                <div>
                  <WrapperItemsForm className="localisations">
                    <h3 className="sub-title">Intervenants</h3>

                    {/*======================
                Intervenant principal
              ======================*/}
                    <SelectTiersVisite
                      label="Intervenant principal"
                      services={services.serviceSelectIntervenantPrincipal}
                    />
                    {/*======================
                Intervenant secondaire
              ======================*/}
                    <SelectTiersVisite
                      label="Intervenant secondaire"
                      services={services.serviceSelectIntervenantSecondaire}
                    />
                  </WrapperItemsForm>

                  {services.hasIntervenantPrincipal && (
                    <>
                      <WrapperItemsForm className="localisations">
                        <h3 className="sub-title">Localisations par défaut</h3>
                        {/*======================
                      Localisation DM
                    ======================*/}
                        <SelectLocalisation
                          services={services.serviceLocalisationPrelevementDM}
                          label="Prélèvement DM"
                        />
                        {/*======================
                      Localisation DMC
                      ======================*/}
                        <SelectLocalisation
                          services={services.serviceLocalisationPrelevementDMC}
                          label="Prélèvement DMC"
                        />
                      </WrapperItemsForm>
                      {/*======================
                          TV / TP
                        ======================*/}
                      <WrapperItemsForm>
                        <WrapperTypeVisitePrestation>
                          <div>
                            <Label htmlFor="types-visite">
                              Types de visites & types de prestations
                            </Label>
                          </div>
                          <div>
                            <SelectTypeVisiteTypePrestaVisite
                              services={services.serviceTypeVisiteTypePresta}
                            />
                          </div>
                        </WrapperTypeVisitePrestation>
                      </WrapperItemsForm>
                    </>
                  )}
                </div>
              </Card>
            </div>

            {services.serviceInfoRdv.rdvOrVisite &&
              services.serviceInfoRdv.rdvOrVisite?.ID !== 0 && (
                <div className="column">
                  <Card>
                    <InfoRdvVisite
                      services={services.serviceInfoRdv}
                      libelle="Rendez-vous"
                    />
                  </Card>
                </div>
              )}
          </WrapperForm>
        </FormWidget>
      </WrapperMain>
    </>
  );
}
