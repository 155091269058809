import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Loading } from "@ads/ui";
import { FormProvider, useForm } from "react-hook-form";
import { RouteParam } from "../../../../interface/core/RouteParam";
import {
  AdresseAPI,
  ConfigAppareilSpecTiersVisiteAPI,
  ConfigAppSpecTiersDTOModel,
  ModeleAPI,
  TypePrestationAPI
} from "@ads/isadom-model";
import WrapperExitAnimation from "components/core/animations/WrapperExitAnimation";
import TitleMain from "components/ui/atoms/titles/TitleMain";
import ListeAppareils from "../organisms/ListeAppareils";
import FiltresListeConfigurationAppareils from "../molecules/FiltresListeConfigurationAppareils";
import SelectAppareils from "../molecules/SelectAppareils";
import { useApiGetConfigAppDTOByVisiteID } from "modules/appareils/hooks/useApiGetConfigAppDTOByVisiteID";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";

const Wrapper = styled(WrapperExitAnimation)<PropsWrapper>`
  .ant-select-clear {
    opacity: 1;
    background: white;
    top: 40%;
    svg {
      width: 2em;
      height: 2em;
      color: ${({ theme }) => theme.colors.danger[100]};
    }
  }
  .ant-select-arrow {
    svg {
      display: ${(props) =>
        props.prestation.length === 0 ? "inherit" : "none"};
    }
  }
  .rc-virtual-list-holder {
    max-width: 8em;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`;

const WrapperFiltres = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 2rem;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    gap: 2rem;
  }
`;

const TitreConfigurationAppareils = styled(TitleMain)`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
export interface PropsFilters {
  prestation: string[];
  masquerAppareilsAbsents: boolean;
  idProduit?: number;
}

export interface PropsWrapper {
  prestation: string[];
}

/**
 * @description DETTE TECHNIQUE - cette partie initialement manipulé directement des ConfigAppareilSpecTiersVisiteAPI
 * c'est pourquoi on reconvertit les objets Model en objets Api
 */
function transformSpecDTOToSpec(
  specDTO: ConfigAppSpecTiersDTOModel
): ConfigAppareilSpecTiersVisiteAPI {
  return {
    adresseInstallation: {
      iPKAdresse: specDTO.localisationID,
      sAdresseComplete: specDTO.localisationActuelle
    } as AdresseAPI,
    bAbsent: specDTO.bAbsent,
    bAjout: specDTO.bAjout,
    bRetrait: specDTO.bRetrait,
    dateInstallation: specDTO.dateInstallation,
    preponderance: specDTO.preponderance,
    typePrestation: {
      sPKTypePrestation: specDTO.typePrestationID,
      sLibelle: specDTO.libelleTypePrestation
    } as TypePrestationAPI,
    produit: {
      IDProduit: specDTO.appareilID,
      sLibelle: specDTO.libelleModele,
      sRefProduit: specDTO.referenceAppareil,
      sCodeAffectation: specDTO.libelleAffectation,
      modele: {
        sLibelle: specDTO.libelleModele,
        sRefProduit: specDTO.referenceModele
      } as ModeleAPI
    }
  } as ConfigAppareilSpecTiersVisiteAPI;
}

const appliqueFiltres = (
  listeAppareils: ConfigAppareilSpecTiersVisiteAPI[],
  filtres: PropsFilters,
  appareilsPresentsLieuVisite?: ConfigAppareilSpecTiersVisiteAPI[]
): ConfigAppareilSpecTiersVisiteAPI[] => {
  const { prestation, masquerAppareilsAbsents, idProduit } = filtres;
  let listeAppareilsFiltree: ConfigAppareilSpecTiersVisiteAPI[] =
    listeAppareils;

  if (prestation?.length > 0) {
    listeAppareilsFiltree = listeAppareilsFiltree.filter(
      (configAppareil: ConfigAppareilSpecTiersVisiteAPI) =>
        prestation.indexOf(configAppareil.typePrestation.sPKTypePrestation) !==
        -1
    );
  }

  if (masquerAppareilsAbsents && appareilsPresentsLieuVisite) {
    listeAppareilsFiltree = appareilsPresentsLieuVisite;
  }

  // if i got here, i have to filter by config id
  if (idProduit) {
    listeAppareilsFiltree = listeAppareilsFiltree.filter(
      (configAppareil: ConfigAppareilSpecTiersVisiteAPI) =>
        configAppareil.produit.IDProduit !== idProduit
    );
  }

  return listeAppareilsFiltree;
};

const TemplateConfiguration = () => {
  const { iPKVisite } = useParams<RouteParam>();
  const methods = useForm();
  const [listeAppareils, setListeAppareils] = useState<
    ConfigAppSpecTiersDTOModel[]
  >([]);

  const { data, loading, fetch } = useApiGetConfigAppDTOByVisiteID(+iPKVisite);

  const { data: visite } = useGetVisiteQuery(+iPKVisite);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  const appareilAbsentsLieuVisite = listeAppareils.filter(
    (appareil) => appareil.localisationID !== visite?.adresse.iPKAdresse
  );

  const appareilsPresentsLieuVisite = listeAppareils.filter(
    (appareil) => appareil.localisationID === visite?.adresse.iPKAdresse
  );

  useEffect(() => {
    if (data !== undefined) {
      setListeAppareils(data);
    }
  }, [data, setListeAppareils]);

  const [filtresAppareils, setFiltresAppareils] = useState<PropsFilters>({
    prestation: [],
    masquerAppareilsAbsents: false
  });

  const listeAppareilsFiltree: ConfigAppareilSpecTiersVisiteAPI[] =
    appliqueFiltres(
      listeAppareils.map(transformSpecDTOToSpec),
      filtresAppareils,
      appareilsPresentsLieuVisite.map(transformSpecDTOToSpec)
    );

  if (loading) {
    return <Loading>Chargement en cours de la liste des appareils</Loading>;
  }

  return (
    <Wrapper prestation={filtresAppareils.prestation}>
      <>
        <TopWrapper>
          <TitreConfigurationAppareils>
            {listeAppareilsFiltree?.length === 0 &&
              `Aucun appareil dans la visite`}
            {listeAppareilsFiltree?.length === 1 && `1 appareil`}
            {listeAppareilsFiltree?.length > 1 &&
              `${listeAppareilsFiltree?.length} appareils`}
          </TitreConfigurationAppareils>

          <WrapperFiltres>
            {listeAppareils.length > 0 && (
              <FiltresListeConfigurationAppareils
                listeAppareils={listeAppareils.map(transformSpecDTOToSpec)}
                setFiltersAppareils={setFiltresAppareils}
                filtresAppareils={filtresAppareils}
              />
            )}
            <FormProvider {...methods}>
              <SelectAppareils
                configurationsProduitSpec={listeAppareils.map(
                  transformSpecDTOToSpec
                )}
              />
            </FormProvider>
          </WrapperFiltres>
        </TopWrapper>
        {listeAppareilsFiltree.length > 0 && (
          <ListeAppareils
            items={listeAppareilsFiltree}
            setFiltresAppareils={setFiltresAppareils}
            filtresAppareils={filtresAppareils}
            appareilsAbsents={appareilAbsentsLieuVisite.map(
              transformSpecDTOToSpec
            )}
            visite={visite}
          />
        )}
      </>
    </Wrapper>
  );
};

export default TemplateConfiguration;
