import { FamilleAdapter } from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";
export function apiGetFamillesByTypeProduitID(typeProduitID) {
    const api = getDefaultApiInstance();
    if (api) {
        const adapter = new FamilleAdapter();
        return apiGet(api, `/types-produits/${typeProduitID}/familles`, adapter);
    }
    throw new Error("La config d'api par défaut est null");
}
