var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { apiListeConsommablesLivraisonsVisite } from "../api/apiListeConsommablesLivraisonsVisite";
import { apiEnregistreConsommablesLivraisons } from "../api/apiEnregistreConsommablesLivraisons";
import { apiRaZConsommablesLivraisonsVisite } from "../api/apiRaZConsommablesLivraisonsVisite";
import { adsNotification } from "@ads/ui";
import { apiGetConsommableByProduitID } from "../api/apiGetConsommableByProduitID";
import { apiGetTypePrestationByFamilleID } from "../api/apiGetTypePrestationsByFamilleId";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { apiListeConsommablesConfigurationVisite } from "../api/apiListeConsommablesConfigurationVisite";
import { apiListeTypesAffectationsProduits } from "../api/apiListeTypesAffectationsProduits";
import { ajouteConfigurationConsommable } from "../features";
export const useConsommablesLivraisonsForm = (visiteID) => {
    const [isLoadingFetch, setIsLoadingFetch] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingReset, setIsLoadingReset] = useState(false);
    const [isError, setIsError] = useState(false);
    const [affectations, setAffectations] = useState([]);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState([]);
    const fetchData = (visiteID) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoadingFetch(true);
            const [dataLivraisons, config, affectationsList] = yield Promise.all([
                apiListeConsommablesLivraisonsVisite(visiteID),
                apiListeConsommablesConfigurationVisite(visiteID),
                apiListeTypesAffectationsProduits("C"),
            ]);
            setAffectations(affectationsList);
            setData(dataLivraisons.sort((a, b) => {
                if (a.ligneLivraisonID < b.ligneLivraisonID) {
                    return -1;
                }
                if (a.ligneLivraisonID > b.ligneLivraisonID) {
                    return 1;
                }
                if (a.consommable.sLibelle < b.consommable.sLibelle) {
                    return -1;
                }
                if (a.consommable.sLibelle > b.consommable.sLibelle) {
                    return 1;
                }
                return 0;
            }));
            setConfig(config);
        }
        catch (err) {
            console.error(err);
            setIsError(true);
        }
        finally {
            setIsLoadingFetch(false);
        }
    });
    const refreshData = (livraison) => {
        const livraisonsData = data.map((r) => r.ligneLivraisonID === livraison.ligneLivraisonID ? livraison : r);
        setData(livraisonsData.sort((a, b) => {
            if (a.ligneLivraisonID < b.ligneLivraisonID) {
                return -1;
            }
            if (a.ligneLivraisonID > b.ligneLivraisonID) {
                return 1;
            }
            if (a.consommable.sLibelle < b.consommable.sLibelle) {
                return -1;
            }
            if (a.consommable.sLibelle > b.consommable.sLibelle) {
                return 1;
            }
            return 0;
        }));
    };
    const refreshAllData = (newData) => {
        const livraisonsData = [];
        data.forEach((r) => {
            livraisonsData.push(Object.assign(Object.assign({}, r), newData));
        });
        setData(livraisonsData.sort((a, b) => {
            if (a.ligneLivraisonID < b.ligneLivraisonID) {
                return -1;
            }
            if (a.ligneLivraisonID > b.ligneLivraisonID) {
                return 1;
            }
            if (a.consommable.sLibelle < b.consommable.sLibelle) {
                return -1;
            }
            if (a.consommable.sLibelle > b.consommable.sLibelle) {
                return 1;
            }
            return 0;
        }));
    };
    const removeData = (ligneLivraisonID) => {
        const formData = data.filter((r) => r.ligneLivraisonID !== ligneLivraisonID);
        setData(formData.sort((a, b) => {
            if (a.ligneLivraisonID < b.ligneLivraisonID) {
                return -1;
            }
            if (a.ligneLivraisonID > b.ligneLivraisonID) {
                return 1;
            }
            if (a.consommable.sLibelle < b.consommable.sLibelle) {
                return -1;
            }
            if (a.consommable.sLibelle > b.consommable.sLibelle) {
                return 1;
            }
            return 0;
        }));
    };
    const addData = (produit) => __awaiter(void 0, void 0, void 0, function* () {
        // const {
        //   produitID,
        //   libelle: sLibelle,
        //   refFournissseurPrincipal: sRefProduit,
        // } = produit;
        // const { data: consommables }: any = await apiGetConsommableByProduitID(
        //   getDefaultApiInstance(),
        //   produitID
        // );
        // const consommable = consommables[0];
        // const prestations = await apiGetTypePrestationByFamilleID(
        //   consommable.familleID
        // );
        // const livraison = {
        //   ligneLivraisonID: -new Date().getTime(),
        //   consommable: {
        //     IDProduit: parseInt(produitID, 10),
        //     sRefProduit,
        //     sFKTypeProduit: "",
        //     sCodeNatureProduit: "",
        //     sLibelle,
        //     sRefFabricant: "",
        //     bInactif: false,
        //     bPrevisionInactif: false,
        //     dArretCdeInterne: "",
        //     bArretCommande: false,
        //     typeProduit: {
        //       typeProduitId: consommable.typeProduitID,
        //       libelle: consommable.libelleTypeProduit,
        //       codeNatureProduit: consommable.nature,
        //       suiviTiers: false,
        //       suiviIntervention: false,
        //       inactif: false,
        //     },
        //     famille: {
        //       idFamille: consommable.familleID,
        //       libelle: consommable.libelleFamille,
        //       suiviCompteur: false,
        //       suiviReglage: false,
        //       observance: false,
        //       inactif: false,
        //       sousFamille: [],
        //       tTypePrestation: prestations,
        //     },
        //     iFKCodePriorite: -1,
        //     sCodePriorite: "",
        //     bSuiviLot: false,
        //     bSuiviDatePeremption: false,
        //     bSuiviNumeroLot: false,
        //     bSuiviSortie: consommable.suiviLotSortie,
        //     bKit: false,
        //     bControleLivraison: false,
        //     iQuantiteConfig: -1,
        //     iFrequenceConfig: -1,
        //     iFrequenceInventaire: -1,
        //     sFKTypeIntervention: "",
        //     bInfoLivraisonProduitSubstitution: false,
        //     bIgnoreControleConformite: false,
        //     iMultipleCdeCatalogue: -1,
        //     sTypePeremption: "",
        //   },
        //   iQuantite: 0,
        //   lot: undefined,
        // };
        // const present = data.find(
        //   (r) => r.consommable.IDProduit === parseInt(produitID, 10)
        // );
        // if (!present || consommable.suiviLotSortie) {
        //   setData([livraison, ...data]);
        // } else {
        //   adsNotification.error({
        //     message: "Le consommable est déjà présent dans la liste.",
        //   });
        // }
        try {
            const { produitID, libelle: sLibelle, refFournissseurPrincipal: sRefProduit, } = produit;
            const result = yield apiGetConsommableByProduitID(getDefaultApiInstance(), produitID);
            const consommable = result.data[0];
            const prestations = yield apiGetTypePrestationByFamilleID(consommable.familleID);
            const livraison = {
                ligneLivraisonID: -new Date().getTime(),
                consommable: {
                    IDProduit: parseInt(produitID),
                    sRefProduit,
                    sFKTypeProduit: "",
                    sCodeNatureProduit: "",
                    sLibelle,
                    sRefFabricant: "",
                    bInactif: false,
                    bPrevisionInactif: false,
                    dArretCdeInterne: "",
                    bArretCommande: false,
                    typeProduit: {
                        typeProduitId: consommable.typeProduitID,
                        libelle: consommable.libelleTypeProduit,
                        codeNatureProduit: consommable.nature,
                        suiviTiers: false,
                        suiviIntervention: false,
                        inactif: false,
                    },
                    famille: {
                        idFamille: consommable.familleID,
                        libelle: consommable.libelleFamille,
                        suiviCompteur: false,
                        suiviReglage: false,
                        observance: false,
                        inactif: false,
                        sousFamille: [],
                        tTypePrestation: prestations,
                    },
                    iFKCodePriorite: -1,
                    sCodePriorite: "",
                    bSuiviLot: false,
                    bSuiviDatePeremption: false,
                    bSuiviNumeroLot: false,
                    bSuiviSortie: consommable.suiviLotSortie,
                    bKit: false,
                    bControleLivraison: false,
                    iQuantiteConfig: -1,
                    iFrequenceConfig: -1,
                    iFrequenceInventaire: -1,
                    sFKTypeIntervention: "",
                    bInfoLivraisonProduitSubstitution: false,
                    bIgnoreControleConformite: false,
                    iMultipleCdeCatalogue: -1,
                    sTypePeremption: "",
                },
                appareil: undefined,
                iQuantite: 0,
                lot: undefined,
                typePrestation: undefined,
            };
            const present = data.find((r) => r.consommable.IDProduit === parseInt(produitID, 10));
            if (present) {
                if (["FM", "PD"].indexOf(consommable.typeProduitID) !== -1) {
                    // if (configAppareils.length > 1) {
                    setData([livraison, ...data]);
                    // } else {
                    //   throw new Error("Aucun appareil pour ce consommable");
                    // }
                }
                else {
                    if (prestations.length > 1) {
                        setData([livraison, ...data]);
                    }
                    else {
                        throw new Error("Une livraison existe déjà pour ce consommable (1 seule prestation)");
                    }
                }
            }
            else {
                setData([livraison, ...data]);
            }
        }
        catch (err) {
            adsNotification.error({
                message: err.message ||
                    "Une erreur est survenue lors de l'enregistrement de la livraison de ce consommable.",
            });
            console.error(err);
        }
    });
    const saveData = (visiteID, livraisons) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoadingSave(true);
            const payload = livraisons.map((r) => r.ligneLivraisonID < 0 ? Object.assign(Object.assign({}, r), { ligneLivraisonID: -1 }) : r);
            const { data } = yield apiEnregistreConsommablesLivraisons(+visiteID, payload);
            adsNotification.success({
                message: "Les données des livraisons ont été enregistrées.",
            });
            setData(data.sort((a, b) => {
                if (a.ligneLivraisonID < b.ligneLivraisonID) {
                    return -1;
                }
                if (a.ligneLivraisonID > b.ligneLivraisonID) {
                    return 1;
                }
                if (a.consommable.sLibelle < b.consommable.sLibelle) {
                    return -1;
                }
                if (a.consommable.sLibelle > b.consommable.sLibelle) {
                    return 1;
                }
                return 0;
            }));
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsLoadingSave(false);
        }
    });
    const addToConfig = (ligne) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const configConsoSpecTiersVisiteModel = {
                configCsoID: 0,
                configCsoVisiteID: 0,
                bAfficherCR: false,
                bAfficherGED: false,
                bAfficherOrdo: false,
                bAjoutConfig: true,
                bPreparation: false,
                bRetraitConfig: false,
                bTest: false,
                dDebut: "",
                dDerniereLivraison: "",
                dFin: "",
                iFrequence: 0,
                iQuantite: 0,
                sInformation: "",
                sTypeConfig: "",
                iQuantiteDerniereLivraison: 0,
                produit: ligne.livraisons[0].consommable,
                typePrestation: ligne.livraisons[0].typePrestation ||
                    {
                        id: "",
                    },
            };
            yield ajouteConfigurationConsommable({
                api: getDefaultApiInstance(),
                visiteID,
                configConsoSpecTiersVisiteModel,
            });
            if (config.find((c) => c.produit.IDProduit === ligne.consommable.IDProduit)) {
                setConfig(config.map((c) => c.produit.IDProduit === ligne.consommable.IDProduit
                    ? Object.assign(Object.assign({}, c), { bAjoutConfig: true }) : c));
            }
            else {
                setConfig([...config, configConsoSpecTiersVisiteModel]);
            }
        }
        catch (err) {
            console.error(err);
        }
    });
    const resetData = (visiteID) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoadingReset(true);
            yield apiRaZConsommablesLivraisonsVisite(visiteID);
            adsNotification.success({
                message: "Les données des livraisons ont été réinitialisées.",
            });
            fetchData(visiteID);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            setIsLoadingReset(false);
        }
    });
    const isDataConfig = (livraison) => {
        return !!config.find((c) => c.produit.IDProduit === livraison.consommable.IDProduit);
    };
    useEffect(() => {
        if (visiteID) {
            fetchData(visiteID);
        }
    }, [visiteID]);
    return {
        data,
        config,
        isLoadingFetch,
        refreshData,
        refreshAllData,
        saveData,
        isLoadingSave,
        removeData,
        addData,
        addToConfig,
        isError,
        resetData,
        isLoadingReset,
        isDataConfig,
        affectations,
    };
};
