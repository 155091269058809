var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Button, Icons, IconDeleteItem } from "@ads/ui";
import { FormLivraisonVisiteAppareil } from "../molecules/FormLivraisonVisiteAppareil";
import { FormLivraisonVisitePrestation } from "../molecules/FormLivraisonVisitePrestation";
import { FormLivraisonVisiteQuantite } from "../molecules/FormLivraisonVisiteQuantite";
import { FormLivraisonVisiteNumLot } from "../molecules/FormLivraisonVisiteNumLot";
import { FormLivraisonVisiteDLCLot } from "../molecules/FormLivraisonVisiteDLCLot";
import { FormLivraisonVisiteAffectationProduit } from "../molecules/FormLivraisonVisiteAffectationProduit";
const WrapperMain = styled.div `
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2.6rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.lg} {
  }
`;
const ButtonRemove = styled.button `
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  z-index: 10;
  margin: 0;
  padding: 0;
  height: 4.2rem;
  width: 4.2rem;
  line-height: 4.2rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  circle {
    transition: all 0.2s linear;
  }
  &:hover {
    circle {
      fill: ${({ theme }) => theme.colors.danger[100]};
    }
  }
`;
const Card = styled.div `
  background: ${(props) => props.theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  transition: transform 0.1s ease-out, box-shadow 0.2s;
`;
const LivraisonVisiteStyled = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.6rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0.6rem 0;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: 6rem auto;
    max-width: 80rem;
  }
`;
const Status = styled.div `
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  padding: 1.7rem;
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: inherit;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;
const ConsommableStatus = styled.div `
  width: 3rem;
  height: 3rem;
  background: ${({ theme, active }) => active ? theme.colors.secondary[100] : theme.colors.neutral[130]};
  border-radius: 50%;
  transition: background 0.25s ease-in-out;
`;
const Details = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.md} {
  }
  ${({ theme }) => theme.media.lg} {
  }
`;
const ConsommableNom = styled.div `
  font-weight: 700;
  display: block;
  ${({ theme }) => theme.media.lg} {
    display: flex;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;
const WrapperDetailPrecisions = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem 2rem;
`;
const WrapperLivraison = styled.div `
  margin-bottom: 1rem;

  &:last-child {
    margin: 0;
  }
`;
const LivraisonDetail = styled.div `
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;
const Precisions = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const LotDetails = styled.div `
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;

  & button {
    width: 4.8rem;
    height: 4.8rem;
    padding: 1rem;
    gap: 0;
  }

  ${({ theme }) => theme.media.md} {
    padding: inherit;
  }

  ${({ theme }) => theme.media.lg} {
    & button {
      position: absolute;
      right: -5.8rem;
    }
  }
`;
const FormArea = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  ${({ theme }) => theme.media.lg} {
  }
`;
const AjoutConfig = styled.div `
  grid-area: ajout;
  display: flex;
  gap: 1rem;
  justify-content: right;
  height: 100%;
  ${({ theme }) => theme.media.md} {
    align-items: flex-start;

    & .label {
      white-space: nowrap;
    }
  }
  ${({ theme }) => theme.media.lg} {
  }
`;
const Affectation = styled.div ``;
export const LigneFormulaireLivraison = ({ affectations, data, refreshData, removeData, isDataConfig, livraisons, configConsommables, addToConfig, }) => {
    const removeLivraison = (livraison) => __awaiter(void 0, void 0, void 0, function* () {
        removeData(livraison.ligneLivraisonID);
    });
    // const [ligne, setLigne] = useState<LigneFormumlaireLivraisonModel>(data);
    // const removeLivraison = async (livraison: LivraisonVisiteModel) => {
    //   const livraisons: LivraisonVisiteModel[] = [];
    //   livraisonsConsommables.forEach((l: LivraisonVisiteModel) => {
    //     if (l.ligneLivraisonID !== livraison.ligneLivraisonID) {
    //       livraisons.push(l);
    //     }
    //   });
    //   setLivraisonsConsommables(livraisons);
    //   if (livraison.ligneLivraisonID >= 0) {
    //     await apiRetireConsommableLivraisonVisite(
    //       visiteID,
    //       livraison.ligneLivraisonID
    //     );
    //   }
    // };
    /*
    const handleAjoutConfig = async (data: LigneFormumlaireLivraisonModel) => {
      const configConsoSpecTiersVisiteModel: ConfigConsoSpecTiersVisiteModel = {
        configCsoID: 0,
        configCsoVisiteID: 0,
        bAfficherCR: false,
        bAfficherGED: false,
        bAfficherOrdo: false,
        bAjoutConfig: true,
        bPreparation: false,
        bRetraitConfig: false,
        bTest: false,
        dDebut: "",
        dDerniereLivraison: "",
        dFin: "",
        iFrequence: 0,
        iQuantite: 0,
        sInformation: "",
        sTypeConfig: "",
        iQuantiteDerniereLivraison: 0,
        produit: data.livraisons[0].consommable,
        typePrestation:
          data.livraisons[0].typePrestation ||
          ({
            id: "",
          } as TypePrestationModel),
      };
  
      try {
        // await ajouteConfigurationConsommable({
        //   api: getDefaultApiInstance(),
        //   visiteID,
        //   configConsoSpecTiersVisiteModel,
        // });
        // setLigne({ ...ligne, isConfig: true });
      } catch (err) {
        console.error(err);
      }
    };
  */
    return (_jsxs(WrapperMain, { children: [!isDataConfig(data.livraisons[0]) &&
                !data.livraisons.find((l) => l.ligneLivraisonID > 0) && (_jsx(ButtonRemove, Object.assign({ onClick: (e) => {
                    e.preventDefault();
                    data.livraisons.forEach((l) => {
                        removeLivraison(l);
                    });
                } }, { children: _jsx(IconDeleteItem, { iconSize: "3em", iconColor: "#ffffff" }, void 0) }), void 0)), _jsx(Card, { children: _jsxs(LivraisonVisiteStyled, { children: [_jsx(Status, { children: _jsx(ConsommableStatus, { active: false }, void 0) }, void 0), _jsxs(WrapperDetailPrecisions, { children: [_jsxs(Details, { children: [_jsx(ConsommableNom, { children: `${data.consommable.sLibelle} - ${data.consommable.sRefProduit}` }, void 0), !isDataConfig(data) && (_jsx(AjoutConfig, { children: _jsx(Button, { type: "button", as: "button", onClick: () => addToConfig(data), label: "Ajouter \u00E0 la configuration", buttonStyle: "primary", size: "S", icon: Icons.Add, iconPosition: "left" }, void 0) }, void 0))] }, void 0), data.livraisons
                                    .sort((a, b) => {
                                    if ((a.typePrestation || "") > (b.typePrestation || "")) {
                                        return -1;
                                    }
                                    if ((a.typePrestation || "") < (b.typePrestation || "")) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                    .map((livraison, index) => (_jsxs(WrapperLivraison, { children: [index < 1 && (_jsxs(LivraisonDetail, { children: [_jsx(Precisions, { children: ["FM", "PD"].indexOf(data.consommable.typeProduit &&
                                                        data.consommable.typeProduit.typeProduitId) !== -1 ? (_jsx(FormLivraisonVisiteAppareil, { livraison: livraison, refreshData: refreshData, livraisons: livraisons, configConsommables: configConsommables }, void 0)) : (_jsx(FormLivraisonVisitePrestation, { livraison: livraison, configConsommables: configConsommables, refreshData: refreshData }, void 0)) }, void 0), _jsx(FormArea, { children: _jsx(FormLivraisonVisiteQuantite, { livraison: data.livraisons.length > 1
                                                            ? {
                                                                iQuantite: data.quantite,
                                                            }
                                                            : livraison, disabled: data.consommable.bSuiviSortie, lot: false, refreshData: refreshData }, void 0) }, void 0)] }, void 0)), data.consommable.bSuiviSortie && (_jsxs(LotDetails, { children: [_jsx(Button, { as: "button", type: "button", label: "", buttonStyle: "primary", size: "S", icon: Icons.Close, onClick: () => removeLivraison(livraison) }, void 0), _jsx(FormLivraisonVisiteNumLot, { livraison: livraison, refreshData: refreshData }, void 0), _jsx(FormLivraisonVisiteDLCLot, { livraison: livraison, refreshData: refreshData }, void 0), _jsx(FormLivraisonVisiteQuantite, { livraison: livraison, disabled: false, lot: true, refreshData: refreshData }, void 0)] }, void 0))] }, livraison.ligneLivraisonID))), _jsx(Affectation, { children: _jsx(FormLivraisonVisiteAffectationProduit, { affectations: affectations, livraisons: data.livraisons, refreshData: refreshData }, void 0) }, void 0)] }, void 0)] }, void 0) }, void 0)] }, void 0));
};
