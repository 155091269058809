import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from "antd";
import styled from "styled-components";
import { v4 } from "uuid";
import { Loading, FormWidget } from "@ads/ui";
import { useConsommablesRetoursForm } from "../../hooks/useConsommablesRetoursForm";
import { LigneFormulaireRetour } from "../organisms/LigneFormulaireRetour";
import { RetoursConsommablesListHeader, } from "../organisms/RetoursConsommablesListHeader";
const List = styled.form ``;
const formatLignesFormulaire = (retoursConsommables) => {
    let lignesFormulaire = [];
    retoursConsommables.forEach((retour) => {
        const position = lignesFormulaire.findIndex((configConso) => configConso.consommable.IDProduit === retour.consommable.IDProduit);
        if (position !== -1) {
            // Le consommable existe déjà dans le formulaire, on ajoute la livraison à la ligne
            const ligne = lignesFormulaire[position];
            const savedRetours = [];
            const unsavedRetours = [];
            [...ligne.retours, retour].forEach((r) => {
                if (r.ligneRetourID < -10000) {
                    unsavedRetours.push(r);
                }
                else {
                    savedRetours.push(r);
                }
            });
            // console.log("unsaved", unsavedRetours);
            // console.log("saved", savedRetours);
            const retours = [
                ...savedRetours.sort((a, b) => {
                    if (a.ligneRetourID < b.ligneRetourID) {
                        return 1;
                    }
                    if (a.ligneRetourID > b.ligneRetourID) {
                        return -1;
                    }
                    return 0;
                }),
                ...unsavedRetours.sort((a, b) => {
                    if (a.ligneRetourID < b.ligneRetourID) {
                        return 1;
                    }
                    if (a.ligneRetourID > b.ligneRetourID) {
                        return -1;
                    }
                    return 0;
                }),
            ];
            lignesFormulaire[position] = Object.assign(Object.assign({}, ligne), { quantite: ligne.quantite + (retour.iQuantite || 0), retours });
        }
        else {
            // Le consommable n'est pas présent dans les lignes du formulaire, on créée une nouvelle ligne
            lignesFormulaire.push({
                ligneID: v4(),
                consommable: retour.consommable,
                quantite: retour.iQuantite || 0,
                retours: [retour],
            });
        }
    });
    return lignesFormulaire.sort((a, b) => {
        if (a.consommable.sLibelle < b.consommable.sLibelle) {
            return -1;
        }
        if (a.consommable.sLibelle > b.consommable.sLibelle) {
            return 1;
        }
        return 0;
    });
};
export const ConsommablesRetoursTemplate = ({ visiteID, intervenantID, SelectionRetourConsommable, localisationsRetraits, }) => {
    const { isError, isLoadingFetch, isLoadingSave, isLoadingReset, data, refreshData, refreshAllData, saveData, removeData, addData, resetData, isDataConfig, } = useConsommablesRetoursForm(visiteID);
    const handleSaveRetours = () => {
        saveData(visiteID, data);
    };
    if (isLoadingFetch) {
        return _jsx(Loading, { children: "Chargement en cours des retours de consommables." }, void 0);
    }
    if (isError) {
        return (_jsx(Alert, { showIcon: true, type: "error", message: "Une erreur est survenue.", description: "Les retours de consommables n'ont pu \u00EAtre r\u00E9cup\u00E9r\u00E9s." }, void 0));
    }
    // console.log(formatLignesFormulaire(data));
    return (_jsxs(_Fragment, { children: [_jsx(RetoursConsommablesListHeader, { visiteID: visiteID, intervenantID: intervenantID, nbConso: data.length, SelectionRetourConsommable: SelectionRetourConsommable, resetData: resetData, isLoadingReset: isLoadingReset, addData: addData }, void 0), data.length > 0 ? (_jsx(FormWidget, Object.assign({ handleSave: handleSaveRetours, isLoadingSave: isLoadingSave }, { children: _jsx(List, Object.assign({ id: "retours-consommables" }, { children: formatLignesFormulaire(data).map((ligneFormulaire) => (_jsx(LigneFormulaireRetour, { data: ligneFormulaire, localisationsRetraits: localisationsRetraits, refreshData: refreshData, refreshAllData: refreshAllData, removeData: removeData, isDataConfig: isDataConfig }, ligneFormulaire.consommable.IDProduit))) }), void 0) }), void 0)) : (_jsx(Alert, { showIcon: true, type: "info", message: "La liste des retours est vide." }, void 0))] }, void 0));
};
