var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { ApplicationContext, getApiInstance } from '@ads/react-common-module';
import { apiGetPatientById } from '../../../api/apiGetPatientById';
export function useFichePatient(input) {
    const [patient, setPatient] = useState(undefined);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const appSettings = ApplicationContext.getInstance().getAppSettings().getData();
    const apiConfigIsadom = appSettings ? appSettings.apiConfig : null;
    const apiIsadom = getApiInstance(apiConfigIsadom);
    const getPatientById = (id) => __awaiter(this, void 0, void 0, function* () {
        return (yield apiGetPatientById(apiIsadom, id)).data;
    });
    useEffect(() => {
        if (!Number.isNaN(input.fetchParam) && patient === undefined) {
            getPatientById(input.fetchParam)
                .then((p) => {
                setPatient(p);
            })
                .catch((err) => {
                setError(err);
            })
                .finally(() => {
                setIsLoading(false);
            });
        }
        else if (Number.isNaN(input.fetchParam) && patient === undefined) {
            throw new Error("L'id du tiers n'a pas un format valide.");
        }
    }, []);
    const result = {
        patient,
        isLoading,
        error,
    };
    return result;
}
