import { apiGet } from "@ads/react-common-module";
import { TypeLocalisationAdapter, } from "@ads/isadom-model";
export const apiGetTypeLocalisations = (api) => {
    if (api) {
        const adapter = new TypeLocalisationAdapter();
        return apiGet(api, `/types-localisations`, adapter);
    }
    return new Promise((_, reject) => {
        reject("La configuration api est null");
    });
};
