var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter, TiersAdapter } from "../..";
import { AdresseAdapter } from ".";
var LieuExerciceMedecinAdapter = /** @class */ (function (_super) {
    __extends(LieuExerciceMedecinAdapter, _super);
    function LieuExerciceMedecinAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tiersAdapter = new TiersAdapter();
        _this.adresseAdapter = new AdresseAdapter();
        return _this;
    }
    LieuExerciceMedecinAdapter.prototype.toClient = function (src) {
        return {
            ID: src.lieuExerciceMedecinID,
            medecin: src.medecin && this.tiersAdapter.toClient(src.medecin),
            etablissement: src.etablissement && this.tiersAdapter.toClient(src.etablissement),
            service: src.service && this.tiersAdapter.toClient(src.service),
            adresse: src.adresse && this.adresseAdapter.toClient(src.adresse),
        };
    };
    LieuExerciceMedecinAdapter.prototype.toAPI = function (src) {
        return {
            lieuExerciceMedecinID: src.ID,
            medecin: src.medecin && this.tiersAdapter.toAPI(src.medecin),
            etablissement: src.etablissement && this.tiersAdapter.toAPI(src.etablissement),
            service: src.service && this.tiersAdapter.toAPI(src.service),
            adresse: src.adresse && this.adresseAdapter.toAPI(src.adresse),
        };
    };
    return LieuExerciceMedecinAdapter;
}(Adapter));
export { LieuExerciceMedecinAdapter };
