import { AdresseModel, TiersModel } from "@ads/isadom-model";
import { useListSelection } from "@ads/ui";
import { useState } from "react";
import { ISelectAdresseByTiersHookInput } from "./interfaces/ISelectAdresseByTiersHookInput";
import { ISelectAdresseByTiersHookService } from "./interfaces/ISelectAdresseByTiersHookService";

export function useSelectAdresseByTiers({
  tiers,
}: ISelectAdresseByTiersHookInput): ISelectAdresseByTiersHookService {
  const [tiersState, setTiersState] = useState(tiers);

  function getAdresseSelectionnableByTiers(
    tiers: TiersModel | null
  ): AdresseModel[] {
    const adresses = [];
    if (tiers) {
      adresses.push(tiers.adressePrincipale);
      if (tiers.tAdresse) {
        adresses.push(...tiers.tAdresse);
      }
    }
    return adresses;
  }

  const services = useListSelection({
    data: getAdresseSelectionnableByTiers(tiersState),
    defaultSelectedData:
      tiers && tiers.adressePrincipale ? [tiers.adressePrincipale] : [],
    getKeyFromData(adresse: AdresseModel) {
      return adresse.adresseID;
    },
    typeSelection: "simple",
  });

  function handleTiersChanged(tiers: TiersModel) {
    setTiersState(tiers);
    services.notifyData(getAdresseSelectionnableByTiers(tiers));
    if (tiers) {
      services.notifyDataSelectedAllWithoutControl([tiers.adressePrincipale]);
    } else {
      services.notifyDataSelectedAll([]);
    }
  }

  return {
    selectionAdresseServices: services,
    notifyTiersChanged: handleTiersChanged,
  };
}
