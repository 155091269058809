import {
  DiagnostiqueEducatifAdapter,
  DiagnostiqueEducatifModel
} from "@ads/isadom-model";
import {
  getDefaultApiInstance,
  useApiGetV3,
  UseApiGetV3Output
} from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export interface UseApiGetDiagnosticEducatifByIDHookInput {
  visiteID: number;
}

export function useApiGetDiagnosticEducatifByVisiteID({
  visiteID
}: UseApiGetDiagnosticEducatifByIDHookInput): UseApiGetV3Output<DiagnostiqueEducatifModel> {
  const adapter = new DiagnostiqueEducatifAdapter();
  return useApiGetV3(
    getDefaultApiInstance(),
    `/visites/${visiteID}/diagnostiques-educatifs`,
    {
      errorStrategy: apiIsadomErrorStrategy,
      adaptResult: (data) => {
        const result = adapter.toClient(data.data);
        return result;
      }
    }
  );
}
