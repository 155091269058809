import { motion } from "framer-motion";
import { FormItemWrapper, ErrorFormMsg } from "@ads/ui";
import { UseFormReturn } from "react-hook-form";
import { LocalisationInterneAutoriseeAPI } from "@ads/isadom-model";
import SelectLocalisation from "./SelectLocalisation";

/**
 * Element de formulaire composé du label et de son Select
 * Récupère les différentes localisations possible en fonction de l'intervenant principal de la visite courante
 * @returns Label + Select
 */

interface Props {
  methods: UseFormReturn<any, Record<string, any>>;
  localisationsAutoriseesTriees?: LocalisationInterneAutoriseeAPI[];
  defaultValue: string;
  selectedValue: LocalisationInterneAutoriseeAPI;
}

const LocalisationArriveeRetrait = ({
  methods,
  localisationsAutoriseesTriees,
  defaultValue,
  selectedValue,
}: Props): JSX.Element => {
  return (
    <FormItemWrapper direction="vertical">
      {localisationsAutoriseesTriees && (
        <SelectLocalisation
          name="localisationRetrait"
          titre="Localisation de retrait"
          methods={methods}
          localisationsAutoriseesTriees={localisationsAutoriseesTriees}
          placeholder="Sélectionnez une adresse de retrait"
          defaultValue={defaultValue}
          selectedValue={selectedValue}
        />
      )}
      {methods.formState.errors.localisationAutorisee &&
        methods.formState.errors.localisationAutorisee.type === "required" && (
          <motion.div
            initial={{ x: -10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
          >
            <ErrorFormMsg>
              La localisation de retrait est obligatoire
            </ErrorFormMsg>
          </motion.div>
        )}
    </FormItemWrapper>
  );
};
export default LocalisationArriveeRetrait;
