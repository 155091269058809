var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlocNoteVisiteAdapter } from "@ads/isadom-model";
import { apiIsadomPut } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";
export function apiPutBlocsNotes(api, visiteID, note) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log(note);
        const adapter = new BlocNoteVisiteAdapter();
        yield apiIsadomPut(api, `/visites/${visiteID}/blocs-notes`, adapter.toAPI(note), apiIsadomErrorStrategy);
        return true;
    });
}
