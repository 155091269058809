var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NavLink, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useApi } from "@ads/react-common-module";
import { IconSeparator, DeleteButton, theme } from "@ads/ui";
import { Modal, notification } from "antd";
import { apiDeleteMessage } from "../../api/apiDeleteMessage";
import { useState } from "react";
//Wrapper qui sert a positionner le bouton delete hors de la card pour le rendre clicable
const WrapperMain = styled.div `
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2.6rem;
`;
const MessageStyled = styled(NavLink) `
  display: grid;
  min-height: 20rem;
  padding: 0.6rem 0;
  background: ${(props) => props.theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  cursor: pointer;
  align-items: center;
  grid-template-areas:
    "status status"
    "message btn";
  //grid-template-columns: 90% 10%;
  //grid-template-rows: 30% 70%;
  ${({ theme }) => theme.media.sm} {
    grid-template-areas: "status message btn";
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: auto;
    padding: 0.6rem 0;
    min-height: 13.6rem;
  }
  ${({ theme }) => theme.media.md} {
    grid-template-areas: "status message btn";
    grid-template-columns: 10% 80% 10%;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-areas: "status message btn";
    grid-template-columns: 5% 90% 5%;
  }
`;
const Status = styled.div `
  grid-area: status;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 1.7rem;

  ${({ theme }) => theme.media.sm} {
    justify-content: center;
    align-items: center;
    padding: 0;
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;
const AppareilStatus = styled.div `
  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.neutral[130]};
  border-radius: 50%;
`;
const Wrapper = styled.div `
  grid-area: message;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
`;
const WrapperMessage = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 1.7rem;

  ${({ theme }) => theme.media.sm} {
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
  }
`;
const Libelle = styled.div `
  font-weight: 800;
`;
const ObjetMessage = styled.div `
  margin-left: 0.5rem;
`;
const ObjetDestinataire = styled.div `
  margin-left: 0.5rem;
  &:after {
    content: ", ";
  }
  &:last-child {
    &:after {
      content: "";
    }
  }
`;
const Btn = styled("button") `
  grid-area: btn;
  border: none;
  background: none;
  ${({ theme }) => theme.media.lg} {
    justify-content: center;
    align-items: center;
  }
  cursor: pointer;
`;
const WrapperContent = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${({ theme }) => theme.media.sm} {
    border-left: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;
const WrapperButton = styled.div `
  display: block;
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  padding: 0.5rem;
  cursor: pointer;
  ${({ theme }) => theme.media.sm} {
    top: -2.2rem;
    right: -2.2rem;
  }
`;
const MessageItem = ({ message, visiteID, selection }) => {
    const { iPKVisite } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const api = useApi();
    const history = useHistory();
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield apiDeleteMessage(api, visiteID, message.ID);
            history.push(`/visites/${iPKVisite}/messages/`);
        }
        catch (error) {
            notification.error({
                message: "Erreur",
                description: "Le message n'a pas pu être supprimé.",
                duration: theme.duration.notification,
            });
        }
    });
    return (_jsxs(WrapperMain, { children: [selection !== "effectue" && (_jsxs(_Fragment, { children: [_jsx(WrapperButton, Object.assign({ onClick: () => setIsModalVisible(true) }, { children: _jsx(DeleteButton, {}, void 0) }), void 0), _jsxs(Modal, Object.assign({ mask: false, visible: isModalVisible, onOk: () => {
                            handleConfirm();
                            setIsModalVisible(false);
                        }, onCancel: () => {
                            setIsModalVisible(false);
                        } }, { children: ["Attention la suprression du message est irr\u00E9versible.", _jsx("br", {}, void 0), " Souhaitez-vous continuer ?"] }), void 0)] }, void 0)), _jsxs(MessageStyled, Object.assign({ to: `/visites/${visiteID}/messages/${message.ID}` }, { children: [_jsx(Status, { children: _jsx(AppareilStatus, {}, void 0) }, void 0), _jsxs(Wrapper, { children: [_jsxs(WrapperMessage, { children: [_jsx(Libelle, { children: `${message.typeObjet.libelle} :` }, void 0), _jsx(ObjetMessage, { children: ` ${message.objet}` }, void 0)] }, void 0), _jsxs(WrapperMessage, { children: [_jsx(Libelle, { children: "Destinataires :" }, void 0), message.destinatairesPrincipaux.map((destinataire) => (_jsx(ObjetDestinataire, { children: destinataire.nom }, void 0)))] }, void 0)] }, void 0), _jsx(WrapperContent, { children: _jsx(Btn, { children: _jsx(IconSeparator, { iconSize: "2.5rem", iconColor: "#524D72" }, void 0) }, void 0) }, void 0)] }), void 0)] }, void 0));
};
export default MessageItem;
