import { Redirect, Route } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import { useAppSelector } from "../../../hooks/store";

export default function ProtectedRoute(props: RouteProps) {
  const { bConnecte } = useAppSelector((state) => state.auth);

  if (!bConnecte) {
    const redirect = props.location
      ? encodeURIComponent(props.location.pathname + props.location.search)
      : undefined;
    return (
      <Redirect
        to={`/utilisateur/connexion${
          redirect && redirect !== "%2F" ? `?redirect=${redirect}` : ""
        }`}
      />
    );
  }

  return <Route {...props} />;
}
