import styled from "styled-components";

const WrapperApp = styled.div`
  display: flex;
  flex-flow: column;
  overflow: hidden;
  min-height: 100vh;
`;

export default WrapperApp;
