import { ProgrammeEducatifModel } from "@ads/isadom-model"
import { UseApiGetV3Output, useGetWithCache } from "@ads/react-common-module"
import { useApiGetProgrammeEducatifByIDAndVisiteID } from "../api/useApiGetProgrammeEducatifByIDAndVisiteID"
import { useSelectPEStore } from "../components/context/PEContext"

interface Criteria {
    visiteID: number,
    id: number
}

export function useGetPEByVisiteIdAndNautID(visiteID: number, nautID: number): UseApiGetV3Output<ProgrammeEducatifModel> {
    const { getPEByNautID } = useSelectPEStore()
    return useGetWithCache<ProgrammeEducatifModel, Criteria>({
        criteria: {
            visiteID: visiteID,
            id: nautID
        },
        getFromCache: (criteira) => getPEByNautID(criteira.id),
        getFromRemote: (criteria) => useApiGetProgrammeEducatifByIDAndVisiteID(criteria.id, criteria.visiteID)
    })

}