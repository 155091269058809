import { apiDelete, getDefaultApiInstance } from "@ads/react-common-module";
export function apiDeleteDocumentByVisiteAndDocumentID(visiteID, documentVisiteId) {
    const api = getDefaultApiInstance();
    if (api) {
        return apiDelete(api, `visites/${visiteID}/documents/${documentVisiteId}`);
    }
    return new Promise((_resolve, reject) => {
        reject("La configuration d'api n'est pas initialisée");
    });
}
