import ProtectedRoute from "components/core/routes/ProtectedRoute";
import { RouteParam } from "interface/core/RouteParam";
import { Switch, useParams } from "react-router-dom";
import { DiagnosticEducatifFormVisiteTemplate } from "./diagnostic-educatif/components/form/DiagnosticEducatifFormVisiteTemplate";
import { PEStoreProvider } from "./programme-educatif/components/context/PEContext";
import { ProgrammeEducatifCreateFormVisiteTemplate } from "./programme-educatif/components/form/create/ProgrammeEducatifCreateFormVisiteTemplate";
import { ProgrammeEducatifModifFormVisiteTemplate } from "./programme-educatif/components/form/modif/ProgrammeEducatifModifFormVisiteTemplate";
import { ProgrammeEducatifVisiteListTemplate } from "./programme-educatif/components/list/ProgrammeEducatifVisiteListTemplate";

export function RouterSuiviPatient() {
  const { iPKVisite } = useParams<RouteParam>();

  return (
    <>
        
     <PEStoreProvider>
     <Switch>
        <ProtectedRoute
          exact
          path={`/visites/:iPKVisite/programmes/programmes-educatifs`}
        >
          <ProgrammeEducatifVisiteListTemplate visiteID={+iPKVisite} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={`/visites/:iPKVisite/programmes/programmes-educatifs/create`}
        >
          <ProgrammeEducatifCreateFormVisiteTemplate visiteID={+iPKVisite} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={`/visites/:iPKVisite/programmes/programmes-educatifs/:peID`}
        >
          <ProgrammeEducatifModifFormVisiteTemplate visiteID={+iPKVisite} />
        </ProtectedRoute>

        
        </Switch>
      </PEStoreProvider>
      
      <ProtectedRoute
        exact
        path={`/visites/:iPKVisite/programmes/diagnostics-educatifs`}
      >
        <DiagnosticEducatifFormVisiteTemplate visiteID={+iPKVisite} />
      </ProtectedRoute>
    </>
     
  );
}
