const errorAdapter = (error) => {
    for (const [_, value] of Object.entries(error)) {
        const errors = value;
        if (errors.length > 0) {
            const messagesAndIds = errors.map((error) => ({
                sType: error.type,
                message: error.message,
                idConcerne: error.idConcerne,
            }));
            return messagesAndIds;
        }
    }
    return null;
};
export default errorAdapter;
