import { ConfigConsoSpecTiersVisiteAdapter, } from "@ads/isadom-model";
import { apiGet } from "@ads/react-common-module";
export const apiGetConsommablesConfigurationVisite = (api, visiteID, configConsoID) => {
    if (api) {
        const adapter = new ConfigConsoSpecTiersVisiteAdapter();
        return apiGet(api, `/visites/${visiteID}/consommables/${configConsoID}`, adapter);
    }
    return new Promise((_resolve, reject) => {
        reject("La configuration d'api n'ets pas initialisé");
    });
};
