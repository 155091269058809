//
import { FormItemWrapper, Input, Label, PrimaryButton, SelectWrapper, } from "@ads/ui";
import { Radio } from "antd";
import React from "react";
import styled from "styled-components";
import { FormSelectionLocalisationItem } from "../../../../localisation/component/selection/ui/FormSelectionLocalisationItem";
import { FormSelectionModeleItem } from "../../../../modele/components/selection/ui/FormSelectionModeleItem";
import { FormSelectionTypeLocalisationItem } from "../../../../type-localisation/components/selection/ui/FormSelectionTypeLocalisationItem";
import { SelectionProduit } from "../../../../produit/component/selection/ui";
import { ParamDisponibilite } from "../hook/interface/IParamDisponibilite";
const WrapperAppareil = styled.form `
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
  }
`;
const WrapperDisponibilite = styled.div `
  margin-bottom: 4.2rem;
`;
const WrapperLabel = styled.div `
  margin-bottom: 1.2rem;
`;
const WrapperButton = styled.div `
  margin-bottom: 2rem;
`;
export function FormSelectionAppareil({ codeOrRef, familleSelectionService, formData, localisationService, modeleService, natureSelectionService, sousFamilleSelectionService, typeLocalisationService, typeProduitSelectionService, notifyFormDataChanged, setCodeOrRef, disponibilite, isSearchLoading, setDisponibilite, }) {
    const handleSubmit = (event) => {
        var _a;
        event.preventDefault();
        const formDataToSubmit = {
            config: formData.config,
            data: {
                modele: modeleService.selectedData[0],
                ref_sn: codeOrRef,
                disponibilite: disponibilite,
                nature: natureSelectionService.selectedData[0],
                typeProduit: typeProduitSelectionService.selectedData[0],
                famille: familleSelectionService.selectedData[0],
                sousFamille: sousFamilleSelectionService.selectedData[0],
                typeLocalisationID: (_a = typeLocalisationService.selectedData[0]) === null || _a === void 0 ? void 0 : _a.typeLocalisationID,
                localisation: localisationService.selectedData[0],
            },
        };
        notifyFormDataChanged(formDataToSubmit);
    };
    const handleDisponibiliteChanged = (e) => {
        setDisponibilite(e.target.value);
    };
    return (React.createElement(WrapperAppareil, { onSubmit: handleSubmit },
        React.createElement("div", null,
            React.createElement(SelectionProduit, { natureSelectionService: natureSelectionService, typeProduitSelectionService: typeProduitSelectionService, familleSelectionService: familleSelectionService, sousFamilleSelectionService: sousFamilleSelectionService }),
            React.createElement(FormSelectionModeleItem, Object.assign({}, modeleService, { libelle: "Mod\u00E8le de l'appareil" }))),
        React.createElement("div", null,
            React.createElement(FormItemWrapper, null,
                React.createElement(Label, { htmlFor: "ref_sn" }, "Code / Num. de s\u00E9rie"),
                React.createElement(Input, { id: "codeOrRef", name: "codeOrRef", selectMode: "light", value: codeOrRef, onChange: (e) => setCodeOrRef(e.target.value), type: "text" })),
            React.createElement(WrapperDisponibilite, null,
                React.createElement(FormItemWrapper, { direction: "vertical" },
                    React.createElement(WrapperLabel, null,
                        React.createElement(Label, { htmlFor: "disponibilite" }, "Disponibilit\u00E9")),
                    React.createElement(SelectWrapper, { selectMode: "light" },
                        React.createElement(Radio.Group, { defaultValue: 1, value: disponibilite, onChange: handleDisponibiliteChanged },
                            React.createElement(Radio, { value: ParamDisponibilite.disponible }, "Disponible"),
                            React.createElement(Radio, { value: ParamDisponibilite.indisponible }, "Non disponible"),
                            React.createElement(Radio, { value: ParamDisponibilite.tous }, "Tous"))))),
            React.createElement(FormSelectionTypeLocalisationItem, Object.assign({}, typeLocalisationService, { libelle: "Type de localisation :" })),
            React.createElement(FormSelectionLocalisationItem, Object.assign({}, localisationService, { libelle: "Localisations :" })),
            React.createElement(WrapperButton, null,
                React.createElement(PrimaryButton, { as: "button", type: "submit", size: "M", buttonStyle: isSearchLoading ? "neutral130" : "secondary", label: "Rechercher", isLoading: isSearchLoading })))));
}
