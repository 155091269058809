import {
  QuestionnaireObligatoireListVisiteTemplate,
  QuestionnaireListMolecule,
  QuestionnaireListTiersHistoTemplate,
  QuestionnaireSupplementaireListVisiteTemplate,
  QuestionnaireForm
} from "@ads/module-grille";
import ProtectedRoute from "components/core/routes/ProtectedRoute";
import { RouteParam } from "interface/core/RouteParam";
import { Switch, useParams } from "react-router-dom";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";
import { PageQuestionnaireDetail } from "../components/PageQuestionnaireDetail";

const RouterQuestionnaire = () => {
  const { iPKVisite } = useParams<RouteParam>();
  const { data: visite } = useGetVisiteQuery(+iPKVisite);

  if (!visite) return <></>;

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`/visites/:iPKVisite/questionnaires/obligatoires`}
      >
        <QuestionnaireObligatoireListVisiteTemplate
          children={QuestionnaireListMolecule}
          visiteID={parseInt(iPKVisite)}
          tiersVisiteID={visite.tiersVisite.iPKTiers}
        />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/visites/:iPKVisite/questionnaires/supplémentaires`}
      >
        <QuestionnaireSupplementaireListVisiteTemplate
          children={QuestionnaireListMolecule}
          visiteID={parseInt(iPKVisite)}
          tiersVisiteID={visite.tiersVisite.iPKTiers}
        />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/visites/:iPKVisite/questionnaires/historique`}
      >
        <QuestionnaireListTiersHistoTemplate
          children={QuestionnaireListMolecule}
          tiersID={visite.tiersVisite.iPKTiers}
        />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/visites/:iPKVisite/questionnaires/historique/:questionnaireID`}
      >
        <PageQuestionnaireDetail context="tiers" disableActions />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/visites/:iPKVisite/questionnaires/supplémentaires/nouveau`}
      >
        <div>QuestionnaireForm n'est pas exporté par @ads/module-grille</div>
        <QuestionnaireForm
          visiteID={parseInt(iPKVisite)}
          tiersVisiteID={visite?.tiersVisite.iPKTiers}
        />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/visites/:iPKVisite/questionnaires/supplémentaires/:questionnaireID`}
      >
        <PageQuestionnaireDetail context="visite" />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={`/visites/:iPKVisite/questionnaires/obligatoires/:questionnaireID`}
      >
        <PageQuestionnaireDetail context="visite" />
      </ProtectedRoute>
    </Switch>
  );
};

export default RouterQuestionnaire;
