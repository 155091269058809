import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { v4 } from "uuid";
import { IconAlerteProtocole, IconExterne, IconPatientDecede, IconPatientSorti, IconResident, IconRisqueVital, IconTelesuivi, } from "@ads/ui";
import { IconAlert } from "../atoms/FichePatientElements";
import React from "react";
const codeToIcon = {
    RISQUEVITAL: _jsx(IconRisqueVital, { iconSize: "2.2rem", iconColor: "orange" }, void 0),
    RESIDENT: _jsx(IconResident, { iconSize: "2.2rem", iconColor: "orange" }, void 0),
    EXTERNE: _jsx(IconExterne, { iconSize: "2.2rem", iconColor: "orange" }, void 0),
    DECEDE: _jsx(IconPatientDecede, { iconSize: "2.2rem", iconColor: "orange" }, void 0),
    SORTI: _jsx(IconPatientSorti, { iconSize: "2.2rem", iconColor: "orange" }, void 0),
    PROTOCOLE: _jsx(IconAlerteProtocole, { iconSize: "2.2rem", iconColor: "orange" }, void 0),
    "TELESUIVI-FAUX": _jsx(IconTelesuivi, { iconSize: "2.2rem", iconColor: "red" }, void 0),
    "TELESUIVI-SANSDATE": _jsx(IconTelesuivi, { iconSize: "2.2rem", iconColor: "orange" }, void 0),
    "TELESUIVI-OK": _jsx(IconTelesuivi, { iconSize: "2.2rem", iconColor: "green" }, void 0),
};
export const AlerteIcone = ({ alertes }) => {
    return (_jsxs(_Fragment, { children: [alertes
                .filter((alerte) => alerte.pImage !== "")
                .map((alerte) => (_jsx(IconAlert, { src: `data:image/jpeg;base64,${alerte.pImage}`, alt: alerte.code, title: alerte.code }, v4()))), alertes.map((alerte) => (_jsx(React.Fragment, { children: codeToIcon[alerte.code] }, v4())))] }, void 0));
};
