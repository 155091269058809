var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
import { StringParametrageIDAdapter } from "../params/StringParametrageIDAdapter";
var TypeFormulaireAdapter = /** @class */ (function (_super) {
    __extends(TypeFormulaireAdapter, _super);
    function TypeFormulaireAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.stringParamAdapter = new StringParametrageIDAdapter();
        return _this;
    }
    TypeFormulaireAdapter.prototype.toClient = function (src) {
        return {
            id: src.id.sID,
            libelle: src.sLibelle,
            module: src.sModule,
            version: src.id.iVersion,
            score: null,
            estValide: null,
            definitionCalculScore: null,
            getValidation: function () { return null; },
            getGereScore: function () { return null; },
            getDefinitionCalculScore: function () { return null; }
        };
    };
    TypeFormulaireAdapter.prototype.toAPI = function (src) {
        var idObjet = {
            id: src.id,
            version: src.version,
        };
        return {
            id: this.stringParamAdapter.toAPI(idObjet),
            sLibelle: src.libelle,
            sModule: src.module
        };
    };
    return TypeFormulaireAdapter;
}(Adapter));
export { TypeFormulaireAdapter };
