import { ProgrammeEducatifModel } from "@ads/isadom-model";
import { UseApiGetV3Output, useGetWithCache } from "@ads/react-common-module";
import { useApiGetPEByTiersIDAndID } from "../api/useApiGetPEByTiersIDAndID";
import { useSelectPEStore } from "../components/context/PEContext";

interface Criteria {
    tiersID: number,
    id: number
}

/**
 * @param isaID 
 * @returns 
 */
export function useGetPEByTiersIDAndIsaID(tiersID: number, isaID: number): UseApiGetV3Output<ProgrammeEducatifModel> {
    const { getPEByIsaID } = useSelectPEStore()
    return useGetWithCache<ProgrammeEducatifModel, Criteria>({
        criteria: {
            tiersID: tiersID,
            id: isaID
        },
        getFromCache: (criteira) => getPEByIsaID(criteira.id),
        getFromRemote: (criteria) => useApiGetPEByTiersIDAndID(criteria.tiersID, criteria.id)
    })

}