import {
  IPreponderance,
  TypeKey,
  TypeValue,
} from "./../interface/preponderance";

const setSelectPrepondreance = (): IPreponderance[] => {
  const preponderanceDefinition = {
    S: "Secondaire",
    P: "Principal",
    A: "Alterné",
  };

  const selectPreponderance: IPreponderance[] = [];

  for (const [key, value] of Object.entries(preponderanceDefinition)) {
    selectPreponderance.push({
      label: value as TypeValue,
      value: key as TypeKey,
    });
  }
  return selectPreponderance;
};

export default setSelectPrepondreance;
