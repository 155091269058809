import type { AppConfig } from "./interface/app/AppConfig";

const config: AppConfig = {
  version: process.env.REACT_APP_VERSION || "",
  name: process.env.REACT_APP_NAME || "",

  theme: process.env.REACT_APP_THEME || "ads",

  apiURL: process.env.REACT_APP_API_URL || "",
  identityServerURL: process.env.REACT_APP_IDENTITY_SERVER_URL || "",

  jwtPUBLIC: process.env.REACT_APP_JWT_PUBLIC || "",

  localStorageIdentityKey: process.env.REACT_APP_IDENTITY_KEY || "NOMAD",
};

export default config;
