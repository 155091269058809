import { NavLink } from "react-router-dom";
import styled from "styled-components";

const BtnEdit = styled(NavLink)`
  grid-area: modifier;
  position: relative;
  z-index: 20;
  display: inline-flex;
  justify-content: flex-end;
  margin-bottom: 8px;

  background: none;
  border: none;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
  cursor: pointer;
  gap: 0.5rem;
  text-indent: -999rem;
  color: ${({ theme }) => theme.colors.quinary[100]};
  transition: all 0.2s ease-in-out;

  ${({ theme }) => theme.media.lg} {
    margin-bottom: 8px;
  }

  :hover {
    color: ${({ theme }) => theme.colors.primary[100]};
    .a {
      stroke: ${({ theme }) => theme.colors.primary[100]};
    }
  }

  ${({ theme }) => theme.media.lg} {
    text-indent: 0;
  }
`;

export default BtnEdit;
