import { IIAHModel } from "@ads/isadom-model";
import { useState } from "react";

export interface IAHHookInput {
  IAHPrecedente: IIAHModel;
  IAH: IIAHModel;
}

interface IAHHookService {
  IAHPrecedente: IIAHModel;
  IAH: IIAHModel;
  updateIAHChamp: (idIAH: string, newValue: string) => void;
}

export function useIAH(input: IAHHookInput): IAHHookService {
  const [actualIAH, setActualIAH] = useState<IIAHModel>(input.IAH);

  const updateIAHChamp = (idIAH: string, newValue: string) => {
    const newIAH = actualIAH;
    switch (idIAH) {
      case "IAHAA":
        newIAH.IAHAA = newValue;
        break;
      case "IAH":
        newIAH.IAH = newValue;
        break;
      case "IH":
        newIAH.IH = newValue;
        break;
      case "IAO":
        newIAH.IAO = newValue;
        break;
      case "IAC":
        newIAH.IAC = newValue;
        break;
      case "fuite":
        newIAH.fuite = newValue;
        break;
      default:
        throw new Error("Update impossible");
    }
    setActualIAH(newIAH);
  };

  return {
    IAHPrecedente: input.IAHPrecedente,
    IAH: actualIAH,
    updateIAHChamp: updateIAHChamp
  };
}
