import { FormItemWrapper, Input, Label } from "@ads/ui";
import { useParams } from "react-router-dom";
import { useGetAppareilFromAPI } from "api/appareil";
import { RouteParam } from "interface/core/RouteParam";
import useTypesPrestationsFamille from "hooks/useTypesPrestationsFamille";

interface Props {
  idPrestation: string;
}

const SelectPrestations = ({ idPrestation }: Props) => {
  const { IDProduit } = useParams<RouteParam>();
  const { data: appareil } = useGetAppareilFromAPI({
    appareilID: IDProduit,
  });

  const idFamille = appareil?.modele.famille.sPKFamille;
  const { typesPrestations } = useTypesPrestationsFamille(idFamille);

  const selectedPrestation = typesPrestations?.find(
    (prestation) => prestation.sPKTypePrestation === idPrestation
  );

  return selectedPrestation?.sLibelle ? (
    <FormItemWrapper direction="vertical">
      <Label htmlFor="typePrestation">Type de prestation</Label>
      <Input
        name="prestation"
        type="text"
        selectMode="light"
        value={selectedPrestation?.sLibelle}
        disabled
      />
    </FormItemWrapper>
  ) : (
    <></>
  );
};

export default SelectPrestations;
