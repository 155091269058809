import {
  IBaseParametreEditionListHookInput,
  IBaseParametreEditionListHookService,
  useBaseParametreEditionList
} from ".generated/list";
import { IParametreEditionModel } from "@ads/isadom-model";

export interface IParametreEditionListHookInput<
  ParametreEditionDataType extends IParametreEditionModel,
  FetchParam
> extends IBaseParametreEditionListHookInput<FetchParam> {
  update:
    | ((pe: ParametreEditionDataType) => Promise<ParametreEditionDataType>)
    | null;
  delete: ((peID: number) => Promise<boolean>) | null;
}

export type IParamatreEditionListHookResult =
  IBaseParametreEditionListHookService;

export function useParametreEditionList<FetchParam>(
  input: IParametreEditionListHookInput<IParametreEditionModel, FetchParam>
): IParamatreEditionListHookResult {
  const legacyHook = useBaseParametreEditionList({
    data: input.data,
    fetchParams: {},
    async getDataFunction() {
      return [...input.data];
    },
    isEquals: input.isEquals
  });

  // TO-DO : Ajouter récupération de la liste des types de NR
  // L'idée est de les passer en props pour chaque element de la CL
  // Et faire donc moins d'appelle API
  const result: IParamatreEditionListHookResult = {
    data: legacyHook.data,
    addItem: legacyHook.addItem,
    deleteAllItems: legacyHook.deleteAllItems,
    deleteItem: legacyHook.deleteItem,
    modifyItem: legacyHook.modifyItem,
    registerListener: legacyHook.registerListener,
    unregisterListener: legacyHook.unregisterListener
  };

  return result;
}
