var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EvenementAppareilAdapter } from ".";
var EvenementAppareilTelesuiviAdapter = /** @class */ (function (_super) {
    __extends(EvenementAppareilTelesuiviAdapter, _super);
    function EvenementAppareilTelesuiviAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EvenementAppareilTelesuiviAdapter.prototype.toClient = function (src) {
        return __assign(__assign({}, _super.prototype.toClient.call(this, src)), { estObservance: src.bObservance, dateAppairage: src.dAppairage, numeroECNPatient: src.sNumeroECNPatient, estPatientAnonyme: src.bPatientAnonyme, estInactif: src.bInactif, dateInstallation: src.dInstall, dateRetrait: src.dRetrait });
    };
    EvenementAppareilTelesuiviAdapter.prototype.toClients = function (srcs) {
        var result = [];
        if (srcs) {
            for (var _i = 0, srcs_1 = srcs; _i < srcs_1.length; _i++) {
                var src = srcs_1[_i];
                if (src) {
                    result.push(this.toClient(src));
                }
            }
        }
        return result;
    };
    EvenementAppareilTelesuiviAdapter.prototype.toAPI = function (src) {
        return __assign(__assign({}, _super.prototype.toAPI.call(this, src)), { bObservance: src.estObservance, dAppairage: src.dateAppairage, sNumeroECNPatient: src.numeroECNPatient, bPatientAnonyme: src.estPatientAnonyme, bInactif: src.estInactif, dInstall: src.dateInstallation, dRetrait: src.dateRetrait });
    };
    EvenementAppareilTelesuiviAdapter.prototype.toAPIs = function (srcs) {
        var result = [];
        if (srcs) {
            for (var _i = 0, srcs_2 = srcs; _i < srcs_2.length; _i++) {
                var src = srcs_2[_i];
                if (src) {
                    result.push(this.toAPI(src));
                }
            }
        }
        return result;
    };
    return EvenementAppareilTelesuiviAdapter;
}(EvenementAppareilAdapter));
export { EvenementAppareilTelesuiviAdapter };
