import styled from "styled-components";

const TextNom = styled("span")`
  margin: 1.5rem 0 0 0;
  font-family: PoppinsBold, sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 2.6rem;

  ${({ theme }) => theme.media.md} {
    margin-top: 0;
  }
`;

export default TextNom;
