import { theme } from "@ads/ui";
import { notification } from "antd";
import { IconType } from "antd/lib/notification";
interface Props {
  type: IconType;
  error?: string;
  errorForm?: string;
  success?: string;
}

const formNotification = ({ type, error, success }: Props) => {
  if (error === "Erreur interne du serveur") {
    notification[type]({
      message: "Erreur interne du serveur",
      duration: theme.duration.notification,
    });
  } else {
    notification[type]({
      message:
        type === "success" || success
          ? "Le formulaire a été envoyé avec succès."
          : "Le formulaire comporte des erreurs.",
      description:
        type === "success" ? "Les modifications sont prises en compte" : error,

      duration: theme.duration.notification,
    });
  }
};

export default formNotification;
