var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { HttpMessageAdapter } from "./HttpMessageAdapter";
var HttpResponseAdapter = /** @class */ (function (_super) {
    __extends(HttpResponseAdapter, _super);
    function HttpResponseAdapter(adapter) {
        var _this = _super.call(this) || this;
        _this.messageAdapter = new HttpMessageAdapter();
        _this.dataAdapter = adapter;
        return _this;
    }
    HttpResponseAdapter.prototype.toClient = function (src) {
        var adapt = {
            messages: this.messageAdapter.toClients(src.messages),
        };
        if (src.data && src.data instanceof Array) {
            adapt.data = this.dataAdapter.toClients(src.data);
        }
        else {
            adapt.data = this.dataAdapter.toClient(src.data);
        }
        return adapt;
    };
    HttpResponseAdapter.prototype.toAPI = function (src) {
        var adapt = {
            messages: this.messageAdapter.toAPIs(src.messages),
        };
        if (src.data && src.data instanceof Array) {
            adapt.data = this.dataAdapter.toAPIs(src.data);
        }
        else {
            adapt.data = this.dataAdapter.toAPI(src.data);
        }
        return adapt;
    };
    return HttpResponseAdapter;
}(Adapter));
export { HttpResponseAdapter };
