import { Icons, INavigationItem } from "@ads/ui";
import NavigationPrimary from "components/ui/molecules/NavigationPrimary";
import { useAppSelector } from "hooks/store";
import { RouteParam } from "interface/core/RouteParam";
import { useParams } from "react-router-dom";

const NavigationFeuilleDeRoute = () => {
  const { typeRdv } = useParams<RouteParam>();
  const { tRdvAFaire, tVisiteEnCours, tVisiteTerminee } = useAppSelector(
    (state) => state.tournee
  );

  const navItemsFeuilleDeRoute: INavigationItem[] = [
    {
      to: "/feuille-de-route/a-faire",
      number: tRdvAFaire.length,
      label: "À faire",
      active: typeRdv === "a-faire",
      visible: true
    },
    {
      to: "/feuille-de-route/en-cours",
      label: "En cours",
      active: typeRdv === "en-cours",
      visible: true,
      number: tVisiteEnCours.length
    },
    {
      to: "/feuille-de-route/effectue",
      label: "Effectués",
      active: typeRdv === "effectue",
      visible: true,
      number: tVisiteTerminee.length
    },
    {
      to: "/visites/",
      icon: Icons.AddAccount,
      label: "Visite",
      active: typeRdv === "ajouter-rdv",
      visible: true
    }
  ];

  return (
    <NavigationPrimary
      navItems={navItemsFeuilleDeRoute}
      isOpen={false}
      isLargeNav={false}
    />
  );
};

export default NavigationFeuilleDeRoute;
