import { FieldComment } from "@ads/ui";
import { useState } from "react";
import styled from "styled-components";

export interface IAHItemProps {
  libelle: string;
  value: string;
  oldValue: string;
  onChange: (value: string) => void;
}

const Libelle = styled.h5`
  margin: 1rem 0 1rem;
  padding: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary[100]};
`;

export function IAHItem(props: IAHItemProps) {
  const [value, setValue] = useState(props.value);
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const handleClosePopover = () => {
    setPopoverVisible(!isPopoverVisible);
  };

  return (
    <div className="iah-item">
      <Libelle>{props.libelle}</Libelle>
      <FieldComment
        inputType={"input"}
        inputProps={{
          id: "iah-value-" + props.libelle,
          onChange: (event) => {
            setValue(event.target.value);
            props.onChange(event.target.value);
          },
          name: props.libelle,
          type: "number",
          value: value,
        }}
        buttonProps={{
          hasComment: props.oldValue !== "",
          onClick: handleClosePopover,
        }}
        popoverProps={{
          content:
            props.oldValue === ""
              ? "Pas de valeur précédente"
              : "Ancienne Valeur : " + props.oldValue,
          title: "",
          visible: isPopoverVisible,
          handleClose: handleClosePopover,
        }}
      />
    </div>
  );
}
