import ProtectedRoute from 'components/core/routes/ProtectedRoute'
import { Route, Switch } from 'react-router-dom';
import { EvenementRGPDForm } from '../ui/organisms/evenementRGPD';
import { EvenementStandardForm } from '../ui/organisms/evenementStandard';
import { EvenementTelesuiviForm } from '../ui/organisms/evenementTelesuivi';
import { EvenementsListeTemplate } from '../ui/templates/EvenementsListeTemplate';

const RouterEvenement = () => {
  return (
    <Switch>
      {/* Page listing */}
      <ProtectedRoute exact path="/visites/:iPKVisite/evenements/:tabActive">
        <EvenementsListeTemplate />
      </ProtectedRoute>

      {/* Page détail - irelm-teleobs */}
      <Route path="/visites/:iPKVisite/evenements/irelm-teleobs/:evenementID">
        <EvenementTelesuiviForm />
      </Route>

      {/* Page détail - RGPD */}
      <Route path="/visites/:iPKVisite/evenements/rgpd/:evenementID">
        <EvenementRGPDForm />
      </Route>

      {/* Page détail - Non réservés */}
      <Route path="/visites/:iPKVisite/evenements/non-reserves/:evenementID">
        <EvenementStandardForm />
      </Route>
    </Switch>
  )
}

export default RouterEvenement
