import { jsx as _jsx } from "react/jsx-runtime";
import { FieldComment } from "@ads/ui";
import { useEffect, useState } from "react";
import { CommentModal } from "../..";
export function ChampCheck(controler) {
    const [isPopoverVisible, setPopoverVisible] = useState(false);
    const [valueControl, setValueControl] = useState(controler.data.valeur.valeurs && controler.data.valeur.valeurs[0]
        ? controler.data.valeur.valeurs[0]
        : false);
    useEffect(() => {
        setValueControl(controler.data.valeur.valeurs && controler.data.valeur.valeurs[0]
            ? controler.data.valeur.valeurs[0]
            : false);
    }, [controler.data.valeur.valeurs]);
    return (_jsx(FieldComment, { buttonProps: {
            hasComment: controler.data.valeur.commentaire !== "",
            onClick: () => {
                setPopoverVisible(!isPopoverVisible);
            },
        }, checkboxProps: {
            onChange: (event) => {
                setValueControl(event.target.checked);
                controler.setValue(event.target.checked);
            },
            libelle: controler.data.libelle,
            checked: valueControl,
        }, inputType: "checkbox", popoverProps: {
            title: "Commentaire de saisie",
            content: (_jsx(CommentModal, { setCommentaire: (value) => {
                    controler.updateCommentaire(value);
                }, commentaire: controler.data.valeur.commentaire }, void 0)),
            zIndex: 200,
            handleClose: () => setPopoverVisible(false),
            visible: isPopoverVisible,
        } }, void 0));
}
