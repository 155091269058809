import {
  HttpResponseAPI,
  ConfigAppSpecTiersVisiteDTOAdapter,
  ConfigAppSpecTiersDTOModel,
  ConfigAppSpecTiersDTOAPI
} from "@ads/isadom-model";
import {
  getDefaultApiInstance,
  HttpResponseError,
  useApiGetV2
} from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export interface ApiGetConfigAppByVisiteID {
  errorStrategy?: (error: HttpResponseError<any>) => void;
}

export function useApiGetConfigAppDTOByVisiteID(
  visiteID: number,
  options?: ApiGetConfigAppByVisiteID
) {
  const adapter = new ConfigAppSpecTiersVisiteDTOAdapter();
  return useApiGetV2(
    getDefaultApiInstance(),
    `/visites/${visiteID}/appareils?dto=1`,
    {
      errorStrategy: options?.errorStrategy
        ? options.errorStrategy
        : apiIsadomErrorStrategy,
      adaptResult(
        result: HttpResponseAPI<ConfigAppSpecTiersDTOAPI[]>
      ): ConfigAppSpecTiersDTOModel[] {
        return adapter.toClients(result.data);
      }
    }
  );
}
