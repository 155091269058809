import {
  getDefaultApiInstance,
  UseApiPostPutV3Output,
  useApiPutV3
} from "@ads/react-common-module";
import {
  DiagnostiqueEducatifAdapter,
  DiagnostiqueEducatifModel
} from "@ads/isadom-model";
import { apiIsadomErrorStrategy } from "@ads/ui";

export interface ApiPutDiagnoticEducatifVisiteHookInput {
  visiteID: number;
}

export function useApiPutDiagnoticEducatifVisite({
  visiteID
}: ApiPutDiagnoticEducatifVisiteHookInput): UseApiPostPutV3Output<DiagnostiqueEducatifModel> {
  const adapter = new DiagnostiqueEducatifAdapter();
  return useApiPutV3(
    getDefaultApiInstance(),
    `/visites/${visiteID}/diagnostiques-educatifs`,
    {
      errorStrategy: apiIsadomErrorStrategy,
      adaptResult: (data) => {
        return adapter.toClient(data.data);
      },
      adaptBody: (data) => {
        return adapter.toAPI(data);
      }
    }
  );
}
