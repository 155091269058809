import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../../config";
import { MotifRetrait } from "./../../../interface/motifRetrait";
import {
  AppareilAPI,
  ModeleAPI,
  ConfigAppareilSpecTiersVisiteAPI,
  IntervenantAPI,
  AppareilRechercheAPI,
  ModeReglageAPI,
  TypePrestationAPI,
  EvenementStandardModel,
  EvenementTelesuiviAPI,
  EvenementStandardAPI,
  EvenementRGPDAPI
} from "@ads/isadom-model";
import { VisiteAPI } from "@ads/isadom-model";
import { RetraitAppareil } from "../../../interface/retraitAppareil";
import { FamilleAPI, ProduitAPI, TypeProduitAPI } from "@ads/isadom-model";
import { PayloadSearchAppareil } from "modules/appareils/interfaces/appareils.interfaces";
import { TourneeDTOAPI } from "interface/feuille-de-route/tournee";

export const nomadApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: `${config.apiURL}` }),
  keepUnusedDataFor: 10,
  tagTypes: ["Visite"],
  endpoints: (builder) => ({
    getAppareil: builder.query<AppareilAPI, number | string | undefined>({
      query: (iPKProduitDM) => `appareils/${iPKProduitDM}`
    }),
    deleteAppareil: builder.mutation<ProduitAPI, RetraitAppareil>({
      query: (body) => ({
        url: `/visites/${body.visiteId}/appareils/${body.appareilId}/retirer`,
        method: "POST",
        body: {
          localisation: { ...body.localisation },
          motif: { ...body.motif },
          compteur: { ...body.compteur },
          information: body.information
        }
      })
    }),
    updateVisite: builder.mutation<VisiteAPI, VisiteAPI>({
      query: (body) => ({
        url: `/visites`,
        method: "PUT",
        body
      }),
      invalidatesTags: [`Visite`]
    }),
    getTournee: builder.query<
      TourneeDTOAPI,
      {
        iPKIntervenant: number | string | undefined;
        dateTournee: number | string | undefined;
      }
    >({
      query: ({ iPKIntervenant, dateTournee }) =>
        `/intervenants/${iPKIntervenant}/tournees/${dateTournee}?dto=1`,
      providesTags: ["Visite"]
    }),

    getAppareilVisite: builder.query<
      ConfigAppareilSpecTiersVisiteAPI,
      {
        visiteID: number | string | undefined;
        appID: number | string | undefined;
      }
    >({
      query: ({ visiteID, appID }) => `visites/${visiteID}/appareils/${appID}`
    }),
    searchAppareils: builder.mutation<
      { data: AppareilRechercheAPI[] },
      PayloadSearchAppareil
    >({
      query: (body) => ({
        url: `/appareils/recherche`,
        method: "POST",
        body
      })
    }),
    getFamilles: builder.query<FamilleAPI[], null>({
      query: () => `familles`
    }),
    getTypesPrestationsFamilles: builder.query<
      TypePrestationAPI[],
      string | undefined | number
    >({
      query: (familleID) => `familles/${familleID}/types-presta`
    }),
    getDefinitionsGrillesReglages: builder.query<
      ModeReglageAPI[],
      {
        iPKTiers: string | number | undefined;
        iPKAppareil: string | number | undefined;
      }
    >({
      query: ({ iPKTiers, iPKAppareil }) =>
        `tiers/${iPKTiers}/appareils/${iPKAppareil}/definition`
    }),
    getValeursSaisiesGrilles: builder.query<
      ModeReglageAPI[],
      {
        iPKVisite: string | number | undefined;
        iPKAppareil: string | number | undefined;
      }
    >({
      query: ({ iPKVisite, iPKAppareil }) =>
        `visites/${iPKVisite}/appareils/${iPKAppareil}/modes-reglages`
    }),
    getValeursPrecedentesGrilles: builder.query<
      ModeReglageAPI[],
      {
        iPKTiers: string | number | undefined;
        iPKAppareil: string | number | undefined;
      }
    >({
      query: ({ iPKTiers, iPKAppareil }) =>
        `tiers/${iPKTiers}/appareils/${iPKAppareil}/modes-reglages`
    }),
    getFamillesByTypesProduits: builder.query<FamilleAPI[], string>({
      query: (idTypeProduit) => `types-produits/${idTypeProduit}/familles`
    }),
    getSousFamilles: builder.query<FamilleAPI[], null>({
      query: () => `familles`
    }),
    getIntervenantById: builder.query<IntervenantAPI, number | undefined>({
      query: (intervenantID) => `intervenants/${intervenantID}`
    }),
    getIntervenant: builder.query<IntervenantAPI, number>({
      query: (iPKProduitDM) => `appareils/${iPKProduitDM}`
    }),
    getModeles: builder.query<IntervenantAPI, number>({
      query: () => `modeles`
    }),
    getMotifsRetrait: builder.query<MotifRetrait[], string>({
      query: (idAppareil) => `/nomad/modeles/${idAppareil}/motifsRetrait`
    }),
    getModelesProduit: builder.mutation<ModeleAPI[], any>({
      query: (body) => ({
        url: `/produits/recherche`,
        method: "POST",
        body
      })
    }),
    getTypesProduits: builder.query<TypeProduitAPI[], string>({
      query: (nature) => `/natures-produits/${nature}/types-produits`
    }),
    getVisite: builder.query<VisiteAPI, number | string>({
      providesTags: ["Visite"],
      query: (idVisite) => `/visites/${idVisite}`
    }),
    getRendezVous: builder.query<VisiteAPI, number | string>({
      providesTags: ["Visite"],
      query: (idRendezVous) => `/rendez-vous/${idRendezVous}`
    }),
    addEvenement: builder.mutation<
      EvenementStandardModel,
      {
        body: EvenementTelesuiviAPI | EvenementStandardAPI | EvenementRGPDAPI;
        params: {
          visiteId: number | string;
          queryString?: string;
        };
      }
    >({
      query: ({ body, params }) => ({
        url: `visites/${params.visiteId}/evenements${params.queryString || ""}`,
        method: "POST",
        body
      })
    }),
    updateEvenement: builder.mutation<
      EvenementStandardModel,
      {
        body: EvenementTelesuiviAPI | EvenementStandardAPI | EvenementRGPDAPI;
        params: {
          visiteId: number | string;
          evenementVisiteId: number | string;
          queryString?: string;
        };
      }
    >({
      query: ({ body, params }) => ({
        url: `visites/${params.visiteId}/evenements/${
          params.evenementVisiteId
        }${params.queryString || ""}`,
        method: "PUT",
        body
      })
    })
  })
});

export const {
  useDeleteAppareilMutation,
  useUpdateVisiteMutation,
  useGetAppareilQuery,
  useGetAppareilVisiteQuery,
  useGetTourneeQuery,
  useGetDefinitionsGrillesReglagesQuery,
  useGetFamillesByTypesProduitsQuery,
  useGetFamillesQuery,
  useGetIntervenantByIdQuery,
  useGetIntervenantQuery,
  useGetModelesProduitMutation,
  useGetMotifsRetraitQuery,
  useGetTypesPrestationsFamillesQuery,
  useGetTypesProduitsQuery,
  useGetValeursPrecedentesGrillesQuery,
  useGetValeursSaisiesGrillesQuery,
  useGetVisiteQuery,
  useGetRendezVousQuery,
  useAddEvenementMutation,
  useUpdateEvenementMutation,
  useSearchAppareilsMutation
} = nomadApi;

export const { middleware } = nomadApi;
