import { LigneCommandeVisiteModel } from "@ads/isadom-model";
import { FormItemWrapper, Label, Select, SelectWrapper } from "@ads/ui";
import { useFormulaireCommandesStore } from "modules/commande/store/formulaireCommandeStore";
// import { multiValueCSS } from "react-select/src/components/MultiValue";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 90%;
  ${({ theme }) => theme.media.md} {
    text-align: left;
    margin: 0;
    width: auto;
  }

  .mode-light {
    text-align: left;
    width: 100%;
    ${({ theme }) => theme.media.md} {
      width: calc(100% + 2rem);
    }
  }
  .form-item {
    margin-bottom: 0;
  }
`;

interface CommandeItemPrestationProps {
  item: LigneCommandeVisiteModel;
}

const CommandeItemPrestation = ({ item }: CommandeItemPrestationProps) => {
  const { prestations, setPrestation } = useFormulaireCommandesStore();

  return (
    <Wrapper>
      <FormItemWrapper direction="vertical">
        <Label htmlFor={`${item.ID}-${item.famille?.idFamille}-prestation`}>
          Prestation
        </Label>
        <SelectWrapper selectMode="light">
          <Select
            disabled={false}
            id={`${item.ID}-${item.famille?.idFamille}-prestation`}
            name={`${item.ID}-${item.famille?.idFamille}-prestation`}
            onSelect={(value) => {
              const prestation = prestations.find((p) => `${p.id}` === value);
              if (prestation) {
                setPrestation(item.ID, prestation);
              }
            }}
            showSearch
            filterOption={(input: string, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            itemsSelect={prestations.map((prestation) => ({
              value: `${prestation.id}`,
              content: prestation.type.libelle
            }))}
            selectMode={"light"}
            placeholder="Sélectionnez une prestation"
            defaultValue={
              item.typePrestation ? `${item.typePrestation.id}` : undefined
            }
            allowClear
          />
        </SelectWrapper>
      </FormItemWrapper>
    </Wrapper>
  );
};

export default CommandeItemPrestation;
