import { TypeQuestionnaireAdapter, } from "@ads/isadom-model";
import { apiSearch } from "@ads/react-common-module";
export function apiSearchTypeQuestionnaire(api, libelle) {
    if (api) {
        const adapter = new TypeQuestionnaireAdapter();
        return apiSearch(api, "/types-questionnaires/recherche", adapter, {
            libelle: libelle,
        });
    }
    return new Promise((_, reject) => {
        reject("La configuration api est null");
    });
}
