var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FamilleAdapter, TypeProduitAdapter } from "../..";
import { Adapter } from "../../../_adapter";
var ConsommableAdapter = /** @class */ (function (_super) {
    __extends(ConsommableAdapter, _super);
    function ConsommableAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeProduitAdapter = new TypeProduitAdapter();
        _this.familleAdapter = new FamilleAdapter();
        return _this;
    }
    ConsommableAdapter.prototype.toClient = function (src) {
        if (!src) {
            return null;
        }
        return {
            IDProduit: src.IDProduit,
            sRefProduit: src.sRefProduit,
            sFKTypeProduit: src.sFKTypeProduit,
            sCodeNatureProduit: src.sCodeNatureProduit,
            sLibelle: src.sLibelle,
            sRefFabricant: src.sRefFabricant,
            bInactif: src.bInactif,
            bPrevisionInactif: src.bPrevisionInactif,
            dArretCdeInterne: src.dArretCdeInterne,
            bArretCommande: src.bArretCommande,
            typeProduit: src.typeProduit && this.typeProduitAdapter.toClient(src.typeProduit),
            famille: src.famille && this.familleAdapter.toClient(src.famille),
            iFKCodePriorite: src.iFKCodePriorite,
            sCodePriorite: src.sCodePriorite,
            bSuiviLot: src.bSuiviLot,
            bSuiviDatePeremption: src.bSuiviDatePeremption,
            bSuiviNumeroLot: src.bSuiviNumeroLot,
            bSuiviSortie: src.bSuiviSortie,
            bKit: src.bKit,
            bControleLivraison: src.bControleLivraison,
            iQuantiteConfig: src.iQuantiteConfig,
            iFrequenceConfig: src.iFrequenceConfig,
            iFrequenceInventaire: src.iFrequenceInventaire,
            sFKTypeIntervention: src.sFKTypeIntervention,
            bInfoLivraisonProduitSubstitution: src.bInfoLivraisonProduitSubstitution,
            bIgnoreControleConformite: src.bIgnoreControleConformite,
            iMultipleCdeCatalogue: src.iMultipleCdeCatalogue,
            sTypePeremption: src.sTypePeremption,
        };
    };
    ConsommableAdapter.prototype.toAPI = function (src) {
        if (!src) {
            return null;
        }
        return {
            IDProduit: src.IDProduit,
            sRefProduit: src.sRefProduit,
            sFKTypeProduit: src.sFKTypeProduit,
            sCodeNatureProduit: src.sCodeNatureProduit,
            sLibelle: src.sLibelle,
            sRefFabricant: src.sRefFabricant,
            bInactif: src.bInactif,
            bPrevisionInactif: src.bPrevisionInactif,
            dArretCdeInterne: src.dArretCdeInterne,
            bArretCommande: src.bArretCommande,
            typeProduit: src.typeProduit && this.typeProduitAdapter.toAPI(src.typeProduit),
            famille: this.familleAdapter.toAPI(src.famille),
            iFKCodePriorite: src.iFKCodePriorite,
            sCodePriorite: src.sCodePriorite,
            bSuiviLot: src.bSuiviLot,
            bSuiviDatePeremption: src.bSuiviDatePeremption,
            bSuiviNumeroLot: src.bSuiviNumeroLot,
            bSuiviSortie: src.bSuiviSortie,
            bKit: src.bKit,
            bControleLivraison: src.bControleLivraison,
            iQuantiteConfig: src.iQuantiteConfig,
            iFrequenceConfig: src.iFrequenceConfig,
            iFrequenceInventaire: src.iFrequenceInventaire,
            sFKTypeIntervention: src.sFKTypeIntervention,
            bInfoLivraisonProduitSubstitution: src.bInfoLivraisonProduitSubstitution,
            bIgnoreControleConformite: src.bIgnoreControleConformite,
            iMultipleCdeCatalogue: src.iMultipleCdeCatalogue,
            sTypePeremption: src.sTypePeremption,
        };
    };
    return ConsommableAdapter;
}(Adapter));
export { ConsommableAdapter };
