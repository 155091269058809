import ReactQuill from "react-quill";
import styled from "styled-components";

const Informations = styled.div`
  margin: 0 2rem 0 0;
  padding: 0.6rem;
  height: auto;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.neutral[130]};
  border-radius: 0.6rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ql-container.ql-snow {
      border: none;
  }

  ${({ theme }) => theme.media.md} {
    width: calc(50% - 1rem);
    margin-bottom: 0;
  }
`;

interface EvenementRGPDInformationsProps {
  informations: string;
}

export const EvenementRGPDInformations = ({
  informations,
}: EvenementRGPDInformationsProps) => (
  <Informations>
    <ReactQuill
      theme="snow"
      readOnly
      modules={{
        toolbar: false,
      }}
      defaultValue={informations} 
    />
  </Informations>
);
