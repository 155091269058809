import { FicheSuiviModel } from "@ads/isadom-model";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { apiGetFicheSuivisByVisiteID } from "../api/apiGetFichesSuivisByVisiteID";

export async function getFichesSuivisByVisiteID(
  visiteID: number
): Promise<FicheSuiviModel[]> {
  const api = getDefaultApiInstance();
  return (await apiGetFicheSuivisByVisiteID(api, visiteID)).data;
}
