import { ModuleProvider } from "@ads/react-common-module";
import { Alert } from "antd";
import { Link } from "react-router-dom";
import {
  MessagesVisiteModule,
  MessagesListeTemplate,
  MessageCreationTemplate,
  MessageEditionTemplate,
  MessageLectureSeuleTemplate,
} from "@ads/module-messages-visite";
import {
  NavItem,
  TemplateSaisieVisite,
} from "../../../components/ui/templates/TemplateSaisieVisiteOLD";
import { useParams } from "react-router-dom";
import { useAppSelector } from "hooks/store";
import { SelectionDestinataire } from "modules/messagerie/component/selection/SelectionDestinataire";
import { useGetVisiteQuery } from "store/redux/services/nomadApi";

interface MessagesRouteParams {
  iPKVisite: string;
  messageID: string;
}

export default function PageMessages() {
  const { iPKVisite, messageID } = useParams<MessagesRouteParams>();
  const { iPKIntervenant, selection } = useAppSelector(
    (state) => state.tournee
  );

  const { data: visite } = useGetVisiteQuery(+iPKVisite);

  const navItems: Array<NavItem> = [
    {
      id: "liste-messages",
      to: `/visites/${iPKVisite}/messages`,
      active: true,
      label: "Messages de la visite",
      visible: true,
    },
  ];

  const messagesModule = new MessagesVisiteModule();

  return (
    <TemplateSaisieVisite
      title={"Messages de la viste"}
      visiteID={iPKVisite}
      patientID={visite?.tiersVisite.iPKTiers}
      children={
        <ModuleProvider module={messagesModule}>
          <>
            {(() => {
              try {
                if (messageID === undefined) {
                  return (
                    <MessagesListeTemplate
                      selection={selection}
                      visiteID={parseInt(iPKVisite)}
                    />
                  );
                } else if (messageID === "nouveau") {
                  return (
                    <MessageCreationTemplate
                      visiteID={parseInt(iPKVisite)}
                      intervenantID={iPKIntervenant}
                      selectionDestinataire={SelectionDestinataire}
                    />
                  );
                } else if (!isNaN(+messageID)) {
                  return selection === "effectue" ? (
                    <MessageLectureSeuleTemplate
                      visiteID={parseInt(iPKVisite)}
                      messageID={+messageID}
                    />
                  ) : (
                    <MessageEditionTemplate
                      visiteID={parseInt(iPKVisite)}
                      intervenantID={iPKIntervenant}
                      messageID={parseInt(messageID)}
                      selectionDestinataire={SelectionDestinataire}
                    />
                  );
                }

                // eslint-disable-next-line no-throw-literal
                throw "Message incorrect";
              } catch (error: any) {
                return (
                  <Alert
                    message={error}
                    description={
                      <div>
                        <Link to={`/visites/${iPKVisite}/messages`}>
                          Retourner à la liste des messages
                        </Link>
                      </div>
                    }
                    type="error"
                    showIcon
                  />
                );
              }
            })()}
          </>
        </ModuleProvider>
      }
      navItems={navItems}
    />
  );
}
