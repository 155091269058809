import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { ObservanceVisiteModel, VisiteAPI } from "@ads/isadom-model";
import { Checkbox, Input } from "antd";

import { CheckboxChangeEvent } from "antd/lib/checkbox";

const ADSLabel = styled.label<{ display?: string; editable?: boolean }>`
  display: ${({ display }) => (display ? display : "inline")};
  font-weight: 600;
  color: #4d4848;
  font-size: 1.4rem;
  line-height: 2.1rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;

  ${({ theme }) => theme.media.md} {
    margin-bottom: ${({ editable }) => (editable ? "0.5rem" : "2rem")};
  }
`;

const { TextArea } = Input;

const ADSInput = styled(Input)`
  background-color: #e8e4e4;
  border: 1 px solid #e8e4e4;
  color: #000;
  padding: 0rem 2rem;
  line-height: 4.8rem;
  width: 100%;
  font-size: 1.4rem;
  border-radius: 1.3rem;
  outline-style: none;
  text-align: right;

  &:focus {
    border: 1 px solid #ffffff;
  }

  &.ant-input:hover {
    border-color: #ffffff;
    border-right-width: 1px !important;
  }

  &.ant-input:focus,
  &.ant-input-focused {
    border-color: #ffffff;
    box-shadow: none;
    border-right-width: 1px !important;
    outline: 0;
  }
`;

const ADSTextArea = styled(TextArea)`
  background-color: #e8e4e4;
  border: 1px solid #e8e4e4;
  color: #000;
  padding: 1.2rem 2rem;
  line-height: 1.2em;
  width: 100%;
  min-height: 10rem;
  font-size: 1.4rem;
  border-radius: 1.3rem;
  outline-style: none;
  text-align: left;

  &:focus {
    border: 1 px solid #ffffff;
  }

  &.ant-input:hover {
    border-color: #ffffff;
    border-right-width: 1px !important;
  }

  &.ant-input:focus,
  &.ant-input-focused {
    border-color: #ffffff;
    box-shadow: none;
    border-right-width: 1px !important;
    outline: 0;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 2.6rem 0 0;
  padding: 0 1.5rem 1.5rem;
  background: ${(props) => props.theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  grid-template-areas:
    "prestation prestation prestation prestation"
    "prescrite calculee releve releve"
    "mediane estimee vide vide "
    "mediane totale vide vide "
    "commentaire commentaire commentaire commentaire "
    "information information information information ";
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;

  &:first-child {
    margin: 0;
  }

  &.disabled {
    pointer-events: none;
  }

  ${({ theme }) => theme.media.md} {
    padding: 2.5rem 0;
    grid-template-areas:
      "prestation prescrite calculee releve mediane estimee"
      "prestation vide vide vide vide totale"
      "prestation commentaire commentaire commentaire commentaire commentaire"
      "prestation information information information information information";
    grid-template-columns: repeat(6, 1fr) 15%;
    grid-template-rows: auto;
  }

  ${({ theme }) => theme.media.lg} {
    padding: 2.5rem;
    grid-template-areas:
      "prestation prescrite  calculee releve releve  mediane mediane estimee estimee commentaire commentaire commentaire"
      "prestation vide vide vide vide vide vide vide vide vide totale information";
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
  }
`;

const FormItemWrapper = styled.div<{ align?: string }>`
  text-align: ${({ align }) => (align ? align : "left")};

  & label {
    text-align: left;
  }

  &.space-10 {
    margin-top: 1.0rem;
  }
`;

const Prestation = styled.div`
  grid-area: prestation;
  display: flex;
  margin: 2rem 0 0;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
`;

const PrestationLibelle = styled.span`
  padding: 0 2.4rem;
  background: #e8e4e4;
  border-radius: 1.7rem;
  font-family: "PoppinsMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 5rem;
`;

const Prescrite = styled.div`
  grid-area: prescrite;
`;

const Calculee = styled.div`
  grid-area: calculee;
`;

const Releve = styled.div`
  grid-area: releve;
`;

const Mediane = styled.div`
  grid-area: mediane;
`;

const Estimee = styled.div`
  grid-area: estimee;
`;

const Commentaire = styled.div`
  grid-area: commentaire;
`;

const Information = styled.div`
  grid-area: information;
  display: flex;
  align-items: start;
  justify-content: right;
  align-self: start;
`;

const Totale = styled.div`
  grid-area: totale;
`;
interface FormObservanceProps {
  observance: ObservanceVisiteModel;
  observances: ObservanceVisiteModel[];
  setObservances: React.Dispatch<React.SetStateAction<ObservanceVisiteModel[]>>;
  visite: VisiteAPI | undefined;
}

const isNumber = /^[0-9]*[,.]?[0-9]*$/;

export const FormObservance = ({
  observance,
  observances,
  setObservances,
  visite,
}: FormObservanceProps) => {
  const [observanceVisite, setObservanceVisite] =
    useState<ObservanceVisiteModel>(observance);

  const handleEstimationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const dureeEstimee = e.target.value;

    if (isNumber.test(dureeEstimee)) {
      const observanceToSend: ObservanceVisiteModel = {
        ...observanceVisite,
        dureeEstimee,
      };
      setObservances(
        observances.map((observance) =>
          observance.ID === observanceVisite.ID ? observanceToSend : observance
        )
      );
      setObservanceVisite(observanceToSend);
    }
  };

  const handleMedianeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const dureeMediane = e.target.value;

    if (isNumber.test(dureeMediane)) {
      const observanceToSend: ObservanceVisiteModel = {
        ...observanceVisite,
        dureeMediane,
      };
      setObservances(
        observances.map((observance) =>
          observance.ID === observanceVisite.ID ? observanceToSend : observance
        )
      );
      setObservanceVisite(observanceToSend);
    }
  };

  const handleDeambulationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const dureeEstimee = e.target.value;

    if (isNumber.test(dureeEstimee)) {
      const observanceToSend: ObservanceVisiteModel = {
        ...observanceVisite,
        observanceDeambulation: {
          ...observanceVisite.observanceDeambulation,
          dureeEstimee,
        },
      };
      setObservances(
        observances.map((observance) =>
          observance.ID === observanceVisite.ID ? observanceToSend : observance
        )
      );
      setObservanceVisite(observanceToSend);
    }
  };

  const handleInformationChange = async (
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    const information: string = e.target.value;
    const observanceToSend: ObservanceVisiteModel = {
      ...observanceVisite,
      information,
    };
    setObservances(
      observances.map((observance) =>
        observance.ID === observanceVisite.ID ? observanceToSend : observance
      )
    );
    setObservanceVisite(observanceToSend);
  };

  const handleInfoMedecinChange = async (e: CheckboxChangeEvent) => {
    const estInfoMedecin = !!e.target.checked;
    const observanceToSend: ObservanceVisiteModel = {
      ...observanceVisite,
      estInfoMedecin,
    };
    setObservances(
      observances.map((observance) =>
        observance.ID === observanceVisite.ID ? observanceToSend : observance
      )
    );
    setObservanceVisite(observanceToSend);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  // Ids unique pour les elements de formulaire
  const idDureeMediane = `${observance.ID}-duree-mediane`;
  const idDureeEstimee = `${observance.ID}-duree-estimee`;
  const idInformation = `${observance.ID}-information`;
  const idEstInfoMedecin = `${observance.ID}-est-medecin`;
  const idDeambulation = `${observance.ID}-deambulation`;

  return (
    <form>
      <Wrapper>
        <Prestation>
          <PrestationLibelle>
            {observanceVisite?.typePrestation.id}
          </PrestationLibelle>
        </Prestation>
        <Prescrite>
          <FormItemWrapper align="right">
            <ADSLabel htmlFor="" display="block">
              Prescrite
            </ADSLabel>
            {observanceVisite?.dureeTP
              ? (
                Math.round(parseFloat(observance?.dureeTP) * 100) / 100
              ).toFixed(2)
              : ""}
          </FormItemWrapper>
        </Prescrite>
        <Calculee>
          <FormItemWrapper align="right">
            <ADSLabel htmlFor="dureeCalculee" display="block">
              Calculée
            </ADSLabel>
            {observanceVisite?.dureeCalculee
              ? (
                Math.round(
                  parseFloat(observanceVisite?.dureeCalculee) * 100
                ) / 100
              ).toFixed(2)
              : ""}
          </FormItemWrapper>
        </Calculee>
        <Releve>
          <FormItemWrapper align="center">
            <ADSLabel htmlFor="dureeEstimee" display="block">
              Dernier relevé
            </ADSLabel>
            {observanceVisite &&
              observanceVisite?.dernierReleve !== "0000-00-00" &&
              `${observanceVisite?.derniereDuree} le ${new Intl.DateTimeFormat(
                "fr-FR"
              ).format(new Date(observanceVisite?.dernierReleve))}`}
          </FormItemWrapper>
        </Releve>

        <Mediane>
          <FormItemWrapper>
            {visite?.bValide ? (
              <ADSLabel htmlFor={idDureeMediane} display="block">
                Médiane : {observanceVisite.dureeMediane}
              </ADSLabel>
            ) : (
              <>
                <ADSLabel htmlFor={idDureeMediane} display="block" editable>
                  Médiane
                </ADSLabel>
                <ADSInput
                  id={idDureeMediane}
                  value={
                    observanceVisite?.dureeMediane === "undefined"
                      ? ""
                      : observanceVisite.dureeMediane
                  }
                  onChange={handleMedianeChange}
                  onFocus={handleFocus}
                />
              </>
            )}
          </FormItemWrapper>
          {observanceVisite?.typePrestation.id === "OXY" && (
            <FormItemWrapper className="space-10">
              {visite?.bValide ? (
                <ADSLabel htmlFor={idDeambulation} editable>
                  Déambulation :{" "}
                  {observanceVisite?.observanceDeambulation?.dureeEstimee}
                </ADSLabel>
              ) : (
                <>
                  <ADSLabel htmlFor={idDeambulation} editable>
                    Déambulation
                  </ADSLabel>
                  <ADSInput
                    id={idDeambulation}
                    value={
                      observanceVisite?.observanceDeambulation?.dureeEstimee ||
                      ""
                    }
                    onChange={handleDeambulationChange}
                    onFocus={handleFocus}
                  />
                </>
              )}
            </FormItemWrapper>
          )}
        </Mediane>

        <Estimee>
          <FormItemWrapper>
            {visite?.bValide ? (
              <ADSLabel htmlFor={idDureeEstimee} display="block" editable>
                Estimée : {observanceVisite?.dureeEstimee}
              </ADSLabel>
            ) : (
              <>
                <ADSLabel htmlFor={idDureeEstimee} display="block" editable>
                  Estimée
                </ADSLabel>
                <ADSInput
                  id={idDureeEstimee}
                  value={observanceVisite?.dureeEstimee || ""}
                  onChange={handleEstimationChange}
                  onFocus={handleFocus}
                />
              </>
            )}
          </FormItemWrapper>
        </Estimee>
        <Commentaire>
          <FormItemWrapper>
            <ADSLabel htmlFor={idInformation} display="block" editable>
              Commentaire
            </ADSLabel>
            <ADSTextArea
              id={idInformation}
              name={idInformation}
              value={observanceVisite?.information}
              onChange={handleInformationChange}
              rows={1}
            />
          </FormItemWrapper>
        </Commentaire>
        <Information>
          <FormItemWrapper>
            <Checkbox
              id={idEstInfoMedecin}
              checked={!!observanceVisite?.estInfoMedecin}
              onChange={handleInfoMedecinChange}
              disabled={visite?.bValide}
            >
              <ADSLabel htmlFor={idEstInfoMedecin}>
                Information Prescripteur
              </ADSLabel>
            </Checkbox>
          </FormItemWrapper>
        </Information>
        <Totale></Totale>
      </Wrapper>
    </form>
  );
};
