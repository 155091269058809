import {
  TypeVisiteAdapter,
  TypeVisiteAPI,
  TypeVisiteModel,
} from "@ads/isadom-model";
import { apiGetV2, ApiInstance } from "@ads/react-common-module";
import { apiErrorStrategy } from "@ads/ui";

export async function apiGetTypeVisiteByIntervenatID(
  api: ApiInstance | null,
  intervenantID: number
): Promise<TypeVisiteModel[]> {
  const result = await apiGetV2<TypeVisiteAPI[]>(
    api,
    `/intervenants/${intervenantID}/types-visites`,
    apiErrorStrategy
  );
  return new TypeVisiteAdapter().toClients(result);
}
