import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { FormItemWrapper, Label } from "@ads/ui";
import { FiltreListeConfigurationConsommables } from "../molecules/ConfigurationConsommableListFilter";
const Wrapper = styled.div `
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    align-items: flex-end;
  } ;
`;
const WrapperTitre = styled.h1 `
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 0 0 1rem;
  font-weight: normal;
  font-size: 2rem;
  line-height: 5rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2.2rem;
  }
`;
const WrapperActions = styled.div `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.6rem;

  .form-item {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
    gap: 2rem;
    align-items: end;
  } ;
`;
export const ConfigurationConsommablesListHeader = ({ configurationsConsommable, visiteID, setFiltreConfiguration, SelectionConsommable, }) => {
    const history = useHistory();
    const nbConso = configurationsConsommable === null || configurationsConsommable === void 0 ? void 0 : configurationsConsommable.filter((conso) => conso.bRetraitConfig === false).length;
    function handleConsommableSelected(consommables) {
        if (!consommables || consommables.length === 0) {
            return;
        }
        // Je récupère le consommable sélectionné
        const consommable = consommables[0];
        // Je récupère un éventuel consommable déjà présent dans la configuration
        const configurationConsommable = configurationsConsommable.find((configurationConsommable) => configurationConsommable.produit.IDProduit === +(consommable === null || consommable === void 0 ? void 0 : consommable.produitID));
        // Si il est présent, je redirige vers la page de modification
        if (configurationConsommable) {
            history.push(`/visites/${visiteID}/consommables/${configurationConsommable.configCsoVisiteID}`);
        }
        // Sinon je l'ajoute
        else if (consommables.length === 1) {
            const { produitID } = consommables[0];
            history.push(encodeURI(`/visites/${visiteID}/consommables/ajoute/${produitID}`));
        }
        else if (consommables.length !== 0) {
            throw new Error("erreur systeme impossible de selectionner plusieurs consommables");
        }
    }
    return (_jsxs(Wrapper, Object.assign({ className: "wrapper" }, { children: [_jsxs(WrapperTitre, Object.assign({ className: "wrapper-titre" }, { children: [nbConso === 0 && `Aucun consommable`, nbConso === 1 && `1 consommable`, nbConso > 1 && `${nbConso} consommables`] }), void 0), _jsxs(WrapperActions, Object.assign({ className: "wrapper-actions" }, { children: [_jsx("div", { children: _jsx(FiltreListeConfigurationConsommables, { configurationsConsommable: configurationsConsommable, setFiltreConfiguration: setFiltreConfiguration }, void 0) }, void 0), _jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "" }, { children: "Installer un consommable" }), void 0), _jsx(SelectionConsommable, { onConsommableSelected: handleConsommableSelected }, void 0)] }), void 0)] }), void 0)] }), void 0));
};
