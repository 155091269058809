import { TypeDocumentAdapter } from "./TypeDocumentAdapter";
import { Adapter } from "@ads/isadom-model";
export class DocumentAdapter extends Adapter {
    constructor() {
        super(...arguments);
        this.typeDocumentAdapter = new TypeDocumentAdapter();
    }
    toClient(src) {
        return {
            id: src.documentID,
            typeDocument: this.typeDocumentAdapter.toClient(src.typeDocument),
            URL: src.sURL,
            isDocumentExist: src.dataExist,
        };
    }
    toAPI(src) {
        return {
            documentID: src.id,
            typeDocument: this.typeDocumentAdapter.toAPI(src.typeDocument),
            sURL: src.URL,
            dataExist: src.isDocumentExist,
        };
    }
}
