import { getDefaultApiInstance } from "@ads/react-common-module";
import { adsNotification, useListFilter, } from "@ads/ui";
import { useEffect, useState } from "react";
const TAG = "useBlocNoteList";
const ID = "@ads/" + TAG;
export function useBlocNoteFilter(input) {
    const [initialData, setInitialData] = useState(input.data ? input.data : []);
    const api = getDefaultApiInstance();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const hookInput = {
        data: initialData,
        isEquals: input.isEquals,
        defaultFilter: input.defaultFilter,
        filter: input.filter,
    };
    const listener = {
        idListener: ID,
        onBeforeDeleteItem: function (item) {
            return new Promise((resolve, _reject) => {
                if (input.delete !== null && item !== null) {
                    input
                        .delete(api, input.fetchParams, item)
                        .then(() => {
                        adsNotification.success({
                            message: "Suppression avec success.",
                        });
                        resolve(item);
                    })
                        .catch((err) => {
                        {
                            let displayError = "";
                            for (const errorBack of err.metiers) {
                                displayError += errorBack.message + "\n";
                            }
                            adsNotification.error({ message: displayError });
                        }
                    });
                }
            });
        },
        onAfterDeleteItem: function (_item) { },
        onBeforeAddItem: function (item) {
            return Promise.resolve(item);
        },
        onAfterAddItem: function (_item) { },
        onBeforemModifyItem: function (item) {
            return Promise.resolve(item);
        },
        onAfterModifyItem: function (_item) { },
        onBeforeDeleteAll: function () {
            return Promise.resolve(true);
        },
        onAfterDeleteAll: function () { },
        onBeforeFiltered: function (_filter, _item) {
            return true;
        },
        onAfterFiltered: function (_filter, _item) { },
    };
    const legacyHook = useListFilter(hookInput);
    legacyHook.registerListener(listener);
    useEffect(() => {
        setLoading(true);
        setError(false);
        input
            .getDataFunction(api, input.fetchParams)
            .then((result) => {
            setInitialData(result);
            setLoading(false);
        })
            .catch(() => {
            setLoading(false);
            setError(true);
        });
    }, []);
    return Object.assign(Object.assign({}, legacyHook), { isLoading: isLoading, isDeletable: input.isDeletable, error: error });
}
