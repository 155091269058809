import { useState } from "react";
export function useListContext({ initialData, getID }) {
    const [data, setData] = useState(initialData);
    return {
        data,
        setDataList(data) {
            setData(data);
        },
        addDataItem(item) {
            const newDataList = Array();
            if (data) {
                newDataList.push(...data);
            }
            let find = false;
            let i = 0;
            for (i = 0; i < newDataList.length; i++) {
                if (getID(item) === getID(newDataList[i])) {
                    find = true;
                    break;
                }
            }
            if (!find) {
                newDataList.push(item);
                setData(newDataList);
            }
            else {
                newDataList[i] = item;
                setData(newDataList);
            }
            return newDataList;
        }
    };
}
