import { useFormSelectionItem, } from "../../../../common";
import { getLocalisationsByCriteria } from "../../../feature";
export function useFormSelectionLocalisationItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.localisationId;
        },
        config: input.config,
        initParams: input.initParams,
        isDataMatchWithFilter(_data, _filter) {
            return true;
        },
        onGetDataRequested: getLocalisationsByCriteria,
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
