var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter, TiersAdapter, AdresseAdapter } from "../../";
var ParametreEditionAdapter = /** @class */ (function (_super) {
    __extends(ParametreEditionAdapter, _super);
    function ParametreEditionAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tiersAdapter = new TiersAdapter;
        _this.adresseAdapter = new AdresseAdapter();
        return _this;
    }
    ParametreEditionAdapter.prototype.toClient = function (src) {
        return {
            id: src.id,
            tiers: this.tiersAdapter.toClient(src.tiers),
            adresse: this.adresseAdapter.toClient(src.adresse)
        };
    };
    ParametreEditionAdapter.prototype.toAPI = function (src) {
        return {
            id: src.id,
            tiers: this.tiersAdapter.toAPI(src.tiers),
            adresse: this.adresseAdapter.toAPI(src.adresse)
        };
    };
    return ParametreEditionAdapter;
}(Adapter));
export { ParametreEditionAdapter };
