import { FicheSuiviModel } from "@ads/isadom-model";
import { createContext, useContext, useState } from "react";

interface IContextStore {
  fichesSuivi: FicheSuiviModel[] | null;
}

const initalState: IContextStore = {
  fichesSuivi: null
};

interface StoreProviderInterface extends IContextStore {
  setFichesSuivi(fs: FicheSuiviModel[] | null): void;
}

const StoreContext = createContext(initalState as StoreProviderInterface);

export function useSelectFicheSuiviStore() {
  return useContext(StoreContext);
}

interface ProviderInterface {
  children: JSX.Element | JSX.Element[];
}

export function FichesSuiviStoreProvider({ children }: ProviderInterface) {
  const [state, setState] = useState(initalState);

  const value: StoreProviderInterface = {
    ...state,
    setFichesSuivi(fs) {
      setState({
        ...state,
        fichesSuivi: fs
      });
    }
  };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
}
