import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Icons } from "@ads/ui";
import { Modal } from "antd";
export const ButtonResetLivraisonsConso = ({ visiteID, resetData, isLoadingReset, }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleConfirmRaZ = () => {
        resetData(visiteID);
    };
    return (_jsxs("div", { children: [_jsx(Button, { as: "button", buttonStyle: isLoadingReset ? "neutral130" : "secondary", iconPosition: "left", type: "button", label: "R\u00E0Z", size: "S", icon: isLoadingReset ? undefined : Icons.Chrono, onClick: () => setIsModalVisible(true), isLoading: isLoadingReset }, void 0), _jsxs(Modal, Object.assign({ mask: false, visible: isModalVisible, onOk: () => {
                    handleConfirmRaZ();
                    setIsModalVisible(false);
                }, onCancel: () => {
                    setIsModalVisible(false);
                } }, { children: ["Attention la suppression des livraisons saisies est irr\u00E9versible.", _jsx("br", {}, void 0), " Souhaitez-vous continuer ?"] }), void 0)] }, void 0));
};
