import { motion } from "framer-motion";
import styled from "styled-components";

const WrapperBlur = styled(motion.div)<{ blur?: boolean, ref?: any }>`
  &.active {
    &::before {
      content: "";
      position: fixed;
      z-index: 200;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(0.5rem) brightness(30%);

      display: block;
    }
  }
`;

export default WrapperBlur;
