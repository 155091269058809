import { FichePatient, FichePatientTemplate } from "@ads/module-tiers";
import { MultiRollingPanelFixedTitle } from "@ads/ui";
import styled from "styled-components";

const Wrapper = styled.div`
  /* height: 100vh; */
  background: ${(props) => props.theme.colors.neutral[100]};
  width: calc(100vw - 7rem);
  ${(props) => props.theme.media.md} {
    width: 100%;
  }
`;

const FilterWrapper = styled("div")`
  padding-top: 8rem;
  ${(props) => props.theme.media.md} {
    width: 46.4rem;
    min-height: calc(100vh - 7rem);
  }
`;

export interface IPanelFichePatientProps {
  patientID: number;
}

const PanelFichePatient = (props: IPanelFichePatientProps) => (
  <Wrapper>
    <MultiRollingPanelFixedTitle title="Fiche Patient" />
    <FilterWrapper>
      <FichePatientTemplate
        patientID={props.patientID}
        children={FichePatient}
      />
    </FilterWrapper>
  </Wrapper>
);

export default PanelFichePatient;
