import { natureProduit, TypeProduitModel } from "@ads/isadom-model";
import {
  SelectionConsommable,
  SelectionConsommableProps,
} from "@ads/module-produit";
import { SelectionConsommableProps as SelectionConsommablePropsFromModuleConso } from "@ads/module-consommables-visite";
import { getDefaultApiInstance, ERROR_UNKNOWN } from "@ads/react-common-module";
import { Loading } from "@ads/ui";
import { Alert } from "antd";
import WrapperAlert from "components/ui/organisms/wrappers/WrapperAlert";
import { useState, useEffect } from "react";
import { apiGetTypeProduitByID } from "api/appareil";

export function SelectionConsommableConfigConso(
  props: SelectionConsommablePropsFromModuleConso
) {
  const [defaultTypeProduit, setDefaultTypeProduit] =
    useState<TypeProduitModel>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiGetTypeProduitByID(getDefaultApiInstance(), "CSO")
      .then((result) => {
        setDefaultTypeProduit(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading && !defaultTypeProduit) {
    return (
      <Loading>
        Chargement du composant pour l'ajout d'un consommable en cours.
      </Loading>
    );
  } else if (!loading && defaultTypeProduit) {
    const selectionConsommableProps: SelectionConsommableProps = {
      config: {
        natureConfig: {
          allowChange: false,
          defaultSelectedData: [natureProduit("C")],
          typeSelection: "simple",
          visible: false,
        },
        typeProduitConfig: {
          allowChange: true,
          defaultSelectedData: [defaultTypeProduit],
          typeSelection: "simple",
          visible: true,
        },
        familleConfig: {
          allowChange: true,
          defaultSelectedData: [],
          typeSelection: "simple",
          visible: true,
        },
        sousFamilleConfig: {
          allowChange: true,
          defaultSelectedData: [],
          typeSelection: "simple",
          visible: true,
        },
        typePrestationConfig: {
          allowChange: true,
          defaultSelectedData: [],
          typeSelection: "simple",
          visible: true,
        },
      },
      // hasScanner: true,
      onConsommablesSelected: props.onConsommableSelected,
      hasScanner: true,
    };

    return <SelectionConsommable {...selectionConsommableProps} />;
  } else {
    return (
      <WrapperAlert>
        <Alert
          showIcon
          description={`Erreur lors du chargement du composant d'ajout de consommable (${ERROR_UNKNOWN}).`}
          type="error"
        />
      </WrapperAlert>
    );
  }
}
