import { getDefaultApiInstance, apiPutV2 } from "@ads/react-common-module";
import { LivraisonVisiteAdapter, } from "@ads/isadom-model";
export const apiEnregistreConsommablesLivraisons = (visiteID, livraisonsVisite) => {
    const api = getDefaultApiInstance();
    const livraisonAdapter = new LivraisonVisiteAdapter();
    if (api) {
        const livraisonsAPI = livraisonsVisite.map((livraison) => livraisonAdapter.toAPI(livraison));
        return apiPutV2(api, `/visites/${visiteID}/livraisons`, livraisonsAPI);
    }
    return new Promise((_, reject) => {
        reject("La configuration api est nulle");
    });
};
