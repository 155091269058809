import { ProgrammeEducatifAdapter } from "@ads/isadom-model";
import { getDefaultApiInstance, useApiGetV3 } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export function useApiGetProgrammeEducatifByIDAndVisiteID(
  peiID: number,
  visiteID: number
) {
  const adapter = new ProgrammeEducatifAdapter();
  return useApiGetV3(
    getDefaultApiInstance(),
    `/visites/${visiteID}/programmes-educatifs/${peiID}`,
    {
      errorStrategy: apiIsadomErrorStrategy,
      adaptResult: (data) => {
        const result = adapter.toClient(data.data);
        return result;
      }
    }
  );
}
