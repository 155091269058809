import { useFormSelectionItem, } from "../../../../common";
import { searchTypePrestations, } from "../../../feature/searchTypePrestation";
export function useFormSelectionTypePrestationItem(input) {
    const legacyHookInput = {
        getKeyFromData(data) {
            return data.id; //Récupération de l'id du type de prestation choisi
        },
        config: input.config,
        initParams: input.initParams,
        isDataMatchWithFilter(data, filter) {
            //Mise à jour des types de prestation en fonction de ou des famille(s)
            if (filter.familleIDs) {
                for (const familleID of filter.familleIDs) {
                    if (familleID === data.id) {
                        return true;
                    }
                }
            }
            return false;
        },
        onGetDataRequested: searchTypePrestations, //recupération de la liste des types de prestations(appel back)
    };
    const legacyHook = useFormSelectionItem(legacyHookInput);
    return legacyHook;
}
