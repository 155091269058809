import styled from "styled-components";

export const Content = styled("div")`
  transform-style: preserve-3d;
  position: relative;
  margin-top: 2.5rem;
  padding: 2.2rem 1.9rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.neutral[100]};
  box-shadow: 0 8px 1rem rgb(80 70 70 / 25%);
  margin-bottom: 8.6rem;

  &::before {
    transform: translateZ(-1px);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 1rem;
    width: 100%;
    /* box-shadow: 1px -4px 12px rgb(80 70 70 / 21%); */
    box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  }

  ${({ theme }) => theme.media.md} {
    padding: 4rem 6rem;
    margin-bottom: 0;
    box-shadow: 0 4px 1rem rgba(80, 70, 70, 0.25);
  }
`;
