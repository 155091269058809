import { VisiteModel } from "@ads/isadom-model";
import { createContext, useContext, useState } from "react";

interface VisiteStore {
  visitesMap: Map<number, VisiteModel>;
}

const initalState: VisiteStore = {
  visitesMap: new Map()
};

interface VisiteStoreProviderInterface extends VisiteStore {
  addVisite(visite: VisiteModel): boolean;
}

const VisiteStoreContext = createContext(
  initalState as VisiteStoreProviderInterface
);

export function useSelectVisiteStore() {
  return useContext(VisiteStoreContext);
}

interface ProviderInterface {
  children: JSX.Element | JSX.Element[];
}

export function VisiteStoreProvider({ children }: ProviderInterface) {
  const [state, setState] = useState(initalState);

  const value: VisiteStoreProviderInterface = {
    ...state,
    addVisite(visite) {
      const visiteMapUpdated = state.visitesMap.set(visite.ID, visite);
      setState({ ...state, visitesMap: visiteMapUpdated });
      return true;
    }
  };

  return (
    <VisiteStoreContext.Provider value={value}>
      {children}
    </VisiteStoreContext.Provider>
  );
}
