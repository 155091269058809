import { GenericError } from "../../interface/erreur/api/genericError";
import {
  ItemStoreErrors,
  TypeError,
} from "../../interface/store/itemStoreErrors";
import { IErrorBackEnd } from "../../interface/erreur/api/errorBackEnd";

interface IItemError {
  message: string;
  idConcerne: string;
}

const errorAdapter = (error: IErrorBackEnd) => {
  let errors: ItemStoreErrors[] = [];
  if (error) {
    for (const [key, value] of Object.entries(error)) {
      const arrayError = value as GenericError[];
      if (arrayError.length > 0) {
        const messagesAndIds = arrayError.map((element: IItemError) => ({
          message: element.message,
          id: element.idConcerne,
        }));
        errors = messagesAndIds.map((messageId) => ({
          sType: key as TypeError,
          message: messageId.message,
          idConcerne: messageId.id,
        }));
      }
    }
  } else {
    errors.push({
      sType: "systeme" as TypeError,
      message: "Une erreur inatendue est survenue",
      idConcerne: "0",
    });
  }
  return errors;
};

export default errorAdapter;
