var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import axios from "axios";
import { setup } from "axios-cache-adapter";
import { deepCopy, ApplicationContext } from "..";
import { HttpResponseError } from "../error/HttpResponseError";
import { ApiIsadomHttpResponseError } from "../error/ApiIsadomHttpResponseError";
import { BaseError } from "../error/BaseError";
import { ERROR_HTTP_API_NULL, ERROR_HTTP_UNKNOWN_POST, ERROR_HTTP_UNKNOWN_PUT, ERROR_HTTP_UNKNOWN_DELETE, ERROR_HTTP_UNKNOWN_SEARCH, ERROR_HTTP_UNKNOWN_GET, } from "../error/CodeError";
/**
 * @deprecated since 0.3.0 prefer use getDefaultApiInstance
 */
export function useApi() {
    const appSettings = ApplicationContext.getInstance()
        .getAppSettings()
        .getData();
    if (appSettings) {
        return getApiInstance(appSettings.apiConfig);
    }
    else {
        return null;
    }
}
/**
 * @deprecated since 0.3.0
 * @param uri
 * @param adapter
 * @returns
 */
export function useApiGet(uri, adapter, _cache) {
    const [data, setData] = useState();
    const [error, setError] = useState("");
    const [isLoading, setloading] = useState(true);
    const api = useApi();
    const fetchData = () => {
        api === null || api === void 0 ? void 0 : api.get(uri).then((response) => {
            if (response.data instanceof Array) {
                setData(adapter.toClients(response.data));
            }
            else {
                setData(adapter.toClient(response.data));
            }
        }).catch((error) => {
            var _a, _b;
            if (axios.isAxiosError(error)) {
                if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                    setError("Erreur interne du serveur.");
                }
                else {
                    setError(((_b = error.response) === null || _b === void 0 ? void 0 : _b.data) || "Erreur inconnue");
                }
            }
            else {
                setError(error);
            }
        }).finally(() => {
            setloading(false);
        });
    };
    useEffect(() => {
        fetchData();
    }, []);
    return { data, error, isLoading };
}
/**
 *
 * @deprecated since 0.3.0 prefer use apiGet
 * @param promise
 * @returns
 */
export function useAdaptApiGet(promise) {
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState();
    const [data, setData] = useState();
    promise
        .then((data) => {
        setData(deepCopy(data));
    })
        .catch((err) => {
        setErr(err);
    })
        .finally(() => {
        setIsLoading(false);
    });
    return { data, isLoading, error: err };
}
export function getDefaultApiInstance() {
    const appSettings = ApplicationContext.getInstance()
        .getAppSettings()
        .getData();
    if (appSettings) {
        return getApiInstance(appSettings.apiConfig);
    }
    else {
        return null;
    }
}
export function getApiInstance(apiConfig) {
    if (apiConfig) {
        const api = setup({
            baseURL: apiConfig.baseURL,
            cache: apiConfig.cache
                ? {
                    maxAge: apiConfig.cache.maxAge || 2 * 60 * 1000,
                    exclude: {
                        methods: ["post", "put", "patch", "delete"],
                    },
                }
                : undefined,
            withCredentials: false,
        });
        return api;
    }
    return null;
}
/**
 * @deprecated since 0.3.0 prefer use apiGetV2
 * @param api
 * @param uri
 * @param adapter
 * @returns
 */
export function apiGet(api, uri, adapter) {
    return new Promise((resolve, reject) => {
        api
            .get(uri)
            .then((response) => {
            if (response.data instanceof Array) {
                resolve(adapter.toClients(response.data));
            }
            else {
                resolve(adapter.toClient(response.data));
            }
        })
            .catch((error) => {
            var _a, _b;
            if (axios.isAxiosError(error)) {
                if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                    reject("Erreur interne du serveur.");
                }
                else {
                    reject(((_b = error.response) === null || _b === void 0 ? void 0 : _b.data) || "Erreur inconnue");
                }
            }
            else {
                reject(error);
            }
        });
    });
}
export function apiGetV2(api, uri, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            try {
                return (yield api.get(uri)).data;
            }
            catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const newError = new HttpResponseError(ERROR_HTTP_UNKNOWN_GET, error.response.status, error.response.data);
                        if (errorStrategy) {
                            errorStrategy(newError);
                        }
                        throw newError;
                    }
                    else {
                        throw new BaseError(ERROR_HTTP_UNKNOWN_GET);
                    }
                }
                else {
                    return error.data;
                }
            }
        }
        else {
            throw new BaseError(ERROR_HTTP_API_NULL);
        }
    });
}
function useApiGetCommon(api, uri, apiRequestFunction, options) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    function fetch() {
        setLoading(true);
        apiRequestFunction(api, uri, options === null || options === void 0 ? void 0 : options.errorStrategy)
            .then((response) => {
            if (!(options === null || options === void 0 ? void 0 : options.adaptResult)) {
                setData(response);
            }
            else {
                setData(options.adaptResult(response));
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }
    return {
        data: data,
        fetch: fetch,
        loading: loading,
    };
}
export function useApiGetV2(api, uri, options) {
    return useApiGetCommon(api, uri, apiGetV2, options);
}
function useApiPostCommon(api, uri, apiRequestFunction, options) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    function post(body) {
        setLoading(true);
        apiRequestFunction(api, uri, body, options === null || options === void 0 ? void 0 : options.errorStrategy)
            .then((response) => {
            if (!(options === null || options === void 0 ? void 0 : options.adaptResult)) {
                setData(response);
            }
            else {
                setData(options.adaptResult(response));
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }
    return {
        data: data,
        post: post,
        loading: loading,
    };
}
export function useApiPostV2(api, uri, options) {
    return useApiPostCommon(api, uri, apiPutV2, options);
}
function useApiPutCommon(api, uri, body, apiRequestFunction, options) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    function put() {
        setLoading(true);
        apiRequestFunction(api, uri, body, options === null || options === void 0 ? void 0 : options.errorStrategy)
            .then((response) => {
            if (!(options === null || options === void 0 ? void 0 : options.adaptResult)) {
                setData(response);
            }
            else {
                setData(options.adaptResult(response));
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }
    return {
        data: data,
        put: put,
        loading: loading,
    };
}
export function useApiPutV2(api, uri, body, options) {
    return useApiPutCommon(api, uri, body, apiPutV2, options);
}
/**
 *
 * @param api @deprecated since 0.3.0 prefer use apiSearchV2
 * @param uri
 * @param adapter
 * @param body
 * @returns
 */
export function apiSearch(api, uri, adapter, body) {
    return new Promise((resolve, reject) => {
        api
            .post(uri, body)
            .then((response) => {
            resolve(adapter.toClients(response.data));
        })
            .catch((error) => {
            var _a, _b;
            if (axios.isAxiosError(error)) {
                if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                    reject("Erreur interne du serveur.");
                }
                else {
                    reject(((_b = error.response) === null || _b === void 0 ? void 0 : _b.data) || "Erreur inconnue");
                }
            }
            else {
                reject(error);
            }
        });
    });
}
function apiPutPost(method, uri, adapter, body) {
    return new Promise((resolve, reject) => {
        let realBody;
        if (body instanceof Array) {
            realBody = adapter.toAPIs(body);
        }
        else {
            realBody = adapter.toAPI(body);
        }
        method(uri, realBody)
            .then((response) => {
            if (response.data instanceof Array) {
                resolve(adapter.toClients(response.data));
            }
            else {
                resolve(adapter.toClient(response.data));
            }
        })
            .catch((error) => {
            var _a, _b;
            if (axios.isAxiosError(error)) {
                if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                    reject("Erreur interne du serveur.");
                }
                else {
                    reject(((_b = error.response) === null || _b === void 0 ? void 0 : _b.data) || "Erreur inconnue");
                }
            }
            else {
                reject(error);
            }
        });
    });
}
/**
 *  @deprecated since 0.3.0 prefer use apiPutV2
 * */
export function apiPut(api, uri, adapter, body) {
    if (api === null) {
        throw new Error("La config api est null. Vérrifiez votre utilisation du context applicatif");
    }
    return apiPutPost(api.put, uri, adapter, body);
}
/**
 *  @deprecated since 0.3.0 prefer use apiPostV2
 * */
export function apiPost(api, uri, adapter, body) {
    return apiPutPost(api.post, uri, adapter, body);
}
/**
 *
 * @param api @deprecated since 0.3.0 prefer use apiDeleteV2
 * @param uri
 * @returns
 */
export function apiDelete(api, uri) {
    return api.delete(uri);
}
export function apiDeleteV2(api, uri, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            try {
                return (yield api.delete(uri)).data;
            }
            catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const newError = new HttpResponseError(ERROR_HTTP_UNKNOWN_DELETE, error.response.status, error.response.data);
                        if (errorStrategy) {
                            errorStrategy(newError);
                        }
                        throw newError;
                    }
                    else {
                        throw new BaseError(ERROR_HTTP_UNKNOWN_DELETE);
                    }
                }
                else {
                    return error.data;
                }
            }
        }
        else {
            throw new BaseError(ERROR_HTTP_API_NULL);
        }
    });
}
export function apiIsadomDelete(api, uri, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield apiDeleteV2(api, uri);
        }
        catch (error) {
            if (error instanceof HttpResponseError) {
                const newErrorType = new ApiIsadomHttpResponseError(ERROR_HTTP_UNKNOWN_DELETE, error.status, error.data, error.message);
                if (errorStrategy) {
                    errorStrategy(newErrorType);
                }
                throw newErrorType;
            }
            else {
                throw new BaseError(ERROR_HTTP_UNKNOWN_DELETE);
            }
        }
    });
}
export function apiPutV2(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            try {
                return (yield api.put(uri, body)).data;
            }
            catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const newError = new HttpResponseError(ERROR_HTTP_UNKNOWN_POST, error.response.status, error.response.data);
                        if (errorStrategy) {
                            errorStrategy(newError);
                        }
                        throw newError;
                    }
                    else {
                        throw new BaseError(ERROR_HTTP_UNKNOWN_POST);
                    }
                }
                else {
                    return error.data;
                }
            }
        }
        else {
            throw new BaseError(ERROR_HTTP_API_NULL);
        }
    });
}
export function apiSearchV2(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            try {
                return (yield api.post(uri, body)).data;
            }
            catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const newError = new HttpResponseError(ERROR_HTTP_UNKNOWN_SEARCH, error.response.status, error.response.data);
                        if (errorStrategy) {
                            errorStrategy(newError);
                        }
                        throw newError;
                    }
                    else {
                        throw new BaseError(ERROR_HTTP_UNKNOWN_SEARCH);
                    }
                }
                else {
                    return error.data;
                }
            }
        }
        else {
            throw new BaseError(ERROR_HTTP_API_NULL);
        }
    });
}
export function apiPostV2(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            try {
                return (yield api.post(uri, body)).data;
            }
            catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        const newError = new HttpResponseError(ERROR_HTTP_UNKNOWN_POST, error.response.status, error.response.data);
                        if (errorStrategy) {
                            errorStrategy(newError);
                        }
                        throw newError;
                    }
                    else {
                        throw new BaseError(ERROR_HTTP_UNKNOWN_POST);
                    }
                }
                else {
                    return error.data;
                }
            }
        }
        else {
            throw new BaseError(ERROR_HTTP_API_NULL);
        }
    });
}
export function apiIsadomPost(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield apiPostV2(api, uri, body);
        }
        catch (error) {
            if (error instanceof HttpResponseError) {
                const newErrorType = new ApiIsadomHttpResponseError(ERROR_HTTP_UNKNOWN_POST, error.status, error.data, error.message);
                if (errorStrategy) {
                    errorStrategy(newErrorType);
                }
                throw newErrorType;
            }
            else {
                throw new BaseError(ERROR_HTTP_UNKNOWN_POST);
            }
        }
    });
}
export function apiIsadomSearch(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield apiSearchV2(api, uri, body);
        }
        catch (error) {
            if (error instanceof HttpResponseError) {
                const newErrorType = new ApiIsadomHttpResponseError(ERROR_HTTP_UNKNOWN_SEARCH, error.status, error.data, error.message);
                if (errorStrategy) {
                    errorStrategy(newErrorType);
                }
                throw newErrorType;
            }
            else {
                throw new BaseError(ERROR_HTTP_UNKNOWN_SEARCH);
            }
        }
    });
}
export function apiIsadomPut(api, uri, body, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield apiPutV2(api, uri, body);
        }
        catch (error) {
            if (error instanceof HttpResponseError) {
                const newErrorType = new ApiIsadomHttpResponseError(ERROR_HTTP_UNKNOWN_PUT, error.status, error.data, error.message);
                if (errorStrategy) {
                    errorStrategy(newErrorType);
                }
                throw newErrorType;
            }
            else {
                throw new BaseError(ERROR_HTTP_UNKNOWN_PUT);
            }
        }
    });
}
export function apiIsadomGet(api, uri, errorStrategy) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            return yield apiGetV2(api, uri);
        }
        catch (error) {
            if (error instanceof HttpResponseError) {
                const newErrorType = new ApiIsadomHttpResponseError(ERROR_HTTP_UNKNOWN_GET, error.status, error.data, error.message);
                if (errorStrategy) {
                    errorStrategy(newErrorType);
                }
                throw newErrorType;
            }
            else {
                throw new BaseError(ERROR_HTTP_UNKNOWN_GET);
            }
        }
    });
}
