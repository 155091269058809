var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Button, Icons, IconDeleteItem } from "@ads/ui";
import { FormRetourVisiteQuantite } from "../molecules/FormRetourVisiteQuantite";
import { FormRetourVisiteNumLot } from "../molecules/FormRetourVisiteNumLot";
import { FormRetourVisiteDLCLot } from "../molecules/FormRetourVisiteDLCLot";
// import { apiRetireConsommableRetourVisite } from "../../api/apiRetireConsommableRetourVisite";
import { FormRetourVisiteLocalisation } from "../molecules/FormRetourVisiteLocalisation";
const WrapperMain = styled.fieldset `
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2.6rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.lg} {
  }
`;
const ButtonRemove = styled.button `
  position: absolute;
  top: -1.5rem;
  right: -1.5rem;
  z-index: 10;
  margin: 0;
  padding: 0;
  height: 4.2rem;
  width: 4.2rem;
  line-height: 4.2rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  circle {
    transition: all 0.2s linear;
  }
  &:hover {
    circle {
      fill: ${({ theme }) => theme.colors.danger[100]};
    }
  }
`;
const Card = styled.div `
  background: ${(props) => props.theme.colors.neutral[100]};
  border-radius: 0.6rem;
  box-shadow: 0 0 1rem rgba(80, 70, 70, 0.25);
  transition: transform 0.1s ease-out, box-shadow 0.2s;
`;
const RetourVisiteStyled = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.6rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0.6rem 0;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: 6rem auto;
    max-width: 80rem;
  }
`;
const Status = styled.div `
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  padding: 1.7rem;
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: inherit;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;
const ConsommableStatus = styled.div `
  width: 3rem;
  height: 3rem;
  background: ${({ theme, active }) => active ? theme.colors.secondary[100] : theme.colors.neutral[130]};
  border-radius: 50%;
  transition: background 0.25s ease-in-out;
`;
const Details = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.md} {
  }
  ${({ theme }) => theme.media.lg} {
  }
`;
const ConsommableNom = styled.div `
  font-weight: 700;
  display: block;
  ${({ theme }) => theme.media.lg} {
    display: flex;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;
const WrapperDetailPrecisions = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem 2rem;
`;
const WrapperRetour = styled.div `
  margin-bottom: 1rem;

  &:last-child {
    margin: 0;
  }
`;
const RetourDetail = styled.div `
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`;
const Precisions = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const LotDetails = styled.div `
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;

  & button {
    width: 4.8rem;
    height: 4.8rem;
    padding: 1rem;
    gap: 0;
  }

  ${({ theme }) => theme.media.md} {
    padding: inherit;
  }

  ${({ theme }) => theme.media.lg} {
    & button {
      position: absolute;
      right: -5.8rem;
    }
  }
`;
const FormArea = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  ${({ theme }) => theme.media.lg} {
  }
`;
export const LigneFormulaireRetour = ({ data, localisationsRetraits, refreshData, refreshAllData, removeData, isDataConfig, }) => {
    const removeRetour = (retour) => __awaiter(void 0, void 0, void 0, function* () {
        removeData(retour.ligneRetourID);
    });
    return (_jsxs(WrapperMain, { children: [!isDataConfig(data.retours[0]) &&
                !data.retours.find((r) => r.ligneRetourID > 0) && (_jsx(ButtonRemove, Object.assign({ onClick: (e) => {
                    e.preventDefault();
                    data.retours.forEach((r) => {
                        removeRetour(r);
                    });
                } }, { children: _jsx(IconDeleteItem, { iconSize: "3em", iconColor: "#ffffff" }, void 0) }), void 0)), _jsx(Card, { children: _jsxs(RetourVisiteStyled, { children: [_jsx(Status, { children: _jsx(ConsommableStatus, { active: false }, void 0) }, void 0), _jsxs(WrapperDetailPrecisions, { children: [_jsx(Details, { children: _jsx(ConsommableNom, { children: `${data.consommable.sLibelle} - ${data.consommable.sRefProduit}` }, void 0) }, void 0), data.retours.map((retour, index) => (_jsxs(WrapperRetour, { children: [index < 1 && (_jsxs(RetourDetail, { children: [_jsx(Precisions, { children: _jsx(FormRetourVisiteLocalisation, { localisationsRetraits: localisationsRetraits, retour: retour, refreshAllData: refreshAllData }, void 0) }, void 0), _jsx(FormArea, { children: _jsx(FormRetourVisiteQuantite, { retour: data.retours.length > 1
                                                            ? {
                                                                iQuantite: data.quantite,
                                                            }
                                                            : retour, disabled: data.consommable.bSuiviSortie, lot: false, refreshData: refreshData }, void 0) }, void 0)] }, void 0)), data.consommable.bSuiviSortie && (_jsxs(LotDetails, { children: [data.retours.length > 1 && (_jsx(Button, { as: "button", type: "button", label: "", buttonStyle: "primary", size: "S", icon: Icons.Close, onClick: (e) => {
                                                        e.preventDefault();
                                                        removeRetour(retour);
                                                    } }, void 0)), _jsx(FormRetourVisiteNumLot, { retour: retour, refreshData: refreshData }, void 0), _jsx(FormRetourVisiteDLCLot, { retour: retour, refreshData: refreshData }, void 0), _jsx(FormRetourVisiteQuantite, { retour: retour, disabled: false, lot: true, refreshData: refreshData }, void 0)] }, void 0))] }, retour.ligneRetourID)))] }, void 0)] }, void 0) }, void 0)] }, void 0));
};
