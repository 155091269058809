import { PrestationModel, PrestationAdapter } from "@ads/isadom-model";
import { apiGet, getDefaultApiInstance } from "@ads/react-common-module";

export function apiGetPrestationsByTiersID(
  tiersID: number,
  date: string
): Promise<PrestationModel[]> {
  const api = getDefaultApiInstance();
  if (api) {
    const adapter = new PrestationAdapter();
    return apiGet(
      api,
      `tiers/${tiersID}/prestations?dto=0&dDate=${date.split("-").join("")}`,
      adapter
    ) as Promise<PrestationModel[]>;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
