import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notification } from "antd";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import config from "config"
import { EvenementRGPDAdapter, EvenementRGPDModel, VisiteAdapter } from "@ads/isadom-model";
import { apiIsadomErrorStrategy, Breadcrumb, FormWidget, Loading } from "@ads/ui";
import MenuTab from "../../../../../components/ui/molecules/MenuTab";
import ContentTab from "../../../../../components/ui/molecules/ContentTab";
import {
  EvenementRGPDDetailsForm,
  EvenementRGPDInformationsForm,
} from "../../molecules/evenementRGPD";
import { useAddEvenementMutation, useGetVisiteQuery, useUpdateEvenementMutation } from "store/redux/services/nomadApi";
import { RouteParam } from "interface/core/RouteParam";
import useGetEvenement from "modules/evenement/hooks/useGetEvenement";
import getBreadcrumbFormEvenement from "modules/evenement/functions/getBreadcrumbFormEvenement";

const Content = styled.div`
  display: block;

  ${({ theme }) => theme.media.md} {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

const ContentTabEnhanced = styled(ContentTab)`
	padding: 2rem 4rem;
`;

const tabActive = "rgpd"

export const EvenementRGPDForm = () => {
  const history = useHistory();
  const { iPKVisite: visiteID, evenementID } = useParams<RouteParam>()
  const { data: visiteAPI, isLoading: isLoadingVisite } = useGetVisiteQuery(+visiteID)
  const visiteClient = visiteAPI && new VisiteAdapter().toClient(visiteAPI);
  const { evenement, loading: isLoadingEvenement } = useGetEvenement(evenementID, tabActive, visiteClient)

  const [formData, setFormData] = useState<EvenementRGPDModel>();

  // Typeguard
  let evenementRGPDModel: EvenementRGPDModel | undefined
  if ((evenement && "estRealisationPrestation" in evenement) || evenement === undefined) {
    evenementRGPDModel = evenement
  }

  useEffect(() => {
    if (evenementRGPDModel)
      setFormData(evenementRGPDModel)
  }, [evenementRGPDModel])

  const breadcrumbsItems = getBreadcrumbFormEvenement(evenement, visiteClient, tabActive)

  const handleChange = (formData: EvenementRGPDModel) => {
    setFormData(formData);
  };

  const [updateEvenement, { isLoading: isLoadingUpdateEvenement }] = useUpdateEvenementMutation()
  const [addEvenement, { isLoading: isLoadingAddEvenement }] = useAddEvenementMutation()
  const adapter = new EvenementRGPDAdapter();

  const handleSubmit = async () => {
    if (!formData) return;

    if (!evenement) {
      const evenementRGPD = {
        ...formData,
        typeEvenement: {
          ID: "RGPD",
        },
      } as EvenementRGPDModel

      const evenementApi = adapter.toAPI(evenementRGPD)

      addEvenement({ body: evenementApi, params: { visiteId: visiteID } }).unwrap().then(() => {
        notification.success({
          key: `form_success`,
          message: "Confirmation",
          description: "L'évènement a bien été enregistré.",
        });
        history.push(`/visites/${visiteID}/evenements/${tabActive}`);
      })
        .catch((error: any) => apiIsadomErrorStrategy(error))
    } else if (evenement.evenementVisiteID) {
      const evenementApi = adapter.toAPI(formData as EvenementRGPDModel)

      const data = {
        body: evenementApi,
        params: {
          visiteId: visiteID,
          evenementVisiteId: evenement.evenementVisiteID,
        }
      }

      updateEvenement(data).unwrap().then(() => {
        notification.success({
          key: `form_success`,
          message: "Confirmation",
          description: "L'évènement a bien été mis à jour.",
        });
        history.push(`/visites/${visiteID}/evenements/${tabActive}`);
      }).catch((error: any) => apiIsadomErrorStrategy(error))

    }
  };

  // On affiche uniquement le loading en cas d'édition d'un évènement
  if (evenementID !== "nouveau" && (isLoadingVisite || isLoadingEvenement)) {
    return <Loading>Chargement de l'évènement.</Loading>
  }
  const title = evenement ? `Évènement ${evenement.typeEvenement.libelle}` : "Nouvel Évènement";

  return (
    <>
      <Helmet title={`${title} - NOMAD v${config.version}`} />
      {breadcrumbsItems && <Breadcrumb breadcrumbItems={breadcrumbsItems} size="M" />}
      <FormWidget
        handleCancel={() =>
          history.push(`/visites/${visiteID}/evenements/${tabActive}`)
        }
        handleSave={handleSubmit}
        isLoadingSave={isLoadingAddEvenement || isLoadingUpdateEvenement}
      >
        <MenuTab
          tabs={[
            { id: "informations", label: "Informations générales", url: "#" },
          ]}
          subTabActive={"informations"}
        />
        <ContentTabEnhanced>
          <Content>
            <EvenementRGPDInformationsForm
              onChange={handleChange}
              evenement={formData}
            />
            <EvenementRGPDDetailsForm
              onChange={handleChange}
              evenement={formData}
            />
          </Content>
        </ContentTabEnhanced>
      </FormWidget>
    </>
  );
};