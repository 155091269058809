import { useApiGetTypeProgrammeEducatifList } from "../api/useApiGetTypeProgrammeEducatifList"
import { useSelectPEStore } from "../components/context/PEContext"

export function useSyncTypePEWithContext() {
    const { metadata, setMetadata } = useSelectPEStore()
    const service = useApiGetTypeProgrammeEducatifList()
    async function fetchWithSync() {
        const result = await service.fetch()
        setMetadata({
            etapeListService: metadata.etapeListService,
            typePEListService: { ...service }
        })
        return result
    }

    return {
        ...service,
        fetch: fetchWithSync
    }
}