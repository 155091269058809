var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiIsadomErrorStrategy } from "@ads/ui";
import { HttpResponseAdapter, FichePatientAdapter, } from "@ads/isadom-model";
import { apiIsadomPost } from "@ads/react-common-module";
export function apiGetPatientById(api, id) {
    return __awaiter(this, void 0, void 0, function* () {
        if (api) {
            const adapter = new HttpResponseAdapter(new FichePatientAdapter());
            const result = yield apiIsadomPost(api, `/annuaires/${id}`, [
                { nomObjet: "TIERS", tParam: [] },
                { nomObjet: "PRESTATION", tParam: [] },
                { nomObjet: "PRESCRIPTION", tParam: [] },
                { nomObjet: "APPAREIL", tParam: [] },
                { nomObjet: "CONSOMMABLE", tParam: [] },
                { nomObjet: "ALERTE", tParam: [] },
            ], apiIsadomErrorStrategy);
            return adapter.toClient(result);
        }
        return new Promise((_resolve, reject) => {
            reject("La config d'api est null");
        });
    });
}
