import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Loading, FormItemWrapper, Label, SelectWrapper } from "@ads/ui";
import { useQuestionnaireForm } from "../../hooks/questionnaire/form/useQuestionnaireForm";
import { QuestionnaireDetailMolecule } from "./QuestionnaireDetailMolecule";
import { Select, Modal, Alert } from "antd";
import { useState } from "react";
const { Option } = Select;
export function QuestionnaireForm(props) {
    var _a, _b, _c;
    const service = useQuestionnaireForm({
        visiteID: props.visiteID,
        tiersVisiteID: props.tiersVisiteID,
    });
    const [modalVisible, setModalVisible] = useState("");
    const [typeQuestionnaireID, setTypeQuestionnaireID] = useState(((_a = service.questionnaire) === null || _a === void 0 ? void 0 : _a.typeQuestionnaire)
        ? (_b = service.questionnaire) === null || _b === void 0 ? void 0 : _b.typeQuestionnaire.libelle
        : "");
    if (service.isLoading) {
        return _jsx(Loading, { children: "Chargement du type de Questionnaire" }, void 0);
    }
    if (service.error) {
        return (_jsx(Alert, { message: "Erreur", showIcon: true, description: "Une erreur est survenue pendant le chargement du questionnaire", type: "error" }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsxs(FormItemWrapper, Object.assign({ direction: "vertical" }, { children: [_jsx(Label, Object.assign({ htmlFor: "typeQuestionnaireID" }, { children: "Type de questionnaires" }), void 0), _jsx(SelectWrapper, Object.assign({ selectMode: "light" }, { children: _jsx(Select, Object.assign({ id: "typeQuestionnaireID", showSearch: true, value: typeQuestionnaireID, onSelect: (value, _option) => {
                                setTypeQuestionnaireID(value);
                                setModalVisible("modify");
                            }, placeholder: "S\u00E9lectionnez le type de questionnaire", filterOption: (input, option) => {
                                return (option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0 ||
                                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0);
                            } }, { children: (_c = service.typeQuestionnairesDisponibles) === null || _c === void 0 ? void 0 : _c.map((typeObjet) => (_jsx(Option, Object.assign({ value: typeObjet.typeQuestionnaireID }, { children: typeObjet.libelle }), `${typeObjet.typeQuestionnaireID}`))) }), void 0) }), void 0), _jsx(Modal, Object.assign({ title: "", visible: modalVisible === "modify", onOk: () => {
                            setModalVisible("");
                            service.typeQuestionnairesDisponibles.map((e) => {
                                if (e.typeQuestionnaireID === typeQuestionnaireID) {
                                    service.updateTypeQuestionnaire(e);
                                }
                            });
                        }, onCancel: () => setModalVisible("") }, { children: _jsx("p", { children: "Voulez-vous initialiser ou reinitialiser le questionnaire ? Attention, cela effacera la totalit\u00E9 du questionnaire en cours s'ily en a un." }, void 0) }), void 0)] }), void 0), " ", service.questionnaire && (_jsx(QuestionnaireDetailMolecule, { fetchError: undefined, questionnaireLoading: service.isLoading, disableActions: false, cancelLocalChange: () => {
                    service.cancelCreateQuestionnaire();
                }, performUpdate: () => {
                    return service.uploadQuestionnaire();
                }, questionnaire: service.questionnaire, onQuestionnaireChange: (questionnaire) => {
                    service.onUpdate(questionnaire);
                } }, void 0))] }, void 0));
}
