import styled from "styled-components";
import { IconAlertHome } from "@ads/ui";
import { ConfigAppareilSpecTiersVisiteAPI } from "@ads/isadom-model";
import nomadTheme from "utils/nomad-theme";

const Alerts = styled.div`
  grid-area: alerts;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  text-align: right;
  height: 4rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[110]};

  & > img {
    height: 3rem;
    width: auto;
  }

  ${({ theme }) => theme.media.lg} {
    border-bottom: 0;
    padding-right: 2rem;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[120]};
  }
`;
const WrapperIconsAlert = styled("div")`
  display: flex;
  padding: 0 1rem 0 0;

  ${({ theme }) => theme.media.lg} {
    padding: 0;
    gap: 8px;
    align-items: baseline;
  }

  & > .icon {
    font-size: 2.6rem;
    ${({ theme }) => theme.media.lg} {
      font-size: 3.8rem;
    }
  }
`;

interface Props {
  appareilsAbsents: ConfigAppareilSpecTiersVisiteAPI[];
  configurationProduitSpec: ConfigAppareilSpecTiersVisiteAPI;
}

const AlertesAppareilItem = ({
  configurationProduitSpec,
  appareilsAbsents,
}: Props) => {
  //Tableau des appareils absents du lieu de visite
  const idAppareilsAbsents = appareilsAbsents.map(
    (app) => app.produit.IDProduit
  );

  const isAbsent = idAppareilsAbsents.includes(
    configurationProduitSpec.produit.IDProduit
  );

  return (
    <Alerts>
      <WrapperIconsAlert>
        {/* <IconAlertOxygen iconSize="" iconColor={nomadTheme.colors.neutral[180]} /> */}
        {isAbsent && (
          <IconAlertHome
            iconSize=""
            iconColor={nomadTheme.colors.neutral[180]}
          />
        )}
        {/* <IconMaintenance iconSize="" iconColor={nomadTheme.colors.neutral[180]} /> */}
      </WrapperIconsAlert>
    </Alerts>
  );
};

export default AlertesAppareilItem;
