import {
  DiagnostiqueEducatifAdapter,
  DiagnostiqueEducatifModel
} from "@ads/isadom-model";
import {
  getDefaultApiInstance,
  useApiDeleteV3,
  UseApiDeleteV3Output
} from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export interface ApiDeleteDiagnosticEducatifByVisiteIDAndIDHookInput {
  visiteID: number;
}

export function useApiDeleteDiagnosticEducatifByVisiteIDAndID({
  visiteID
}: ApiDeleteDiagnosticEducatifByVisiteIDAndIDHookInput): UseApiDeleteV3Output<DiagnostiqueEducatifModel> {
  const adapter = new DiagnostiqueEducatifAdapter();
  return useApiDeleteV3(
    getDefaultApiInstance(),
    `/visites/${visiteID}/diagnostiques-educatifs`,
    {
      errorStrategy: apiIsadomErrorStrategy,
      adaptResult: (data) => {
        return adapter.toClient(data.data);
      }
    }
  );
}
