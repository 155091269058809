import { jsx as _jsx } from "react/jsx-runtime";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { AdvancedSearchSelect, DEFAULT_SEARCH_GUARD_LENGTH, useGetAdvancedSearchProps, } from "@ads/ui";
import { apiSearchTiersV2, } from "../../../api/apiSearchTiersV2";
import { FormSelectionTiersAdapter } from "../ui/FormSelectionTiersAdapter";
import { columns } from "../ui/SelectionTiers";
import { defaultTransformSearchResultsToSelectOptions, } from "../ui/SelectionTiersV2";
export class SelectionTiersV3DefaultProps {
    static getKeyFromData(item) {
        return item.tiersID;
    }
    static transformModalFormDataToSearchParam(formData, defaultSelectedTypeTiers) {
        return {
            qui: formData.data.qui,
            tTypetiersID: defaultSelectedTypeTiers.map((value) => value.typeTiersID),
            ville_cp: formData.data.ou,
        };
    }
    static transformSelectFormDataToSearchParam(formData, defaultSelectedTypeTiers, defaultVilleCPSelected) {
        return {
            qui: formData.trim(),
            tTypetiersID: defaultSelectedTypeTiers.map((value) => value.typeTiersID),
            ville_cp: defaultVilleCPSelected,
        };
    }
}
export function useDefaultSelectionTiersV3Props({ formConfig, onTiersSelected, selectionConfig, transformSearchResultsToSelectOptions = defaultTransformSearchResultsToSelectOptions, }) {
    const api = getDefaultApiInstance();
    const defaultModalFormData = {
        config: formConfig,
        data: {
            typesTiers: formConfig.typesTiersConfig.defaultSelectedData,
            ou: formConfig.ouConfig.defaultData,
            qui: formConfig.quiConfig.defaultData,
        },
    };
    const service = useGetAdvancedSearchProps({
        hookParams: {
            api,
            defaulSelectedData: selectionConfig.defaultSelectedData,
            defaultModalFormData: defaultModalFormData,
            getKeyFromData: SelectionTiersV3DefaultProps.getKeyFromData,
            typeSelection: selectionConfig.typeSelection,
            modalSearch: apiSearchTiersV2,
            selectSearch: apiSearchTiersV2,
            transformModalFormDataToSearchParam: (formData) => {
                return {
                    qui: formData.data.qui,
                    tTypetiersID: formData.data.typesTiers.map((value) => value.typeTiersID),
                    ville_cp: formData.data.ou,
                };
            },
            transformSelectFormDataToSearchParam: (formdata) => SelectionTiersV3DefaultProps.transformSelectFormDataToSearchParam(formdata, formConfig.typesTiersConfig.defaultSelectedData, formConfig.ouConfig.defaultData),
        },
        tableSearchConfiguration: {
            columns: columns,
            formAdapter: FormSelectionTiersAdapter,
            modalTitleAdapter: "Recherche d'un tiers",
        },
        selectSearchConfiguration: {
            transformSearchResultsToSelectOptions,
            searchGuardLength: DEFAULT_SEARCH_GUARD_LENGTH,
            placeholder: "Nom ou code",
        },
        onSubmit: onTiersSelected,
    });
    return service;
}
export function SelectionTiersV3({ service }) {
    return _jsx(AdvancedSearchSelect, Object.assign({}, service), void 0);
}
