import { useState } from "react";
import { TiersModel, TypeTiersModel } from "@ads/isadom-model";
import { ISelectionTiersV2Props, SelectionTiersV2 } from "@ads/module-tiers";
import { Button, FormItemWrapper, Label } from "@ads/ui";
import { Card, Col, Row } from "antd";

export interface SelectionTiersDestinatairePEPropsData {
  typeTiers: TypeTiersModel[] | null;
}

export interface SelectionTiersDestinatairePEPropsCallbacks {
  onTiersSelected(tiers: TiersModel[]): void;
}

export interface SelectionTiersDestinatairePEProps {
  data: SelectionTiersDestinatairePEPropsData;
  callbacks: SelectionTiersDestinatairePEPropsCallbacks;
}

export function SelectionTiersDestinatairePE(
  props: SelectionTiersDestinatairePEProps
) {
  const [selectedTiers, setSelectedTiers] = useState<TiersModel[]>([]);

  const selectionProps: ISelectionTiersV2Props = {
    formConfig: {
      typesTiersConfig: {
        allowChange: true,
        defaultSelectedData: [
          {
            typeTiersID: "MED",
            libelle: "Médecin/prescripteurs",
          } as TypeTiersModel,
        ],
        typeSelection: "multiple",
        visible: true,
      },
      ouConfig: {
        allowChange: true,
        defaultData: "",
      },
      quiConfig: {
        allowChange: true,
        defaultData: "",
      },
    },
    selectionConfig: {
      typeSelection: "multiple",
      defaultSelectedData: [],
    },
    onTiersSelected: setSelectedTiers,
  };

  return (
    <Card>
      <Row>
        <Col>
          <FormItemWrapper direction="vertical">
            <Label htmlFor="">Selection de destinataires</Label>
            <SelectionTiersV2 {...selectionProps} />
          </FormItemWrapper>
        </Col>
        <Col>
          <Button
            as="button"
            buttonStyle="secondary"
            size="S"
            label="Ajouter"
            type="button"
            onClick={() => {
              props.callbacks.onTiersSelected(selectedTiers);
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}
