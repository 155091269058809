import { TiersAdapter } from "../../tiers";
import { ThemeAdapter } from "./ThemeAdapter";
var TypeQuestionnaireAdapter = /** @class */ (function () {
    function TypeQuestionnaireAdapter() {
        this.themeAdapater = new ThemeAdapter();
        this.tiersAdapter = new TiersAdapter();
    }
    TypeQuestionnaireAdapter.prototype.toClients = function (srcs) {
        var result = [];
        for (var _i = 0, srcs_1 = srcs; _i < srcs_1.length; _i++) {
            var src = srcs_1[_i];
            result.push(this.toClient(src));
        }
        return result;
    };
    TypeQuestionnaireAdapter.prototype.toAPIs = function (srcs) {
        var result = [];
        for (var _i = 0, srcs_2 = srcs; _i < srcs_2.length; _i++) {
            var src = srcs_2[_i];
            result.push(this.toAPI(src));
        }
        return result;
    };
    TypeQuestionnaireAdapter.prototype.toClient = function (src) {
        return {
            typeQuestionnaireID: src.sPKGrille,
            besoinValidation: src.bValidation,
            evalTherapeutique: src.bEvalTherapeutique,
            gereScore: src.bScore,
            iCPParGrille: src.iCPParGrille,
            inactif: src.bInactif,
            libelle: src.sLibelle,
            publication: src.bPublication,
            scoreMax: src.iTotalScore,
            calcul: src.sCalcul,
            fonctionValidationID: src.sFKFonctionValidation,
            libelleCalcul: src.sLibelleCalcul,
            themes: this.themeAdapater.toClients(src.tabTheme),
            tiersValidation: this.tiersAdapter.toClient(src.tiersValidation),
        };
    };
    TypeQuestionnaireAdapter.prototype.toAPI = function (src) {
        return {
            sPKGrille: src.typeQuestionnaireID,
            bEvalTherapeutique: src.evalTherapeutique,
            bInactif: src.inactif,
            bPublication: src.publication,
            bScore: src.gereScore,
            bValidation: src.besoinValidation,
            iCPParGrille: src.iCPParGrille,
            iTotalScore: src.scoreMax,
            sCalcul: src.calcul,
            sFKFonctionValidation: src.fonctionValidationID,
            sLibelle: src.libelle,
            sLibelleCalcul: src.libelleCalcul,
            sListesFKTypeVisite: src.typeVisites,
            tabTheme: src.themes && this.themeAdapater.toAPIs(src.themes),
            tiersValidation: src.tiersValidation && this.tiersAdapter.toAPI(src.tiersValidation)
        };
    };
    return TypeQuestionnaireAdapter;
}());
export { TypeQuestionnaireAdapter };
