import styled from "styled-components";
import iconMobile from "../../../../assets/icons/icon-mobile.svg";

const TextMobile = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;
  line-height: 2.2rem;
  font-family: ${({ theme }) => theme.font.type.primaryMedium};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.quinary[100]};

  &::before {
    content: "";
    display: block;
    width: 1.6rem;
    height: 2.2rem;
    background: url(${iconMobile}) 0 50% no-repeat transparent;
  }
`;

export default TextMobile;
