import { FicheSuiviModel } from "@ads/isadom-model";
import { Formulaire } from "@ads/module-formulaire";
import { adsNotification, Checkbox, FormWidget } from "@ads/ui";
import TitleMain from "components/ui/atoms/titles/TitleMain";
import {
  ILoadingsProps,
  withError,
  withLoading,
} from "modules/fiche-suivi/common";
import { updateFicheSuiviByVisiteID } from "modules/fiche-suivi/feature/updateFicheSuiviByVisiteID";
import { useEffect } from "react";
import nomadTheme from "utils/nomad-theme";
import { useFicheSuivi } from "../hook/useFicheSuivi";

interface FicheSuiviPropsCallbacks {
  callbacks?: {
    onChange?(data: FicheSuiviModel): Promise<FicheSuiviModel>;
  };
}

export type FicheSuiviProps = {
  data: FicheSuiviModel;
  visiteID: number;
} & ILoadingsProps &
  FicheSuiviPropsCallbacks;

const FicheSuivi = (props: FicheSuiviProps) => {
  const service = useFicheSuivi({
    data: {
      ficheSuivi: {...props.data},
    },
    callbacks: {
      async onChange(fs) {
        if (props.callbacks?.onChange) {
          return props.callbacks.onChange(fs);
        }
        return fs;
      },
    },
    features: {
      async updateFicheSuivi(fs) {
        const newFS = fs;
        if (newFS) {
          newFS.parametresEditions =
            service.parametreEditionService.servicePEList.data;
        }
        const result = updateFicheSuiviByVisiteID(props.visiteID, newFS);
        result.then(() => {
          adsNotification.success({
            message: "Formulaire sauvegardé avec succès.",
            duration: nomadTheme.duration.notification,
          });
        });
        return result;
      },
    },
  });

  useEffect(() => {
    service.formulaireService.features.updateFormulaire(props.data.formulaire)
  }, [props.data])

  return (
    <>
      <FormWidget
        handleSave={() =>
          service.features.updateFicheSuivi(service.features.getFicheSuivi())
        }
      >
        <div>
          <TitleMain>Formulaire de la fiche</TitleMain>
          <br />
          <Checkbox
            libelle="Intégrer la fiche de suivi à la visite."
            checked={service.features.getFicheSuivi().bIntegrationFormulaire}
            onChange={(e: any) => {
              service.actions.notifyFicheSuiviChange({
                ...service.features.getFicheSuivi(),
                bIntegrationFormulaire: e.target.checked,
              });
            }}
          />
          <br />
          <>
            <Formulaire
              data={service.formulaireService.data}
              callbacks={{
                onChange: undefined,
              }}
            />
          </>
        </div>
      </FormWidget>
    </>
  );
}

const FicheSuiviWithLoading = withLoading(FicheSuivi);
export const FicheSuiviWithLoadingWithError = withError(FicheSuiviWithLoading);
