import { CureModel, CureAdapter } from "@ads/isadom-model";
import { apiSearch, getDefaultApiInstance } from "@ads/react-common-module";
export interface CureSearchParams {
  dDebut?: string;
  dFin?: string;
  tiersID: number;
  prestationID?: number;
  prescriptionID?: number;
}

export function apiGetCuresByTiersID(
  searchParams: CureSearchParams
): Promise<CureModel[]> {
  const api = getDefaultApiInstance();
  if (api) {
    const adapter = new CureAdapter();
    return apiSearch(api, `cures/recherche`, adapter, searchParams) as Promise<
      CureModel[]
    >;
  }
  return new Promise((_resolve, reject) => {
    reject("La configuration d'api n'est pas initialisée");
  });
}
