import {
  Annulation,
  Btn,
  HeaderCard,
  DateDebut,
  DateFin,
  Information,
  Status,
  Validation,
} from "../../atoms/itemFields";

export const EvenementRGPDListHeader = () => (
  <HeaderCard>
    <Status />
    <DateDebut>Du</DateDebut>
    <DateFin>au</DateFin>
    <Information>Informations</Information>
    <Validation>Validé</Validation>
    <Annulation>Annulé</Annulation>
    <Btn />
  </HeaderCard>
);
