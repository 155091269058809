var TypeTacheModel = /** @class */ (function () {
    function TypeTacheModel(id, libelle, reference, isReserve, isInactif) {
        this.id = id;
        this.libelle = libelle;
        this.reference = reference;
        this.isReserve = isReserve;
        this.isInactif = isInactif;
    }
    return TypeTacheModel;
}());
export { TypeTacheModel };
