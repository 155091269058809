var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Breadcrumb, FormWidget, theme } from "@ads/ui";
import { useApi } from "@ads/react-common-module";
import { notification } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { MessageForm } from "../organisms/MessageForm";
import { Content } from "../atoms/Content";
import { apiSaveMessage } from "../../api/apiSaveMessage";
import errorAdapter from "../../api/errorAdapter";
export function MessageCreationTemplate({ visiteID, intervenantID, selectionDestinataire }) {
    // @ts-ignore
    const { iPKVisite } = useParams();
    const [messageData, setMessageData] = useState();
    const api = useApi();
    const history = useHistory();
    const breadcrumbItems = [
        {
            item: "Messages",
            to: `/visites/${iPKVisite}/messages`,
        },
        {
            item: `Nouveau message`,
            to: "",
        },
    ];
    const handleSaveMessage = (messageFormData) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            const payload = Object.assign(Object.assign({}, messageFormData), { typeObjet: {
                    ID: messageFormData.typeObjetID,
                    libelle: "",
                    corpsMessageParDefaut: "",
                }, destinatairesPrincipaux: messageFormData.destinataires.map((destinataire) => ({
                    tiersID: destinataire.value,
                })) });
            yield apiSaveMessage(api, visiteID, Object.assign(Object.assign({}, payload), { ID: 0 }));
            notification.success({
                message: "Succès",
                description: "Votre message a bien été sauvegardé.",
                duration: theme.duration.notification,
            });
            history.push(`/visites/${visiteID}/messages`);
        }
        catch (error) {
            (_a = errorAdapter(error)) === null || _a === void 0 ? void 0 : _a.forEach((e, index) => {
                notification.error({
                    key: `erreur-${index}`,
                    message: "Erreur",
                    description: e.message || "Erreur interne du serveur",
                });
            });
        }
    });
    return (_jsxs(FormWidget, Object.assign({ handleSave: () => handleSaveMessage(messageData), handleCancel: () => history.push(`/visites/${visiteID}/messages`) }, { children: [_jsx(Breadcrumb, { size: "M", breadcrumbItems: breadcrumbItems }, void 0), _jsx(Content, { children: _jsx(MessageForm, { intervenantID: intervenantID, setMessageData: setMessageData, selectionDestinataire: selectionDestinataire }, void 0) }, void 0)] }), void 0));
}
