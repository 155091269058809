import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
const Details = styled.div `
  grid-area: details;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 0 1.7rem 1.7rem;

  ${({ theme }) => theme.media.sm} {
    padding: 1.7rem 0;
  }

  ${({ theme }) => theme.media.lg} {
    padding: 0;
  }
`;
const ConsommableConfigInfos = styled.div `
  font-size: 1.5rem;
  line-height: 2.1rem;

  ${({ theme }) => theme.media.lg} {
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;
const ConsommableNom = styled.div `
  font-weight: 700;
  display: block;

  ${({ theme }) => theme.media.lg} {
    display: inline-block;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }
`;
const LastDelivery = styled.div `
  margin: 1rem 0 0;

  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    font-size: 1.5rem;
  }
`;
const LastDeliveryInformation = styled.div `
  padding: 0 0.5rem;
`;
export const ConsommableDetail = ({ configConsoSpecTiersVisite, }) => (_jsxs(Details, { children: [_jsx(ConsommableNom, { children: `${configConsoSpecTiersVisite.produit.sLibelle} - ${configConsoSpecTiersVisite.produit.sRefProduit}` }, void 0), _jsxs(ConsommableConfigInfos, { children: [configConsoSpecTiersVisite.iQuantite ? (_jsxs(_Fragment, { children: ["Fr\u00E9quence : ", _jsx("strong", { children: configConsoSpecTiersVisite.iQuantite }, void 0), " ", "tous les ", _jsx("strong", { children: configConsoSpecTiersVisite.iFrequence }, void 0), " jour", configConsoSpecTiersVisite.iFrequence > 1 && `s`] }, void 0)) : null, configConsoSpecTiersVisite.iQuantiteDerniereLivraison ? (_jsxs(LastDelivery, { children: ["Derni\u00E8re livraison :", " ", _jsxs(LastDeliveryInformation, { children: [_jsx("strong", { children: ` ${configConsoSpecTiersVisite.iQuantiteDerniereLivraison}` }, void 0), " ", "le", " ", _jsx("strong", { children: new Intl.DateTimeFormat("fr-FR").format(new Date(configConsoSpecTiersVisite.dDerniereLivraison)) }, void 0)] }, void 0)] }, void 0)) : null] }, void 0)] }, void 0));
