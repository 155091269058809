import styled from "styled-components";

const TitleMain = styled.h1`
  padding: 0 0 0 1rem;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.8rem;

  ${({ theme }) => theme.media.sm} {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 2.2rem;
  }
`;

export default TitleMain;
