var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../../_adapter";
var ConfigAppSpecTiersVisiteDTOAdapter = /** @class */ (function (_super) {
    __extends(ConfigAppSpecTiersVisiteDTOAdapter, _super);
    function ConfigAppSpecTiersVisiteDTOAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ConfigAppSpecTiersVisiteDTOAdapter.prototype.toClient = function (src) {
        return {
            appareilID: src.appareilID,
            affectationID: src.affectationID,
            bAbsent: src.bAbsent,
            bAjout: src.bAjout,
            bPresent: src.bPresent,
            bRetrait: src.bRetrait,
            dateInstallation: src.dInstallation,
            referenceAppareil: src.sReferenceAppareil,
            libelleAffectation: src.sLibelleAffectation,
            libelleModele: src.sLibelleModele,
            libellePreponderance: src.sLibellePreponderance,
            libelleTypePrestation: src.sLibelleTypePrestation,
            localisationActuelle: src.sLocalisationActuelle,
            localisationID: src.localisationID,
            modeleID: src.modeleID,
            natureLocalisation: src.natureLocalisation,
            numeroSerie: src.sNumeroSerie,
            preponderance: src.sPreponderance,
            referenceModele: src.sReferenceModele,
            typePrestationID: src.typePrestationID,
        };
    };
    ConfigAppSpecTiersVisiteDTOAdapter.prototype.toAPI = function (src) {
        return {
            appareilID: src.appareilID,
            affectationID: src.affectationID,
            bAbsent: src.bAbsent,
            bAjout: src.bAjout,
            bPresent: src.bPresent,
            bRetrait: src.bRetrait,
            dInstallation: src.dateInstallation,
            sReferenceAppareil: src.referenceAppareil,
            sLibelleAffectation: src.libelleAffectation,
            sLibelleModele: src.libelleModele,
            sLibellePreponderance: src.libellePreponderance,
            sLibelleTypePrestation: src.libelleTypePrestation,
            sLocalisationActuelle: src.localisationActuelle,
            localisationID: src.localisationID,
            modeleID: src.modeleID,
            natureLocalisation: src.natureLocalisation,
            sNumeroSerie: src.numeroSerie,
            sPreponderance: src.preponderance,
            sReferenceModele: src.referenceModele,
            typePrestationID: src.typePrestationID,
        };
    };
    return ConfigAppSpecTiersVisiteDTOAdapter;
}(Adapter));
export { ConfigAppSpecTiersVisiteDTOAdapter };
