var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../..";
import { IntParametrageIDAdapter } from "../params/IntParametrageIDAdapter";
import { TypeContrainteAdapter } from './TypeContrainteAdapter';
var AbstractContrainteAdapter = /** @class */ (function (_super) {
    __extends(AbstractContrainteAdapter, _super);
    function AbstractContrainteAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.typeContrainteAdapter = new TypeContrainteAdapter();
        _this.intParamAdapter = new IntParametrageIDAdapter();
        return _this;
    }
    AbstractContrainteAdapter.prototype.toClient = function (src) {
        return {
            id: src.id.iID.toString(),
            module: src.sModule,
            typeContrainte: this.typeContrainteAdapter.toClient(src.typeContrainte),
            categorie: src.sCategorie,
            version: src.id.iVersion,
        };
    };
    AbstractContrainteAdapter.prototype.toAPI = function (src) {
        var idObjet = {
            id: parseInt(src.id),
            version: src.version,
        };
        return {
            id: this.intParamAdapter.toAPI(idObjet),
            sModule: src.module,
            sCategorie: src.categorie,
            typeContrainte: this.typeContrainteAdapter.toAPI(src.typeContrainte),
        };
    };
    return AbstractContrainteAdapter;
}(Adapter));
export { AbstractContrainteAdapter };
