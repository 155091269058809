import {
  IntervenantModel,
  VisiteAdapter,
  VisiteModel
} from "@ads/isadom-model";
import { useInfoRdvVisite } from "../../InforRdvVisite/hook/useInfoRdvVisite";
import { useSelectTiersVisite } from "../../SelectTiersVisite/hook/useSelectTiersVisite";
import {
  filterForPrelevementDM,
  filterForPrelevementDMC,
  useSelectLocalisationByIntervenantVisite
} from "../../SelectLocalisation/hook/useSelectLocalisationByIntervenantVisite";
import { useSelectTypeVisiteTypePrestaVisite } from "../../SelectTypeVisiteTypePrestaVisite/hook/useSelectTypeVisiteTypePrestaVisite";
import { IFormModifierVisiteHookInput } from "./interfaces/IFormModifierVisiteHookInput";
import {
  IFormModifierVisiteFeature,
  IFormModifierVisiteHookService
} from "./interfaces/IFormModifierVisiteHookService";
import { ETypeTiersID } from "@ads/module-tiers";
import { useState } from "react";
import { adsNotification } from "@ads/ui";
import { useHistory } from "react-router-dom";

export function useFormModifierVisite({
  visite,
  postDataFeature
}: IFormModifierVisiteHookInput): IFormModifierVisiteHookService {
  const [informationVisite, setInformationVisite] = useState<string>(
    visite?.sInformation ? visite?.sInformation : ""
  );

  const serviceTypeVisiteTypePresta = useSelectTypeVisiteTypePrestaVisite({
    intervenantID: visite ? visite.intervenantPrincipal.tiersID : null,
    readOnliTypesVisitesTypesPresta: visite ? visite.tTypeVisite : undefined
  });

  const serviceLocalisationPrelevementDM =
    useSelectLocalisationByIntervenantVisite({
      defaultIntervenant: visite ? visite.intervenantPrincipal : null,
      isDefault: filterForPrelevementDM,
      defaultSelectedData: visite
        ? visite.intervenantPrincipal.localisationAutorisees.filter(
            (loc) => loc.localisationId === visite.iFKLocalisationPreleveDM
          )
        : undefined
    });

  const serviceLocalisationPrelevementDMC =
    useSelectLocalisationByIntervenantVisite({
      defaultIntervenant: visite ? visite.intervenantPrincipal : null,
      isDefault: filterForPrelevementDMC,
      defaultSelectedData: visite
        ? visite.intervenantPrincipal.localisationAutorisees.filter(
            (loc) => loc.localisationId === visite.iFKLocalisationPreleveDMC
          )
        : undefined
    });

  const serviceInfoVisite = useInfoRdvVisite({
    rdvOrVisite: visite ? visite : undefined
  });

  const serviceInfoRdv = useInfoRdvVisite({
    rdvOrVisite: visite ? visite.Rdv : undefined
  });

  const serviceSelectIntervenantSecondaire = useSelectTiersVisite({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onTiersSelected(tiers) {},
    defaultTypeTiersID: ETypeTiersID.INTERVENANT,
    defaultTiersSelected: visite?.tIntervenantsAutre[0]
  });

  const history = useHistory();

  const features: IFormModifierVisiteFeature = {
    setInformationVisite: setInformationVisite,
    updateVisite: () => {
      if (visite) {
        const visiteState: VisiteModel = {
          ...visite,
          sInformation: informationVisite
            ? informationVisite
            : visite.sInformation,
          tIntervenantsAutre: serviceSelectIntervenantSecondaire
            .serviceSearchSelectTiers.selectService
            .selectedData as IntervenantModel[],

          sNatureLocalisationPreleveDM:
            serviceLocalisationPrelevementDM.selectedData[0].nature,
          iFKLocalisationPreleveDM:
            serviceLocalisationPrelevementDM.selectedData[0].localisationId,
          sNatureLocalisationPreleveDMC:
            serviceLocalisationPrelevementDMC.selectedData[0].nature,
          iFKLocalisationPreleveDMC:
            serviceLocalisationPrelevementDMC.selectedData[0].localisationId,
          tTypeVisite:
            serviceTypeVisiteTypePresta.serviceTypeVisiteTypePrestaList
              .typesVisites
        };
        const adapterVisite = new VisiteAdapter();

        postDataFeature(adapterVisite.toAPI(visiteState)).then(async () => {
          adsNotification.success({
            message: "Modification de la visite sauvegardée"
          });

          history.push(`/visites/${visiteState.ID}`);
        });
      }
    }
  };

  return {
    serviceTypeVisiteTypePresta,
    serviceLocalisationPrelevementDM,
    serviceLocalisationPrelevementDMC,
    serviceInfoVisite,
    serviceInfoRdv,
    serviceSelectIntervenantSecondaire,
    infoVisite: informationVisite,
    features
  };
}
