import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { v4 } from "uuid";
import { IconSexeFeminin, IconSexeMasculin, Loading } from "@ads/ui";
import { TableAppareil } from "../molecules/TableAppareil";
import { TableConsommable } from "../molecules/TableConsommable";
import { TagPrescriptions } from "../molecules/TagPrescriptions";
import { TagPrestations } from "../molecules/TagPrestations";
import { AdressesPatient, ContentFichePatient, DateNaissance, InformationsPatient, Nomination, Separateur, } from "../atoms/FichePatientElements";
import { AlerteIcone } from "../molecules/AlerteIcone";
import { AlerteMessage } from "../molecules/AlerteMessage";
import { Alert } from "antd";
const calculeAge = (dateNaissance) => {
    const birthday = new Date(dateNaissance);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};
export function FichePatient(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (props.isLoading && props.patient === undefined) {
        return _jsx(Loading, { children: "Chargement en cours de la fiche patient ..." }, void 0);
    }
    else if (props.patient !== undefined) {
        const tiers = (_a = props.patient) === null || _a === void 0 ? void 0 : _a.tiers;
        const nom = tiers === null || tiers === void 0 ? void 0 : tiers.nom.toUpperCase();
        const appareils = (_b = props.patient) === null || _b === void 0 ? void 0 : _b.appareil.map((appareil) => ({
            key: v4(),
            libelle: appareil.libelleModele,
            preponderance: appareil.preponderance && appareil.preponderance[0],
            dateInstallation: appareil.dInstallation,
        }));
        const consommables = (_c = props.patient) === null || _c === void 0 ? void 0 : _c.consommable.map((consommable) => ({
            key: v4(),
            libelle: consommable.produit.sLibelle,
            quantite: consommable.iQuantite,
            frequence: consommable.iFrequence,
        }));
        return (_jsxs(_Fragment, { children: [_jsxs(InformationsPatient, { children: [_jsxs(Nomination, { children: [(_d = tiers.civilite) === null || _d === void 0 ? void 0 : _d.code, " ", nom, " ", tiers.prenom, " "] }, void 0), " ", "(", tiers.codeTiers, ")", " ", tiers.sexe === "M" && (_jsx(IconSexeMasculin, { iconSize: "2rem", iconColor: "blue" }, void 0)), tiers.sexe === "F" && (_jsx(IconSexeFeminin, { iconSize: "2rem", iconColor: "pink" }, void 0)), _jsxs(DateNaissance, { children: [tiers.dateNaissance &&
                                    tiers.dateNaissance !== "0000-00-00" &&
                                    `Né le ${new Intl.DateTimeFormat("fr-FR").format(new Date(tiers.dateNaissance))} (${calculeAge(tiers.dateNaissance)} ans)`, _jsx(AlerteIcone, { alertes: (_e = props.patient) === null || _e === void 0 ? void 0 : _e.alerte }, void 0)] }, void 0), _jsx("div", { children: tiers.mail }, void 0)] }, void 0), _jsxs(ContentFichePatient, { children: [_jsx(AlerteMessage, { alertes: (_f = props.patient) === null || _f === void 0 ? void 0 : _f.alerte }, void 0), _jsxs(AdressesPatient, { children: [_jsx("div", { children: (_g = tiers.adressePrincipale) === null || _g === void 0 ? void 0 : _g.adresseComplete }, void 0), _jsxs("div", { children: ["Fixe : ", (_h = tiers.adressePrincipale) === null || _h === void 0 ? void 0 : _h.telFixe] }, void 0), _jsxs("div", { children: ["Mobile : ", tiers.telgsm] }, void 0)] }, void 0), _jsx(Separateur, {}, void 0), _jsx(TagPrescriptions, { prescriptions: (_j = props.patient) === null || _j === void 0 ? void 0 : _j.prescription }, void 0), _jsx(TagPrestations, { prestations: (_k = props.patient) === null || _k === void 0 ? void 0 : _k.prestation }, void 0), _jsx(TableAppareil, { data: appareils }, void 0), _jsx(TableConsommable, { data: consommables }, void 0)] }, void 0)] }, void 0));
    }
    else {
        return _jsx(Alert, { children: "Une erreur est survenue, veuillez essayer \u00E0 nouveau" }, void 0);
    }
}
