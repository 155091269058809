var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Adapter } from "../../_adapter";
import { TypeObjetAdapter } from ".";
var MessageAdapter = /** @class */ (function (_super) {
    __extends(MessageAdapter, _super);
    function MessageAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MessageAdapter.prototype.toClient = function (src) {
        return {
            ID: src.messageID,
            typeObjet: new TypeObjetAdapter().toClient(src.typeObjet),
            objet: src.sObjet,
            corps: src.sMessage,
            destinatairesPrincipaux: src.tDestinatairePrincipal,
        };
    };
    MessageAdapter.prototype.toAPI = function (src) {
        return {
            messageID: src.ID,
            typeObjet: new TypeObjetAdapter().toAPI(src.typeObjet),
            sObjet: src.objet,
            sMessage: src.corps,
            tDestinatairePrincipal: src.destinatairesPrincipaux,
        };
    };
    return MessageAdapter;
}(Adapter));
export { MessageAdapter };
