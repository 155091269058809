import { useHistory } from "react-router-dom";
import { useQuestionnaireListVisite } from ".";
import { useGetQuestionnaireSupplementaireByVisiteID, } from "../../..";
export function useQuestionnaireSupplementaireListVisite(visiteID, tiersVisiteID) {
    const history = useHistory();
    const service = useQuestionnaireListVisite(visiteID, tiersVisiteID, useGetQuestionnaireSupplementaireByVisiteID, true);
    const optionHeader = {
        add: true,
        onAddButtonClickCallback: () => {
            const url = window.location.href.split("/");
            history.push(url.pop() + "/" + "nouveau");
        },
        title: service.questionnaires && service.questionnaires.length > 0
            ? service.questionnaires.length > 1
                ? service.questionnaires.length + " questionnaires supplémentaires "
                : "1 questionnaire supplémentaire"
            : "Aucun questionnaire supplémentaire",
    };
    return {
        optionsList: optionHeader,
        questionnairesLoading: service.questionnairesLoading,
        fetchError: service.fetchError,
        questionnaires: service.questionnaires,
        filter: service.filter,
        createQuestionnaire: service.createQuestionnaire,
        createQuestionnaireFromType: service.createQuestionnaireFromType,
        addFiltersOptions: service.addFiltersOptions,
        setBaseQuestionnaires: service.setBaseQuestionnaires,
    };
}
