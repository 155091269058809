import { TypeDocumentAdapter } from '../common/interfaces/adapter/TypeDocumentAdapter';
import { HttpResponseAdapter } from "@ads/isadom-model";
import { apiGet } from "@ads/react-common-module";
export function apiGetTypesDocuments(api) {
    if (api) {
        const adapter = new HttpResponseAdapter(new TypeDocumentAdapter());
        return apiGet(api, `/types-documents`, adapter);
    }
    else {
        throw new Error("La configuration d'api est null");
    }
}
