import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Identite, Params } from "../../../../interface/utilisateur";
import {
  connexion,
  reconnexion,
  deconnexion,
  chargeParams
} from "../../services/utilisateur";

interface AuthState {
  isLoading: boolean;
  bConnecte: boolean;
  bReconnexion: boolean;
  identite: Identite | undefined;
  params: Params | undefined;
  sErreur: any;
  token: string | undefined;
}

interface ReconnexionPayload {
  reconnexion: boolean;
}

const initialState: AuthState = {
  isLoading: false,
  bConnecte: false,
  bReconnexion: false,
  identite: undefined,
  params: undefined,
  sErreur: undefined,
  token: undefined
};

export const utilisateurSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reconnexionStatus: (
      state: AuthState,
      { payload }: PayloadAction<ReconnexionPayload>
    ) => {
      const { reconnexion } = payload;
      state.bReconnexion = reconnexion;
    }
  },
  extraReducers: (builder) => {
    // Connexion (via le formulaire)
    builder.addCase(connexion.pending, (state) => {
      state.isLoading = true;
      state.sErreur = undefined;
      state.bConnecte = false;
    });
    builder.addCase(connexion.fulfilled, (state, action) => {
      state.identite = action.payload.identity;
      state.isLoading = false;
      state.bConnecte = true;
      state.sErreur = undefined;
    });
    builder.addCase(connexion.rejected, (state) => {
      state.identite = undefined;
      state.isLoading = false;
      state.bConnecte = false;
      state.sErreur = "Ooopsie!";
    });

    // Reconnexion si rechargement de l'application
    builder.addCase(reconnexion.pending, (state) => {
      state.isLoading = true;
      state.bConnecte = false;
      state.sErreur = undefined;
    });
    builder.addCase(reconnexion.fulfilled, (state, action) => {
      state.identite = action.payload.identity;
      state.isLoading = false;
      state.bConnecte = true;
      state.sErreur = undefined;
    });
    builder.addCase(reconnexion.rejected, (state) => {
      state.identite = undefined;
      state.isLoading = false;
      state.bConnecte = false;
      state.sErreur = "Ooopsie!";
    });

    // Déconnexion via le bouton dans l'entête
    builder.addCase(deconnexion.pending, (state) => {
      state.identite = undefined;
      state.params = undefined;
      state.isLoading = true;
      state.bConnecte = false;
      state.sErreur = undefined;
    });
    builder.addCase(deconnexion.fulfilled, (state) => {
      state.identite = undefined;
      state.params = undefined;
      state.isLoading = false;
      state.bConnecte = false;
      state.sErreur = undefined;
    });
    builder.addCase(deconnexion.rejected, (state) => {
      state.identite = undefined;
      state.params = undefined;
      state.isLoading = false;
      state.bConnecte = false;
      state.sErreur = "Ooopsie!";
    });

    // Chargement des paramètres de l'utilisateur
    builder.addCase(chargeParams.pending, (state) => {
      state.isLoading = true;
      state.params = undefined;
      state.sErreur = undefined;
    });
    builder.addCase(chargeParams.fulfilled, (state, { payload: params }) => {
      state.params = params;
      state.isLoading = false;
      state.sErreur = undefined;
    });
    builder.addCase(chargeParams.rejected, (state) => {
      state.identite = undefined;
      state.params = undefined;
      state.isLoading = false;
      state.bConnecte = false;
      state.sErreur = "Ooopsie!";
    });
  }
});

export const { reconnexionStatus } = utilisateurSlice.actions;

export default utilisateurSlice.reducer;
