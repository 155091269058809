import { Module } from '@ads/react-common-module';
export * from "./ui";
export * from "./api";
export * from "./hooks";
export * from "./features";
export const QUESTIONNAIRE_BASE_PATH = "questionnaires";
export const QUESTIONNAIRE_DETAIL_PATH = "questionnaires/:questionnaireID";
export const MODULE_NAME = "moduleQuestionnaire";
export class QuestionnaireModule extends Module {
    constructor() {
        super(MODULE_NAME, [], []);
    }
}
