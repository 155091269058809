var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb, FormWidget } from "@ads/ui";
import { getDefaultApiInstance } from "@ads/react-common-module";
import { ConsommbaleConfigurationDetailWrapper } from "../atoms/ConsommbaleConfigurationDetailWrapper";
import { ConsommableConfigurationDetailZone } from "../atoms/ConsommableConfigurationDetailZone";
import { ConsommableConfigurationDetailInfo } from "../atoms/ConsommableConfigurationDetailInfo";
import { ConsommableConfigurationDetailInfoTitle } from "../atoms/ConsommableConfigurationDetailIInfoTitle";
import { ConfigurationConsommableForm } from "./ConfigurationConsommableForm";
import { editeConfigurationConsommable, ajouteConfigurationConsommable, } from "../../features";
export const ConsommableConfigurationDetail = ({ visiteID, configConsoSpecTiersVisite, ajoute, }) => {
    var _a;
    const history = useHistory();
    const [isSavingForm, setIsSavingForm] = useState(false);
    const api = getDefaultApiInstance();
    const initFormValues = {
        iFrequence: configConsoSpecTiersVisite.iFrequence || 0,
        iQuantite: configConsoSpecTiersVisite.iQuantite || 0,
        bAfficherCR: configConsoSpecTiersVisite.bAfficherCR || false,
        bAfficherGED: configConsoSpecTiersVisite.bAfficherGED || false,
        bAfficherOrdo: configConsoSpecTiersVisite.bAfficherOrdo || false,
        bPreparation: configConsoSpecTiersVisite.bPreparation || false,
        bTest: configConsoSpecTiersVisite.bTest || false,
        sPKTypePrestation: ((_a = configConsoSpecTiersVisite === null || configConsoSpecTiersVisite === void 0 ? void 0 : configConsoSpecTiersVisite.typePrestation) === null || _a === void 0 ? void 0 : _a.id) || "",
        sInformation: (configConsoSpecTiersVisite === null || configConsoSpecTiersVisite === void 0 ? void 0 : configConsoSpecTiersVisite.sInformation) || "",
    };
    const [formulaireConsommableValues, setFormulaireConsommableValues] = useState(initFormValues);
    const breadcrumbItems = [
        {
            item: "Consommables",
            to: `/visites/${visiteID}/consommables`,
        },
        {
            item: configConsoSpecTiersVisite.produit.sLibelle,
            to: "",
        },
    ];
    const handleSaveConsommable = (consommableData) => __awaiter(void 0, void 0, void 0, function* () {
        if (!consommableData)
            return;
        setIsSavingForm(true);
        const configConsoSpecTiersVisiteModel = Object.assign(Object.assign({}, configConsoSpecTiersVisite), { iFrequence: consommableData.iFrequence, iQuantite: consommableData.iQuantite, bAfficherCR: consommableData.bAfficherCR, bAfficherGED: consommableData.bAfficherGED, bAfficherOrdo: consommableData.bAfficherOrdo, bPreparation: consommableData.bPreparation, bTest: consommableData.bTest, sInformation: consommableData.sInformation, typePrestation: {
                id: consommableData.sPKTypePrestation,
            } });
        if (ajoute) {
            const body = Object.assign(Object.assign({}, configConsoSpecTiersVisiteModel), { configCsoVisiteID: 0 });
            const isSuccess = yield ajouteConfigurationConsommable({
                api,
                visiteID,
                configConsoSpecTiersVisiteModel: body,
            });
            setIsSavingForm(false);
            if (isSuccess) {
                history.push(`/visites/${visiteID}/consommables/configuration`);
            }
        }
        else {
            const isSuccess = yield editeConfigurationConsommable({
                api,
                visiteID,
                configurationConsommable: configConsoSpecTiersVisiteModel,
            });
            setIsSavingForm(false);
            if (isSuccess) {
                history.push(`/visites/${visiteID}/consommables/configuration`);
            }
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(Breadcrumb, { breadcrumbItems: breadcrumbItems, size: "M" }, void 0), _jsx(FormWidget, Object.assign({ isLoadingSave: isSavingForm, handleSave: () => handleSaveConsommable(formulaireConsommableValues) }, { children: _jsxs(ConsommbaleConfigurationDetailWrapper, { children: [_jsxs(ConsommableConfigurationDetailZone, { children: [_jsxs(ConsommableConfigurationDetailInfo, { children: [_jsx(ConsommableConfigurationDetailInfoTitle, { children: "Libell\u00E9" }, void 0), configConsoSpecTiersVisite.produit.sLibelle] }, void 0), _jsxs(ConsommableConfigurationDetailInfo, { children: [_jsx(ConsommableConfigurationDetailInfoTitle, { children: "R\u00E9f\u00E9rence" }, void 0), configConsoSpecTiersVisite.produit.sRefProduit] }, void 0)] }, void 0), formulaireConsommableValues && (_jsx(ConfigurationConsommableForm, { formulaireConsommableValues: formulaireConsommableValues, configConsoSpecTiersVisite: configConsoSpecTiersVisite, setFormulaireConsommableValues: setFormulaireConsommableValues }, void 0)), configConsoSpecTiersVisite.iQuantiteDerniereLivraison > 0 && (_jsx(ConsommableConfigurationDetailZone, { children: _jsxs(ConsommableConfigurationDetailInfo, { children: [_jsx(ConsommableConfigurationDetailInfoTitle, { children: "Derni\u00E8re livraison" }, void 0), " ", configConsoSpecTiersVisite.iQuantiteDerniereLivraison, " le", " ", new Intl.DateTimeFormat("fr-FR").format(new Date(configConsoSpecTiersVisite.dDerniereLivraison))] }, void 0) }, void 0))] }, void 0) }), void 0)] }, void 0));
};
