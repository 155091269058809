import { motion } from "framer-motion";
import styled from "styled-components";

const WrapperMainContent = styled(motion.div)<{ ref?: any }>`
  position: relative;
  padding: 5rem 7rem 2rem 3rem;

  &.no-nav {
    margin-top: 0;
    padding-top: 2rem;
  }

  ${({ theme }) => theme.media.sm} {
    padding: 8rem 7rem 8rem 3rem;
    &.no-nav {
      margin-top: 0;
      padding-top: 3rem;
    }
  }

  transition-property: padding-left, padding-right, margin-right;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);

  ${({ theme }) => theme.media.xl} {
    max-width: calc(100vw - 8rem);
    padding: 5rem 3rem 2rem;
    margin: 0 auto;
    &.isOpenLeft {
      padding-left: 50rem;
    }
    &.isOpenRight {
      padding-right: 50rem;
    }
  }

  .is-sticky.has-header & {
    padding-top: 32.8rem;
  }
`;

export default WrapperMainContent;
