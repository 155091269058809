import { HeaderList } from "components/core/ui/headerList/ui/HeaderListNomad";
import { LoadingView } from "components-v2/loading/LoadingView";
import { useHistory } from "react-router-dom";

export interface ProgrammeEducatifFetchLoadingViewProps {
  visiteID: number;
}
export function ProgrammeEducatifFetchLoadingView({
  visiteID
}: ProgrammeEducatifFetchLoadingViewProps) {
  const history = useHistory();
  return (
    <>
      <HeaderList
        handleAddClick={() => {
          history.push(
            `/visites/${visiteID}/programmes/programmes-educatifs/create`
          );
        }}
        nomModuleSingulier={"Programme éducatif"}
        nomModulePluriel={"Programmes éducatifs"}
        lenghtList={0}
        filtersList={[
          {
            libelle: "Filtrer par type de programme éducatif",
            items: []
          }
        ]}
      />
      <br />
      <LoadingView libelle="des programmes éducatifs" />
    </>
  );
}
