import { Alert } from "antd";
import { FC } from "react";
import TitleMain from "../atoms/titles/TitleMain";
import WrapperError from "../organisms/wrappers/WrapperError";

interface Props {
  type: number;
  retry?: () => void;
}

const TemplateErreur: FC<Props> = ({ type, retry }: Props): JSX.Element => (
  <>
    <TitleMain>Erreur {type}</TitleMain>
    <WrapperError>
      <Alert
        message="La page que vous recherchez n'existe pas"
        type="warning"
        showIcon
      />
    </WrapperError>
    {retry && (
      <p>
        <button onClick={retry}>Tenter à nouveau</button>
      </p>
    )}
  </>
);

export default TemplateErreur;
