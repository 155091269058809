import { ReponseAdapter } from "./ReponseAdapter";
var QuestionAdapter = /** @class */ (function () {
    function QuestionAdapter() {
        this.reponseAdapater = new ReponseAdapter();
    }
    QuestionAdapter.prototype.toClients = function (srcs) {
        var result = [];
        for (var _i = 0, srcs_1 = srcs; _i < srcs_1.length; _i++) {
            var src = srcs_1[_i];
            result.push(this.toClient(src));
        }
        return result;
    };
    QuestionAdapter.prototype.toAPIs = function (srcs) {
        var result = [];
        for (var _i = 0, srcs_2 = srcs; _i < srcs_2.length; _i++) {
            var src = srcs_2[_i];
            result.push(this.toAPI(src));
        }
        return result;
    };
    QuestionAdapter.prototype.toClient = function (src) {
        return {
            questionID: src.iPKQuestionnaireDetail,
            comptabilise: src.bComptabilise,
            itemPrioritaire: src.bItemPrioritaire,
            libelle: src.sLibelle,
            ordre: src.iOrdre,
            reponseSaisie: this.reponseAdapater.toClient(src.reponseSaisie),
            reponsesPossibles: this.reponseAdapater.toClients(src.tabReponsePossible)
        };
    };
    QuestionAdapter.prototype.toAPI = function (src) {
        return {
            iPKQuestionnaireDetail: src.questionID,
            bComptabilise: src.comptabilise,
            bItemPrioritaire: src.itemPrioritaire,
            iOrdre: src.ordre,
            sLibelle: src.libelle,
            reponseSaisie: this.reponseAdapater.toAPI(src.reponseSaisie),
            tabReponsePossible: this.reponseAdapater.toAPIs(src.reponsesPossibles)
        };
    };
    return QuestionAdapter;
}());
export { QuestionAdapter };
