import { 
  Annulation, 
  Btn, 
  HeaderCard,
  DateDebut, 
  DateFin, 
  Information, 
  Status, 
  TypeEvenement, 
  Validation 
} from "../../atoms/itemFields";

export const EvenementStandardListHeader = () => (
  <HeaderCard>
    <Status />
    <TypeEvenement>Type d'évènement</TypeEvenement>
    <DateDebut>Du</DateDebut>
    <DateFin>au</DateFin>
    <Information>Informations</Information>
    <Validation>Validé</Validation>
    <Annulation>Annulé</Annulation>
    <Btn />
  </HeaderCard>
);
