var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useListSelection } from "@ads/ui";
import { useEffect, useState } from "react";
const ID = "@ads/useFormSelectionItem";
export function useFormSelectionItem(input) {
    const [currentCriteria, setCurrentCriteria] = useState(input.initParams);
    const [loading, setLoading] = useState(false);
    const selectionService = useListSelection({
        data: input.config.defaultSelectedData,
        defaultSelectedData: input.config.defaultSelectedData,
        getKeyFromData: input.getKeyFromData,
        typeSelection: input.config.typeSelection,
    });
    /**
     * Reaction aux modifications de la selection
     */
    const listener = {
        idListener: ID,
        onBeforeDataSelectedChanged: function (selectedData) {
            return __awaiter(this, void 0, void 0, function* () {
                return selectedData;
            });
        },
        onAfterDataSelectedChanged: function (_selectedData) { },
        onBeforeDataItemSelected: function (_data) {
            return __awaiter(this, void 0, void 0, function* () {
                return _data;
            });
        },
        onAfterDataItemSelected: function (_data) { },
        onBeforeDataItemUnselected: function (_selectedData) {
            return __awaiter(this, void 0, void 0, function* () {
                return _selectedData;
            });
        },
        onAfterDataItemUnselected: function (_selectedData) { },
        onBeforeDataItemSelectedReset: function () {
            return __awaiter(this, void 0, void 0, function* () {
                return true;
            });
        },
        onAfterDataItemSelectedReset: function () { },
    };
    selectionService.registerListener(listener);
    useEffect(() => {
        const filter = input.initParams;
        reloadData(filter);
    }, []);
    const reloadData = (filter) => {
        setCurrentCriteria(filter);
        setLoading(true);
        //on garde les données selectionnée si elles sont compatible
        deselectNotMatching(filter);
        //Rechargement des données en fonction du nouveau filtre
        if (input.onGetDataRequested !== null) {
            input
                .onGetDataRequested(filter)
                .then((result) => {
                selectionService.notifyData(result);
            })
                .finally(() => {
                setLoading(false);
            });
        }
        else {
            setLoading(false);
        }
    };
    const deselectNotMatching = (filter) => {
        for (const selectedData of selectionService.selectedData) {
            if (!input.isDataMatchWithFilter(selectedData, filter)) {
                selectionService.notifyDataUnselected(input.getKeyFromData(selectedData));
            }
        }
    };
    const setData = (data) => {
        deselectNotMatching(null);
        selectionService.notifyData(data);
    };
    return {
        data: selectionService.data,
        config: input.config,
        loading: loading,
        currentCriteria,
        selectedData: selectionService.selectedData,
        setSelectionnableData: setData,
        requestReloadData: (filter) => reloadData(filter),
        notifyDataSelectedReset: selectionService.notifyDataSelectedReset,
        getKeyFromData: selectionService.getKeyFromData,
        notifyDataSelected: selectionService.notifyDataSelected,
        notifyDataUnSelected: selectionService.notifyDataUnselected,
        registerListener: (listener) => selectionService.registerListener(listener),
    };
}
