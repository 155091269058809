import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { toFrDate } from "@ads/react-common-module";
import { ListViewHeader } from "./ListViewHeader";
import { Alert, Divider, Skeleton } from "antd";
import { QuestionnaireModelStatus, } from "@ads/isadom-model";
import { Card } from "../atoms/Card";
import { adsNotification, List, ListDataNotifier, theme, } from "@ads/ui";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const CardQuestionnaire = styled(Card) `
  display: flex;
  flex-wrap: nowrap;
`;
const CardLeft = styled.div `
  height: 100%;
  display: flex;
  align-self: center;
`;
const DividerQuestionnaire = styled(Divider) `
  height: auto;
`;
export function QuestionnaireListMolecule(props) {
    const history = useHistory();
    const redirect = (questionnaireID) => {
        const url = window.location.href.split("/");
        history.push(url.pop() + "/" + questionnaireID);
    };
    const onTypeQuestionnaireSelected = (typeQuestionnaireID) => {
        props
            .createQuestionnaireFromType(typeQuestionnaireID)
            .then((questionnaire) => {
            redirect(questionnaire.questionnaireID);
        })
            .catch((err) => adsNotification.error({
            message: err,
            duration: theme.duration.notification,
        }));
    };
    props.optionsList.onSelect = onTypeQuestionnaireSelected;
    return (_jsxs(_Fragment, { children: [_jsx(ListViewHeader, Object.assign({}, props.optionsList), void 0), _jsx("br", {}, void 0), props.questionnaires !== undefined && !props.questionnairesLoading ? (_jsx(List, { adapter: QuestionnaireListItemMolecule, listener: {
                    idListener: "IdListQuestion",
                    onItemClick(item) {
                        if (item.status === QuestionnaireModelStatus.TO_CREATE_ON_CLICK) {
                            props
                                .createQuestionnaire(item)
                                .then((questionnaire) => {
                                redirect(questionnaire.questionnaireID);
                            })
                                .catch((err) => adsNotification.error({
                                message: err,
                                duration: theme.duration.notification,
                            }));
                        }
                        else {
                            redirect(item.questionnaireID);
                        }
                    },
                }, notifier: new ListDataNotifier(), data: props.questionnaires }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0)] }, void 0))
            /*props.questionnaires !== undefined && !props.questionnairesLoading ? props.questionnaires.map((value) => (
                <QuestionnaireListItemMolecule questionnaire={value} createQuestionnaire={props.createQuestionnaire}/>
            )) : <>
                <Skeleton paragraph={{rows: 3}}/>
                <Skeleton paragraph={{rows: 3}}/>
                <Skeleton paragraph={{rows: 3}}/>
            </>
            */
            , !props.questionnairesLoading &&
                !props.fetchError &&
                (props.questionnaires === undefined ||
                    props.questionnaires.length === 0) && (_jsx(Alert, { message: "", showIcon: true, description: "La liste des questionnaires est vide", type: "warning" }, void 0)), !props.questionnairesLoading && props.fetchError && (_jsx(Alert, { message: "", showIcon: true, description: "Une erreur inconnue est survenue. Veuillez essayer \u00E0 nouveau. Si le probl\u00E8me persiste, contactez votre administrateur.", type: "error" }, void 0))] }, void 0));
}
export function QuestionnaireListItemMolecule(props) {
    const questionnaire = props.data;
    return (_jsx(_Fragment, { children: _jsxs(CardQuestionnaire, Object.assign({ onClick: () => {
                if (props.notifier && props.notifier.notifyItemClick) {
                    props.notifier.notifyItemClick(props.data);
                }
            } }, { children: [_jsxs(CardLeft, { children: [toFrDate(questionnaire.dateSaisie), " ", _jsx(DividerQuestionnaire, { type: "vertical" }, void 0)] }, void 0), questionnaire.typeQuestionnaire.libelle] }), void 0) }, void 0));
}
