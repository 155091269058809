import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FieldComment } from "@ads/ui";
import { useState } from "react";
import { CommentModal } from "../..";
export function ChampSelect(controler) {
    const [isPopoverVisible, setPopoverVisible] = useState(false);
    const makeChoix = () => {
        const choix = [];
        if (controler.data.choix) {
            controler.data.choix.valeursPossible.forEach((e) => {
                choix.push({ value: e.valeur, content: e.valeur });
            });
        }
        return choix;
    };
    return (_jsx(_Fragment, { children: _jsx(FieldComment, { buttonProps: {
                hasComment: controler.data.valeur.commentaire !== "",
                onClick: () => {
                    setPopoverVisible(!isPopoverVisible);
                },
            }, inputBackgroundProps: {
                maxWidth: "300px",
            }, inputType: "select", selectProps: {
                id: "select",
                mode: controler.data.isChampMultiple.estChoixMultiple
                    ? "multiple"
                    : undefined,
                showArrow: true,
                itemsSelect: makeChoix(),
                name: controler.data.libelle,
                onSelect: (value) => {
                    controler.updateValues(value);
                },
                defaultValue: controler.data.valeur.valeurs,
                value: controler.data.valeur.valeurs,
                selectMode: "light",
                placeholder: "Sélectionnez la valeur",
            }, popoverProps: {
                title: "Commentaire de saisie",
                content: (_jsx(_Fragment, { children: _jsx(CommentModal, { setCommentaire: (value) => {
                            controler.updateCommentaire(value);
                        }, commentaire: controler.data.valeur.commentaire }, void 0) }, void 0)),
                visible: isPopoverVisible,
                zIndex: 200,
                handleClose: () => setPopoverVisible(false),
            } }, void 0) }, void 0));
}
