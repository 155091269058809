import {
  PrestationAdapter,
  PrestationAPI,
  PrestationModel,
  // HttpResponseAPI,
  HttpResponseModel,
  HttpMessageModel
} from "@ads/isadom-model";
import { apiGetV2, getDefaultApiInstance } from "@ads/react-common-module";
import { apiIsadomErrorStrategy } from "@ads/ui";

export async function apiGetPrestationsTiers(
  tiersID: number,
  date: string
): Promise<HttpResponseModel<PrestationModel[]>> {
  try {
    if (!tiersID) {
      throw new Error("L'identifiant du tiers est manquant.");
    }

    const api = getDefaultApiInstance();

    if (!api) {
      throw new Error("La configuration d'api n'est pas initialisée");
    }

    const adapter = new PrestationAdapter();

    // HttpResponse quand reponse du ws sera converti
    // const { data, messages }: HttpResponseAPI<PrestationAPI[]> = await apiGetV2(
    //   api,
    //   `/tiers/${tiersID}/prestations?dDate=${date.split("-").join("")}`,
    //   apiIsadomErrorStrategy
    // );

    const data: PrestationAPI[] = await apiGetV2(
      api,
      `/tiers/${tiersID}/prestations?dDate=${date.split("-").join("")}`,
      apiIsadomErrorStrategy
    );
    const messages: HttpMessageModel[] = [] as unknown as HttpMessageModel[];

    // console.log("D", data);

    return { data: adapter.toClients(data), messages };
  } catch (err: any) {
    return new Promise((_resolve, reject) => {
      reject(err.message);
    });
  }
}
