import { EvenementDTOModel } from "@ads/isadom-model";
import { truncate } from "@ads/react-common-module";
import { IconSeparator, DeleteButton } from "@ads/ui";

import {
  Annulation,
  Btn,
  CardItem,
  DateDebut,
  DateFin,
  EvenementStatus,
  Information,
  LabelItem,
  Status,
  Validation,
  WrapperButton,
} from "../../atoms/itemFields";

interface ListItemProps {
  visiteID: number;
  type: string;
  evenement: EvenementDTOModel;
  removeEvenement: () => void;
}

const objDate = new Intl.DateTimeFormat("fr-FR");

export const EvenementRGPDListItem = ({
  visiteID,
  type,
  evenement,
  removeEvenement,
}: ListItemProps) => (
  <CardItem
    to={`/visites/${visiteID}/evenements/${type}/${evenement.evenementVisiteID
      ? `${evenement.evenementVisiteID}?origine=visite`
      : `${evenement.ID}?origine=isadom`
      }`}
  >
    {evenement.evenementVisiteID && (
      <WrapperButton
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          e.stopPropagation();
          removeEvenement();
        }}
      >
        <DeleteButton />
      </WrapperButton>
    )}
    <Status>
      <EvenementStatus />
    </Status>
    <DateDebut>
      <LabelItem>Date de début : </LabelItem>
      {evenement.dateDebut !== "0000-00-00" &&
        objDate.format(new Date(evenement.dateDebut))}
    </DateDebut>
    <DateFin>
      <LabelItem>Date de fin : </LabelItem>
      {evenement.dateFin !== "0000-00-00" &&
        objDate.format(new Date(evenement.dateFin))}
    </DateFin>
    <Information>
      <LabelItem>Informations : </LabelItem>
      {truncate(evenement.information, 135)}
    </Information>
    <Validation>
      <LabelItem>Validé : </LabelItem>
      {evenement.estPeriodeValidite ? "Oui" : "Non"}
    </Validation>
    <Annulation>
      <LabelItem>Annulé : </LabelItem>
      {evenement.estAnnule ? "Oui" : "Non"}
    </Annulation>
    <Btn>
      <IconSeparator iconSize="3rem" iconColor="#524D72" />
    </Btn>
  </CardItem>
);
