import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Loading } from "@ads/ui";
import { Skeleton, Alert } from "antd";
import { useGetConsommableByProduitID } from "../../hooks/useGetConsommableByProduitID";
import { ConsommableConfigurationDetail } from "../organisms/ConfigurationConsommableDetail";
import { useGetTypesPrestationsByFamilleId } from "../../hooks/useGetTypesPrestationsByFamilleId";
import { useParams } from "react-router-dom";
export const ConsommablesConfigurationAjouteTemplate = ({ visiteID, }) => {
    const { consommableID } = useParams();
    const { consommablesDTOModel, isConsommablesLoading, consommablesErrors } = useGetConsommableByProduitID(consommableID);
    const { typesPrestations } = useGetTypesPrestationsByFamilleId(consommablesDTOModel === null || consommablesDTOModel === void 0 ? void 0 : consommablesDTOModel.familleID);
    // Any car avant ConfigConsoSpecTiersVisiteModel mais nous n'avons pas besoin de toutes les propriétés
    const configConsoSpecTiersVisite = {
        produit: {
            IDProduit: consommableID,
            sRefFabricant: "",
            bInactif: false,
            bPrevisionInactif: false,
            dArretCdeInterne: "0000-00-00",
            sFKTypeProduit: "",
            sCodeNatureProduit: "",
            sLibelle: (consommablesDTOModel === null || consommablesDTOModel === void 0 ? void 0 : consommablesDTOModel.libelle) || "",
            sRefProduit: "",
            famille: {
                idFamille: (consommablesDTOModel === null || consommablesDTOModel === void 0 ? void 0 : consommablesDTOModel.familleID) || "",
                libelle: "",
                suiviCompteur: false,
                suiviReglage: false,
                observance: false,
                inactif: false,
                sousFamille: [],
                tTypePrestation: typesPrestations || [],
            },
        },
        sTypeConfig: "",
        iQuantite: 0,
        iFrequence: 0,
        dDebut: "",
        dFin: "",
        bPreparation: false,
        bTest: false,
        sInformation: "",
        bAfficherCR: false,
        bAfficherOrdo: false,
        bAfficherGED: false,
        configCsoVisiteID: visiteID,
        bAjoutConfig: true,
        bRetraitConfig: false,
        typePrestation: {
            id: "",
            dommainID: "",
            libelle: "",
            isCure: false,
            isAppareillage: false,
            isOxygene: false,
            isObservance: false,
            isGestionTelesuivi: false,
            isPrescriptionMultiple: false,
        },
        dDerniereLivraison: "",
        iQuantiteDerniereLivraison: 0,
    };
    if (isConsommablesLoading) {
        return (_jsxs(_Fragment, { children: [_jsx(Loading, { children: "Chargement en cours du consommable." }, void 0), _jsx(Skeleton, { paragraph: { rows: 3 } }, void 0)] }, void 0));
    }
    if (consommablesErrors) {
        return (_jsx(Alert, { showIcon: true, type: "error", message: "Une erreur est survenue...", description: "La configuration du consommable n'a pu \u00EAtre r\u00E9cup\u00E9r\u00E9e." }, void 0));
    }
    if (configConsoSpecTiersVisite) {
        return (_jsx(ConsommableConfigurationDetail, { visiteID: visiteID, configConsoSpecTiersVisite: configConsoSpecTiersVisite, ajoute: true }, void 0));
    }
    return _jsx(_Fragment, {}, void 0);
};
